var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { BasePageComponent } from '../../../base-page';
import { CategoryService } from '../../../../services/category.service';
import { ELearningService } from 'src/app/services/elearning.service';
import { HttpService } from '../../../../services/http/http.service';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { TestService } from '../../../../services/test.service';
import { TicketService } from 'src/app/services/ticket.service';
import { Utils } from 'src/utils/utils';
var ELearningStudentContainerComponent = /** @class */ (function (_super) {
    __extends(ELearningStudentContainerComponent, _super);
    function ELearningStudentContainerComponent(store, dialog, testService, categoryService, authenticationService, ticket, elearning, httpSv, location, utils, modal) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.dialog = dialog;
        _this.testService = testService;
        _this.categoryService = categoryService;
        _this.authenticationService = authenticationService;
        _this.ticket = ticket;
        _this.elearning = elearning;
        _this.location = location;
        _this.utils = utils;
        _this.modal = modal;
        _this.categories = [];
        _this.showMessage = false;
        _this.selectedValue = 0;
        _this.userId = 0;
        _this.authenticationService.currentUser.subscribe(function (x) {
            _this.currentUser = x;
            _this.userId = _this.currentUser.user.id;
        });
        _this.pageData = {
            title: 'Ensino à Distância',
            loaded: true
        };
        return _this;
    }
    ELearningStudentContainerComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.ticket.ticketsByUserId(this.currentUser.user.id).subscribe(function (ticket) {
            _this.categories = [];
            ticket['hasTickets'].forEach(function (element) {
                if (element['type'] == 'eLearning' && element['daysLeft'] > 0) {
                    if (!_this.categories.find(function (x) { return x.categoryId === element.categoryId; }))
                        _this.categories.push(element);
                }
            });
            if (_this.categories.length > 0) {
                _this.categories.sort(function (a, b) { return (a.categoryName > b.categoryName) ? 1 : -1; });
                console.log(_this.categories);
                _this.activeCategory = _this.categories[0];
                _this.selectedValue = _this.activeCategory.categoryId;
                _this.showMessage = true;
            }
            else {
                _this.showMessage = true;
            }
            //console.log(this.categories);
        });
    };
    ELearningStudentContainerComponent.prototype.select = function (e, i, o) {
        //console.log(this.categories);
        //console.log(o);
        //console.log(this.categories[i]);
        this.activeCategory = this.categories[i];
    };
    ELearningStudentContainerComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return ELearningStudentContainerComponent;
}(BasePageComponent));
export { ELearningStudentContainerComponent };
