import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Theme } from '../models/Theme';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SheetSalesService {

    constructor(private http: HttpClient) { }


    getSchoolSheets(userId: number, startDt: string, endDt: string) {
        console.log(startDt)
        console.log(endDt)
        return this.http.get<any[]>(`${environment.apiUrl}/sheets/schoolSheets/${userId}/${startDt}/${endDt}`);
    }

    GetSchoolSalesSheets(userId: number, startDt: string, endDt: string) {
        return this.http.get<any[]>(`${environment.apiUrl}/sheets/schoolSalesSheets/${userId}/${startDt}/${endDt}`);
    }


    getStudentsReportGeneral(companyId: number, startDt: string, endDt: string) {
        return this.http.get<any[]>(`${environment.apiUrl}/sheets/GetStudentsReportGeneral/${companyId}/${startDt}/${endDt}`);
    }
}
