var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../../../pages/base-page';
import { ConfirmComponent } from '../../DialogBoxes/confirm/confirm.component';
import { DialogBoxTestComponent } from '../../DialogBoxes/dialog-box-test/dialog-box-test.component';
import { HttpService } from '../../../../services/http/http.service';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TestDefinitionService } from '../../../../services/test-definition.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';
var TestComponent = /** @class */ (function (_super) {
    __extends(TestComponent, _super);
    function TestComponent(store, httpSv, testDefinitionService, dialog, toastr) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.testDefinitionService = testDefinitionService;
        _this.dialog = dialog;
        _this.toastr = toastr;
        _this.bordered = true;
        _this.position = 'bottom';
        _this.searchValue = '';
        _this.pageSize = 10;
        _this.listOfDisplayData = [];
        _this.listOfAllData = [];
        _this.list = [];
        _this.currentPage = 0;
        _this.sortName = null;
        _this.sortValue = null;
        _this.getData(environment.apiUrl + "/testDefinition/", 'tableData', 'setLoaded');
        _this.testDefinitionService.getAll().pipe(first()).subscribe(function (test) {
            _this.listOfAllData = test;
            _this.list = test;
        }, function (err) {
            _this.toastr.error('Algo ocorreu mal!');
        });
        return _this;
        /*
        this.pageData = {
          title: 'Testes',
          loaded: true
        };*/
    }
    TestComponent.prototype.currentPageDataChange = function ($event) {
        this.listOfDisplayData = $event;
    };
    TestComponent.prototype.sort = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.listOfAllData = this.list;
        this.listOfDisplayData = this.list;
        this.search();
    };
    TestComponent.prototype.reset = function () {
        this.searchValue = '';
        this.listOfAllData = this.list;
        this.search();
    };
    TestComponent.prototype.select = function (event) {
        var path = event.path || (event.composedPath && event.composedPath());
        if (path[1].className.match('active')) {
            path[1].classList.remove('active');
        }
        else {
            path[1].classList.add('active');
        }
    };
    TestComponent.prototype.search = function () {
        var _this = this;
        var filterFunc = function (item) {
            return (item['categoryName'].toLowerCase().indexOf(_this.searchValue.toLowerCase()) !== -1);
        };
        var data = this.list.filter(function (item) { return filterFunc(item); });
        this.listOfAllData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    TestComponent.prototype.openDialog = function (action, e, i) {
        var _this = this;
        if (e == null || !e.target.className.match('ant-table-column-sorters') && !e.target.className.match('ant-spin-container')) {
            var path = e == null ? "" : e.path || (e.composedPath && e.composedPath());
            var row = {};
            row = e == null ? {} : this.listOfAllData[(this.currentPage * this.pageSize + i)];
            row.action = action;
            var dialogRef = this.dialog.open(DialogBoxTestComponent, {
                width: '99%',
                maxWidth: '850px',
                height: 'auto',
                data: row,
                disableClose: true,
                closeOnNavigation: true
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result === undefined) { }
                else if (result.event == 'Adicionar') {
                    _this.addRowData(result.data);
                }
                else if (result.event == 'Modificar') {
                    _this.updateRowData(result.data);
                }
            });
        }
    };
    TestComponent.prototype.removeSelectedRows = function () {
        var _this = this;
        var row = {};
        row.text = 'Confirma a remoção do(s) teste(s) selecionado(s)?';
        var dialogRef = this.dialog.open(ConfirmComponent, {
            id: 'confirm',
            data: row
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === undefined) { }
            else if (result.event == 'sim') {
                var e = document.querySelectorAll('tr');
                e.forEach(function (el) {
                    if (el.className.match('active')) {
                        var index = _this.listOfAllData.findIndex(function (d) { return d['categoryName'].trim() === el.children[0].lastChild.nodeValue.trim(); });
                        _this.testDefinitionService.deleteTestDefinition(_this.listOfAllData[index]['testDefinitionId']).subscribe(function (data) {
                            _this.testDefinitionService.getAll().pipe(first()).subscribe(function (test) {
                                _this.listOfAllData = test;
                                _this.list = test;
                                _this.toastr.success('teste(s) removido(s) com sucesso!');
                            }, function (err) {
                                _this.toastr.error('Algo ocorreu mal!');
                            });
                        }, function (err) {
                            _this.toastr.error('Algo ocorreu mal!');
                        });
                    }
                });
            }
        });
    };
    TestComponent.prototype.addRowData = function (rowObj) {
        var _this = this;
        this.testDefinitionService.createTestDefinition(rowObj.CategoryName, rowObj.TestTypeName, rowObj.duration, rowObj.numberOfQuestions, rowObj.wrongQuestions).subscribe(function (data) {
            _this.testDefinitionService.getAll().pipe(first()).subscribe(function (test) {
                _this.listOfAllData = test;
                _this.list = test;
                _this.toastr.success('Teste criado com sucesso!');
            }, function (err) {
                _this.toastr.error('Algo ocorreu mal!');
            });
        });
    };
    TestComponent.prototype.custom = function (i) {
        this.currentPage = i - 1;
        console.log(this.currentPage);
    };
    TestComponent.prototype.updateRowData = function (rowObj) {
        var _this = this;
        this.testDefinitionService.updateTestDefinition(rowObj.testDefinitionId, rowObj.CategoryName, rowObj.TestTypeName, rowObj.duration, rowObj.numberOfQuestions, rowObj.wrongQuestions).subscribe(function (data) {
            _this.testDefinitionService.getAll().pipe(first()).subscribe(function (test) {
                _this.listOfAllData = test;
                _this.list = test;
                _this.toastr.success('teste modificado com sucesso!');
            }, function (err) {
                _this.toastr.error('Algo ocorreu mal!');
            });
        });
    };
    TestComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    TestComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return TestComponent;
}(BasePageComponent));
export { TestComponent };
