import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Theme } from '../models/Theme';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private http: HttpClient) { }


  getAll() {
    return this.http.get<Theme[]>(`${environment.apiUrl}/Theme/`);
  }

  createTheme(categories: number[], name: string) {
    return this.http.post<any>(`${environment.apiUrl}/Theme/`, {
      categories, name 
    }
    );
  }

  updateTheme(id: number, name: string, categories: number[]) {
    return this.http.put<any>(`${environment.apiUrl}/Theme/${id}`, {
      name, categories
    });
  }

  deleteTheme(id: number) {
    return this.http.delete<Theme>(`${environment.apiUrl}/Theme/${id}`);
  }
  getThemesAndCategories() {
    return this.http.get<any[]>(`${environment.apiUrl}/Theme/themesAndCategories/`);
  }
}
