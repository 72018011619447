import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Media } from '../models/media';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(private http: HttpClient) { }

  createImageToUser(id: number, url: any, mimeType: string, name: string, size: string) {
    return this.http.post<Media>(`${environment.apiUrl}/Media/imageToUser/${id}`, { url, mimeType, name, size });
  }
  linkImageToQuestion(id: number, url: any, name: string, size: string) {
    return this.http.post<Media>(`${environment.apiUrl}/Media/imageToQuestion/${id}`, { url, name, size });
  }

  getImageByUserId(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/Media/imageByUser/${id}`);
  }

  createImageToCompany(id: number, url: any, mimeType: string, name: string, size: string) {
    return this.http.post<Media>(`${environment.apiUrl}/Media/imageToCompany/${id}`, { url, mimeType, name, size });
  }
  createBgImageToCompany(id: number, url: any, mimeType: string, name: string, size: string) {
    return this.http.post<Media>(`${environment.apiUrl}/Media/bgImageToCompany/${id}`, { url, mimeType, name, size });
  }

  getImageByQuestionId(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/Media/byQuestionId/${id}`);
  }
  getImagesByCompanyId(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/Media/byCompanyId/${id}`);
  }
  // getBackgroundByCompanyName(name: string){
  //   return this.http.get<any>(`${environment.apiUrl}/Media/byCompanyName/${name}`);
  // }
  getRGPD() {
    return this.http.get<any>(`${environment.apiUrl}/Media/RGPD`);
  }

  getImageById(id: number){
    return this.http.get<any>(`${environment.apiUrl}/Media/${id}`);
  }
}
