<div style="display: inline-table;">
    <div style="display: table-cell; width: 99%;text-align: left;">
        <h4 small>{{action}} {{localData.description}}</h4>
    </div>
    <div style="display: table-cell; width: 1%; text-align: right; vertical-align: top;">
        <a mat-dialog-close style="float: right; cursor:pointer" class="tc-icon-wrap icofont-close-circled"></a>
    </div>
</div>
<div style="clear: both; width: 100%;">
    <div style="clear: both; width: 100%;">
        <div class="tab">
            <button class="tablinks" (click)="openCity($event, 'Geral')">Geral</button>
            <button class="tablinks" (click)="openCity($event, 'Imagem')">Imagem</button>
        </div>

        <div id="Geral" class="tabcontent" style="display: block;">
            <form [formGroup]="themesForm">
                <div style="float:right">
                    {{localData.oldQuestionId}}
                </div>
                
                <tc-form-group>
                    <br>
                    <tc-switcher formControlName="status" [value]="localData.active">Activo</tc-switcher>
                </tc-form-group>
                <tc-form-group>
                    <tc-form-label>Descrição da pergunta</tc-form-label>
                    <tc-textarea [(ngModel)]="localData.description" [placeholder]="'Descrição da pergunta'"
                        formControlName="description"></tc-textarea>
                </tc-form-group>
                <tc-form-group>
                    <br>
                    <tc-form-label>Ajuda da pergunta</tc-form-label>
                    <tc-textarea [(ngModel)]="localData.help == null ? '' : localData.help"
                        [placeholder]="'Ajuda da pergunta'" formControlName="help"></tc-textarea>
                </tc-form-group>
                <tc-card [outline]="true" tcBorderColor="#E0E0E2" [tcBgColor]="'#F9F9FA'" title="Opções de resposta">
                    <nz-table #rowSelectionTable [nzData]="listOfAllData" [nzShowPagination]="false"
                        style="width: 98%;padding-left: 2%;overflow: auto;">
                        <thead>
                            <tr>
                                <th nzWidth="5%">Opção</th>
                                <th nzWidth="90%">Descrição</th>
                                <th nzWidth="5%">Correta</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>A</td>
                                <td nzWidth="100%">
                                    <mat-form-field>
                                        <input formControlName="questionOption0" matInput
                                            [(ngModel)]="localData.option1">
                                    </mat-form-field>
                                </td>
                                <td nzWidth="10%">
                                    <tc-checkbox [disabled]="getDisabled(0)" class="checkbox" (click)="change(0)"
                                        [value]="getAnswer(0)"></tc-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>B</td>
                                <td>
                                    <mat-form-field>
                                        <input formControlName="questionOption1" matInput
                                            [(ngModel)]="localData.option2">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <tc-checkbox [disabled]="getDisabled(1)" class="checkbox" (click)="change(1)"
                                        [value]="getAnswer(1)"></tc-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>C</td>
                                <td>
                                    <mat-form-field>
                                        <input formControlName="questionOption2" matInput
                                            [(ngModel)]="localData.option3">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <tc-checkbox [disabled]="getDisabled(2)" class="checkbox" (click)="change(2)"
                                        [value]="getAnswer(2)"> </tc-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>D</td>
                                <td>
                                    <mat-form-field>
                                        <input formControlName="questionOption3" matInput
                                            [(ngModel)]="localData.option4">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <tc-checkbox [disabled]="getDisabled(3)" class="checkbox" (click)="change(3)"
                                        [value]="getAnswer(3)"></tc-checkbox>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </tc-card>
                <br>
                <tc-form-group>
                    <tc-form-label>Fonte da Questão:</tc-form-label>
                    <mat-select formControlName="font" placeholder="Fonte da Questão" class="select-box">
                        <mat-option [value]="0">
                            AlvesCosta
                        </mat-option>
                        <mat-option [value]="1">
                            IMT
                        </mat-option>
                    </mat-select>
                </tc-form-group>
                <tc-form-group>
                    <tc-form-label>Temas</tc-form-label>
                    <div *ngFor="let state of themes; let i=index;">
                        <tc-checkbox
                            *ngIf="state.name != 'B 1 - Princípios Gerais de Trânsito e de Segurança Rodoviária'"
                            [value]="isSelected(state.id)" (click)="selectedTheme(i)">{{state.name}}</tc-checkbox>
                    </div>
                </tc-form-group>
            </form>
        </div>
        <div id="Imagem" class="tabcontent">
            {{imageName}}<br>
            <img src="{{imageUrl}}" alt="" style="width: 200px;">
            <input #file type="file" id="file" accept='image/*' (change)="preview(file.files)" />
        </div>
        <mat-dialog-actions layout="row">
            <button tc-button [view]="'success'" (click)="onSubmit()" style="margin: 2px;"
                [beforeIcon]="'icofont-diskette'">Submeter</button>
            <button tc-button [view]="'warning'" (click)="closeDialog()" style="margin: 2px;"
                [beforeIcon]="'icofont-close-circled'"></button>
        </mat-dialog-actions>
    </div>
</div>