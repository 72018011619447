import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';



@Injectable({ providedIn: 'root' })
export class SessionService {
    constructor(private http: HttpClient) { }


    getLastInteration(id: number) {
        return this.http.get<any>(`${environment.apiUrl}/session/getLastInteration/${id}`);
    }

    isLogged(id: number) {
        return this.http.post<any>(`${environment.apiUrl}/session/isLogged/${id}`, { id });
    }

    updateLastInteration(id: number) {
        return this.http.post<any>(`${environment.apiUrl}/session/updateLastInteration/${id}`, { id });
    }
    killSession(id: number) {
        return this.http.post<any>(`${environment.apiUrl}/session/killSession/${id}`, { id });
    }
}