var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Product } from '../../../../models/product';
import { MatDialogRef } from '@angular/material';
import { ProductService } from '../../../../services/product.service';
var DialogBoxProductsComponent = /** @class */ (function () {
    function DialogBoxProductsComponent(dialogRef, productService, formBuilder, 
    // @Optional() is used to prevent error if no data is passed
    data) {
        this.dialogRef = dialogRef;
        this.productService = productService;
        this.formBuilder = formBuilder;
        this.data = data;
        this.loading = false;
        this.submitted = false;
        this.localData = __assign({}, data);
        this.action = this.localData.action;
    }
    Object.defineProperty(DialogBoxProductsComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.productsForm.controls; },
        enumerable: true,
        configurable: true
    });
    DialogBoxProductsComponent.prototype.closeDialog = function () {
        this.dialogRef.close({ event: 'Cancel' });
    };
    DialogBoxProductsComponent.prototype.ngOnInit = function () {
        this.productsForm = this.formBuilder.group({
            name: ['', Validators.required],
            tag: ['', Validators.required]
        });
    };
    DialogBoxProductsComponent.prototype.onSubmit = function () {
        this.localData.name = this.f.name.value;
        this.localData.tag = this.f.tag.value;
        this.dialogRef.close({ event: this.action, data: this.localData });
    };
    return DialogBoxProductsComponent;
}());
export { DialogBoxProductsComponent };
