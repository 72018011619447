import {Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'tc-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class TCAvatarComponent implements OnInit, OnChanges {
  @HostBinding('class.tc-avatar') true;

  @Input() src: string;
  @Input() initials: string;
  @Input() size: number;
  @Input() alt: string;
  @Input() bgColor: string;

  landscape: boolean = false;
  portrait: boolean = false;
  
  @HostBinding('style.height') get height() {
    return `${this.size}px`;
  }
  @HostBinding('style.width') get width() {
    return `${this.size}px`;
  }
  @HostBinding('style.min-width') get minWidth() {
    return `${this.size}px`;
  }
  @HostBinding('style.min-height') get minHeight() {
    return `${this.size}px`;
  }
  @HostBinding('style.fontSize') get fontSize() {
    return `${this.size / 2.4}px`;
  }
  @HostBinding('class.avatar-loading') @Input() loading: boolean;

  @HostBinding('style.background-color') get backgroundColor() {
    if (this.initials) {
      return this.bgColor || '#805aff';
    }
  }

  loaded: boolean;

  constructor() {
    this.size = 100;
    this.loaded = false;  
    this.landscape = false;
    this.portrait = false;
  }
  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    //console.log(changes);
    this.imageLoaded();
  }

  imageLoaded() {
    this.initials = '';
    this.loaded = true;
    var img = document.getElementById('imgOriginal') as HTMLImageElement;
    //console.log(this.size);

    this.portrait = false;
    this.landscape = false;

    let element = document.getElementById('imgBig')
    let elementSmall = document.getElementById('imgSmall')

    if(img)
    {
      if(img.height >= img.width)
      {
        this.portrait = true;
        if (element) element.className = 'imgPort'
        if (elementSmall) elementSmall.className = 'imgPortSmall'    
        //console.log("portrait");
      }
      else
      {
        this.landscape = true;
        if (element) element.className = 'imgLand'    
        if (elementSmall) elementSmall.className = 'imgLandSmall'    
        //console.log("landscape");
      }
    }
  } 
}
