import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { UserService } from '../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/internal/operators/first';

@Component({
  selector: 'page-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class PageResetPasswordComponent {
  email: FormControl;

  constructor(private router: Router, private _location: Location, private userService: UserService, private toastr: ToastrService) {
    this.email = new FormControl('', [Validators.required]);
  }
  reset() {
    this.userService.recoverPassword(this.email.value).subscribe(c => {
      if(c == true){
        this.toastr.success("Nova senha enviada! Verifique o seu email.");
        this._location.back();
      }
      else{
        this.toastr.error('Email Inexistente!');
      }
    });
  }

  back() {
    this._location.back();
  }
}
