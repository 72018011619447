import { Component, OnInit, Optional, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Utils } from 'src/utils/utils';

@Component({
  selector: 'app-questionHelp',
  templateUrl: 'questionHelp.component.html',
  styleUrls: ['questionHelp.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class QuestionHelpComponent implements OnInit {
  localData: any;
  text: string;
  constructor(public dialogRef: MatDialogRef<QuestionHelpComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: string, public utils: Utils) {
    this.text = data;
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }


}
