import { HttpClient } from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError } from 'rxjs/internal/operators/catchError';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var HttpService = /** @class */ (function () {
    function HttpService(http) {
        this.http = http;
    }
    HttpService.prototype.getData = function (source) {
        return this.http.get(source).pipe(tap(function (res) { return res; }), catchError(this.handleError));
    };
    HttpService.prototype.handleError = function (error) {
        return observableThrowError(error.error || 'Server error');
    };
    HttpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpService_Factory() { return new HttpService(i0.ɵɵinject(i1.HttpClient)); }, token: HttpService, providedIn: "root" });
    return HttpService;
}());
export { HttpService };
