import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../../services/auth.service';
import { BasePageComponent } from '../../base-page';
import { CategoryService } from '../../../services/category.service';
import { HttpService } from '../../../services/http/http.service';
import { IAppState } from '../../../interfaces/app-state';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { ShowTestComponent } from '../show-test/show-test.component';
import { ShowcamTestComponent } from '../showCamTest/showCamTest.component';
import { Store } from '@ngrx/store';
import { TestService } from '../../../services/test.service';
import { Utils } from 'src/utils/utils';
import { environment } from '../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';

@Component({
  selector: 'app-test-history',
  templateUrl: './test-history.component.html',
  styleUrls: ['./test-history.component.scss']
})
export class TestHistoryComponent extends BasePageComponent implements OnInit {
  bordered = true;
  position = 'both';
  currentPage = 0;
  pageSize = 10;
  searchValue = '';
  isCam: boolean;
  listOfDisplayData: any[] = [];
  listOfAllData: any[] = [];
  sortName: string | null = null;
  sortValue: string | null = null;
  currentUser: any;

  
  constructor(store: Store<IAppState>, public dialog: MatDialog, private testService: TestService, private categoryService: CategoryService, private authenticationService: AuthService,
    httpSv: HttpService, private location: Location,
    public utils: Utils) {
    super(store, httpSv);
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getData(`${environment.apiUrl}/category`, 'tabledata', 'setLoaded');
    this.testService.getTestsByUserId(this.currentUser.user.id).pipe(first()).subscribe(data => {
      this.listOfAllData = data;
    });

    this.pageData = {
      title: 'Histórico de testes',
      loaded: true
    };
  }

  ngOnInit() {
    super.ngOnInit();
  }
  openDialog(e, i) {
    this.testService.getTypeByTestId(this.listOfAllData[this.currentPage * this.pageSize + i].testId).subscribe(x => {
      this.isCam = x['type'] == 'CAM' || x['type'] == 'ADR' || x['type'] == 'Taxis';
      console.log(x['type']);

      console.log(this.isCam);

      let row: any = {};
      const deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
      const deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
      const deviceMaxHeight  = this.utils.isMobileDevice() ? '90vh' : '90vh';



      row.testId = this.listOfAllData[this.currentPage * this.pageSize + i].testId;
      row.dashboard = false;
      row.origin = 'history';
row.categoryId = x['categoryId'];

      if (this.isCam == true) {
        const dialogRef = this.dialog.open(ShowcamTestComponent, {
          id: 'camWizard',
          width: deviceWidth,
          maxWidth: deviceMaxWidth,
          height: 'auto',
          maxHeight: deviceMaxHeight,
          disableClose: true,
          closeOnNavigation: true,
          panelClass: 'custom-modalbox',
          data: row
        });
      } else {

        console.log(row);
        const dialogRef = this.dialog.open(ShowTestComponent, {
          id: 'wizard',
          width: deviceWidth,
          maxWidth: deviceMaxWidth,
          height: 'auto',
          maxHeight: deviceMaxHeight,
          disableClose: true,
          closeOnNavigation: true,
          panelClass: 'custom-modalbox',
          data: row
        });
      }
    });
  }

  custom(i) {
    this.currentPage = i - 1;
    console.log(this.currentPage);
  }

  currentPageDataChange($event: any[]): void {
    // console.log($event);
    this.listOfDisplayData = $event;
  }
  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.search();
  }
  reset(): void {
    this.searchValue = '';
    this.search();
  }
  select(event) {
    if (event.path[1].className.match('active')) {
      event.path[1].classList.remove('active');
    }
    else {
      event.path[1].classList.add('active');
    }
  }
  search(): void {
    //  const data = this.listOfDisplayData.filter((item) => filterFunc(item));
    /** sort data **/
    if (this.sortName && this.sortValue) {
      this.listOfDisplayData = this.listOfDisplayData.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
            ? 1
            : -1
      );
    } else {
      this.listOfDisplayData = this.listOfDisplayData;
    }
  }
}
