var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BasePageComponent } from '../../../../pages/base-page';
import { CamWizardComponent } from '../camWizard/camWizard.component';
import { CategoryService } from '../../../../services/category.service';
import { CategoryThemeService } from '../../../../services/category-theme.service';
import { HttpService } from '../../../../services/http/http.service';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { TestDefinitionService } from 'src/app/services/test-definition.service';
import { Utils } from 'src/utils/utils';
import { WizardComponent } from '../wizard/wizard.component';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';
var ThemeListComponent = /** @class */ (function (_super) {
    __extends(ThemeListComponent, _super);
    function ThemeListComponent(route, dialog, categoryService, themeProductService, location, testDefinitionService, store, httpSv, utils) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.route = route;
        _this.dialog = dialog;
        _this.categoryService = categoryService;
        _this.themeProductService = themeProductService;
        _this.location = location;
        _this.testDefinitionService = testDefinitionService;
        _this.utils = utils;
        _this.bodyTag = document.getElementsByTagName('body')[0];
        _this.htmlTag = document.getElementsByTagName('html')[0];
        _this.data = {};
        _this.count = 0;
        _this.numberOfQuestions = 30;
        _this.selectedThemes = [];
        _this.categoryId = _this.route.snapshot.params['id'];
        _this.font = _this.route.snapshot.params['font'];
        _this.testDefinitionService
            .getTestDefinitionByCategoryId(_this.categoryId, 'Tematico')
            .subscribe(function (definition) {
            //console.log(definition);
            _this.numberOfQuestions = definition.numberOfQuestions;
        });
        _this.themeProductService.getNewThemesProductById(_this.categoryId, _this.font).pipe(first()).subscribe(function (themeProduct) {
            console.log(themeProduct);
            _this.themes = themeProduct;
        });
        _this.getData(environment.apiUrl + "/Product/", 'tableData', 'setLoaded');
        _this.categoryService.isCam(_this.categoryId).subscribe(function (camCheck) {
            _this.isCam = camCheck;
        });
        _this.pageData = {
            title: 'Realizar teste',
            loaded: true
        };
        return _this;
    }
    ThemeListComponent.prototype.select = function (e, id) {
        var el = document.getElementById(id);
        if (el.className.match('active')) {
            el.classList.remove('active');
            this.count--;
        }
        else {
            el.classList.add('active');
            this.count++;
        }
    };
    ThemeListComponent.prototype.beginTest = function () {
        var _this = this;
        var el = document.querySelectorAll('li.form-control.active');
        var deviceWidth = this.isMobileDevice() == true ? '99vw' : '90vw';
        var deviceMaxWidth = this.isMobileDevice() == true ? '99vw' : '90vw';
        var deviceMaxHeight = this.utils.isMobileDevice() ? '90vh' : '90vh';
        this.selectedThemes = [];
        el.forEach(function (elem) {
            _this.selectedThemes.push(Number(elem.getAttribute('id')));
        });
        if (this.selectedThemes.length != 0 && this.canStart()) {
            this.data = { 'type': 'Tematico', 'id': this.categoryId, 'themes': this.selectedThemes, 'font': this.font, 'origin': 'test' };
            if (this.isCam == true) {
                var dialogRef = this.dialog.open(CamWizardComponent, {
                    id: 'camWizard',
                    width: deviceWidth,
                    maxWidth: deviceMaxWidth,
                    height: 'auto',
                    maxHeight: deviceMaxHeight,
                    disableClose: true,
                    closeOnNavigation: true,
                    data: this.data,
                    panelClass: 'custom-modalbox'
                });
            }
            else {
                var dialogRef = this.dialog.open(WizardComponent, {
                    id: 'wizard',
                    width: deviceWidth,
                    maxWidth: deviceMaxWidth,
                    height: 'auto',
                    maxHeight: deviceMaxHeight,
                    disableClose: true,
                    closeOnNavigation: true,
                    data: this.data,
                    panelClass: 'custom-modalbox'
                });
            }
        }
    };
    ThemeListComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    ThemeListComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    ThemeListComponent.prototype.back = function () {
        this.location.back();
    };
    ThemeListComponent.prototype.isMobileDevice = function () {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };
    ThemeListComponent.prototype.canStart = function () {
        var _this = this;
        var sum = 0;
        var el = document.querySelectorAll('li.form-control.active');
        console.log(this.themes);
        el.forEach(function (elem) {
            var id = Number(elem.getAttribute('id'));
            var theme = _this.themes.find(function (x) { return x.themeId == id; });
            console.log(theme);
            sum = sum + theme.count;
        });
        console.log(sum);
        return sum > this.numberOfQuestions;
    };
    return ThemeListComponent;
}(BasePageComponent));
export { ThemeListComponent };
