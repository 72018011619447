import {
  Component,
  OnInit,
  OnDestroy,
  Pipe,
  PipeTransform,
  Input,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { TicketService } from "../../../../services/ticket.service";
import { ToastrService } from "ngx-toastr";
import { BasePageComponent } from "../../../base-page";
import { Store } from "@ngrx/store";
import { IAppState } from "../../../../interfaces/app-state";
import { HttpService } from "../../../../services/http/http.service";
import { environment } from "../../../../../environments/environment";
import { Company } from "../../../../models/Company";
import { User } from "../../../../models/user";
import { AuthService } from "../../../../services/auth.service";
import { DidaticMaterialService } from "../../../../services/didaticMaterial.service";
import { ActivatedRoute } from "@angular/router";
import { DidaticMaterialComponent } from "../../DialogBoxes/dialog-box-material/dialog-box-material.component";
import { ConfirmComponent } from "../../DialogBoxes/confirm/confirm.component";
@Component({
  selector: "app-material",
  styleUrls: ["./material.component.scss"],
  templateUrl: "./material.component.html",
})
export class MaterialComponent
  extends BasePageComponent
  implements OnInit, OnDestroy {
  bordered = true;
  position = "bottom";
  currentUser: User;
  currentPage = 0;
  inputFiles: any[] = [];
  fileBlob: any;
  c: any = [];
  id;
  @Input() title: string;
  constructor(
    public dialog: MatDialog,
    store: Store<IAppState>,
    private authenticationService: AuthService,
    private route: ActivatedRoute,
    httpSv: HttpService,
    private didacticMaterial: DidaticMaterialService,
    private toastr: ToastrService
  ) {
    super(store, httpSv);
    this.getData(`${environment.apiUrl}/User/`, "tableData", "setLoaded");
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    this.id = this.route.snapshot.data["id"];

    if (this.id == null || this.id == undefined) {
      this.id =
        this.title == (undefined || null)
          ? this.currentUser.user.companyId
          : this.title;
    }

    if (this.currentUser.user.profileId != 3 && this.currentUser.user.profileId != 7) {
      this.didacticMaterial.getByCompanyId(this.id).subscribe((c) => {
        this.c = c;
      });
    } else {
      this.didacticMaterial
        .getByUserId(this.currentUser.user.id)
        .subscribe((c) => {
          this.c = c;
        });
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    // super.ngOnDestroy();
  }
  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  openFile(i) {
    this.didacticMaterial.getImageById(this.c[i]["mediaId"]).subscribe((a) => {
      if (
        !this.c[i]["mimeType"].includes("image") &&
        !this.c[i]["mimeType"].includes("video")
      ) {
        this.fileBlob = this.b64toBlob(a["didatic"], this.c[i]["mimeType"]);
        this.fileBlob = URL.createObjectURL(this.fileBlob);
        window.open(encodeURI(this.fileBlob + "#toolbar=0&navpanes=0"));
      } else {
        let row: any = {};
        row.mimeType = this.c[i]["mimeType"];
        row.src = "data:" + this.c[i]["mimeType"] + ";base64," + a["didatic"];
        const dialogRef = this.dialog.open(DidaticMaterialComponent, {
          width: "auto",
          height: "auto",
          maxWidth: "95vw",
          maxHeight: "90vh",
          data: row,
        });
      }
    });
  }

  guardar(i) {
    const el = document.getElementById("inputValue" + i);
    let text = el.children[0].children[0].children[0].attributes[3].value;

    this.c[i]["description"] = text;
    this.didacticMaterial
      .updateMaterial(this.c[i]["didaticMaterialId"], text)
      .subscribe(
        (c) => {
          this.toastr.success("Material modificado com sucesso");
        },
        (error) => {
          this.toastr.error("Algo correu mal");
        }
      );
  }
  remover(i) {
    let row: any = {};
    row.text = "Confirma a remoção do material didático?";
    let dialogRef = this.dialog.open(ConfirmComponent, {
      id: "confirm",
      data: row,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) {
      } else if (result.event == "sim") {
        this.didacticMaterial
          .removeDidatic(this.c[i]["didaticMaterialId"])
          .subscribe(
            (c) => {
              this.toastr.success("Material removido com sucesso");
            },
            (error) => {
              this.toastr.error("Algo correu mal");
            }
          );
      }
    });
  }

  currentPageDataChange($event: Company[]): void {
    this.c = $event;
  }

  isAdmin(): boolean {
    return this.currentUser.user.profileId == 1;
  }
}
@Pipe({
  name: "split",
})
export class SplitPipe implements PipeTransform {
  transform(val: string, params: string[]): string {
    return val.split(params[0])[params[1]];
  }
}
