import { Component, OnDestroy, OnInit } from '@angular/core';

import { BasePageComponent } from '../../../../pages/base-page';
import { ConfirmComponent } from '../../DialogBoxes/confirm/confirm.component';
import { DialogBoxTestComponent } from '../../DialogBoxes/dialog-box-test/dialog-box-test.component';
import { HttpService } from '../../../../services/http/http.service';
import { IAppState } from '../../../../interfaces/app-state';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TestDefinition } from '../../../../models/TestDefinition';
import { TestDefinitionService } from '../../../../services/test-definition.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent extends BasePageComponent implements OnInit, OnDestroy {
  bordered = true;
  position = 'bottom';
  searchValue = '';
  pageSize = 10;
  listOfDisplayData: TestDefinition[] = [];
  listOfAllData: TestDefinition[] = [];
  list: TestDefinition[] = [];
  currentPage = 0;
  sortName: string | null = null;
  sortValue: string | null = null;

  constructor(store: Store<IAppState>,
    httpSv: HttpService, private testDefinitionService: TestDefinitionService,
    public dialog: MatDialog,
    private toastr: ToastrService) {
    super(store, httpSv);
    this.getData(`${environment.apiUrl}/testDefinition/`, 'tableData', 'setLoaded');
    this.testDefinitionService.getAll().pipe(first()).subscribe(test => {
      this.listOfAllData = test;
      this.list = test;
    },
      err => {
        this.toastr.error('Algo ocorreu mal!');
      }
    );
    /*
    this.pageData = {
      title: 'Testes',
      loaded: true
    };*/
  }
  currentPageDataChange($event: TestDefinition[]): void {
    this.listOfDisplayData = $event;
  }
  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.listOfAllData = this.list;
    this.listOfDisplayData = this.list;
    this.search();
  }
  reset(): void {
    this.searchValue = '';
    this.listOfAllData = this.list;
    this.search();
  }
  select(event) {

    var path = event.path || (event.composedPath && event.composedPath());

    if (path[1].className.match('active')) {
      path[1].classList.remove('active');
    }
    else {
      path[1].classList.add('active');
    }
  }
  search(): void {
    const filterFunc = (item: TestDefinition) => {
      return (
        item['categoryName'].toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
      );
    };
    const data = this.list.filter((item: TestDefinition) => filterFunc(item));
    this.listOfAllData = data.sort((a, b) =>
      this.sortValue === 'ascend'
        ? a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );
  }


  openDialog(action, e, i) {
    if (e == null || !e.target.className.match('ant-table-column-sorters') && !e.target.className.match('ant-spin-container')) {

      var path = e == null ? "" : e.path || (e.composedPath && e.composedPath());

      let row: any = {};
      row = e == null ? {} : this.listOfAllData[(this.currentPage * this.pageSize + i)];
      row.action = action;
      const dialogRef = this.dialog.open(DialogBoxTestComponent, {
        width: '99%',
        maxWidth: '850px',
        height: 'auto',
        data: row,
        disableClose: true,
        closeOnNavigation: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === undefined) { }
        else if (result.event == 'Adicionar') {
          this.addRowData(result.data);
        } else if (result.event == 'Modificar') {
          this.updateRowData(result.data);
        }
      });
    }
  }

  removeSelectedRows() {
    let row: any = {};
    row.text = 'Confirma a remoção do(s) teste(s) selecionado(s)?'
    let dialogRef = this.dialog.open(ConfirmComponent, {
      id: 'confirm',
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) { }
      else if (result.event == 'sim') {
        const e = document.querySelectorAll('tr');
        e.forEach(el => {
          if (el.className.match('active')) {
            const index: number = this.listOfAllData.findIndex(d => d['categoryName'].trim() === el.children[0].lastChild.nodeValue.trim());
            this.testDefinitionService.deleteTestDefinition(this.listOfAllData[index]['testDefinitionId']).subscribe((data) => {
              this.testDefinitionService.getAll().pipe(first()).subscribe(test => {
                this.listOfAllData = test;
                this.list = test;
                this.toastr.success('teste(s) removido(s) com sucesso!');
              },
                err => {
                  this.toastr.error('Algo ocorreu mal!');
                });
            },
              err => {
                this.toastr.error('Algo ocorreu mal!');
              });
          }
        });
      }
    });
  }
  addRowData(rowObj) {
    this.testDefinitionService.createTestDefinition(rowObj.CategoryName, rowObj.TestTypeName, rowObj.duration, rowObj.numberOfQuestions, rowObj.wrongQuestions).subscribe((data) => {
      this.testDefinitionService.getAll().pipe(first()).subscribe(test => {
        this.listOfAllData = test;
        this.list = test;
        this.toastr.success('Teste criado com sucesso!');
      },
        err => {
          this.toastr.error('Algo ocorreu mal!');
        });
    });
  }

  custom(i) {
    this.currentPage = i - 1;
    console.log(this.currentPage);
  }

  updateRowData(rowObj) {
    this.testDefinitionService.updateTestDefinition(rowObj.testDefinitionId, rowObj.CategoryName, rowObj.TestTypeName, rowObj.duration, rowObj.numberOfQuestions, rowObj.wrongQuestions).subscribe((data) => {
      this.testDefinitionService.getAll().pipe(first()).subscribe(test => {
        this.listOfAllData = test;
        this.list = test;
        this.toastr.success('teste modificado com sucesso!');
      },
        err => {
          this.toastr.error('Algo ocorreu mal!');
        });
    });
  }
  ngOnInit() {
    super.ngOnInit();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
