export const SETTINGS = {
  boxed: true, // boolean
  layout: 'app', // app | vertical
  sidebarBg: '#022E53', // string
  sidebarBg2: '#022E53', // string
  sidebarColor: '#ffffff', // string
  sidebarAccentColor: '#f8e71c', // string
  sidebarAccentContrastColor: '#8b572a', // string
  sidebarOpened: false, // boolean
  topbarBg: '#ffffff', // string
  topbarColor: '#82BEEF', // string
  actualize: true
};
export class GlobalConstants {
  public static actualize: boolean = true;
}