var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ProductService } from '../../../../services/product.service';
var DidaticMaterialComponent = /** @class */ (function () {
    function DidaticMaterialComponent(dialogRef, productService, formBuilder, 
    // @Optional() is used to prevent error if no data is passed
    data) {
        this.dialogRef = dialogRef;
        this.productService = productService;
        this.formBuilder = formBuilder;
        this.data = data;
        this.localData = __assign({}, data);
        console.log(this.localData);
    }
    // convenience getter for easy access to form fields
    // get f() { return this.productsForm.controls; }
    // closeDialog() {
    //     this.dialogRef.close({ event: 'Cancel' });
    // }
    DidaticMaterialComponent.prototype.ngOnInit = function () {
    };
    // onSubmit() {
    //     this.localData.name = this.f.name.value;
    //     this.localData.tag = this.f.tag.value;
    //     this.dialogRef.close({ event: this.action, data: this.localData });
    // }
    DidaticMaterialComponent.prototype.split = function (c) {
        //   console.log(c)
        return c.split('/')[0];
    };
    return DidaticMaterialComponent;
}());
export { DidaticMaterialComponent };
