import { Component, OnInit, Optional, Inject } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Product } from '../../../../models/product';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProductService } from '../../../../services/product.service';

@Component({
  selector: 'app-dialog-box-products',
  templateUrl: './dialog-box-products.component.html',
  styleUrls: ['./dialog-box-products.component.css']
})
export class DialogBoxProductsComponent implements OnInit {

  productsForm: FormGroup;
  loading = false;
  submitted = false;
  categories: Product;
  action: string;
  localData: any;
  constructor(
    public dialogRef: MatDialogRef<DialogBoxProductsComponent>,
    private productService: ProductService,  private formBuilder: FormBuilder,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Product) {
    this.localData = {...data};
    this.action = this.localData.action;
  }
  // convenience getter for easy access to form fields
  get f() { return this.productsForm.controls; }

  closeDialog() {
    this.dialogRef.close({event: 'Cancel'});
  }
  ngOnInit(){
    this.productsForm = this.formBuilder.group({
      name: ['', Validators.required],
      tag: ['', Validators.required]
    });
  }

  
  onSubmit() {
    this.localData.name = this.f.name.value;
    this.localData.tag = this.f.tag.value;
    this.dialogRef.close({ event: this.action, data: this.localData });
  }
}
