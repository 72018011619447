/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./questionHelp.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/common";
import * as i4 from "./questionHelp.component";
import * as i5 from "../../../../../utils/utils";
var styles_QuestionHelpComponent = [i0.styles];
var RenderType_QuestionHelpComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_QuestionHelpComponent, data: {} });
export { RenderType_QuestionHelpComponent as RenderType_QuestionHelpComponent };
function View_QuestionHelpComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Esta pergunta n\u00E3o tem ajuda"]))], null, null); }
function View_QuestionHelpComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.utils.transform(_co.text); _ck(_v, 1, 0, currVal_0); }); }
export function View_QuestionHelpComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "modalbox"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "modal_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["style", "margin-top: 0.428571rem; margin-bottom: 0.428571rem"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["AJUDA"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "hr", [["style", "border-bottom: 1px solid #CCCCCC;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionHelpComponent_1)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionHelpComponent_2)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.text == null) || (_co.text == "")); _ck(_v, 8, 0, currVal_0); var currVal_1 = ((_co.text != null) || (_co.text != "")); _ck(_v, 10, 0, currVal_1); }, null); }
export function View_QuestionHelpComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-questionHelp", [], null, null, null, View_QuestionHelpComponent_0, RenderType_QuestionHelpComponent)), i1.ɵdid(1, 114688, null, 0, i4.QuestionHelpComponent, [i2.MatDialogRef, [2, i2.MAT_DIALOG_DATA], i5.Utils], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuestionHelpComponentNgFactory = i1.ɵccf("app-questionHelp", i4.QuestionHelpComponent, View_QuestionHelpComponent_Host_0, {}, {}, []);
export { QuestionHelpComponentNgFactory as QuestionHelpComponentNgFactory };
