import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CategoryService = /** @class */ (function () {
    function CategoryService(http) {
        this.http = http;
    }
    CategoryService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl + "/Category/");
    };
    CategoryService.prototype.isCam = function (id) {
        return this.http.get(environment.apiUrl + "/Category/isCam/" + id);
    };
    CategoryService.prototype.getCategoryByThemeId = function (id) {
        return this.http.get(environment.apiUrl + "/Category/byTheme/" + id);
    };
    CategoryService.prototype.createCategory = function (name, ProductId) {
        return this.http.post(environment.apiUrl + "/Category/", {
            name: name, ProductId: ProductId
        });
    };
    CategoryService.prototype.updateCategory = function (id, name, ProductId) {
        return this.http.put(environment.apiUrl + "/Category/" + id, { name: name, ProductId: ProductId });
    };
    CategoryService.prototype.deleteCategory = function (id) {
        return this.http.delete(environment.apiUrl + "/Category/" + id);
    };
    CategoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoryService_Factory() { return new CategoryService(i0.ɵɵinject(i1.HttpClient)); }, token: CategoryService, providedIn: "root" });
    return CategoryService;
}());
export { CategoryService };
