import {
  Component,
  OnInit,
  Optional,
  Inject,
  HostListener,
  Input,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatTreeNestedDataSource,
  MatDialog,
} from "@angular/material";
import { DialogBoxUsersComponent } from "../dialog-box-users/dialog-box-users.component";
import { CompanyService } from "../../../../services/company.service";
import { CompanyType } from "../../../../models/companyType";
import { Company } from "../../../../models/Company";
import { CompanyTypeService } from "../../../../services/company-type.service";
import { Duration } from "../../../../models/Duration";
import { DurationService } from "../../../../services/duration.service";
import { ProductService } from "../../../../services/product.service";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ProductModel } from "../../../../models/productModel";
import { NestedTreeControl } from "@angular/cdk/tree";
import { SelectionModel } from "@angular/cdk/collections";
import { TicketService } from "../../../../services/ticket.service";
import { Ticket } from "../../../../models/Ticket";
import { AuthService } from "../../../../services/auth.service";
import { MediaService } from "../../../../services/media.service";
import { first } from "rxjs/internal/operators/first";
import { ToastrService } from "ngx-toastr";
import { ConfirmComponent } from "../confirm/confirm.component";
import { DidaticMaterialService } from "../../../../services/didaticMaterial.service";
import { UserService } from "../../../../services/user.service";
import { User } from "../../../../models/user";
import { UserModel } from "../../../../models/userModel";
import { DidaticMaterialComponent } from "../dialog-box-material/dialog-box-material.component";
import { ActivatedRoute } from "@angular/router";
import { DialogBoxEbookComponent } from "../dialog-box-ebook/dialog-box-ebook.component";

@Component({
  selector: "app-dialog-box-companies",
  templateUrl: "./dialog-box-companies.component.html",
  styleUrls: ["./dialog-box-companies.component.css"],
})
export class DialogBoxCompaniesComponent implements OnInit {
  companyGeralForm: FormGroup;
  companyTicketsForm: FormGroup;
  companyDidacticTicketsForm: FormGroup;
  fileForm: FormGroup;
  ebookForm: FormGroup;
  loading = false;
  submitted = false;
  action: string;
  localData: any;
  companyId: string = "";
  companyTypes: CompanyType[];
  comercials: any[];
  durations: Duration[];
  products: ProductModel[];
  tickets: Ticket[] = [];
  didacticTickets: Ticket[] = [];
  cMaterial: any = [];
  cEbook: any = [];
  fileBlob: any;
  id;
  createdBy: string;
  didacticCreatedBy: string;
  didacticMaterial: ProductModel[];
  public message: string;
  public imagePath = "";
  media = "";
  public imgURL: any = "assets/img/img_avatar.png";
  imgLabel: string;
  bgImage: any = "assets/img/img_avatar.png";
  bgLabel: string;
  treeControl = new NestedTreeControl<ProductModel>((node) => node.children);
  dataSourceTickets = new MatTreeNestedDataSource<ProductModel>();
  dataSourceDidacticMaterial = new MatTreeNestedDataSource<ProductModel>();
  name: string;
  selectedAll = true;
  selection = new SelectionModel<ProductModel>(true, []);
  currentUser;
  inputFiles: any[] = [];
  @Input() title: string;
  @HostListener("window:keyup.esc") onKeyUp() {
    this.dialogRef.close();
  }
  constructor(
    public dialogRef: MatDialogRef<DialogBoxUsersComponent>,
    private companyTypeService: CompanyTypeService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthService,
    private didacticMaterialService: DidaticMaterialService,
    private companyService: CompanyService,
    private durationService: DurationService,
    private toastr: ToastrService,
    private productService: ProductService,
    private ticketService: TicketService,
    private mediaService: MediaService,
    public dialog: MatDialog,
    private userService: UserService,
    private route: ActivatedRoute,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Company
  ) {
    this.localData = { ...data };
    console.log(this.localData);
    this.id = this.route.snapshot.data["id"];
    this.action = this.localData.action;
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    this.companyTypeService.getAll().subscribe((companyTypes) => {
      this.companyTypes = companyTypes;
    });
    this.userService.getComercials().subscribe((a) => {
      this.comercials = a;
    });
    this.durationService.getAll().subscribe((durations) => {
      this.durations = durations;
    });
    this.productService.getAllProductCategories().subscribe((products) => {
      console.log(products);
      this.dataSourceTickets.data = products;
      this.products = products;
    });

    if (this.action != "Adicionar") {
      this.companyId = this.localData.companyId.toString();
      this.mediaService
        .getImagesByCompanyId(this.localData.companyId)
        .subscribe((a) => {
          if (a.image != null && a.image != "") {
            this.imgURL = "data:image/jpg;base64," + a.image;
          }
          if (a.bgImage != null && a.bgImage != "") {
            this.bgImage = "data:image/jpg;base64," + a.bgImage;
          }
        });

      this.ticketService
        .ticketsByCompanyId(this.localData.companyId)
        .subscribe((tickets) => {
          this.tickets = tickets;
          for (let index = 0; index < this.tickets.length; index++) {
            for (let i = 0; i < this.tickets[index]["tickets"].length; i++) {
              this.createdBy = this.tickets[index]["tickets"][i].createdBy;
            }
          }
        });

      this.ticketService
        .didacticMaterialTicketsByCompanyId(this.localData.companyId)
        .subscribe((dtickets) => {
          this.didacticTickets = dtickets;
          for (let index = 0; index < this.didacticTickets.length; index++) {
            for (
              let i = 0;
              i < this.didacticTickets[index]["tickets"].length;
              i++
            ) {
              this.didacticCreatedBy = this.didacticTickets[index]["tickets"][
                i
              ].createdBy;
            }
          }
        });

      if (this.id == null || this.id == undefined) {
        this.id =
          this.title == (undefined || null)
            ? this.currentUser.user.companyId
            : this.title;
      }

      if (this.currentUser.user.profileId != 3) {
        this.didacticMaterialService
          .getByCompanyId(this.localData.companyId)
          .subscribe((cMaterial) => {
            this.cMaterial = cMaterial;
            console.log(this.cMaterial);
          });
      }

      if (this.currentUser.user.profileId != 3) {
        this.didacticMaterialService
          .getEbooksByCompanyId(this.localData.companyId)
          .subscribe((cEbook) => {
            this.cEbook = cEbook;
          });
      }

      this.didacticMaterialService
        .getDidacticMaterialByCompanyId(this.localData.companyId)
        .subscribe((material) => {
          this.didacticMaterial = material["didatic"];
          this.dataSourceDidacticMaterial.data = material["didatic"];
        });
    }
  }
  hasChild = (_: number, node: ProductModel) =>
    !!node.children && node.children.length > 0;
  // convenience getter for easy access to form fields
  get fgeral() {
    return this.companyGeralForm.controls;
  }
  get fticket() {
    return this.companyTicketsForm.controls;
  }
  get dticket() {
    return this.companyDidacticTicketsForm.controls;
  }
  get febook() {
    return this.ebookForm.controls;
  }

  onSubmitGeral() {
    let type = "";

    if (this.dticket.ticketType.untouched == false) {
      const duration = this.durations.filter((item) => {
        return item.name === this.dticket.ticketType.value;
      })[0];
      this.localData.durationId = duration["durationId"];
      this.localData.quantity = this.dticket.quantityD.value;
      this.localData.products = this.didacticMaterial;
      this.authenticationService.currentUser.subscribe(
        (x) => (this.localData.createdBy = x.user.username)
      );
      type = "ticket";
    } else if (this.fticket.ticketType.untouched == false) {
      const duration = this.durations.filter((item) => {
        return item.name === this.fticket.ticketType.value;
      })[0];
      this.localData.durationId = duration["durationId"];
      this.localData.quantity = this.fticket.quantity.value;
      this.localData.products = this.products;
      this.authenticationService.currentUser.subscribe(
        (x) => (this.localData.createdBy = x.user.username)
      );
      type = "ticket";
    } else {
      const companyType = this.companyTypes.filter((item) => {
        return item.name === this.fgeral.companyType.value;
      })[0];
      const comercial = this.comercials.filter((item) => {
        return item.name === this.fgeral.comercial.value;
      })[0];
      // this.localData.comercialId = this.fgeral.comercial.touched == true ? comercial['id'] : '';
      this.localData.comercialId = comercial["id"];
      this.localData.companyTypeId = companyType["companyTypeId"];
      // this.localData.active = this.fgeral.status.value == '' ? this.localData.active : this.fgeral.status.value;
      this.localData.active = this.fgeral.status.value;
      this.localData.name = this.fgeral.name.value;
      this.localData.nif = this.fgeral.nif.value;
      this.localData.address = this.fgeral.address.value;
      this.localData.alvara = this.fgeral.alvara.value;
      this.localData.email = this.fgeral.email.value;
      this.localData.contacts = this.fgeral.contacts.value;
      this.localData.prefix = this.fgeral.prefix.value;
      this.localData.plataform = this.fgeral.plataform.value;
      this.localData.url = this.fgeral.url.value;
      this.localData.emailInstrutores = this.fgeral.emailInstrutores.value;

      type = "geral";
    }
    this.dialogRef.close({
      event: this.action + "Geral",
      data: this.localData,
      submitType: type,
    });
  }
  // onSubmitTickets() {
  //   console.log();

  //   this.dialogRef.close({ event: 'Tickets', data: this.localData });
  // }

  ngOnInit() {
    this.companyGeralForm = this.formBuilder.group({
      name: ["", Validators.required],
      nif: ["", Validators.required],
      contacts: ["", Validators.required],
      email: ["", Validators.required],
      address: ["", Validators.required],
      plataform: ["", Validators.required],
      prefix: ["", Validators.required],
      alvara: ["", Validators.required],
      companyType: ["", Validators.required],
      status: ["", Validators.required],
      url: ["", Validators.required],
      emailInstrutores: [""],
      comercial: [""],
    });
    this.companyTicketsForm = this.formBuilder.group({
      quantity: ["", Validators.required],
      ticketType: ["", Validators.required],
      product: ["", Validators.required],
    });
    this.companyDidacticTicketsForm = this.formBuilder.group({
      quantityD: ["", Validators.required],
      ticketType: ["", Validators.required],
      product: ["", Validators.required],
    });
    this.fileForm = this.formBuilder.group({
      fileToUpload: ["", Validators.required],
      fileDesc: ["", Validators.required],
    });
    this.ebookForm = this.formBuilder.group({
      ebookDesc: ["", Validators.required],
      ebookUrl: ["", Validators.required],
    });
    this.fgeral.companyType.setValue(this.localData.companyType);
    this.fgeral.comercial.setValue(this.localData.comercialUser);
    this.fgeral.status.setValue(this.localData.active);
  }

  closeDialog() {
    this.dialogRef.close({ event: "Cancel" });
  }

  selectAll(ticketType: string) {
    if (ticketType == "categoryTicket") {
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].children != null) {
          this.products[i].selected = this.selectedAll;
          for (let j = 0; j < this.products[i].children.length; j++) {
            this.products[i].children[j].selected = this.selectedAll;
          }
        } else {
          this.products[i].selected =
            this.products[i].selected == this.selectedAll;
        }
      }
      this.selectedAll = this.selectedAll == true ? false : true;
    } else {
      for (let i = 0; i < this.didacticMaterial.length; i++) {
        if (this.didacticMaterial[i].children != null) {
          this.didacticMaterial[i].selected = this.selectedAll;
          for (let j = 0; j < this.didacticMaterial[i].children.length; j++) {
            this.didacticMaterial[i].children[j].selected = this.selectedAll;
          }
        } else {
          this.didacticMaterial[i].selected =
            this.didacticMaterial[i].selected == this.selectedAll;
        }
      }
      this.selectedAll = this.selectedAll == true ? false : true;
    }
  }

  masterToggle(node?: any) {
    if (node.children != null) {
      for (let i = 0; i < node.children.length; i++) {
        node.children[i].selected =
          node.children[i].selected == true ? false : true;
      }
    } else {
      node.selected = node.selected == true ? false : true;
    }
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;

    reader.readAsDataURL(files[0]);
    // tslint:disable-next-line: variable-name
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      let url = reader.result.toString().split(",")[1];
      // var url = this.imgURL.replace(/data:image\/jpeg;base64,/g, '') || this.imgURL.replace(/data:image\/png;base64,/g, '') || this.imgURL.replace(/data:image\/jpg;base64,/g, '');
      this.mediaService
        .createImageToCompany(
          this.localData.companyId,
          url,
          mimeType,
          files[0].name,
          files[0].size
        )
        .pipe(first())
        .subscribe((user) => {
          this.toastr.success("Imagem da Empresa Modificada com sucesso!");
        });
    };
  }
  backgroundUpload(files) {
    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    let reader = new FileReader();
    this.imagePath = files;

    reader.readAsDataURL(files[0]);
    // tslint:disable-next-line: variable-name
    reader.onload = (_event) => {
      this.bgImage = reader.result; // data:image/jpeg;base64,
      let url = reader.result.toString().split(",")[1];
      // var url = this.bgImage.replace(/data:image\/jpeg;base64,/g, '') || this.bgImage.replace(/data:image\/jpg;base64,/g, '') || this.bgImage.replace(/data:image\/png;base64,/g, '');
      // console.log(url);
      this.mediaService
        .createBgImageToCompany(
          this.localData.companyId,
          url,
          mimeType,
          files[0].name,
          files[0].size
        )
        .pipe(first())
        .subscribe((user) => {
          this.toastr.success(
            "Imagem de fundo da Empresa Modificada com sucesso!"
          );
        });
    };
  }
  remove(e, i: number, j: number, ticketType: string) {
    let row: any = {};
    row.text = "Confirma a remoção da senha?";
    let dialogRef = this.dialog.open(ConfirmComponent, {
      id: "confirm",
      // width: '30vw',
      // height: '23vh',
      data: row,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) {
      } else if (result.event == "sim") {
        if (ticketType == "didacticTicket") {
          this.ticketService
            .removeticket(this.didacticTickets[i]["tickets"][j].ticketId)
            .subscribe((c) => {
              this.toastr.success("Senha removida com sucesso");
            });
        } else {
          this.ticketService
            .removeticket(this.tickets[i]["tickets"][j].ticketId)
            .subscribe((c) => {
              this.toastr.success("Senha removida com sucesso");
            });
        }
        this.dialogRef.close({});
      }
    });
  }

  changeStatus(i: number, j: number, ticketType: string) {
    if (ticketType == "didacticTicket") {
      this.didacticTickets[i]["tickets"][j].active =
        this.didacticTickets[i]["tickets"][j].active == true ? false : true;
      this.ticketService
        .ticketStatus(
          this.didacticTickets[i]["tickets"][j].ticketId,
          this.didacticTickets[i]["tickets"][j].active
        )
        .subscribe((c) => {
          this.toastr.success("Senha ativada/inativada com sucesso");
        });
    } else {
      this.tickets[i]["tickets"][j].active =
        this.tickets[i]["tickets"][j].active == true ? false : true;
      this.ticketService
        .ticketStatus(
          this.tickets[i]["tickets"][j].ticketId,
          this.tickets[i]["tickets"][j].active
        )
        .subscribe((c) => {
          this.toastr.success("Senha ativada/inativada com sucesso");
        });
    }
  }

  changeFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  uploadFile(files, fileDesc) {
    if (files.length === 0) {
      return;
    } else {
      for (let index = 0; index < files.length; index++) {
        let reader = new FileReader();
        let mimeType = files[index].type;
        reader.readAsDataURL(files[index]);
        // this.materialForm = this.form -- inicializar formulário.
        reader.onload = (_event) => {
          let url = reader.result.toString().split(",")[1];
          this.companyService
            .upload(
              this.localData.companyId,
              mimeType,
              url.toString(),
              files[index].name.toString(),
              files[index].size.toString(),
              fileDesc.toString()
            )
            .subscribe((m) => {
              this.toastr.success("Ficheiro submetido com sucesso");
            });
          this.dialogRef.close({});
        };
      }
    }
  }

  openCity(evt, cityName, cityTab) {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    document.getElementById(cityName).classList.add("active");
    document.getElementById(cityTab).classList.add("active");
  }

  addEbook() {
    this.didacticMaterialService
      .createEbook(
        this.companyId,
        this.febook.ebookDesc.value,
        this.febook.ebookUrl.value
      )
      .subscribe((a) => {
        this.toastr.success("Ebook criado com sucesso");
        this.dialogRef.close();
      });
  }

  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  openFile(i, type: string) {
    if (type == "outros") {
      this.didacticMaterialService
        .getImageById(this.cMaterial[i]["mediaId"])
        .subscribe((a) => {
          console.log(a);

          if (
            !this.cMaterial[i]["mimeType"].includes("image") &&
            !this.cMaterial[i]["mimeType"].includes("video")
          ) {
            this.fileBlob = this.b64toBlob(
              a["didatic"],
              this.cMaterial[i]["mimeType"]
            );
            this.fileBlob = URL.createObjectURL(this.fileBlob);
            window.open(encodeURI(this.fileBlob + "#toolbar=0&navpanes=0"));
          } else {
            let row: any = {};
            row.mimeType = this.cMaterial[i]["mimeType"];
            row.src =
              "data:" +
              this.cMaterial[i]["mimeType"] +
              ";base64," +
              a["didatic"];
            const dialogRef = this.dialog.open(DidaticMaterialComponent, {
              width: "auto",
              height: "auto",
              maxWidth: "95vw",
              maxHeight: "90vh",
              data: row,
            });
          }
        });
    } else {
      let row: any = {};
      row.url = this.cEbook[i]["url"];
      const dialogRef = this.dialog.open(DialogBoxEbookComponent, {
        width: "95vw",
        height: "90vh",
        maxWidth: "95vw",
        maxHeight: "90vh",
        minWidth: "95vw",
        minHeight: "90vh",
        data: row,
      });
    }
  }

  guardar(i, type: string) {
    if (type == "outros") {
      const el = document.getElementById("inputValue" + i);
      let text = el.children[0].children[0].children[0].attributes[3].value;

      this.cMaterial[i]["description"] = text;
      this.didacticMaterialService
        .updateMaterial(this.cMaterial[i]["didaticMaterialId"], text)
        .subscribe(
          (c) => {
            this.toastr.success("Material modificado com sucesso");
          },
          (error) => {
            this.toastr.error("Algo correu mal");
          }
        );
    } else {
      const el = document.getElementById("input" + i);
      let text = el.children[0].children[0].children[0].attributes[3].value;

      this.cEbook[i]["description"] = text;

      this.didacticMaterialService
        .updateMaterial(this.cEbook[i]["didacticMaterialId"], text)
        .subscribe(
          (c) => {
            this.toastr.success("Material modificado com sucesso");
          },
          (error) => {
            this.toastr.error("Algo correu mal");
          }
        );
    }
  }
  remover(i, type: string) {
    if (type == "outros") {
      let row: any = {};
      row.text = "Confirma a remoção do material didático?";
      let dialogRef = this.dialog.open(ConfirmComponent, {
        id: "confirm",
        data: row,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === undefined) {
        } else if (result.event == "sim") {
          this.didacticMaterialService
            .removeDidatic(this.cMaterial[i]["didaticMaterialId"])
            .subscribe(
              (c) => {
                this.cMaterial.splice(i, 1);
                this.toastr.success("Material removido com sucesso");
                this.dialogRef.close({});
              },
              (error) => {
                this.toastr.error("Algo correu mal");
              }
            );
        }
      });
    } else {
      let row: any = {};
      row.text = "Confirma a remoção do ebook?";
      let dialogRef = this.dialog.open(ConfirmComponent, {
        id: "confirm",
        data: row,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === undefined) {
        } else if (result.event == "sim") {
          this.didacticMaterialService
            .removeDidatic(this.cEbook[i]["didacticMaterialId"])
            .subscribe(
              (c) => {
                this.cEbook.splice(i, 1);
                this.toastr.success("Material removido com sucesso");
                this.dialogRef.close({});
              },
              (error) => {
                this.toastr.error("Algo correu mal");
              }
            );
        }
      });
    }
  }

  currentPageDataChange($event: Company[], type: string): void {
    if (type == "ebook") {
      this.cEbook = $event;
    } else {
      this.cMaterial = $event;
    }
  }

  isAdmin() {
    return this.currentUser.user.profileId == 1 ? false : true;
  }
}
