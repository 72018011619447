var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Question } from '../../../../models/question';
import { MatDialogRef, MatDialog } from '@angular/material';
import { QuestionOptionService } from '../../../../services/question-option.service';
import { ThemeService } from '../../../../services/theme.service';
import { MediaService } from '../../../../services/media.service';
import { first } from 'rxjs/internal/operators/first';
import { QuestionService } from '../../../../services/question.service';
import { DialogBoxThemesComponent } from '../../DialogBoxes/dialog-box-themes/dialog-box-themes.component';
var DialogBoxQuestionsComponent = /** @class */ (function () {
    function DialogBoxQuestionsComponent(dialogRef, questionOptionService, formBuilder, questionService, themeService, mediaService, dialog, data) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.questionOptionService = questionOptionService;
        this.formBuilder = formBuilder;
        this.questionService = questionService;
        this.themeService = themeService;
        this.mediaService = mediaService;
        this.dialog = dialog;
        this.data = data;
        this.loading = false;
        this.submitted = false;
        this.themes = [];
        this.imageName = '';
        this.selectedThemesData = [];
        this.themesSelected = [];
        this.x = '';
        this.questionAnswered = -1;
        this.localData = __assign({}, data);
        this.action = this.localData.action;
        this.imageName = '';
        this.themeService.getAll().subscribe(function (themes) {
            _this.themes = themes;
        });
        if (this.action != 'Adicionar') {
            this.questionService.getThemesByQuestionId(this.localData.questionId).subscribe(function (c) { _this.themesOfQuestion = c; });
            this.questionOptionService.getByQuestionId(this.localData.questionId).subscribe(function (questionOptions) {
                _this.questionOptions = questionOptions;
                _this.listOfAllData = questionOptions;
                for (var index = 0; index < _this.questionOptions.length; index++) {
                    if (index == 0) {
                        _this.localData.option1 = (_this.questionOptions[index].description == null ? '' : _this.questionOptions[index].description);
                    }
                    if (index == 1) {
                        _this.localData.option2 = _this.questionOptions[index].description == null ? '' : _this.questionOptions[index].description;
                    }
                    if (index == 2) {
                        _this.localData.option3 = _this.questionOptions[index].description == null ? '' : _this.questionOptions[index].description;
                    }
                    if (index == 3) {
                        _this.localData.option4 = _this.questionOptions[index].description == null ? '' : _this.questionOptions[index].description;
                    }
                    if (_this.questionOptions[index].isAnswer == true) {
                        _this.localData.isAnswer = index;
                    }
                }
            });
            this.themeService.getAll().subscribe(function (themes) {
                _this.themes = themes;
                _this.questionService.getThemesByQuestionId(_this.localData.questionId).subscribe(function (questionTheme) {
                    _this.selectedThemesData = questionTheme;
                    themes.forEach(function (a) {
                        var found = 0;
                        questionTheme.forEach(function (c) {
                            if (c["name"] == a["name"]) {
                                found = 1;
                            }
                        });
                        if (found == 1) {
                            var t = { 'themeId': a["id"], 'value': a["name"], 'selected': true };
                            _this.themesSelected.push(t);
                        }
                        else {
                            var t = { 'themeId': a["id"], 'value': a["name"], 'selected': false };
                            _this.themesSelected.push(t);
                        }
                    });
                });
            });
        }
        if (this.action == 'Adicionar') {
            this.themeService.getAll().subscribe(function (_themes) {
                _this.themes = _themes;
                _this.themes.forEach(function (a) {
                    var t = { 'themeId': a["id"], 'value': a["name"], 'selected': false };
                    _this.themesSelected.push(t);
                });
            });
        }
    }
    DialogBoxQuestionsComponent.prototype.isSelected = function (themeId) {
        if (this.localData.action == 'Modificar') {
            var e = this.selectedThemesData.find(function (th) { return th['themeId'] === themeId; }) ? true : false;
            return e;
        }
        else if (this.localData.action == 'Adicionar') {
        }
    };
    DialogBoxQuestionsComponent.prototype.selectedTheme = function (i) {
        if (this.localData.action == 'Modificar') {
            this.themesSelected[i]["selected"] = this.themesSelected[i]["selected"] == true ? false : true;
        }
        else if (this.localData.action == 'Adicionar') {
            this.themesSelected[i]["selected"] = this.themesSelected[i]["selected"] == true ? false : true;
        }
    };
    DialogBoxQuestionsComponent.prototype.getAnswer = function (i) {
        if (this.localData.isAnswer == i) {
            return true;
        }
        else {
            return false;
        }
    };
    DialogBoxQuestionsComponent.prototype.getDisabled = function (i) {
        var aux = this.localData['option' + (i + 1)];
        if (aux == undefined || aux == '' || aux == null) {
            return true;
        }
        else {
            return false;
        }
    };
    Object.defineProperty(DialogBoxQuestionsComponent.prototype, "f", {
        get: function () { return this.themesForm.controls; },
        enumerable: true,
        configurable: true
    });
    DialogBoxQuestionsComponent.prototype.closeDialog = function () {
        this.dialogRef.close({ event: 'Cancel' });
    };
    DialogBoxQuestionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.themesForm = this.formBuilder.group({
            description: ['', Validators.required],
            help: [''],
            themes: ['', Validators.required],
            isAnswer: ['', Validators.required],
            status: ['', Validators.required],
            font: ['', Validators.required],
            questionOption0: [''],
            questionOption1: [''],
            questionOption2: [''],
            questionOption3: ['']
        });
        this.f.font.setValue(this.localData.font == 'IMT' ? 1 : 0);
        console.log(this.f.font.value);
        if (this.action != 'Adicionar') {
            this.mediaService.getImageByQuestionId(this.localData.questionId).pipe().subscribe(function (a) {
                if (a.image != null && a.image != '') {
                    _this.imageUrl = 'data:image/jpg;base64,' + a.image;
                    _this.imageName = a.name;
                }
                else {
                    _this.imageUrl = 'assets/img/img_avatar.png';
                    _this.imageName = '';
                }
            });
        }
        else {
            this.imageUrl = 'assets/img/img_avatar.png';
        }
    };
    DialogBoxQuestionsComponent.prototype.onSubmit = function () {
        var _this = this;
        this.localData.active = this.f.status.untouched ? this.localData.active : this.f.status.value;
        this.localData.description = this.f.description.value;
        this.localData.help = this.f.help.value;
        this.localData.questionOption0 = this.f.questionOption0.value == '' ? this.localData.option1 : this.f.questionOption0.value;
        this.localData.questionOption1 = this.f.questionOption1.value == '' ? this.localData.option2 : this.f.questionOption1.value;
        this.localData.questionOption2 = this.f.questionOption2.value == '' ? this.localData.option3 : this.f.questionOption2.value;
        this.localData.questionOption3 = this.f.questionOption3.value == '' ? this.localData.option4 : this.f.questionOption3.value;
        this.localData.isAnswer = this.questionAnswered == -1 ? this.localData.isAnswer : this.questionAnswered;
        this.localData.font = this.f.font.value == 0 ? 'AlvesCosta' : 'IMT';
        this.localData.themes = [];
        this.themesSelected.forEach(function (c) {
            if (c["selected"] == true) {
                var x = Number(c["themeId"]);
                _this.localData.themes.push(x);
            }
        });
        this.dialogRef.close({ event: this.action, data: this.localData });
    };
    DialogBoxQuestionsComponent.prototype.preview = function (files) {
        var _this = this;
        if (files.length === 0) {
            return;
        }
        var mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.message = 'Only images are supported.';
            return;
        }
        var reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(files[0]);
        // tslint:disable-next-line: variable-name
        reader.onload = function (_event) {
            _this.imageUrl = reader.result;
            // const url: any =  reader.readAsDataURL(files[0]);
            var url = reader.result.toString().split(',')[1];
            _this.mediaService.linkImageToQuestion(_this.localData.questionId, url, files[0].name, files[0].size).pipe(first()).subscribe(function (user) {
                _this.dialogRef.close();
            });
        };
    };
    DialogBoxQuestionsComponent.prototype.openDialog = function (action, e, i) {
        if (e == null || !e.target.className.match('ant-table-column-sorters') && !e.target.className.match('ant-spin-container')) {
            var row = {};
            row.action = action;
            row.id = this.themesOfQuestion[i]['themeId'];
            row.name = e == null ? '' : e.path[1].children[0].innerText;
            var dialogRef = this.dialog.open(DialogBoxThemesComponent, {
                width: '70vw',
                height: '90vh',
                minHeight: '90vh',
                data: row
            });
        }
    };
    DialogBoxQuestionsComponent.prototype.change = function (e) {
        this.questionAnswered = e;
        var elem = document.getElementsByClassName('checkbox');
        for (var index = 0; index < elem.length; index++) {
            if (index == e) {
                elem[index].classList.add('checked');
            }
            else {
                elem[index].classList.remove('checked');
            }
        }
    };
    DialogBoxQuestionsComponent.prototype.openCity = function (evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("active", "");
        }
        document.getElementById(cityName).style.display = "block";
        document.getElementById(cityName).classList.add("active");
    };
    return DialogBoxQuestionsComponent;
}());
export { DialogBoxQuestionsComponent };
