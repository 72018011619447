import * as AllIcons from '@ant-design/icons-angular/icons';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialComponent, SplitPipe } from './custom/Material/material/material.component';
import { NZ_I18N, en_US } from 'ng-zorro-antd';
import { NZ_ICONS, NgZorroAntdModule } from 'ng-zorro-antd';
import { StudentRequestsComponent, TrimPipe } from '../pages/custom/student-requests/student-requests.component';

import { BasePageComponent } from './base-page';
import { BlockTemplateComponent } from './custom/block-template/block-template.component';
import { BlockUIModule } from 'ng-block-ui';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CamWizardComponent } from './custom/Tests/camWizard/camWizard.component';
import { CategoriesComponent } from './custom/Plataform/categories/categories.component';
import { CdkTreeModule } from '@angular/cdk/tree';
import { ChangePasswordComponent } from './apps/user-pages/change-password/change-password.component';
import { ChartsModule } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { CompaniesComponent } from './custom/Access/companies/companies.component';
import { ConfirmComponent } from './custom/DialogBoxes/confirm/confirm.component';
import { ConfirmTerminateComponent } from './custom/DialogBoxes/confirm-terminate/confirm-terminate.component';
import { DialogBoxActivateTicketComponent } from './custom/DialogBoxes/dialog-box-activateTicket/dialog-box-activateTicket.component';
import { DialogBoxCategoriesComponent } from './custom/DialogBoxes/dialog-box-categories/dialog-box-categories.component';
import { DialogBoxCompaniesComponent } from './custom/DialogBoxes/dialog-box-companies/dialog-box-companies.component';
import { DialogBoxEbookComponent } from './custom/DialogBoxes/dialog-box-ebook/dialog-box-ebook.component';
import { DialogBoxEmailComponent } from './custom/DialogBoxes/dialog-box-email/dialog-box-email.component';
import { DialogBoxProductsComponent } from './custom/DialogBoxes/dialog-box-products/dialog-box-products.component';
import { DialogBoxQuestionsComponent } from './custom/DialogBoxes/dialog-box-questions/dialog-box-questions.component';
import { DialogBoxTeacherEmailComponent } from '../pages/custom/DialogBoxes/dialog-box-teacher-email/dialog-box-teacher-email.component';
import { DialogBoxTestComponent } from './custom/DialogBoxes/dialog-box-test/dialog-box-test.component';
import { DialogBoxTestResultComponent } from './custom/DialogBoxes/dialog-box-test-result/dialog-box-test-result.component';
import { DialogBoxTestResultFreeComponent } from './custom/DialogBoxes/dialog-box-test-result-free/dialog-box-test-result-free.component';
import { DialogBoxThemesComponent } from './custom/DialogBoxes/dialog-box-themes/dialog-box-themes.component';
import { DialogBoxUsersComponent } from './custom/DialogBoxes/dialog-box-users/dialog-box-users.component';
import { DialogBoxZoomComponent } from './custom/DialogBoxes/dialog-box-zoom/dialog-box-zoom.component';
import { DidaticMaterialComponent } from './custom/DialogBoxes/dialog-box-material/dialog-box-material.component';
import { ELearningStudentComponent } from './custom/Plataform/elearning-student/elearning-student.component';
import { ELearningStudentContainerComponent } from './custom/Plataform/elearning-student/elearning-student-container-component';
import { EbookComponent } from './custom/Material/Ebook/ebook.component';
import { ErrorBasePageComponent } from './errors/error-base-page';
import { FixedTestComponent } from './custom/Tests/FixedTest/fixedTest.component';
import { FreeComponent } from './custom/Tests/BeforefixedTest/free.component';
import { IconDefinition } from '@ant-design/icons-angular';
import { LayoutModule } from '../layout/layout.module';
import { Lesson } from './custom/Lesson/lesson';
import { MaterialModule } from '../../material.module';
import { NgModule } from '@angular/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxEchartsModule } from 'ngx-echarts';
import { NzMessageModule } from 'ng-zorro-antd';
import { Page404Component } from './errors/page-404';
import { PageAnalyticsComponent } from './dashboards/analytics';
import { PageDashboardComponent } from './dashboards/dashboard';
import { PageEditAccountComponent } from './apps/user-pages/edit-account';
import { PageResetPasswordComponent } from './apps/authentication/reset-password';
import { PageSettingsComponent } from './settings';
import { PageSignInComponent } from './apps/authentication/sign-in';
import { PageVerifyAccountComponent } from './apps/authentication/verify-account';
import { ProductsComponent } from './custom/Plataform/products/products.component';
import { QuestionHelpComponent } from './custom/DialogBoxes/questionHelp/questionHelp.component';
import { QuestionsComponent } from './custom/Plataform/questions/questions.component';
import { ReplacePipe } from './custom/replace.pipe';
import { RequestsComponent } from '../pages/custom/requests/requests.component';
import { RouterModule } from '@angular/router';
import { SafePipe } from './custom/safe.pipe';
import { SchoolSalesSheetsComponent } from './custom/Relatorios/RelatoriosEscola/VendasEscolas/vendasEscolas.component';
import { SchoolSheetsComponent } from './custom/Relatorios/RelatoriosEscola/relatorioEscola.component';
import { ShowTestComponent } from './custom/show-test/show-test.component';
import { ShowcamTestComponent } from './custom/showCamTest/showCamTest.component';
import { StudentSheetsComponent } from './custom/Relatorios/RelatoriosEscola/RelatorioAluno/relatorioAluno.component';
import { TestComponent } from './custom/Plataform/test/test.component';
import { TestHistoryComponent } from './custom/test-history/test-history.component';
import { TestTypeComponent } from './custom/Tests/test-type/test-type.component';
import { ThemeListComponent } from './custom/Tests/theme-list/theme-list.component';
import { ThemesComponent } from './custom/Plataform/themes/themes.component';
import { TreetableModule } from 'ng-material-treetable';
import { TypeComponent } from './custom/Tests/type/type.component';
import { UIModule } from '../ui/ui.module';
import { UsersComponent } from './custom/Access/users/users.component';
import { WizardComponent } from './custom/Tests/wizard/wizard.component';
import { categoriesTestComponent } from './custom/Tests/categories/categoriesTest.component';
import en from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])


registerLocaleData(en);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CdkTreeModule,
    ReactiveFormsModule,
    RouterModule,
    ChartsModule,
    NgxChartsModule,
    NgxEchartsModule,
    NzMessageModule,
    BrowserAnimationsModule,
    MaterialModule,
    BlockUIModule.forRoot(),
    NgZorroAntdModule,
    UIModule,
    LayoutModule,
    TreetableModule,
  ],
  declarations: [
    BlockTemplateComponent,
    BasePageComponent,
    PageAnalyticsComponent,
    Page404Component,
    PageEditAccountComponent,
    PageSignInComponent,
    PageSettingsComponent,
    PageDashboardComponent,
    PageResetPasswordComponent,
    PageVerifyAccountComponent,
    ErrorBasePageComponent,
    CategoriesComponent,
    ProductsComponent,
    ThemesComponent,
    QuestionsComponent,
    ChangePasswordComponent,
    ChangePasswordComponent,
    DialogBoxUsersComponent,
    DialogBoxCompaniesComponent,
    DialogBoxCategoriesComponent,
    DialogBoxProductsComponent,
    DialogBoxThemesComponent,
    DialogBoxQuestionsComponent,
    UsersComponent,
    CompaniesComponent,
    ThemeListComponent,
    TypeComponent,
    TestTypeComponent,
    WizardComponent,
    BlockTemplateComponent,
    DialogBoxTestResultComponent,
    Lesson,
    TestComponent,
    DialogBoxTestComponent,
    RequestsComponent,
    DialogBoxEmailComponent,
    StudentRequestsComponent,
    DialogBoxTeacherEmailComponent,
    DialogBoxZoomComponent,
    TestHistoryComponent,
    ShowTestComponent,
    ConfirmTerminateComponent,
    TrimPipe,
    ReplacePipe,
    DialogBoxActivateTicketComponent,
    categoriesTestComponent,
    SafePipe,
    ConfirmComponent,
    MaterialComponent,
    EbookComponent,
    SplitPipe,
    DidaticMaterialComponent,
    StudentSheetsComponent,
    SchoolSalesSheetsComponent,
    SchoolSheetsComponent,
    DialogBoxEbookComponent,
    CamWizardComponent,
    ShowcamTestComponent,
    QuestionHelpComponent,
    FreeComponent,
    FixedTestComponent,
    DialogBoxTestResultFreeComponent,
    ELearningStudentComponent,
    ELearningStudentContainerComponent
  ],
  exports: [BlockTemplateComponent, TrimPipe, SplitPipe],
  providers: [

    { provide: NZ_I18N, useValue: en_US }, { provide: NZ_ICONS, useValue: icons }],
  entryComponents: [
    DialogBoxUsersComponent, DialogBoxCompaniesComponent, DialogBoxCategoriesComponent, DialogBoxTestComponent, DialogBoxEmailComponent, DialogBoxZoomComponent,
    DialogBoxProductsComponent, DialogBoxThemesComponent, DialogBoxQuestionsComponent, WizardComponent, BlockTemplateComponent, DialogBoxTeacherEmailComponent,
    ShowTestComponent, ConfirmTerminateComponent, DialogBoxActivateTicketComponent, ConfirmComponent, ShowcamTestComponent, FixedTestComponent,
    DialogBoxTestResultComponent, DidaticMaterialComponent, DialogBoxEbookComponent, CamWizardComponent, QuestionHelpComponent,
    DialogBoxTestResultFreeComponent, Lesson
  ],
})
export class PagesModule { }
