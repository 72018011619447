import { SimpleChanges, OnChanges, OnInit, } from '@angular/core';
var TCCircleProgressComponent = /** @class */ (function () {
    function TCCircleProgressComponent() {
        this.strokeColor = '';
        this.secondaryColor = '';
        this.strokeDashoffset = 0;
        this.circumference = 2 * Math.PI * 47;
        this.secondaryOffset = 0;
        this.rotate = -90;
    }
    Object.defineProperty(TCCircleProgressComponent.prototype, "ViewSize", {
        get: function () {
            return this.size;
        },
        enumerable: true,
        configurable: true
    });
    TCCircleProgressComponent.prototype.ngOnInit = function () { };
    TCCircleProgressComponent.prototype.ngOnChanges = function (changes) {
        if (changes['value']) {
            this.strokeDashoffset = this.calculateOffset(changes['value'].currentValue);
        }
    };
    TCCircleProgressComponent.prototype.calculateOffset = function (val) {
        return this.circumference - (val / 100) * this.circumference;
    };
    Object.defineProperty(TCCircleProgressComponent.prototype, "SecondaryOffset", {
        get: function () {
            return this.calculateOffset(this.secondaryOffset);
        },
        enumerable: true,
        configurable: true
    });
    TCCircleProgressComponent.prototype.getSecondaryCircleStyles = function () {
        return {
            stroke: this.secondaryColor,
            'stroke-dasharray': this.circumference,
            'stroke-offset': this.SecondaryOffset
        };
    };
    TCCircleProgressComponent.prototype.getProgressCircleStyles = function () {
        return {
            stroke: this.strokeColor,
            'stroke-dashoffset': this.strokeDashoffset,
            'stroke-dasharray': this.circumference,
            'stroke-width': this.strokeWidth,
            transform: "rotate(" + this.rotate + "deg)",
        };
    };
    return TCCircleProgressComponent;
}());
export { TCCircleProgressComponent };
