import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ELearningService {

  constructor(private http: HttpClient) { }


  getReport(list: any[]) {
    return this.http.post<any[]>(`${environment.apiUrl}/elearning/report`, list);
  } 

  getELearningResume(id: number, categoryId: number) {
    return this.http.get<any[]>(`${environment.apiUrl}/elearning/resume/${id}/${categoryId}`);
  } 

  createTestSession(userElearningLessonId: number, testId: number) {
    return this.http.post<any>(`${environment.apiUrl}/elearning/elearningTestLessonSession/`, {userElearningLessonId, testId});
  }

  createSession(userElearningLessonId: number, StartDt: number, EndDt: number) {
    return this.http.post<any>(`${environment.apiUrl}/elearning/elearningLessonSession/`, {userElearningLessonId, StartDt, EndDt});
  }
 
  updateSession(elearningLessonSessionId: number, EndDt: number) {
    return this.http.put<any>(`${environment.apiUrl}/elearning/elearningLessonSession/${elearningLessonSessionId}`, EndDt);
  }

  toggleNodeLesson(userELearningLessonId: number) {
    return this.http.put<any>(`${environment.apiUrl}/elearning/treeNodeToggle/${userELearningLessonId}`, null);
  }

}


