var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, } from "@angular/core";
import { MatDialogRef, MatTreeNestedDataSource, MatDialog, } from "@angular/material";
import { CompanyService } from "../../../../services/company.service";
import { Company } from "../../../../models/Company";
import { CompanyTypeService } from "../../../../services/company-type.service";
import { DurationService } from "../../../../services/duration.service";
import { ProductService } from "../../../../services/product.service";
import { Validators, FormBuilder } from "@angular/forms";
import { NestedTreeControl } from "@angular/cdk/tree";
import { SelectionModel } from "@angular/cdk/collections";
import { TicketService } from "../../../../services/ticket.service";
import { AuthService } from "../../../../services/auth.service";
import { MediaService } from "../../../../services/media.service";
import { first } from "rxjs/internal/operators/first";
import { ToastrService } from "ngx-toastr";
import { ConfirmComponent } from "../confirm/confirm.component";
import { DidaticMaterialService } from "../../../../services/didaticMaterial.service";
import { UserService } from "../../../../services/user.service";
import { DidaticMaterialComponent } from "../dialog-box-material/dialog-box-material.component";
import { ActivatedRoute } from "@angular/router";
import { DialogBoxEbookComponent } from "../dialog-box-ebook/dialog-box-ebook.component";
var DialogBoxCompaniesComponent = /** @class */ (function () {
    function DialogBoxCompaniesComponent(dialogRef, companyTypeService, formBuilder, authenticationService, didacticMaterialService, companyService, durationService, toastr, productService, ticketService, mediaService, dialog, userService, route, 
    // @Optional() is used to prevent error if no data is passed
    data) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.companyTypeService = companyTypeService;
        this.formBuilder = formBuilder;
        this.authenticationService = authenticationService;
        this.didacticMaterialService = didacticMaterialService;
        this.companyService = companyService;
        this.durationService = durationService;
        this.toastr = toastr;
        this.productService = productService;
        this.ticketService = ticketService;
        this.mediaService = mediaService;
        this.dialog = dialog;
        this.userService = userService;
        this.route = route;
        this.data = data;
        this.loading = false;
        this.submitted = false;
        this.companyId = "";
        this.tickets = [];
        this.didacticTickets = [];
        this.cMaterial = [];
        this.cEbook = [];
        this.imagePath = "";
        this.media = "";
        this.imgURL = "assets/img/img_avatar.png";
        this.bgImage = "assets/img/img_avatar.png";
        this.treeControl = new NestedTreeControl(function (node) { return node.children; });
        this.dataSourceTickets = new MatTreeNestedDataSource();
        this.dataSourceDidacticMaterial = new MatTreeNestedDataSource();
        this.selectedAll = true;
        this.selection = new SelectionModel(true, []);
        this.inputFiles = [];
        this.hasChild = function (_, node) {
            return !!node.children && node.children.length > 0;
        };
        this.b64toBlob = function (b64Data, contentType, sliceSize) {
            if (contentType === void 0) { contentType = ""; }
            if (sliceSize === void 0) { sliceSize = 512; }
            var byteCharacters = atob(b64Data);
            var byteArrays = [];
            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);
                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            var blob = new Blob(byteArrays, { type: contentType });
            return blob;
        };
        this.localData = __assign({}, data);
        console.log(this.localData);
        this.id = this.route.snapshot.data["id"];
        this.action = this.localData.action;
        this.authenticationService.currentUser.subscribe(function (x) { return (_this.currentUser = x); });
        this.companyTypeService.getAll().subscribe(function (companyTypes) {
            _this.companyTypes = companyTypes;
        });
        this.userService.getComercials().subscribe(function (a) {
            _this.comercials = a;
        });
        this.durationService.getAll().subscribe(function (durations) {
            _this.durations = durations;
        });
        this.productService.getAllProductCategories().subscribe(function (products) {
            console.log(products);
            _this.dataSourceTickets.data = products;
            _this.products = products;
        });
        if (this.action != "Adicionar") {
            this.companyId = this.localData.companyId.toString();
            this.mediaService
                .getImagesByCompanyId(this.localData.companyId)
                .subscribe(function (a) {
                if (a.image != null && a.image != "") {
                    _this.imgURL = "data:image/jpg;base64," + a.image;
                }
                if (a.bgImage != null && a.bgImage != "") {
                    _this.bgImage = "data:image/jpg;base64," + a.bgImage;
                }
            });
            this.ticketService
                .ticketsByCompanyId(this.localData.companyId)
                .subscribe(function (tickets) {
                _this.tickets = tickets;
                for (var index = 0; index < _this.tickets.length; index++) {
                    for (var i = 0; i < _this.tickets[index]["tickets"].length; i++) {
                        _this.createdBy = _this.tickets[index]["tickets"][i].createdBy;
                    }
                }
            });
            this.ticketService
                .didacticMaterialTicketsByCompanyId(this.localData.companyId)
                .subscribe(function (dtickets) {
                _this.didacticTickets = dtickets;
                for (var index = 0; index < _this.didacticTickets.length; index++) {
                    for (var i = 0; i < _this.didacticTickets[index]["tickets"].length; i++) {
                        _this.didacticCreatedBy = _this.didacticTickets[index]["tickets"][i].createdBy;
                    }
                }
            });
            if (this.id == null || this.id == undefined) {
                this.id =
                    this.title == (undefined || null)
                        ? this.currentUser.user.companyId
                        : this.title;
            }
            if (this.currentUser.user.profileId != 3) {
                this.didacticMaterialService
                    .getByCompanyId(this.localData.companyId)
                    .subscribe(function (cMaterial) {
                    _this.cMaterial = cMaterial;
                    console.log(_this.cMaterial);
                });
            }
            if (this.currentUser.user.profileId != 3) {
                this.didacticMaterialService
                    .getEbooksByCompanyId(this.localData.companyId)
                    .subscribe(function (cEbook) {
                    _this.cEbook = cEbook;
                });
            }
            this.didacticMaterialService
                .getDidacticMaterialByCompanyId(this.localData.companyId)
                .subscribe(function (material) {
                _this.didacticMaterial = material["didatic"];
                _this.dataSourceDidacticMaterial.data = material["didatic"];
            });
        }
    }
    DialogBoxCompaniesComponent.prototype.onKeyUp = function () {
        this.dialogRef.close();
    };
    Object.defineProperty(DialogBoxCompaniesComponent.prototype, "fgeral", {
        // convenience getter for easy access to form fields
        get: function () {
            return this.companyGeralForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogBoxCompaniesComponent.prototype, "fticket", {
        get: function () {
            return this.companyTicketsForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogBoxCompaniesComponent.prototype, "dticket", {
        get: function () {
            return this.companyDidacticTicketsForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogBoxCompaniesComponent.prototype, "febook", {
        get: function () {
            return this.ebookForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    DialogBoxCompaniesComponent.prototype.onSubmitGeral = function () {
        var _this = this;
        var type = "";
        if (this.dticket.ticketType.untouched == false) {
            var duration = this.durations.filter(function (item) {
                return item.name === _this.dticket.ticketType.value;
            })[0];
            this.localData.durationId = duration["durationId"];
            this.localData.quantity = this.dticket.quantityD.value;
            this.localData.products = this.didacticMaterial;
            this.authenticationService.currentUser.subscribe(function (x) { return (_this.localData.createdBy = x.user.username); });
            type = "ticket";
        }
        else if (this.fticket.ticketType.untouched == false) {
            var duration = this.durations.filter(function (item) {
                return item.name === _this.fticket.ticketType.value;
            })[0];
            this.localData.durationId = duration["durationId"];
            this.localData.quantity = this.fticket.quantity.value;
            this.localData.products = this.products;
            this.authenticationService.currentUser.subscribe(function (x) { return (_this.localData.createdBy = x.user.username); });
            type = "ticket";
        }
        else {
            var companyType = this.companyTypes.filter(function (item) {
                return item.name === _this.fgeral.companyType.value;
            })[0];
            var comercial = this.comercials.filter(function (item) {
                return item.name === _this.fgeral.comercial.value;
            })[0];
            // this.localData.comercialId = this.fgeral.comercial.touched == true ? comercial['id'] : '';
            this.localData.comercialId = comercial["id"];
            this.localData.companyTypeId = companyType["companyTypeId"];
            // this.localData.active = this.fgeral.status.value == '' ? this.localData.active : this.fgeral.status.value;
            this.localData.active = this.fgeral.status.value;
            this.localData.name = this.fgeral.name.value;
            this.localData.nif = this.fgeral.nif.value;
            this.localData.address = this.fgeral.address.value;
            this.localData.alvara = this.fgeral.alvara.value;
            this.localData.email = this.fgeral.email.value;
            this.localData.contacts = this.fgeral.contacts.value;
            this.localData.prefix = this.fgeral.prefix.value;
            this.localData.plataform = this.fgeral.plataform.value;
            this.localData.url = this.fgeral.url.value;
            this.localData.emailInstrutores = this.fgeral.emailInstrutores.value;
            type = "geral";
        }
        this.dialogRef.close({
            event: this.action + "Geral",
            data: this.localData,
            submitType: type,
        });
    };
    // onSubmitTickets() {
    //   console.log();
    //   this.dialogRef.close({ event: 'Tickets', data: this.localData });
    // }
    DialogBoxCompaniesComponent.prototype.ngOnInit = function () {
        this.companyGeralForm = this.formBuilder.group({
            name: ["", Validators.required],
            nif: ["", Validators.required],
            contacts: ["", Validators.required],
            email: ["", Validators.required],
            address: ["", Validators.required],
            plataform: ["", Validators.required],
            prefix: ["", Validators.required],
            alvara: ["", Validators.required],
            companyType: ["", Validators.required],
            status: ["", Validators.required],
            url: ["", Validators.required],
            emailInstrutores: [""],
            comercial: [""],
        });
        this.companyTicketsForm = this.formBuilder.group({
            quantity: ["", Validators.required],
            ticketType: ["", Validators.required],
            product: ["", Validators.required],
        });
        this.companyDidacticTicketsForm = this.formBuilder.group({
            quantityD: ["", Validators.required],
            ticketType: ["", Validators.required],
            product: ["", Validators.required],
        });
        this.fileForm = this.formBuilder.group({
            fileToUpload: ["", Validators.required],
            fileDesc: ["", Validators.required],
        });
        this.ebookForm = this.formBuilder.group({
            ebookDesc: ["", Validators.required],
            ebookUrl: ["", Validators.required],
        });
        this.fgeral.companyType.setValue(this.localData.companyType);
        this.fgeral.comercial.setValue(this.localData.comercialUser);
        this.fgeral.status.setValue(this.localData.active);
    };
    DialogBoxCompaniesComponent.prototype.closeDialog = function () {
        this.dialogRef.close({ event: "Cancel" });
    };
    DialogBoxCompaniesComponent.prototype.selectAll = function (ticketType) {
        if (ticketType == "categoryTicket") {
            for (var i = 0; i < this.products.length; i++) {
                if (this.products[i].children != null) {
                    this.products[i].selected = this.selectedAll;
                    for (var j = 0; j < this.products[i].children.length; j++) {
                        this.products[i].children[j].selected = this.selectedAll;
                    }
                }
                else {
                    this.products[i].selected =
                        this.products[i].selected == this.selectedAll;
                }
            }
            this.selectedAll = this.selectedAll == true ? false : true;
        }
        else {
            for (var i = 0; i < this.didacticMaterial.length; i++) {
                if (this.didacticMaterial[i].children != null) {
                    this.didacticMaterial[i].selected = this.selectedAll;
                    for (var j = 0; j < this.didacticMaterial[i].children.length; j++) {
                        this.didacticMaterial[i].children[j].selected = this.selectedAll;
                    }
                }
                else {
                    this.didacticMaterial[i].selected =
                        this.didacticMaterial[i].selected == this.selectedAll;
                }
            }
            this.selectedAll = this.selectedAll == true ? false : true;
        }
    };
    DialogBoxCompaniesComponent.prototype.masterToggle = function (node) {
        if (node.children != null) {
            for (var i = 0; i < node.children.length; i++) {
                node.children[i].selected =
                    node.children[i].selected == true ? false : true;
            }
        }
        else {
            node.selected = node.selected == true ? false : true;
        }
    };
    DialogBoxCompaniesComponent.prototype.preview = function (files) {
        var _this = this;
        if (files.length === 0) {
            return;
        }
        var mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.message = "Only images are supported.";
            return;
        }
        var reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(files[0]);
        // tslint:disable-next-line: variable-name
        reader.onload = function (_event) {
            _this.imgURL = reader.result;
            var url = reader.result.toString().split(",")[1];
            // var url = this.imgURL.replace(/data:image\/jpeg;base64,/g, '') || this.imgURL.replace(/data:image\/png;base64,/g, '') || this.imgURL.replace(/data:image\/jpg;base64,/g, '');
            _this.mediaService
                .createImageToCompany(_this.localData.companyId, url, mimeType, files[0].name, files[0].size)
                .pipe(first())
                .subscribe(function (user) {
                _this.toastr.success("Imagem da Empresa Modificada com sucesso!");
            });
        };
    };
    DialogBoxCompaniesComponent.prototype.backgroundUpload = function (files) {
        var _this = this;
        if (files.length === 0) {
            return;
        }
        var mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.message = "Only images are supported.";
            return;
        }
        var reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(files[0]);
        // tslint:disable-next-line: variable-name
        reader.onload = function (_event) {
            _this.bgImage = reader.result; // data:image/jpeg;base64,
            var url = reader.result.toString().split(",")[1];
            // var url = this.bgImage.replace(/data:image\/jpeg;base64,/g, '') || this.bgImage.replace(/data:image\/jpg;base64,/g, '') || this.bgImage.replace(/data:image\/png;base64,/g, '');
            // console.log(url);
            _this.mediaService
                .createBgImageToCompany(_this.localData.companyId, url, mimeType, files[0].name, files[0].size)
                .pipe(first())
                .subscribe(function (user) {
                _this.toastr.success("Imagem de fundo da Empresa Modificada com sucesso!");
            });
        };
    };
    DialogBoxCompaniesComponent.prototype.remove = function (e, i, j, ticketType) {
        var _this = this;
        var row = {};
        row.text = "Confirma a remoção da senha?";
        var dialogRef = this.dialog.open(ConfirmComponent, {
            id: "confirm",
            // width: '30vw',
            // height: '23vh',
            data: row,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === undefined) {
            }
            else if (result.event == "sim") {
                if (ticketType == "didacticTicket") {
                    _this.ticketService
                        .removeticket(_this.didacticTickets[i]["tickets"][j].ticketId)
                        .subscribe(function (c) {
                        _this.toastr.success("Senha removida com sucesso");
                    });
                }
                else {
                    _this.ticketService
                        .removeticket(_this.tickets[i]["tickets"][j].ticketId)
                        .subscribe(function (c) {
                        _this.toastr.success("Senha removida com sucesso");
                    });
                }
                _this.dialogRef.close({});
            }
        });
    };
    DialogBoxCompaniesComponent.prototype.changeStatus = function (i, j, ticketType) {
        var _this = this;
        if (ticketType == "didacticTicket") {
            this.didacticTickets[i]["tickets"][j].active =
                this.didacticTickets[i]["tickets"][j].active == true ? false : true;
            this.ticketService
                .ticketStatus(this.didacticTickets[i]["tickets"][j].ticketId, this.didacticTickets[i]["tickets"][j].active)
                .subscribe(function (c) {
                _this.toastr.success("Senha ativada/inativada com sucesso");
            });
        }
        else {
            this.tickets[i]["tickets"][j].active =
                this.tickets[i]["tickets"][j].active == true ? false : true;
            this.ticketService
                .ticketStatus(this.tickets[i]["tickets"][j].ticketId, this.tickets[i]["tickets"][j].active)
                .subscribe(function (c) {
                _this.toastr.success("Senha ativada/inativada com sucesso");
            });
        }
    };
    DialogBoxCompaniesComponent.prototype.changeFile = function (file) {
        return new Promise(function (resolve, reject) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () { return resolve(reader.result); };
            reader.onerror = function (error) { return reject(error); };
        });
    };
    DialogBoxCompaniesComponent.prototype.uploadFile = function (files, fileDesc) {
        var _this = this;
        if (files.length === 0) {
            return;
        }
        else {
            var _loop_1 = function (index) {
                var reader = new FileReader();
                var mimeType = files[index].type;
                reader.readAsDataURL(files[index]);
                // this.materialForm = this.form -- inicializar formulário.
                reader.onload = function (_event) {
                    var url = reader.result.toString().split(",")[1];
                    _this.companyService
                        .upload(_this.localData.companyId, mimeType, url.toString(), files[index].name.toString(), files[index].size.toString(), fileDesc.toString())
                        .subscribe(function (m) {
                        _this.toastr.success("Ficheiro submetido com sucesso");
                    });
                    _this.dialogRef.close({});
                };
            };
            for (var index = 0; index < files.length; index++) {
                _loop_1(index);
            }
        }
    };
    DialogBoxCompaniesComponent.prototype.openCity = function (evt, cityName, cityTab) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(cityName).style.display = "block";
        document.getElementById(cityName).classList.add("active");
        document.getElementById(cityTab).classList.add("active");
    };
    DialogBoxCompaniesComponent.prototype.addEbook = function () {
        var _this = this;
        this.didacticMaterialService
            .createEbook(this.companyId, this.febook.ebookDesc.value, this.febook.ebookUrl.value)
            .subscribe(function (a) {
            _this.toastr.success("Ebook criado com sucesso");
            _this.dialogRef.close();
        });
    };
    DialogBoxCompaniesComponent.prototype.openFile = function (i, type) {
        var _this = this;
        if (type == "outros") {
            this.didacticMaterialService
                .getImageById(this.cMaterial[i]["mediaId"])
                .subscribe(function (a) {
                console.log(a);
                if (!_this.cMaterial[i]["mimeType"].includes("image") &&
                    !_this.cMaterial[i]["mimeType"].includes("video")) {
                    _this.fileBlob = _this.b64toBlob(a["didatic"], _this.cMaterial[i]["mimeType"]);
                    _this.fileBlob = URL.createObjectURL(_this.fileBlob);
                    window.open(encodeURI(_this.fileBlob + "#toolbar=0&navpanes=0"));
                }
                else {
                    var row = {};
                    row.mimeType = _this.cMaterial[i]["mimeType"];
                    row.src =
                        "data:" +
                            _this.cMaterial[i]["mimeType"] +
                            ";base64," +
                            a["didatic"];
                    var dialogRef = _this.dialog.open(DidaticMaterialComponent, {
                        width: "auto",
                        height: "auto",
                        maxWidth: "95vw",
                        maxHeight: "90vh",
                        data: row,
                    });
                }
            });
        }
        else {
            var row = {};
            row.url = this.cEbook[i]["url"];
            var dialogRef = this.dialog.open(DialogBoxEbookComponent, {
                width: "95vw",
                height: "90vh",
                maxWidth: "95vw",
                maxHeight: "90vh",
                minWidth: "95vw",
                minHeight: "90vh",
                data: row,
            });
        }
    };
    DialogBoxCompaniesComponent.prototype.guardar = function (i, type) {
        var _this = this;
        if (type == "outros") {
            var el = document.getElementById("inputValue" + i);
            var text = el.children[0].children[0].children[0].attributes[3].value;
            this.cMaterial[i]["description"] = text;
            this.didacticMaterialService
                .updateMaterial(this.cMaterial[i]["didaticMaterialId"], text)
                .subscribe(function (c) {
                _this.toastr.success("Material modificado com sucesso");
            }, function (error) {
                _this.toastr.error("Algo correu mal");
            });
        }
        else {
            var el = document.getElementById("input" + i);
            var text = el.children[0].children[0].children[0].attributes[3].value;
            this.cEbook[i]["description"] = text;
            this.didacticMaterialService
                .updateMaterial(this.cEbook[i]["didacticMaterialId"], text)
                .subscribe(function (c) {
                _this.toastr.success("Material modificado com sucesso");
            }, function (error) {
                _this.toastr.error("Algo correu mal");
            });
        }
    };
    DialogBoxCompaniesComponent.prototype.remover = function (i, type) {
        var _this = this;
        if (type == "outros") {
            var row = {};
            row.text = "Confirma a remoção do material didático?";
            var dialogRef = this.dialog.open(ConfirmComponent, {
                id: "confirm",
                data: row,
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result === undefined) {
                }
                else if (result.event == "sim") {
                    _this.didacticMaterialService
                        .removeDidatic(_this.cMaterial[i]["didaticMaterialId"])
                        .subscribe(function (c) {
                        _this.cMaterial.splice(i, 1);
                        _this.toastr.success("Material removido com sucesso");
                        _this.dialogRef.close({});
                    }, function (error) {
                        _this.toastr.error("Algo correu mal");
                    });
                }
            });
        }
        else {
            var row = {};
            row.text = "Confirma a remoção do ebook?";
            var dialogRef = this.dialog.open(ConfirmComponent, {
                id: "confirm",
                data: row,
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result === undefined) {
                }
                else if (result.event == "sim") {
                    _this.didacticMaterialService
                        .removeDidatic(_this.cEbook[i]["didacticMaterialId"])
                        .subscribe(function (c) {
                        _this.cEbook.splice(i, 1);
                        _this.toastr.success("Material removido com sucesso");
                        _this.dialogRef.close({});
                    }, function (error) {
                        _this.toastr.error("Algo correu mal");
                    });
                }
            });
        }
    };
    DialogBoxCompaniesComponent.prototype.currentPageDataChange = function ($event, type) {
        if (type == "ebook") {
            this.cEbook = $event;
        }
        else {
            this.cMaterial = $event;
        }
    };
    DialogBoxCompaniesComponent.prototype.isAdmin = function () {
        return this.currentUser.user.profileId == 1 ? false : true;
    };
    return DialogBoxCompaniesComponent;
}());
export { DialogBoxCompaniesComponent };
