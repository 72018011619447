import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CategoryProductService = /** @class */ (function () {
    function CategoryProductService(http) {
        this.http = http;
    }
    CategoryProductService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl + "/CategoryProduct/");
    };
    CategoryProductService.prototype.getCategoriesByProductName = function (name) {
        return this.http.get(environment.apiUrl + "/CategoryProduct/name/" + name);
    };
    CategoryProductService.prototype.getCategoryProductListByUserId = function (id) {
        return this.http.get(environment.apiUrl + "/CategoryProduct/abc/" + id);
    };
    CategoryProductService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoryProductService_Factory() { return new CategoryProductService(i0.ɵɵinject(i1.HttpClient)); }, token: CategoryProductService, providedIn: "root" });
    return CategoryProductService;
}());
export { CategoryProductService };
