<div class="row" style="padding-left: 1%;">
    <tc-card style="width: 99%;">
        <mat-label>Selecione o Intervalo de Tempo</mat-label> <br>
        <div class="row">
            <div class="col-9">
                <mat-form-field>
                    <mat-label>Data Inicial</mat-label>
                    <input matInput [matDatepicker]="dp" [formControl]="startDt">
                    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp></mat-datepicker>
                </mat-form-field>
                <mat-form-field style="padding-left: 25px;padding-right: 25px;">
                    <mat-label>Data Final</mat-label>
                    <input matInput [matDatepicker]="dp1" [formControl]="endDt">
                    <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                    <mat-datepicker #dp1></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-3">
                <button tc-button class="btn-success" (click)="date()" [beforeIcon]="'icofont-search-1'"
                    [view]="'success'">Filtrar Data</button>
            </div>
        </div>


    </tc-card>
</div>
<tc-card>
    <div class="row" style="padding-left:2%;padding-bottom:2%">
        <div class="col-5 text-left mt-2">

        </div>
        <div class="col-3  text-right mt-2">
        </div>
        <div class="col-3  text-right mt-2">
            <button tc-button class="btn-default" (click)="export()" [beforeIcon]="'icofont-file-excel'"
                [view]="'default'">Exportar para Excel</button>
        </div>
        <div class="col-1"></div>
    </div>
    <nz-table #rowSelectionTable [nzData]="displayData" [nzBordered]="bordered" nzShowSizeChanger
        [nzPaginationPosition]="position" (nzCurrentPageDataChange)="currentPageDataChange($event)"
        style="overflow: auto;">
        <thead>
            <tr>
                <th nzShowExpand></th>
                <th>Nº Aluno</th>
                <th>Nome</th>
                <th>Email</th>
                <th>Escola</th>
                <th>Total Exames</th>
                <th>Taxa Sucesso Global</th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-data [ngForOf]="rowSelectionTable.data">
                <tr (dblclick)="openDialog('Modificar',$event)">
                    <td nzShowExpand [(nzExpand)]="data.expand"></td>
                    <td>{{ data.studentNumber }}</td>
                    <td>{{ data.name }}</td>
                    <td>{{ data.eMail }}</td>
                    <td>{{ data.school }}</td>
                    <td>{{ data.allFontNumberOfTests }}</td>
                    <td>{{ data.allFontSuccess }}</td>
                </tr>
                <tr [nzExpand]="data.expand">
                    <td></td>
                    <td colspan="7">
                        <nz-table #innerTable [nzData]="data.children" nzSize="middle" [nzShowPagination]="false">
                            <thead>
                                <tr>
                                    <th>Categoria</th>
                                    <th>Data de Atribuição</th>
                                    <th>Data Senha Ativa</th>
                                    <th>Testes Realizados AC</th>
                                    <th>Taxa Sucesso AC</th>
                                    <th>Testes Realizados IMT</th>
                                    <th>Taxa Sucesso IMT</th>
                                </tr>
                            </thead>
        <tbody>
            <tr *ngFor="let data of innerTable.data">
                <td>{{ data.categoryName }}</td>
                <td>{{ data.startDt  | date: 'dd/MM/yyyy'}}</td>
                <td>{{ data.activeDt  | date: 'dd/MM/yyyy' }}</td>
                <td>{{ data.alvesCostaNumberOfTests }}</td>
                <td>{{ data.alvesCostaSuccess }}</td>
                <td>{{ data.imtNumberOfTests }}</td>
                <td>{{ data.imtSuccess }}</td>
            </tr>
        </tbody>
    </nz-table>
    </td>
    </tr>
    </ng-template>
    </tbody>
    </nz-table>
    <label>*AC - Alves Costa</label><br>
    <label>*NA - Not Available (Não disponivel)</label>

</tc-card>