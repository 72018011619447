import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MediaService = /** @class */ (function () {
    function MediaService(http) {
        this.http = http;
    }
    MediaService.prototype.createImageToUser = function (id, url, mimeType, name, size) {
        return this.http.post(environment.apiUrl + "/Media/imageToUser/" + id, { url: url, mimeType: mimeType, name: name, size: size });
    };
    MediaService.prototype.linkImageToQuestion = function (id, url, name, size) {
        return this.http.post(environment.apiUrl + "/Media/imageToQuestion/" + id, { url: url, name: name, size: size });
    };
    MediaService.prototype.getImageByUserId = function (id) {
        return this.http.get(environment.apiUrl + "/Media/imageByUser/" + id);
    };
    MediaService.prototype.createImageToCompany = function (id, url, mimeType, name, size) {
        return this.http.post(environment.apiUrl + "/Media/imageToCompany/" + id, { url: url, mimeType: mimeType, name: name, size: size });
    };
    MediaService.prototype.createBgImageToCompany = function (id, url, mimeType, name, size) {
        return this.http.post(environment.apiUrl + "/Media/bgImageToCompany/" + id, { url: url, mimeType: mimeType, name: name, size: size });
    };
    MediaService.prototype.getImageByQuestionId = function (id) {
        return this.http.get(environment.apiUrl + "/Media/byQuestionId/" + id);
    };
    MediaService.prototype.getImagesByCompanyId = function (id) {
        return this.http.get(environment.apiUrl + "/Media/byCompanyId/" + id);
    };
    // getBackgroundByCompanyName(name: string){
    //   return this.http.get<any>(`${environment.apiUrl}/Media/byCompanyName/${name}`);
    // }
    MediaService.prototype.getRGPD = function () {
        return this.http.get(environment.apiUrl + "/Media/RGPD");
    };
    MediaService.prototype.getImageById = function (id) {
        return this.http.get(environment.apiUrl + "/Media/" + id);
    };
    MediaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MediaService_Factory() { return new MediaService(i0.ɵɵinject(i1.HttpClient)); }, token: MediaService, providedIn: "root" });
    return MediaService;
}());
export { MediaService };
