import { Component, OnInit, Optional, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ConfirmComponent implements OnInit {
    localData: any;
    text: string;
    constructor(public dialogRef: MatDialogRef<ConfirmComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.localData = { ...data };
        this.text = this.localData.text;
    }

    ngOnInit() {
    }

    closeDialog() {
        this.dialogRef.close({ event: 'Cancel' });
    }
    confirm() {
        this.dialogRef.close({ event: 'sim' });
    }

}
