<div style="width: 100%;text-align: left; height: 30px; max-height: 30px;overflow: hidden;">
  <h4 small>{{action}} {{localData.name}}</h4>
</div>


<mat-tab-group mat-align-tabs="start" class="tabContainer">
  <mat-tab label="Geral">
    <form [formGroup]="companyGeralForm">
      <tc-form-group>
        <tc-form-label>Activo</tc-form-label>
        <tc-switcher formControlName="status" [value]="localData.active">Activo</tc-switcher>
      </tc-form-group>
      <tc-form-group>
        <tc-form-label>Nome</tc-form-label>
        <tc-input [placeholder]="'Nome'" [type]="'text'" formControlName="name" [(ngModel)]="localData.name">
        </tc-input>
      </tc-form-group>
      <tc-form-group>
        <tc-form-label>Nif</tc-form-label>
        <tc-input [placeholder]="'Nif'" [type]="'text'" formControlName="nif" [(ngModel)]="localData.nif"></tc-input>
      </tc-form-group>
      <tc-form-group>
        <tc-form-label>Contactos</tc-form-label>
        <tc-textarea [placeholder]="'Contactos'" formControlName="contacts" [(ngModel)]="localData.contacts">
        </tc-textarea>
      </tc-form-group>
      <tc-form-group>
        <tc-form-label>Email</tc-form-label>
        <tc-input [placeholder]="'Email'" [type]="'email'" formControlName="email" [(ngModel)]="localData.email">
        </tc-input>
      </tc-form-group>
      <tc-form-group>
        <tc-form-label>Email instrutores</tc-form-label>
        <tc-input [placeholder]="'Email instrutores'" [type]="'email'" formControlName="emailInstrutores" [(ngModel)]="localData.emailInstrutores">
        </tc-input>
      </tc-form-group>
      <tc-form-group>
        <tc-form-label>Morada</tc-form-label>
        <tc-input [placeholder]="'Morada'" [type]="'text'" formControlName="address" [(ngModel)]="localData.address">
        </tc-input>
      </tc-form-group>
      <tc-form-group>
        <tc-form-label>Nome Plataforma</tc-form-label>
        <tc-input [placeholder]="'Nome Plataforma'" [type]="'text'" formControlName="plataform"
          [(ngModel)]="localData.plataformName"></tc-input>
        <tc-form-label>www.testesdecodigo.com/{{localData.plataformName}}</tc-form-label>
      </tc-form-group>
      <tc-form-group>
        <tc-form-label>Url do Site</tc-form-label>
        <tc-input [placeholder]="'url do site'" [type]="'text'" formControlName="url" [(ngModel)]="localData.url">
        </tc-input>
      </tc-form-group>
      <tc-form-group>
        <tc-form-label>Prefixo</tc-form-label>
        <tc-input [placeholder]="'Prefixo'" [type]="'text'" formControlName="prefix" [(ngModel)]="localData.prefix">
        </tc-input>
      </tc-form-group>
      <tc-form-group>
        <tc-form-label>Alvara</tc-form-label>
        <tc-input [placeholder]="'Alvara'" [type]="'text'" formControlName="alvara" [(ngModel)]="localData.alvara">
        </tc-input>
      </tc-form-group>
      <tc-form-group>
        <tc-form-label>Tipo de Empresa</tc-form-label>
        <mat-select formControlName="companyType" placeholder="Tipo de Empresa" class="select-box">
          <mat-option *ngFor="let state of companyTypes" [value]="state.name">
            {{ state.name }}
          </mat-option>
        </mat-select>
      </tc-form-group>
      <tc-form-group>
        <tc-form-label>Comercial</tc-form-label>
        <mat-select formControlName="comercial" placeholder="Comercial" class="select-box">
          <mat-option *ngFor="let state of comercials" [value]="state.name">
            {{ state.name }}
          </mat-option>
        </mat-select>
      </tc-form-group>
    </form>
  </mat-tab>

  <mat-tab *ngIf="currentUser.user.profileId == 1 && action != 'Adicionar'" label="Logo">
    <img class="img-profile img-circle img-responsive center-block avatar" [src]="imgURL" alt="" width="200px">
    <input #file type="file" id="file" accept='image/*' (change)="preview(file.files)" />
    <label></label>
  </mat-tab>

  <mat-tab *ngIf="currentUser.user.profileId == 1 && action != 'Adicionar'" label="Imagem de Fundo">
    <img class="img-profile img-circle img-responsive center-block avatar" [src]="bgImage" alt="" width="200px">
    <input #fileBg type="file" id="fileBg" accept='image/*' (change)="backgroundUpload(fileBg.files)" />
    <label></label>
  </mat-tab>

  <mat-tab *ngIf="action != 'Adicionar'" label="Senhas">
    <div class="" id="accordionExample">
      <div *ngFor="let items of tickets; let i = index;" class="card z-depth-0 bordered">
        <div *ngIf="items.totalQuantity != 0">
          <div style="padding: 2%;">
            <div *ngIf="items.totalQuantity != 0" class="card-header" id="headingOne">
              <div class="row" style="background-color: lightgray;padding: 5px;">
                <div class="col-2">
                  <tc-badge view="info" class="info-badge" size="sm">{{items.totalQuantity}}</tc-badge>
                </div>
                <div class="col-5">
                  <span class="fontSize">
                    Tipo:<strong>{{items.durationName}}</strong>
                  </span>
                </div>
                <div class="col-5">
                  <span class="fontSize">
                    Atribuido por: <strong>{{createdBy}}</strong>
                  </span>
                </div>
              </div>
            </div>

            <div *ngIf="items.totalQuantity != 0" id="{{'collapse'+ i }}" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample" style="padding-bottom: 5%;">
              <div class="row striped" *ngFor="let subItems of tickets[i].tickets; let j= index;" style="padding: 5px;">

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
                  {{subItems.categoryName}}
                </div>

                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 text-center" style="min-width: 20px;">
                  <tc-badge view="info" class="info-badge" rel="tooltip" matTooltip="Quantidade de senhas atribuídas" size="sm">{{subItems.quantity}}</tc-badge>
                </div>

                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 text-center" style="min-width: 20px;">
                  <tc-badge view="success" class=" success-badge" rel="tooltip" matTooltip="Senhas que faltam atribuir" size="sm">{{subItems.left}}</tc-badge>
                </div>

                <div class="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-center" style="min-width: 20px;">
                  <span class="mobile">{{subItems.ticketDate | date: 'dd/MM/yyyy'}}</span>
                </div>

                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 text-center" style="min-width: 20px;">
                  <tc-switcher *ngIf="currentUser.user.profileId == 1" [value]="subItems.active" (click)="changeStatus(i,j)"></tc-switcher>
                </div>

                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 text-center" style="min-width: 20px;">
                  <i *ngIf="currentUser.user.profileId == 1" class="icofont-close-circled" (click)="remove($event,i,j)"></i>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card z-depth-0 bordered">
      <div class="card-header" id="headingThree">
        <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapse4" style="width: 100%;font-size: 17px;" aria-expanded="false" aria-controls="collapseThree">
          Novas Senhas
        </button>
      </div>
      <div id="collapse4" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
        <div class="card-body">
          <form [formGroup]="companyTicketsForm">
            <tc-form-group>
              <tc-form-label>Quantidade</tc-form-label>
              <tc-input [placeholder]="'Quantidade'" [type]="'text'" formControlName="quantity"
                [(ngModel)]="localData.amount">
              </tc-input>
            </tc-form-group>
            <tc-form-group>
              <tc-form-label>Duração</tc-form-label>
              <mat-select formControlName="ticketType" placeholder="Duração" class="select-box">
                <mat-option *ngFor="let state of durations" [value]="state.name"> {{ state.name }}
                </mat-option>
              </mat-select>

            </tc-form-group>
            <div class="tree">
              <mat-checkbox (change)="selectAll('categoryTicket')" [disableRipple]="true">Selecione todas as categorias
              </mat-checkbox>
              <mat-tree style="text-align: left; list-style:none" [dataSource]="dataSourceTickets"
                [treeControl]="treeControl" class="example-tree">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                  <button mat-icon-button disabled></button>
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? masterToggle(node) : null"
                    [checked]="node.selected" class="checklist-leaf-node" [disableRipple]="true">{{node.name}}
                  </mat-checkbox>
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                  <li>
                    <div class="mat-tree-node">
                      <button type="button" mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'toggle ' + node.filename" [disableRipple]="true">
                        <mat-icon class="mat-icon-rtl-mirror">
                          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                      </button>
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? masterToggle(node) : null"
                        [checked]="node.selected" [disableRipple]="true">{{node.name}}
                      </mat-checkbox>
                    </div>
                    <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                      <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                  </li>
                </mat-nested-tree-node>
              </mat-tree>
            </div>
          </form>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab *ngIf="action != 'Adicionar'" label="Senhas de Material">
    <div class="" id="accordionExample2">
      <div *ngFor="let items of didacticTickets; let i = index;" class="card z-depth-0 bordered">
        <div *ngIf="items.totalQuantity != 0">
          <div style="padding: 2%;">
            <div *ngIf="items.totalQuantity != 0" class="card-header" id="headingOne">
              <div class="row" style="background-color: lightgray;padding: 5px;">
                <div class="col-2">
                  <tc-badge view="info" class="info-badge" size="sm">{{items.totalQuantity}}</tc-badge>
                </div>
                <div class="col-5">
                  <span class="fontSize">
                    Tipo: <strong>{{items.durationName}}</strong>
                  </span>
                </div>
                <div class="col-5">
                  <span class="fontSize">
                    Atribuido por: <strong>{{didacticCreatedBy}}</strong>
                  </span>
                </div>
              </div>
            </div>

            <div *ngIf="items.totalQuantity != 0" id="{{'collapse'+ i }}" class="collapse " aria-labelledby="headingOne" data-parent="#accordionExample2" style="padding-bottom: 5%;">
              <div class="row striped" *ngFor="let subItems of didacticTickets[i].tickets; let j= index;" style="padding: 5px;">
                
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
                  {{subItems.categoryName}}
                </div>
                
                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 text-center" style="min-width: 20px;">
                  <tc-badge view="info" size="sm" class="info-badge" rel="tooltip" matTooltip="Quantidade de senhas atribuídas">{{subItems.quantity}}</tc-badge>
                </div>

                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 text-center" style="min-width: 20px;">
                  <tc-badge view="success" size="sm" class="success-badge" rel="tooltip" matTooltip="Senhas que faltam atribuir">{{subItems.left}}</tc-badge>
                </div>
                
                <div class="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-center" style="min-width: 20px;">
                  {{subItems.ticketDate | date: 'dd/MM/yyyy'}}
                </div>

                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 text-center" style="min-width: 20px;">
                  <tc-switcher *ngIf="currentUser.user.profileId == 1" [value]="subItems.active" (click)="changeStatus(i, j, 'didacticTicket')"></tc-switcher>
                </div>
                
                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 text-center" style="min-width: 20px;">
                  <i *ngIf="currentUser.user.profileId == 1" class="icofont-close-circled" (click)="remove($event,i,j, 'didacticTicket')"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card z-depth-0 bordered">
        <div class="card-header" id="headingThree">
          <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapse4"
            style="width: 100%;font-size: 17px;" aria-expanded="false" aria-controls="collapseThree">
            Novas Senhas
          </button>
        </div>
        <div id="collapse4" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample2">
          <div class="card-body">
            <form [formGroup]="companyDidacticTicketsForm">
              <tc-form-group>
                <tc-form-label>Quantidade</tc-form-label>
                <tc-input [placeholder]="'Quantidade'" [type]="'text'" formControlName="quantityD"
                  [(ngModel)]="localData.amountD">
                </tc-input>
              </tc-form-group>
              <tc-form-group>
                <tc-form-label>Duração</tc-form-label>
                <mat-select formControlName="ticketType" placeholder="Duração" class="select-box">
                  <mat-option *ngFor="let state of durations" [value]="state.name">
                    {{ state.name }}
                  </mat-option>
                </mat-select>

              </tc-form-group>

              <div class="tree">
                <mat-checkbox (change)="selectAll('didacticTicket')" [disableRipple]="true">Selecionar todos
                </mat-checkbox>
                <mat-tree style="text-align: left; list-style:none" [dataSource]="dataSourceDidacticMaterial"
                  [treeControl]="treeControl" class="example-tree">
                  <!-- This is the tree node template for leaf nodes -->
                  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                    <button mat-icon-button disabled></button>
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? masterToggle(node) : null"
                      [checked]="node.selected" class="checklist-leaf-node" [disableRipple]="true">{{node.name}}
                    </mat-checkbox>
                  </mat-tree-node>
                  <!-- This is the tree node template for expandable nodes -->
                  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                    <li>
                      <div class="mat-tree-node">
                        <button type="button" mat-icon-button matTreeNodeToggle
                          [attr.aria-label]="'toggle ' + node.filename" [disableRipple]="true">
                          <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                          </mat-icon>
                        </button>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? masterToggle(node) : null"
                          [checked]="node.selected" [disableRipple]="true">{{node.name}}
                        </mat-checkbox>
                      </div>
                      <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                        <ng-container matTreeNodeOutlet></ng-container>
                      </ul>
                    </li>
                  </mat-nested-tree-node>
                </mat-tree>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  
  <mat-tab *ngIf="currentUser.user.profileId != 5 && localData.action != 'Adicionar'" label="Material">
    <div [formGroup]="fileForm">
      <tc-form-label>Upload de ficheiros</tc-form-label>
      <tc-form-group>
        <input #fileUpload formControlName="fileToUpload" id="fileUpload" type="file">
      </tc-form-group>
      <tc-form-group>
        <tc-input #fileDesc [placeholder]="'Descrição'" [type]="'text'" formControlName="fileDesc"></tc-input>
      </tc-form-group>
      <button tc-button [disabled]="fileForm.invalid" [view]="'success'" (click)="uploadFile(fileUpload.files, fileDesc.value)" style="margin: 2px;" [beforeIcon]="'icofont-diskette'">Adicionar Ficheiro</button>
      <br>
    </div>
    <nz-table #rowSelectionTable [nzData]="cMaterial" [nzBordered]="bordered" [nzPaginationPosition]="position" [nzFrontPagination]="false" style="overflow: auto; padding-top: 2%;">
      <thead nzSingleSort>
          <tr class="noClick">
              <th>Nome</th>
              <th>Descrição</th>
              <th>Tipo</th>
              <th *ngIf="currentUser.user.profileId == 1" nzWidth="25%"></th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let data of rowSelectionTable.data; let i=index;" (dblclick)="openFile(i, 'outros')">
              <td>{{data.name}}</td>
              <td>
                  <tc-input [value]="data.description" id="{{'inputValue' + i}}">
                  </tc-input>
              </td>
              <td>{{data.mimeType}}</td>
              <td *ngIf="currentUser.user.profileId == 1" style="display: flex; justify-content: space-evenly; align-items: center;">
                  <button tc-button [beforeIcon]="'icofont-diskette'" (click)="guardar(i, 'outros')" [view]="'success'"></button>
                  &nbsp;
                  <button tc-button [beforeIcon]="'icofont-close-circled'" (click)="remover(i, 'outros')" [view]="'error'"></button>
              </td>
          </tr>
      </tbody>
    </nz-table>
  </mat-tab>


  <mat-tab *ngIf="action != 'Adicionar'" label="Ebook">
    <div [formGroup]="ebookForm">
      <tc-form-group>
        <tc-form-label>Descrição</tc-form-label>
        <tc-input [placeholder]="'Descrição'" [type]="'text'" formControlName="ebookDesc"></tc-input>
      </tc-form-group>
      <tc-form-group>
        <tc-form-label>Url</tc-form-label>
        <tc-input [placeholder]="'Url'" [type]="'text'" formControlName="ebookUrl"></tc-input>
      </tc-form-group>
    </div>
    <button tc-button [disabled]="ebookForm.invalid" [view]="'success'" (click)="addEbook()" style="margin: 2px;" [beforeIcon]="'icofont-diskette'">Adicionar Ebook</button>
    
    <nz-table #rowSelectionTable [nzData]="cEbook" [nzBordered]="bordered" [nzPaginationPosition]="position" [nzFrontPagination]="false"
      style="overflow: auto; padding-top: 2%;">
      <thead nzSingleSort>
        <tr class="noClick">
          <th nzWidth="75%">Descrição</th>
          <th *ngIf="currentUser.user.profileId == 1" nzWidth="25%"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of rowSelectionTable.data; let i=index;" (dblclick)="openFile(i, 'ebook')">
          <td nzWidth="75%">
            <tc-input [value]="data.description" id="{{'input' + i}}" style="min-width: 170px;"></tc-input>
          </td>
          <td nzWidth="25%" *ngIf="currentUser.user.profileId == 1" style="display: flex; justify-content: space-evenly; align-items: center;">
            <button tc-button [beforeIcon]="'icofont-diskette'" (click)="guardar(i, 'ebook')" [view]="'success'"></button>
            &nbsp;
            <button tc-button [beforeIcon]="'icofont-close-circled'" (click)="remover(i, 'ebook')" [view]="'error'"></button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </mat-tab>

</mat-tab-group>


<mat-dialog-actions layout="row" style="height: 60px;">
  <button tc-button [view]="'success'" (click)="onSubmitGeral()" style="margin: 2px;" [beforeIcon]="'icofont-diskette'">Gravar</button>
  <button tc-button [view]="'warning'" (click)="closeDialog()" style="margin: 2px;" [beforeIcon]="'icofont-close-circled'">Cancelar</button>
</mat-dialog-actions>
