<div class="col col-12 col-md-12 mb-12 mb-md-12">
    <tc-card class="mb-0 custom">
        <div class="row">
            <div class="container">
                <p>Escolha a categoria do teste</p>
                <ul class="list-group" style="padding: 0%;">
                    <li class="form-control" *ngFor="let item of categories; let i = index; " [ngClass]="{'active': activeElement == 1}" (click)="select($event,i)">{{item.categoryName}}</li>
                    <p *ngIf="categories.length == 0"> Não existem senhas ativas, consulte a sua escola para obter senhas.</p>
                </ul>
            </div>
        </div>
    </tc-card>
</div>

