<tc-card>
  <div class="row">
    <div class="col-md-2" style="text-align: center;">
      <h6><strong>Contactos:</strong></h6>
    </div>
    <div class="col-10">
      <h6>{{contacts}}<br><sup>(Rede fixa nacional ou rede móvel nacional)</sup></h6><br>
    </div>
  </div>
</tc-card>
<li class="form-control li" (click)="openDialog(undefined,undefined,undefined,undefined)" style="text-align: center;"><strong>Enviar Pedido</strong></li>
<br>

<tc-card>
  <nz-table #userRequests [nzData]="mails" [nzFrontPagination]="true" style="overflow: auto;"
    (nzCurrentPageDataChange)="true">
    <thead nzSingleSort class="noClick">
      <tr>
        <th class="centerTableHeader">Tipo</th>
        <th class="centerTableHeader">Data</th>
        <th class="centerTableHeader">Respondido</th>
        <th class="centerTableHeader">Responder</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of userRequests.data">
        <td>{{data.subject}}</td>
        <td class="centerTableHeader">{{data.sendedDt | date:'dd/MM/yyyy HH:mm'}}</td>
        <td class="">
          <tc-badge *ngIf="data.responded == true" [view]="'success'" [tcShape]="0"
            style="width: 100%; text-align: center; justify-content: center;">respondido</tc-badge>
          <tc-badge *ngIf="data.responded == false" [view]="'warning'" [tcShape]="0"
            style="width: 100%; text-align: center; justify-content: center;">por&nbsp;responder</tc-badge>
        </td>
        <td class="">
          <button *ngIf="data.responded == true" tc-button [view]="'success'" [beforeIcon]="'icofont-eye'"
            (click)="openDialog(data.subject, data.responded, data.response, data.content)"
            type="button" style="width: 100%;">Ver</button>
            <button *ngIf="data.responded == false" tc-button [view]="'warning'" [beforeIcon]="'icofont-clock-time'"
            (click)="openDialog(data.subject, data.responded, data.response, data.content)"
            type="button" style="width: 100%;">Aguarde</button>
        </td>
      </tr>
    </tbody>
  </nz-table>
</tc-card>
<!--
<div class="tab" *ngIf="false">
  <button class="tablinks" [ngClass]="{'active':selectedTab == 'Enviadas'}" (click)="openCity($event, 'Enviadas')">Enviadas</button>
  <button class="tablinks" [ngClass]="{'active':selectedTab == 'Recebidas'}" (click)="openCity($event, 'Recebidas')">Recebidas</button>
</div>
<div id="Enviadas" class="tabcontent tabs" style="display: block;">
  <div *ngIf="mails.length == 0">
    <br>
    <label>Não existem mensagens</label>
  </div>
  <div *ngIf="mails.length > 0">
    <div class="row" style="padding-left:2%;padding-bottom:2%;padding-top: 2%;" *ngIf="false">
      <div class="col-2">
        <button tc-button class="btn-default" (click)="openFilter()" [beforeIcon]="'icofont-search-document'"
          [view]="'default'">Filtro</button>
      </div>
      <div class="col-6"></div>
      <div class="col-4" style=" padding-left: 7%;">
      </div>

    </div>
    <div style="border:1px solid gray; border-radius: 10px; background-color: aliceblue;margin-bottom:2%;" id="filtro"
      class="hide">
      <div class="row" style="padding-top: 1%;" *ngIf="false">
        <div class="col-9" style="margin-right: -7%; padding-left: 3%;">
          <div class="row">
            <div class="col-11">
              <tc-form-group class="tc-form-group" style="padding-top: 1%;">
                <tc-input [(ngModel)]="inputValue" placeholder="Filtre por Utilizador" class="custom-shape tc-input"
                  style="border-radius: 6px;margin-right:6%">

                </tc-input>
              </tc-form-group>
            </div>
          </div>
        </div>
        <div class="col-1" style="padding-top: 6px;">
          <tc-dropdown-button>
            <button [outline]="true" (click)="inputSubmit()" tc-button>Go</button>
          </tc-dropdown-button>

        </div>
        <div class="col-2" style="padding-top: 6px;">
          <tc-dropdown class="item" [close]="closeDropdown">
            <tc-dropdown-button>
              <button [afterIcon]="'icofont icofont-simple-down'" [outline]="true" tc-button>Mostrar</button>
            </tc-dropdown-button>

            <tc-dropdown-content [align]="'right'" [animation]="'fadeInUp'" [offset]="[10, 0]" [width]="180">
              <ul class="list">
                <li class="px-2 item">
                  <a (click)="show('show','all')" class="item-link align-items-center item-content">
                    Todas as mensagens
                  </a>
                </li>
                <li class="px-2 item">
                  <a (click)="show('show','responded')" class="item-link align-items-center item-content">
                    Todas as mensagens respondidas
                  </a>
                </li>
                <li class="px-2 item">
                  <a (click)="show('show','unResponded')" class="item-link align-items-center item-content">
                    Todas as mensagens por Responder
                  </a>
                </li>
              </ul>
            </tc-dropdown-content>
          </tc-dropdown>
        </div>
      </div>
    </div>
    <tc-card *ngFor="let item of mails"
      class="{{item.responded == true ? 'responded' : 'unResponded'}} all {{item.userName}}">
      <tc-form-group>
        <tc-form-label>Tipo: {{item.subject}}</tc-form-label>
        <tc-form-label>Data: {{item.sendedDt | date:'dd/MM/yyyy HH:mm'}}</tc-form-label>
        <tc-form-label>Dúvida:</tc-form-label>
        <p [innerHTML]="utils.transform(item.content)"></p>
      </tc-form-group>
      <tc-form-group *ngIf="item.responded == true">
        <tc-form-label>Resposta:</tc-form-label>
        <p [innerHTML]="utils.transform(item.response)"></p>
      </tc-form-group>
      <div class="row">
        <div class="col-12 col-sm-6">

            <tc-badge *ngIf="item.responded == true" [view]="'success'" [tcShape]="0">respondido</tc-badge>
            <tc-badge *ngIf="item.responded == false" [view]="'error'" [tcShape]="0">por&nbsp;responder</tc-badge>

          button *ngIf="item.responded == false" tc-button [view]="'success'" [beforeIcon]="'icofont-paper-plane'"
            (click)="openDialog(item.userId, item.alertMailId)" type="button">Responder
          </button>
          <tc-badge *ngIf="item.responded == true" [view]="'success'" [tcShape]="0">respondido</tc-badge
        </div>
      </div>
    </tc-card>
    <div *ngIf="mails == null" class="form-control" style="border-radius: 10px;">
      <h4 style="text-align: center;">Não existem mensagens para este utilizador</h4>
    </div>
  </div>
</div>

<div id="Recebidas" class="tabcontent tabs" *ngIf="false">
  <div *ngIf="responded.length == 0">
    <br>
    <label>Não existem mensagens</label>
  </div>
  <div *ngIf="responded.length > 0">
    <div class="row" style="padding-left:2%;padding-bottom:2%;padding-top: 2%;">
      <div class="col-2">
        <button tc-button class="btn-default" (click)="openFilter()" [beforeIcon]="'icofont-search-document'"
          [view]="'default'">Filtro</button>
      </div>
      <div class="col-6"></div>
      <div class="col-4" style=" padding-left: 7%;">
      </div>

    </div>
    <div style="border:1px solid gray; border-radius: 10px; background-color: aliceblue;margin-bottom:2%;" id="filtro"
      class="hide">
      <div class="row" style="padding-top: 1%;">
        <div class="col-9" style="margin-right: -7%; padding-left: 3%;">
          <div class="row">
            <div class="col-11">
              <tc-form-group class="tc-form-group" style="padding-top: 1%;">
                <tc-input [(ngModel)]="inputValue" placeholder="Filtre por Utilizador" class="custom-shape tc-input" style="border-radius: 6px;margin-right:6%"></tc-input>
              </tc-form-group>
            </div>
          </div>
        </div>
        <div class="col-1" style="padding-top: 6px;">
          <tc-dropdown-button>
            <button [outline]="true" (click)="inputSubmit()" tc-button>Go</button>
          </tc-dropdown-button>
        </div>
        <div class="col-2" style="padding-top: 6px;">
          <tc-dropdown class="item" [close]="closeDropdown">
            <tc-dropdown-button>
              <button [afterIcon]="'icofont icofont-simple-down'" [outline]="true" tc-button>Mostrar</button>
            </tc-dropdown-button>

            <tc-dropdown-content [align]="'right'" [animation]="'fadeInUp'" [offset]="[10, 0]" [width]="180">
              <ul class="list">
                <li class="px-2 item">
                  <a (click)="show('show','all')" class="item-link align-items-center item-content">Todas as mensagens</a>
                </li>
                <li class="px-2 item">
                  <a (click)="show('show','responded')" class="item-link align-items-center item-content">Todas as mensagens respondidas</a>
                </li>
                <li class="px-2 item">
                  <a (click)="show('show','unResponded')" class="item-link align-items-center item-content">Todas as mensagens por Responder</a>
                </li>
              </ul>
            </tc-dropdown-content>
          </tc-dropdown>
        </div>
      </div>
    </div>
    <tc-card *ngFor="let item of responded"
      class="{{item.responded == true ? 'responded' : 'unResponded'}} all {{item.userName}}"
      [title]="'Dúvida solicitada pelo utilizador:      '+ item.userName ">
      <tc-form-group>
        <tc-form-label>Data: {{item.sendedDt}}</tc-form-label>
        <tc-form-label>Dúvida:</tc-form-label>
        <tc-textarea [value]="'' + item.content" disabled="true"></tc-textarea>
      </tc-form-group>
      <tc-form-group *ngIf="item.responded == true">
        <tc-form-label>Resposta:</tc-form-label>
        <tc-textarea [value]="'' + item.response" disabled="true"></tc-textarea>
      </tc-form-group>
      <div class="row">
        <div class="col-12 col-sm-6">
          <button *ngIf="item.responded == false" tc-button [view]="'success'" [beforeIcon]="'icofont-paper-plane'"
            (click)="openDialog(item.userId, item.alertMailId)" type="button">
            Responder
          </button>
          <tc-badge *ngIf="item.responded == true" [view]="'success'" [tcShape]="0">respondido</tc-badge>
        </div>
      </div>
    </tc-card>
    <div *ngIf="mails == null" class="form-control" style="border-radius: 10px;">
      <h4 style="text-align: center;">Não existem mensagens para este utilizador</h4>
    </div>
  </div>
</div>
<br/> -->