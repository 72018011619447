<div class="row" style="padding-left:2%;padding-bottom:2%;padding-top: 2%;">
  <div class="col-2">
    <button tc-button class="btn-default" (click)="openFilter()" [beforeIcon]="'icofont-search-document'"
      [view]="'default'">Filtro</button>
  </div>
  <div class="col-6"></div>
  <div class="col-4" style=" padding-left: 7%;">
  </div>
</div>
<div style="border:1px solid #ccd3d9; border-radius: 10px; background-color: aliceblue;margin-bottom:2%;" id="filtro" class="hide">
  <div class="row" style="padding-top: 1%;">
    <div class="col-2" style="padding-top: 6px;padding-left:5%;">
      <!-- <tc-dropdown class="item" [close]="closeDropdown">
        <tc-dropdown-button>
          <button [afterIcon]="'icofont icofont-simple-down'" [outline]="true" tc-button>Filtrar</button>
        </tc-dropdown-button>

        <tc-dropdown-content>
          <tc-list class="px-4">
            <tc-list-item>
              <tc-dropdown class="item" [close]="closeDropdown">
                <tc-dropdown-button>
                  <span tc-list-line><a>Todos</a></span>
                </tc-dropdown-button>
                <tc-dropdown-content>
                  <tc-list class="px-4">
                    <tc-list-item *ngFor="let elem of schools">
                      <span tc-list-line (click)="filter('all',elem.name)"><a>{{elem.name}}</a></span>
                    </tc-list-item>
                  </tc-list>
                </tc-dropdown-content>
              </tc-dropdown>
            </tc-list-item>
            <tc-list-item>
              <tc-dropdown class="item" [close]="closeDropdown">
                <tc-dropdown-button>
                  <span tc-list-line><a>Respondidas</a></span>
                </tc-dropdown-button>
                <tc-dropdown-content>
                  <tc-list class="px-4">
                    <tc-list-item *ngFor="let elem of schools">
                      <span tc-list-line (click)="filter('responded',elem.name)"><a>{{elem.name}}</a></span>
                    </tc-list-item>
                  </tc-list>
                </tc-dropdown-content>
              </tc-dropdown>
            </tc-list-item>

            <tc-list-item>
              <tc-dropdown class="item" [close]="closeDropdown">
                <tc-dropdown-button>
                  <span tc-list-line><a>Por Responder</a></span>
                </tc-dropdown-button>
                <tc-dropdown-content>
                  <tc-list class="px-4">
                    <tc-list-item *ngFor="let elem of schools">
                      <span tc-list-line (click)="filter('unResponded',elem.name)"><a>{{elem.name}}</a></span>
                    </tc-list-item>
                  </tc-list>
                </tc-dropdown-content>
              </tc-dropdown>
            </tc-list-item>
          </tc-list>
        </tc-dropdown-content>
      </tc-dropdown> -->
      <button tc-button class="btn-default" [beforeIcon]="'icofont-ui-reply'" (click)="reset()"
      [view]="'default'">Reset</button>
    </div>
    <!-- <div class="col-2" *ngIf="!isAdmin()" style="padding-top: 6px;padding-left:5%;">
      &nbsp;
    </div> -->
    <div class="col-7">
      <div class="row">
        <div class="col-12">
          <tc-form-group class="tc-form-group" style="padding-top: 1%;"> 
            <tc-input [(ngModel)]="inputValue" placeholder="Filtre por Utilizador" class="custom-shape tc-input" style="border-radius: 6px;margin-right:6%"></tc-input>
          </tc-form-group>
        </div>
        <!-- <div class="col-1 oii" (click)="inputSubmit()" style="height: 45px;">
          <a style="padding-top: 1%;"> <strong>GO</strong> </a>
        </div> -->

      </div>
    </div>
    <div class="col-1" style="padding-top: 6px;">
      <tc-dropdown-button>
        <button [outline]="true" (click)="filterSuportTableByUser()" tc-button>Go</button>
      </tc-dropdown-button>

    </div>
    <div class="col-2" style="padding-top: 6px;">
      <tc-dropdown class="item" [close]="closeDropdown">
        <tc-dropdown-button>
          <button [afterIcon]="'icofont icofont-simple-down'" [outline]="true" tc-button>Mostrar</button>
        </tc-dropdown-button>
        <tc-dropdown-content [align]="'right'" [animation]="'fadeInUp'" [offset]="[10, 0]" [width]="180">
          <ul class="list">
            <li class="px-2 item">
              <a (click)="filterSuportTable('')" class="item-link align-items-center item-content">
                Todas as mensagens
              </a>
            </li>
            <li class="px-2 item">
              <a (click)="filterSuportTable('true')" class="item-link align-items-center item-content">
                Todas as mensagens respondidas
              </a>
            </li>
            <li class="px-2 item">
              <a (click)="filterSuportTable('false')" class="item-link align-items-center item-content">
                Todas as mensagens por Responder
              </a>
            </li>
          </ul>
        </tc-dropdown-content>
      </tc-dropdown>
    </div>
  </div>
</div>

<tc-card>
<nz-table #userRequests [nzData]="mails" [nzFrontPagination]="true"
   style="overflow: auto;" (nzCurrentPageDataChange)="true">
    <thead nzSingleSort class="noClick">
      <tr>
        <th class="centerTableHeader">Utilizador</th>
        <th class="centerTableHeader">Empresa</th>
        <th class="centerTableHeader">Tipo</th>
        <th class="centerTableHeader">Data</th>
        <th class="centerTableHeader">Respondido</th>
        <th class="centerTableHeader">Responder</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of userRequests.data">
      <td class="centerTableHeader">{{data.userName}}</td>
        <td class="centerTableHeader">{{data.companyName}}</td>
        <td class="centerTableHeader">{{data.subject}}</td>
        <td class="centerTableHeader">{{data.sendedDt | date:'dd/MM/yyyy HH:mm'}}</td>
      <td class="">
          <tc-badge *ngIf="data.responded == true" [view]="'success'" [tcShape]="0" style="width: 100%; text-align: center; justify-content: center;">respondido</tc-badge>
          <tc-badge *ngIf="data.responded == false" [view]="'error'" [tcShape]="0" style="width: 100%; text-align: center; justify-content: center;">por&nbsp;responder</tc-badge>
        </td>
        <td class="">
          <button *ngIf="data.responded == true" tc-button [view]="'success'" [beforeIcon]="'icofont-eye'"
            (click)="openDialog(data.userId, data.alertMailId, data.responded, data.response, data.content)" type="button" style="width: 100%;">Ver</button>
          <button *ngIf="data.responded == false" tc-button [view]="'error'" [beforeIcon]="'icofont-paper-plane'"
            (click)="openDialog(data.userId, data.alertMailId, data.responded, '', data.content)" type="button" style="width: 100%;">Responder</button>
        </td>
      </tr>
    </tbody>
  </nz-table>
</tc-card>

<!-- <tc-card *ngFor="let item of mails"
  class="{{item.responded == true ? 'responded' : 'unResponded'}} {{item.companyName | trim}} all {{item.userName}}"
  [title]="'Dúvida solicitada pelo utilizador: ' + item.userName + ' da empresa: '+ item.companyName">
  <tc-form-group>
    <tc-form-label>Tipo: {{item.subject}}</tc-form-label>
    <tc-form-label>Data: {{item.sendedDt | date:'dd/MM/yyyy HH:mm'}}</tc-form-label>
    <tc-form-label>Dúvida:</tc-form-label>
    <p [innerHTML]="utils.transform(item.content)"></p>
  </tc-form-group>
  <tc-form-group *ngIf="item.responded == true">
    <tc-form-label>Resposta:</tc-form-label>
    <p [innerHTML]="utils.transform(item.response)"></p>
  </tc-form-group>
  <div class="row">

    <div class="col-3">
      <tc-badge *ngIf="item.responded == true" [view]="'success'" [tcShape]="0">respondido</tc-badge>
      <tc-badge *ngIf="item.responded == false" [view]="'error'" [tcShape]="0">por&nbsp;responder</tc-badge>
    </div>
    <div class="col-6"></div>
    <div class="col-3">
      <button *ngIf="item.responded == false" tc-button [view]="'success'" [beforeIcon]="'icofont-paper-plane'"
        (click)="openDialog(item.userId, item.alertMailId)" type="button">
        Responder
      </button></div>
  </div>
</tc-card> -->