import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SessionService = /** @class */ (function () {
    function SessionService(http) {
        this.http = http;
    }
    SessionService.prototype.getLastInteration = function (id) {
        return this.http.get(environment.apiUrl + "/session/getLastInteration/" + id);
    };
    SessionService.prototype.isLogged = function (id) {
        return this.http.post(environment.apiUrl + "/session/isLogged/" + id, { id: id });
    };
    SessionService.prototype.updateLastInteration = function (id) {
        return this.http.post(environment.apiUrl + "/session/updateLastInteration/" + id, { id: id });
    };
    SessionService.prototype.killSession = function (id) {
        return this.http.post(environment.apiUrl + "/session/killSession/" + id, { id: id });
    };
    SessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.ɵɵinject(i1.HttpClient)); }, token: SessionService, providedIn: "root" });
    return SessionService;
}());
export { SessionService };
