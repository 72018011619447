<div class="bg" [style.background-image]="'url('+bgImage+')'"></div>

<div class="content-box">
  <div class="content-header">
    <div class="logo-wrap" style="margin: auto;"><a href="{{url}}" target="_blank">
        <img [src]="logo || ''" class="logo-img">
      </a>
    </div>
  </div>

  <div class="content-body">
    <router-outlet></router-outlet>
  </div>
  <div class="content-footer">

  </div>
</div>