import { Component, OnInit, Optional, Inject, Input, ViewEncapsulation, HostListener, Pipe, ChangeDetectorRef,  ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { User } from '../../../models/user';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { WizardComponent } from '../Tests/wizard/wizard.component';
import { AuthService } from '../../../services/auth.service';
import { TestService } from '../../../services/test.service';
import { first } from 'rxjs/internal/operators/first';
import { FormControl } from '@angular/forms';
import { DialogBoxZoomComponent } from '../DialogBoxes/dialog-box-zoom/dialog-box-zoom.component';
import { BlockTemplateComponent } from '../block-template/block-template.component';
import { Subject } from 'rxjs/internal/Subject';
import { DomSanitizer } from '@angular/platform-browser';
import { QuestionHelpComponent } from '../DialogBoxes/questionHelp/questionHelp.component';
import { Utils } from 'src/utils/utils';

@Component({
  selector: 'app-show-test',
  templateUrl: './show-test.component.html',
  styleUrls: ['./show-test.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default
})

export class ShowTestComponent implements OnInit {
  currentUser: User;
  localData: any;
  questions: any[] = [];
  selectedTab: number = 0;
  @Input() selectedIndex: number | null;
  minutesLeft: number;
  action: string;
  profile: any[];
  currentName;
  selected = new FormControl(0);
  selectedThemes: number[] = [];
  blockTemplate = BlockTemplateComponent;
  numberOfQuestions: number;
  dashboard = true;
  nif: string = ''
  public destroyed = new Subject<any>();
  
  constructor(public dialog: MatDialog, private testService: TestService, private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<WizardComponent>, private authenticationService: AuthService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public utils: Utils,
    private cd: ChangeDetectorRef,
    private elRef:ElementRef) {
    this.localData = { ...data };

    console.log(this.localData);

    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

    let va = null;

    if(this.localData.name)
    {
      va = this.localData.name.split(' ');
    }
    else{
      va = this.currentUser.user.name.split(' ');
    }

    if(this.localData.nif)
    {
      this.nif = this.localData.nif;
    }
    else{
      if(this.currentUser.user.nif)
        this.nif = this.currentUser.user.nif.toString();
      else
        this.nif = '';
    }

    if (this.localData.name != undefined && this.localData.name != null) {
      this.dashboard = false;
    }

    if (this.localData.dashboard == true || this.dashboard == true) {
      if (va.length > 1) {
        this.currentName = va[0] + ' ' + va[va.length - 1];
      } else this.currentName = va[0];
    } else {
      if (va.length > 1) {
        this.currentName = va[0] + ' ' + va[va.length - 1];
      } else this.currentName = va[0];
    }

    this.testService.getQuestionsByTestId(this.localData.testId).pipe(first()).subscribe(questions => {
      this.questions = questions;
      this.numberOfQuestions = questions.length;
      const tabList = document.getElementsByClassName('mat-tab-list')[0];
      

      if (this.localData.dashboard === true) {       
        const index: number = this.questions.findIndex(d => d['questionId'] === this.localData.questionId);
        this.selected.setValue(index);
        this.selectedIndex = index;
      }     
    });
  }

  ngOnInit() {
  }



  //@HostListener('mouseover')
  refreshTabs() {
    let i = 0;

    this.questions.forEach(element => {

      let tabGroupIndex = this.elRef.nativeElement.querySelectorAll('.Dummy .mat-tab-body')[0].id.split('-')[3];
     
      //console.log("mat-tab-label-" + tabGroupIndex + "-" + i);

      const tab = this.elRef.nativeElement.querySelectorAll("#mat-tab-label-" + tabGroupIndex + "-" + i)[0];

      //console.log(tab);


      if (element.answerSelected == undefined || element.answerSelected == null) {
        element.answerSelected = -1;
        tab.classList.add('wrongTab');
      }
      else {
        if (element.answerSelected == element['answerCorrect']) 
          tab.classList.add('correctTab');
        else 
          tab.classList.add('wrongTab');
      }
      i++;
    });
  }

  indexChange(event) {
    //console.log("indexChange");
    //console.log(event);
    this.selected.setValue(event);
  }

  selectTab(id) {
    //console.log('selected');
    this.refreshTabs();
  }

  zoom() {
    const el = document.getElementById("imagem");
    let row: any = {};
    row.src = el.getAttribute("src");
    let dialogRef = this.dialog.open(DialogBoxZoomComponent, {
      id: "zoom",
      data: row,
      width: 'auto',
      height: 'auto',
      maxHeight: "97vh",
      maxWidth: "97vw"
    });
  }

  close() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  sanitizeUrl(img) {
    let i = 'data:image/jpg;base64,' + img;
    return this.sanitizer.bypassSecurityTrustResourceUrl(i);

  }

  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  swipe(selectedIndex: number, action = this.SWIPE_ACTION.RIGHT) {
      console.log("swipe");
      console.log("number",this.selected.value);
      console.log(action);
      console.log(this.questions.length - 1);
      
      // Out of range
      if (this.selected.value < 0 || this.selected.value > (this.questions.length - 1)) return;
      
      // Swipe left, next tab
      if (action === this.SWIPE_ACTION.LEFT) {
          const isLast = this.selected.value === (this.questions.length - 1);
          console.log("isLast", isLast);
          this.selected.setValue(isLast ? 0 : this.selected.value + 1);
          console.log("Swipe right - INDEX: " + this.selected.value);
      }

      // Swipe right, previous tab
      if (action === this.SWIPE_ACTION.RIGHT) {
          const isFirst = this.selected.value === 0;
          console.log("isFirst", isFirst);
          this.selected.setValue(isFirst ? 1 : this.selected.value - 1);
          console.log("Swipe left - INDEX: " + this.selected.value);
      }
  }

 openHelp() {
    let width = this.utils.isMobileDevice() ? "90vw" : "30vw";
    let maxWidth = this.utils.isMobileDevice() ? "99vw" : "30vw";

    let dialogRef = this.dialog.open(QuestionHelpComponent, {
      data: this.questions[this.selected.value]["help"],
      width: width,
      height: 'auto',
      disableClose: false,
      closeOnNavigation: true,
      maxWidth: maxWidth,
      maxHeight: "80vh",
    });
  }
}

