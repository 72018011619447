var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../../base-page';
import { CategoryService } from '../../../../services/category.service';
import { ConfirmComponent } from '../../DialogBoxes/confirm/confirm.component';
import { DialogBoxCategoriesComponent } from '../../DialogBoxes/dialog-box-categories/dialog-box-categories.component';
import { HttpService } from '../../../../services/http/http.service';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';
var CategoriesComponent = /** @class */ (function (_super) {
    __extends(CategoriesComponent, _super);
    function CategoriesComponent(store, httpSv, categoryService, dialog, toastr) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.categoryService = categoryService;
        _this.dialog = dialog;
        _this.toastr = toastr;
        _this.bordered = true;
        _this.position = 'bottom';
        _this.searchValue = '';
        _this.pageSize = 10;
        _this.listOfDisplayData = [];
        _this.listOfAllData = [];
        _this.list = [];
        _this.currentPage = 0;
        _this.sortName = null;
        _this.sortValue = null;
        _this.getData(environment.apiUrl + "/Category/", 'tableData', 'setLoaded');
        _this.categoryService.getAll().pipe(first()).subscribe(function (categories) {
            _this.listOfAllData = categories;
            _this.list = categories;
        });
        return _this;
    }
    CategoriesComponent.prototype.currentPageDataChange = function ($event) {
        this.listOfDisplayData = $event;
    };
    CategoriesComponent.prototype.sort = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.listOfAllData = this.list;
        this.listOfDisplayData = this.list;
        this.search();
    };
    CategoriesComponent.prototype.reset = function () {
        this.searchValue = '';
        this.listOfAllData = this.list;
        this.search();
    };
    CategoriesComponent.prototype.select = function (event) {
        var path = event.path || (event.composedPath && event.composedPath());
        if (path[1].className.match('active')) {
            path[1].classList.remove('active');
        }
        else {
            path[1].classList.add('active');
        }
    };
    CategoriesComponent.prototype.search = function () {
        var _this = this;
        var filterFunc = function (item) {
            return (item['name'].toLowerCase().indexOf(_this.searchValue.toLowerCase()) !== -1);
        };
        var data = this.list.filter(function (item) { return filterFunc(item); });
        this.listOfAllData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    CategoriesComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    CategoriesComponent.prototype.removeSelectedRows = function () {
        var _this = this;
        var row = {};
        row.text = 'Confirma a remoção da(s) categoria(s) selecionada(s)?';
        var dialogRef = this.dialog.open(ConfirmComponent, {
            id: 'confirm',
            data: row
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === undefined) { }
            else if (result.event == 'sim') {
                var e = document.querySelectorAll('tr');
                e.forEach(function (el) {
                    if (el.className.match('active')) {
                        var index = _this.listOfAllData.findIndex(function (d) { return d['name'].trim() === el.children[0].lastChild.nodeValue.trim(); });
                        _this.categoryService.deleteCategory(_this.listOfAllData[index]['categoryId']).subscribe(function (data) {
                            _this.categoryService.getAll().subscribe(function (x) {
                                _this.listOfAllData = x;
                            });
                            _this.toastr.success('Categoria(s) removida(s) com sucesso!');
                        }, function (err) {
                            _this.toastr.error('Algo ocorreu mal!');
                        });
                    }
                });
            }
        });
    };
    CategoriesComponent.prototype.openDialog = function (action, e, i) {
        var _this = this;
        if (e == null || !e.target.className.match('ant-table-column-sorters') && !e.target.className.match('ant-spin-container')) {
            var path = e == null ? '' : e.path || (e.composedPath && e.composedPath());
            var row = {};
            row.action = action;
            row.id = e == null ? '' : this.listOfAllData[(this.currentPage * this.pageSize + i)]['categoryId'];
            row.name = e == null ? '' : path[1].children[0].innerText;
            var dialogRef = this.dialog.open(DialogBoxCategoriesComponent, {
                width: '99%',
                maxWidth: '850px',
                height: 'auto',
                data: row,
                disableClose: true,
                closeOnNavigation: true
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result === undefined) { }
                else if (result.event == 'Adicionar') {
                    _this.addRowData(result.data);
                }
                else if (result.event == 'Modificar') {
                    _this.updateRowData(result.data);
                }
            });
        }
    };
    CategoriesComponent.prototype.addRowData = function (rowObj) {
        var _this = this;
        this.categoryService.createCategory(rowObj.name, rowObj.productId).subscribe(function (data) {
            _this.categoryService.getAll().subscribe(function (x) {
                _this.listOfAllData = x;
            });
        });
    };
    CategoriesComponent.prototype.updateRowData = function (rowObj) {
        var _this = this;
        this.categoryService.updateCategory(rowObj.id, rowObj.name, rowObj.productId).subscribe(function (data) {
            _this.categoryService.getAll().subscribe(function (x) {
                _this.listOfAllData = x;
            });
        });
    };
    CategoriesComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    CategoriesComponent.prototype.custom = function (i) {
        this.currentPage = i - 1;
    };
    return CategoriesComponent;
}(BasePageComponent));
export { CategoriesComponent };
