import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var QuestionOptionService = /** @class */ (function () {
    function QuestionOptionService(http) {
        this.http = http;
    }
    QuestionOptionService.prototype.getByQuestionId = function (id) {
        return this.http.get(environment.apiUrl + "/QuestionOption/question/" + id);
    };
    QuestionOptionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QuestionOptionService_Factory() { return new QuestionOptionService(i0.ɵɵinject(i1.HttpClient)); }, token: QuestionOptionService, providedIn: "root" });
    return QuestionOptionService;
}());
export { QuestionOptionService };
