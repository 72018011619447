import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlertMailService {

  constructor(private http: HttpClient) { }

  sendRequest(id: number, subject: string, requestType: string, body:string){
    return this.http.post<any>(`${environment.apiUrl}/AlertMail/sendRequest/${id}`, {subject, requestType, body});
  }
  getRequests(id: number, profileId: number){
    return this.http.get<any[]>(`${environment.apiUrl}/AlertMail/getRequests/${id}/${profileId}`);

  }
  getRequestsByProfile(id: number, profileId: number){
    return this.http.get<any[]>(`${environment.apiUrl}/AlertMail/getRequestsByProfile/${id}/${profileId}`);
    
  }
  sendTeacherRequest(id: number,alertMailId: number, Subject: string, Body:string){
    return this.http.post<any>(`${environment.apiUrl}/AlertMail/sendTeacherRequest/${id}`, {alertMailId, Subject, Body});
  }

  getRequestsByUser(id: number){
    return this.http.get<any[]>(`${environment.apiUrl}/AlertMail/getRequestsByUser/${id}`);
  }
  getRespondedRequestsByUser(id: number){
    return this.http.get<any[]>(`${environment.apiUrl}/AlertMail/getRespondedRequestsByUser/${id}`);
  }
  
  sendAccountInfo(id: number){
    return this.http.post<any>(`${environment.apiUrl}/AlertMail/sendAccountInfo/${id}`, {});
  }
}
