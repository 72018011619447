import { OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { HttpService } from '../../../services/http/http.service';
import * as SettingsActions from '../../../store/actions/app-settings.actions';
import { AuthService } from '../../../services/auth.service';
import { MenuService } from '../../../services/menu.service';
var MenuComponent = /** @class */ (function () {
    function MenuComponent(httpSv, store, router, elementRef, authenticationService, menuService) {
        var _this = this;
        this.httpSv = httpSv;
        this.store = store;
        this.router = router;
        this.elementRef = elementRef;
        this.authenticationService = authenticationService;
        this.menuService = menuService;
        this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        this.menuService.getAll(this.currentUser.user.profileId).pipe(first()).subscribe(function (menu) {
            _this.menuItems = menu;
        });
    }
    Object.defineProperty(MenuComponent.prototype, "app", {
        get: function () {
            return this.orientation === 'app';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuComponent.prototype, "vertical", {
        get: function () {
            return this.orientation === 'vertical';
        },
        enumerable: true,
        configurable: true
    });
    MenuComponent.prototype.ngOnInit = function () {
    };
    MenuComponent.prototype.closeSidebar = function () {
        this.store.dispatch(new SettingsActions.SidebarState(false));
    };
    MenuComponent.prototype.goRoute = function (url) {
        this.router.navigate(['/app/', url]);
        this.closeSidebar();
    };
    return MenuComponent;
}());
export { MenuComponent };
