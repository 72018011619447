import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MenuService = /** @class */ (function () {
    function MenuService(http) {
        this.http = http;
    }
    MenuService.prototype.getAll = function (profileId) {
        // console.log(profileId);
        return this.http.get(environment.apiUrl + "/menu/byRole/" + profileId);
    };
    MenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MenuService_Factory() { return new MenuService(i0.ɵɵinject(i1.HttpClient)); }, token: MenuService, providedIn: "root" });
    return MenuService;
}());
export { MenuService };
