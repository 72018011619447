import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TestService = /** @class */ (function () {
    function TestService(http) {
        this.http = http;
    }
    TestService.prototype.createTest = function (userId, name) {
        return this.http.post(environment.apiUrl + "/Test/" + userId, { userId: userId });
    };
    TestService.prototype.getTestsByUserId = function (userId) {
        return this.http.get(environment.apiUrl + "/Test/" + userId);
    };
    TestService.prototype.getQuestionsByTestId = function (testId) {
        return this.http.get(environment.apiUrl + "/Test/question/" + testId);
    };
    TestService.prototype.getTotalQuestionsByFontId = function (id, categoryProductId, font) {
        return this.http.get(environment.apiUrl + "/Test/TotalQuestionsByFontId/" + id + "/" + categoryProductId + "/" + font);
    };
    TestService.prototype.getTotalQuestionsByUserId = function (id) {
        return this.http.get(environment.apiUrl + "/Test/TotalQuestions/" + id);
    };
    TestService.prototype.getTypeByTestId = function (id) {
        return this.http.get(environment.apiUrl + "/Test/category/" + id);
    };
    TestService.prototype.getGlobalRatingByUserId = function (id, categoryProductId, font) {
        return this.http.get(environment.apiUrl + "/Test/GlobalRatingTax/" + id + "/" + categoryProductId + "/" + font);
    };
    TestService.prototype.getTaxThemeByUserId = function (id, categoryProductId, font) {
        return this.http.get(environment.apiUrl + "/Test/TaxThemeByUserId/" + id + "/" + categoryProductId + "/" + font);
    };
    TestService.prototype.getTestListByUserId = function (id, categoryProductId, font) {
        return this.http.get(environment.apiUrl + "/Test/TestListByUserId/" + id + "/" + categoryProductId + "/" + font);
    };
    TestService.prototype.getElearningTestsByUserId = function (userELearningId) {
        return this.http.get(environment.apiUrl + "/eLearningTest/" + userELearningId);
    };
    TestService.prototype.getSuccessTaxByUserId = function (id, categoryProductId, font) {
        return this.http.get(environment.apiUrl + "/Test/SuccessTaxByUserId/" + id + "/" + categoryProductId + "/" + font);
    };
    TestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TestService_Factory() { return new TestService(i0.ɵɵinject(i1.HttpClient)); }, token: TestService, providedIn: "root" });
    return TestService;
}());
export { TestService };
