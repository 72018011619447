import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../base-page';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'page-analytics',
  templateUrl: './analytics.component.html'
})
export class PageAnalyticsComponent extends BasePageComponent implements OnInit, OnDestroy {
  tableData: [] = [];
  constructor(
    store: Store<IAppState>,
    httpSv: HttpService
  ) {
    super(store, httpSv);
    this.pageData = {
      title: '',
      loaded: true
    };
  }

  ngOnInit() {
    super.ngOnInit();

    this.getData(`${environment.apiUrl}/Company/`, 'tableData', 'setLoaded');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
