<div class="row justify-content-center">
  <div class="col col-12 col-xl-8">
    <form [formGroup]="userForm" novalidate class="new-doctor-form mb-4" (ngSubmit)="saveData(userForm)" *ngIf="userForm">
      <tc-card>
        <tc-form-group class="mb-0">
          <input type="file" accept="image/*" (change)="onFileChanged($event)" #fileInput hidden>

          <div class="d-sm-flex align-items-center">
            <tc-avatar [src]="currentAvatar" [size]="100" class="mb-2 mb-sm-0"></tc-avatar>
            <button tc-button [type]="'button'" [afterIcon]="'icofont-ui-user'" [view]="'info'" [outline]="true" (click)="fileInput.click()">
              Mudar Foto
            </button>
          </div>
        </tc-form-group>
      </tc-card>

      <tc-card>
        <tc-form-group>
          <tc-form-label>Nome Completo</tc-form-label>
          <tc-input [placeholder]="'Nome Completo'" formControlName="name"></tc-input>
        </tc-form-group>
        <tc-form-group>
          <tc-form-label>Utilizador</tc-form-label>
          <tc-input *ngIf="!isAdmin()" [placeholder]="'Utilizador'" [value]="currentUser.user.username" [disabled]="true"></tc-input>
          <tc-input *ngIf="isAdmin()" [placeholder]="'Utilizador'" [value]="currentUser.user.username"></tc-input>
        </tc-form-group>
        <tc-form-group>
          <tc-form-label>Email</tc-form-label>
          <tc-input *ngIf="!isAdmin()" [placeholder]="'Email'" formControlName="email" [readonly]="true"></tc-input>
          <tc-input *ngIf="isAdmin()" [placeholder]="'Email'" formControlName="email"></tc-input>
        </tc-form-group>
        <tc-form-group>
          <tc-form-label>Nif</tc-form-label>
          <tc-input [placeholder]="'Nif'" formControlName="nif"></tc-input>
        </tc-form-group>
        <tc-form-group>
          <tc-form-label>CC</tc-form-label>
          <tc-input [placeholder]="'CC'" formControlName="cc"></tc-input>
        </tc-form-group>
        <div class="row">
          <div class="col-12 col-sm-6">
            <button tc-button [view]="'success'" type="submit" [beforeIcon]="'icofont-diskette'"
              [disabled]="userForm.invalid">
              Guardar
            </button>
          </div>
        </div>
        <div *ngIf="this.currentUser.user.profileId == 6 || this.currentUser.user.profileId == 8">
          <br/>
          <hr/>
          <tc-form-group>
            <tc-form-label>APIKey</tc-form-label>
            <tc-input [placeholder]="'APIKey'" [readonly]="'true'" formControlName="apikey"></tc-input>
          </tc-form-group>           

          <div class="row">
            <div class="col-12 col-sm-6">
              <button tc-button type="button" (click)="openDocumentation()" [beforeIcon]="'icofont-document-folder'">Documentação API</button>
            </div>
          </div>
        </div>
      </tc-card>
    </form>
  </div>
</div>