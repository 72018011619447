import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../../services/company.service';
import { MediaService } from '../../services/media.service';
import { first } from 'rxjs/internal/operators/first';

@Component({
  selector: 'public-layout',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicLayoutComponent {
  logo: string;
  width: string;
  height: string;
  logoAC: string;
  imageUrlLogo: string;
  logoGRAYCELL: string;
  navigationSubscription: any;
  company: any;
  bgImage = '';
  url: string = '';
  constructor(private route: ActivatedRoute, private mediaService: MediaService,
    private companyService: CompanyService, private router: Router) {
  }

  initialiseCompany(name: string) {
    this.companyService.getByName(name).pipe(first()).subscribe(company => {
      this.company = company;
      this.url = company.url;
      if (company.name != 'Alves Costa') {
        document.querySelector('.bg').classList.add('customBg');
      }

      this.logo = 'data:image/jpg;base64,' + company.image;

      this.bgImage = 'data:image/jpg;base64,' + company.bgImage;
      this.width = '100%';
      this.height = '150px';
    });
    this.companyService.getByName('alvescosta').pipe(first()).subscribe(company => {
      this.logoAC = 'data:image/jpg;base64,' + company.image;
    });
    this.companyService.getByName('graycell').pipe(first()).subscribe(company => {
      this.logoGRAYCELL = 'data:image/jpg;base64,' + company.image;
    });
  }
}
