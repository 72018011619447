var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var ConfirmTerminateComponent = /** @class */ (function () {
    function ConfirmTerminateComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.localData = __assign({}, data);
        this.text = this.localData.text;
    }
    ConfirmTerminateComponent.prototype.ngOnInit = function () {
    };
    ConfirmTerminateComponent.prototype.closeDialog = function () {
        this.dialogRef.close({ event: 'Cancel' });
    };
    ConfirmTerminateComponent.prototype.confirm = function () {
        this.dialogRef.close({ event: 'sim' });
    };
    return ConfirmTerminateComponent;
}());
export { ConfirmTerminateComponent };
