var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';
import { BasePageComponent } from '../../../base-page';
import { CompanyService } from 'src/app/services/company.service';
import { HttpService } from '../../../../services/http/http.service';
import { MediaService } from '../../../../services/media.service';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../../services/user.service';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';
var PageEditAccountComponent = /** @class */ (function (_super) {
    __extends(PageEditAccountComponent, _super);
    function PageEditAccountComponent(store, httpSv, formBuilder, authenticationService, userService, mediaService, companyService, toastr) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.formBuilder = formBuilder;
        _this.authenticationService = authenticationService;
        _this.userService = userService;
        _this.mediaService = mediaService;
        _this.companyService = companyService;
        _this.toastr = toastr;
        _this.apiKey = '';
        _this.tableData = [];
        _this.pageData = {
            title: 'Editar Perfil',
            loaded: true
        };
        _this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        _this.actualizeImg(_this.currentUser.user.image);
        _this.changes = false;
        return _this;
    }
    PageEditAccountComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.getData(environment.apiUrl + "/Company/", 'tableData', 'setLoaded');
        this.setLoaded();
        console.log("NGONINIT");
        this.userDetails = JSON.parse(localStorage.getItem('currentUserDetails'));
        this.inituserForm(this.userDetails);
    };
    PageEditAccountComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageEditAccountComponent.prototype.openDocumentation = function () {
        window.open(environment.swagger, '_blank');
    };
    PageEditAccountComponent.prototype.inituserForm = function (data) {
        var _this = this;
        this.userForm = this.formBuilder.group({
            img: [this.currentAvatar],
            name: [data['name'], Validators.required],
            email: [data['email'], Validators.required],
            nif: [data['nif']],
            cc: [data['cc']],
            apikey: [data['systemId']]
        });
        // detect form changes
        this.userForm.valueChanges.subscribe(function () {
            _this.changes = true;
        });
    };
    // save form data
    PageEditAccountComponent.prototype.saveData = function (form) {
        var _this = this;
        if (form.valid) {
            this.userInfo = form.value;
            this.changes = false;
            this.userService.updateUserInfo(this.currentUser.user.id, '', form.value['name'], form.value['email'], form.value['nif'], form.value['cc']).pipe(first()).subscribe(function (data) {
                _this.toastr.success('Perfil Modificado com sucesso!');
                document.getElementsByClassName('currentName')[0].innerHTML = form.value['name'];
                localStorage.setItem('currentUserDetails', JSON.stringify(data));
                _super.prototype.sendMessage.call(_this);
            });
        }
    };
    // upload new file
    PageEditAccountComponent.prototype.onFileChanged = function (inputValue) {
        var _this = this;
        var file = inputValue.target.files[0];
        if (file === null) {
            return;
        }
        var mimeType = file.type;
        if (mimeType.match(/image\/*/) == null) {
            alert('Imagem inválida.');
            return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        // tslint:disable-next-line: variable-name
        reader.onload = function (_event) {
            _this.currentAvatar = reader.result;
            var url = reader.result.toString().split(',')[1];
            _this.mediaService.createImageToUser(_this.currentUser.user.id, url, mimeType, file.name, file.size.toString()).pipe(first()).subscribe(function (user) {
                console.log('saved');
                _super.prototype.sendMessage.call(_this);
            });
        };
    };
    PageEditAccountComponent.prototype.actualizeImg = function (img) {
        if (img && img != '')
            this.currentAvatar = "data:image/jpg;base64," + img;
        else
            this.currentAvatar = "assets/avatar.jpg";
    };
    PageEditAccountComponent.prototype.isAdmin = function () {
        return (this.currentUser.user.role == 'Administrador' ? true : false);
    };
    return PageEditAccountComponent;
}(BasePageComponent));
export { PageEditAccountComponent };
