/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./public.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./public.component";
import * as i4 from "../../services/media.service";
import * as i5 from "../../services/company.service";
var styles_PublicLayoutComponent = [i0.styles];
var RenderType_PublicLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PublicLayoutComponent, data: {} });
export { RenderType_PublicLayoutComponent as RenderType_PublicLayoutComponent };
export function View_PublicLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "bg"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "content-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "content-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "logo-wrap"], ["style", "margin: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "logo-img"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "content-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "content-footer"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 8, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.bgImage) + ")"); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.url, ""); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.logo || ""); _ck(_v, 5, 0, currVal_2); }); }
export function View_PublicLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "public-layout", [], null, null, null, View_PublicLayoutComponent_0, RenderType_PublicLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i3.PublicLayoutComponent, [i2.ActivatedRoute, i4.MediaService, i5.CompanyService, i2.Router], null, null)], null, null); }
var PublicLayoutComponentNgFactory = i1.ɵccf("public-layout", i3.PublicLayoutComponent, View_PublicLayoutComponent_Host_0, {}, {}, []);
export { PublicLayoutComponentNgFactory as PublicLayoutComponentNgFactory };
