var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../base-page';
import { HttpService } from '../../../services/http/http.service';
import { ChartOptions, ChartType } from 'chart.js';
import { AuthService } from '../../../services/auth.service';
import { TestService } from '../../../services/test.service';
import { QuestionService } from '../../../services/question.service';
import { UserService } from '../../../services/user.service';
import { CategoryProductService } from '../../../services/category-product.service';
import { environment } from '../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';
import { ShowTestComponent } from '../../custom/show-test/show-test.component';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { FormControl } from '@angular/forms';
import { TicketService } from '../../../services/ticket.service';
import { ToastrService } from 'ngx-toastr';
import { SheetSalesService } from '../../../services/sheetsSales.service';
import { CategoryService } from '../../../services/category.service';
import { ShowcamTestComponent } from '../../custom/showCamTest/showCamTest.component';
import { Utils } from 'src/utils/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
var PageDashboardComponent = /** @class */ (function (_super) {
    __extends(PageDashboardComponent, _super);
    function PageDashboardComponent(store, dialog, httpSv, questionService, authenticationService, testService, userService, categoryProductService, getStudentsReportGeneralservice, userTicketService, toastr, formBuilder, categoryService, data, utils, _formBuilder) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.dialog = dialog;
        _this.questionService = questionService;
        _this.authenticationService = authenticationService;
        _this.testService = testService;
        _this.userService = userService;
        _this.categoryProductService = categoryProductService;
        _this.getStudentsReportGeneralservice = getStudentsReportGeneralservice;
        _this.toastr = toastr;
        _this.formBuilder = formBuilder;
        _this.categoryService = categoryService;
        _this.data = data;
        _this.utils = utils;
        _this._formBuilder = _formBuilder;
        // Colors
        _this.Colors = [
            { backgroundColor: ['#4BC842', '#F74545'] },
            { borderColor: ['#4BC842', '#F74545'] }
        ];
        _this.ColorsHorizontalBars = [{ backgroundColor: 'rgba(64, 154, 87, 0.45)' }];
        _this.ColorsGlobalRatingChart = [
            { backgroundColor: ['#0459A0', '#367AB3', '#22DB14', '#75A4CB'] },
            { borderColor: ['#0459A0', '#367AB3', '#22DB14', '#75A4CB'] }
        ];
        _this.pageSizeTableUserData = 10;
        _this.pageSizeTableTestListData = 10;
        _this.pageSizeTableQuestionListData = 10;
        _this.pageSizeAlvesCostaTableTestListData = 10;
        _this.pageSizeAlvesCostaTableQuestionListData = 10;
        _this.pageSizeImtTableTestListData = 10;
        _this.pageSizeImtTableQuestionListData = 10;
        _this.componentDestroyed = new Subject();
        _this.bordered = true;
        _this.position = 'both';
        _this.loading = false;
        // Doughnut chart 1
        _this.globalRatingData = [[]];
        _this.globalRatingType = 'doughnut';
        _this.globalRatingLabels = [
            'Perguntas Respondidas',
            'Respostas Corretas',
            'Testes Aprovados',
            'Temas Corretos'
        ];
        _this.alvesCostaRatingData = [[]];
        _this.imtRatingData = [[]];
        _this.chartOptions = {
            responsive: true,
            legend: { display: false },
            scales: {
                yAxes: [{
                        ticks: { callback: function (value, index, ticks) {
                                return value + '%';
                            },
                            min: 0,
                            stepSize: 10,
                            max: 100
                        }
                    }],
                xAxes: [{
                        ticks: {
                            min: 0,
                            max: 100,
                            stepSize: 10
                        }
                    }]
            },
            plugins: {
                filler: {
                    propagate: false
                }
            }
        };
        _this.chartOptionsMobile = {
            responsive: true,
            legend: { display: false },
        };
        _this.chartOptionsDefault = {
            responsive: true,
            legend: { display: true },
        };
        // Doughnut chart 2
        _this.globalTestsTaxData = [[]];
        _this.alvesCostaTestsTaxData = [[]];
        _this.imtTestsTaxData = [[]];
        _this.totalTestsTaxLabels = ['Testes Aprovados', 'Testes Reprovados'];
        _this.totalTestsTaxType = 'doughnut';
        // Table - Tests
        _this.listOfDisplayData = [];
        // Doughnut chart 3
        _this.globalTotalQuestionsData = [[]];
        _this.alvesCostaTotalQuestionsData = [[]];
        _this.imtTotalQuestionsData = [[]];
        _this.totalQuestionsType = 'doughnut';
        _this.totalQuestionsLabels = ['Questões Respondidas', 'Questões Por Responder '];
        _this.currentPage = 0;
        _this.globalTaxThemeData = [];
        _this.TaxThemeApprovalData = [];
        _this.TaxThemeReprovedData = [];
        _this.alvesCostaTaxThemeApprovalData = [];
        _this.alvesCostaTaxThemeReprovedData = [];
        _this.imtTaxThemeApprovalData = [];
        _this.imtTaxThemeReprovedData = [];
        _this.globalTaxThemeType = 'line';
        _this.chartDatasets = [];
        _this.alvesCostachartDatasets = [];
        _this.imtchartDatasets = [];
        _this.chartData = [];
        _this.alvesCostaChartData = [];
        _this.imtChartData = [];
        _this.alvesCostaListThemeTaxGlobal = [];
        _this.imtListThemeTaxGlobal = [];
        // Filter
        _this.endDtValue = '';
        _this.typeValue = '';
        _this.wrongsValue = '';
        _this.resultValue = '';
        _this.fontValueTest = '';
        _this.typeValueAC = '';
        _this.wrongsValueAC = '';
        _this.typeValueImt = '';
        _this.wrongsValueImt = '';
        _this.idValueAC = '';
        _this.descriptionValueAC = '';
        _this.wrongQuestionsValueAC = '';
        _this.idValueImt = '';
        _this.descriptionValueImt = '';
        _this.wrongQuestionsValueImt = '';
        _this.idValue = '';
        _this.fontValue = '';
        _this.descriptionValue = '';
        _this.wrongQuestionsValue = '';
        _this.isAnswerValue = '';
        _this.listQuestions = [];
        _this.listQuestionsAlvesCosta = [];
        _this.listQuestionsImt = [];
        _this.listThemeTaxGlobal = [];
        _this.alvesCostaListThemeTax = [];
        _this.imtListThemeTax = [];
        _this.nameValue = '';
        _this.userNameValue = '';
        _this.companyNameValue = '';
        _this.studentNumberValue = '';
        _this.listTest = [];
        _this.listTestAlvesCosta = [];
        _this.listTestImt = [];
        _this.listUser = [];
        // Sort
        _this.sortName = null;
        _this.sortValue = null;
        _this.mapOfSort = {
            name: null,
            userName: null,
            studentNumber: null,
            endDt: null,
            font: null,
            tag: null,
            wrongQuestions: null,
            approved: null,
            oldQuestionId: null,
            description: null,
            isAnswer: null,
            rightQuestions: null
        };
        _this.teacher = true;
        _this.studentDashboard = false;
        // ShowComponent
        _this.selected = new FormControl(0);
        _this.flag = false;
        _this.showEmptyDashboardValue = false;
        _this.showEmptyDashboardValueImt = false;
        _this.showEmptyDashboardValueAC = false;
        _this.showStudentDashboardTitleValue = false;
        _this.haveElearning = false;
        _this.haveTests = false;
        // DropdownList - Category
        _this.categoryProductList = [];
        _this.categories = [];
        _this.selectedCategoryValue = false;
        _this.list = [];
        _this.loadingSpinner = false;
        _this.userId = 0;
        _this.category = null;
        _this.openedQuestions = false;
        _this.openedQuestionsAlvesCosta = false;
        _this.openedQuestionsImt = false;
        _this.openedTests = false;
        _this.openedTestsAlvesCosta = false;
        _this.openedTestsImt = false;
        _this.openedUsers = false;
        _this.localData = __assign({}, data);
        _this.authenticationService.currentUser.subscribe(function (x) { return (_this.currentUser = x); });
        console.log('constructor');
        console.log(_this.currentUser.user);
        if ((_this.localData.userId != null && _this.localData.userId != undefined) || _this.currentUser.user.profileId === 3) {
            // Se tiver o profileId = 3 -> Student
            _this.teacher = false;
            _this.userId = _this.currentUser.user.id;
            if (_this.currentUser.user.profileId === 3) {
                _this.showStudentDashboardTitleValue = false;
                _this.showEmptyDashboardValue = true;
                _this.categoryProductService
                    .getCategoryProductListByUserId(_this.currentUser.user.id)
                    .subscribe(function (categories) {
                    console.log(categories);
                    if (categories.length > 0) {
                        _this.categories = categories;
                        _this.categoryProductList = categories;
                        _this.categorySelected = _this.categoryProductList[0]['categoryProductId'];
                        _this.initCharts(_this.categorySelected);
                        _this.selectedCategoryValue = true;
                        _this.category = _this.categoryProductList[0];
                        _this.haveElearning = _this.category.type.find(function (x) { return x.type == 'eLearning'; }) != null;
                        _this.haveTests = _this.category.type.find(function (x) { return x.type == 'Code' || x.type == 'CAM' || x.type == 'ADR' || x.type == 'Taxis'; }) != null;
                        console.log(_this.haveElearning);
                        console.log(_this.haveTests);
                    }
                });
            }
            // Outro profileId -> Teacher
            else if (_this.currentUser.user.profileId === 7 || _this.currentUser.user.profileId === 1 || _this.currentUser.user.profileId === 6 || _this.currentUser.user.profileId === 8) {
                _this.teacher = true;
                if (_this.currentUser.user.profileId === 7) {
                    _this.showStudentDashboardTitleValue = true;
                }
                if (_this.currentUser.user.profileId === 8) {
                    _this.showStudentDashboardTitleValue = false;
                }
                _this.userId = _this.localData.userId;
                _this.userService.getById(_this.localData.userId).subscribe(function (c) {
                    //this.currentUser = c;
                    _this.studentId = c['userId'];
                    _this.categoryProductService
                        .getCategoryProductListByUserId(_this.localData.userId)
                        .subscribe(function (categories) {
                        if (categories.length > 0) {
                            _this.categories = categories;
                            _this.selectedCategoryName = _this.categories[0]['name'];
                        }
                    });
                    _this.categoryProductService
                        .getCategoryProductListByUserId(c['userId'])
                        .pipe(first())
                        .subscribe(function (categoryProductList) {
                        _this.categoryProductList = categoryProductList;
                        if (_this.categoryProductList.length >= 1) {
                            _this.category = _this.categoryProductList[0];
                            console.log(_this.category);
                            _this.categorySelectedName = _this.categoryProductList[0]['name'];
                            _this.categorySelected = _this.selectCategory(0);
                            _this.openCity(event, 'RatingGlobal', 'RatingGlobal_');
                        }
                        else {
                            _this.showEmptyDashboardValue = true;
                        }
                    });
                });
                _this.teacher = false;
                userTicketService
                    .ticketsByUserId(_this.localData.userId)
                    .subscribe(function (a) { });
            }
            else {
            }
        }
        else {
            _this.authenticationService.currentUser.subscribe(function (x) { return (_this.currentUser = x); });
            _this.userId = _this.currentUser.user.id;
            _this.teacher = true;
            _this.studentDashboard = false;
            _this.tableUsers();
        }
        _this.getData(environment.apiUrl + "/Category/", 'tableData', 'setLoaded');
        _this.pageData = {
            title: 'Dashboard'
        };
        if (_this.teacher === true) {
            _this.pageData = {
                title: 'Utilizadores',
                loaded: true
            };
        }
        return _this;
    }
    PageDashboardComponent.prototype.selectCategory = function (i) {
        var _this = this;
        this.selectedCategoryValue = true;
        this.categorySelected = this.categoryProductList[i]['categoryProductId'];
        this.category = this.categoryProductList[i];
        this.haveElearning = this.category.type.find(function (x) { return x.type == 'eLearning'; }) != null;
        this.haveTests = this.category.type.find(function (x) { return x.type == 'Code' || x.type == 'CAM' || x.type == 'ADR' || x.type == 'Taxis'; }) != null;
        console.log(this.haveElearning);
        console.log(this.haveTests);
        if (this.studentId != null) {
            this.initChartsForTeacherProfile(this.categorySelected);
            this.categoryService.isCam(this.categoryProductList[i]['categoryId']).subscribe(function (camCheck) {
                _this.isCam = camCheck;
                console.log(_this.isCam);
            });
        }
        else {
            this.initCharts(this.categorySelected);
            this.categoryService.isCam(this.categoryProductList[i]['categoryId']).subscribe(function (camCheck) {
                _this.isCam = camCheck;
                console.log(_this.isCam);
            });
        }
    };
    PageDashboardComponent.prototype.initChartsForTeacherProfile = function (categoryProduct) {
        var _this = this;
        this.testService
            .getTestListByUserId(this.studentId, categoryProduct, null)
            .pipe(first())
            .subscribe(function (b) {
            if (b.length > 0) {
                _this.globalCharts(_this.studentId, categoryProduct);
            }
            else {
                _this.studentDashboard = true;
                _this.showEmptyDashboardValue = true;
            }
        });
        this.testService
            .getTestListByUserId(this.studentId, categoryProduct, 'alvescosta')
            .pipe(first())
            .subscribe(function (b) {
            if (b.length > 0) {
                _this.studentDashboard = true;
                _this.alvesCostaCharts(_this.studentId, categoryProduct);
            }
            else {
                _this.studentDashboard = true;
                _this.showEmptyDashboardValueAC = true;
            }
        });
        this.testService
            .getTestListByUserId(this.studentId, categoryProduct, 'imt')
            .pipe(first())
            .subscribe(function (b) {
            if (b.length > 0) {
                _this.studentDashboard = true;
                _this.imtCharts(_this.studentId, categoryProduct);
            }
            else {
                _this.studentDashboard = true;
                _this.showEmptyDashboardValueImt = true;
            }
        });
    };
    PageDashboardComponent.prototype.initCharts = function (categoryProduct) {
        var _this = this;
        this.testService
            .getTestListByUserId(this.currentUser.user.id, categoryProduct, null)
            .pipe(first())
            .subscribe(function (b) {
            if (b.length > 0) {
                _this.studentDashboard = false;
                _this.showEmptyDashboardValue = false;
                _this.globalCharts(_this.currentUser.user.id, categoryProduct);
                _this.teacher = false;
            }
            else {
                _this.showEmptyDashboardValue = true;
            }
        });
        this.testService
            .getTestListByUserId(this.currentUser.user.id, categoryProduct, 'alvescosta')
            .pipe(first())
            .subscribe(function (b) {
            if (b.length > 0) {
                _this.studentDashboard = true;
                _this.showEmptyDashboardValueAC = false;
                _this.alvesCostaCharts(_this.currentUser.user.id, categoryProduct);
                _this.teacher = false;
            }
            else {
                _this.showEmptyDashboardValueAC = true;
            }
        });
        this.testService
            .getTestListByUserId(this.currentUser.user.id, categoryProduct, 'imt')
            .pipe(first())
            .subscribe(function (b) {
            if (b.length > 0) {
                _this.studentDashboard = true;
                _this.showEmptyDashboardValueImt = false;
                _this.imtCharts(_this.currentUser.user.id, categoryProduct);
                _this.teacher = false;
            }
            else {
                _this.showEmptyDashboardValueImt = true;
            }
        });
    };
    // Charts and Tables
    PageDashboardComponent.prototype.globalCharts = function (c, categoryProduct) {
        var _this = this;
        // Chart 1 and 2
        this.showEmptyDashboardValue = false;
        this.testService
            .getGlobalRatingByUserId(c, categoryProduct, null)
            .pipe(first())
            .subscribe(function (b) {
            _this.globalRatingData = [[]];
            _this.globalTestsTaxData = [[]];
            _this.globalRatingData[0].push(b[0]['answeredTax']);
            _this.globalRatingData[0].push(b[0]['percentageRightQ']);
            _this.globalRatingData[0].push(b[0]['percentageRightTest']);
            _this.globalRatingData[0].push(b[0]['themeAverage']);
            _this.seen = b[0]['answeredTax'];
            _this.questions = b[0]['percentageRightQ'];
            _this.tests = b[0]['percentageRightTest'];
            _this.themes = b[0]['themeAverage'];
            // Chart 2
            _this.globalTestsTaxData[0].push(b[0]['percentageRightTest']);
            _this.globalTestsTaxData[0].push(b[0]['percentageWrongTest']);
            _this.approvedGlobal = b[0]['percentageRightTest'];
            _this.notApprovedGlobal = b[0]['percentageWrongTest'];
        });
        // Chart 3
        this.testService
            .getTotalQuestionsByFontId(c, categoryProduct, null)
            .pipe(first())
            .subscribe(function (b) {
            _this.globalTotalQuestionsData = [[]];
            // Doughnut chart 3
            _this.globalTotalQuestionsData[0].push(b[0]['answeredTax']);
            _this.globalTotalQuestionsData[0].push(b[0]['notAnsweredTax']);
            _this.answeredTaxGlobal = b[0]['answeredTax'];
            _this.notAnsweredTaxGlobal = b[0]['notAnsweredTax'];
            if ((_this.answeredTaxGlobal + _this.notAnsweredTaxGlobal) > 100) {
                if (_this.answeredTaxGlobal >= _this.notAnsweredTaxGlobal) {
                    _this.answeredTaxGlobal = _this.answeredTaxGlobal - 1;
                }
                else {
                    _this.notAnsweredTaxGlobal = _this.notAnsweredTaxGlobal - 1;
                }
            }
        });
        // Tests Table
        this.testService
            .getTestListByUserId(c, categoryProduct, null)
            .pipe(first())
            .subscribe(function (a) {
            _this.tableTestListData = a;
            _this.listTest = _this.tableTestListData;
        });
        //Success Chart
        this.testService
            .getSuccessTaxByUserId(c, categoryProduct, null)
            .pipe(first())
            .subscribe(function (a) {
            _this.globalTaxThemeLabels = a.map(function (data) { return data.formatData; });
            _this.TaxThemeApprovalData = a.map(function (data) { return data.approvedPercent; });
            _this.TaxThemeReprovedData = a.map(function (data) { return data.reprovedPercent; });
            _this.chartDatasets[0] = _this.TaxThemeApprovalData;
            _this.chartDatasets[1] = _this.TaxThemeReprovedData;
            var reccomended = [];
            var i = 0;
            while (i < _this.globalTaxThemeLabels.length) {
                reccomended = reccomended.concat(70);
                i++;
            }
            ;
            _this.chartData = [
                { data: _this.chartDatasets[0], borderColor: 'rgba(64, 154, 87, 0.8)', backgroundColor: 'rgba(64, 154, 87, 0.45)',
                    pointBackgroundColor: 'rgba(64, 154, 87, 1)', label: 'Taxa de Aprovação' },
                { data: _this.chartDatasets[1], borderColor: 'rgba(237, 45, 48, 0.8)', backgroundColor: 'rgba(237, 45, 48, 0.5)',
                    pointBackgroundColor: 'rgba(237, 45, 48, 1)', label: 'Taxa de Reprovação', fill: false },
                { data: reccomended, label: 'Limiar de Segurança', fill: false }
            ];
        });
        // Questions Table
        this.loadingSpinner = true;
        var severalRows = [];
        var page = 1;
        this.questionService.getQuestionListByUserId(c, categoryProduct, null, page)
            .pipe(first())
            .subscribe(function (b) {
            if (b.length > 0) {
                _this.tableQuestionListData = b;
                _this.listQuestions = _this.tableQuestionListData;
                _this.loadingSpinner = false;
                var totalRows = b[0].total;
                var pages_1 = Math.ceil(totalRows / 200);
                severalRows[page - 1] = b;
                for (var i = page + 1; i <= pages_1; i++) {
                    if (_this.componentDestroyed.isStopped)
                        return;
                    console.log(_this.componentDestroyed);
                    severalRows[i - 1] = undefined;
                    _this.questionService.getQuestionListByUserId(c, categoryProduct, null, i)
                        //.pipe(first())
                        .pipe(takeUntil(_this.componentDestroyed))
                        .subscribe(function (b) {
                        if (b.length > 0) {
                            var thisPage = b[0].page;
                            severalRows[thisPage - 1] = b;
                            _this.verifyIfIsOver(severalRows, pages_1);
                        }
                    });
                }
            }
        });
        // Theme Table
        this.testService
            .getTaxThemeByUserId(c, categoryProduct, null)
            .pipe(first())
            .subscribe(function (b) {
            _this.tableTaxThemeData = b;
            _this.listThemeTaxGlobal = _this.tableTaxThemeData;
        });
    };
    PageDashboardComponent.prototype.alvesCostaCharts = function (c, categoryProduct) {
        var _this = this;
        // Chart 1 e Chart 2
        this.testService
            .getGlobalRatingByUserId(c, categoryProduct, 'alvescosta')
            .pipe(first())
            .subscribe(function (b) {
            _this.showEmptyDashboardValue = false;
            _this.alvesCostaRatingData = [[]];
            _this.alvesCostaSeen = [[]];
            _this.alvesCostaQuestions = [[]];
            _this.alvesCostaTests = [[]];
            _this.alvesCostaTestsTaxData = [[]];
            _this.alvesCostaRatingData[0].push(b[0]['answeredTax']);
            _this.alvesCostaRatingData[0].push(b[0]['percentageRightQ']);
            _this.alvesCostaRatingData[0].push(b[0]['percentageRightTest']);
            _this.alvesCostaRatingData[0].push(b[0]['themeAverage']);
            _this.alvesCostaSeen = b[0]['answeredTax'];
            _this.alvesCostaQuestions = b[0]['percentageRightQ'];
            _this.alvesCostaTests = b[0]['percentageRightTest'];
            _this.alvesCostaThemes = b[0]['themeAverage'];
            // Chart 2
            _this.alvesCostaTestsTaxData[0].push(b[0]['percentageRightTest']);
            _this.alvesCostaTestsTaxData[0].push(b[0]['percentageWrongTest']);
            _this.approvedAC = b[0]['percentageRightTest'];
            _this.notApprovedAC = b[0]['percentageWrongTest'];
        });
        // Test Table
        this.testService
            .getTestListByUserId(c, categoryProduct, 'alvescosta')
            .pipe(first())
            .subscribe(function (a) {
            _this.alvesCostaTableTestListData = a;
            _this.listTestAlvesCosta = _this.alvesCostaTableTestListData;
        });
        // Chart 3 - Questions
        this.testService
            .getTotalQuestionsByFontId(c, categoryProduct, 'alvescosta')
            .pipe(first())
            .subscribe(function (b) {
            _this.alvesCostaTotalQuestionsData = [[]];
            // Doughnut chart 3
            _this.alvesCostaTotalQuestionsData[0].push(b[0]['answeredTax']);
            _this.alvesCostaTotalQuestionsData[0].push(b[0]['notAnsweredTax']);
            _this.answeredTaxAC = b[0]['answeredTax'];
            _this.notAnsweredTaxAC = b[0]['notAnsweredTax'];
            if ((_this.answeredTaxAC + _this.notAnsweredTaxAC) > 100) {
                if (_this.answeredTaxAC >= _this.notAnsweredTaxAC) {
                    _this.answeredTaxAC = _this.answeredTaxAC - 1;
                }
                else {
                    _this.notAnsweredTaxAC = _this.notAnsweredTaxAC - 1;
                }
            }
        });
        //Success Chart
        this.testService
            .getSuccessTaxByUserId(c, categoryProduct, 'alvescosta')
            .pipe(first())
            .subscribe(function (a) {
            _this.alvesCostaTaxThemeLabels = a.map(function (data) { return data.formatData; });
            _this.alvesCostaTaxThemeApprovalData = a.map(function (data) { return data.approvedPercent; });
            _this.alvesCostaTaxThemeReprovedData = a.map(function (data) { return data.reprovedPercent; });
            _this.alvesCostachartDatasets[0] = _this.alvesCostaTaxThemeApprovalData;
            _this.alvesCostachartDatasets[1] = _this.alvesCostaTaxThemeReprovedData;
            var reccomended = [];
            var i = 0;
            while (i < _this.alvesCostaTaxThemeLabels.length) {
                reccomended = reccomended.concat(70);
                i++;
            }
            ;
            _this.alvesCostaChartData = [
                { data: _this.alvesCostachartDatasets[0], borderColor: 'rgba(64, 154, 87, 0.8)', backgroundColor: 'rgba(64, 154, 87, 0.45)',
                    pointBackgroundColor: 'rgba(64, 154, 87, 1)', label: 'Taxa de Aprovação' },
                { data: _this.alvesCostachartDatasets[1], borderColor: 'rgba(237, 45, 48, 0.8)', backgroundColor: 'rgba(237, 45, 48, 0.5)',
                    pointBackgroundColor: 'rgba(237, 45, 48, 1)', label: 'Taxa de Reprovação', fill: false },
                { data: reccomended, label: 'Limiar de Segurança', fill: false }
            ];
        });
        // Question Table
        var severalRowsAlves = [];
        var pageAlves = 1;
        this.questionService.getQuestionListByUserId(c, categoryProduct, 'alvescosta', pageAlves)
            .pipe(first())
            .subscribe(function (b) {
            if (b.length > 0) {
                _this.alvesCostaTableQuestionListData = b;
                _this.listQuestionsAlvesCosta = _this.alvesCostaTableQuestionListData;
                var totalRows = b[0].total;
                var pages_2 = Math.ceil(totalRows / 200);
                severalRowsAlves[pageAlves - 1] = b;
                for (var i = pageAlves + 1; i <= pages_2; i++) {
                    if (_this.componentDestroyed.isStopped)
                        return;
                    severalRowsAlves[i - 1] = undefined;
                    _this.questionService.getQuestionListByUserId(c, categoryProduct, 'alvescosta', i)
                        //.pipe(first())
                        .pipe(takeUntil(_this.componentDestroyed))
                        .subscribe(function (b) {
                        if (b.length > 0) {
                            var thisPage = b[0].page;
                            severalRowsAlves[thisPage - 1] = b;
                            _this.verifyIfIsOverAlvesCosta(severalRowsAlves, pages_2);
                        }
                    });
                }
            }
        });
        // Theme Table
        this.testService
            .getTaxThemeByUserId(c, categoryProduct, 'alvescosta')
            .pipe(first())
            .subscribe(function (b) {
            _this.alvesCostaTableTaxThemeData = b;
            _this.alvesCostaListThemeTaxGlobal = _this.alvesCostaTableTaxThemeData;
        });
        console.log('lista dashboard ' + this.alvesCostaRatingData);
    };
    PageDashboardComponent.prototype.imtCharts = function (c, categoryProduct) {
        var _this = this;
        // Chart 1  e 2 - Global Rating / Tests
        this.testService
            .getGlobalRatingByUserId(c, categoryProduct, 'imt')
            .pipe(first())
            .subscribe(function (b) {
            _this.imtTestsTaxData = [[]];
            _this.imtRatingData = [[]];
            _this.imtRatingData[0].push(b[0]['answeredTax']);
            _this.imtRatingData[0].push(b[0]['percentageRightQ']);
            _this.imtRatingData[0].push(b[0]['percentageRightTest']);
            _this.imtRatingData[0].push(b[0]['themeAverage']);
            _this.imtTestsTaxData[0].push(b[0]['percentageRightTest']);
            _this.imtTestsTaxData[0].push(b[0]['percentageWrongTest']);
            // Labels
            _this.approvedImt = b[0]['percentageRightTest'];
            _this.notApprovedImt = b[0]['percentageWrongTest'];
            _this.imtSeen = b[0]['answeredTax'];
            _this.imtQuestions = b[0]['percentageRightQ'];
            _this.imtTests = b[0]['percentageRightTest'];
            _this.imtThemes = b[0]['themeAverage'];
        });
        // Test Table
        this.testService
            .getTestListByUserId(c, categoryProduct, 'imt')
            .pipe(first())
            .subscribe(function (a) {
            _this.imtTableTestListData = a;
            _this.listTestImt = _this.imtTableTestListData;
        });
        // Chart 3 - Questions
        this.testService
            .getTotalQuestionsByFontId(c, categoryProduct, 'imt')
            .pipe(first())
            .subscribe(function (b) {
            _this.imtTotalQuestionsData = [[]];
            // Doughnut chart 3
            _this.imtTotalQuestionsData[0].push(b[0]['answeredTax']);
            _this.imtTotalQuestionsData[0].push(b[0]['notAnsweredTax']);
            _this.answeredTaxImt = b[0]['answeredTax'];
            _this.notAnsweredTaxImt = b[0]['notAnsweredTax'];
            if ((_this.answeredTaxImt + _this.notAnsweredTaxImt) > 100) {
                if (_this.answeredTaxImt >= _this.notAnsweredTaxImt) {
                    _this.answeredTaxImt = _this.answeredTaxImt - 1;
                }
                else {
                    _this.notAnsweredTaxImt = _this.notAnsweredTaxImt - 1;
                }
            }
        });
        //Success Chart
        this.testService
            .getSuccessTaxByUserId(c, categoryProduct, 'imt')
            .pipe(first())
            .subscribe(function (a) {
            _this.imtTaxThemeLabels = a.map(function (data) { return data.formatData; });
            _this.imtTaxThemeApprovalData = a.map(function (data) { return data.approvedPercent; });
            _this.imtTaxThemeReprovedData = a.map(function (data) { return data.reprovedPercent; });
            _this.imtchartDatasets[0] = _this.imtTaxThemeApprovalData;
            _this.imtchartDatasets[1] = _this.imtTaxThemeReprovedData;
            var reccomended = [];
            var i = 0;
            while (i < _this.imtTaxThemeLabels.length) {
                reccomended = reccomended.concat(70);
                i++;
            }
            ;
            _this.imtChartData = [
                { data: _this.imtchartDatasets[0], borderColor: 'rgba(64, 154, 87, 0.8)', backgroundColor: 'rgba(64, 154, 87, 0.45)',
                    pointBackgroundColor: 'rgba(64, 154, 87, 1)', label: 'Taxa de Aprovação' },
                { data: _this.imtchartDatasets[1], borderColor: 'rgba(237, 45, 48, 0.8)', backgroundColor: 'rgba(237, 45, 48, 0.5)',
                    pointBackgroundColor: 'rgba(237, 45, 48, 1)', label: 'Taxa de Reprovação', fill: false },
                { data: reccomended, label: 'Limiar de Segurança', fill: false }
            ];
        });
        // Question Table
        var severalRowsImt = [];
        var pageImt = 1;
        this.questionService.getQuestionListByUserId(c, categoryProduct, 'imt', pageImt)
            .pipe(first())
            .subscribe(function (b) {
            if (b.length > 0) {
                _this.imtTableQuestionListData = b;
                _this.listQuestionsImt = _this.imtTableQuestionListData;
                var totalRows = b[0].total;
                var pages_3 = Math.ceil(totalRows / 200);
                severalRowsImt[pageImt - 1] = b;
                for (var i = pageImt + 1; i <= pages_3; i++) {
                    if (_this.componentDestroyed.isStopped)
                        return;
                    severalRowsImt[i - 1] = undefined;
                    _this.questionService.getQuestionListByUserId(c, categoryProduct, 'imt', i)
                        //.pipe(first())
                        .pipe(takeUntil(_this.componentDestroyed))
                        .subscribe(function (b) {
                        if (b.length > 0) {
                            var thisPage = b[0].page;
                            severalRowsImt[thisPage - 1] = b;
                            _this.verifyIfIsOverImt(severalRowsImt, pages_3);
                        }
                    });
                }
            }
        });
        // Theme Table
        this.testService
            .getTaxThemeByUserId(c, categoryProduct, 'imt')
            .pipe(first())
            .subscribe(function (b) {
            _this.imtTableTaxThemeData = b;
            _this.imtListThemeTaxGlobal = _this.imtTableTaxThemeData;
        });
    };
    PageDashboardComponent.prototype.tableUsers = function () {
        var _this = this;
        this.userService
            .getAllStudentsByCompany(this.currentUser.user.companyId, this.currentUser.user.profileId)
            .pipe(first())
            .subscribe(function (a) {
            _this.tableUserData = a;
            _this.listUser = _this.tableUserData;
        });
    };
    PageDashboardComponent.prototype.verifyIfIsOver = function (severalRows, totalPage) {
        var allOk = false;
        allOk = true;
        for (var i = 0; i < totalPage; i++) {
            if (severalRows[i] == undefined) {
                allOk = false;
            }
        }
        if (allOk) {
            for (var i = 1; i < totalPage; i++) {
                this.tableQuestionListData = this.tableQuestionListData.concat(severalRows[i]);
                this.listQuestions = this.tableQuestionListData;
            }
        }
    };
    PageDashboardComponent.prototype.verifyIfIsOverAlvesCosta = function (severalRows, totalPage) {
        var allOk = false;
        allOk = true;
        for (var i = 0; i < totalPage; i++) {
            if (severalRows[i] == undefined) {
                allOk = false;
            }
        }
        if (allOk) {
            for (var i = 1; i < totalPage; i++) {
                this.alvesCostaTableQuestionListData = this.alvesCostaTableQuestionListData.concat(severalRows[i]);
                this.listQuestionsAlvesCosta = this.alvesCostaTableQuestionListData;
            }
        }
    };
    PageDashboardComponent.prototype.verifyIfIsOverImt = function (severalRows, totalPage) {
        var allOk = false;
        allOk = true;
        for (var i = 0; i < totalPage; i++) {
            if (severalRows[i] == undefined) {
                allOk = false;
            }
        }
        if (allOk) {
            for (var i = 1; i < totalPage; i++) {
                this.imtTableQuestionListData = this.imtTableQuestionListData.concat(severalRows[i]);
                this.listQuestionsImt = this.imtTableQuestionListData;
            }
        }
    };
    PageDashboardComponent.prototype.isTeacher = function () {
        return this.teacher;
    };
    PageDashboardComponent.prototype.selectedCategory = function () {
        return this.selectedCategoryValue;
    };
    PageDashboardComponent.prototype.showStudentDashboardTitle = function () {
        return this.showStudentDashboardTitleValue;
    };
    PageDashboardComponent.prototype.showEmptyDashboard = function () {
        return this.showEmptyDashboardValue && !this.haveElearning;
    };
    PageDashboardComponent.prototype.showEmptyDashboardImt = function () {
        return this.showEmptyDashboardValueImt;
    };
    PageDashboardComponent.prototype.showEmptyDashboardAC = function () {
        return this.showEmptyDashboardValueAC;
    };
    PageDashboardComponent.prototype.isStudentDashboard = function () {
        this.studentDashboard = true;
        return this.studentDashboard;
    };
    PageDashboardComponent.prototype.custom = function (i) {
        this.currentPage = i - 1;
    };
    PageDashboardComponent.prototype.currentPageDataChange = function ($event) {
        this.listOfDisplayData = $event;
    };
    PageDashboardComponent.prototype.sortQuestions = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.searchQuestions('');
        if (this.sortValue == '' || this.sortValue == null) {
            this.sortName = 'name';
            this.sortValue = 'ascend';
            this.filterQuestionTable();
        }
        this.listOfDisplayData = this.listQuestions;
    };
    PageDashboardComponent.prototype.sortQuestionsAlvesCosta = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.searchQuestionsAlvesCosta('');
        if (this.sortValue == '' || this.sortValue == null) {
            this.sortName = 'name';
            this.sortValue = 'ascend';
            this.filterQuestionTableAlvesCosta();
        }
        this.listOfDisplayData = this.listQuestionsAlvesCosta;
    };
    PageDashboardComponent.prototype.sortQuestionsImt = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.searchQuestionsImt('');
        if (this.sortValue == '' || this.sortValue == null) {
            this.sortName = 'name';
            this.sortValue = 'ascend';
            this.filterQuestionTableImt();
        }
        this.listOfDisplayData = this.listQuestionsImt;
    };
    PageDashboardComponent.prototype.sortTestTable = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.searchTests('');
        if (this.sortValue == '' || this.sortValue == null) {
            this.sortName = 'name';
            this.sortValue = 'ascend';
            this.filterTestTable();
        }
        this.listOfDisplayData = this.listTest;
    };
    PageDashboardComponent.prototype.sortTestTableAlvesCosta = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.searchTestsAlvesCosta('');
        if (this.sortValue == '' || this.sortValue == null) {
            this.sortName = 'name';
            this.sortValue = 'ascend';
            this.filterTestTableAlvesCosta();
        }
        this.listOfDisplayData = this.listTestAlvesCosta;
    };
    PageDashboardComponent.prototype.sortTestTableImt = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.searchTestsImt('');
        if (this.sortValue == '' || this.sortValue == null) {
            this.sortName = 'name';
            this.sortValue = 'ascend';
            this.filterTestTableImt();
        }
        this.listOfDisplayData = this.listTestImt;
    };
    PageDashboardComponent.prototype.sortTableUsers = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.searchUsers('');
        if (this.sortValue == '' || this.sortValue == null) {
            this.sortName = 'name';
            this.sortValue = 'ascend';
            this.filterUserTable();
        }
        this.listOfDisplayData = this.listUser;
    };
    PageDashboardComponent.prototype.sortTableThemeTaxGlobal = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.tableTaxThemeData = this.listThemeTaxGlobal;
        this.listOfDisplayData = this.listThemeTaxGlobal;
        this.searchThemeTaxGlobal('');
        if (this.sortValue == '' || this.sortValue == null) {
            this.tableTaxThemeData = this.listThemeTaxGlobal;
            this.listOfDisplayData = this.listThemeTaxGlobal;
        }
    };
    PageDashboardComponent.prototype.sortTableThemeTaxAlvesCosta = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.alvesCostaTableTaxThemeData = this.alvesCostaListThemeTax;
        this.listOfDisplayData = this.alvesCostaListThemeTax;
        this.searchThemeTaxAlvesCosta('');
        if (this.sortValue == '' || this.sortValue == null) {
            this.alvesCostaTableTaxThemeData = this.alvesCostaListThemeTax;
            this.listOfDisplayData = this.alvesCostaListThemeTax;
        }
    };
    PageDashboardComponent.prototype.sortTableThemeTaxImt = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.imtTableTaxThemeData = this.imtListThemeTax;
        this.listOfDisplayData = this.imtListThemeTax;
        this.searchThemeTaxImt('');
        if (this.sortValue == '' || this.sortValue == null) {
            this.imtTableTaxThemeData = this.imtListThemeTax;
            this.listOfDisplayData = this.imtListThemeTax;
        }
    };
    Object.defineProperty(PageDashboardComponent.prototype, "f", {
        get: function () {
            return this.categoriesForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    PageDashboardComponent.prototype.reset = function () {
        this.endDtValue = '';
        this.typeValue = '';
        this.wrongsValue = '';
        this.resultValue = '';
        this.fontValueTest = '';
        this.typeValueAC = '';
        this.wrongsValueAC = '';
        this.typeValueImt = '';
        this.wrongsValueImt = '';
        this.idValue = '';
        this.fontValue = '';
        this.descriptionValue = '';
        this.wrongQuestionsValue = '';
        this.idValueAC = '';
        this.descriptionValueAC = '';
        this.wrongQuestionsValueAC = '';
        this.idValueImt = '';
        this.descriptionValueImt = '';
        this.wrongQuestionsValueImt = '';
        this.isAnswerValue = '';
        this.nameValue = '';
        this.userNameValue = '';
        this.companyNameValue = '';
        this.studentNumberValue = '';
        /* this.searchQuestions('');
        this.searchQuestionsAlvesCosta('');
        this.searchQuestionsImt('');
        this.searchTestsImt('');
        this.searchTests('');
        this.searchTestsAlvesCosta('');
        this.searchUsers('');
        this.searchThemeTaxGlobal('');
        this.searchThemeTaxAlvesCosta('');
        this.searchThemeTaxImt(''); */
        this.tableQuestionListData = this.listQuestions;
        this.alvesCostaTableQuestionListData = this.listQuestionsAlvesCosta;
        this.imtTableQuestionListData = this.listQuestionsImt;
        this.tableTestListData = this.listTest;
        this.alvesCostaTableTestListData = this.listTestAlvesCosta;
        this.imtTableTestListData = this.listTestImt;
        this.tableUserData = this.listUser;
        this.tableTaxThemeData = this.listThemeTaxGlobal;
        this.mapOfSort = {
            name: null,
            userName: null,
            studentNumber: null,
            endDt: null,
            font: null,
            tag: null,
            wrongQuestions: null,
            approved: null,
            oldQuestionId: null,
            description: null,
            isAnswer: null,
            rightQuestions: null
        };
    };
    PageDashboardComponent.prototype.select = function (event) {
        if (event.path[1].className.match('active')) {
            event.path[1].classList.remove('active');
        }
        else {
            event.path[1].classList.add('active');
        }
    };
    PageDashboardComponent.prototype.onSubmit = function () {
        this.localData.CategoryName = this.f.categories.value;
    };
    PageDashboardComponent.prototype.searchQuestions = function (type) {
        var _this = this;
        var filterFunc = function (item) {
            var s = '' + item['oldQuestionId'].substring(_this.charsLenToByPass()).toLowerCase();
            var x = item['font'].toLowerCase();
            var y = item['description'].toLowerCase();
            var z = '' + item['wrongQuestions'];
            return type == 'oldQuestionId'
                ? s.indexOf(_this.idValue) !== -1
                : type == 'font'
                    ? x.indexOf(_this.fontValue.toLowerCase()) !== -1
                    : type == 'description'
                        ? y.indexOf(_this.descriptionValue.toLowerCase()) !== -1
                        : z.indexOf(_this.wrongQuestionsValue) !== -1;
        };
        var data = this.tableQuestionListData.filter(function (item) {
            return filterFunc(item);
        });
        this.tableQuestionListData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? // tslint:disable-next-line: no-non-null-assertion
                    a[_this.sortName] > b[_this.sortName]
                        ? 1
                        : -1
                : // tslint:disable-next-line: no-non-null-assertion
                    b[_this.sortName] > a[_this.sortName]
                        ? 1
                        : -1;
        });
    };
    PageDashboardComponent.prototype.searchQuestionsAlvesCosta = function (type) {
        var _this = this;
        var filterFunc = function (item) {
            var x = '' + item['oldQuestionId'].substring(_this.charsLenToByPass()).toLowerCase();
            var y = item['description'].toLowerCase();
            var z = '' + item['wrongQuestions'];
            return type == 'oldQuestionId'
                ? x.indexOf(_this.idValueAC.toString()) !== -1
                : type == 'description'
                    ? y.indexOf(_this.descriptionValueAC.toLowerCase()) !== -1
                    : z.indexOf(_this.wrongQuestionsValueAC) !== -1;
        };
        var data = this.alvesCostaTableQuestionListData.filter(function (item) {
            return filterFunc(item);
        });
        this.alvesCostaTableQuestionListData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    PageDashboardComponent.prototype.searchQuestionsImt = function (type) {
        var _this = this;
        var filterFunc = function (item) {
            var x = '' + item['oldQuestionId'].substring(_this.charsLenToByPass()).toLowerCase();
            var y = item['description'].toLowerCase();
            var z = '' + item['wrongQuestions'];
            return type == 'oldQuestionId'
                ? x.indexOf(_this.idValueImt.toString()) !== -1
                : type == 'description'
                    ? y.indexOf(_this.descriptionValueImt.toLowerCase()) !== -1
                    : z.indexOf(_this.wrongQuestionsValueImt) !== -1;
        };
        var data = this.imtTableQuestionListData.filter(function (item) {
            return filterFunc(item);
        });
        this.imtTableQuestionListData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    PageDashboardComponent.prototype.searchTests = function (type) {
        var _this = this;
        var filterFunc = function (item) {
            var y = item['font'].toLowerCase();
            var z = item['tag'].toLowerCase();
            var t = '' + item['wrongQuestions'];
            var s = '' + item['approved'];
            return type == 'font'
                ? y.indexOf(_this.fontValueTest.toLowerCase()) !== -1
                : type == 'tag'
                    ? z.indexOf(_this.typeValue.toLowerCase()) !== -1
                    : type == 'wrongQuestions'
                        ? t.indexOf(_this.wrongsValue) !== -1
                        : s.indexOf(_this.resultValue) !== -1;
        };
        var data = this.tableTestListData.filter(function (item) { return filterFunc(item); });
        this.tableTestListData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    PageDashboardComponent.prototype.searchTestsAlvesCosta = function (type) {
        var _this = this;
        var filterFunc = function (item) {
            var z = item['tag'].toLowerCase();
            var t = '' + item['wrongQuestions'];
            return type == 'tag'
                ? z.indexOf(_this.typeValueAC.toLowerCase()) !== -1
                : t.indexOf(_this.wrongsValueAC) !== -1;
        };
        var data = this.alvesCostaTableTestListData.filter(function (item) {
            return filterFunc(item);
        });
        this.alvesCostaTableTestListData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    PageDashboardComponent.prototype.searchTestsImt = function (type) {
        var _this = this;
        var filterFunc = function (item) {
            var z = item['tag'].toLowerCase();
            var t = '' + item['wrongQuestions'];
            return type === 'tag'
                ? z.indexOf(_this.typeValueImt.toLowerCase()) !== -1
                : t.indexOf(_this.wrongsValueImt) !== -1;
        };
        var data = this.imtTableTestListData.filter(function (item) {
            return filterFunc(item);
        });
        this.imtTableTestListData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    PageDashboardComponent.prototype.searchUsers = function (type) {
        var _this = this;
        var filterFunc = function (item) {
            var x = item['name'].toLowerCase();
            var y = item['userName'].toLowerCase();
            var w = '' + item['studentNumber'];
            var names = _this.nameValue.toLowerCase().split(" ");
            var name = '';
            if (names.length > 1) {
                for (var i = 0; i < names.length; i++) {
                    if (x.indexOf(names[i]) != -1 && x.indexOf(names[i + 1]) != -1) {
                        name = '0';
                    }
                }
            }
            else {
                if (x.indexOf(names[0]) != -1)
                    name = '0';
            }
            return type == 'name' ? name != ''
                : type == 'userName'
                    ? y.indexOf(_this.userNameValue.toLowerCase()) !== -1
                    : w.indexOf(_this.studentNumberValue) !== -1;
        };
        var data = this.tableUserData.filter(function (item) { return filterFunc(item); });
        this.tableUserData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    PageDashboardComponent.prototype.searchThemeTaxGlobal = function (type) {
        var _this = this;
        var filterFunc = function (item) {
            var x = '' + item['name'];
            var y = '' + item['totalQuestionsTheme'];
            var w = '' + item['percentageRightTheme'];
            var z = '' + item['totalRightQuestions'];
            var c = '' + item['totalWrongQuestions'];
            return type == 'name'
                ? x.indexOf('') !== -1
                : type == 'totalQuestionsTheme'
                    ? y.indexOf('') !== -1
                    : type == 'percentageRightTheme'
                        ? w.indexOf('') !== -1
                        : type == 'totalRightQuestions'
                            ? z.indexOf('') !== -1
                            : type == 'totalWrongQuestions'
                                ? c.indexOf('') !== -1
                                : w.indexOf('') !== -1;
        };
        var data = this.tableTaxThemeData.filter(function (item) { return filterFunc(item); });
        this.tableTaxThemeData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    PageDashboardComponent.prototype.searchThemeTaxAlvesCosta = function (type) {
        var _this = this;
        var filterFunc = function (item) {
            var x = '' + item['name'];
            var y = '' + item['totalQuestionsTheme'];
            var w = '' + item['percentageRightTheme'];
            var z = '' + item['totalRightQuestions'];
            var c = '' + item['totalWrongQuestions'];
            return type == 'name'
                ? x.indexOf('') !== -1
                : type == 'totalQuestionsTheme'
                    ? y.indexOf('') !== -1
                    : type == 'percentageRightTheme'
                        ? w.indexOf('') !== -1
                        : type == 'totalRightQuestions'
                            ? z.indexOf('') !== -1
                            : type == 'totalWrongQuestions'
                                ? c.indexOf('') !== -1
                                : w.indexOf('') !== -1;
        };
        var data = this.alvesCostaTableTaxThemeData.filter(function (item) { return filterFunc(item); });
        this.alvesCostaTableTaxThemeData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    PageDashboardComponent.prototype.searchThemeTaxImt = function (type) {
        var _this = this;
        var filterFunc = function (item) {
            var x = '' + item['name'];
            var y = '' + item['totalQuestionsTheme'];
            var w = '' + item['percentageRightTheme'];
            var z = '' + item['totalRightQuestions'];
            var c = '' + item['totalWrongQuestions'];
            return type == 'name'
                ? x.indexOf('') !== -1
                : type == 'totalQuestionsTheme'
                    ? y.indexOf('') !== -1
                    : type == 'percentageRightTheme'
                        ? w.indexOf('') !== -1
                        : type == 'totalRightQuestions'
                            ? z.indexOf('') !== -1
                            : type == 'totalWrongQuestions'
                                ? c.indexOf('') !== -1
                                : w.indexOf('') !== -1;
        };
        var data = this.imtTableTaxThemeData.filter(function (item) { return filterFunc(item); });
        this.imtTableTaxThemeData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    PageDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.categoriesForm = this.formBuilder.group({
            categories: ['', Validators.required]
        });
        // Lista de Categorias
        if (this.currentUser.user.profileId === 3) {
            this.categoryProductService
                .getCategoryProductListByUserId(this.currentUser.user.id)
                .subscribe(function (categories) {
                if (categories.length > 0) {
                    _this.categories = categories;
                    _this.selectedCategoryName = _this.categories[0]['name'];
                    _this.categoryService.isCam(_this.categories[0]['categoryId']).subscribe(function (camCheck) {
                        _this.isCam = camCheck;
                        console.log(_this.isCam);
                    });
                }
            });
        }
        // super.ngOnInit();
    };
    // Events
    PageDashboardComponent.prototype.chartClicked = function (_a) {
        var event = _a.event, active = _a.active;
    };
    PageDashboardComponent.prototype.chartHovered = function (_a) {
        var event = _a.event, active = _a.active;
    };
    // tslint:disable-next-line: member-ordering
    PageDashboardComponent.prototype.openDialogUserDashboard = function (e, i) {
        var row = {};
        console.log(this.currentPage * this.pageSizeTableUserData + i);
        console.log(this.tableUserData);
        row.userId = this.tableUserData[this.currentPage * this.pageSizeTableUserData + i].userId;
        row.dashboard = true;
        row.name = this.tableUserData[this.currentPage * this.pageSizeTableUserData + i].name;
        var dialogRef = this.dialog.open(PageDashboardComponent, {
            id: 'dashboard',
            // disableClose: true,
            closeOnNavigation: true,
            width: '85vw',
            height: '90vh',
            maxWidth: '1120px',
            data: row
        });
    };
    PageDashboardComponent.prototype.openDialogTest = function (e, i) {
        var row = {};
        row.testId = this.tableTestListData[this.currentPage * this.pageSizeTableTestListData + i].testId;
        row.dashboard = true;
        row.name = this.localData.name;
        row.nif = this.localData.nif;
        var deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
        var deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
        var deviceMaxHeight = this.utils.isMobileDevice() ? '90vh' : '90vh';
        if (this.isCam == true) {
            var dialogRef = this.dialog.open(ShowcamTestComponent, {
                id: 'camWizard0',
                width: deviceWidth,
                maxWidth: deviceMaxWidth,
                height: 'auto',
                maxHeight: deviceMaxHeight,
                disableClose: true,
                closeOnNavigation: true,
                panelClass: 'custom-modalbox',
                data: row
            });
        }
        else {
            var dialogRef = this.dialog.open(ShowTestComponent, {
                id: 'wizard0',
                width: deviceWidth,
                maxWidth: deviceMaxWidth,
                height: 'auto',
                maxHeight: deviceMaxHeight,
                disableClose: true,
                closeOnNavigation: true,
                panelClass: 'custom-modalbox',
                data: row
            });
        }
    };
    PageDashboardComponent.prototype.openDialogTestAC = function (e, i) {
        var row = {};
        var deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
        var deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
        var deviceMaxHeight = this.utils.isMobileDevice() ? '90vh' : '90vh';
        row.testId = this.alvesCostaTableTestListData[this.currentPage * this.pageSizeAlvesCostaTableTestListData + i].testId;
        row.name = this.localData.name;
        row.nif = this.localData.nif;
        row.dashboard = true;
        if (this.isCam == true) {
            var dialogRef = this.dialog.open(ShowcamTestComponent, {
                id: 'camWizard1',
                width: deviceWidth,
                maxWidth: deviceMaxWidth,
                height: 'auto',
                maxHeight: deviceMaxHeight,
                disableClose: true,
                closeOnNavigation: true,
                panelClass: 'custom-modalbox',
                data: row
            });
        }
        else {
            var dialogRef = this.dialog.open(ShowTestComponent, {
                id: 'wizard1',
                width: deviceWidth,
                maxWidth: deviceMaxWidth,
                height: 'auto',
                maxHeight: deviceMaxHeight,
                disableClose: true,
                closeOnNavigation: true,
                panelClass: 'custom-modalbox',
                data: row
            });
        }
    };
    PageDashboardComponent.prototype.openDialogTestImt = function (e, i) {
        var row = {};
        row.testId = this.imtTableTestListData[(this.currentPage * this.pageSizeImtTableTestListData + i)].testId;
        row.name = this.localData.name;
        row.nif = this.localData.nif;
        row.dashboard = true;
        var deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
        var deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
        var deviceMaxHeight = this.utils.isMobileDevice() ? '90vh' : '90vh';
        if (this.isCam == true) {
            var dialogRef = this.dialog.open(ShowcamTestComponent, {
                id: 'camWizard2',
                width: deviceWidth,
                maxWidth: deviceMaxWidth,
                height: 'auto',
                maxHeight: deviceMaxHeight,
                disableClose: true,
                closeOnNavigation: true,
                panelClass: 'custom-modalbox',
                data: row
            });
        }
        else {
            var dialogRef = this.dialog.open(ShowTestComponent, {
                id: 'wizard2',
                width: deviceWidth,
                maxWidth: deviceMaxWidth,
                height: 'auto',
                maxHeight: deviceMaxHeight,
                disableClose: true,
                closeOnNavigation: true,
                panelClass: 'custom-modalbox',
                data: row
            });
        }
    };
    PageDashboardComponent.prototype.openDialogQuestion = function (e, i) {
        var row = {};
        row.testId = this.tableQuestionListData[this.currentPage * this.pageSizeTableQuestionListData + i].testId;
        row.questionId = this.tableQuestionListData[this.currentPage * this.pageSizeTableQuestionListData + i].questionId;
        row.name = this.localData.name;
        row.nif = this.localData.nif;
        row.dashboard = true;
        var deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
        var deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
        var deviceMaxHeight = this.utils.isMobileDevice() ? '90vh' : '90vh';
        if (this.isCam == true) {
            var dialogRef = this.dialog.open(ShowcamTestComponent, {
                id: 'camWizard3',
                width: deviceWidth,
                maxWidth: deviceMaxWidth,
                height: 'auto',
                maxHeight: deviceMaxHeight,
                disableClose: true,
                closeOnNavigation: true,
                panelClass: 'custom-modalbox',
                data: row
            });
        }
        else {
            var dialogRef = this.dialog.open(ShowTestComponent, {
                id: 'wizard3',
                width: deviceWidth,
                maxWidth: deviceMaxWidth,
                height: 'auto',
                maxHeight: deviceMaxHeight,
                disableClose: true,
                closeOnNavigation: true,
                panelClass: 'custom-modalbox',
                data: row
            });
        }
    };
    PageDashboardComponent.prototype.openDialogQuestionAC = function (e, i) {
        var row = {};
        var deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
        var deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
        var deviceMaxHeight = this.utils.isMobileDevice() ? '90vh' : '90vh';
        row.testId = this.alvesCostaTableQuestionListData[this.currentPage * this.pageSizeAlvesCostaTableQuestionListData + i].testId;
        row.questionId = this.alvesCostaTableQuestionListData[this.currentPage * this.pageSizeAlvesCostaTableQuestionListData + i].questionId;
        row.dashboard = true;
        row.name = this.localData.name;
        row.nif = this.localData.nif;
        if (this.isCam == true) {
            var dialogRef = this.dialog.open(ShowcamTestComponent, {
                id: 'camWizard4',
                width: deviceWidth,
                maxWidth: deviceMaxWidth,
                height: 'auto',
                maxHeight: deviceMaxHeight,
                disableClose: true,
                closeOnNavigation: true,
                panelClass: 'custom-modalbox',
                data: row
            });
        }
        else {
            var dialogRef = this.dialog.open(ShowTestComponent, {
                id: 'wizard4',
                width: deviceWidth,
                maxWidth: deviceMaxWidth,
                height: 'auto',
                maxHeight: deviceMaxHeight,
                disableClose: true,
                closeOnNavigation: true,
                panelClass: 'custom-modalbox',
                data: row
            });
        }
    };
    PageDashboardComponent.prototype.openDialogQuestionImt = function (e, i) {
        var row = {};
        var deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
        var deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
        var deviceMaxHeight = this.utils.isMobileDevice() ? '90vh' : '90vh';
        row.testId = this.imtTableQuestionListData[this.currentPage * this.pageSizeImtTableQuestionListData + i].testId;
        row.questionId = this.imtTableQuestionListData[this.currentPage * this.pageSizeImtTableQuestionListData + i].questionId;
        row.dashboard = true;
        row.name = this.localData.name;
        row.nif = this.localData.nif;
        if (this.isCam == true) {
            var dialogRef = this.dialog.open(ShowcamTestComponent, {
                id: 'camWizard5',
                width: deviceWidth,
                maxWidth: deviceMaxWidth,
                height: 'auto',
                maxHeight: deviceMaxHeight,
                disableClose: true,
                closeOnNavigation: true,
                panelClass: 'custom-modalbox',
                data: row
            });
        }
        else {
            var dialogRef = this.dialog.open(ShowTestComponent, {
                id: 'wizard5',
                width: deviceWidth,
                maxWidth: deviceMaxWidth,
                height: 'auto',
                maxHeight: deviceMaxHeight,
                disableClose: true,
                closeOnNavigation: true,
                panelClass: 'custom-modalbox',
                data: row
            });
        }
    };
    PageDashboardComponent.prototype.closeUserDashboard = function () {
        this.dialog.closeAll();
        // this.dialog.closeAll().subscribe(x => {
        //   this.dialog = null;
        // location.reload(true);
    };
    PageDashboardComponent.prototype.openFilterQuestions = function () {
        var el = document.getElementById('filtroQuestions');
        if (this.openedQuestions == false) {
            el.classList.add('show');
            el.classList.remove('hide');
            this.openedQuestions = true;
        }
        else {
            el.classList.add('hide');
            el.classList.remove('show');
            this.openedQuestions = false;
        }
    };
    PageDashboardComponent.prototype.openFilterQuestionsAlvesCosta = function () {
        var el = document.getElementById('filterQuestionsAlvesCosta');
        if (this.openedQuestionsAlvesCosta == false) {
            el.classList.add('show');
            el.classList.remove('hide');
            this.openedQuestionsAlvesCosta = true;
        }
        else {
            el.classList.add('hide');
            el.classList.remove('show');
            this.openedQuestionsAlvesCosta = false;
        }
    };
    PageDashboardComponent.prototype.openFilterQuestionsImt = function () {
        var el = document.getElementById('filterQuestionsImt');
        if (this.openedQuestionsImt == false) {
            el.classList.add('show');
            el.classList.remove('hide');
            this.openedQuestionsImt = true;
        }
        else {
            el.classList.add('hide');
            el.classList.remove('show');
            this.openedQuestionsImt = false;
        }
    };
    PageDashboardComponent.prototype.openFilterTests = function () {
        var el = document.getElementById('filtroTests');
        if (this.openedTests == false) {
            el.classList.add('show');
            el.classList.remove('hide');
            this.openedTests = true;
        }
        else {
            el.classList.add('hide');
            el.classList.remove('show');
            this.openedTests = false;
        }
    };
    PageDashboardComponent.prototype.openFilterTestsAlvesCosta = function () {
        var el = document.getElementById('filterTestsAlvesCosta');
        if (this.openedTestsAlvesCosta == false) {
            el.classList.add('show');
            el.classList.remove('hide');
            this.openedTestsAlvesCosta = true;
        }
        else {
            el.classList.add('hide');
            el.classList.remove('show');
            this.openedTestsAlvesCosta = false;
        }
    };
    PageDashboardComponent.prototype.openFilterTestsImt = function () {
        var el = document.getElementById('filterTestsImt');
        if (this.openedTestsImt == false) {
            el.classList.add('show');
            el.classList.remove('hide');
            this.openedTestsImt = true;
        }
        else {
            el.classList.add('hide');
            el.classList.remove('show');
            this.openedTestsImt = false;
        }
    };
    PageDashboardComponent.prototype.openFilterUsers = function () {
        var el = document.getElementById('filterUsers');
        if (this.openedUsers == false) {
            el.classList.add('show');
            el.classList.remove('hide');
            this.openedUsers = true;
        }
        else {
            el.classList.add('hide');
            el.classList.remove('show');
            this.openedUsers = false;
        }
    };
    PageDashboardComponent.prototype.filterQuestionTable = function () {
        this.tableQuestionListData = this.listQuestions;
        if (this.idValue != '' && this.idValue != null) {
            this.searchQuestions('oldQuestionId');
        }
        if (this.fontValue != '') {
            this.searchQuestions('font');
        }
        if (this.descriptionValue != '') {
            this.searchQuestions('description');
        }
        if (this.wrongQuestionsValue != '') {
            this.searchQuestions('wrongQuestions');
        }
    };
    PageDashboardComponent.prototype.filterQuestionTableAlvesCosta = function () {
        this.alvesCostaTableQuestionListData = this.listQuestionsAlvesCosta;
        if (this.idValueAC != '' && this.idValueAC != null) {
            this.searchQuestionsAlvesCosta('oldQuestionId');
        }
        if (this.descriptionValueAC != '') {
            this.searchQuestionsAlvesCosta('description');
        }
        if (this.wrongQuestionsValueAC != '') {
            this.searchQuestionsAlvesCosta('wrongQuestions');
        }
    };
    PageDashboardComponent.prototype.filterQuestionTableImt = function () {
        this.imtTableQuestionListData = this.listQuestionsImt;
        if (this.idValueImt != '' && this.idValueImt != null) {
            this.searchQuestionsImt('oldQuestionId');
        }
        if (this.descriptionValueImt != '') {
            this.searchQuestionsImt('description');
        }
        if (this.wrongQuestionsValueImt != '') {
            this.searchQuestionsImt('wrongQuestions');
        }
    };
    PageDashboardComponent.prototype.filterTestTable = function () {
        this.tableTestListData = this.listTest;
        if (this.fontValueTest != '') {
            this.searchTests('font');
        }
        if (this.typeValue != '' && this.typeValue != ' ') {
            this.searchTests('tag');
        }
        if (this.wrongsValue != '') {
            this.searchTests('wrongQuestions');
        }
    };
    PageDashboardComponent.prototype.filterTestTableAlvesCosta = function () {
        this.alvesCostaTableTestListData = this.listTestAlvesCosta;
        if (this.typeValueAC != '' && this.typeValueAC != '  ') {
            this.searchTestsAlvesCosta('tag');
        }
        if (this.wrongsValueAC != '') {
            this.searchTestsAlvesCosta('wrongQuestions');
        }
    };
    PageDashboardComponent.prototype.filterTestTableImt = function () {
        this.imtTableTestListData = this.listTestImt;
        // tslint:disable-next-line: triple-equals
        if (this.typeValueImt != '' && this.typeValueImt != '  ') {
            this.searchTestsImt('tag');
        }
        // tslint:disable-next-line: triple-equals
        if (this.wrongsValueImt != '') {
            this.searchTestsImt('wrongQuestions');
        }
    };
    PageDashboardComponent.prototype.filterUserTable = function () {
        this.tableUserData = this.listUser;
        // tslint:disable-next-line: triple-equals
        if (this.nameValue != '') {
            this.searchUsers('name');
        }
        // tslint:disable-next-line: triple-equals
        if (this.userNameValue != '') {
            this.searchUsers('userName');
        }
        // tslint:disable-next-line: triple-equals
        if (this.studentNumberValue != '' && this.studentNumberValue != null) {
            this.searchUsers('studentNumber');
        }
    };
    PageDashboardComponent.prototype.ngOnDestroy = function () {
        // super.ngOnDestroy();
        console.log("componentDestroyed");
        this.componentDestroyed.next(true);
        this.componentDestroyed.complete();
        console.log(this.componentDestroyed.isStopped);
    };
    PageDashboardComponent.prototype.openCity = function (evt, cityName, cityTab) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("dashTab");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("active", "");
        }
        document.getElementById(cityName).style.display = "block";
        document.getElementById(cityName).classList.add("active");
        document.getElementById(cityTab).classList.add("active");
    };
    PageDashboardComponent.prototype.isMobile = function () {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };
    PageDashboardComponent.prototype.charsLenToByPass = function () {
        if (this.categorySelected == 14 || this.categorySelected == 15 || this.categorySelected == 16 || this.categorySelected == 17 || this.categorySelected == 18 || this.categorySelected == 25 || this.categorySelected == 54)
            return 4;
        return 3;
    };
    PageDashboardComponent.prototype.isIMT = function () {
        /*console.log('isIMT()');
        console.log(this.currentUser.user);*/
        return this.currentUser.user.profileId === 8;
    };
    ;
    PageDashboardComponent.prototype.getChartOptions = function () {
        if (this.isMobile())
            return this.chartOptionsMobile;
        else
            return this.chartOptionsDefault;
    };
    ;
    return PageDashboardComponent;
}(BasePageComponent));
export { PageDashboardComponent };
