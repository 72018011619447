<!-- <button tc-button [view]="'success'" class="btnTest" [beforeIcon]="'tc-icon-wrap icofont-check'" [routerLink]="'/public/free'">Teste Grátis</button> -->
<form [formGroup]="loginForm" (ngSubmit)="Login()">
  <div class="title-block">
    <h5 class="form-title center">{{companyName}}</h5>
  </div>
  <tc-form-group>
    <tc-input [prefixIcon]="'icofont-ui-user'" [placeholder]="'Utilizador'" formControlName="Utilizador"></tc-input>
  </tc-form-group>
  <tc-form-group>
    <tc-input [type]="'password'" formControlName="password" [prefixIcon]="'icofont-lock'" [placeholder]="'Password'" class="password">
    </tc-input>
    <tc-checkbox style="padding-left: 5%;padding-top: 2%;font-size:11px;" (click)="togglePassword()">Mostrar palavra-passe
    </tc-checkbox>
  </tc-form-group>
  <tc-form-group class="mt-1 d-flex justify-content-between align-items-center">
    <a [routerLink]="'/public/forgot-password'" class="link pl-sm-0">Recuperar palavra-passe</a>
    <button type="submit" tc-button [view]="'gradient'" [beforeIcon]="'icofont-sign-in'">Login</button>
  </tc-form-group>
  <label style="float: right;font-size: 12px;">version: {{version}}</label>
  <label style="font-size: 12px;">
    &#169; 2023 <a href="{{url}}" target="_blank">AlvesCosta. </a> Created by <a href="http://www.graycell.pt" target="_blank">graycell.</a>
  </label>
</form>