import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TicketService = /** @class */ (function () {
    function TicketService(http) {
        this.http = http;
    }
    // getCategoryIdByUserId(id: number){
    //   return this.http.get<any[]>(`${environment.apiUrl}/UserTicket/category/${id}`);
    // }
    TicketService.prototype.ticketByCategoryId = function (CompanyId, ProductCategoriesId, DidacticMaterialsId, DurationId, Quantity, CreatedBy) {
        return this.http.post(environment.apiUrl + "/Ticket/", { CompanyId: CompanyId, ProductCategoriesId: ProductCategoriesId, DidacticMaterialsId: DidacticMaterialsId, DurationId: DurationId, Quantity: Quantity, CreatedBy: CreatedBy });
    };
    TicketService.prototype.ticketsByCompanyId = function (id) {
        return this.http.get(environment.apiUrl + "/Ticket/company/" + id);
    };
    TicketService.prototype.didacticMaterialTicketsByCompanyId = function (id) {
        return this.http.get(environment.apiUrl + "/Ticket/company/didacticticket/" + id);
    };
    TicketService.prototype.ticketsByUserId = function (id) {
        return this.http.get(environment.apiUrl + "/UserTicket/user/" + id);
    };
    TicketService.prototype.didacticTicketsByUserId = function (id) {
        return this.http.get(environment.apiUrl + "/UserTicket/didactic/user/" + id);
    };
    TicketService.prototype.ticketByCategoryIdToUser = function (CompanyId, ProductId, CategoryId, DurationId, UserId) {
        var CategoryIdList = [];
        CategoryIdList.push(CategoryId);
        var ProductIdList = [];
        ProductIdList.push(ProductId);
        return this.http.post(environment.apiUrl + "/UserTicket/", { CompanyId: CompanyId, CategoryIdList: CategoryIdList, ProductIdList: ProductIdList, DurationId: DurationId, UserId: UserId });
    };
    TicketService.prototype.ticketByDidacticMaterialIdToUser = function (CompanyId, didacticMaterialId, DurationId, UserId) {
        var DidacticMaterialIdList = [];
        DidacticMaterialIdList.push(didacticMaterialId);
        return this.http.post(environment.apiUrl + "/UserTicket/didactitouser/", { CompanyId: CompanyId, DidacticMaterialIdList: DidacticMaterialIdList, DurationId: DurationId, UserId: UserId });
    };
    TicketService.prototype.activateTicket = function (x, userId) {
        var ids = [];
        x.forEach(function (item) {
            ids.push(item['userTicketId']);
        });
        return this.http.put(environment.apiUrl + "/UserTicket/activate/" + userId, { userId: userId, ids: ids });
    };
    TicketService.prototype.ticketStatus = function (id, active) {
        return this.http.put(environment.apiUrl + "/Ticket/ticketStatus/" + id + "/" + active, { active: active });
    };
    TicketService.prototype.removeticket = function (id) {
        return this.http.put(environment.apiUrl + "/Ticket/removeTickets/" + id, { id: id });
    };
    TicketService.prototype.userTicketStatus = function (id, active) {
        return this.http.put(environment.apiUrl + "/UserTicket/userTicketStatus/" + id + "/" + active, { active: active });
    };
    TicketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TicketService_Factory() { return new TicketService(i0.ɵɵinject(i1.HttpClient)); }, token: TicketService, providedIn: "root" });
    return TicketService;
}());
export { TicketService };
