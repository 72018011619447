import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TestDefinitionService = /** @class */ (function () {
    function TestDefinitionService(http) {
        this.http = http;
    }
    TestDefinitionService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl + "/testDefinition/");
    };
    TestDefinitionService.prototype.createTestDefinition = function (CategoryName, TestTypeName, duration, numberOfQuestions, wrongQuestions) {
        return this.http.post(environment.apiUrl + "/testDefinition/", {
            CategoryName: CategoryName, TestTypeName: TestTypeName, duration: duration, numberOfQuestions: numberOfQuestions, wrongQuestions: wrongQuestions
        });
    };
    TestDefinitionService.prototype.updateTestDefinition = function (testDefinitionId, CategoryName, TestTypeName, duration, numberOfQuestions, wrongQuestions) {
        return this.http.put(environment.apiUrl + "/testDefinition/" + testDefinitionId, {
            CategoryName: CategoryName, TestTypeName: TestTypeName, duration: duration, numberOfQuestions: numberOfQuestions, wrongQuestions: wrongQuestions
        });
    };
    TestDefinitionService.prototype.deleteTestDefinition = function (id) {
        return this.http.delete(environment.apiUrl + "/testDefinition/" + id);
    };
    TestDefinitionService.prototype.getTestDefinitionByCategoryId = function (id, name) {
        return this.http.get(environment.apiUrl + "/testDefinition/byCategoryId/" + id + "/" + name);
    };
    TestDefinitionService.prototype.getTestDefinitionById = function (id) {
        return this.http.get(environment.apiUrl + "/testDefinition/" + id);
    };
    TestDefinitionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TestDefinitionService_Factory() { return new TestDefinitionService(i0.ɵɵinject(i1.HttpClient)); }, token: TestDefinitionService, providedIn: "root" });
    return TestDefinitionService;
}());
export { TestDefinitionService };
