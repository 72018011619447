import { Injectable } from '@angular/core';
import { CompanyType } from '../models/CompanyType';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompanyTypeService {

  constructor(private http: HttpClient) { }
  getAll() {
    return this.http.get<CompanyType[]>(`${environment.apiUrl}/companyType/`);
  }
  getById(id: number) {
    return this.http.get<CompanyType>(`${environment.apiUrl}/companyType/${id}`);
  }
}
