import { SETTINGS } from './settings';

export const environment = {
  production: true,
  appSettings: SETTINGS,
  version: '2.0.2',
  apiUrl: 'https://api.testesdecodigo.com/api',
  //apiUrl: 'https://api.testesdecodigo.graycell.pt/api',
  swagger: 'https://api.testesdecodigo.com/swagger/index.html',
  //  apiUrl: 'http://80.172.233.181/AlvesCostaAPI/api'
};
