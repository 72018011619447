import { Component, OnInit, OnDestroy, Pipe, PipeTransform, forwardRef } from '@angular/core';
import { BasePageComponent } from '../../../../base-page';
import { User } from '../../../../../models/user';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../../interfaces/app-state';
import { AuthService } from '../../../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '../../../../../services/http/http.service';
import { SheetSalesService } from '../../../../../services/sheetsSales.service';
import { environment } from '../../../../../../environments/environment';
import { Company } from '../../../../../models/Company';
import * as xlsx from 'xlsx';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormControl } from '@angular/forms';
// import * as _moment from 'moment';
import { DialogBoxUsersComponent } from '../../../DialogBoxes/dialog-box-users/dialog-box-users.component';
import { UserService } from '../../../../../services/user.service';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
// tslint:disable-next-line:no-duplicate-imports
// import { default as _rollupMoment } from 'moment';

// const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
@Component({
    selector: 'app-studentSheets',
    styleUrls: ['./relatorioAluno.component.css'],
    templateUrl: './relatorioAluno.component.html',
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class StudentSheetsComponent extends BasePageComponent implements OnInit, OnDestroy {
    bordered = true;
    position = 'bottom';
    startDt = new FormControl();
    endDt = new FormControl();
    currentUser: User;
    currentPage = 0;
    studentSheets: any;
    listOfAllData: any[] = [];
    currentListData: any [] = [];
    found = false;
    displayData: any[];
    constructor(store: Store<IAppState>, private userService: UserService, public dialog: MatDialog, private authenticationService: AuthService, private route: ActivatedRoute, private getStudentsReportGeneralservice: SheetSalesService,
        httpSv: HttpService, private toastr: ToastrService, private sheetsService: SheetSalesService) {
        super(store, httpSv);
        this.getData(`${environment.apiUrl}/User/`, 'tableData', 'setLoaded');
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        this.pageData = {
            title: 'Relatório de Alunos',
            loaded: true
        };
        this.getReports(this.currentUser.user.companyId, "start", "end");
    }

    ngOnInit() {
        super.ngOnInit();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }

    currentPageDataChange($event: Company[]): void {
        this.studentSheets = $event;
    }
    isAdmin() {
        return this.currentUser.user.profileId == 1 ? false : true;
    }
    export() {
        var tableExport: any[] = [];
        this.displayData.forEach(element => {
            var z = {
                'Nome': element['name'],
                'Nº de Aluno': element['studentNumber'],
                'Email': element['eMail'],
                'Escola': element['school'],
                'Testes Realizados': element['allFontNumberOfTests'],
                'Taxa Sucesso Global': element['allFontSuccess'],
            };
            tableExport.push(z);
            element['children'].forEach(item => {
                var x = {
                    'Categoria': item['categoryName'],
                    'Data de Atribuição': item['startDt'],
                    'Data de Ativação': item['activeDt'],
                    'Testes Realizados AC': item['alvesCostaNumberOfTests'],
                    'Taxa Sucesso AC': item['alvesCostaSuccess'],
                    'Testes Realizados IMT': item['imtNumberOfTests'],
                    'Taxa Sucesso IMT': item['imtSuccess']
                };
                tableExport.push(x);
            });
        });
        const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(tableExport);
        const wb: xlsx.WorkBook = xlsx.utils.book_new();

        var wscols = [
            { wch: 30 },
            { wch: 10 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 15 },
            { wch: 15 },
            { wch: 20 },
            { wch: 15 },
            { wch: 15 },
            { wch: 20 },
            { wch: 15 },
            { wch: 15 },
            { wch: 20 }
        ];
        ws['!cols'] = wscols;
        xlsx.utils.book_append_sheet(wb, ws, 'relatorio_Alunos');
        xlsx.writeFile(wb, 'relatorio_Alunos.xlsx');
    }
    date() {
        let start = this.startDt.touched == false ? "start" :
            "" + this.startDt.value['_i']['year'] + "-" + (this.startDt.value['_i']['month'] + 1 < 10 ? "0" + (this.startDt.value['_i']['month'] + 1) : this.startDt.value['_i']['month'] + 1)
            + "-" + (this.startDt.value['_i']['date'] < 10 ? "0" + this.startDt.value['_i']['date'] : this.startDt.value['_i']['date']);
        let end = this.endDt.touched == false ? "end" :
            "" + this.endDt.value['_i']['year'] + "-" + (this.endDt.value['_i']['month'] + 1 < 10 ? "0" + (this.endDt.value['_i']['month'] + 1) : this.endDt.value['_i']['month'] + 1) + "-"
            + (this.endDt.value['_i']['date'] < 10 ? "0" + this.endDt.value['_i']['date'] : this.endDt.value['_i']['date']);

        /* this.getReports(this.currentUser.user.companyId, start, end); */
        this.currentListData = [];
        this.getStudentsReportGeneralservice
            .getStudentsReportGeneral(this.currentUser.user.companyId, start, end)
            .subscribe(sheetTest => {
                sheetTest.forEach(element => {
                    this.found = false;
                    for (let index = 0; index < this.listOfAllData.length; index++) {
                        if (this.listOfAllData[index]['userId'] == element['userId']) {
                            this.found = true;
                            var z = {
                                'userId': element['userId'], 'expand': false, 'key': this.listOfAllData.length,
                                'studentNumber': element['studentNumber'], 'name': element['name'], 'eMail': element['eMail'], 'school': element['school'],
                                'allFontNumberOfTests': element['allFontNumberOfTests'],
                                'allFontSuccess': element['allFontSuccess'],
                                'children': [] = []
                            };
                            this.currentListData.push(z);
                            var y = {
                                'categoryName': element['categoryName'], 'startDt': element['startDt'], 'activeDt': element['activeDt'],
                                'alvesCostaNumberOfTests': element['alvesCostaNumberOfTests'],
                                'alvesCostaSuccess': element['alvesCostaSuccess'], 'imtNumberOfTests': element['imtNumberOfTests'],
                                'imtSuccess': element['imtSuccess'], 'key': this.listOfAllData.length - 1
                            };
                            z['children'].push(y);
                        }
                    }
                });
                this.displayData = this.currentListData;
            })
    }

    getReports(id, st, end) {
        this.getStudentsReportGeneralservice
            .getStudentsReportGeneral(id, st, end)
            .subscribe(sheetTest => {
                sheetTest.forEach(element => {
                    this.found = false;
                    for (let index = 0; index < this.listOfAllData.length; index++) {
                        if (this.listOfAllData[index]['userId'] == element['userId']) {
                            this.found = true;
                            var x = {
                                'categoryName': element['categoryName'], 'startDt': element['startDt'], 'activeDt': element['activeDt'],
                                'alvesCostaNumberOfTests': element['alvesCostaNumberOfTests'],
                                'alvesCostaSuccess': element['alvesCostaSuccess'], 'imtNumberOfTests': element['imtNumberOfTests'],
                                'imtSuccess': element['imtSuccess'], 'key': this.listOfAllData.length - 1
                            };
                            this.listOfAllData[index]['children'].push(x);
                            break;
                        }
                    }
                    // se nao existir, cria o userId na primeira table e preenche a segunda table tambem
                     if (this.found == false) {
                        var z = {
                            'userId': element['userId'], 'expand': false, 'key': this.listOfAllData.length,
                            'studentNumber': element['studentNumber'], 'name': element['name'], 'eMail': element['eMail'], 'school': element['school'],
                            'allFontNumberOfTests': element['allFontNumberOfTests'],
                            'allFontSuccess': element['allFontSuccess'],
                            'children': [] = []
                        };
                        this.listOfAllData.push(z);
                        var y = {
                            'categoryName': element['categoryName'], 'startDt': element['startDt'], 'activeDt': element['activeDt'],
                            'alvesCostaNumberOfTests': element['alvesCostaNumberOfTests'],
                            'alvesCostaSuccess': element['alvesCostaSuccess'], 'imtNumberOfTests': element['imtNumberOfTests'],
                            'imtSuccess': element['imtSuccess'], 'key': this.listOfAllData.length - 1
                        };
                        z['children'].push(y);
                    }
                });
                this.displayData = this.listOfAllData;
            })
    }
    openDialog(action, event) {
        let c = event.srcElement.closest('tr');
        let i = this.displayData.findIndex(d => d['studentNumber'].toString() === c.children[1].innerText);
        
        this.userService.getById(this.displayData[i]['userId']).subscribe(a => {
            let row: any = {};
            row = a;
            row.action = action;
            row.companyName = this.displayData[i]['school'];
            row.isReportPage = true;
            let windowHeight = window.innerHeight * 0.30;

            const dialogRef = this.dialog.open(DialogBoxUsersComponent, {
                width: '85vw',
                maxWidth: '1120px',
                height: '90vh',
                maxHeight: windowHeight.toString(),
                data: row
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result === undefined) { }
                else if (result.event == 'ModificarGeral') {
                    this.updateRowData(result.data);
                }
            });
        })

    }

    updateRowData(rowObj) {
        this.userService.updateUser(rowObj.userId, '', rowObj.name, rowObj.email,
            rowObj.nif, rowObj.cc, rowObj.profileId, rowObj.companyId, rowObj.active, rowObj.contacts,
            rowObj.studentNumber,rowObj.learningLicense, rowObj.userName, '').subscribe((data) => {
                this.getReports(this.currentUser.user.companyId, "start", "end");

                this.toastr.success('Utilizador modificado com sucesso!');
            },
                err => {
                    this.toastr.error('Algo ocorreu mal!');
                });
    }
}
