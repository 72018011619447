import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { UserModel } from '../models/userModel';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<UserModel[]>(`${environment.apiUrl}/user/`);
    }

    getByProfileId(profileId: number) {
        return this.http.get<UserModel[]>(`${environment.apiUrl}/user/byprofile/${profileId}`);
    }

    getById(id: number) {
        return this.http.get<User>(`${environment.apiUrl}/user/${id}`);
    }

    getWithImgeById(id: number) {
        return this.http.get<any>(`${environment.apiUrl}/user/detail/${id}`);
    }

    getComercials() {
        return this.http.get<any[]>(`${environment.apiUrl}/user/comercial`);
    }

    createUser(SystemTs: string, Name: string, Email: string, Nif: string, Cc: string, ProfileId: number,
        CompanyId: number[], Active: boolean, StudentNumber: number, learningLicense: string, contacts: string,  Username: string, Password: string) {

        return this.http.post<any>(`${environment.apiUrl}/user/`, {
            Name, Email, Nif, Cc, ProfileId,
            CompanyId, Active, StudentNumber, learningLicense, contacts, Username, Password
        }
        );
    }

    updateUser(id: number, systemTs: string, name: string, email: string, nif: string, cc: string, profileId: number,
        companyId: number[], active: boolean, contacts: string, studentNumber: number, learningLicense: string, username: string, password: string) {

        return this.http.put<User>(`${environment.apiUrl}/user/${id}`, {
            systemTs, name, email, nif, cc, profileId, contacts,
            companyId, active, studentNumber, learningLicense, username, password
        }
        );
    }

    updateTerms(id: number) {
        return this.http.post<any>(`${environment.apiUrl}/user/terms/${id}`, { id });
    }

    updateUserInfo(id: number, systemTs: string, name: string, email: string, nif: string, cc: string) {
        console.log(id);
        console.log(name);
        console.log(email);
        console.log(nif);
        console.log(cc);

        return this.http.put<User>(`${environment.apiUrl}/user/userInfo/${id}`, {
            systemTs, name, email, nif, cc
        }
        );
    }
    
    deleteUser(id: number) {
        return this.http.delete<UserModel>(`${environment.apiUrl}/user/${id}`);
    }

    recoverPassword(email: string) {
        return this.http.put<any>(`${environment.apiUrl}/user/recover/${email}`, email);
    }
    updatePassword(password: string, id: number) {
        return this.http.put<any>(`${environment.apiUrl}/user/update/${id}/${password}`, password);
    }

    getAllStudentsByCompany(companyId: number, profileId: number) {
        return this.http.get<any[]>(`${environment.apiUrl}/user/GetAllStudentsByCompany/${companyId}/${profileId}`);

    }
    checkEmail(email: string, id: number) {
        return this.http.get<any>(`${environment.apiUrl}/user/checkEmail/${email}/${id}`);
    }
    checkUserName(username: string) {
        return this.http.get<any>(`${environment.apiUrl}/user/checkUserName/${username}`);
    }

    GetLastDataById(userId: number) {
        return this.http.get<any>(`${environment.apiUrl}/user/GetLastDataById/${userId}`);

    }
}
