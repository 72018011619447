import { OnInit, EventEmitter, ElementRef, DoCheck, Renderer2 } from '@angular/core';
var TCDropdownContentComponent = /** @class */ (function () {
    function TCDropdownContentComponent(element, renderer) {
        this.element = element;
        this.renderer = renderer;
        this.opened = false;
        this.appendToBody = true;
        this.globalPositioning = false;
        this.overlay = true;
        this.animation = 'fade';
        this.align = 'left';
        this.width = 280;
        this.maxWidth = '100%';
        this.maxHeight = 400;
        this.closeDropdown = new EventEmitter();
    }
    Object.defineProperty(TCDropdownContentComponent.prototype, "fade", {
        // animation type
        get: function () {
            return this.animation === 'fade' ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCDropdownContentComponent.prototype, "fadeInUp", {
        get: function () {
            return this.animation === 'fadeInUp' ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCDropdownContentComponent.prototype, "fadeInDown", {
        get: function () {
            return this.animation === 'fadeInDown' ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCDropdownContentComponent.prototype, "fadeInLeft", {
        get: function () {
            return this.animation === 'fadeInLeft' ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCDropdownContentComponent.prototype, "fadeInRight", {
        get: function () {
            return this.animation === 'fadeInRight' ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCDropdownContentComponent.prototype, "zoom", {
        get: function () {
            return this.animation === 'zoom' ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    // close from Esc key
    TCDropdownContentComponent.prototype.keyboardInput = function (event) {
        if (this.opened && event.keyCode === 27) {
            this.hide();
        }
    };
    TCDropdownContentComponent.prototype.ngOnInit = function () {
        // append dropdown content to the body
        if (this.appendToBody || this.globalPositioning) {
            document.body.appendChild(this.element.nativeElement);
        }
        if (typeof this.width === 'number') {
            this.width = this.width + 'px';
        }
    };
    TCDropdownContentComponent.prototype.ngDoCheck = function () {
        if (this.position) {
            var element = this.getContentElement();
            var position = this.calcPositionOffset(this.position);
            if (position) {
                this.renderer.setStyle(element, 'top', position.top + "px");
                this.renderer.setStyle(element, 'left', position.left + "px");
            }
        }
    };
    TCDropdownContentComponent.prototype.eventHandler = function (keycode) {
        console.log('button clicked');
    };
    // show dropdown content
    TCDropdownContentComponent.prototype.show = function () {
        this.opened = true;
    };
    // hide dropdown content
    TCDropdownContentComponent.prototype.hide = function () {
        this.opened = false;
        this.closeDropdown.emit(false);
    };
    // get dropdown children elements
    TCDropdownContentComponent.prototype.getContentElement = function () {
        return this.element.nativeElement.children[0];
    };
    // updates the dropdown content position
    TCDropdownContentComponent.prototype.updatePosition = function (position) {
        this.position = position;
    };
    // calculation dropdown position
    TCDropdownContentComponent.prototype.calcPositionOffset = function (position) {
        if (!position) {
            return;
        }
        var element = this.getContentElement();
        var supportPageOffset = window.pageXOffset !== undefined;
        var isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
        var x = supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft;
        var y = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
        var clientWidth = element.clientWidth;
        var clientHeight = element.clientHeight;
        var buttonHeight = position.height;
        var buttonWidth = position.width;
        var _a = this.applyOffset(buttonHeight + (this.appendToBody ? (position.top + y) : 0), (this.appendToBody) ? position.left + x : 0), top = _a.top, left = _a.left;
        if (this.align === 'right') {
            left -= clientWidth - buttonWidth;
        }
        else if (this.align === 'center') {
            left -= (clientWidth - buttonWidth) / 2;
        }
        return { top: top, left: left };
    };
    // calculation dropdown offset
    TCDropdownContentComponent.prototype.applyOffset = function (top, left) {
        var offset = this.offset;
        if (!offset ||
            !(offset instanceof Array) ||
            (offset instanceof Array && (offset.length === 0) || (typeof offset[0] !== 'number'
                || !this.appendToBody))) {
            return { top: top, left: left };
        }
        top += offset[0];
        if ((typeof offset[1] === 'number') && (offset[1] !== 0)) {
            left += offset[1];
        }
        return { top: top, left: left };
    };
    return TCDropdownContentComponent;
}());
export { TCDropdownContentComponent };
