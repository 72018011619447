<form>
  <div class="title-block d-flex-column mb-4">
    <h5 class="form-title">Recuperar palavra-passe</h5>

    <span class="desc">
     Por favor insira o seu email. Enviar-lhe-emos uma nova palavra-passe para conseguir entrar.
    </span>
  </div>

  <div class="elem-list lg">
    <tc-form-group>
      <tc-input [placeholder]="'Email'" [formControl]="email"></tc-input> 
    </tc-form-group>
  
    <button (click)="reset()" tc-button [view]="'success'">Recuperar</button>
    <button (click)="back()" tc-button [view]="'warning'">Voltar</button>
  </div>
</form>
