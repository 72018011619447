<div class="row justify-content-center">
  <div class="col col-12 col-xl-8">

    <tc-card class="mb-0">
      <form [formGroup]="passwordForm" novalidate class="new-doctor-form mb-4"
        (ngSubmit)="savePasswordData(passwordForm)">
        <div class="row">
          <div class="col-12 col-sm-6">
            <tc-form-group>
              <tc-form-label>Senha Corrente</tc-form-label>
              <tc-input [placeholder]="'Senha Corrente'" type="password" formControlName="current"></tc-input>
            </tc-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <tc-form-group>
              <tc-form-label>Nova Senha</tc-form-label>
              <tc-input [placeholder]="'Nova Senha'" type="password" formControlName="new"></tc-input>
            </tc-form-group>
          </div>

          <div class="col-12 col-sm-6">
            <tc-form-group>
              <tc-form-label>Confirma a nova Senha</tc-form-label>
              <tc-input [placeholder]="'Confirma a nova Senha'" type="password" formControlName="again"></tc-input>
            </tc-form-group>
          </div>
        </div>

        <button tc-button [view]="'success'" type="submit" [beforeIcon]="'icofont-diskette'"
          [disabled]="!changes || passwordForm.invalid">
          Mudar Senha
        </button>
      </form>
    </tc-card>
  </div>
</div>