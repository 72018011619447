import { Component, ElementRef, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';
import { DidaticMaterialComponent } from "../../pages/custom/DialogBoxes/dialog-box-material/dialog-box-material.component";
import { AuthService } from '../../services/auth.service';
import { BaseLayoutComponent } from '../base-layout/base-layout.component';
import { CompanyService } from '../../services/company.service';
import { FormBuilder } from '@angular/forms';
import { GlobalConstants } from 'src/environments/settings';
import { HttpService } from '../../services/http/http.service';
import { IAppState } from '../../interfaces/app-state';
import { Store } from '@ngrx/store';
import { User } from '../../models/user';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-layout',
  templateUrl: './app.component.html',
  styleUrls: [
    '../base-layout/base-layout.component.scss',
    './app.component.scss'
  ]
})
export class AppLayoutComponent extends BaseLayoutComponent implements OnInit {
  imageUrl: string;
  currentUser: User;
  width: string;
  height: string;

  constructor(
    store: Store<IAppState>,
    fb: FormBuilder,
    httpSv: HttpService,
    router: Router,
    elRef: ElementRef,
    private companyService: CompanyService,
    authenticationService: AuthService,
    public dialog: MatDialog
  ) {
    super(store, fb, httpSv, router, elRef);
    authenticationService.currentUser.subscribe(x => this.currentUser = x);
    // console.log(this.currentUser)
    this.companyService.getById(this.currentUser.user.companyId).subscribe(company => {
      if (company.image != null && company.image != '') {
        this.imageUrl = 'data:image/jpg;base64,' + company.image;
        this.width = '100%';
        this.height = '33px';
      }
    });
  }

  public actualize(): boolean
  {
    if(GlobalConstants)
      return GlobalConstants.actualize;
    
    return false;
  }

  private removeParamFromUrl(param: Params, key: string) {
    const queryParams = {};
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getNavbarStyles(): any {
    return {
      'background-image': `linear-gradient(188deg, ${this.appSettings.sidebarBg}, ${this.appSettings.sidebarBg2} 65%)`,
      'color': this.appSettings.sidebarColor,
      'z-index': '1000'
    };
  }

  getRoute(): string {
    return this.currentUser.user.profileId == 3 ? '/app/dashboard' : '/app/homePage';
  }

  btnLogoClick() {
    if (this.currentUser.user.profileId == 3 || this.currentUser.user.profileId == 7) {
      this.router.navigateByUrl('/app/dashboard');
    }
  }

  openHelp()
  {
    let row: any = {};
    row.mimeType = 'video/mp4';
    row.src = "./assets/movie/sample.mp4";
    const dialogRef = this.dialog.open(DidaticMaterialComponent, {
      width: "auto",
      height: "auto",
      maxWidth: "95vw",
      maxHeight: "90vh",
      data: row,
    });
  }

}
