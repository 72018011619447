import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SheetSalesService = /** @class */ (function () {
    function SheetSalesService(http) {
        this.http = http;
    }
    SheetSalesService.prototype.getSchoolSheets = function (userId, startDt, endDt) {
        console.log(startDt);
        console.log(endDt);
        return this.http.get(environment.apiUrl + "/sheets/schoolSheets/" + userId + "/" + startDt + "/" + endDt);
    };
    SheetSalesService.prototype.GetSchoolSalesSheets = function (userId, startDt, endDt) {
        return this.http.get(environment.apiUrl + "/sheets/schoolSalesSheets/" + userId + "/" + startDt + "/" + endDt);
    };
    SheetSalesService.prototype.getStudentsReportGeneral = function (companyId, startDt, endDt) {
        return this.http.get(environment.apiUrl + "/sheets/GetStudentsReportGeneral/" + companyId + "/" + startDt + "/" + endDt);
    };
    SheetSalesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SheetSalesService_Factory() { return new SheetSalesService(i0.ɵɵinject(i1.HttpClient)); }, token: SheetSalesService, providedIn: "root" });
    return SheetSalesService;
}());
export { SheetSalesService };
