import { Component } from '@angular/core';

@Component({
  selector: 'block-temp',
  styles: [`
    :host {
      text-align: center;
      color: #1976D2;
    }
  `],
  template: `
    <div class="block-ui-template">
      <div class="loader-overlay"></div>
      <div class="app-loader main-loader">
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
      </div>
    </div>
  `
})

export class BlockTemplateComponent {
message:string;
}
