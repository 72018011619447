import { Component, OnInit, Inject, Optional } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Question } from '../../../../models/question';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { QuestionOption } from '../../../../models/QuestionOption';
import { QuestionOptionService } from '../../../../services/question-option.service';
import { Theme } from '../../../../models/Theme';
import { ThemeService } from '../../../../services/theme.service';
import { MediaService } from '../../../../services/media.service';
import { first } from 'rxjs/internal/operators/first';
import { QuestionService } from '../../../../services/question.service';
import { DialogBoxThemesComponent } from '../../DialogBoxes/dialog-box-themes/dialog-box-themes.component';
@Component({
  selector: 'app-dialog-box-questions',
  templateUrl: './dialog-box-questions.component.html',
  styleUrls: ['./dialog-box-questions.component.css']
})
export class DialogBoxQuestionsComponent implements OnInit {
  themesForm: FormGroup;
  loading = false;
  submitted = false;
  themes: Theme[] = [];
  questionOptions: QuestionOption[];
  action: string;
  localData: any;
  imageName: string = '';
  themesOfQuestion: any[];
  public imagePath;
  selectedThemesData: Theme[] = [];
  themesSelected: any[] = [];
  imageUrl: string | ArrayBuffer;
  public message: string;
  x = '';
  questionAnswered: number = -1;
  listOfAllData;
  constructor(
    public dialogRef: MatDialogRef<DialogBoxQuestionsComponent>,
    private questionOptionService: QuestionOptionService, private formBuilder: FormBuilder, private questionService: QuestionService,
    private themeService: ThemeService, private mediaService: MediaService, public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Question) {
    this.localData = { ...data };
    this.action = this.localData.action;
    this.imageName = '';
    this.themeService.getAll().subscribe(themes => {
      this.themes = themes;
    });

    if (this.action != 'Adicionar') {
      this.questionService.getThemesByQuestionId(this.localData.questionId).subscribe(c => { this.themesOfQuestion = c; });

      this.questionOptionService.getByQuestionId(this.localData.questionId).subscribe(questionOptions => {
        this.questionOptions = questionOptions;
        this.listOfAllData = questionOptions;
        for (let index = 0; index < this.questionOptions.length; index++) {
          if (index == 0) {
            this.localData.option1 = (this.questionOptions[index].description == null ? '' : this.questionOptions[index].description);
          }
          if (index == 1) {
            this.localData.option2 = this.questionOptions[index].description == null ? '' : this.questionOptions[index].description;
          }
          if (index == 2) {
            this.localData.option3 = this.questionOptions[index].description == null ? '' : this.questionOptions[index].description;
          }
          if (index == 3) {
            this.localData.option4 = this.questionOptions[index].description == null ? '' : this.questionOptions[index].description;
          }
          if (this.questionOptions[index].isAnswer == true) {
            this.localData.isAnswer = index;
          }
        }
      });
      this.themeService.getAll().subscribe(themes => {
        this.themes = themes;

        this.questionService.getThemesByQuestionId(this.localData.questionId).subscribe(questionTheme => {
          this.selectedThemesData = questionTheme;

          themes.forEach(a => {
            let found = 0;
            questionTheme.forEach(c => {
              if (c["name"] == a["name"]) {
                found = 1;
              }
            });
            if (found == 1) {
              var t = { 'themeId': a["id"], 'value': a["name"], 'selected': true };
              this.themesSelected.push(t);
            }
            else {
              var t = { 'themeId': a["id"], 'value': a["name"], 'selected': false };
              this.themesSelected.push(t);
            }
          });
        });
      });
    }
    if (this.action == 'Adicionar') {
      this.themeService.getAll().subscribe(_themes => {
        this.themes = _themes;
        this.themes.forEach(a => {
          var t = { 'themeId': a["id"], 'value': a["name"], 'selected': false };
          this.themesSelected.push(t);
        });
      });
    }
  }

  isSelected(themeId) {
    if (this.localData.action == 'Modificar') {
      let e = this.selectedThemesData.find(th => th['themeId'] === themeId) ? true : false;
      return e;
    }
    else if (this.localData.action == 'Adicionar') {

    }

  }

  selectedTheme(i) {
    if (this.localData.action == 'Modificar') {
      this.themesSelected[i]["selected"] = this.themesSelected[i]["selected"] == true ? false : true;
    }
    else if (this.localData.action == 'Adicionar') {
      this.themesSelected[i]["selected"] = this.themesSelected[i]["selected"] == true ? false : true;
    }

  }

  getAnswer(i) {
    if (this.localData.isAnswer == i) {
      return true;
    }
    else {
      return false;
    }
  }

  getDisabled(i) {
    const aux = this.localData['option' + (i + 1)];
    if (aux == undefined || aux == '' || aux == null) {
      return true;
    }
    else {
      return false;
    }
  }
  get f() { return this.themesForm.controls; }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
  ngOnInit() {
    this.themesForm = this.formBuilder.group({
      description: ['', Validators.required],
      help: [''],
      themes: ['', Validators.required],
      isAnswer: ['', Validators.required],
      status: ['', Validators.required],
      font: ['', Validators.required],
      questionOption0: [''],
      questionOption1: [''],
      questionOption2: [''],
      questionOption3: ['']
    });
    this.f.font.setValue(this.localData.font == 'IMT' ? 1 : 0);
    console.log(this.f.font.value);
    if (this.action != 'Adicionar') {
      this.mediaService.getImageByQuestionId(this.localData.questionId).pipe().subscribe(
        a => {
          if (a.image != null && a.image != '') {
            this.imageUrl = 'data:image/jpg;base64,' + a.image;
            this.imageName = a.name;
          }
          else {
            this.imageUrl = 'assets/img/img_avatar.png';
            this.imageName = '';
          }
        });
    } else {
      this.imageUrl = 'assets/img/img_avatar.png';
    }

  }

  onSubmit() {
    this.localData.active = this.f.status.untouched ? this.localData.active : this.f.status.value;
    this.localData.description = this.f.description.value;
    this.localData.help = this.f.help.value;
    this.localData.questionOption0 = this.f.questionOption0.value == '' ? this.localData.option1 : this.f.questionOption0.value;
    this.localData.questionOption1 = this.f.questionOption1.value == '' ? this.localData.option2 : this.f.questionOption1.value;
    this.localData.questionOption2 = this.f.questionOption2.value == '' ? this.localData.option3 : this.f.questionOption2.value;
    this.localData.questionOption3 = this.f.questionOption3.value == '' ? this.localData.option4 : this.f.questionOption3.value;
    this.localData.isAnswer = this.questionAnswered == -1 ? this.localData.isAnswer : this.questionAnswered;
    this.localData.font = this.f.font.value == 0 ? 'AlvesCosta' : 'IMT';
    this.localData.themes = [];
    this.themesSelected.forEach(c => {
      if (c["selected"] == true) {
        var x = Number(c["themeId"]);
        this.localData.themes.push(x);
      }
    });
    this.dialogRef.close({ event: this.action, data: this.localData });
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;

    reader.readAsDataURL(files[0]);
    // tslint:disable-next-line: variable-name
    reader.onload = (_event) => {
      this.imageUrl = reader.result;

      // const url: any =  reader.readAsDataURL(files[0]);
      let url = reader.result.toString().split(',')[1];
      this.mediaService.linkImageToQuestion(this.localData.questionId, url,
        files[0].name, files[0].size).pipe(first()).subscribe(user => {
          this.dialogRef.close();
        });
    };
  }

  openDialog(action, e, i) {
    if (e == null || !e.target.className.match('ant-table-column-sorters') && !e.target.className.match('ant-spin-container')) {
      let row: any = {};
      row.action = action;
      row.id = this.themesOfQuestion[i]['themeId'];
      row.name = e == null ? '' : e.path[1].children[0].innerText;
      const dialogRef = this.dialog.open(DialogBoxThemesComponent, {
        width: '70vw',
        height: '90vh',
        minHeight: '90vh',
        data: row
      });
    }
  }
  change(e) {
    this.questionAnswered = e;
    const elem = document.getElementsByClassName('checkbox');
    for (let index = 0; index < elem.length; index++) {
      if (index == e) {
        elem[index].classList.add('checked');
      }
      else {
        elem[index].classList.remove('checked');
      }
    }
  }

  openCity(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("active", "");
    }
    document.getElementById(cityName).style.display = "block";
    document.getElementById(cityName).classList.add("active");
  }
}
