<div class="row searchButton" style="padding-left:2%;padding-bottom:2%">
  <div class="col-6 col-sm-8 col-md-6 col-lg-8 col-xl-8 text-left mt-2">
    <button tc-button class="btn-default" (click)="openFilter()" [beforeIcon]="'icofont-search-document'"
      [view]="'default'">Filtro</button>
  </div>
  <div class="col-3 col-sm-2 col-md-3 col-lg-2 col-xl-2 text-right mt-2">
    <button tc-button class="btn-success" [view]="'success'" [beforeIcon]="'icofont-ui-add'"
      (click)="openDialog('Adicionar',null, null)">Adicionar</button>
  </div>
  <div class="col-3 col-sm-2 col-md-3 col-lg-2 col-xl-2 text-right mt-2">
    <button tc-button class="btn-error" [view]="'error'" [beforeIcon]="'icofont-ui-delete'"
      (click)="removeSelectedRows()">Remover</button>
  </div>

  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right mt-2" *ngIf="numberOfChecked > 0">
    <tc-dropdown class="item" [close]="closeDropdown">
      <tc-dropdown-button>
        <button nz-button [type]="'primary'">Exportar Questões <i
            class="icofont icofont-simple-down"></i></button>
      </tc-dropdown-button>

      <tc-dropdown-content>
        <tc-list class="px-4">
          <tc-list-item *ngFor="let elem of themes; let i= index;">
            <tc-dropdown class="item" [close]="closeDropdown">
              <tc-dropdown-button>
                <span tc-list-line><a>{{elem.name}}</a></span>
              </tc-dropdown-button>
              <tc-dropdown-content>
                <tc-list class="px-4">
                  <tc-list-item *ngFor="let item of elem.categories; let j = index;">
                    <span tc-list-line (click)="export(i,j)"><a>{{item.name}}</a></span>
                  </tc-list-item>
                </tc-list>
              </tc-dropdown-content>
            </tc-dropdown>
          </tc-list-item>
        </tc-list>
      </tc-dropdown-content>
    </tc-dropdown>
    <br>
    <span *ngIf="numberOfChecked" class="small" style="padding-right: 1%;"> {{ numberOfChecked }} questões</span>
  </div>
</div>

<div style="border:1px solid #ccd3d9; border-radius: 10px; background-color: aliceblue;" id="filtro" class="hide">
  <div class="row resetButton" style="padding-bottom: 2%;padding-top: 1%;">
    <button tc-button class="btn-default" [beforeIcon]="'icofont-ui-reply'" (click)="reset()" [view]="'default'">Reset</button>
  </div>
  <div *ngIf="!isMobile()"  class="row" style="padding-left: -2%;padding-bottom: 2%;">
    <div class="col-1" style="padding-top: 7px; text-align: right;">Id:</div>
    <!-- <div class="col-2"><input type="number" [(ngModel)]="idValue" class="form-control"></div> -->
    <div class="col-2"><input type="text" [(ngModel)]="idValue" class="form-control"></div>
    <div class="col-2" style="padding-top: 7px; text-align: right">Descrição:</div>
    <div class="col-2" style="padding-right: 20px;"><input type="text" [(ngModel)]="descValue" class="form-control"></div>
    <div class="col-1" style="padding-top: 7px; text-align: right;">Fonte:</div>
    <div class="col-2"><input type="text" [(ngModel)]="fontValue" class="form-control"></div>
    <div class="row filterButton"  >
      <button tc-button class="btn-success" [view]="'default'" [beforeIcon]="'icofont-search-1'" (click)="filter()">Filtrar</button>
    </div>
  </div>

  <div *ngIf="isMobile()" class="row" style="padding-left:1%;padding-bottom: 2%;">
    <div class="row" style="padding-bottom: 2%;">
      <div class="col-5" style="padding-top: 7px; text-align: right; padding-right:22%;">Id:</div>
      <div class="col-7"><input type="text" [(ngModel)]="idValue" class="form-control"></div>
   </div>
   <div class="row" style="padding-bottom: 2%;">
    <div class="col-5" style="padding-top: 7px; text-align: center">Descrição:</div>
    <div class="col-7"><input type="text" [(ngModel)]="descValue" class="form-control"></div>
  </div>
    <div class="row" style="padding-bottom: 2%;">
      <div class="col-5" style="padding-top: 7px; text-align: right;padding-right:12%;">Fonte:</div>
      <div class="col-7"><input type="text" [(ngModel)]="fontValue" class="form-control"></div>
    </div>
    <div class="row filterButton"  >
      <button tc-button class="btn-success" [view]="'default'" [beforeIcon]="'icofont-search-1'" (click)="filter()">Filtrar</button>
    </div>
  </div>
</div>
  



<!-- </div> -->

<tc-card [padding]="'20px 0'" [title]="'Questões'">
  <nz-table #rowSelectionTable [nzData]="listOfAllData" [nzBordered]="bordered" nzShowSizeChanger
    [nzPaginationPosition]="position" (nzCurrentPageDataChange)="currentPageDataChange($event)" style="overflow: auto;">
    <thead (nzSortChange)="sort($event)" nzSingleSort class="noClick">
      <tr>
        <th nzShowCheckbox [(nzChecked)]="isAllDisplayDataChecked" [nzIndeterminate]="isIndeterminate"
          (nzCheckedChange)="checkAll($event)"></th>
        <th nzShowSort nzSortKey="oldQuestionId">Id</th>
        <th nzShowSort nzSortKey="description">Descrição</th>
        <th nzShowSort nzSortKey="font">Fonte</th>
        <th nzShowSort nzSortKey="systemDt">Data</th>
        <th>Ativo</th>
      </tr>
    </thead>
    <tbody style="cursor: pointer;">
      <tr *ngFor="let data of rowSelectionTable.data; let i = index;" (click)="select($event,i)" [attr.id]="i"
        (dblclick)="openDialog('Modificar', $event, data)">
        <td style="width: 5%; text-align: right;" nzShowCheckbox [(nzChecked)]="mapOfCheckedId[data.questionId]"
          (nzCheckedChange)="refreshStatus()"></td>
        <!-- <td style="width: 10%;">{{ data.oldQuestionId | replace:'[a-zA-z]':''}}</td> -->
        <td style="width: 10%;">{{ data.oldQuestionId }}</td>
        <td style="width: 65%;">{{ data.description }}</td>
        <td style="width: 5%; text-align: center;">{{ data.font }}</td>
        <td style="width: 10%; text-align: center;">{{data.systemDt | date: 'dd/MM/yyyy'}}</td>
        <td style="width: 5%; text-align: center;" nzShowCheckbox [(nzChecked)]="data.active" [nzDisabled]="true"></td>
      </tr>
    </tbody>
  </nz-table>
</tc-card>