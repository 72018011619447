<div class="row">
  <div class="col-12 col-xl-8">
    <tc-card class="our-card our-border">
      <div tc-card-header class="title-box">
        <h6 class="title mb-1">Resumo</h6>
        <span class="sub-title">{{categoryName}}</span>
      </div>
      
      <div class="align-items-end chart-container container-h-160 mt-3">
        <nz-table #basicTable [nzShowPagination]="false">
          <thead>
            <tr>
              <th></th>
              <th class="text-center">Realizado</th>
              <th class="text-center">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="person-block">
                  Tempo Despendido
                </div>
              </td>
              <td class="text-center">{{doneTime}}</td>
              <td class="text-center">{{totalTime}}</td>
            </tr>
            <tr>
              <td>
                <div class="person-block">
                  Tema/SubTema
                </div>
              </td>
              <td class="text-center">{{lessonDone}}</td>
              <td class="text-center">{{lessonTotal}}</td>
            </tr>
            <tr>
              <td>
                <div class="person-block">
                  Testes
                </div>
              </td>
              <td class="text-center">{{examesDone}}</td>
              <td class="text-center">{{examesTotal}}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
      <tc-spinner [show]="loading"></tc-spinner>
    </tc-card>
  </div>
  <div class="col-12 col-xl-4">
    <tc-card class="our-card our-border">
      <div tc-card-header class="title-box">
        <h6 class="title mb-1">% Concretização</h6>
        <span class="sub-title">
          {{categoryName}}
        </span>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center chart-container container-h-160 mt-3">
        <tc-spinner [show]="loading"></tc-spinner>
        <tc-circle-progress [value]="concludedGeneral" [size]="'160px'" [strokeWidth]="2" [secondaryColor]="'#0459A0'"></tc-circle-progress>
      </div>
    </tc-card>
  </div>
</div>


<div class="mb-3">
  <div *ngIf="this.showDayClosed && this.dailyResume">
    <div class="m-1" *ngIf="!this.dailyResume.dayClosed">
      <span class="sub-title">Limite diário de permanência</span>
      <span class="sub-title" style="float: right;" >{{this.dailyCurrent}}/{{this.dailyMax}}</span>
      <tc-progress [percent]="false" color="#032F54" [progress]="this.dailyResume.percentage" [size]="'sm'" class="percentPanel"></tc-progress>
    </div>

    <div class="error text-center mb-4" style="line-height: 30px; background-color: white;" *ngIf="this.dailyResume.dayClosed">
      O limite diário(4h) de permanência na plataforma, foi atingido. Por favor regresse amanhã.
    </div>
  </div> 

  <mat-accordion>
    <mat-expansion-panel class="mat-expansion-panel-first" [expanded]="!this.comeFromDashboard">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h5 class="hardTitle">Aulas</h5>
        </mat-panel-title>
      </mat-expansion-panel-header>
  
      <div class="row our-mat-expansion-content-body">
        <div class="col-12 col-xl-12">
          <div tc-card-header class="title-box text-left">
            <span class="sub-title" style="padding-left: 5px;">{{categoryName}}</span>
          </div>
          <tc-spinner [show]="loading"></tc-spinner>
          <table mat-table [dataSource]="dataSource" style="width:97%; border: 1px; margin:auto;">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef width="70%">
                <span [style.paddingLeft.px]="40"></span>
              </th>

              <td mat-cell *matCellDef="let data"
              (click)="data.isLeaf ? openLesson(data.userElearningLessonId) : openNode(data)" [ngClass]="!this.comeFromDashboard ?'itemLession' : 'itemLessionNoPointer'"
                [style.paddingLeft.px]="this.utils.isMobileDevice() ? 0 : 24">
                <div [style.marginLeft.px]="this.utils.isMobileDevice() ? (data.level - 1) * 5 : (data.level - 1) * 32">
                  <div class="iconBox">
                    <button mat-icon-button [style.visibility]="!data.expandable ? 'hidden' : ''">
                      <mat-icon class="mat-icon-rtl-mirror">
                        <span [class]="data.cssColor">
                          {{treeControl.isExpanded(data) ? 'expand_more' : 'chevron_right'}}
                        </span>
                      </mat-icon>
                    </button>
                  </div>
                  <div class="titleBox">
                    <span [class]="data.cssLevel + ' ' + data.cssColor">
                      {{data.name}}
                    </span>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="view">
              <th mat-header-cell *matHeaderCellDef width="10%" class="text-center">&nbsp;Conteúdos&nbsp;</th>
              <td mat-cell *matCellDef="let data" class="text-center">
                <button (click)="openLesson(data.userElearningLessonId)" tc-button
                  *ngIf="!data.isClosed && !data.isEvaluation && data.isLeaf" [tcBgColor]="'#FE5076'" [size]="'sm'"
                  [afterIcon]="'icofont-play-alt-1'" [square]="true" [view]="'error'"></button>
                <button (click)="openLesson(data.userElearningLessonId)" tc-button
                  *ngIf="data.isClosed && !data.isEvaluation && data.isLeaf" [tcBgColor]="'#A6CE3A'" [size]="'sm'"
                  [afterIcon]="'icofont-play-alt-1'" [square]="true" [view]="'success'"></button>

                <button (click)="openLesson(data.userElearningLessonId)" tc-button
                  *ngIf="data.isEvaluation && !data.isClosed && data.testId > 0 && !this.testUnavailable" [tcBgColor]="'#FE5076'" [size]="'sm'"
                  [afterIcon]="'icofont-spinner-alt-3'" title="Refazer teste" [square]="true"
                  [view]="'error'"></button>
                <button (click)="openLesson(data.userElearningLessonId)" tc-button
                  *ngIf="data.isEvaluation && !data.isClosed && data.testId == 0 && !this.testUnavailable" [tcBgColor]="'#FE5076'"
                  [size]="'sm'" [afterIcon]="'icofont-close-squared'" title="Fazer teste" [square]="true"
                  [view]="'info'"></button>
                <button (click)="openLesson(data.userElearningLessonId)" tc-button
                  *ngIf="data.isEvaluation && data.isClosed" [tcBgColor]="'#A6CE3A'" [size]="'sm'"
                  [afterIcon]="'icofont-eye-alt'" [square]="true" [view]="'success'"
                  title="Visualizar teste"></button>
              </td>
            </ng-container>

            <ng-container matColumnDef="time">
              <th mat-header-cell *matHeaderCellDef width="10%" class="text-center">Duração</th>

              <td mat-cell *matCellDef="let data" [attr.colspan]='data.isEvaluation?2:1' class="text-center">
                <div *ngIf="!data.isEvaluation">
                  <span [class]="data.cssLevel + ' ' + data.cssColor" *ngIf="!data.isEvaluation">{{data.time}} </span>
                </div>

                <div *ngIf="data.isEvaluation">
                  <tc-badge [view]="'danger'" *ngIf="data.isEvaluation && !data.isClosed && data.testId > 0"
                    [size]="'sm'">Reprovado</tc-badge>
                  <tc-badge [view]="'danger'" *ngIf="data.isEvaluation && !data.isClosed && data.testId == 0"
                    [size]="'sm'">Não realizado</tc-badge>
                  <tc-badge [view]="'success'" *ngIf="data.isEvaluation && data.isClosed" [size]="'sm'">Aprovado
                  </tc-badge>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="done">
              <th class="text-center" mat-header-cell *matHeaderCellDef width="10%" class="text-center"
              [style.paddingRight.px]="this.utils.isMobileDevice() ? 0 : 24"
              >&nbsp;Realizado&nbsp;</th>
              <td class="text-center" mat-cell *matCellDef="let data"
                [attr.class]="data.isEvaluation?'hideIt':'text-center mat-cell cdk-column-done mat-column-done ng-star-inserted'" class="text-center"
                [style.paddingRight.px]="this.utils.isMobileDevice() ? 0 : 24"
                >
                <span [class]="data.cssLevel + ' ' + data.cssColor">
                  {{data.done}}%
                </span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>
  
  
    </mat-expansion-panel>
  
    <mat-expansion-panel class="mat-expansion-panel-last">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h5 class="hardTitle">Testes realizados no âmbito do Ensino à Distância</h5>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
  
  
      <div class="row our-mat-expansion-content-body" *ngIf="categoryId != 0">
        <div class="col-12 col-xl-12">
            <div tc-card-header class="title-box text-left">
              <span class="sub-title" style="padding-left: 5px;">{{categoryName}}</span>
            </div>
            <tc-spinner [show]="loading"></tc-spinner>
            <nz-table #rowSelectionTable [nzData]="listOfAllData" [nzBordered]="bordered" [(nzPageSize)]="pageSize"
              [nzPaginationPosition]="position" (nzCurrentPageDataChange)="currentPageDataChange($event)"
              (nzPageIndexChange)="custom($event)" style="overflow: auto; width:97%; border: 1px; margin:auto;" class="text-center">
              <thead (nzSortChange)="sort($event)" nzSingleSort class="noClick">
                <thead (nzSortChange)="sort($event)" nzSingleSort>
                  <tr class="noClick">
                    <th class="text-center">Ensino à distância</th>
                    <th class="text-center" *ngIf="!utils.isMobileDevice()">Tema de Perguntas</th>
                    <th class="text-center">Resultado</th>
                    <th class="text-center">Data</th>
                  </tr>
                </thead>
              <tbody style="cursor: pointer;">
                <tr *ngFor="let data of rowSelectionTable.data; let i = index;" (click)="openDialog($event, i)">
                  <td class="text-center">{{data.categoryName}}</td>
                  <td class="text-left" *ngIf="!utils.isMobileDevice()">
                    <div [innerHTML]="data.testDefinition"></div>
                  </td>
                  <td class="text-center">
                    <tc-badge *ngIf="data.aproved == true" [size]="'sm'" style="width: 125px;" [view]="'success'">Aprovado</tc-badge>
                    <tc-badge *ngIf="data.aproved == false" [size]="'sm'" style="width: 125px;" [view]="'danger'">
                      Reprovado</tc-badge>
                  </td>
                  <td class="text-center">{{data.endDt | date: 'dd/MM/yyyy H:mm:ss'}}</td>
                </tr>
              </tbody>
            </nz-table>
        </div>
      </div>
  
  
    </mat-expansion-panel>
  </mat-accordion>
</div>