var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AuthService } from '../../../../services/auth.service';
import { MediaService } from '../../../../services/media.service';
var DialogBoxActivateTicketComponent = /** @class */ (function () {
    function DialogBoxActivateTicketComponent(dialogRef, authenticationService, mediaService, data) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.authenticationService = authenticationService;
        this.mediaService = mediaService;
        this.data = data;
        this.isSelected = true;
        this.tickets = [];
        this.b64toBlob = function (b64Data, contentType, sliceSize) {
            if (contentType === void 0) { contentType = ''; }
            if (sliceSize === void 0) { sliceSize = 512; }
            var byteCharacters = atob(b64Data);
            var byteArrays = [];
            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);
                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            var blob = new Blob(byteArrays, { type: contentType });
            return blob;
        };
        this.localData = __assign({}, data);
        this.authenticationService.currentUser.subscribe(function (x) { return (_this.currentUser = x); });
        if (this.localData.tickets != undefined && this.localData.tickets != null) {
            this.localData.tickets.forEach(function (element) {
                if (element['activateDate'] == null) {
                    _this.tickets.push(element);
                }
            });
        }
        this.mediaService.getRGPD().subscribe(function (a) {
            console.log(a.url);
            var fileBlob = _this.b64toBlob(a.url, 'application/pdf');
            _this.rgpd = URL.createObjectURL(fileBlob);
            // window.open(encodeURI(fileBlob + '#toolbar=0&navpanes=0'));
        });
    }
    DialogBoxActivateTicketComponent.prototype.ngOnInit = function () { };
    DialogBoxActivateTicketComponent.prototype.activate = function () {
        if (this.isSelected == true) {
            this.dialogRef.close({ event: 'activate', data: this.tickets, agreed: this.isSelected });
        }
    };
    DialogBoxActivateTicketComponent.prototype.out = function () {
        this.dialogRef.close({});
    };
    DialogBoxActivateTicketComponent.prototype.changeValue = function () {
        this.isSelected = this.isSelected == true ? false : true;
    };
    DialogBoxActivateTicketComponent.prototype.changeTicket = function (i) {
        var _this = this;
        if (this.tickets.findIndex(function (c) { return c['userTicketId'] === _this.localData.tickets[i]['userTicketId']; }) != -1) {
            this.tickets.splice(this.tickets.findIndex(function (c) { return c['userTicketId'] === _this.localData.tickets[i]['userTicketId']; }), 1);
        }
        else {
            this.tickets.push(this.localData.tickets[i]);
        }
    };
    DialogBoxActivateTicketComponent.prototype.open = function () {
        window.open(encodeURI(this.rgpd + '#toolbar=0&navpanes=0'));
    };
    return DialogBoxActivateTicketComponent;
}());
export { DialogBoxActivateTicketComponent };
