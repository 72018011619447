import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Theme } from '../../../../models/Theme';
import { Category } from '../../../../models/Category';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CategoryService } from '../../../../services/category.service';
import { QuestionService } from '../../../../services/question.service';
import { Question } from '../../../../models/question';
import { first } from 'rxjs/internal/operators/first';
import { DialogBoxQuestionsComponent } from '../dialog-box-questions/dialog-box-questions.component';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-dialog-box-themes',
  templateUrl: './dialog-box-themes.component.html',
  styleUrls: ['./dialog-box-themes.component.css']
})
export class DialogBoxThemesComponent implements OnInit {
  bordered = true;
  position = 'bottom';
  listOfDisplayData: Question[] = [];
  listOfAllData: Question[] = [];
  list: Question[] = [];
  sortName: string | null = null;
  sortValue: string | null = null;
  themesForm: FormGroup;
  themes: Theme;
  categories: Category[] = [];
  action: string;
  localData: any;
  categoriesF: FormGroup;
  categoriesMultiple: FormGroup;
  idValue = '';
  descValue = '';
  fontValue = '';
  selectedCategoriesData: Category[] = [];
  categoriesSelected: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<DialogBoxThemesComponent>,
    private categoryService: CategoryService, private formBuilder: FormBuilder, private questionService: QuestionService,
    public dialog: MatDialog, private toastr: ToastrService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Category) {
    this.localData = { ...data };
    this.action = this.localData.action;

    this.categoryService.getAll().subscribe(categories => {
      this.categories = categories.sort((prev, curr) => curr.CategoryName > prev.CategoryName ? -1 : 1);

      console.log(this.categories);
    });

    if (this.action != 'Adicionar') {
      this.questionService.getQuestionsByTheme(this.localData.id).subscribe(c => {
        this.listOfAllData = c;
        this.list = c;
      });
    }

    this.categoryService.getAll().subscribe(a => { this.categories = a });
    this.categoryService.getCategoryByThemeId(this.localData.id).subscribe(categoriesTheme => {
      this.selectedCategoriesData = categoriesTheme;

      this.categories.map(a => {
        let found = 0;
        this.selectedCategoriesData["name"].forEach(c => {
          if (c["name"] == a["name"]) {
            found = 1;
          }
        });

        if (found == 1) {
          var cat = { 'id': a["categoryId"], 'value': a["name"], 'selected': true };
          this.categoriesSelected.push(cat);
        }
        else {
          var cat = { 'id': a["categoryId"], 'value': a["name"], 'selected': false };
          this.categoriesSelected.push(cat);
        }
      });
    });
  }

  selectCategory(i) {
    this.categoriesSelected[i]["selected"] = this.categoriesSelected[i]["selected"] == true ? false : true;
  }

  isSelected(categoryId) {
    const e = this.selectedCategoriesData['name'] == undefined ? -1 : this.selectedCategoriesData['name'].findIndex(c => c['categoryId'] === categoryId);

    return e == -1 ? false : true;
  }


  get f() { return this.themesForm.controls; }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  ngOnInit() {
    this.themesForm = this.formBuilder.group({
      name: ['', Validators.required],
      categoriesF: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.action != 'Adicionar') {
      this.localData.categories = []
      this.categoriesSelected.forEach(c => {
        if (c["selected"] == true) {
          var x = Number(c["id"]);
          this.localData.categories.push(x);
        }
      })

      this.localData.name = this.f.name.value;

      this.dialogRef.close({ event: this.action, data: this.localData });
    }
    else {
      this.localData.categories = []
      this.categoriesSelected.forEach(c => {
        if (c["selected"] == true) {
          var x = Number(c["id"]);
          this.localData.categories.push(x);
          console.log(x)
        }
      })
      this.localData.name = this.f.name.value;

      this.dialogRef.close({ event: this.action, data: this.localData });
    }


  }
  currentPageDataChange($event: Question[]): void {
    this.listOfDisplayData = $event;
  }

  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.listOfAllData = this.list;
    this.listOfDisplayData = this.list;
    this.search('');
  }

  reset(): void {
    this.idValue = '';
    this.descValue = '';
    this.fontValue = '';
    this.listOfAllData = this.list;
    this.search('');
  }

  select(event) {
    if (event.path[1].className.match('active')) {
      event.path[1].classList.remove('active');
    }
    else {
      event.path[1].classList.add('active');
    }
  }

  search(type: string): void {
    const filterFunc = (item: Question) => {
      let x = '' + item['questionId'];
      let y = item['description'];
      let z = item['font'];
      return (
        type == 'id' ? x.indexOf(this.idValue) !== -1 : type == 'desc' ? y.indexOf(this.descValue) !== -1 : z.indexOf(this.fontValue) !== -1
      );
    };
    const data = this.listOfAllData.filter((item: Question) => filterFunc(item));
    this.listOfAllData = data.sort((a, b) =>

      this.sortValue === 'ascend'
        ? a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );
  }

  openDialog(action, e) {
    if (e == null || !e.target.className.match('ant-table-column-sorters') && !e.target.className.match('ant-spin-container')) {
      let row: any = {};
      row = e == null ? {} : this.listOfAllData[this.listOfAllData.findIndex(d => d['description'].trim() === e.path[1].children[1].innerText.trim())];
      row.action = action;
      const dialogRef = this.dialog.open(DialogBoxQuestionsComponent, {
        width: '99%',
        maxWidth: '850px',
        height: 'auto',
        data: row
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === undefined) { }
        else if (result.event == 'Adicionar') {
          this.addRowData(result.data);
        } else if (result.event == 'Modificar') {
          this.updateRowData(result.data);
        }
      });
    }
  }

  addRowData(rowObj) {
    this.questionService.createQuestion(rowObj.description, rowObj.help, rowObj.questionOption0, rowObj.questionOption1,
      rowObj.questionOption2, rowObj.questionOption3, rowObj.isAnswer, rowObj.active, rowObj.font, rowObj.themeId
    ).subscribe((data) => {
      this.questionService.getAll().pipe(first()).subscribe(questions => {
        this.listOfAllData = questions;
        this.list = questions;
      });
      this.toastr.success('Questão criada com sucesso!');
    },
      err => {
        this.toastr.error('Algo ocorreu mal!');
      });
  }

  updateRowData(rowObj) {
    this.questionService.updateQuestion(rowObj.questionId, rowObj.description, rowObj.help, rowObj.questionOption0, rowObj.questionOption1,
      rowObj.questionOption2, rowObj.questionOption3, rowObj.isAnswer, rowObj.active, rowObj.font, rowObj.themeId).subscribe((data) => {
        this.questionService.getAll().pipe(first()).subscribe(questions => {
          this.listOfAllData = questions;
          this.list = questions;
        });
        this.toastr.success('Questão modificada com sucesso!');
      },
        err => {
          this.toastr.error('Algo ocorreu mal!');
        });
  }

  opened = false;

  openFilter() {
    const el = document.getElementById('filtro');
    if (this.opened == false) {
      el.classList.add('show');
      el.classList.remove('hide');
      this.opened = true;
    }
    else {
      el.classList.add('hide');
      el.classList.remove('show');
      this.opened = false;
    }
  }

  filter() {
    this.listOfAllData = this.list;
    if (this.idValue != '') { this.search('id'); }
    if (this.descValue != '') { this.search('desc'); }
    if (this.fontValue != '') { this.search('font'); }
  }

  openCity(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("active", "");
    }
    document.getElementById(cityName).style.display = "block";
    document.getElementById(cityName).classList.add("active");
  }
}
