var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../base-page';
import { HttpService } from '../../../services/http/http.service';
import { environment } from '../../../../environments/environment';
var PageAnalyticsComponent = /** @class */ (function (_super) {
    __extends(PageAnalyticsComponent, _super);
    function PageAnalyticsComponent(store, httpSv) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.tableData = [];
        _this.pageData = {
            title: '',
            loaded: true
        };
        return _this;
    }
    PageAnalyticsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.getData(environment.apiUrl + "/Company/", 'tableData', 'setLoaded');
    };
    PageAnalyticsComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return PageAnalyticsComponent;
}(BasePageComponent));
export { PageAnalyticsComponent };
