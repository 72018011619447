var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Category } from '../../../../models/Category';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { CategoryService } from '../../../../services/category.service';
import { ProductService } from '../../../../services/product.service';
var DialogBoxCategoriesComponent = /** @class */ (function () {
    function DialogBoxCategoriesComponent(dialogRef, categoryService, formBuilder, productService, data) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.categoryService = categoryService;
        this.formBuilder = formBuilder;
        this.productService = productService;
        this.data = data;
        this.loading = false;
        this.submitted = false;
        this.products = [];
        this.localData = __assign({}, data);
        this.action = this.localData.action;
        if (this.action != 'Adicionar') {
            this.productService.getProductByCategoryId(this.localData.id).subscribe(function (product) {
                _this.f.productsF.setValue(product.name);
            });
        }
        this.productService.getAll().subscribe(function (products) {
            _this.products = products;
        });
    }
    Object.defineProperty(DialogBoxCategoriesComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.categoriesForm.controls; },
        enumerable: true,
        configurable: true
    });
    DialogBoxCategoriesComponent.prototype.closeDialog = function () {
        this.dialogRef.close({ event: 'Cancel' });
    };
    DialogBoxCategoriesComponent.prototype.ngOnInit = function () {
        this.categoriesForm = this.formBuilder.group({
            name: ['', Validators.required],
            productsF: ['', Validators.required]
        });
    };
    DialogBoxCategoriesComponent.prototype.onSubmit = function () {
        var _this = this;
        var product = this.products.filter(function (item) {
            return item.name === _this.f.productsF.value;
        })[0];
        this.localData.productId = product['productId'];
        this.localData.name = this.f.name.value;
        this.dialogRef.close({ event: this.action, data: this.localData });
    };
    return DialogBoxCategoriesComponent;
}());
export { DialogBoxCategoriesComponent };
