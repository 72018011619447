import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import { AuthService } from '../../../../services/auth.service';
import { CamWizardComponent } from '../../Tests/camWizard/camWizard.component';
import { CategoryService } from '../../../../services/category.service';
import { Content } from '../../../../ui/interfaces/modal';
import { ELearningService } from 'src/app/services/elearning.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { HttpService } from '../../../../services/http/http.service';
import { IAppState } from '../../../../interfaces/app-state';
import { ITreeOptions } from 'angular-tree-component';
import { Lesson } from '../../Lesson/lesson';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { ShowTestComponent } from '../../show-test/show-test.component';
import { ShowcamTestComponent } from '../../showCamTest/showCamTest.component';
import { Store } from '@ngrx/store';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { TestService } from '../../../../services/test.service';
import { TicketService } from 'src/app/services/ticket.service';
import { Utils } from 'src/utils/utils';
import { WizardComponent } from '../../Tests/wizard/wizard.component';
import { first } from 'rxjs/operators';

export class LessonNode {
  lessonId: number;
  userElearningLessonId: number;
  name: string;
  time: string;
  done: number;
  isEvaluation: boolean;
  isClosed: boolean;
  isLeaf: boolean;
  cssLevel: string;
  cssColor: string;
  children: LessonNode[];
  treeOpened: boolean;
  testId: number;
}

interface FlatNode {
  lessonId: number;
  userElearningLessonId: number;
  name: string;
  time: string;
  done: number;
  isEvaluation: boolean;
  isClosed: boolean;
  isLeaf: boolean;
  cssLevel: string;
  cssColor: string;
  level: number;
  expandable: boolean;
  treeOpened: boolean;
  testId: number;
}

var options: ITreeOptions = {
  displayField: 'name',
  useVirtualScroll: false,
  nodeHeight: 25,
  allowDrag: false,
  allowDrop: false
};

@Component({
  selector: 'app-elearning-student',
  templateUrl: './elearning-student.component.html',
  styleUrls: ['./elearning-student.component.scss']
})

export class ELearningStudentComponent implements OnInit, OnChanges {
  @Input() userId: number = 0;
	@Input() categoryId: number = 0;
  @Input() categoryName: string;
  @Input() comeFromDashboard: boolean = false;
  public loading = false;

  testUnavailable = false;
  
  displayedColumns: string[] = ['name', 'view', 'time', 'done'];
  TREE_DATA: LessonNode[] = [];
  treeControl: any = null;
  treeFlattener: any = null;
  dataSource: any = null;
  resume: any = null;
  dailyResume: any;
  dailyCurrent: any;
  dailyMax: any;

  showDayClosed: boolean = true;
  totalTime: any = 0;
  doneTime: any = 0;
  lessonTotal: any = 0;
  lessonDone: any = 0;
  examesTotal: any = 0;
  examesDone: any = 0;
  concludedGeneral: any = 0;

  userELearningId: number = 0;
  currentUser: any;

  hideResults: boolean = true;
  bordered = true;
  position = 'both';
  currentPage = 0;
  pageSize = 10;
  searchValue = '';
  isCam: boolean;
  listOfDisplayData: any[] = [];
  listOfAllData: any[] = [];
  sortName: string | null = null;
  sortValue: string | null = null;


  private transformer = (node: LessonNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      lessonId: node.lessonId,
      userElearningLessonId: node.userElearningLessonId,
      time: node.time,
      done: node.done,
      isEvaluation: node.isEvaluation,
      isClosed: node.isClosed,
      isLeaf: node.isLeaf,
      cssLevel: node.cssLevel,
      cssColor: node.cssColor,
      level: level,
      treeOpened: node.treeOpened,
      testId: node.testId
    };
  }


  constructor(store: Store<IAppState>, public dialog: MatDialog, private testService: TestService, private categoryService: CategoryService, private authenticationService: AuthService,
    private ticket: TicketService,
    private elearning: ELearningService,
    httpSv: HttpService, private location: Location,
    public utils: Utils,
    private modal: TCModalService) {

    this.authenticationService.currentUser.subscribe(x => 
      {
        this.currentUser = x;
        this.showDayClosed = (this.comeFromDashboard === false) && (this.currentUser.user.id === this.userId);
      });
  }

  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, options: any = null) {
    this.modal.open({
      body: body,
      header: header,
      footer: footer,
      options: options
    });
  }

  closeModal() {
    this.modal.close();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.utils.isMobileDevice() || this.comeFromDashboard)
      this.displayedColumns = ['name', 'time', 'done'];

    this.showDayClosed = (this.comeFromDashboard === false) && (this.currentUser.user.id === this.userId);

    this.populateELearnSelected(this.categoryId);
  }

  hasChild = (_: number, node: LessonNode) => !!node.children && node.children.length > 0;

  ngOnInit() {
    //console.log('this.comeFromDashboard');
    //console.log(this.comeFromDashboard);

  }

  populateELearnSelected(categoryId:any) {
    this.loading = true;
    console.log();

    this.elearning.getELearningResume(this.userId, categoryId).subscribe(resume => {
        //this.loading = false;
        this.hideResults = false;
        //console.log(resume);

        var index = 0;

        this.TREE_DATA = [];

        this.resume = resume;

        //console.log(this.resume);

        resume.forEach(element => {
          if(index == 0)
          {
            this.totalTime = element.time;
            this.doneTime = element.timeSpend;
            this.lessonTotal = element.number;
            this.lessonDone = element.closedLesson;
            this.examesTotal = element.totalExames;
            this.examesDone = element.totalExamesClosed;
            this.concludedGeneral = Math.round(element.concludedPercentage * 100.0);
            this.userELearningId = element.userELearningId;
            this.dailyResume = element.today;
            let h = Math.floor(element.today.totalSeconds / 3600);
            let m = Math.floor(element.today.totalSeconds % 3600 / 60);
            this.dailyCurrent = h + "h " + m + "min";
            h = Math.floor(element.today.max / 3600);
            m = Math.floor(element.today.max % 3600 / 60);
            this.dailyMax = h + "h " + m + "min";

            if(this.dailyResume.dayClosed)
            {
              this.showDayClosed = (this.comeFromDashboard === false) && (this.currentUser.user.id === this.userId);
              //this.comeFromDashboard = true; //readonly everthyng
              this.testUnavailable = true; //Test turn off
            }
              
          }
          else
          {
            var lesson = new LessonNode();
            lesson.lessonId = element.lessonId;
            lesson.userElearningLessonId = element.userElearningLessonId;
            lesson.name = element.number + ' - ' + element.description;
            lesson.time = element.time;
            lesson.done = Math.round(element.concludedPercentage * 100.0);
            lesson.cssLevel = 'itemLesson' + element.level.toString();
            lesson.isEvaluation = element.isEvaluation == 1;
            lesson.isClosed = element.isClosed == 1;
            lesson.isLeaf = element.isLeaf == 1;
            lesson.treeOpened = element.treeOpened;
            lesson.testId =  element.testId;
            lesson.children = [];
            var parentId = element.lessonParent;
   
            if(lesson.isClosed)
              lesson.cssColor = 'itemClose';
            else
            {
              if(lesson.done > 0) lesson.cssColor = 'itemHalfway';
              else lesson.cssColor = 'itemOpen';
            }
  
            element.LessonNode = lesson;
  
            if(parentId == 0) 
              this.TREE_DATA.push(lesson);
            else
            {
              var lessonFnd = resume.find(x => x.lessonId == parentId);
              lessonFnd.LessonNode.children.push(lesson);
            }
          }
          index++;
        });

        this.treeControl = new FlatTreeControl<FlatNode>(node => node.level, node => node.expandable);
        this.treeFlattener = new MatTreeFlattener(this.transformer, node => node.level, node => node.expandable, node => node.children);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        this.dataSource.data = this.TREE_DATA;

        this.restoreExpandedNodes(this.resume.filter(x => x.treeOpened == true));

        this.listTests();

        this.loading = false;
    },
    error => {
      this.loading = false;
      //console.log(error);
      this.hideResults = true;
    }
    );
  }

  restoreExpandedNodes(expandedNodes: Array<any>) {
    //console.log(expandedNodes);

    Array.from(expandedNodes, child => {
      //console.log(child.userElearningLessonId);
      this.treeControl.expand(this.treeControl.dataNodes.find(n => n.userElearningLessonId === child.userElearningLessonId));
    });
  }

  openLesson(id: any)
  {
    /*console.log('this.comeFromDashboard');
    console.log(this.comeFromDashboard);
    console.log(id);*/

    if(this.comeFromDashboard) return;

    var row = this.resume.find(x => x.userElearningLessonId == id);
   
    if(row.isEvaluation)
    {
      if(row.isClosed)
      {
        this.showTest(row.testId);
      }
      else
      {
        if(this.verifyNodes(row)) //Verify if brother are closed
        {
          if(!this.testUnavailable){
            console.log('Open');
            this.openNewTest(row);
          }
        }
        else
        {
          this.openModal('Este teste tem precedências obrigatórias. Por favor feche os temas, subtemas ou testes anteriores para conseguir realizar este teste.', 'Precedência Obrigatória', null, { closeButton: true, overlayClose: false })
        }
      }
    }
    else
    {
      this.openCommonLession(row);
    }
  }

  openCommonLession(row: any)
  {
    const deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxHeight  = this.utils.isMobileDevice() ? '90vh' : '90vh';

    row.dayClosed = this.dailyResume.dayClosed;

    const dialogRef = this.dialog.open(Lesson, {
      id: 'lesson',
      width: deviceWidth,
      maxWidth: deviceMaxWidth,
      height: 'auto',
      maxHeight: deviceMaxHeight,
      disableClose: true,
      closeOnNavigation: true,
      panelClass: 'custom-modalbox',
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialog.closeAll();
      this.populateELearnSelected(this.categoryId);
    });
  }

  openNewTest(row: any)
  {
    //console.log(row);

    const el = document.querySelectorAll('li.form-control.active');
    const deviceWidth = this.utils.isMobileDevice() == true ? '99vw' : '90vw';
    const deviceMaxWidth = this.utils.isMobileDevice() == true ? '99vw' : '90vw';
    const deviceMaxHeight  = this.utils.isMobileDevice() ? '90vh' : '90vh';

    var selectedThemes = [];

    row.testMaterial.forEach(elem => {
      selectedThemes.push(Number(elem.themeId));
    });

    if (selectedThemes.length != 0) {
      var data = { 'type': 'Tematico', 'id': this.categoryId, 'themes': selectedThemes, 'font': 'AlvesCosta', 'origin' : 'elearn', userElearningLessonId: row.userElearningLessonId };

      //Batota
      if(this.categoryId == 16 || this.categoryId == 17)
      {
        const dialogRef = this.dialog.open(CamWizardComponent, {
          id: 'wizard',
          width: deviceWidth,
          maxWidth: deviceMaxWidth,
          height: 'auto',
          maxHeight: deviceMaxHeight,
          disableClose: true,
          closeOnNavigation: true,
          data: data,
          panelClass: 'custom-modalbox'
        });

        dialogRef.afterClosed().subscribe(result => {
          this.dialog.closeAll();
          this.populateELearnSelected(this.categoryId);
        });
      }
      else
      {
        const dialogRef = this.dialog.open(WizardComponent, {
          id: 'wizard',
          width: deviceWidth,
          maxWidth: deviceMaxWidth,
          height: 'auto',
          maxHeight: deviceMaxHeight,
          disableClose: true,
          closeOnNavigation: true,
          data: data,
          panelClass: 'custom-modalbox'
        });

        dialogRef.afterClosed().subscribe(result => {
          this.dialog.closeAll();
          this.populateELearnSelected(this.categoryId);
        });
      }
        
    }
  }


  showTest(testId: number)
  {
    let row: any = {};
    const deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxHeight  = this.utils.isMobileDevice() ? '90vh' : '90vh';

    row.testId = testId;
    row.dashboard = false;
    row.origin = 'history';

    console.log(this.categoryId);
    //Batota
    if(this.categoryId == 16 || this.categoryId == 17)
    {
      const dialogRef = this.dialog.open(ShowcamTestComponent, {
        id: 'wizard',
        width: deviceWidth,
        maxWidth: deviceMaxWidth,
        height: 'auto',
        maxHeight: deviceMaxHeight,
        disableClose: true,
        closeOnNavigation: true,
        panelClass: 'custom-modalbox',
        data: row
      });
    }
    else
    {
      const dialogRef = this.dialog.open(ShowTestComponent, {
        id: 'wizard',
        width: deviceWidth,
        maxWidth: deviceMaxWidth,
        height: 'auto',
        maxHeight: deviceMaxHeight,
        disableClose: true,
        closeOnNavigation: true,
        panelClass: 'custom-modalbox',
        data: row
      });
    }
  }


  verifyNodes(row: any) : boolean
  {
    //return true;
    var completed: boolean = true;
    //console.log(row);
    var listOfBrothers = this.resume.filter(x => x.path.toString().substring(0, x.path.length - 3) == row.path.substring(0, row.path.length - 3) && x.lessonId != row.lessonId && x.level != 0);

    listOfBrothers.forEach(elem => {
      //console.log(elem.isClosed);

      if(!elem.isClosed) 
        completed = false;
    });
       
    return completed;
  }

  openNode(data)
  {
    this.treeControl.toggle(data);

    if(!this.comeFromDashboard)
      this.elearning.toggleNodeLesson(data.userElearningLessonId).subscribe(x => {
      });
  }

  listTests()
  {
    this.testService.getElearningTestsByUserId(this.userELearningId).pipe(first()).subscribe(data => {
      this.listOfAllData = data;
    });
  }

  openDialog(e, i) {
    this.testService.getTypeByTestId(this.listOfAllData[this.currentPage * this.pageSize + i].testId).subscribe(x => {
      this.isCam = x['type'] == 'CAM' || x['type'] == 'ADR';

      //console.log(this.isCam);

      let row: any = {};
      const deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
      const deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
      const deviceMaxHeight  = this.utils.isMobileDevice() ? '90vh' : '90vh';



      row.testId = this.listOfAllData[this.currentPage * this.pageSize + i].testId;
      row.dashboard = false;
      row.origin = 'history';

      if(this.categoryId == 16 || this.categoryId == 17)
      {
        const dialogRef = this.dialog.open(ShowcamTestComponent, {
          id: 'camWizard',
          width: deviceWidth,
          maxWidth: deviceMaxWidth,
          height: 'auto',
          maxHeight: deviceMaxHeight,
          disableClose: true,
          closeOnNavigation: true,
          panelClass: 'custom-modalbox',
          data: row
        });
      } else {

        //console.log(row);
        const dialogRef = this.dialog.open(ShowTestComponent, {
          id: 'wizard',
          width: deviceWidth,
          maxWidth: deviceMaxWidth,
          height: 'auto',
          maxHeight: deviceMaxHeight,
          disableClose: true,
          closeOnNavigation: true,
          panelClass: 'custom-modalbox',
          data: row
        });
      }
    });
  }

  custom(i) {
    this.currentPage = i - 1;
    //console.log(this.currentPage);
  }

  currentPageDataChange($event: any[]): void {
    // console.log($event);
    this.listOfDisplayData = $event;
  }

  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.search();
  }

  reset(): void {
    this.searchValue = '';
    this.search();
  }

  search(): void {
    if (this.sortName && this.sortValue) {
      this.listOfDisplayData = this.listOfDisplayData.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
            ? 1
            : -1
      );
    } else {
      this.listOfDisplayData = this.listOfDisplayData;
    }
  }
}



