var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var DialogBoxZoomComponent = /** @class */ (function () {
    function DialogBoxZoomComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.localData = __assign({}, data);
        this.imagePath = this.localData.src;
    }
    DialogBoxZoomComponent.prototype.ngOnInit = function () {
    };
    DialogBoxZoomComponent.prototype.close = function () {
        this.dialogRef.close();
        this.dialogRef._containerInstance = null;
    };
    return DialogBoxZoomComponent;
}());
export { DialogBoxZoomComponent };
