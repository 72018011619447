<div class="row" style="padding-left: 1%;">
    <tc-card style="width: 99%;">
        <mat-label>Selecione o Intervalo de Tempo</mat-label> <br>
        <div class="row">
            <div class="col-9">
                <mat-form-field>
                    <mat-label>Data Inicial</mat-label>
                    <input matInput [matDatepicker]="dp" [formControl]="startDt">
                    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp></mat-datepicker>
                </mat-form-field>
                <mat-form-field style="padding-left: 25px;padding-right: 25px;">
                    <mat-label>Data Final</mat-label>
                    <input matInput [matDatepicker]="dp1" [formControl]="endDt">
                    <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                    <mat-datepicker #dp1></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-3">
                <button tc-button class="btn-success" (click)="date()" [beforeIcon]="'icofont-search-1'"
                    [view]="'success'">Filtrar Data</button>
            </div>
        </div>


    </tc-card>
</div>
<!--
<div class="row" style="padding-left:2%;padding-bottom:2%">
    <div class="col-5 text-left mt-2">
        <button tc-button class="btn-default" (click)="openFilter()" [beforeIcon]="'icofont-search-document'"
            [view]="'default'">Filtro</button>
    </div>
    <div class="col-3  text-right mt-2">
    </div>
    <div class="col-3  text-right mt-2">
        <button tc-button class="btn-secondary" (click)="export()" [beforeIcon]="'icofont-file-excel'"
            [view]="'secondary'">Exportar para Excel</button>
    </div>
    <div class="col-1"></div>
</div>
<div style="border:1px solid gray; border-radius: 3px; background-color: aliceblue;" id="filtro" class="hide">
    <div class="row" style="padding-left: 2%;padding-bottom: 2%;padding-top: 1%;"><button tc-button class="btn-default"
            [beforeIcon]="'icofont-ui-reply'" (click)="reset()" [view]="'default'">Reset</button></div>
    <div class="row" style="padding-left: 10%;padding-bottom: 2%;">
        <div class="col-1" style="padding-top: 3px;">Escola:</div>
        <div class="col-2"><input type="text" [(ngModel)]="schoolValue" class="form-control"></div>
        <div class="col-1" style="padding-top: 3px;padding-left:1px">Tipo:</div>
        <div class="col-2"><input type="text" [(ngModel)]="typeValue" class="form-control"></div>
        <div class="col-1" style="padding-top: 3px;padding-right:0px;">Plataforma:</div>
        <div class="col-2"><input type="text" [(ngModel)]="plataformValue" class="form-control"></div>
    </div>
    <div class="row" style="padding-bottom: 2%;">
        <div class="col-10"></div>
        <div class="col-2"><button tc-button class="btn-success" [view]="'default'" [beforeIcon]="'icofont-search-1'"
                (click)="filter()">Filtrar</button>
        </div>
    </div>
</div> -->
<tc-card>
    <div class="row" style="padding-left:2%;padding-bottom:2%">
        <div class="col-5 text-left mt-2">

        </div>
        <div class="col-3  text-right mt-2">
        </div>
        <div class="col-3  text-right mt-2">
            <button tc-button class="btn-default" (click)="export()" [beforeIcon]="'icofont-file-excel'"
                [view]="'default'">Exportar para Excel</button>
        </div>
        <div class="col-1"></div>
    </div>
    <nz-table #nestedTable [nzData]="listOfAllData" [nzPaginationPosition]="position" style="overflow: auto;">
        <thead>
            <tr>
                <th nzShowExpand></th>
                <th>Escola</th>
                <th>Tipo</th>
                <th>Plataforma</th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-data [ngForOf]="nestedTable.data">
                <tr>
                    <td nzShowExpand [(nzExpand)]="data.expand"></td>
                    <td>{{ data.companyName }}</td>
                    <td>{{ data.companyType }}</td>
                    <td>{{ data.plataformName }}</td>
                </tr>
                <tr [nzExpand]="data.expand">
                    <td></td>
                    <td colspan="4">
                        <nz-table #innerTable [nzData]="data.children" nzSize="middle" [nzShowPagination]="false">
                            <thead>
                                <tr>
                                    <th>Categoria</th>
                                    <th>Data de Atribuição</th>
                                    <th>Senhas Atribuidas</th>
                                    <th>Senhas Restantes</th>
                                    <th>Duração</th>
                                </tr>
                            </thead>
        <tbody>
            <tr *ngFor="let data of innerTable.data">
                <td>{{ data.categoryName }}</td>
                <td>{{ data.date  | date: 'dd/MM/yyyy H:mm:ss'}}</td>
                <td>{{ data.quantity }}</td>
                <td>{{ data.left }}</td>
                <td>{{ data.durationName }}</td>
            </tr>
        </tbody>
    </nz-table>
    </td>
    </tr>
    </ng-template>
    </tbody>
    </nz-table>
</tc-card>