import { Question } from './../../../../models/question';
import { Component, OnInit, Optional, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-dialog-box-teacher-email",
  templateUrl: "./dialog-box-teacher-email.component.html",
  styleUrls: ["./dialog-box-teacher-email.component.scss"],
})
export class DialogBoxTeacherEmailComponent implements OnInit {
  localData: any;
  emailForm: FormGroup;
  name: string = '';


  constructor(
    public dialogRef: MatDialogRef<DialogBoxTeacherEmailComponent>,
    private formBuilder: FormBuilder,
    private userService: UserService,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.localData = { ...data };

    //console.log(this.localData);
    
    if(this.localData.userId)
    {
      this.userService.getById(this.localData.userId).subscribe( x =>
        {
          this.name = x['name'];
        });
    }
  }

  ngOnInit() {
    this.emailForm = this.formBuilder.group({
      //subject: ['', Validators.required],
      body: ["", Validators.required],
    });
  }

  get f() {
    return this.emailForm.controls;
  }

  onSubmit() {
    this.localData.subject = "Re: Dúvidas";
    this.localData.body = this.f.body.value;
    if(this.localData.body != '')
    this.dialogRef.close({ data: this.localData, event: "Send" });
  }

  closeDialog() {
    console.log("close");
    this.dialogRef.close({ event: "Cancel" });
  }

  isResponded(){
    if(this.localData.responded == true){
      return true;
    } else{
      return false;
    }
  }

   form = this.formBuilder.group({
     myForm: new FormControl({ value: '', disabled: true }),
     myQuestion: new FormControl({ value: '', disabled: true })
  });
}
