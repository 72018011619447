var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { QuestionService } from '../../../../services/question.service';
import { environment } from '../../../../../environments/environment';
import { BasePageComponent } from '../../../base-page';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../../services/http/http.service';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../../../services/auth.service';
import { TestDefinitionService } from '../../../../services/test-definition.service';
import { BlockTemplateComponent } from '../../block-template/block-template.component';
import { BlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs/internal/Subject';
import { filter } from 'rxjs/internal/operators/filter';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Utils } from 'src/utils/utils';
import { ELearningService } from 'src/app/services/elearning.service';
var DialogBoxTestResultComponent = /** @class */ (function (_super) {
    __extends(DialogBoxTestResultComponent, _super);
    function DialogBoxTestResultComponent(questionService, store, router, _location, authenticationService, httpSv, testDefinitionService, dialogRef, data, utils, elearningService) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.questionService = questionService;
        _this.router = router;
        _this._location = _location;
        _this.authenticationService = authenticationService;
        _this.testDefinitionService = testDefinitionService;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.utils = utils;
        _this.elearningService = elearningService;
        _this.tableData = [];
        _this.bordered = true;
        _this.msg = '';
        _this.blockTemplate = BlockTemplateComponent;
        _this.destroyed = new Subject();
        _this.localData = __assign({}, data);
        console.log(_this.localData);
        setTimeout(function () {
            _this.blockUI.stop();
        }, 2000);
        _this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        var va = _this.currentUser.user.name.split(' ');
        if (va.length > 1) {
            _this.currentName = va[0] + ' ' + va[va.length - 1];
        }
        else {
            _this.currentName = _this.currentUser.user.name;
        }
        _this.getData(environment.apiUrl + "/Category/", 'tableData', 'setLoaded');
        _this.testDefinitionService.getTestDefinitionById(_this.localData.testDefinitionId).subscribe(function (a) {
            _this.approvedQuestions = a['questionsWrong'];
            _this.repprovedQuestions = a['questionsWrong'] + 1;
            _this.approved = _this.localData.wrongQuestions > a['questionsWrong'] ? 'Reprovado' : 'Aprovado';
        });
        _this.questionService.arrangeQuestionsByTheme(_this.localData.userId, _this.localData.questions, _this.localData.startDt, _this.localData.endDt, _this.localData.testDefinitionId).subscribe(function (questions) {
            if (_this.localData.count == true) {
                _this.questionService.ratingQuestionsByTheme(_this.localData.userId, _this.localData.questions, _this.localData.categoryId, _this.localData.startDt, _this.localData.endDt, _this.localData.testDefinitionId, questions[questions.length - 1].countRight, questions[questions.length - 1].countWrong, _this.localData.font).subscribe(function (c) {
                    _this.testId = c['testId'];
                    console.log(_this.testId);
                    console.log(_this.localData.userElearningLessonId);
                    console.log(_this.localData.userId);
                    console.log(_this.localData.startDt);
                    console.log(_this.localData.endDt);
                    //this.testId = 24481; //batota
                    _this.elearningService.createTestSession(_this.localData.userElearningLessonId, _this.testId).subscribe(function (x) {
                        console.log(x);
                    });
                });
            }
            else {
                _this.msg = 'Este teste não será contabilizado, uma vez que existem várias questões sem resposta';
            }
            _this.tableData = questions;
        });
        _this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; }), takeUntil(_this.destroyed)).subscribe();
        return _this;
    }
    DialogBoxTestResultComponent.prototype.ngOnInit = function () {
    };
    DialogBoxTestResultComponent.prototype.ngOnDestroy = function () {
        this.destroyed.next();
        this.destroyed.complete();
    };
    DialogBoxTestResultComponent.prototype.rever = function () {
        this.dialogRef.close();
        this.dialogRef._containerInstance = null;
    };
    DialogBoxTestResultComponent.prototype.out = function () {
        this._location.back();
    };
    DialogBoxTestResultComponent.prototype.currentPageDataChange = function ($event) {
        this.tableData = $event;
    };
    DialogBoxTestResultComponent.prototype.Msg = function () {
        return this.msg;
    };
    ;
    __decorate([
        BlockUI(),
        __metadata("design:type", Object)
    ], DialogBoxTestResultComponent.prototype, "blockUI", void 0);
    return DialogBoxTestResultComponent;
}(BasePageComponent));
export { DialogBoxTestResultComponent };
