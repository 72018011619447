import * as AllIcons from '@ant-design/icons-angular/icons';
import { en_US } from 'ng-zorro-antd';
import en from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
var antDesignIcons = AllIcons;
var ɵ0 = function (key) { return antDesignIcons[key]; };
var icons = Object.keys(antDesignIcons).map(ɵ0);
registerLocaleData(en);
var ɵ1 = en_US, ɵ2 = icons;
var PagesModule = /** @class */ (function () {
    function PagesModule() {
    }
    return PagesModule;
}());
export { PagesModule };
export { ɵ0, ɵ1, ɵ2 };
