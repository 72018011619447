<div>
  <div class="row"><label class="currentName" style="float: right; padding-top:1%;">{{name}}</label></div>
  <div class="row"><label class="text profile small" style="float: right; padding-top:3%;">({{currentUser.user.role}})</label>
  </div>
</div>
<tc-dropdown class="item">
  <tc-dropdown-button>
    <tc-icon [iconClass]="'icofont icofont-notification'" [tcFontSize]="24"></tc-icon>
    <tc-badge [tcShape]="500" [view]="'gradient'" *ngIf="notifications && notifications.length" [size]="'sm'">
      {{ notifications.length }}
    </tc-badge>
  </tc-dropdown-button>

  <tc-dropdown-content [align]="'right'" [animation]="'fadeInUp'" [offset]="[10, 0]">
    <div class="menu-header">
      <h4 class="h5 menu-title mt-0 mb-0">Notificações</h4>
    </div>

    <ul class="list">
      <ng-container *ngIf="notifications && notifications.length">
        <li *ngFor="let item of notifications" (click)="goTo($event, 'requests')">
          <span class="item-content">
            <span [tcBgColor]="iconBg" [tcColor]="'#fff'" class="icon {{ icon }}"></span>

            <div class="content">
              <a class="desc">{{ item.content | slice : 0  : 20}} ...</a>

              <span [tcColor]="'#4a505c'" class="date">{{ item.subject }}</span>
            </div>
          </span>
        </li>
      </ng-container>

      <li *ngIf="!notifications || (notifications.length === 0)">
        <span class="empty-item">Sem Notificações</span>
      </li>
    </ul>
  </tc-dropdown-content>
</tc-dropdown>

<tc-dropdown class="item" [close]="closeDropdown">
  <tc-dropdown-button>
    <div class="d-flex align-items-center">
      <tc-avatar [src]="imageUrl" [size]="50" class="mr-1" *ngIf="imageUrl"></tc-avatar>
      <i class="icofont-simple-down"></i>
    </div>
  </tc-dropdown-button>

  <tc-dropdown-content [align]="'right'" [animation]="'fadeInUp'" [offset]="[10, 0]" [width]="180">
    <ul class="list">
      <li class="px-2 item">
        <a href="#" (click)="goTo($event, 'edit-account')" class="item-link align-items-center item-content">
          Informações de Perfil
        </a>
      </li>

      <li class="px-2 item">
        <a href="#" (click)="goTo($event, 'change-password')" class="item-link align-items-center item-content">
          Mudar Senha
        </a>
      </li>

      <li class="divider m-0 p-0"></li>

      <li class="px-2 item">
        <a (click)="logout()" class="item-link align-items-center item-content">
          Sair
          <span class="icon icofont-logout log-out-icon p-1 mr-0"></span>
        </a>
      </li>
    </ul>
  </tc-dropdown-content>
</tc-dropdown>