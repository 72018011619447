<div class="row" style="padding-left:2%;padding-bottom:2%">
  <div class="col-12 text-right">
    <button tc-button class="btn-success" style="margin-right: 2%;" [view]="'success'" [beforeIcon]="'icofont-ui-add'"
      (click)="openDialog('Adicionar', null, 0)">Adicionar</button>
    <button tc-button class="btn-error" [view]="'error'" [beforeIcon]="'icofont-ui-delete'"
      (click)="removeSelectedRows()">Remover</button>
  </div>
</div>

<tc-card [padding]="'20px 0'" [title]="'Categorias'">
  <nz-table #rowSelectionTable [nzData]="listOfAllData" [nzBordered]="bordered" nzShowSizeChanger
    [nzPaginationPosition]="position" [(nzPageSize)]="pageSize"
    (nzCurrentPageDataChange)="currentPageDataChange($event)" (nzPageIndexChange)="custom($event)">
    <thead (nzSortChange)="sort($event)" nzSingleSort>
      <tr>
        <th nzCustomFilter nzShowSort nzSortKey="name">Nome
          <i nz-th-extra class="ant-table-filter-icon" nz-icon nz-dropdown #dropdown="nzDropdown" nzType="search"
            [nzDropdownMenu]="menu" [class.ant-table-filter-open]="dropdown.nzVisible" nzTrigger="click"
            nzPlacement="bottomRight" [nzClickHide]="false" nzTableFilter></i></th>
      </tr>
    </thead>
    <tbody style="cursor: pointer;">
      <tr *ngFor="let data of rowSelectionTable.data; let i = index;" (click)="select($event)"
        (dblclick)="openDialog('Modificar', $event, i)">
        <td>{{ data.name }}</td>
      </tr>
    </tbody>
  </nz-table>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <div class="search-box">
      <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValue" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">
        Search
      </button>
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>
  </nz-dropdown-menu>
</tc-card>