import { Component, OnDestroy, OnInit } from '@angular/core';

import { AuthService } from '../../../../services/auth.service';
import { BasePageComponent } from '../../../../pages/base-page';
import { CategoryService } from 'src/app/services/category.service';
import { HttpService } from '../../../../services/http/http.service';
import { IAppState } from '../../../../interfaces/app-state';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TicketService } from '../../../../services/ticket.service';
import { User } from '../../../../models/user';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-categoriesTest',
    templateUrl: './categoriesTest.component.html',
    styleUrls: ['./categoriesTest.component.css']
})
export class categoriesTestComponent extends BasePageComponent implements OnInit, OnDestroy {
    activeElement: number;
    currentUser: User;
    categoryId: number;
    categories: any[] = [];
    navigationSubscription;
    constructor(private router: Router, private authenticationService: AuthService, private ticket: TicketService, private categoryService: CategoryService,
        store: Store<IAppState>,
        httpSv: HttpService) {
        super(store, httpSv);

        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        this.getData(`${environment.apiUrl}/Product/`, 'tableData', 'setLoaded');
        if (this.currentUser.user.profileId == 3 || this.currentUser.user.profileId == 7) {
            this.ticket.ticketsByUserId(this.currentUser.user.id).subscribe(ticket => {

                ticket['hasTickets'].forEach(element => {
                    console.log(element);

                    if (element['active'] == true && element['activateDate'] != null && element['daysLeft'] > 0 && (element['type'] =='Code' || element['type'] =='CAM' || element['type'] =='ADR' || element['type'] =='Taxis')) {
                        if(!this.categories.find(x => x.categoryId == element.categoryId))
                            this.categories.push(element);
                    }
                });
                this.categories.sort((a, b) => (a.categoryName > b.categoryName) ? 1 : -1)
            });
        }
        else {
            this.ticket.ticketsByCompanyId(this.currentUser.user.companyId).subscribe(ticket => {
                console.log(ticket);
                ticket.forEach(element => {
                    if (element.tickets.length != 0) {
                        element.tickets.forEach(elem => {
                            if (elem['active'] == true) {
                                if(!this.categories.find(x => x.categoryId == elem.categoryId))
                                    this.categories.push(elem);
                            }
                        });
                    }

                });
                //console.log(this.categories)
                this.categories.sort((a, b) => (a.categoryName > b.categoryName) ? 1 : -1)
            });
        }
        this.pageData = {
            title: 'Realizar teste',
            loaded: true
        };
    }
    ngOnInit() {
        super.ngOnInit();
    }

    select(e, i) {
        this.activeElement = i;
        this.categoryService.isCam(this.categories[i].categoryId).subscribe(isCam =>{
            if (isCam == true && this.categories[i].categoryId != 55) {
                this.router.navigate(['app/TestType/AlvesCosta/' + this.categories[i].categoryId]);
            } else if(this.categories[i].categoryId == 54) {
                this.router.navigate(['app/TestType/IMT/' + this.categories[i].categoryId]);
            } else if(this.categories[i].categoryId == 55) {
                this.router.navigate(['app/TestType/IMT/' + this.categories[i].categoryId]);
            } else {
                this.router.navigate(['app/Taking/' + this.categories[i].categoryId]);
            }
        });
    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
