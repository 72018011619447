var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DidaticMaterialComponent } from "../../pages/custom/DialogBoxes/dialog-box-material/dialog-box-material.component";
import { AuthService } from '../../services/auth.service';
import { BaseLayoutComponent } from '../base-layout/base-layout.component';
import { CompanyService } from '../../services/company.service';
import { FormBuilder } from '@angular/forms';
import { GlobalConstants } from 'src/environments/settings';
import { HttpService } from '../../services/http/http.service';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material';
var AppLayoutComponent = /** @class */ (function (_super) {
    __extends(AppLayoutComponent, _super);
    function AppLayoutComponent(store, fb, httpSv, router, elRef, companyService, authenticationService, dialog) {
        var _this = _super.call(this, store, fb, httpSv, router, elRef) || this;
        _this.companyService = companyService;
        _this.dialog = dialog;
        authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        // console.log(this.currentUser)
        _this.companyService.getById(_this.currentUser.user.companyId).subscribe(function (company) {
            if (company.image != null && company.image != '') {
                _this.imageUrl = 'data:image/jpg;base64,' + company.image;
                _this.width = '100%';
                _this.height = '33px';
            }
        });
        return _this;
    }
    AppLayoutComponent.prototype.actualize = function () {
        if (GlobalConstants)
            return GlobalConstants.actualize;
        return false;
    };
    AppLayoutComponent.prototype.removeParamFromUrl = function (param, key) {
        var queryParams = {};
    };
    AppLayoutComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    AppLayoutComponent.prototype.getNavbarStyles = function () {
        return {
            'background-image': "linear-gradient(188deg, " + this.appSettings.sidebarBg + ", " + this.appSettings.sidebarBg2 + " 65%)",
            'color': this.appSettings.sidebarColor,
            'z-index': '1000'
        };
    };
    AppLayoutComponent.prototype.getRoute = function () {
        return this.currentUser.user.profileId == 3 ? '/app/dashboard' : '/app/homePage';
    };
    AppLayoutComponent.prototype.btnLogoClick = function () {
        if (this.currentUser.user.profileId == 3 || this.currentUser.user.profileId == 7) {
            this.router.navigateByUrl('/app/dashboard');
        }
    };
    AppLayoutComponent.prototype.openHelp = function () {
        var row = {};
        row.mimeType = 'video/mp4';
        row.src = "./assets/movie/sample.mp4";
        var dialogRef = this.dialog.open(DidaticMaterialComponent, {
            width: "auto",
            height: "auto",
            maxWidth: "95vw",
            maxHeight: "90vh",
            data: row,
        });
    };
    return AppLayoutComponent;
}(BaseLayoutComponent));
export { AppLayoutComponent };
