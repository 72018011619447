import { Component, OnInit, Optional, Inject } from '@angular/core';
import { Category } from '../../../../models/Category';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CategoryService } from '../../../../services/category.service';
import { ProductService } from '../../../../services/product.service';
import { Product } from '../../../../models/product';

@Component({
  selector: 'app-dialog-box-categories',
  templateUrl: './dialog-box-categories.component.html',
  styleUrls: ['./dialog-box-categories.component.css']
})
export class DialogBoxCategoriesComponent implements OnInit {

  categoriesForm: FormGroup;
  loading = false;
  submitted = false;
  categories: Category;
  products: Product[] = [];
  action: string;
  localData: any;
  productsF: FormGroup;
  selected;
  toSelect;
  constructor(
    public dialogRef: MatDialogRef<DialogBoxCategoriesComponent>,
    private categoryService: CategoryService, private formBuilder: FormBuilder,
    private productService: ProductService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Category) {
    this.localData = { ...data };
    this.action = this.localData.action;
    if (this.action != 'Adicionar') {
      this.productService.getProductByCategoryId(this.localData.id).subscribe(product => {
        this.f.productsF.setValue(product.name);
      });
    }
    this.productService.getAll().subscribe(products => {
      this.products = products;
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.categoriesForm.controls; }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
  ngOnInit() {
    this.categoriesForm = this.formBuilder.group({
      name: ['', Validators.required],
      productsF: ['', Validators.required]
    });
  }

  onSubmit() {
    const product = this.products.filter(item => {
      return item.name === this.f.productsF.value;
    })[0];
    this.localData.productId = product['productId'];
    this.localData.name = this.f.name.value;
    this.dialogRef.close({ event: this.action, data: this.localData });
  }
}
