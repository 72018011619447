import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { MenuItem } from '../models/menuItem';
import { IMenuItem } from '../interfaces/main-menu';

@Injectable({ providedIn: 'root' })
export class MenuService {

  constructor(private http: HttpClient) { }

  getAll(profileId: number) {
   // console.log(profileId);
      return this.http.get<MenuItem[]>(`${environment.apiUrl}/menu/byRole/${profileId}`);
  }
  
}
