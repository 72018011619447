import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CompanyTypeService = /** @class */ (function () {
    function CompanyTypeService(http) {
        this.http = http;
    }
    CompanyTypeService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl + "/companyType/");
    };
    CompanyTypeService.prototype.getById = function (id) {
        return this.http.get(environment.apiUrl + "/companyType/" + id);
    };
    CompanyTypeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyTypeService_Factory() { return new CompanyTypeService(i0.ɵɵinject(i1.HttpClient)); }, token: CompanyTypeService, providedIn: "root" });
    return CompanyTypeService;
}());
export { CompanyTypeService };
