import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { first } from 'rxjs/internal/operators/first';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../../../services/product.service';
import { Product } from '../../../../models/product';
import { BasePageComponent } from '../../../base-page';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { environment } from '../../../../../environments/environment';
import { DialogBoxProductsComponent } from '../../DialogBoxes/dialog-box-products/dialog-box-products.component';
import { ConfirmComponent } from '../../DialogBoxes/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent extends BasePageComponent implements OnInit, OnDestroy {
  bordered = true;
  position = 'bottom';
  searchValue = '';
  listOfDisplayData: Product[] = [];
  listOfAllData: Product[] = [];
  list: Product[] = [];
  sortName: string | null = null;
  sortValue: string | null = null;
  constructor(private productService: ProductService,
    public dialog: MatDialog, store: Store<IAppState>,
    httpSv: HttpService, private toastr: ToastrService) {
    super(store, httpSv);
    this.getData(`${environment.apiUrl}/Product/`, 'tableData', 'setLoaded');
    this.productService.getAll().pipe(first()).subscribe(product => {
      this.listOfAllData = product;
      this.list = product;
    });

    /*
        this.pageData = {
          title: 'Produtos',
          loaded: true
        };*/
  }

  currentPageDataChange($event: Product[]): void {
    this.listOfDisplayData = $event;
  }
  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.listOfAllData = this.list;
    this.listOfDisplayData = this.list;
    this.search();
  }
  reset(): void {
    this.searchValue = '';
    this.listOfAllData = this.list;
    this.search();
  }

  select(event) {

    var path = event.path || (event.composedPath && event.composedPath());

    if (path[1].className.match('active')) {
      path[1].classList.remove('active');
    }
    else {
      path[1].classList.add('active');
    }
  }

  search(): void {
    const filterFunc = (item: Product) => {
      return (
        item['name'].toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
      );
    };
    const data = this.list.filter((item: Product) => filterFunc(item));
    this.listOfAllData = data.sort((a, b) =>
      this.sortValue === 'ascend'
        ? a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );
  }
  removeSelectedRows() {
    let row: any = {};
    row.text = 'Confirma a remoção do(s) produto(s) selecionado(s)?'
    let dialogRef = this.dialog.open(ConfirmComponent, {
      id: 'confirm',
      // width: '30vw',
      // height: '23vh',
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) { }
      else if (result.event == 'sim') {
        const e = document.querySelectorAll('tr');
        e.forEach(el => {
          if (el.className.match('active')) {
            const index: number = this.listOfAllData.findIndex(d => d['name'] === el.children[0].lastChild.nodeValue.trim());
            this.productService.deleteProduct(this.listOfAllData[index]['productId']).subscribe((data) => {
              this.productService.getAll().pipe(first()).subscribe(product => {
                this.listOfAllData = product;
              });
              this.toastr.success('Produto(s) removido(s) com sucesso!');
            },
              err => {
                this.toastr.error('Algo ocorreu mal!');
              });
          }
        });
      }
    });
  }
  openDialog(action, e) {
    if (e == null || !e.target.className.match('ant-table-column-sorters') && !e.target.className.match('ant-spin-container')) {

      var path = e == null ? '' : e.path || (e.composedPath && e.composedPath());

      let row: any = {};
      row.action = action;
      row.tag = e == null ? '' : this.listOfAllData[this.listOfAllData.findIndex(d => d['name'] === path[1].children[0].innerText)].tag;
      row.productId = e == null ? -1 : this.listOfAllData[this.listOfAllData.findIndex(d => d['name'] === path[1].children[0].innerText)]['productId'];
      row.name = e == null ? '' : path[1].children[0].innerText;


      const dialogRef = this.dialog.open(DialogBoxProductsComponent, {
        width: '99%',
        maxWidth: '850px',
        height: 'auto',
        data: row,
        disableClose: true,
        closeOnNavigation: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === undefined) { }
        else if (result.event == 'Adicionar') {
          this.addRowData(result.data);
        } else if (result.event == 'Modificar') {
          this.updateRowData(result.data);
        }
      });
    }
  }

  addRowData(rowObj) {
    this.productService.createProduct(rowObj.name, rowObj.tag).subscribe((data) => {
      this.productService.getAll().pipe(first()).subscribe(product => {
        this.listOfAllData = product;
      });
      this.toastr.success('Produto Adicionado com sucesso!');
    },
      err => {
        this.toastr.error('Algo ocorreu mal!');
      });
  }
  updateRowData(rowObj) {
    this.productService.updateProduct(rowObj.productId, rowObj.name, rowObj.tag).subscribe((data) => {
      this.productService.getAll().pipe(first()).subscribe(product => {
        this.listOfAllData = product;
      });
      this.toastr.success('Produto Modificado com sucesso!');
    },
      err => {
        this.toastr.error('Algo ocorreu mal!');
      });
  }
  ngOnInit() {
    super.ngOnInit();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
