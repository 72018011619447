var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { BasePageComponent } from '../../../../pages/base-page';
import { CategoryService } from 'src/app/services/category.service';
import { HttpService } from '../../../../services/http/http.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TicketService } from '../../../../services/ticket.service';
import { environment } from '../../../../../environments/environment';
var categoriesTestComponent = /** @class */ (function (_super) {
    __extends(categoriesTestComponent, _super);
    function categoriesTestComponent(router, authenticationService, ticket, categoryService, store, httpSv) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.router = router;
        _this.authenticationService = authenticationService;
        _this.ticket = ticket;
        _this.categoryService = categoryService;
        _this.categories = [];
        _this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        _this.getData(environment.apiUrl + "/Product/", 'tableData', 'setLoaded');
        if (_this.currentUser.user.profileId == 3 || _this.currentUser.user.profileId == 7) {
            _this.ticket.ticketsByUserId(_this.currentUser.user.id).subscribe(function (ticket) {
                ticket['hasTickets'].forEach(function (element) {
                    console.log(element);
                    if (element['active'] == true && element['activateDate'] != null && element['daysLeft'] > 0 && (element['type'] == 'Code' || element['type'] == 'CAM' || element['type'] == 'ADR' || element['type'] == 'Taxis')) {
                        if (!_this.categories.find(function (x) { return x.categoryId == element.categoryId; }))
                            _this.categories.push(element);
                    }
                });
                _this.categories.sort(function (a, b) { return (a.categoryName > b.categoryName) ? 1 : -1; });
            });
        }
        else {
            _this.ticket.ticketsByCompanyId(_this.currentUser.user.companyId).subscribe(function (ticket) {
                console.log(ticket);
                ticket.forEach(function (element) {
                    if (element.tickets.length != 0) {
                        element.tickets.forEach(function (elem) {
                            if (elem['active'] == true) {
                                if (!_this.categories.find(function (x) { return x.categoryId == elem.categoryId; }))
                                    _this.categories.push(elem);
                            }
                        });
                    }
                });
                //console.log(this.categories)
                _this.categories.sort(function (a, b) { return (a.categoryName > b.categoryName) ? 1 : -1; });
            });
        }
        _this.pageData = {
            title: 'Realizar teste',
            loaded: true
        };
        return _this;
    }
    categoriesTestComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    categoriesTestComponent.prototype.select = function (e, i) {
        var _this = this;
        this.activeElement = i;
        this.categoryService.isCam(this.categories[i].categoryId).subscribe(function (isCam) {
            if (isCam == true && _this.categories[i].categoryId != 55) {
                _this.router.navigate(['app/TestType/AlvesCosta/' + _this.categories[i].categoryId]);
            }
            else if (_this.categories[i].categoryId == 54) {
                _this.router.navigate(['app/TestType/IMT/' + _this.categories[i].categoryId]);
            }
            else if (_this.categories[i].categoryId == 55) {
                _this.router.navigate(['app/TestType/IMT/' + _this.categories[i].categoryId]);
            }
            else {
                _this.router.navigate(['app/Taking/' + _this.categories[i].categoryId]);
            }
        });
    };
    categoriesTestComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return categoriesTestComponent;
}(BasePageComponent));
export { categoriesTestComponent };
