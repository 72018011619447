<div style="display: inline-table;">
  <div style="display: table-cell; width: 99%;text-align: left;">
    <h4 small>{{action}} {{localData.name}}</h4>
  </div>
  <div style="display: table-cell; width: 1%; text-align: right; vertical-align: top;">
    <!-- <a mat-dialog-close style="float: right; cursor:pointer" class="tc-icon-wrap icofont-close-circled"></a> -->
  </div>
</div>
<div style="clear: both; width: 100%;">
  <mat-dialog-content [formGroup]="categoriesForm">
    <tc-form-group>
      <tc-form-label>Nome</tc-form-label>
      <tc-input [placeholder]="'Nome'" [type]="'text'" formControlName="name" [(ngModel)]="localData.name"></tc-input>
    </tc-form-group>
    <tc-form-group>
      <tc-form-label>Pertence ao Produto</tc-form-label>
      <mat-select formControlName="productsF" [value]="selected" placeholder="Pertence ao Produto" class="select-box">
        <mat-option *ngFor="let state of products" [value]="state.name">
          {{ state.name }}
        </mat-option>
      </mat-select>
    </tc-form-group>
  </mat-dialog-content>
</div>
<mat-dialog-actions layout="row">
  <button tc-button [view]="'success'" (click)="onSubmit()" [beforeIcon]="'icofont-diskette'"
    style="margin: 2px;">Submeter</button>
  <button tc-button [view]="'warning'" (click)="closeDialog()" style="margin: 2px;"
    [beforeIcon]="'icofont-close-circled'"></button>
</mat-dialog-actions>