/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./navbar.component";
var styles_NavbarComponent = [i0.styles];
var RenderType_NavbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
export function View_NavbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "navbar-wrap"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_NavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "navbar", [], [[2, "navbar", null], [2, "boxed", null], [2, "opened", null], [2, "app", null], [2, "vertical", null], [2, "app-vertical", null], [4, "minHeight", null]], null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i1.ɵdid(1, 114688, null, 0, i2.NavbarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).boxed; var currVal_2 = i1.ɵnov(_v, 1).opened; var currVal_3 = i1.ɵnov(_v, 1).app; var currVal_4 = i1.ɵnov(_v, 1).vertical; var currVal_5 = i1.ɵnov(_v, 1).appVertical; var currVal_6 = i1.ɵnov(_v, 1).height; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
var NavbarComponentNgFactory = i1.ɵccf("navbar", i2.NavbarComponent, View_NavbarComponent_Host_0, { boxed: "boxed", opened: "opened", orientation: "orientation", minHeight: "minHeight" }, {}, ["*"]);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };
