var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { AlertMailService } from '../../../services/alert-mail.service';
import { AuthService } from '../../../services/auth.service';
import { BasePageComponent } from '../../base-page';
import { CompanyService } from '../../../services/company.service';
import { DialogBoxEmailComponent } from '../DialogBoxes/dialog-box-email/dialog-box-email.component';
import { HttpService } from '../../../services/http/http.service';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Utils } from 'src/utils/utils';
import { environment } from '../../../../environments/environment';
var RequestsComponent = /** @class */ (function (_super) {
    __extends(RequestsComponent, _super);
    function RequestsComponent(store, dialog, authenticationService, companyService, httpSv, toastr, alertMailService, utils) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.dialog = dialog;
        _this.authenticationService = authenticationService;
        _this.companyService = companyService;
        _this.toastr = toastr;
        _this.alertMailService = alertMailService;
        _this.utils = utils;
        _this.mails = [];
        _this.responded = [];
        _this.inputValue = '';
        _this.contacts = '';
        _this.selectedTab = 'Enviadas';
        _this.opened = false;
        _this.closeDropdown = new EventEmitter();
        _this.getData(environment.apiUrl + "/Category/", 'tabledata', 'setLoaded');
        _this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        _this.alertMailService.getRequestsByUser(_this.currentUser.user.id).subscribe(function (c) {
            _this.mails = c;
        });
        _this.alertMailService.getRespondedRequestsByUser(_this.currentUser.user.id).subscribe(function (c) {
            _this.responded = c;
        });
        _this.pageData = {
            title: 'Mensagens',
            loaded: true
        };
        _this.companyService.getById(_this.currentUser.user.companyId).subscribe(function (c) {
            _this.contacts = c.contacts;
        });
        return _this;
    }
    RequestsComponent.prototype.openDialog = function (subject, responded, response, question) {
        var _this = this;
        var row = {};
        row.subject = subject;
        row.responded = responded;
        row.response = response;
        row.question = question;
        var width = this.utils.isMobileDevice() == true ? '95vw' : '60vw';
        var height = this.utils.isMobileDevice() == true ? 'auto' : 'auto';
        var dialogRef = this.dialog.open(DialogBoxEmailComponent, {
            disableClose: true,
            height: height,
            maxWidth: width,
            width: width,
            data: row
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result.data) {
                var _data = result.data;
                var _body = result.data.body;
                var _reqType = result.data.requestType;
                var _subject = result.data.subject;
                var _is_valid = result && _data && _reqType && _body && _subject;
                if (_is_valid) {
                    _this.sendEmail(result.data);
                }
            }
        });
    };
    RequestsComponent.prototype.inputSubmit = function () {
        var _this = this;
        if (this.inputValue != "") {
            var cards = document.querySelectorAll('tc-card');
            cards.forEach(function (elem) {
                elem.classList.remove('hide');
            });
            cards.forEach(function (elem) {
                if (elem.className.match(_this.inputValue)) {
                    elem.classList.remove('hide');
                }
                else {
                    elem.classList.add('hide');
                }
            });
        }
        this.inputValue = "";
    };
    RequestsComponent.prototype.show = function (type, param) {
        // console.log(type);
        // console.log(param);
        var cards = document.querySelectorAll('tc-card');
        cards.forEach(function (elem) {
            elem.classList.remove('hide');
        });
        if (type == 'show') {
            if (param == 'all') {
                cards.forEach(function (elem) {
                    elem.classList.remove('hide');
                });
            }
            else {
                cards.forEach(function (elem) {
                    if (elem.className.match(param)) {
                        elem.classList.remove('hide');
                    }
                    else {
                        elem.classList.add('hide');
                    }
                });
            }
        }
        else if (type == 'bySchool') {
            cards.forEach(function (elem) {
                if (elem.className.match(param.replace(' ', ''))) {
                    elem.classList.remove('hide');
                }
                else {
                    elem.classList.add('hide');
                }
            });
        }
        this.onCloseDropdown();
    };
    RequestsComponent.prototype.onCloseDropdown = function () {
        this.closeDropdown.emit(true);
    };
    RequestsComponent.prototype.isAdmin = function () {
        // console.log(this.currentUser.user.role);
        return (this.currentUser.user.role == 'Administrador' ? true : false);
    };
    RequestsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    RequestsComponent.prototype.ngOnDestroy = function () {
        ~_super.prototype.ngOnDestroy.call(this);
    };
    RequestsComponent.prototype.sendEmail = function (rowObj) {
        var _this = this;
        this.alertMailService.sendRequest(this.currentUser.user.id, rowObj.subject, rowObj.requestType, rowObj.body).subscribe(function (c) {
            _this.alertMailService.getRequestsByUser(_this.currentUser.user.id).subscribe(function (c) {
                _this.mails = c;
            });
            _this.toastr.success('Pedido enviado com sucesso! Tentaremos responder o mais breve possivel, Obrigado!');
        });
    };
    RequestsComponent.prototype.openFilter = function () {
        var el = document.getElementById('filtro');
        if (this.opened == false) {
            el.classList.add('show');
            el.classList.remove('hide');
            this.opened = true;
        }
        else {
            el.classList.add('hide');
            el.classList.remove('show');
            this.opened = false;
        }
    };
    RequestsComponent.prototype.openCity = function (evt, cityName) {
        this.selectedTab = cityName;
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("active", "");
        }
        document.getElementById(cityName).style.display = "block";
        document.getElementById(cityName).classList.add("active");
    };
    return RequestsComponent;
}(BasePageComponent));
export { RequestsComponent };
