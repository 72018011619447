<div *ngIf="isStudentDashboard() && !isTeacher() && showStudentDashboardTitle() ">
  <h4 mat-dialog-title small>
    <strong class="roboto250" style="color:#022E53">DASHBOARD DO ALUNO {{localData.name}}</strong>

    <a style="float: right; cursor:pointer" (click)="closeUserDashboard()"
      class="tc-icon-wrap icofont-close-circled"></a>
    <hr>
  </h4>
</div>

<div class="row special-row" *ngIf="categories.length > 0 && categories">
  <tc-card class="mb-4 mt-1 our-border">
    <div class="row">
      <div class="container text-left">
        <tc-form-group [formGroup]="categoriesForm">
          <tc-form-label>Categoria</tc-form-label>

          <mat-select formControlName="categories" class="select-box" placeholder="Selecionar Categoria"
            [(ngModel)]="selectedCategoryName" name="state">
            <mat-option *ngFor="let state of categories; let i=index" [value]="state.name" (click)="selectCategory(i)">
              {{ state.name }}
            </mat-option>
          </mat-select>
        </tc-form-group>
      </div>
    </div>
  </tc-card>
</div>


<div *ngIf="showEmptyDashboard()">
  <tc-card style="align-content: center;">
    <b>
      <h5 class="roboto250">Sem estatísticas disponíveis</h5>
    </b>
  </tc-card>
</div>

<div class="row" *ngIf="isTeacher()">
  <div class="col-md-12 col-sm-12">

    <tc-card>
      <div class="row searchButton mb-2">
        <button tc-button class="btn-default" (click)="openFilterUsers()" [beforeIcon]="'icofont-search-document'"
          [view]="'default'">Filtro</button>
      </div>

      <tc-card style="background-color: #F1F1F2;" id="filterUsers" class="our-border hide">
        <tc-form-group>
          <tc-form-label>Nome</tc-form-label>
          <tc-input type="text" [(ngModel)]="nameValue"></tc-input>
        </tc-form-group>

        <tc-form-group>
          <tc-form-label>Utilizador</tc-form-label>
          <tc-input type="text" [(ngModel)]="userNameValue"></tc-input>
        </tc-form-group>

        <tc-form-group>
          <tc-form-label>Nº Estudante</tc-form-label>
          <tc-input type="number" [(ngModel)]="studentNumberValue"></tc-input>
        </tc-form-group>

        <div class="d-flex justify-content-end">
          <button tc-button class="btn-default mr-1" (click)="reset()" [view]="'default'">Reset</button>
          <button tc-button class="btn-success" [view]="'default'" [beforeIcon]="'icofont-search-1'"
            (click)="filterUserTable()">Filtrar</button>
        </div>
      </tc-card>

      <nz-table #rowSelectionTableUser [nzData]="tableUserData" [nzBordered]="bordered" nzShowSizeChange
        [(nzPageSize)]="pageSizeTableUserData" (nzPageIndexChange)="custom($event)" class="text-center"
        [nzPaginationPosition]="position" (nzCurrentPageDataChange)="currentPageDataChange($event)"
        style="overflow: auto;">
        <thead (nzSortChange)="sortTableUsers($event)" nzSingleSort class="noClick">
          <tr>
            <th nzShowSort [(nzSort)]="mapOfSort.name" nzSortKey="name">Nome </th>
            <th nzShowSort [(nzSort)]="mapOfSort.username" nzSortKey="username">Utilizador</th>
            <th nzShowSort [(nzSort)]="mapOfSort.studentNumber" nzSortKey="studentNumber">Nº Estudante</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of rowSelectionTableUser.data;let i = index;"
            (dblclick)="openDialogUserDashboard($event, i)">
            <td>{{ data.name }}</td>
            <td>{{ data.userName }}</td>
            <td>{{ data.studentNumber }}</td>
          </tr>
        </tbody>
      </nz-table>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValue" />
          <button tc-button class="btn-default" (click)="openFilterQuestionsAlvesCosta()"
            [beforeIcon]="'icofont-search-document'" [view]="'default'">Filtro</button>
          <button nz-button nzSize="small" (click)="reset()">Reset</button>
        </div>
      </nz-dropdown-menu>
    </tc-card>
  </div>
</div>


<mat-accordion *ngIf="!showEmptyDashboard() && !isTeacher()">
  <mat-expansion-panel class="mat-expansion-panel-first" *ngIf="this.haveElearning" [expanded]="this.haveElearning">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h5 class="hardTitle">Ensino à Distância</h5>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>


    <div class="col-12 col-xl-12 our-mat-expansion-content-body">
      <app-elearning-student *ngIf="userId && category" [comeFromDashboard]="true" [userId]="userId"
        [categoryId]="category.categoryId" [categoryName]="category.name"></app-elearning-student>
    </div>

    <mat-action-row *ngIf="false">
      <button mat-button>Click me</button>
    </mat-action-row>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-expansion-panel-last" [expanded]="!this.haveElearning"
    *ngIf="!this.isIMT() && this.haveTests">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h5 class="hardTitle">Testes</h5>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="col-12 col-xl-12 our-mat-expansion-content-body">

      <div *ngIf="!showEmptyDashboard() && !isTeacher()" class="tab">
        <button *ngIf="!isMobile()" class="tablinks active" id="RatingGlobal_"
          (click)="openCity(event, 'RatingGlobal','RatingGlobal_')">Rating
          Global</button>
        <button *ngIf="!isMobile()" class="tablinks" id="RatingAlvesCosta_"
          (click)="openCity(event, 'RatingAlvesCosta','RatingAlvesCosta_')">Rating Alves Costa</button>
        <button *ngIf="!isMobile()" class="tablinks" id="RatingIMT_"
          (click)="openCity(event, 'RatingIMT','RatingIMT_')">Rating IMT</button>

        <button *ngIf="isMobile()" class="tablinks active" id="RatingGlobal_"
          (click)="openCity(event, 'RatingGlobal','RatingGlobal_')">Global</button>
        <button *ngIf="isMobile()" class="tablinks" id="RatingAlvesCosta_"
          (click)="openCity(event, 'RatingAlvesCosta','RatingAlvesCosta_')"> Alves
          Costa</button>
        <button *ngIf="isMobile()" class="tablinks" id="RatingIMT_"
          (click)="openCity(event, 'RatingIMT', 'RatingIMT_')">
          IMT</button>
      </div>

      <div *ngIf="!showEmptyDashboard() && !isTeacher()" id="RatingGlobal" class="tabcontent dashTab"
        style="display: block;">
        <br>
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="chartTitle chartTitleFont">
              <b>TAXA DE APROVAÇÃO PARA EXAME</b>
            </div>
            <tc-card>
              <div class="row" style="overflow: auto;">
                <div class="col-md-12 col-sm-12">
                  <div style="display: block"
                    *ngIf="chartData && ColorsHorizontalBars && globalTaxThemeLabels && globalTaxThemeType && chartOptions">
                    <canvas baseChart id="linear" class="bar-chart-horizontal" [datasets]="chartData" [legend]="true"
                      [colors]="ColorsHorizontalBars" [labels]="globalTaxThemeLabels" [chartType]="globalTaxThemeType"
                      [options]="chartOptions">
                    </canvas>
                  </div>
                </div>
              </div>
            </tc-card>
          </div>
        </div>
        <!--Doughnut chart - Taxa de aprovação global-->
        <!-- <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="chartTitle chartTitleFont">
              <b>TAXA DE APROVAÇÃO PARA EXAME</b>
            </div>
            <tc-card>
              <div style="display: block">
                <canvas baseChart [data]="globalTestsTaxData" [labels]="totalTestsTaxLabels"
                  [chartType]="totalTestsTaxType" [colors]="Colors" [options]="this.getChartOptions()"></canvas>
              </div>
              <div class="row align-charts">
                <div class="notApproved">
                  <b class="notApproved"> TESTES <br /> REPROVADOS </b><br />
                  <b><span class="value">
                      {{notApprovedGlobal}}%
                    </span>
                  </b>
                </div>
                <div class="approved">
                  <b class="approved"> TESTES <br /> APROVADOS </b><br />
                  <b><span class="value">
                      {{approvedGlobal}}%
                    </span>
                  </b>
                </div>
              </div>
            </tc-card>
          </div>
        </div> -->
        <!--Doughnut chart - Rating global-->
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div>
              <div class="chartTitle chartTitleFont">
                <b>RATING GLOBAL</b>
              </div>
              <tc-card>
                <div class="row align-charts">
                  <div class="seen">
                    <b class="seen"> PERGUNTAS <br /> RESPONDIDAS </b><br />
                    <b><span class="value">
                        {{seen}}%
                      </span></b>
                  </div>
                  <div class="questions">
                    <b class="questions"> RESPOSTAS <br /> CORRETAS </b><br />
                    <b><span class="value">
                        {{questions}}%
                      </span></b>
                  </div>
                </div>
                <div class="col-md-12 col-sm-12">
                  <div style="display: block">
                    <canvas baseChart width="100%" [data]="globalRatingData" [labels]="globalRatingLabels"
                      [chartType]="globalRatingType" [colors]="ColorsGlobalRatingChart"
                      [options]="this.getChartOptions()">
                    </canvas>
                  </div>
                </div>
                <div class="row align-charts">
                  <div class="tests">
                    <div>
                      <b class="tests"> TESTES <br /> APROVADOS </b><br />
                      <b><span class="value">
                          {{tests}}%
                        </span></b>
                    </div>
                  </div>
                  <div class="themes">
                    <b class="themes"> TEMAS <br /> CORRETOS </b><br />
                    <b><span class="value">
                        {{themes}}%
                      </span>
                    </b>
                  </div>
                </div>
              </tc-card>
            </div>
          </div>
        </div>
        <!--Tabela - Testes Realizados-->
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div>
              <div class="chartTitle chartTitleFont">
                <b>TESTES REALIZADOS</b>
              </div>
              <tc-card>
                <div class="row searchButton mb-2">
                  <button tc-button class="btn-default" (click)="openFilterTests()"
                    [beforeIcon]="'icofont-search-document'" [view]="'default'">Filtro</button>
                </div>
                <tc-card style="background-color: #FAFAFA;" id="filtroTests" class="our-border hide">
                  <tc-form-group>
                    <tc-form-label>Origem</tc-form-label>
                    <tc-input type="text" [(ngModel)]="fontValueTest"></tc-input>
                  </tc-form-group>

                  <tc-form-group>
                    <tc-form-label>Tipo</tc-form-label>
                    <mat-select [(ngModel)]="typeValue" class="select-box">
                      <mat-option *ngIf="typeValue != '' " value="  "></mat-option>
                      <mat-option value="Exame">Exame</mat-option>
                      <mat-option value="Tematico">Temático</mat-option>
                    </mat-select>
                    <!-- <tc-input type="text" [(ngModel)]="typeValue"></tc-input> -->
                  </tc-form-group>

                  <div class="d-flex justify-content-end">
                    <button tc-button class="btn-default mr-1" [beforeIcon]="'icofont-ui-reply'" (click)="reset()"
                      [view]="'default'">Reset</button>
                    <button tc-button class="btn-success" [view]="'default'" [beforeIcon]="'icofont-search-1'"
                      (click)="filterTestTable()">Filtrar</button>
                  </div>

                </tc-card>
                <h6 style="text-align: left;">Clique no Teste para obter mais informação</h6>
                <nz-table #rowSelectionTableTest [nzData]="tableTestListData" [nzBordered]="bordered" nzShowSizeChanger
                  [(nzPageSize)]="pageSizeTableTestListData" [nzPaginationPosition]="position"
                  (nzPageIndexChange)="custom($event)" (nzCurrentPageDataChange)="currentPageDataChange($event)"
                  style="overflow: auto;" class="text-center">
                  <thead (nzSortChange)="sortTestTable($event)" nzSingleSort class="noClick">
                    <tr>
                      <th nzShowSort [(nzSort)]="mapOfSort.endDt" nzSortKey="endDt">Data</th>
                      <th nzShowSort [(nzSort)]="mapOfSort.font" nzSortKey="font">Origem</th>
                      <th nzShowSort [(nzSort)]="mapOfSort.tag" nzSortKey="tag">Tipo </th>
                      <th nzShowSort [(nzSort)]="mapOfSort.wrongQuestions" nzSortKey="wrongQuestions">Questões Erradas
                      </th>
                      <th [(nzSort)]="mapOfSort.approved" nzSortKey="approved">Resultado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of rowSelectionTableTest.data; let i = index;"
                      (dblclick)="openDialogTest($event, i)">
                      <td class="text-center">{{data.endDt | date: 'dd/MM/yyyy H:mm:ss'}}</td>
                      <td class="text-center">{{ data.font }}</td>
                      <td class="text-center">{{ data.tag }}</td>
                      <td class="text-center">{{ data.wrongQuestions }}</td>
                      <td class="text-center">
                        <tc-badge *ngIf="data.approved == true" style="width: 125px; padding-left:23px;"
                          [view]="'success'">Aprovado</tc-badge>
                        <tc-badge style="text-align: center;" *ngIf="data.approved == false" style="width: 125px;"
                          [view]="'danger'">Reprovado</tc-badge>
                      </td>
                    </tr>
                  </tbody>
                </nz-table>
              </tc-card>
            </div>
          </div>
        </div>
        <!-- Doughnut Chart - Questões Respondidas-->
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div>
              <div class="chartTitle chartTitleFont">
                <b>QUESTÕES RESPONDIDAS</b>
              </div>
              <tc-card>
                <!--Doughnut chart - Questões Respondidas-->
                <div style="display: block">
                  <canvas baseChart [data]="globalTotalQuestionsData" [labels]="totalQuestionsLabels"
                    [chartType]="totalQuestionsType" [colors]="Colors" (chartClick)="chartClicked($event)"
                    [options]="this.getChartOptions()">
                  </canvas>
                </div>
                <div class="row align-charts">
                  <div class="notApproved">
                    <b class="notApproved"> QUESTÕES <br /> POR RESPONDER </b><br />
                    <b><span class="value">
                        {{notAnsweredTaxGlobal}}%
                      </span></b>
                  </div>
                  <div class="approved">
                    <b class="approved"> QUESTÕES <br /> RESPONDIDAS </b><br />
                    <b><span class="value">
                        {{answeredTaxGlobal}}%
                      </span></b>
                  </div>
                </div>
              </tc-card>
            </div>
          </div>
        </div>
        <!--Tabela - Questões Respondidas pelo aluno-->
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div>
              <div class="chartTitle chartTitleFont">
                <b>TABELA DE QUESTÕES</b>
              </div>
              <tc-card>
                <div class="row searchButton mb-2">
                  <button tc-button class="btn-default" (click)="openFilterQuestions()"
                    [beforeIcon]="'icofont-search-document'" [view]="'default'">Filtro</button>
                </div>
                <tc-card style="background-color: #FAFAFA;" id="filtroQuestions" class="our-border hide">
                  <tc-form-group>
                    <tc-form-label>Id</tc-form-label>
                    <tc-input type="number" [(ngModel)]="idValue"></tc-input>
                  </tc-form-group>

                  <tc-form-group>
                    <tc-form-label>Origem</tc-form-label>
                    <tc-input type="text" [(ngModel)]="fontValue"></tc-input>
                  </tc-form-group>

                  <tc-form-group>
                    <tc-form-label>Questão</tc-form-label>
                    <tc-input type="text" [(ngModel)]="descriptionValue"></tc-input>
                  </tc-form-group>

                  <div class="d-flex justify-content-end">
                    <button tc-button class="btn-default mr-1" [beforeIcon]="'icofont-ui-reply'" (click)="reset()"
                      [view]="'default'">Reset</button>
                    <button tc-button class="btn-success" [view]="'default'" [beforeIcon]="'icofont-search-1'"
                      (click)="filterQuestionTable()">Filtrar</button>
                  </div>
                </tc-card>
                <h6 style="text-align: left;">Clique na questão para obter mais informação</h6>
                <nz-table #rowSelectionTableQuestion [nzData]="tableQuestionListData" [nzBordered]="bordered"
                  nzShowSizeChanger [nzPaginationPosition]="position" (nzPageIndexChange)="custom($event)"
                  [(nzPageSize)]="pageSizeTableQuestionListData"
                  (nzCurrentPageDataChange)="currentPageDataChange($event)" style="overflow: auto;" class="text-center">
                  <thead (nzSortChange)="sortQuestions($event)" nzSingleSort class="noClick">
                    <tr>
                      <th nzShowSort [(nzSort)]="mapOfSort.oldQuestionId" nzSortKey="oldQuestionId">Id</th>
                      <th nzShowSort [(nzSort)]="mapOfSort.font" nzSortKey="font">Origem</th>
                      <th nzShowSort [(nzSort)]="mapOfSort.description" nzSortKey="description">Questão </th>
                      <th class="text-center" nzShowSort [(nzSort)]="mapOfSort.wrongQuestions"
                        nzSortKey="wrongQuestions" rel="tooltip"
                        matTooltip="Nº de vezes que errou ao responder a esta questão">Erradas </th>
                      <th class="text-center" nzShowSort [(nzSort)]="mapOfSort.rightQuestions"
                        nzSortKey="rightQuestions" rel="tooltip"
                        matTooltip="Nº de vezes que acertou ao responder a esta questão">Certas </th>
                      <th class="text-center" nzShowSort [(nzSort)]="mapOfSort.isAnswer" nzSortKey="isAnswer"
                        rel="tooltip" matTooltip="resultado da ultima vez que respondeu a esta questão">Última Resposta
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of rowSelectionTableQuestion.data; let i = index;"
                      (click)="openDialogQuestion($event, i)">
                      <td class="text-center">{{ data.oldQuestionId | replace:'[a-zA-z]':''}}</td>
                      <td class="text-center">{{ data.font }}</td>
                      <td>{{ data.description }}</td>
                      <td class="text-center">{{ data.wrongQuestions }}</td>
                      <td class="text-center">{{ data.rightQuestions }}</td>
                      <td>
                        <tc-badge *ngIf="data.isAnswer == true" style="width: 90px; padding-left:35px;"
                          [view]="'success'">OK</tc-badge>
                        <tc-badge *ngIf="data.isAnswer == false" style="width: 90px;" [view]="'danger'">Falhou
                        </tc-badge>
                      </td>
                    </tr>
                  </tbody>
                </nz-table>
              </tc-card>
            </div>
          </div>
        </div>
        <br />
        <!--Horizontal bar - Taxa de Sucesso Global por Tema-->
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div>
              <div class="chartTitle chartTitleFont">
                <b>Estatísticas Por Tema</b>
              </div>
              <nz-table #themeStats [nzData]="tableTaxThemeData" [nzBordered]="bordered" [nzFrontPagination]="false"
                (nzCurrentPageDataChange)="currentPageDataChange($event)" style="overflow: auto;">
                <thead (nzSortChange)="sortTableThemeTaxGlobal($event)" nzSingleSort class="noClick">
                  <tr>
                    <th nzShowSort nzSortKey="name">Tema</th>
                    <th nzShowSort nzSortKey="totalQuestionsTheme">Respostas Totais</th>
                    <th nzShowSort nzSortKey="totalRightQuestions">Respostas Certas</th>
                    <th nzShowSort nzSortKey="totalWrongQuestions">Respostas Erradas</th>
                    <th nzShowSort nzSortKey="percentageRightTheme">Taxa de Sucesso</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of themeStats.data">
                    <td>{{data.name}}</td>
                    <td class="themeStatusValue">{{data.totalQuestionsTheme}}</td>
                    <td class="themeStatusValue">{{data.totalRightQuestions}}</td>
                    <td class="themeStatusValue">{{data.totalWrongQuestions}}</td>
                    <td class="themeStatusValue">{{data.percentageRightTheme}}%</td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!isTeacher()" id="RatingAlvesCosta" class="tabcontent dashTab">
        <h4 class="roboto300" *ngIf="showEmptyDashboardAC()" style="font-size: 20px">Não foram realizados testes
          Alves Costa
        </h4>
        <br />
        <div *ngIf="!showEmptyDashboardAC()">
          <!--Testes-->
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="chartTitle chartTitleFont">
                <b>TAXA DE APROVAÇÃO PARA EXAME</b>
              </div>
              <tc-card>
                <div class="row" style="overflow: auto;">
                  <div class="col-md-12 col-sm-12">
                    <div style="display: block"
                      *ngIf="alvesCostaChartData && ColorsHorizontalBars && alvesCostaTaxThemeLabels && globalTaxThemeType && chartOptions">
                      <canvas baseChart id="linear" class="bar-chart-horizontal" [datasets]="alvesCostaChartData"
                        [legend]="true" [colors]="ColorsHorizontalBars" [labels]="alvesCostaTaxThemeLabels"
                        [chartType]="globalTaxThemeType" [options]="chartOptions">
                      </canvas>
                    </div>
                  </div>
                </div>
              </tc-card>
              <!-- <tc-card>
                Doughnut chart - Taxa de aprovação Alves Costa
                <div style="display: block">
                  <canvas baseChart [data]="alvesCostaTestsTaxData" [labels]="totalTestsTaxLabels"
                    [chartType]="totalTestsTaxType" [colors]="Colors" (chartClick)="chartClicked($event)" [options]="this.getChartOptions()">
                  </canvas>
                </div>
                <div class="row align-charts">
                  <div class="notApproved">
                    <b class="notApproved"> TESTES <br /> REPROVADOS </b><br />
                    <b><span class="value">
                        {{notApprovedAC}}%
                      </span></b>
                  </div>
                  <div class="approved">
                    <b class="approved"> TESTES <br /> APROVADOS </b><br />
                    <b><span class="value">
                        {{approvedAC}}%
                      </span>
                    </b>
                  </div>
                </div>
              </tc-card> -->
            </div>
          </div>

          <!--Doughnut chart - Rating global-->
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="chartTitle chartTitleFont">
                <b>RATING GLOBAL</b>
              </div>
              <tc-card>
                <div class="row align-charts">
                  <div class="seen">
                    <b class="seen"> PERGUNTAS <br /> RESPONDIDAS </b><br />
                    <b><span class="value">
                        {{alvesCostaSeen}}%
                      </span></b>
                  </div>
                  <div class="questions">
                    <b class="questions"> RESPOSTAS <br /> CORRETAS </b><br />
                    <b><span class="value">
                        {{alvesCostaQuestions}}%
                      </span></b>
                  </div>
                </div>
                <div class="col-md-12 col-sm-12">
                  <div style="display: block">
                    <canvas baseChart width="100%" [data]="alvesCostaRatingData" [labels]="globalRatingLabels"
                      [chartType]="globalRatingType" [colors]="ColorsGlobalRatingChart"
                      [options]="this.getChartOptions()">
                    </canvas>
                  </div>
                </div>
                <div class="row align-charts">
                  <div class="tests">
                    <div>
                      <b class="tests"> TESTES <br /> APROVADOS </b><br />
                      <b><span class="value">
                          {{alvesCostaTests}}%
                        </span></b>
                    </div>
                  </div>
                  <div class="themes">
                    <b class="themes"> TEMAS <br /> CORRETOS </b><br />
                    <b><span class="value">
                        {{alvesCostaThemes}}%
                      </span>
                    </b>
                  </div>
                </div>
              </tc-card>
            </div>
          </div>

          <!--Tabela - Testes Realizados-->
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div>
                <div class="chartTitle chartTitleFont">
                  <b>TESTES REALIZADOS</b>
                </div>
                <tc-card>
                  <div class="row searchButton mb-2">
                    <button tc-button class="btn-default" (click)="openFilterTestsAlvesCosta()"
                      [beforeIcon]="'icofont-search-document'" [view]="'default'">Filtro</button>
                  </div>
                  <tc-card style="background-color: #F1F1F2;" id="filterTestsAlvesCosta" class="our-border hide">
                    <tc-form-group>
                      <tc-form-label>Tipo</tc-form-label>
                      <mat-select [(ngModel)]="typeValueAC" class="select-box">
                        <mat-option *ngIf="typeValueAC != '' " value="  "></mat-option>
                        <mat-option value="Exame">Exame</mat-option>
                        <mat-option value="Tematico">Temático</mat-option>
                      </mat-select>
                      <!-- <tc-input type="text" [(ngModel)]="typeValueAC"></tc-input> -->
                    </tc-form-group>

                    <div class="d-flex justify-content-end">
                      <button tc-button class="btn-default mr-1" [beforeIcon]="'icofont-ui-reply'" (click)="reset()"
                        [view]="'default'">Reset</button>
                      <button tc-button class="btn-success" [view]="'default'" [beforeIcon]="'icofont-search-1'"
                        (click)="filterTestTableAlvesCosta()">Filtrar</button>
                    </div>
                  </tc-card>
                  <nz-table #rowSelectionTableTestAC [nzData]="alvesCostaTableTestListData" [nzBordered]="bordered"
                    nzShowSizeChanger [nzPaginationPosition]="position" (nzPageIndexChange)="custom($event)"
                    [(nzPageSize)]="pageSizeAlvesCostaTableTestListData"
                    (nzCurrentPageDataChange)="currentPageDataChange($event)" style="overflow: auto;"
                    class="text-center">
                    <thead (nzSortChange)="sortTestTableAlvesCosta($event)" nzSingleSort class="noClick">
                      <tr>
                        <th nzShowSort [(nzSort)]="mapOfSort.endDt" nzSortKey="endDt">Data</th>
                        <th [(nzSort)]="mapOfSort.font" nzSortKey="font">Origem</th>
                        <th nzShowSort [(nzSort)]="mapOfSort.tag" nzSortKey="tag">Tipo </th>
                        <th nzShowSort [(nzSort)]="mapOfSort.wrongQuestions" nzSortKey="wrongQuestions">Questões Erradas
                        </th>
                        <th [(nzSort)]="mapOfSort.approved" nzSortKey="approved">Resultado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of rowSelectionTableTestAC.data; let i = index;"
                        (click)="openDialogTestAC($event, i)">
                        <td class="text-center">{{data.endDt | date: 'dd/MM/yyyy H:mm:ss'}}</td>
                        <td class="text-center">{{ data.font }}</td>
                        <td class="text-center">{{ data.tag }}</td>
                        <td class="text-center">{{ data.wrongQuestions }}</td>
                        <td class="text-center">
                          <tc-badge *ngIf="data.approved == true" style="width: 125px; padding-left:23px;"
                            [view]="'success'">
                            Aprovado</tc-badge>
                          <tc-badge *ngIf="data.approved == false" style="width: 125px;" [view]="'danger'">
                            Reprovado
                          </tc-badge>
                        </td>
                      </tr>
                    </tbody>
                  </nz-table>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <div class="search-box">
                      <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValue" />
                      <button nz-button nzSize="small" nzType="primary" (click)="sortTestTableAlvesCosta()"
                        class="search-button">
                        Search
                      </button>
                      <button nz-button nzSize="small" (click)="reset()">Reset</button>
                    </div>
                  </nz-dropdown-menu>
                </tc-card>
              </div>
            </div>
          </div>
          <br />

          <!--Questões Respondidas-->
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="chartTitle chartTitleFont">
                <b>QUESTÕES RESPONDIDAS</b>
              </div>
              <tc-card>
                <!--Doughnut chart - Questões Respondidas Alves Costa-->
                <div style="display: block">
                  <canvas baseChart [data]="alvesCostaTotalQuestionsData" [labels]="totalQuestionsLabels"
                    [chartType]="totalQuestionsType" [colors]="Colors" (chartClick)="chartClicked($event)"
                    [options]="this.getChartOptions()">
                  </canvas>
                </div>
                <div class="row align-charts">
                  <div class="approved">
                    <b class="approved"> QUESTÕES <br /> RESPONDIDAS </b><br />
                    <b><span class="value">
                        {{answeredTaxAC}}%
                      </span></b>
                  </div>
                  <div class="notApproved">
                    <b class="notApproved"> QUESTÕES <br /> POR RESPONDER </b><br />
                    <b><span class="value">
                        {{notAnsweredTaxAC}}%
                      </span></b>
                  </div>
                </div>
              </tc-card>
            </div>
          </div>

          <!--Tabela - Questões Respondidas pelo aluno-->
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div>
                <div class="chartTitle chartTitleFont">
                  <b>TABELA DE QUESTÕES</b>
                </div>
                <tc-card>
                  <div class="row searchButton mb-2">
                    <button tc-button class="btn-default" (click)="openFilterQuestionsAlvesCosta()"
                      [beforeIcon]="'icofont-search-document'" [view]="'default'">Filtro</button>
                  </div>
                  <tc-card style="background-color: #F1F1F2;" id="filterQuestionsAlvesCosta" class="our-border hide">
                    <tc-form-group>
                      <tc-form-label>Id</tc-form-label>
                      <tc-input type="number" [(ngModel)]="idValueAC"></tc-input>
                    </tc-form-group>

                    <tc-form-group>
                      <tc-form-label>Questão</tc-form-label>
                      <tc-input type="text" [(ngModel)]="descriptionValueAC"></tc-input>
                    </tc-form-group>

                    <div class="d-flex justify-content-end">
                      <button tc-button class="btn-default mr-1" [beforeIcon]="'icofont-ui-reply'" (click)="reset()"
                        [view]="'default'">Reset</button>
                      <button tc-button class="btn-success" [view]="'default'" [beforeIcon]="'icofont-search-1'"
                        (click)="filterQuestionTableAlvesCosta()">Filtrar</button>
                    </div>
                  </tc-card>
                  <nz-table #rowSelectionTableQuestionAC [nzData]="alvesCostaTableQuestionListData"
                    [nzBordered]="bordered" nzShowSizeChanger [nzPaginationPosition]="position"
                    (nzPageIndexChange)="custom($event)" [(nzPageSize)]="pageSizeAlvesCostaTableQuestionListData"
                    (nzCurrentPageDataChange)="currentPageDataChange($event)" style="overflow: auto;"
                    class="text-center">
                    <thead (nzSortChange)="sortQuestionsAlvesCosta($event)" nzSingleSort class="noClick">
                      <tr>
                        <th nzShowSort [(nzSort)]="mapOfSort.oldQuestionId" nzSortKey="oldQuestionId">Id</th>
                        <th [(nzSort)]="mapOfSort.font" nzSortKey="font">Origem</th>
                        <th nzShowSort [(nzSort)]="mapOfSort.description" nzSortKey="description">Questão </th>
                        <th class="text-center" nzShowSort [(nzSort)]="mapOfSort.wrongQuestions"
                          nzSortKey="wrongQuestions">Erradas </th>
                        <th class="text-center" nzShowSort [(nzSort)]="mapOfSort.rightQuestions"
                          nzSortKey="rightQuestions">Certas </th>
                        <th class="text-center" nzShowSort [(nzSort)]="mapOfSort.isAnswer" nzSortKey="isAnswer">Última
                          Resposta</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of rowSelectionTableQuestionAC.data; let i = index;"
                        (click)="openDialogQuestionAC($event, i)">
                        <td class="text-center">{{ data.oldQuestionId | replace:'[a-zA-z]':''}}</td>
                        <td class="text-center">{{ data.font }}</td>
                        <td>{{ data.description }}</td>
                        <td class="text-center">{{ data.wrongQuestions }}</td>
                        <td class="text-center">{{ data.rightQuestions }}</td>
                        <td class="text-center">
                          <tc-badge *ngIf="data.isAnswer == true" style="width: 90px; padding-left:35px;"
                            [view]="'success'">OK</tc-badge>
                          <tc-badge *ngIf="data.isAnswer == false" style="width: 90px;" [view]="'danger'">Falhou
                          </tc-badge>
                        </td>
                      </tr>
                    </tbody>
                  </nz-table>
                </tc-card>
              </div>
            </div>
          </div>
          <br />

          <!--Horizontal bar - Taxa de Sucesso Global por Tema-->
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div>
                <div class="chartTitle chartTitleFont">
                  <b>TAXA DE SUCESSO GLOBAL POR TEMA</b>
                </div>
                <nz-table #themeStatsAC [nzData]="alvesCostaTableTaxThemeData" [nzBordered]="bordered"
                  [nzFrontPagination]="false" (nzCurrentPageDataChange)="currentPageDataChange($event)"
                  style="overflow: auto;">
                  <thead (nzSortChange)="sortTableThemeTaxAlvesCosta($event)" nzSingleSort class="noClick">
                    <tr>
                      <th nzShowSort nzSortKey="name">Tema</th>
                      <th nzShowSort nzSortKey="totalQuestionsTheme">Respostas Totais</th>
                      <th nzShowSort nzSortKey="totalRightQuestions">Respostas Certas</th>
                      <th nzShowSort nzSortKey="totalWrongQuestions">Respostas Erradas</th>
                      <th nzShowSort nzSortKey="percentageRightTheme">Taxa de Sucesso</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of themeStatsAC.data">
                      <td>{{data.name}}</td>
                      <td class="themeStatusValue">{{data.totalQuestionsTheme}}</td>
                      <td class="themeStatusValue">{{data.totalRightQuestions}}</td>
                      <td class="themeStatusValue">{{data.totalWrongQuestions}}</td>
                      <td class="themeStatusValue">{{data.percentageRightTheme}}%</td>
                    </tr>
                  </tbody>
                </nz-table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!isTeacher()" id="RatingIMT" class="tabcontent dashTab">
        <h4 *ngIf="showEmptyDashboardImt()" class="roboto300" style="font-size: 20px">Não foram realizados
          testes do IMT
        </h4>
        <br />
        <div *ngIf="!showEmptyDashboardImt()">
          <!--Taxa de aprovação global-->
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="chartTitle chartTitleFont">
                <b>TAXA DE APROVAÇÃO PARA EXAME</b>
              </div>
              <tc-card>
                <div class="row" style="overflow: auto;">
                  <div class="col-md-12 col-sm-12">
                    <div style="display: block"
                      *ngIf="imtChartData && ColorsHorizontalBars && imtTaxThemeLabels && globalTaxThemeType && chartOptions">
                      <canvas baseChart id="linear" class="bar-chart-horizontal" [datasets]="imtChartData"
                        [legend]="true" [colors]="ColorsHorizontalBars" [labels]="imtTaxThemeLabels"
                        [chartType]="globalTaxThemeType" [options]="chartOptions">
                      </canvas>
                    </div>
                  </div>
                </div>
              </tc-card>
              <!--Doughnut chart - Taxa de aprovação -->
              <!-- <div style="display: block">
                  <canvas baseChart [data]="imtTestsTaxData" [labels]="totalTestsTaxLabels"
                    [chartType]="totalTestsTaxType" [colors]="Colors" (chartClick)="chartClicked($event)" [options]="this.getChartOptions()">
                  </canvas>
                </div>
                <div class="row align-charts">
                  <div class="notApproved">
                    <b class="notApproved"> TESTES <br /> REPROVADOS </b><br />
                    <b><span class="value">
                        {{notApprovedImt}}%
                      </span>
                    </b>
                  </div>
                  <div class="approved">
                    <b class="approved"> TESTES <br /> APROVADOS </b><br />
                    <b><span class="value">
                        {{approvedImt}}%
                      </span>
                    </b>
                  </div>
                </div>
              </tc-card> -->
            </div>
          </div>

          <!--Doughnut chart - Rating global-->
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="chartTitle chartTitleFont">
                <b>RATING GLOBAL</b>
              </div>
              <tc-card>
                <div class="row align-charts">
                  <div class="seen">
                    <b class="seen"> PERGUNTAS <br /> RESPONDIDAS </b><br />
                    <b><span class="value">
                        {{imtSeen}}%
                      </span></b>
                  </div>
                  <div class="questions">
                    <b class="questions"> RESPOSTAS<br /> CORRETAS </b><br />
                    <b><span class="value">
                        {{imtQuestions}}%
                      </span></b>
                  </div>
                </div>
                <div class="col-md-12 col-sm-12">
                  <div style="display: block">
                    <canvas baseChart width="100%" [data]="globalRatingData" [labels]="globalRatingLabels"
                      [chartType]="globalRatingType" [colors]="ColorsGlobalRatingChart"
                      [options]="this.getChartOptions()">
                    </canvas>
                  </div>
                </div>
                <div class="row align-charts">
                  <div class="tests">
                    <div>
                      <b class="tests"> TESTES <br /> APROVADOS </b><br />
                      <b><span class="value">
                          {{imtTests}}%
                        </span></b>
                    </div>
                  </div>
                  <div class="themes">
                    <b class="themes"> TEMAS <br /> CORRETOS </b><br />
                    <b><span class="value">
                        {{imtThemes}}%
                      </span>
                    </b>
                  </div>
                </div>
              </tc-card>
            </div>
          </div>

          <!--Tabela - Testes Realizados-->
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div>
                <div class="chartTitle chartTitleFont">
                  <b>TESTES REALIZADOS</b>
                </div>
                <tc-card>
                  <div class="row searchButton mb-2">
                    <button tc-button class="btn-default" (click)="openFilterTestsImt()"
                      [beforeIcon]="'icofont-search-document'" [view]="'default'">Filtro</button>
                  </div>
                  <tc-card style="background-color: #FAFAFA;" id="filterTestsImt" class="our-border hide">

                    <tc-form-group>
                      <tc-form-label>Tipo</tc-form-label>
                      <mat-select [(ngModel)]="typeValueImt" class="select-box">
                        <mat-option *ngIf="typeValueImt != '' " value="  "></mat-option>
                        <mat-option value="Exame">Exame</mat-option>
                        <mat-option value="Tematico">Temático</mat-option>
                      </mat-select>
                      <!-- <tc-input type="text" [(ngModel)]="typeValueImt"></tc-input> -->
                    </tc-form-group>

                    <div class="d-flex justify-content-end">
                      <button tc-button class="btn-default mr-1" [beforeIcon]="'icofont-ui-reply'" (click)="reset()"
                        [view]="'default'">Reset</button>
                      <button tc-button class="btn-success" [view]="'default'" [beforeIcon]="'icofont-search-1'"
                        (click)="filterTestTableImt()">Filtrar</button>
                    </div>
                  </tc-card>

                  <nz-table #rowSelectionTableTestImt [nzData]="imtTableTestListData" [nzBordered]="bordered"
                    nzShowSizeChanger [nzPaginationPosition]="position" (nzPageIndexChange)="custom($event)"
                    [(nzPageSize)]="pageSizeImtTableTestListData"
                    (nzCurrentPageDataChange)="currentPageDataChange($event)" style="overflow: auto;"
                    class="text-center">
                    <thead (nzSortChange)="sortTestTableImt($event)" nzSingleSort class="noClick">
                      <tr>
                        <th nzShowSort [(nzSort)]="mapOfSort.endDt" nzSortKey="endDt">Data</th>
                        <th [(nzSort)]="mapOfSort.font" nzSortKey="font">Origem</th>
                        <th nzShowSort [(nzSort)]="mapOfSort.tag" nzSortKey="tag">Tipo </th>
                        <th nzShowSort [(nzSort)]="mapOfSort.wrongQuestions" nzSortKey="wrongQuestions">Questões Erradas
                        </th>
                        <th [(nzSort)]="mapOfSort.approved" nzSortKey="approved">Resultado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of rowSelectionTableTestImt.data; let i = index;"
                        (click)="openDialogTestImt($event, i)">
                        <td class="text-center">{{data.endDt | date: 'dd/MM/yyyy H:mm:ss'}}</td>
                        <td class="text-center">{{ data.font }}</td>
                        <td class="text-center">{{ data.tag }}</td>
                        <td class="text-center">{{ data.wrongQuestions }}</td>
                        <td class="text-center">
                          <tc-badge *ngIf="data.approved == true" style="width: 125px; padding-left:23px;"
                            [view]="'success'">
                            Aprovado</tc-badge>
                          <tc-badge *ngIf="data.approved == false" style="width: 125px;" [view]="'danger'">
                            Reprovado
                          </tc-badge>
                        </td>
                      </tr>
                    </tbody>
                  </nz-table>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <div class="search-box">
                      <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValue" />
                      <button nz-button nzSize="small" nzType="primary" (click)="sortTestTableImt()"
                        class="search-button">
                        Search
                      </button>
                      <button nz-button nzSize="small" (click)="reset()">Reset</button>
                    </div>
                  </nz-dropdown-menu>
                </tc-card>
              </div>
            </div>
          </div>

          <!--Doughnut chart - Questões Respondidas-->
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="chartTitle chartTitleFont">
                <b>QUESTÕES RESPONDIDAS</b>
              </div>
              <tc-card>
                <div style="display: block">
                  <canvas baseChart [data]="imtTotalQuestionsData" [labels]="totalQuestionsLabels"
                    [chartType]="totalQuestionsType" [colors]="Colors" (chartClick)="chartClicked($event)"
                    [options]="this.getChartOptions()">
                  </canvas>
                </div>
                <div class="row align-charts">
                  <div class="approved">
                    <b class="approved"> QUESTÕES <br /> RESPONDIDAS </b><br />
                    <b><span class="value">
                        {{answeredTaxImt}}%
                      </span></b>
                  </div>
                  <div class="notApproved">
                    <b class="notApproved"> QUESTÕES <br /> POR RESPONDER </b><br />
                    <b><span class="value">
                        {{notAnsweredTaxImt}}%
                      </span></b>
                  </div>
                </div>
              </tc-card>
            </div>

          </div>

          <!--Questões Respondidas-->
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div>
                <div class="chartTitle chartTitleFont">
                  <b>TABELA DE QUESTÕES</b>
                </div>
                <tc-card>
                  <div class="row searchButton mb-2">
                    <button tc-button class="btn-default" (click)="openFilterQuestionsImt()"
                      [beforeIcon]="'icofont-search-document'" [view]="'default'">Filtro</button>
                  </div>
                  <tc-card style="background-color: #FAFAFA;" id="filterQuestionsImt" class="our-border hide">
                    <tc-form-group>
                      <tc-form-label>Id</tc-form-label>
                      <tc-input type="number" [(ngModel)]="idValueImt"></tc-input>
                    </tc-form-group>

                    <tc-form-group>
                      <tc-form-label>Questão</tc-form-label>
                      <tc-input type="text" [(ngModel)]="descriptionValueImt"></tc-input>
                    </tc-form-group>

                    <div class="d-flex justify-content-end">
                      <button tc-button class="btn-default mr-1" [beforeIcon]="'icofont-ui-reply'" (click)="reset()"
                        [view]="'default'">Reset</button>
                      <button tc-button class="btn-success" [view]="'default'" [beforeIcon]="'icofont-search-1'"
                        (click)="filterQuestionTableImt()">Filtrar</button>
                    </div>

                  </tc-card>

                  <nz-table #rowSelectionTableQuestionImt [nzData]="imtTableQuestionListData" [nzBordered]="bordered"
                    nzShowSizeChanger [nzPaginationPosition]="position" (nzPageIndexChange)="custom($event)"
                    [(nzPageSize)]="pageSizeImtTableQuestionListData"
                    (nzCurrentPageDataChange)="currentPageDataChange($event)" style="overflow: auto;"
                    class="text-center">
                    <thead (nzSortChange)="sortQuestionsImt($event)" nzSingleSort class="noClick">
                      <tr>
                        <th nzShowSort [(nzSort)]="mapOfSort.oldQuestionId" nzSortKey="oldQuestionId">Id</th>
                        <th [(nzSort)]="mapOfSort.font" nzSortKey="font">Origem</th>
                        <th nzShowSort [(nzSort)]="mapOfSort.description" nzSortKey="description">Questão </th>
                        <th class="text-center" nzShowSort [(nzSort)]="mapOfSort.wrongQuestions"
                          nzSortKey="wrongQuestions">Erradas </th>
                        <th class="text-center" nzShowSort [(nzSort)]="mapOfSort.rightQuestions"
                          nzSortKey="rightQuestions">Certas </th>
                        <th class="text-center" nzShowSort [(nzSort)]="mapOfSort.isAnswer" nzSortKey="isAnswer">Última
                          Resposta</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of rowSelectionTableQuestionImt.data; let i = index;"
                        (click)="openDialogQuestionImt($event, i)">
                        <td class="text-center">{{ data.oldQuestionId | replace:'[a-zA-z]':''}}</td>
                        <td class="text-center">{{ data.font }}</td>
                        <td>{{ data.description }}</td>
                        <td class="text-center">{{ data.wrongQuestions }}</td>
                        <td class="text-center">{{ data.rightQuestions }}</td>
                        <td>
                          <tc-badge *ngIf="data.isAnswer == true" style="width: 90px; padding-left:35px;"
                            [view]="'success'">
                            OK</tc-badge>
                          <tc-badge *ngIf="data.isAnswer == false" style="width: 90px;" [view]="'danger'">Falhou
                          </tc-badge>
                        </td>
                      </tr>
                    </tbody>
                  </nz-table>
                </tc-card>
              </div>
            </div>
          </div>
          <!--Horizontal bar - Taxa de Sucesso Global por Tema-->
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div>
                <div class="chartTitle chartTitleFont">
                  <b>TAXA DE SUCESSO GLOBAL POR TEMA</b>
                </div>
                <tc-card class="mb-0 custom">
                  <nz-table #themeStatsIMT [nzData]="imtTableTaxThemeData" [nzBordered]="bordered"
                    [nzFrontPagination]="false" (nzCurrentPageDataChange)="currentPageDataChange($event)"
                    style="overflow: auto;">
                    <thead (nzSortChange)="sortTableThemeTaxImt($event)" nzSingleSort class="noClick">
                      <tr>
                        <th nzShowSort nzSortKey="name">Tema</th>
                        <th nzShowSort class="themeStatusValue" nzSortKey="totalQuestionsTheme">Respostas Totais</th>
                        <th nzShowSort class="themeStatusValue" nzSortKey="totalRightQuestions">Respostas Certas</th>
                        <th nzShowSort class="themeStatusValue" nzSortKey="totalWrongQuestions">Respostas Erradas</th>
                        <th nzShowSort class="themeStatusValue" nzSortKey="percentageRightTheme">Taxa de Sucesso</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of themeStatsIMT.data">
                        <td>{{data.name}}</td>
                        <td class="themeStatusValue">{{data.totalQuestionsTheme}}</td>
                        <td class="themeStatusValue">{{data.totalRightQuestions}}</td>
                        <td class="themeStatusValue">{{data.totalWrongQuestions}}</td>
                        <td class="themeStatusValue">{{data.percentageRightTheme}}%</td>
                      </tr>
                    </tbody>
                  </nz-table>
                </tc-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mat-action-row *ngIf="false">
        <button mat-button>Click me</button>
      </mat-action-row>
    </div>
  </mat-expansion-panel>
</mat-accordion>