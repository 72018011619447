import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CategoryThemeService = /** @class */ (function () {
    function CategoryThemeService(http) {
        this.http = http;
    }
    CategoryThemeService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl + "/CategoryTheme/");
    };
    CategoryThemeService.prototype.getThemesProductById = function (id, font) {
        return this.http.get(environment.apiUrl + "/CategoryTheme/byTheme/" + id + "/" + font);
    };
    CategoryThemeService.prototype.getNewThemesProductById = function (id, font) {
        return this.http.get(environment.apiUrl + "/CategoryTheme/byTheme/" + id + "/" + font);
    };
    CategoryThemeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoryThemeService_Factory() { return new CategoryThemeService(i0.ɵɵinject(i1.HttpClient)); }, token: CategoryThemeService, providedIn: "root" });
    return CategoryThemeService;
}());
export { CategoryThemeService };
