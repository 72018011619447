import { Company } from '../models/Company';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Company[]>(`${environment.apiUrl}/company/`);
  }

  getAllSorted() {
    return this.http.get<Company[]>(`${environment.apiUrl}/company/`);
  }


  getById(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/company/${id}`);
  }
  getByName(name: string) {
    name = name === undefined || '' ? 'AlvesCosta' : name;
    return this.http.get<any>(`${environment.apiUrl}/company/name/${name}`);
  }

  createCompany(systemTs: string, Name: string, Email: string, NIF: string, Contacts: string, CompanyTypeId: number,
    address: string, active: boolean, PlataformName: string, Prefix: string, Alvara: string, ComercialUserId: number) {

    return this.http.post<Company>(`${environment.apiUrl}/company/`, {
      systemTs, Name, Email, NIF, Contacts, CompanyTypeId,
      address, active, PlataformName, Prefix, Alvara, ComercialUserId
    }
    );
  }
  updateCompany(id: number, systemTs: string, Name: string, Email: string, NIF: string, Contacts: string, CompanyTypeId: number,
    address: string, active: boolean, PlataformName: string, Prefix: string, Alvara: string, url: string, ComercialUserId: number, EmailInstrutores: string) {
    return this.http.put<Company>(`${environment.apiUrl}/company/${id}`, {
      systemTs, Name, Email, NIF, Contacts, CompanyTypeId,
      address, active, PlataformName, Prefix, Alvara, url, ComercialUserId, EmailInstrutores
    }
    );
  }
  deleteCompany(id: number) {
    return this.http.delete<Company>(`${environment.apiUrl}/company/${id}`);
  }
  getCompaniesOfComercial(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/company/comercial/${id}`);
  }

  upload(id: number, mimeType: string, url: string, name: string, size: string, description: string) {
    console.log(url);
    return this.http.post<Company>(`${environment.apiUrl}/company/upload/${id}`, { mimeType, url, name, size, description });
  }
  unlinkComercialUser(id: number, companyId: number) {
    console.log(id);
    console.log(companyId);
    return this.http.delete<Company>(`${environment.apiUrl}/company/unlink/${id}/${companyId}`);
  }
}
