import { Component, Inject, Optional, OnInit, ComponentFactoryResolver, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTreeNestedDataSource, MatDialog } from '@angular/material';
import { UserModel } from '../../../../models/userModel';
import { CompanyService } from '../../../../services/company.service';
import { ProfileService } from '../../../../services/profile.service';
import { TicketService } from '../../../../services/ticket.service';
import { ProductModel } from '../../../../models/productModel';
import { NestedTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { ProductService } from '../../../../services/product.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Duration } from '../../../../models/Duration';
import { DurationService } from '../../../../services/duration.service';
import { first } from 'rxjs/internal/operators/first';
import { MediaService } from '../../../../services/media.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../../services/auth.service';
import { PageDashboardComponent } from '../../../dashboards/dashboard/dashboard.component';
import { TestService } from '../../../../services/test.service';
import { AlertMailService } from '../../../../services/alert-mail.service';
import { ConfirmComponent } from '../confirm/confirm.component';
import { UserService } from '../../../../services/user.service';
import { didacticProductModel } from '../../../../models/didacticProductModel';
import { Utils } from 'src/utils/utils';

@Component({
  selector: 'app-dialog-box-users',
  templateUrl: './dialog-box-users.component.html',
  styleUrls: ['./dialog-box-users.component.css']
})


export class DialogBoxUsersComponent implements OnInit {

  showEmptyDashboardValueAC = false;
  showEmptyDashboardValueImt = false;
  showEmptyDashboardValue = false;
  userGeralForm: FormGroup;
  userTicketsForm: FormGroup;
  loading = false;
  submitted = false;
  companies: any[];
  action: string;
  public localData: any;
  profile: any[];
  tickets: any[];
  didacticTickets: any[];
  durations: Duration[];
  products: ProductModel[];
  didacticProducts: didacticProductModel[];
  selectedCompanies: any[];
  public message: string;
  public imagePath = '';
  selectedValue = '';
  public imgURL: any = "assets/img/img_avatar.png";
  treeControl = new NestedTreeControl<ProductModel>(node => node.children);
  dataSource = new MatTreeNestedDataSource<ProductModel>();
  selectedAll = true;
  selection = new SelectionModel<ProductModel>(true, []);
  currentUser;
  schools: any[];
  emailExists = false;
  categoryProductList: any[] = [];
  UserNameExists = false;
  dashboard: PageDashboardComponent;
  constructor(
    public dialogRef: MatDialogRef<DialogBoxUsersComponent>, private resolver: ComponentFactoryResolver,
    private profileService: ProfileService, private companyService: CompanyService,
    private ticketService: TicketService, private productService: ProductService,
    public dialog: MatDialog, private userService: UserService,
    private durationService: DurationService, private formBuilder: FormBuilder, private authenticationService: AuthService,
    private mediaService: MediaService, private toastr: ToastrService, private alertMailService: AlertMailService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UserModel, private testService: TestService,
    public utils: Utils) {

    this.localData = { ...data };
    console.log(this.localData);
    this.action = this.localData.action;
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    if (this.action != 'Adicionar') {
      this.mediaService.getImageByUserId(this.localData.userId).subscribe(x => {
        if (x.image != null && x.image != '') {
          this.imgURL = 'data:image/jpg;base64,' + x.image;
        }
      });

      if (this.localData.isReportPage == false || this.localData.isReportPage == undefined) {
        this.companyService.getByName(this.localData.plataformName).subscribe(companies => {

          this.productService.getAllWithChildrenByCompanyId(companies['companyId']).subscribe(products => {
            this.dataSource.data = products;
            this.products = products;
          });

          this.durationService.getDurationdByCompanyId(companies['companyId']).subscribe(durations => {
            this.durations = durations;
          });

          this.productService.getAllDidacticmWithChildrenByCompanyId(companies['companyId']).subscribe(dproducts => {
            // this.dataSource.data = products;
            this.didacticProducts = dproducts;
          });
        });

        this.ticketService.ticketsByUserId(this.localData.userId).subscribe(tickets => {
          this.tickets = tickets['hasTickets'];
        });

        this.ticketService.didacticTicketsByUserId(this.localData.userId).subscribe(dtickets => {
          this.didacticTickets = dtickets['hasTickets'];
        });
      }

      if (this.localData.profileName == 'Comercial') {
        this.companyService.getCompaniesOfComercial(this.localData.userId).subscribe(company => {
          this.schools = company;
        });
      }
      this.companyService.getAllSorted().subscribe(companies => {
        this.companies = companies;
        let compan = this.companies.filter(item => {
          return item.name === this.localData.companyName;
        })[0];
        this.localData.prefix = compan['prefix'];

      });
    }
    if (this.localData.profileName != 'Aluno' || this.currentUser.user.profileId == 1) {
      this.profileService.getAll().subscribe(profiles => {
        this.profile = profiles;
        console.log(this.profile);
      });
      this.companyService.getAllSorted().subscribe(companies => {
        this.companies = companies;
        let compan = this.companies.filter(item => {
          return item.name === this.localData.companyName;
        })[0];
        this.localData.prefix = compan['prefix'];

      });
    }
  }

  isReadOnly(): boolean {
    //action = modificar e perfil é escola e o 
    return (this.currentUser.user.profileId == 6 || this.currentUser.user.profileId == 7 || this.currentUser.user.profileId == 8) && this.action != 'Adicionar';
  }
  
  // convenience getter for easy access to form fields
  get fgeral() { return this.userGeralForm.controls; }
  get fticket() { return this.userTicketsForm.controls; }

  onChanges(): void {
    this.fgeral.email.valueChanges.subscribe(x => {
      this.userService.checkEmail(x, this.localData.userId).subscribe(a => {
        this.emailExists = a == true ? true : false;
      });
    });
  }
  onChangesNumber(): void {
    this.fgeral.studentNumber.valueChanges.subscribe(x => {
      this.userService.checkUserName(this.localData.prefix + '' + x).subscribe(a => {
        this.UserNameExists = a == true ? true : false;
      });
    });
  }
  changeCompany() {
    let compan = this.companies.filter(item => {
      return item.name === this.fgeral.company.value;
    })[0];
    this.localData.prefix = compan['prefix'];
  }
  changeProfile() {
    this.selectedValue = this.fgeral.profile.value;
  }
  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }
  hasChild = (_: number, node: ProductModel) => !!node.children && node.children.length > 0;

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  onSubmitGeral() {
    this.localData.companyId = [];
    let profiles;
    let compan;
    if (this.currentUser.user.profileId != 6) {
      profiles = this.profile.filter(item => {
        return item.name === this.fgeral.profile.value;
      })[0];
      compan = this.companies.filter(item => {
        return item.name === this.fgeral.company.value;
      })[0];
      this.localData.profileId = profiles['profileId'];
      if (this.localData.profileName != 'Comercial') {
        this.localData.companyId.push(compan['companyId']);
      } else {
        this.selectedCompanies.forEach(element => {
          this.localData.companyId.push(this.companies.filter(item => { return item.name === element; })[0]['companyId']);
        });
      }
    } else {
      this.localData.companyId.push(this.currentUser.user.companyId);
      this.localData.profileId = 3;
    }

    this.localData.active = this.fgeral.status.untouched == true ? this.localData.active : this.fgeral.status.value;
    this.localData.contacts = this.fgeral.contacts.value;
    this.localData.name = this.fgeral.name.value;
    this.localData.nif = this.fgeral.nif.value;
    this.localData.cc = this.fgeral.cc.value;
    this.localData.email = this.fgeral.email.value;
    this.localData.learningLicense = this.fgeral.learningLicense.value;
    
    if(this.fgeral.studentNumber)
      this.localData.studentNumber = this.fgeral.studentNumber.value.replace('%2F', '/') == ('' || null) ? '' : this.fgeral.studentNumber.value.replace('%2F', '/');
       
    if (this.currentUser.user.profileId == 6) {
      if (this.fgeral.name.valid && this.fgeral.email.valid && this.fgeral.studentNumber.valid) {
        this.dialogRef.close({ event: this.action + 'Geral', data: this.localData });
      }
      else {
        this.toastr.error("formulario com erros");
      }
    } else {
      if (this.fgeral.name.valid && this.fgeral.email.valid && this.fgeral.company.valid
        && this.fgeral.companyN.valid && this.fgeral.learningLicense.valid && this.fgeral.profile.valid && this.fgeral.status.valid) {
        this.dialogRef.close({ event: this.action + 'Geral', data: this.localData });
        console.log(this.action);
      }
      else {
        this.toastr.error("formulario com erros");
        console.log("dsfdsf2");
      }
    }
  }

  submitTickets(i, ticketType: string) {
    let row: any = {};
    const deviceWidth = this.utils.isMobileDevice() == true ? '80vw' : '25vw';
    const devideHeight = this.utils.isMobileDevice() == true ? 'auto' : '25vh';
    row.text = 'Confirma a atribuição da/s senha/s?'
    let dialogRef = this.dialog.open(ConfirmComponent, {
      id: 'confirm',
      width: deviceWidth,
      height: devideHeight,
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) { }
      else if (result.event == 'sim') {
        this.companyService.getAll().subscribe(companies => {
          const compan = companies.filter(item => {
            return item.name === this.localData.companyName;
          })[0];

          if (ticketType == 'didacticTicket') {
            this.ticketService.ticketByDidacticMaterialIdToUser(compan['companyId'],
              this.didacticProducts[i]['didacticMaterialId'], this.didacticProducts[i]['durationId'], this.localData.userId).subscribe((data) => {
                this.toastr.success('Senhas atribuídas com sucesso!');
              },
                err => {
                  this.toastr.error('Algo ocorreu mal!');
                }
              );
          }
          else {
            console.log(this.products[i]['categoryId']);
            console.log(this.products[i]);
            console.log(this.products);

            this.ticketService.ticketByCategoryIdToUser(compan['companyId'], this.products[i]['productId'],
              this.products[i]['categoryId'], this.products[i]['durationId'], this.localData.userId).subscribe((data) => {
                this.toastr.success('Senhas atribuídas com sucesso!');
              },
                err => {
                  this.toastr.error('Algo ocorreu mal!');
                }
              );

          }


        });
        this.dialogRef.close({});
      }
    });
  }

  ngOnInit() {
    this.userGeralForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      nif: new FormControl(''),
      contacts: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      cc: new FormControl(''),
      company: new FormControl('', Validators.required),
      companyN: new FormControl(''),
      studentNumber: new FormControl('', Validators.required),
      learningLicense: new FormControl(''),
      profile: new FormControl('', Validators.required),
      status: new FormControl(''),
      emailInstrutores: new FormControl(''),
    });
    this.userTicketsForm = this.formBuilder.group({
      quantity: new FormControl('', Validators.required),
      ticketType: new FormControl('', Validators.required),
      product: new FormControl('', Validators.required)
    });

    if (this.localData.profileId == 3) {
      this.localData.profileName = "Aluno";
    }

    this.fgeral.company.setValue(this.localData.companyName);
    this.fgeral.profile.setValue(this.localData.profileName);
  }

  selectAll() {
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].children != null) {
        this.products[i].selected = this.selectedAll;
        for (let j = 0; j < this.products[i].children.length; j++) {
          this.products[i].children[j].selected = this.selectedAll;
        }
      }
      else {
        this.products[i].selected = this.products[i].selected == this.selectedAll;
      }
    }
    this.selectedAll = this.selectedAll == true ? false : true;
  }
  masterToggle(node?: any) {
    if (node.children != null) {
      for (let i = 0; i < node.children.length; i++) {
        node.children[i].selected = node.children[i].selected == true ? false : true;
      }
    }
    else {
      node.selected = node.selected == true ? false : true;
    }

  }
  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;

    reader.readAsDataURL(files[0]);
    // tslint:disable-next-line: variable-name
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      // console.log(reader.result.toString().split(',')[1]);
      let url = reader.result.toString().split(',')[1];
      // let url = this.imgURL.replace(/data:image\/png;base64,/g, '') || this.imgURL.replace(/data:image\/jpeg;base64,/g, '') || this.imgURL.replace(/data:image\/jpg;base64,/g, '');
      this.mediaService.createImageToUser(this.localData.userId, url, mimeType,
        files[0].name, files[0].size).pipe(first()).subscribe(user => {
          this.toastr.success('Imagem do Utilizador Modificada com sucesso!');
        });
    };
  }
  clickedOption() {
  }

  showEmptyDashboard() {
    return this.showEmptyDashboardValue;
  }
  showEmptyDashboardImt() {
    return this.showEmptyDashboardValueImt;
  }
  showEmptyDashboardAC() {
    return this.showEmptyDashboardValueAC;
  }
  sendEmail() {
    this.alertMailService.sendAccountInfo(this.localData.userId).subscribe(a => { this.toastr.success("Dados enviados com sucesso"); });
  }
  changeStatus(i: number) {
    this.tickets[i].active = this.tickets[i].active == true ? false : true;
    this.ticketService.userTicketStatus(this.tickets[i].userTicketId, this.tickets[i].active).subscribe(c => {
      this.toastr.success('senha ativada/inativada com sucesso');
    });

  }

  changeDidaticStatus(i: number) {
    this.didacticTickets[i].active = this.didacticTickets[i].active == true ? false : true
    this.ticketService.userTicketStatus(this.didacticTickets[i].userTicketId, this.didacticTickets[i].active).subscribe(c => {
      this.toastr.success('senha ativada/inativada com sucesso');
    })
  }

  deleteCompanyLinkToComercial(i) {
    this.companyService.unlinkComercialUser(this.localData.userId, this.schools[i]['companyId']).subscribe(c => {
      this.toastr.success('Empresa removida do Comercial com sucesso');
      this.dialogRef.close({});
    });
  }

  openCity(evt, cityName, cityTab) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("active", "");
    }
    document.getElementById(cityName).style.display = "block";
    document.getElementById(cityName).classList.add("active");
    document.getElementById(cityTab).classList.add("active");
  }

  isAdmin() {
    return (this.currentUser.user.role == 'Administrador' ? true : false);
  }
}
