import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { QuestionOption } from '../models/QuestionOption';

@Injectable({
  providedIn: 'root'
})
export class QuestionOptionService {

  constructor(private http: HttpClient) { }

  getByQuestionId(id: number) {
    return this.http.get<QuestionOption[]>(`${environment.apiUrl}/QuestionOption/question/${id}`);
  }
}
