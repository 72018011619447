var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, PipeTransform, } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { BasePageComponent } from "../../../base-page";
import { Store } from "@ngrx/store";
import { HttpService } from "../../../../services/http/http.service";
import { environment } from "../../../../../environments/environment";
import { AuthService } from "../../../../services/auth.service";
import { DidaticMaterialService } from "../../../../services/didaticMaterial.service";
import { ActivatedRoute } from "@angular/router";
import { DidaticMaterialComponent } from "../../DialogBoxes/dialog-box-material/dialog-box-material.component";
import { ConfirmComponent } from "../../DialogBoxes/confirm/confirm.component";
var MaterialComponent = /** @class */ (function (_super) {
    __extends(MaterialComponent, _super);
    function MaterialComponent(dialog, store, authenticationService, route, httpSv, didacticMaterial, toastr) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.dialog = dialog;
        _this.authenticationService = authenticationService;
        _this.route = route;
        _this.didacticMaterial = didacticMaterial;
        _this.toastr = toastr;
        _this.bordered = true;
        _this.position = "bottom";
        _this.currentPage = 0;
        _this.inputFiles = [];
        _this.c = [];
        _this.b64toBlob = function (b64Data, contentType, sliceSize) {
            if (contentType === void 0) { contentType = ""; }
            if (sliceSize === void 0) { sliceSize = 512; }
            var byteCharacters = atob(b64Data);
            var byteArrays = [];
            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);
                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            var blob = new Blob(byteArrays, { type: contentType });
            return blob;
        };
        _this.getData(environment.apiUrl + "/User/", "tableData", "setLoaded");
        _this.authenticationService.currentUser.subscribe(function (x) { return (_this.currentUser = x); });
        _this.id = _this.route.snapshot.data["id"];
        if (_this.id == null || _this.id == undefined) {
            _this.id =
                _this.title == (undefined || null)
                    ? _this.currentUser.user.companyId
                    : _this.title;
        }
        if (_this.currentUser.user.profileId != 3 && _this.currentUser.user.profileId != 7) {
            _this.didacticMaterial.getByCompanyId(_this.id).subscribe(function (c) {
                _this.c = c;
            });
        }
        else {
            _this.didacticMaterial
                .getByUserId(_this.currentUser.user.id)
                .subscribe(function (c) {
                _this.c = c;
            });
        }
        return _this;
    }
    MaterialComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    MaterialComponent.prototype.ngOnDestroy = function () {
        // super.ngOnDestroy();
    };
    MaterialComponent.prototype.openFile = function (i) {
        var _this = this;
        this.didacticMaterial.getImageById(this.c[i]["mediaId"]).subscribe(function (a) {
            if (!_this.c[i]["mimeType"].includes("image") &&
                !_this.c[i]["mimeType"].includes("video")) {
                _this.fileBlob = _this.b64toBlob(a["didatic"], _this.c[i]["mimeType"]);
                _this.fileBlob = URL.createObjectURL(_this.fileBlob);
                window.open(encodeURI(_this.fileBlob + "#toolbar=0&navpanes=0"));
            }
            else {
                var row = {};
                row.mimeType = _this.c[i]["mimeType"];
                row.src = "data:" + _this.c[i]["mimeType"] + ";base64," + a["didatic"];
                var dialogRef = _this.dialog.open(DidaticMaterialComponent, {
                    width: "auto",
                    height: "auto",
                    maxWidth: "95vw",
                    maxHeight: "90vh",
                    data: row,
                });
            }
        });
    };
    MaterialComponent.prototype.guardar = function (i) {
        var _this = this;
        var el = document.getElementById("inputValue" + i);
        var text = el.children[0].children[0].children[0].attributes[3].value;
        this.c[i]["description"] = text;
        this.didacticMaterial
            .updateMaterial(this.c[i]["didaticMaterialId"], text)
            .subscribe(function (c) {
            _this.toastr.success("Material modificado com sucesso");
        }, function (error) {
            _this.toastr.error("Algo correu mal");
        });
    };
    MaterialComponent.prototype.remover = function (i) {
        var _this = this;
        var row = {};
        row.text = "Confirma a remoção do material didático?";
        var dialogRef = this.dialog.open(ConfirmComponent, {
            id: "confirm",
            data: row,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === undefined) {
            }
            else if (result.event == "sim") {
                _this.didacticMaterial
                    .removeDidatic(_this.c[i]["didaticMaterialId"])
                    .subscribe(function (c) {
                    _this.toastr.success("Material removido com sucesso");
                }, function (error) {
                    _this.toastr.error("Algo correu mal");
                });
            }
        });
    };
    MaterialComponent.prototype.currentPageDataChange = function ($event) {
        this.c = $event;
    };
    MaterialComponent.prototype.isAdmin = function () {
        return this.currentUser.user.profileId == 1;
    };
    return MaterialComponent;
}(BasePageComponent));
export { MaterialComponent };
var SplitPipe = /** @class */ (function () {
    function SplitPipe() {
    }
    SplitPipe.prototype.transform = function (val, params) {
        return val.split(params[0])[params[1]];
    };
    return SplitPipe;
}());
export { SplitPipe };
