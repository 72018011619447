import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { CompanyService } from '../../../services/company.service';
import { IPageData } from '../../../interfaces/page-data';
import { MediaService } from '../../../services/media.service';
import { Utils } from 'src/utils/utils';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @HostBinding('class.footer') true;
  @HostBinding('class.loaded') @Input() loaded: boolean;
  @HostBinding('class.boxed') @Input() boxed: boolean;
  version: string;
  @Input() pageData: IPageData;
  url: string = '';
  terms: string = '';
  constructor(private companyService: CompanyService, private mediaService: MediaService, public utils: Utils) {
    this.version = environment.version;
    this.companyService.getByName('AlvesCosta').subscribe(c => {
      this.url = c.url;
    });
    this.mediaService.getRGPD().subscribe(a => {
      let fileBlob: any = this.b64toBlob([a.url.toString()], 'application/pdf');
      this.terms = URL.createObjectURL(fileBlob);
      this.terms = encodeURI(this.terms + '#toolbar=0&navpanes=0')
    });
  }
  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  ngOnInit() { }
  open() {
    window.open(this.terms);
  }
}
