import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Theme } from '../models/Theme';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryThemeService {

  constructor(private http: HttpClient) { }
  getAll() {
    return this.http.get<Theme[]>(`${environment.apiUrl}/CategoryTheme/`);
  }
  getThemesProductById(id: number, font: string) {
    return this.http.get<Theme[]>(`${environment.apiUrl}/CategoryTheme/byTheme/${id}/${font}`);
  }

  getNewThemesProductById(id: number, font: string) {
    return this.http.get<any[]>(`${environment.apiUrl}/CategoryTheme/byTheme/${id}/${font}`);
  }
}
