var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../../../pages/base-page';
import { ConfirmComponent } from '../../DialogBoxes/confirm/confirm.component';
import { DialogBoxQuestionsComponent } from '../../DialogBoxes/dialog-box-questions/dialog-box-questions.component';
import { HttpService } from '../../../../services/http/http.service';
import { MatDialog } from '@angular/material';
import { QuestionService } from '../../../../services/question.service';
import { Store } from '@ngrx/store';
import { ThemeService } from '../../../../services/theme.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';
var QuestionsComponent = /** @class */ (function (_super) {
    __extends(QuestionsComponent, _super);
    function QuestionsComponent(questionService, store, themeService, httpSv, dialog, toastr) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.questionService = questionService;
        _this.themeService = themeService;
        _this.dialog = dialog;
        _this.toastr = toastr;
        _this.bordered = true;
        _this.position = 'bottom';
        _this.idValue = '';
        _this.descValue = '';
        _this.fontValue = '';
        _this.listOfDisplayData = [];
        _this.listOfAllData = [];
        _this.list = [];
        _this.sortName = null;
        _this.sortValue = null;
        _this.mapOfCheckedId = {};
        _this.isAllDisplayDataChecked = false;
        _this.isIndeterminate = false;
        _this.isOperating = false;
        _this.numberOfChecked = 0;
        _this.prev_index = '0';
        _this.opened = false;
        _this.closeDropdown = new EventEmitter();
        _this.getData(environment.apiUrl + "/Question/", 'tableData', 'setLoaded');
        _this.questionService.getAll().pipe(first()).subscribe(function (questions) {
            _this.listOfAllData = questions;
            _this.list = questions;
            // console.log(questions);
        }, function (err) {
            _this.toastr.error('Algo ocorreu mal!');
        });
        _this.themeService.getThemesAndCategories().pipe(first()).subscribe(function (themes) { _this.themes = themes; });
        return _this;
    }
    QuestionsComponent.prototype.currentPageDataChange = function ($event) {
        this.listOfDisplayData = $event;
        this.refreshStatus();
    };
    QuestionsComponent.prototype.onCloseDropdown = function () {
        this.closeDropdown.emit(true);
    };
    QuestionsComponent.prototype.sort = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.listOfAllData = this.list;
        this.listOfDisplayData = this.list;
        this.search('');
    };
    QuestionsComponent.prototype.reset = function () {
        this.idValue = '';
        this.descValue = '';
        this.fontValue = '';
        this.listOfAllData = this.list;
        this.search('');
    };
    /**
     * @param event
     * @param index
     */
    QuestionsComponent.prototype.select = function (event, index) {
        document.getElementById(this.prev_index).classList.remove('active');
        document.getElementById(index).classList.add('active');
        this.prev_index = index;
    };
    QuestionsComponent.prototype.search = function (type) {
        var _this = this;
        var filterFunc = function (item) {
            // let x = '' + item['oldQuestionId'].substring(4).toLowerCase();
            var x = '' + item['oldQuestionId'].toLowerCase();
            var y = item['description'].toLowerCase();
            var z = item['font'].toLowerCase();
            return (type == 'id' ? x.indexOf(_this.idValue.toString().toLowerCase()) !== -1 : type == 'desc' ? y.indexOf(_this.descValue.toLowerCase()) !== -1 : z.indexOf(_this.fontValue.toLowerCase()) !== -1);
        };
        var data = this.listOfAllData.filter(function (item) { return filterFunc(item); });
        this.listOfAllData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    QuestionsComponent.prototype.removeSelectedRows = function () {
        var _this = this;
        var row = {};
        row.text = 'Confirma a remoção da(s) pergunta(s) selecionada(s)?';
        var dialogRef = this.dialog.open(ConfirmComponent, {
            id: 'confirm',
            // width: '30vw',
            // height: '23vh',
            data: row
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === undefined) { }
            else if (result.event == 'sim') {
                var e = document.querySelectorAll('tbody tr');
                e.forEach(function (el) {
                    if (el.children) {
                        console.log(el.children[0].attributes['ng-reflect-nz-checked'].value);
                        if (el.children[0].attributes['ng-reflect-nz-checked'].value) {
                            console.log(el);
                            var oldId_1 = el.children[1].lastChild.nodeValue;
                            var index = _this.listOfAllData.findIndex(function (d) { return d['oldQuestionId'] === oldId_1; });
                            console.log(_this.listOfAllData[index]);
                            _this.questionService.deleteQuestion(_this.listOfAllData[index]['questionId']).subscribe(function (data) {
                                _this.questionService.getAll().pipe(first()).subscribe(function (questions) {
                                    _this.listOfAllData = questions;
                                    _this.list = questions;
                                });
                                _this.toastr.success('questão(ões) removida(s) com sucesso!');
                            }, function (err) {
                                _this.toastr.error('Algo ocorreu mal!');
                            });
                        }
                    }
                });
            }
        });
    };
    QuestionsComponent.prototype.openDialog = function (action, e, i) {
        var _this = this;
        if (e == null || !e.target.className.match('ant-table-column-sorters') && !e.target.className.match('ant-spin-container')) {
            /*var path = i == null ? '' : ;
            console.log(i);*/
            var row = {};
            if (i) {
                console.log(this.listOfAllData[this.listOfAllData.findIndex(function (d) { return d['questionId'] === i.questionId; })]);
                row = e == null ? {} : this.listOfAllData[this.listOfAllData.findIndex(function (d) { return d['questionId'] === i.questionId; })];
            }
            else {
                row = e == null ? {} : this.listOfAllData[this.listOfAllData.findIndex(function (d) { return d['questionId'] === 0; })];
            }
            row.action = action;
            var dialogRef = this.dialog.open(DialogBoxQuestionsComponent, {
                width: '99%',
                maxWidth: '850px',
                height: 'auto',
                maxHeight: '100vh',
                data: row,
                disableClose: true,
                closeOnNavigation: true
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result === undefined) { }
                else if (result.event == 'Adicionar') {
                    _this.addRowData(result.data);
                }
                else if (result.event == 'Modificar') {
                    _this.updateRowData(result.data);
                }
            });
        }
    };
    QuestionsComponent.prototype.addRowData = function (rowObj) {
        var _this = this;
        this.questionService.createQuestion(rowObj.description, rowObj.help, rowObj.questionOption0, rowObj.questionOption1, rowObj.questionOption2, rowObj.questionOption3, rowObj.isAnswer, rowObj.active, rowObj.font, rowObj.themes).subscribe(function (data) {
            _this.questionService.getAll().pipe(first()).subscribe(function (questions) {
                _this.listOfAllData = questions;
                _this.list = questions;
            });
            _this.toastr.success('questão criada com sucesso!');
        }, function (err) {
            _this.toastr.error('Algo ocorreu mal!');
        });
    };
    QuestionsComponent.prototype.updateRowData = function (rowObj) {
        var _this = this;
        this.questionService.updateQuestion(rowObj.questionId, rowObj.description, rowObj.help, rowObj.questionOption0, rowObj.questionOption1, rowObj.questionOption2, rowObj.questionOption3, rowObj.isAnswer, rowObj.active, rowObj.font, rowObj.themes).subscribe(function (data) {
            _this.questionService.getAll().pipe(first()).subscribe(function (questions) {
                _this.listOfAllData = questions;
                _this.list = questions;
            });
            _this.toastr.success('questão modificada com sucesso!');
        }, function (err) {
            _this.toastr.error('Algo ocorreu mal!');
        });
    };
    QuestionsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    QuestionsComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    QuestionsComponent.prototype.openFilter = function () {
        var el = document.getElementById('filtro');
        if (this.opened == false) {
            el.classList.add('show');
            el.classList.remove('hide');
            this.opened = true;
        }
        else {
            el.classList.add('hide');
            el.classList.remove('show');
            this.opened = false;
        }
    };
    QuestionsComponent.prototype.filter = function () {
        this.listOfAllData = this.list;
        if (this.idValue != '') {
            this.search('id');
        }
        if (this.descValue != '') {
            this.search('desc');
        }
        if (this.fontValue != '') {
            this.search('font');
        }
    };
    QuestionsComponent.prototype.refreshStatus = function () {
        var _this = this;
        this.isAllDisplayDataChecked = this.listOfAllData.every(function (item) { return _this.mapOfCheckedId[item['questionId']]; });
        this.isIndeterminate = this.listOfAllData.some(function (item) { return _this.mapOfCheckedId[item['questionId']]; }) && !this.isAllDisplayDataChecked;
        this.numberOfChecked = this.listOfAllData.filter(function (item) { return _this.mapOfCheckedId[item['questionId']]; }).length;
    };
    QuestionsComponent.prototype.checkAll = function (value) {
        var _this = this;
        this.listOfAllData.forEach(function (item) { return (_this.mapOfCheckedId[item['questionId']] = value); });
        this.refreshStatus();
    };
    QuestionsComponent.prototype.operateData = function () {
        var _this = this;
        this.isOperating = true;
        setTimeout(function () {
            _this.listOfAllData.forEach(function (item) { return (_this.mapOfCheckedId[item['questionId']] = false); });
            _this.refreshStatus();
            _this.isOperating = false;
        }, 1000);
    };
    QuestionsComponent.prototype.export = function (i, j) {
        var _this = this;
        var q = [];
        this.listOfAllData.forEach(function (element) {
            var z = element['questionId'];
            q.push(z);
        });
        this.questionService.exportQuestionsToTheme(this.themes[i]['id'], this.themes[i]['categories'][j]['categoryId'], q).subscribe(function (a) {
            _this.toastr.success('Questão(ões) exportadas com sucesso');
        });
        this.onCloseDropdown();
    };
    QuestionsComponent.prototype.isMobile = function () {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };
    ;
    return QuestionsComponent;
}(BasePageComponent));
export { QuestionsComponent };
