import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProductService = /** @class */ (function () {
    function ProductService(http) {
        this.http = http;
    }
    ProductService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl + "/Product/");
    };
    ProductService.prototype.getAllWithChildren = function () {
        return this.http.get(environment.apiUrl + "/Product/category/");
    };
    ProductService.prototype.getAllWithChildrenByCompanyId = function (id) {
        return this.http.get(environment.apiUrl + "/Product/category/company/" + id);
    };
    ProductService.prototype.getAllDidacticmWithChildrenByCompanyId = function (id) {
        return this.http.get(environment.apiUrl + "/Product/didactic/company/" + id);
    };
    ProductService.prototype.getAllProductCategories = function () {
        return this.http.get(environment.apiUrl + "/Product/category/company/all/");
    };
    ProductService.prototype.getProductByCategoryId = function (id) {
        return this.http.get(environment.apiUrl + "/Product/byCategoryId/" + id);
    };
    ProductService.prototype.createProduct = function (name, tag) {
        return this.http.post(environment.apiUrl + "/Product/", {
            name: name, tag: tag
        });
    };
    ProductService.prototype.updateProduct = function (id, name, tag) {
        return this.http.put(environment.apiUrl + "/product/" + id, {
            name: name, tag: tag
        });
    };
    ProductService.prototype.deleteProduct = function (id) {
        return this.http.delete(environment.apiUrl + "/Product/" + id);
    };
    ProductService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductService_Factory() { return new ProductService(i0.ɵɵinject(i1.HttpClient)); }, token: ProductService, providedIn: "root" });
    return ProductService;
}());
export { ProductService };
