import { Component, OnInit, OnDestroy, Pipe, PipeTransform, ViewChild, ElementRef } from '@angular/core';
import { BasePageComponent } from '../../../base-page';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { environment } from '../../../../../environments/environment';
import { Company } from '../../../../models/Company';
import { User } from '../../../../models/user';
import { AuthService } from '../../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { SheetSalesService } from '../../../../services/sheetsSales.service';
import * as xlsx from 'xlsx';
import { FormControl } from '@angular/forms';
// import * as _moment from 'moment';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

// tslint:disable-next-line:no-duplicate-imports
// import { default as _rollupMoment } from 'moment';

// const moment = _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
@Component({
    selector: 'app-schoolSheets',
    styleUrls: ['./relatorioEscola.component.css'],
    templateUrl: './relatorioEscola.component.html',
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class SchoolSheetsComponent extends BasePageComponent implements OnInit, OnDestroy {
    @ViewChild('nestedTable', { static: false }) table: ElementRef;
    startDt = new FormControl();
    endDt = new FormControl();
    bordered = true;
    position = 'bottom';
    currentUser: User;
    currentPage = 0;
    sortName: string | null = null;
    sortValue: string | null = null;
    listOfDisplayData: any[] = [];
    listOfAllData: any[] = [];
    list: any[] = [];
    schoolValue = '';
    typeValue = '';
    plataformValue = '';
    constructor(store: Store<IAppState>, private authenticationService: AuthService, private route: ActivatedRoute,
        httpSv: HttpService, private sheetsService: SheetSalesService) {
        super(store, httpSv);
        this.getData(`${environment.apiUrl}/User/`, 'tableData', 'setLoaded');
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);


        this.pageData = {
            title: 'Relatório das Escolas',
            loaded: true
        };

        this.sheetsService.GetSchoolSalesSheets(this.currentUser.user.id, "start", "end").subscribe(a => {
            this.listOfDisplayData = a;
            this.listOfAllData = a;
            this.list = a;
        });

    }
    sort(sort: { key: string; value: string }): void {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.listOfAllData = this.list;
        this.listOfDisplayData = this.list;
        this.search('');
    }
    reset(): void {
        this.schoolValue = '';
        this.typeValue = '';
        this.plataformValue = '';

        this.listOfAllData = this.list;
        this.search('');
    }

    search(type: string): void {
        const filterFunc = (item: any) => {
            let x = '' + item['companyName'].toLowerCase();
            let y = item['companyType'].toLowerCase();
            let z = item['plataformName'].toLowerCase();
            return (
                type == 'school' ? x.indexOf(this.schoolValue.toLowerCase()) !== -1 : type == 'type' ? y.indexOf(this.typeValue.toLowerCase()) !== -1 : z.indexOf(this.plataformValue.toLowerCase()) !== -1
            );
        };
        const data = this.listOfAllData.filter((item: any) => filterFunc(item));
        this.listOfAllData = data.sort((a, b) =>

            this.sortValue === 'ascend'
                ? a[this.sortName!] > b[this.sortName!]
                    ? 1
                    : -1
                : b[this.sortName!] > a[this.sortName!]
                    ? 1
                    : -1
        );
    }
    custom(i) {
        this.currentPage = i - 1;
    }
    opened = false;
    openFilter() {
        const el = document.getElementById('filtro');
        if (this.opened == false) {
            el.classList.add('show');
            el.classList.remove('hide');
            this.opened = true;
        }
        else {
            el.classList.add('hide');
            el.classList.remove('show');
            this.opened = false;
        }
    }
    filter() {
        this.listOfAllData = this.list;
        if (this.schoolValue != '') { this.search('school'); }
        if (this.typeValue != '') { this.search('type'); }
        if (this.plataformValue != '') { this.search('plataform'); }
    }
    ngOnInit() {
        super.ngOnInit();
    }
    ngOnDestroy() {
        // super.ngOnDestroy();
    }

    currentPageDataChange($event: Company[]): void {
        this.listOfDisplayData = $event;
    }
    isAdmin() {
        return this.currentUser.user.profileId == 1 ? false : true;
    }
    export() {
        var tableExport: any[] = [];
        this.table['nzData'].forEach(element => {
            var z = { 'Escola': element['companyName'], 'Tipo': element['companyType'], 'Plataforma': element['plataformName'] };
            tableExport.push(z);
            element['children'].forEach(item => {
                var x = {
                    'Categoria': item['categoryName'], 'Data de Atribuição': item['date'], 'Senhas Atribuidas': item['quantity'],
                    'Senhas Restantes': item['left'], 'Duração': item['durationName']
                };
                tableExport.push(x);
            });
        });
        const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(tableExport);
        const wb: xlsx.WorkBook = xlsx.utils.book_new();

        var wscols = [
            { wch: 30 },
            { wch: 10 },
            { wch: 20 },
            { wch: 30 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 20 }
        ];
        ws['!cols'] = wscols;
        xlsx.utils.book_append_sheet(wb, ws, 'relatorio_Consumos_Escolas');
        xlsx.writeFile(wb, 'relatorio_Consumos_Escolas.xlsx');
    }
    date() {
        let start = this.startDt.touched == false ? "start" :
            "" + this.startDt.value['_i']['year'] + "-" + (this.startDt.value['_i']['month'] + 1 < 10 ? "0" + (this.startDt.value['_i']['month'] + 1) : this.startDt.value['_i']['month'] + 1)
            + "-" + (this.startDt.value['_i']['date'] < 10 ? "0" + this.startDt.value['_i']['date'] : this.startDt.value['_i']['date']);
        let end = this.endDt.touched == false ? "end" :
            "" + this.endDt.value['_i']['year'] + "-" + (this.endDt.value['_i']['month'] + 1 < 10 ? "0" + (this.endDt.value['_i']['month'] + 1) : this.endDt.value['_i']['month'] + 1) + "-"
            + (this.endDt.value['_i']['date'] < 10 ? "0" + this.endDt.value['_i']['date'] : this.endDt.value['_i']['date']);

        this.sheetsService.GetSchoolSalesSheets(this.currentUser.user.id, start, end).subscribe(a => {
            this.listOfDisplayData = a;
            this.listOfAllData = a;
            this.list = a;
        });
    }
}
