var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, ElementRef } from '@angular/core';
import { BasePageComponent } from '../../../base-page';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../../services/http/http.service';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { SheetSalesService } from '../../../../services/sheetsSales.service';
import * as xlsx from 'xlsx';
import { FormControl } from '@angular/forms';
// tslint:disable-next-line:no-duplicate-imports
// import { default as _rollupMoment } from 'moment';
// const moment = _moment;
export var MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
var SchoolSheetsComponent = /** @class */ (function (_super) {
    __extends(SchoolSheetsComponent, _super);
    function SchoolSheetsComponent(store, authenticationService, route, httpSv, sheetsService) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.authenticationService = authenticationService;
        _this.route = route;
        _this.sheetsService = sheetsService;
        _this.startDt = new FormControl();
        _this.endDt = new FormControl();
        _this.bordered = true;
        _this.position = 'bottom';
        _this.currentPage = 0;
        _this.sortName = null;
        _this.sortValue = null;
        _this.listOfDisplayData = [];
        _this.listOfAllData = [];
        _this.list = [];
        _this.schoolValue = '';
        _this.typeValue = '';
        _this.plataformValue = '';
        _this.opened = false;
        _this.getData(environment.apiUrl + "/User/", 'tableData', 'setLoaded');
        _this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        _this.pageData = {
            title: 'Relatório das Escolas',
            loaded: true
        };
        _this.sheetsService.GetSchoolSalesSheets(_this.currentUser.user.id, "start", "end").subscribe(function (a) {
            _this.listOfDisplayData = a;
            _this.listOfAllData = a;
            _this.list = a;
        });
        return _this;
    }
    SchoolSheetsComponent.prototype.sort = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.listOfAllData = this.list;
        this.listOfDisplayData = this.list;
        this.search('');
    };
    SchoolSheetsComponent.prototype.reset = function () {
        this.schoolValue = '';
        this.typeValue = '';
        this.plataformValue = '';
        this.listOfAllData = this.list;
        this.search('');
    };
    SchoolSheetsComponent.prototype.search = function (type) {
        var _this = this;
        var filterFunc = function (item) {
            var x = '' + item['companyName'].toLowerCase();
            var y = item['companyType'].toLowerCase();
            var z = item['plataformName'].toLowerCase();
            return (type == 'school' ? x.indexOf(_this.schoolValue.toLowerCase()) !== -1 : type == 'type' ? y.indexOf(_this.typeValue.toLowerCase()) !== -1 : z.indexOf(_this.plataformValue.toLowerCase()) !== -1);
        };
        var data = this.listOfAllData.filter(function (item) { return filterFunc(item); });
        this.listOfAllData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    SchoolSheetsComponent.prototype.custom = function (i) {
        this.currentPage = i - 1;
    };
    SchoolSheetsComponent.prototype.openFilter = function () {
        var el = document.getElementById('filtro');
        if (this.opened == false) {
            el.classList.add('show');
            el.classList.remove('hide');
            this.opened = true;
        }
        else {
            el.classList.add('hide');
            el.classList.remove('show');
            this.opened = false;
        }
    };
    SchoolSheetsComponent.prototype.filter = function () {
        this.listOfAllData = this.list;
        if (this.schoolValue != '') {
            this.search('school');
        }
        if (this.typeValue != '') {
            this.search('type');
        }
        if (this.plataformValue != '') {
            this.search('plataform');
        }
    };
    SchoolSheetsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    SchoolSheetsComponent.prototype.ngOnDestroy = function () {
        // super.ngOnDestroy();
    };
    SchoolSheetsComponent.prototype.currentPageDataChange = function ($event) {
        this.listOfDisplayData = $event;
    };
    SchoolSheetsComponent.prototype.isAdmin = function () {
        return this.currentUser.user.profileId == 1 ? false : true;
    };
    SchoolSheetsComponent.prototype.export = function () {
        var tableExport = [];
        this.table['nzData'].forEach(function (element) {
            var z = { 'Escola': element['companyName'], 'Tipo': element['companyType'], 'Plataforma': element['plataformName'] };
            tableExport.push(z);
            element['children'].forEach(function (item) {
                var x = {
                    'Categoria': item['categoryName'], 'Data de Atribuição': item['date'], 'Senhas Atribuidas': item['quantity'],
                    'Senhas Restantes': item['left'], 'Duração': item['durationName']
                };
                tableExport.push(x);
            });
        });
        var ws = xlsx.utils.json_to_sheet(tableExport);
        var wb = xlsx.utils.book_new();
        var wscols = [
            { wch: 30 },
            { wch: 10 },
            { wch: 20 },
            { wch: 30 },
            { wch: 25 },
            { wch: 15 },
            { wch: 15 },
            { wch: 20 }
        ];
        ws['!cols'] = wscols;
        xlsx.utils.book_append_sheet(wb, ws, 'relatorio_Consumos_Escolas');
        xlsx.writeFile(wb, 'relatorio_Consumos_Escolas.xlsx');
    };
    SchoolSheetsComponent.prototype.date = function () {
        var _this = this;
        var start = this.startDt.touched == false ? "start" :
            "" + this.startDt.value['_i']['year'] + "-" + (this.startDt.value['_i']['month'] + 1 < 10 ? "0" + (this.startDt.value['_i']['month'] + 1) : this.startDt.value['_i']['month'] + 1)
                + "-" + (this.startDt.value['_i']['date'] < 10 ? "0" + this.startDt.value['_i']['date'] : this.startDt.value['_i']['date']);
        var end = this.endDt.touched == false ? "end" :
            "" + this.endDt.value['_i']['year'] + "-" + (this.endDt.value['_i']['month'] + 1 < 10 ? "0" + (this.endDt.value['_i']['month'] + 1) : this.endDt.value['_i']['month'] + 1) + "-"
                + (this.endDt.value['_i']['date'] < 10 ? "0" + this.endDt.value['_i']['date'] : this.endDt.value['_i']['date']);
        this.sheetsService.GetSchoolSalesSheets(this.currentUser.user.id, start, end).subscribe(function (a) {
            _this.listOfDisplayData = a;
            _this.listOfAllData = a;
            _this.list = a;
        });
    };
    return SchoolSheetsComponent;
}(BasePageComponent));
export { SchoolSheetsComponent };
