<div class="row" style="padding-left:2%;padding-right:2%; padding-bottom:2%">
  <div class="col-5 col-sm-8 col-md-6 col-lg-8 col-xl-8 text-left mt-2">
    <button tc-button class="btn-default" (click)="openFilter()" [beforeIcon]="'icofont-search-document'" [view]="'default'">Filtro</button>
  </div>

  <div class="col-7 col-sm-4 col-md-6 col-lg-4 col-xl-4 text-right mt-2">
    <div class="row">
      <div class="col-6">
        <button *ngIf="currentUser.user.profileId != 8"  tc-button class="btn-success" style="margin-right: 1%;" [view]="'success'" [beforeIcon]="'icofont-ui-add'" (click)="openDialog('Adicionar',null, 0)">Adicionar</button>
      </div>
      <div class="col-6">
        <button [disabled]="!this.haveSelected()" tc-button class="btn-default" [beforeIcon]="'icofont-file-excel'" (click)="export()"  tooltip="Exportar" style="margin-right: 2%;" [view]="'default'">Exportar</button>
      </div>
    </div>
  </div>

  <div *ngIf="false" class="col-4 col-sm-2 col-md-3 col-lg-2 col-xl-2 text-right mt-2">
    <button *ngIf="currentUser.user.profileId != 6" tc-button class="btn-error" [view]="'error'" [beforeIcon]="'icofont-ui-delete'" (click)="removeSelectedRows()">Remover</button>
  </div>
  
</div>

  <tc-card style="background-color: #FAFAFA;" id="filtro" class="our-border hide">
    <div class="row">
      <tc-form-group class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
        <tc-form-label>Nome</tc-form-label>
        <tc-input type="text" [(ngModel)]="nameValue"></tc-input>
      </tc-form-group>
  
      <tc-form-group class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> 
        <tc-form-label>Utilizador</tc-form-label>
        <tc-input type="text" [(ngModel)]="userNameValue"></tc-input>
      </tc-form-group>
  
      <tc-form-group class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <tc-form-label>Empresa</tc-form-label>
        <tc-input type="text" [(ngModel)]="companyValue"></tc-input>
      </tc-form-group>
  
      <tc-form-group class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <tc-form-label>Email</tc-form-label>
        <tc-input type="text" [(ngModel)]="emailValue"></tc-input>
      </tc-form-group>
  
      <tc-form-group class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="currentUser.user.profileId != 8">
        <tc-form-label>Perfil</tc-form-label>
        <div class="col-12">
          <mat-select [(ngModel)]="profileValue" class="select-box">
            <mat-option *ngIf="profileValue != '' " value="  "></mat-option>
            <mat-option value="Administrador">Administrador</mat-option>
            <mat-option value="Aluno">Aluno</mat-option>
            <mat-option value="Admin Comercial">Admin Comercial</mat-option>
            <mat-option value="Comercial">Comercial</mat-option>
            <mat-option value="Escola">Escola</mat-option>
            <mat-option value="Instrutor">Instrutor</mat-option>
            <mat-option value="IMT">IMT</mat-option>
        </mat-select>
      </div>
    
      </tc-form-group>
      

      <tc-form-group class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="currentUser.user.profileId == 8">
        <tc-form-label>Licença de Aprendizagem</tc-form-label>
        <tc-input type="text" [(ngModel)]="licenseValue"></tc-input>
      </tc-form-group>

      <!-- <tc-form-group class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="currentUser.user.profileId == 8">
        <tc-form-label>Email:</tc-form-label>
        <tc-input type="text" [(ngModel)]="emailValue" class="form-control"></tc-input>
      </tc-form-group> -->

    </div>
    
    <div class="d-flex justify-content-end">
      <button tc-button class="btn-default mr-1" [beforeIcon]="'icofont-ui-reply'" (click)="reset()" [view]="'default'">Reset</button>
      <button tc-button class="btn-success" [view]="'default'" [beforeIcon]="'icofont-search-1'" (click)="filter()">Filtrar</button>
    </div>

  </tc-card>


<tc-card [padding]="'20px 5px'" [title]="currentUser.user.profileId == 8 ? 'Alunos' : 'Utilizadores'"> 
  <nz-table #rowSelectionTable [nzData]="listOfAllData" [nzBordered]="bordered" nzShowSizeChanger
    (nzPageIndexChange)="custom($event)" [nzPaginationPosition]="position" class="text-center"
    [(nzPageSize)]="pageSize"  (nzCurrentPageDataChange)="currentPageDataChange($event)" style="overflow: auto;">
    <thead (nzSortChange)="sort($event)" nzSingleSort>
      <tr class="noClick">
        <th nzShowCheckbox [(nzChecked)]="isAllDisplayDataChecked" [nzIndeterminate]="isIndeterminate"
          (nzCheckedChange)="checkAll($event)">
          <hr>
          <a class="tc-icon-wrap icofont-ui-message" (click)="sendT()" *ngIf="currentUser.user.profileId != 8"
            [style.display]="numberOfChecked === 0 ? 'none' : '' "
            style="margin-left: 5px; font-size: 14px;">
          </a>
          <a class="tc-icon-wrap icofont-trash" *ngIf="currentUser.user.profileId != 6 && currentUser.user.profileId != 8"
            (click)="removeSelectedRows()"
            [style.display]="numberOfChecked === 0 ? 'none' : '' "
            style="margin-left: 5px; font-size: 14px;">
          </a>
        </th>
        <th nzShowSort [(nzSort)]="mapOfSort.name" nzSortKey="name">Nome</th>
        <th nzShowSort [(nzSort)]="mapOfSort.userName" nzSortKey="userName">Utilizador</th>
        <th nzShowSort [(nzSort)]="mapOfSort.email" nzSortKey="email">Email</th>
        <th nzShowSort [(nzSort)]="mapOfSort.companyName" nzSortKey="companyName">Empresa</th>
        <th nzShowSort [(nzSort)]="mapOfSort.profileName" nzSortKey="profileName">Perfil</th>
        <th>Ativo</th>
        <th *ngIf="currentUser.user.profileId != 8"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of rowSelectionTable.data; let i = index;" (click)="select($event,i)" [attr.id]="i">
        <td nzShowCheckbox [(nzChecked)]="mapOfCheckedId[data.userId]" (nzCheckedChange)="refreshStatus()">
        <td (click)="openDialog('Modificar', $event,i)">{{ data.name }}</td>
        <td (click)="openDialog('Modificar', $event,i)">{{ data.userName }}</td>
        <td (click)="openDialog('Modificar', $event,i)">{{ data.email}}</td>
        <td (click)="openDialog('Modificar', $event,i)">{{ data.companyName }}</td>
        <td (click)="openDialog('Modificar', $event,i)">{{ data.profileName }}</td>
        <td nzShowCheckbox [(nzChecked)]="data.active" [nzDisabled]="true"> </td>
        <td style="text-align:center" *ngIf="currentUser.user.profileId != 8"><a class="tc-icon-wrap icofont-ui-message" (click)="sendEmail(i)"></a></td>
      </tr>
    </tbody>
  </nz-table>
</tc-card>
