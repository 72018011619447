import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';

import { BasePageComponent } from '../../../../pages/base-page';
import { ConfirmComponent } from '../../DialogBoxes/confirm/confirm.component';
import { DialogBoxQuestionsComponent } from '../../DialogBoxes/dialog-box-questions/dialog-box-questions.component';
import { HttpService } from '../../../../services/http/http.service';
import { IAppState } from '../../../../interfaces/app-state';
import { MatDialog } from '@angular/material';
import { Question } from '../../../../models/question';
import { QuestionService } from '../../../../services/question.service';
import { Store } from '@ngrx/store';
import { ThemeService } from '../../../../services/theme.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent extends BasePageComponent implements OnInit, OnDestroy {
  bordered = true;
  position = 'bottom';
  idValue = '';
  descValue = '';
  fontValue = '';
  listOfDisplayData: Question[] = [];
  listOfAllData: Question[] = [];
  list: Question[] = [];
  sortName: string | null = null;
  sortValue: string | null = null;
  mapOfCheckedId: { [key: string]: boolean } = {};
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  isOperating = false;
  numberOfChecked = 0;
  themes: any[];
  closeDropdown: EventEmitter<boolean>;
  public prev_index: string = '0';
  
  constructor(private questionService: QuestionService, store: Store<IAppState>, private themeService: ThemeService,
    httpSv: HttpService,
    public dialog: MatDialog,
    private toastr: ToastrService) {
    super(store, httpSv);
    this.closeDropdown = new EventEmitter<boolean>();
    this.getData(`${environment.apiUrl}/Question/`, 'tableData', 'setLoaded');
   
    this.questionService.getAll().pipe(first()).subscribe(questions => {
      this.listOfAllData = questions;
      this.list = questions;
      // console.log(questions);
    },
      err => {
        this.toastr.error('Algo ocorreu mal!');
      }
    );

    this.themeService.getThemesAndCategories().pipe(first()).subscribe(themes => { this.themes = themes; });
  }
  currentPageDataChange($event: Question[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }
  onCloseDropdown() {
    this.closeDropdown.emit(true);
  }
  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.listOfAllData = this.list;
    this.listOfDisplayData = this.list;
    this.search('');
  }
  reset(): void {
    this.idValue = '';
    this.descValue = '';
    this.fontValue = '';
    this.listOfAllData = this.list;
    this.search('');
  }

  /**
   * @param event
   * @param index
   */
  public select(event, index) {
    document.getElementById(this.prev_index).classList.remove('active');
    document.getElementById(index).classList.add('active');
    this.prev_index = index
  }

  search(type: string): void {
    const filterFunc = (item: Question) => {
      // let x = '' + item['oldQuestionId'].substring(4).toLowerCase();
      let x = '' + item['oldQuestionId'].toLowerCase();
      let y = item['description'].toLowerCase();
      let z = item['font'].toLowerCase();
      return (
        type == 'id' ? x.indexOf(this.idValue.toString().toLowerCase()) !== -1 : type == 'desc' ? y.indexOf(this.descValue.toLowerCase()) !== -1 : z.indexOf(this.fontValue.toLowerCase()) !== -1
        );
      };
    
    const data = this.listOfAllData.filter((item: Question) => filterFunc(item));
    
    this.listOfAllData = data.sort((a, b) =>

      this.sortValue === 'ascend'
        ? a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );

  }
  
  removeSelectedRows() {
    let row: any = {};
    row.text = 'Confirma a remoção da(s) pergunta(s) selecionada(s)?'
    let dialogRef = this.dialog.open(ConfirmComponent, {
      id: 'confirm',
      // width: '30vw',
      // height: '23vh',
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) { }
      else if (result.event == 'sim') {
        const e = document.querySelectorAll('tbody tr');
        e.forEach(el => {
          if (el.children) {
            console.log(el.children[0].attributes['ng-reflect-nz-checked'].value);
            if (el.children[0].attributes['ng-reflect-nz-checked'].value){
              console.log(el);
              const oldId = el.children[1].lastChild.nodeValue;

              const index: number = this.listOfAllData.findIndex(d => d['oldQuestionId'] === oldId);

              console.log(this.listOfAllData[index]);

            this.questionService.deleteQuestion(this.listOfAllData[index]['questionId']).subscribe((data) => {
              this.questionService.getAll().pipe(first()).subscribe(questions => {
                this.listOfAllData = questions;
                this.list = questions;
              });
              this.toastr.success('questão(ões) removida(s) com sucesso!');
            },
              err => {
                this.toastr.error('Algo ocorreu mal!');
              });
          }
          }
        });
      }
    });
  }

  openDialog(action, e, i) {
   
    if (e == null || !e.target.className.match('ant-table-column-sorters') && !e.target.className.match('ant-spin-container')) {
      /*var path = i == null ? '' : ;
      console.log(i);*/
      
      let row: any = {};

      if(i)
      {
        console.log(this.listOfAllData[this.listOfAllData.findIndex(d => d['questionId']  === i.questionId)]);

        row = e == null ? {} : this.listOfAllData[this.listOfAllData.findIndex(d => d['questionId']  === i.questionId)];
      }
      else
      {
        row = e == null ? {} : this.listOfAllData[this.listOfAllData.findIndex(d => d['questionId']  === 0)];
      }

      row.action = action;

      const dialogRef = this.dialog.open(DialogBoxQuestionsComponent, {
        width: '99%',
        maxWidth: '850px',
        height: 'auto',
        maxHeight: '100vh',
        data: row,
        disableClose: true,
        closeOnNavigation: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === undefined) { }
        else if (result.event == 'Adicionar') {
          this.addRowData(result.data);
        } else if (result.event == 'Modificar') {
          this.updateRowData(result.data);
        }
      });
    }
  }

  addRowData(rowObj) {
    this.questionService.createQuestion(rowObj.description, rowObj.help, rowObj.questionOption0, rowObj.questionOption1,
      rowObj.questionOption2, rowObj.questionOption3, rowObj.isAnswer, rowObj.active, rowObj.font, rowObj.themes
    ).subscribe((data) => {
      this.questionService.getAll().pipe(first()).subscribe(questions => {
        this.listOfAllData = questions;
        this.list = questions;
      });
      this.toastr.success('questão criada com sucesso!');
    },
      err => {
        this.toastr.error('Algo ocorreu mal!');
      });
  }
  updateRowData(rowObj) {
    this.questionService.updateQuestion(rowObj.questionId, rowObj.description, rowObj.help, rowObj.questionOption0, rowObj.questionOption1,
      rowObj.questionOption2, rowObj.questionOption3, rowObj.isAnswer, rowObj.active, rowObj.font, rowObj.themes).subscribe((data) => {
        this.questionService.getAll().pipe(first()).subscribe(questions => {
          this.listOfAllData = questions;
          this.list = questions;
        });
        this.toastr.success('questão modificada com sucesso!');
      },
        err => {
          this.toastr.error('Algo ocorreu mal!');
        });
  }
  ngOnInit() {
    super.ngOnInit();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  opened = false;
  openFilter() {
    const el = document.getElementById('filtro');
    if (this.opened == false) {
      el.classList.add('show');
      el.classList.remove('hide');
      this.opened = true;
    }
    else {
      el.classList.add('hide');
      el.classList.remove('show');
      this.opened = false;
    }
  }
  filter() {
    this.listOfAllData = this.list;
    if (this.idValue != '') { this.search('id'); }
    if (this.descValue != '') { this.search('desc'); }
    if (this.fontValue != '') { this.search('font'); }
  }
  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfAllData.every(item => this.mapOfCheckedId[item['questionId']]);
    this.isIndeterminate = this.listOfAllData.some(item => this.mapOfCheckedId[item['questionId']]) && !this.isAllDisplayDataChecked;
    this.numberOfChecked = this.listOfAllData.filter(item => this.mapOfCheckedId[item['questionId']]).length;
  }

  checkAll(value: boolean): void {
    this.listOfAllData.forEach(item => (this.mapOfCheckedId[item['questionId']] = value));
    this.refreshStatus();
  }

  operateData(): void {
    this.isOperating = true;
    setTimeout(() => {
      this.listOfAllData.forEach(item => (this.mapOfCheckedId[item['questionId']] = false));
      this.refreshStatus();
      this.isOperating = false;
    }, 1000);
  }

  export(i, j) {
    var q: number[] = [];
    this.listOfAllData.forEach(element => {
      var z = element['questionId'];
      q.push(z);
    });
    this.questionService.exportQuestionsToTheme(this.themes[i]['id'], this.themes[i]['categories'][j]['categoryId'], q).subscribe(a => {
      this.toastr.success('Questão(ões) exportadas com sucesso');
    });
    this.onCloseDropdown();
  }

  isMobile(): boolean {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  };

}