<div class="container-fluid" style="margin: 2% 2% 5% 2%;">
    <div style="align-items: center;">
        <h5>{{text}}</h5>
    </div>
    <br>
    <div *ngIf="localData.text != 'Acabou o tempo para realizar o teste'" style="display: flex; justify-content: space-evenly; align-items: center;">
        <button style="margin-right: 2%;" tc-button  (click)="confirm()" [beforeIcon]="'icofont-sign-out'"  [view]="'success'">Sim</button>
        <button tc-button  (click)="closeDialog()" [beforeIcon]="'icofont-close-circled'" [view]="'warning'" >Cancelar</button>
    </div>
    <div *ngIf="localData.text == 'Acabou o tempo para realizar o teste'"  style="display: flex; justify-content: space-evenly; align-items: center;">
        <button style="margin-right: 2%;" tc-button  (click)="confirm()" [beforeIcon]="'icofont-sign-out'"  [view]="'success'">Continuar</button>
    </div>
</div>