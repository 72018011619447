<div>
    <div style="float: left;">
        <h4 small>{{action}} {{localData.name}}</h4>
    </div>
    <div style="float: right;">
        <!-- <a mat-dialog-close style="float: right; cursor:pointer" class="tc-icon-wrap icofont-close-circled"></a> -->
    </div>
</div>
<div style="clear: both; width: 100%;">
    <mat-dialog-content [formGroup]="productsForm">
        <tc-form-group>
            <tc-form-label>Nome</tc-form-label>
            <tc-input [placeholder]="'Nome'" [type]="'text'" formControlName="name" [(ngModel)]="localData.name">
            </tc-input>
        </tc-form-group>
        <tc-form-group>
            <tc-form-label>Tag</tc-form-label>
            <tc-input [placeholder]="'Tag'" [type]="'text'" formControlName="tag" [(ngModel)]="localData.tag">
            </tc-input>
        </tc-form-group>
    </mat-dialog-content>
</div>
<mat-dialog-actions layout="row">
    <button tc-button [view]="'success'" [beforeIcon]="'icofont-diskette'" (click)="onSubmit()"
        style="margin: 2px;">Submeter</button>
    <button tc-button [view]="'warning'" (click)="closeDialog()" style="margin: 2px;"
        [beforeIcon]="'icofont-close-circled'"></button>
</mat-dialog-actions>