import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { AuthService } from '../../../../services/auth.service';
import { BasePageComponent } from '../../../../pages/base-page';
import { HttpService } from '../../../../services/http/http.service';
import { IAppState } from '../../../../interfaces/app-state';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { TicketService } from '../../../../services/ticket.service';
import { User } from '../../../../models/user';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.css']
})
export class TypeComponent extends BasePageComponent implements OnInit, OnDestroy {
  activeElement: number;
  currentUser: User;
  categoryId: number;
  constructor(private router: Router, private authenticationService: AuthService, private location: Location, private ticket: TicketService,
    store: Store<IAppState>, private route: ActivatedRoute,
    httpSv: HttpService) {
    super(store, httpSv);
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getData(`${environment.apiUrl}/Product/`, 'tableData', 'setLoaded');
    this.categoryId = this.route.snapshot.params['id'];
    this.pageData = {
      title: 'Realizar teste',
      loaded: true
    };
  }

  ngOnInit() {
    super.ngOnInit();
  }

  select(e, i) {
    this.activeElement = i;
      this.router.navigate(['app/TestType/' + e.target.innerHTML.replace(' ', '') + '/' + this.categoryId]);
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  back() {
    this.location.back();
  }
}
