import { async } from '@angular/core/testing';
import { CategoryProduct } from './../../../models/CategoryProduct';
import { Component, OnDestroy, OnInit, Optional, Inject, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../base-page';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { EChartOption } from 'echarts';
import { Chart, ChartData, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
import { User } from '../../../models/user';
import { AuthService } from '../../../services/auth.service';
import { TestService } from '../../../services/test.service';
import { QuestionService } from '../../../services/question.service';
import { UserService } from '../../../services/user.service';
import { CategoryProductService } from '../../../services/category-product.service';
import { environment } from '../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';
import { ShowTestComponent } from '../../custom/show-test/show-test.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { MatDialog } from '@angular/material';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { TicketService } from '../../../services/ticket.service';
import { ToastrService } from 'ngx-toastr';
import { SheetSalesService } from '../../../services/sheetsSales.service';
import { CategoryService } from '../../../services/category.service';
import { ShowcamTestComponent } from '../../custom/showCamTest/showCamTest.component';
import { Utils } from 'src/utils/utils';
import { data } from 'jquery';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: "page-dashboard",
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PageDashboardComponent extends BasePageComponent
  implements OnInit, OnDestroy {
  // Colors
  Colors = [
    { backgroundColor: ['#4BC842', '#F74545'] },
    { borderColor: ['#4BC842', '#F74545'] }
  ];
  ColorsHorizontalBars = [{ backgroundColor: 'rgba(64, 154, 87, 0.45)' }];
  ColorsGlobalRatingChart = [
    { backgroundColor: ['#0459A0', '#367AB3', '#22DB14', '#75A4CB'] },
    { borderColor: ['#0459A0', '#367AB3', '#22DB14', '#75A4CB'] }
  ];

  pageSizeTableUserData = 10;
  pageSizeTableTestListData = 10;
  pageSizeTableQuestionListData = 10;
  pageSizeAlvesCostaTableTestListData = 10;
  pageSizeAlvesCostaTableQuestionListData = 10;
  pageSizeImtTableTestListData = 10;
  pageSizeImtTableQuestionListData = 10;
  componentDestroyed: Subject<boolean> = new Subject();
  
  // Global tab
  browsersOptions: EChartOption;
  bordered = true;
  position = 'both';
  loading = false;
  currentUser: User;
  isCam: boolean;

  // Doughnut chart 1
  globalRatingData: MultiDataSet = [[]];
  globalRatingType: ChartType = 'doughnut';
  tests: any;
  questions: any;
  seen: any;
  themes: any;
  approvedGlobal: any;
  notApprovedGlobal: any;
  answeredTaxGlobal: any;
  notAnsweredTaxGlobal: any;
  categorySelectedName: any;
  approvedAC: any;
  notApprovedAC: any;
  answeredTaxAC: any;
  notAnsweredTaxAC: any;
  selectedCategoryName: any;
  approvedImt: any;
  notApprovedImt: any;
  answeredTaxImt: any;
  notAnsweredTaxImt: any;

  globalRatingLabels: Label[] = [
    'Perguntas Respondidas',
    'Respostas Corretas',
    'Testes Aprovados',
    'Temas Corretos'
  ];


  alvesCostaRatingData: MultiDataSet = [[]];
  alvesCostaTests: any;
  alvesCostaQuestions: any;
  alvesCostaSeen: any;
  alvesCostaThemes: any;

  imtRatingData: MultiDataSet = [[]];
  imtTests: any;
  imtQuestions: any;
  imtSeen: any;
  imtThemes: any;

  public chartOptions: ChartOptions = {
    responsive: true,
    legend: { display: false},
    scales: {
      yAxes: [{
        ticks: 
          {callback: function(value, index, ticks) {
          return value + '%';},
          min: 0,
          stepSize: 10,
          max: 100
        }
      }],
      xAxes: [{
        ticks: {
          min: 0,
          max: 100,
          stepSize: 10
        }
      }]
    },
    plugins: {
            filler: {
                propagate: false
            }
    }
  };

  public chartOptionsMobile: ChartOptions = {
    responsive: true,
    legend: { display: false},
  };

  public chartOptionsDefault: ChartOptions = {
    responsive: true,
    legend: { display: true},
  };


  // Doughnut chart 2
  globalTestsTaxData: MultiDataSet = [[]];
  alvesCostaTestsTaxData: MultiDataSet = [[]];
  imtTestsTaxData: MultiDataSet = [[]];
  totalTestsTaxLabels: Label[] = ['Testes Aprovados', 'Testes Reprovados'];
  totalTestsTaxType: ChartType = 'doughnut';
  dChartOptions: any;
 
  // Table - Users
  tableUserData: any;

  // Table - Tests
  listOfDisplayData: any[] = [];
  tableTestListData: any;
  alvesCostaTableTestListData: any[];
  imtTableTestListData: any[];
  
  // Doughnut chart 3
  globalTotalQuestionsData: MultiDataSet = [[]];
  alvesCostaTotalQuestionsData: MultiDataSet = [[]];
  imtTotalQuestionsData: MultiDataSet = [[]];
  totalQuestionsType: ChartType = 'doughnut';
  totalQuestionsLabels: Label[] = ['Questões Respondidas', 'Questões Por Responder '];

  // Table - Questions
  tableQuestionListData: any;
  alvesCostaTableQuestionListData: any;
  imtTableQuestionListData: any;
  currentPage = 0;

  // Succcess chart
  barChartOptions: any;
  globalTaxThemeLabels: Label[];
  alvesCostaTaxThemeLabels: Label[];
  imtTaxThemeLabels: Label[];
  globalTaxThemeData: MultiDataSet = [];
  TaxThemeApprovalData: MultiDataSet = [];
  TaxThemeReprovedData: MultiDataSet = [];
  alvesCostaTaxThemeApprovalData: MultiDataSet = [];
  alvesCostaTaxThemeReprovedData: MultiDataSet = [];
  imtTaxThemeApprovalData: MultiDataSet = [];
  imtTaxThemeReprovedData: MultiDataSet = [];
  globalTaxThemeType: ChartType = 'line';
  chartDatasets: Array<any> = [];
  alvesCostachartDatasets: Array<any> = [];
  imtchartDatasets: Array<any> = [];
  chartData: any = []
  alvesCostaChartData: any = []
  imtChartData: any = []

  // Table - Theme Tax
  tableTaxThemeData: any[];
  alvesCostaTableTaxThemeData: any[];
  imtTableTaxThemeData: any[];
  alvesCostaListThemeTaxGlobal: any[] = [];
  imtListThemeTaxGlobal: any[] = [];

  // Filter
  endDtValue = '';
  typeValue = '';
  wrongsValue = '';
  resultValue = '';
  fontValueTest = '';

  typeValueAC = '';
  wrongsValueAC = '';

  typeValueImt = '';
  wrongsValueImt = '';

  idValueAC = '';
  descriptionValueAC = '';
  wrongQuestionsValueAC = '';

  idValueImt = '';
  descriptionValueImt = '';
  wrongQuestionsValueImt = '';

  idValue = '';
  fontValue = '';
  descriptionValue = '';
  wrongQuestionsValue = '';

  isAnswerValue = '';
  listQuestions: any[] = [];
  listQuestionsAlvesCosta: any[] = [];
  listQuestionsImt: any[] = [];

  listThemeTaxGlobal: any[] = [];
  alvesCostaListThemeTax: any[] = [];
  imtListThemeTax: any[] = [];

  nameValue = '';
  userNameValue = '';
  companyNameValue = '';
  studentNumberValue = '';

  listTest: any[] = [];
  listTestAlvesCosta: any[] = [];
  listTestImt: any[] = [];
  listUser: any[] = [];
  studentId: any;
  // Sort
  sortName: string | null = null;
  sortValue: string | null = null;
  mapOfSort: { [key: string]: string | null } = {
    name: null,
    userName: null,
    studentNumber: null,
    endDt: null,
    font: null,
    tag: null,
    wrongQuestions: null,
    approved: null,
    oldQuestionId: null,
    description: null,
    isAnswer: null,
    rightQuestions: null
  };
  teacher = true;
  studentDashboard = false;

  // ShowComponent
  selected = new FormControl(0);
  localData: any;
  flag = false;
  title: string;
  showEmptyDashboardValue = false;
  showEmptyDashboardValueImt = false;
  showEmptyDashboardValueAC = false;
  showStudentDashboardTitleValue = false;

  haveElearning: boolean = false;
  haveTests: boolean = false;

  // DropdownList - Category
  categoryProductList: any[] = [];
  categorySelected: any;
  categoriesForm: FormGroup;
  categories: any[] = [];
  selectedCategoryValue = false;
  list: any[] = [];

loadingSpinner = false;

  userId: number = 0;
  category : any = null;

  constructor(
    store: Store<IAppState>,
    public dialog: MatDialog,
    httpSv: HttpService,
    private questionService: QuestionService,
    private authenticationService: AuthService,
    private testService: TestService,
    private userService: UserService,
    private categoryProductService: CategoryProductService,
    private getStudentsReportGeneralservice: SheetSalesService,
    userTicketService: TicketService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private categoryService: CategoryService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public utils: Utils,
    private _formBuilder: FormBuilder
  ) {

    super(store, httpSv);
    this.localData = { ...data };

    this.authenticationService.currentUser.subscribe(
      x => (this.currentUser = x)
    );

    console.log('constructor');
    console.log(this.currentUser.user);

    if ((this.localData.userId != null && this.localData.userId != undefined) || this.currentUser.user.profileId === 3) 
    {      
      // Se tiver o profileId = 3 -> Student
      this.teacher = false;
      this.userId = this.currentUser.user.id;

      if (this.currentUser.user.profileId === 3) {
        this.showStudentDashboardTitleValue = false;
        this.showEmptyDashboardValue = true;

      this.categoryProductService
        .getCategoryProductListByUserId(this.currentUser.user.id)
        .subscribe(categories => {
          console.log(categories);

          if (categories.length > 0) {
            this.categories = categories;

            this.categoryProductList = categories;
            this.categorySelected = this.categoryProductList[0]['categoryProductId'];
            this.initCharts(this.categorySelected);
            this.selectedCategoryValue = true;
            this.category = this.categoryProductList[0];

            this.haveElearning = this.category.type.find(x => x.type == 'eLearning') != null;
            this.haveTests = this.category.type.find(x => x.type == 'Code' || x.type =='CAM' || x.type =='ADR' || x.type == 'Taxis')  != null;

            console.log(this.haveElearning);
            console.log(this.haveTests);
          }
        });
      }
      // Outro profileId -> Teacher
      else if (this.currentUser.user.profileId === 7 || this.currentUser.user.profileId === 1 || this.currentUser.user.profileId === 6 || this.currentUser.user.profileId === 8) {
        this.teacher = true;

        if (this.currentUser.user.profileId === 7) {
          this.showStudentDashboardTitleValue = true;
        }
        
        if (this.currentUser.user.profileId === 8) {
          this.showStudentDashboardTitleValue = false;
        }

        this.userId = this.localData.userId;

        this.userService.getById(this.localData.userId).subscribe(c => {
         
          //this.currentUser = c;
          this.studentId = c['userId'];
          this.categoryProductService
            .getCategoryProductListByUserId(this.localData.userId)
            .subscribe(categories => {
              
              if (categories.length > 0) {
                this.categories = categories;
                this.selectedCategoryName = this.categories[0]['name'];
              }
            });



          this.categoryProductService
            .getCategoryProductListByUserId(c['userId'])
            .pipe(first())
            .subscribe(categoryProductList => {
              this.categoryProductList = categoryProductList;
              
              if (this.categoryProductList.length >= 1) {
                this.category = this.categoryProductList[0];
                console.log(this.category);
                this.categorySelectedName = this.categoryProductList[0]['name'];
                this.categorySelected = this.selectCategory(0);
                this.openCity(event, 'RatingGlobal', 'RatingGlobal_');
              }
              else {
                this.showEmptyDashboardValue = true;
              }
            });
        });
        

        this.teacher = false;
        userTicketService
          .ticketsByUserId(this.localData.userId)
          .subscribe(a => { });
      } else {
      }
    } else {     
      this.authenticationService.currentUser.subscribe(
        x => (this.currentUser = x)
      );
      this.userId = this.currentUser.user.id;
      this.teacher = true;
      this.studentDashboard = false;
      this.tableUsers();
    }
    this.getData(`${environment.apiUrl}/Category/`, 'tableData', 'setLoaded');
    this.pageData = {
      title: 'Dashboard'
    };

    if (this.teacher === true) {
      this.pageData = {
        title: 'Utilizadores',
        loaded: true
      };
    }
  }

  selectCategory(i) {
    this.selectedCategoryValue = true;
    this.categorySelected = this.categoryProductList[i]['categoryProductId'];

    this.category = this.categoryProductList[i];
    this.haveElearning = this.category.type.find(x => x.type == 'eLearning') != null;
    this.haveTests = this.category.type.find(x => x.type == 'Code' || x.type =='CAM' || x.type =='ADR' || x.type == 'Taxis')  != null;

    console.log(this.haveElearning);
    console.log(this.haveTests);
    
    if (this.studentId != null) {
      this.initChartsForTeacherProfile(this.categorySelected);
      this.categoryService.isCam(this.categoryProductList[i]['categoryId']).subscribe(camCheck => {
        this.isCam = camCheck;
        console.log(this.isCam);
      });
    } else {
      this.initCharts(this.categorySelected);
      this.categoryService.isCam(this.categoryProductList[i]['categoryId']).subscribe(camCheck => {
        this.isCam = camCheck;
        console.log(this.isCam);
      });
    }
  }

  initChartsForTeacherProfile(categoryProduct) {
    this.testService
      .getTestListByUserId(this.studentId, categoryProduct, null)
      .pipe(first())
      .subscribe(b => {
        if (b.length > 0) {
          this.globalCharts(
            this.studentId, categoryProduct
          );
        } else {
          this.studentDashboard = true;
          this.showEmptyDashboardValue = true;
        }
      });

    this.testService
      .getTestListByUserId(this.studentId, categoryProduct, 'alvescosta')
      .pipe(first())
      .subscribe(b => {
        if (b.length > 0) {
          this.studentDashboard = true;
          this.alvesCostaCharts(this.studentId, categoryProduct);
        } else {
          this.studentDashboard = true;
          this.showEmptyDashboardValueAC = true;
        }
      });

    this.testService
      .getTestListByUserId(this.studentId, categoryProduct, 'imt')
      .pipe(first())
      .subscribe(b => {
        if (b.length > 0) {
          this.studentDashboard = true;
          this.imtCharts(
            this.studentId,
            categoryProduct
          );
        }
        else {
          this.studentDashboard = true;
          this.showEmptyDashboardValueImt = true;
        }
      });
  }

  initCharts(categoryProduct) {
    this.testService
      .getTestListByUserId(this.currentUser.user.id, categoryProduct, null)
      .pipe(first())
      .subscribe(b => {
        if (b.length > 0) {
          this.studentDashboard = false;
          this.showEmptyDashboardValue = false;
          this.globalCharts(this.currentUser.user.id, categoryProduct);
          this.teacher = false;
        } else {
          this.showEmptyDashboardValue = true;
        }
      });

    this.testService
      .getTestListByUserId(this.currentUser.user.id, categoryProduct, 'alvescosta')
      .pipe(first())
      .subscribe(b => {
        if (b.length > 0) {
          this.studentDashboard = true;
          this.showEmptyDashboardValueAC = false;
          this.alvesCostaCharts(
            this.currentUser.user.id,
            categoryProduct
          );
          this.teacher = false;
        } else {
          this.showEmptyDashboardValueAC = true;
        }
      });

    this.testService
      .getTestListByUserId(this.currentUser.user.id, categoryProduct, 'imt')
      .pipe(first())
      .subscribe(b => {
        if (b.length > 0) {
          this.studentDashboard = true;
          this.showEmptyDashboardValueImt = false;
          this.imtCharts(
            this.currentUser.user.id,
            categoryProduct
          );
          this.teacher = false;
        } else {
          this.showEmptyDashboardValueImt = true;
        }
      });
  }
  // Charts and Tables
  globalCharts(c, categoryProduct) {
    // Chart 1 and 2
    this.showEmptyDashboardValue = false;
    this.testService
      .getGlobalRatingByUserId(c, categoryProduct, null)
      .pipe(first())
      .subscribe(b => {
        this.globalRatingData = [[]];
        this.globalTestsTaxData = [[]];
        this.globalRatingData[0].push(b[0]['answeredTax']);
        this.globalRatingData[0].push(b[0]['percentageRightQ']);
        this.globalRatingData[0].push(b[0]['percentageRightTest']);
        this.globalRatingData[0].push(b[0]['themeAverage']);
        this.seen = b[0]['answeredTax'];
        this.questions = b[0]['percentageRightQ'];
        this.tests = b[0]['percentageRightTest'];
        this.themes = b[0]['themeAverage'];

        // Chart 2
        this.globalTestsTaxData[0].push(b[0]['percentageRightTest']);
        this.globalTestsTaxData[0].push(b[0]['percentageWrongTest']);
        this.approvedGlobal = b[0]['percentageRightTest'];
        this.notApprovedGlobal = b[0]['percentageWrongTest'];

      });

    // Chart 3
    this.testService
      .getTotalQuestionsByFontId(c, categoryProduct, null)
      .pipe(first())
      .subscribe(b => {
        this.globalTotalQuestionsData = [[]];
        // Doughnut chart 3
        this.globalTotalQuestionsData[0].push(b[0]['answeredTax']);
        this.globalTotalQuestionsData[0].push(b[0]['notAnsweredTax']);
        this.answeredTaxGlobal = b[0]['answeredTax'];
        this.notAnsweredTaxGlobal = b[0]['notAnsweredTax'];
        if((this.answeredTaxGlobal + this.notAnsweredTaxGlobal) > 100){
          if(this.answeredTaxGlobal >= this.notAnsweredTaxGlobal){
            this.answeredTaxGlobal = this.answeredTaxGlobal - 1;
          } else {
            this.notAnsweredTaxGlobal = this.notAnsweredTaxGlobal - 1;
          }
        }
      });

    // Tests Table
    this.testService
      .getTestListByUserId(c, categoryProduct, null)
      .pipe(first())
      .subscribe(a => {
        this.tableTestListData = a;
        this.listTest = this.tableTestListData;
      });

      //Success Chart
      this.testService
      .getSuccessTaxByUserId(c, categoryProduct, null)
      .pipe(first())
      .subscribe(a => {
        this.globalTaxThemeLabels = a.map(data => {return data.formatData});
        this.TaxThemeApprovalData = a.map(data => {return data.approvedPercent});
        this.TaxThemeReprovedData = a.map(data => {return data.reprovedPercent});
        this.chartDatasets[0] = this.TaxThemeApprovalData;
        this.chartDatasets[1] = this.TaxThemeReprovedData;
        let reccomended = [];
        let i = 0;
        while(i < this.globalTaxThemeLabels.length){
          reccomended = reccomended.concat(70)
          i++;
        };
        this.chartData = [
          {data: this.chartDatasets[0], borderColor: 'rgba(64, 154, 87, 0.8)', backgroundColor: 'rgba(64, 154, 87, 0.45)',
          pointBackgroundColor: 'rgba(64, 154, 87, 1)', label:'Taxa de Aprovação'},
          {data: this.chartDatasets[1], borderColor: 'rgba(237, 45, 48, 0.8)', backgroundColor: 'rgba(237, 45, 48, 0.5)',
          pointBackgroundColor: 'rgba(237, 45, 48, 1)', label:'Taxa de Reprovação', fill: false},
          {data: reccomended, label:'Limiar de Segurança', fill: false }
        ];
      });

    
    // Questions Table
    this.loadingSpinner = true;
    let severalRows: any = [];
    let page: number = 1;

    this.questionService.getQuestionListByUserId(c, categoryProduct, null, page)
      .pipe(first())
      .subscribe(b => {
        if(b.length > 0)
        {
          this.tableQuestionListData = b;
          this.listQuestions = this.tableQuestionListData;
        this.loadingSpinner = false
          let totalRows: number = b[0].total;
          let pages: number = Math.ceil(totalRows / 200);
    
          severalRows[page - 1] = b;
          for(let i = page + 1; i <= pages; i++)
          {
          if(this.componentDestroyed.isStopped) return;

          console.log(this.componentDestroyed);
            severalRows[i -1] = undefined;
            this.questionService.getQuestionListByUserId(c, categoryProduct, null, i)
          //.pipe(first())
          .pipe(takeUntil(this.componentDestroyed))
            .subscribe(b =>
              {
                if(b.length > 0)
                {
                  let thisPage = b[0].page;
                  severalRows[thisPage - 1] = b;
                  this.verifyIfIsOver (severalRows, pages);
                }
              })            
          } 
        }
      
      });



    // Theme Table
    this.testService
      .getTaxThemeByUserId(c, categoryProduct, null)
      .pipe(first())
      .subscribe(b => {
        this.tableTaxThemeData = b;
        this.listThemeTaxGlobal = this.tableTaxThemeData;
      });




  }
  alvesCostaCharts(c, categoryProduct) {
    // Chart 1 e Chart 2
    this.testService
      .getGlobalRatingByUserId(c, categoryProduct, 'alvescosta')
      .pipe(first())
      .subscribe(b => {
        this.showEmptyDashboardValue = false;
        this.alvesCostaRatingData = [[]];
        this.alvesCostaSeen = [[]];
        this.alvesCostaQuestions = [[]];
        this.alvesCostaTests = [[]];
        this.alvesCostaTestsTaxData = [[]];

        this.alvesCostaRatingData[0].push(b[0]['answeredTax']);
        this.alvesCostaRatingData[0].push(b[0]['percentageRightQ']);
        this.alvesCostaRatingData[0].push(b[0]['percentageRightTest']);
        this.alvesCostaRatingData[0].push(b[0]['themeAverage']);
        this.alvesCostaSeen = b[0]['answeredTax'];
        this.alvesCostaQuestions = b[0]['percentageRightQ'];
        this.alvesCostaTests = b[0]['percentageRightTest'];
        this.alvesCostaThemes = b[0]['themeAverage'];

        // Chart 2
        this.alvesCostaTestsTaxData[0].push(b[0]['percentageRightTest']);
        this.alvesCostaTestsTaxData[0].push(b[0]['percentageWrongTest']);
        this.approvedAC = b[0]['percentageRightTest'];
        this.notApprovedAC = b[0]['percentageWrongTest'];
      });

    // Test Table
    this.testService
      .getTestListByUserId(c, categoryProduct, 'alvescosta')
      .pipe(first())
      .subscribe(a => {
        this.alvesCostaTableTestListData = a;
        this.listTestAlvesCosta = this.alvesCostaTableTestListData;
      });

    // Chart 3 - Questions
    this.testService
      .getTotalQuestionsByFontId(c, categoryProduct, 'alvescosta')
      .pipe(first())
      .subscribe(b => {
        this.alvesCostaTotalQuestionsData = [[]];
        // Doughnut chart 3
        this.alvesCostaTotalQuestionsData[0].push(b[0]['answeredTax']);
        this.alvesCostaTotalQuestionsData[0].push(b[0]['notAnsweredTax']);
        this.answeredTaxAC = b[0]['answeredTax'];
        this.notAnsweredTaxAC = b[0]['notAnsweredTax'];
        if((this.answeredTaxAC + this.notAnsweredTaxAC) > 100){
          if(this.answeredTaxAC >= this.notAnsweredTaxAC){
            this.answeredTaxAC = this.answeredTaxAC - 1;
          } else {
            this.notAnsweredTaxAC = this.notAnsweredTaxAC - 1;
          }
        }
      });

      //Success Chart
      this.testService
      .getSuccessTaxByUserId(c, categoryProduct, 'alvescosta')
      .pipe(first())
      .subscribe(a => {
        this.alvesCostaTaxThemeLabels = a.map(data => {return data.formatData});
        this.alvesCostaTaxThemeApprovalData = a.map(data => {return data.approvedPercent});
        this.alvesCostaTaxThemeReprovedData = a.map(data => {return data.reprovedPercent});
        this.alvesCostachartDatasets[0] = this.alvesCostaTaxThemeApprovalData;
        this.alvesCostachartDatasets[1] = this.alvesCostaTaxThemeReprovedData;
        let reccomended = [];
        let i = 0;
        while(i < this.alvesCostaTaxThemeLabels.length){
          reccomended = reccomended.concat(70)
          i++;
        };
        this.alvesCostaChartData = [
          {data: this.alvesCostachartDatasets[0], borderColor: 'rgba(64, 154, 87, 0.8)', backgroundColor: 'rgba(64, 154, 87, 0.45)',
          pointBackgroundColor: 'rgba(64, 154, 87, 1)', label:'Taxa de Aprovação'},
          {data: this.alvesCostachartDatasets[1], borderColor: 'rgba(237, 45, 48, 0.8)', backgroundColor: 'rgba(237, 45, 48, 0.5)',
          pointBackgroundColor: 'rgba(237, 45, 48, 1)', label:'Taxa de Reprovação', fill: false},
          {data: reccomended, label:'Limiar de Segurança', fill: false }
        ];
      }); 

    // Question Table
    let severalRowsAlves: any = [];
    let pageAlves: number = 1;

    this.questionService.getQuestionListByUserId(c, categoryProduct, 'alvescosta', pageAlves)
      .pipe(first())
      .subscribe(b => {
        if(b.length > 0)
      {
        this.alvesCostaTableQuestionListData = b;
        this.listQuestionsAlvesCosta = this.alvesCostaTableQuestionListData;

        let totalRows: number = b[0].total;
        let pages: number = Math.ceil(totalRows / 200);

        severalRowsAlves[pageAlves - 1] = b;
        for(let i = pageAlves + 1; i <= pages; i++)
        {
          if(this.componentDestroyed.isStopped) return;

          severalRowsAlves[i -1] = undefined;
          this.questionService.getQuestionListByUserId(c, categoryProduct, 'alvescosta', i)
          //.pipe(first())
          .pipe(takeUntil(this.componentDestroyed))
          .subscribe(b =>
            {
              if(b.length > 0)
              {
                let thisPage = b[0].page;
                severalRowsAlves[thisPage - 1] = b;
                this.verifyIfIsOverAlvesCosta (severalRowsAlves, pages);
              }
            })            
        } 
      }
      });

    // Theme Table
    this.testService
      .getTaxThemeByUserId(c, categoryProduct, 'alvescosta')
      .pipe(first())
      .subscribe(b => {
        this.alvesCostaTableTaxThemeData = b;
        this.alvesCostaListThemeTaxGlobal = this.alvesCostaTableTaxThemeData;
      });

    console.log('lista dashboard ' + this.alvesCostaRatingData);

  }

  imtCharts(c, categoryProduct) {
    // Chart 1  e 2 - Global Rating / Tests
    this.testService
      .getGlobalRatingByUserId(c, categoryProduct, 'imt')
      .pipe(first())
      .subscribe(b => {
        this.imtTestsTaxData = [[]];
        this.imtRatingData = [[]];

        this.imtRatingData[0].push(b[0]['answeredTax']);
        this.imtRatingData[0].push(b[0]['percentageRightQ']);
        this.imtRatingData[0].push(b[0]['percentageRightTest']);
        this.imtRatingData[0].push(b[0]['themeAverage']);

        this.imtTestsTaxData[0].push(b[0]['percentageRightTest']);
        this.imtTestsTaxData[0].push(b[0]['percentageWrongTest']);

        // Labels
        this.approvedImt = b[0]['percentageRightTest'];
        this.notApprovedImt = b[0]['percentageWrongTest'];

        this.imtSeen = b[0]['answeredTax'];
        this.imtQuestions = b[0]['percentageRightQ'];
        this.imtTests = b[0]['percentageRightTest'];
        this.imtThemes = b[0]['themeAverage'];

      });

    // Test Table
    this.testService
      .getTestListByUserId(c, categoryProduct, 'imt')
      .pipe(first())
      .subscribe(a => {
        this.imtTableTestListData = a;
        this.listTestImt = this.imtTableTestListData;
      });

    // Chart 3 - Questions
    this.testService
      .getTotalQuestionsByFontId(c, categoryProduct, 'imt')
      .pipe(first())
      .subscribe(b => {
        this.imtTotalQuestionsData = [[]];
        // Doughnut chart 3
        this.imtTotalQuestionsData[0].push(b[0]['answeredTax']);
        this.imtTotalQuestionsData[0].push(b[0]['notAnsweredTax']);
        this.answeredTaxImt = b[0]['answeredTax'];
        this.notAnsweredTaxImt = b[0]['notAnsweredTax'];
        if((this.answeredTaxImt + this.notAnsweredTaxImt) > 100){
          if(this.answeredTaxImt >= this.notAnsweredTaxImt){
            this.answeredTaxImt = this.answeredTaxImt - 1;
          } else {
            this.notAnsweredTaxImt = this.notAnsweredTaxImt - 1;
          }
        }
      });

      //Success Chart
      this.testService
      .getSuccessTaxByUserId(c, categoryProduct, 'imt')
      .pipe(first())
      .subscribe(a => {
        this.imtTaxThemeLabels = a.map(data => {return data.formatData});
        this.imtTaxThemeApprovalData = a.map(data => {return data.approvedPercent});
        this.imtTaxThemeReprovedData = a.map(data => {return data.reprovedPercent});
        this.imtchartDatasets[0] = this.imtTaxThemeApprovalData;
        this.imtchartDatasets[1] = this.imtTaxThemeReprovedData;
        let reccomended = [];
        let i = 0;
        while(i < this.imtTaxThemeLabels.length){
          reccomended = reccomended.concat(70)
          i++;
        };
        this.imtChartData = [
          {data: this.imtchartDatasets[0], borderColor: 'rgba(64, 154, 87, 0.8)', backgroundColor: 'rgba(64, 154, 87, 0.45)',
          pointBackgroundColor: 'rgba(64, 154, 87, 1)', label:'Taxa de Aprovação'},
          {data: this.imtchartDatasets[1], borderColor: 'rgba(237, 45, 48, 0.8)', backgroundColor: 'rgba(237, 45, 48, 0.5)',
          pointBackgroundColor: 'rgba(237, 45, 48, 1)', label:'Taxa de Reprovação', fill: false},
          {data: reccomended, label:'Limiar de Segurança', fill: false }
        ];
      });

    // Question Table
    let severalRowsImt: any = [];
    let pageImt: number = 1;

    this.questionService.getQuestionListByUserId(c, categoryProduct, 'imt', pageImt)
      .pipe(first())
      .subscribe(b => {
        if(b.length > 0)
        {
          this.imtTableQuestionListData = b;
          this.listQuestionsImt = this.imtTableQuestionListData;
  
          let totalRows: number = b[0].total;
          let pages: number = Math.ceil(totalRows / 200);
    
          severalRowsImt[pageImt - 1] = b;
          for(let i = pageImt + 1; i <= pages; i++)
          {
          if(this.componentDestroyed.isStopped) return;

            severalRowsImt[i -1] = undefined;
            this.questionService.getQuestionListByUserId(c, categoryProduct, 'imt', i)
          //.pipe(first())
          .pipe(takeUntil(this.componentDestroyed))
            .subscribe(b =>
              {
                if(b.length > 0)
                {
                  let thisPage = b[0].page;
                  severalRowsImt[thisPage - 1] = b;
                  this.verifyIfIsOverImt (severalRowsImt, pages);
                }
              })            
          } 
        }
      
      });

    // Theme Table
    this.testService
      .getTaxThemeByUserId(c, categoryProduct, 'imt')
      .pipe(first())
      .subscribe(b => {
        this.imtTableTaxThemeData = b;
        this.imtListThemeTaxGlobal = this.imtTableTaxThemeData;
      });


  }

  tableUsers() {
    this.userService
      .getAllStudentsByCompany(this.currentUser.user.companyId, this.currentUser.user.profileId)
      .pipe(first())
      .subscribe(a => {
        this.tableUserData = a;
        this.listUser = this.tableUserData;
      });
  }

  verifyIfIsOver(severalRows, totalPage)
  {
    let allOk: boolean = false;

    allOk = true;
    for(let i = 0; i < totalPage; i++)
    {
      if(severalRows[i] == undefined)
      {
        allOk = false;
      }
    } 

    if(allOk)
    {     
      for(let i = 1; i < totalPage; i++)
      {
        this.tableQuestionListData = this.tableQuestionListData.concat(severalRows[i])
        this.listQuestions = this.tableQuestionListData
      } 
    }  
  }

  verifyIfIsOverAlvesCosta(severalRows, totalPage)
  {
    let allOk: boolean = false;

    allOk = true;
    for(let i = 0; i < totalPage; i++)
    {
      if(severalRows[i] == undefined)
      {
        allOk = false;
      }
    } 

    if(allOk)
    {
      for(let i = 1; i < totalPage; i++)
      {
        this.alvesCostaTableQuestionListData = this.alvesCostaTableQuestionListData.concat(severalRows[i])
        this.listQuestionsAlvesCosta = this.alvesCostaTableQuestionListData;
      } 
    }  
  }

  verifyIfIsOverImt(severalRows, totalPage)
  {
    let allOk: boolean = false;

    allOk = true;
    for(let i = 0; i < totalPage; i++)
    {
      if(severalRows[i] == undefined)
      {
        allOk = false;
      }
    } 

    if(allOk)
    {     
      for(let i = 1; i < totalPage; i++)
      {
        this.imtTableQuestionListData = this.imtTableQuestionListData.concat(severalRows[i])
        this.listQuestionsImt = this.imtTableQuestionListData;
      } 
    }  
  }

  isTeacher() {
    return this.teacher;
  }
  selectedCategory() {
    return this.selectedCategoryValue;
  }
  showStudentDashboardTitle() {
    return this.showStudentDashboardTitleValue;
  }
  showEmptyDashboard() {
    return this.showEmptyDashboardValue && !this.haveElearning;
  }
  showEmptyDashboardImt() {
    return this.showEmptyDashboardValueImt;
  }
  showEmptyDashboardAC() {
    return this.showEmptyDashboardValueAC;
  }
  isStudentDashboard() {
    this.studentDashboard = true;
    return this.studentDashboard;
  }
  custom(i) {
    this.currentPage = i - 1;
  }

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
  }
  sortQuestions(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.searchQuestions('');
    if(this.sortValue == '' || this.sortValue == null){
      this.sortName = 'name'
      this.sortValue = 'ascend'
      this.filterQuestionTable();
    }
    this.listOfDisplayData = this.listQuestions;
  }
  sortQuestionsAlvesCosta(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.searchQuestionsAlvesCosta('');
    if(this.sortValue == '' || this.sortValue == null){
      this.sortName = 'name'
      this.sortValue = 'ascend'
      this.filterQuestionTableAlvesCosta();
    }
    this.listOfDisplayData = this.listQuestionsAlvesCosta;
  }
  sortQuestionsImt(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.searchQuestionsImt('');
    if(this.sortValue == '' || this.sortValue == null){
      this.sortName = 'name'
      this.sortValue = 'ascend'
      this.filterQuestionTableImt();
    }
    this.listOfDisplayData = this.listQuestionsImt;
  }
  sortTestTable(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.searchTests('');
    if(this.sortValue == '' || this.sortValue == null){
      this.sortName = 'name'
      this.sortValue = 'ascend'
      this.filterTestTable();
    }
    this.listOfDisplayData = this.listTest;
  }
  sortTestTableAlvesCosta(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.searchTestsAlvesCosta('');
    if(this.sortValue == '' || this.sortValue == null){
      this.sortName = 'name'
      this.sortValue = 'ascend'
      this.filterTestTableAlvesCosta();
    }
    this.listOfDisplayData = this.listTestAlvesCosta;
  }
  sortTestTableImt(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.searchTestsImt('');
    if(this.sortValue == '' || this.sortValue == null){
      this.sortName = 'name'
      this.sortValue = 'ascend'
      this.filterTestTableImt();
    }
    this.listOfDisplayData = this.listTestImt;
  }
  sortTableUsers(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.searchUsers('');
    if(this.sortValue == '' || this.sortValue == null){
      this.sortName = 'name'
      this.sortValue = 'ascend'
      this.filterUserTable();
    }
    this.listOfDisplayData = this.listUser;
  }
  sortTableThemeTaxGlobal(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.tableTaxThemeData = this.listThemeTaxGlobal;
    this.listOfDisplayData = this.listThemeTaxGlobal;
    this.searchThemeTaxGlobal('');
    if(this.sortValue == '' || this.sortValue == null){
      this.tableTaxThemeData = this.listThemeTaxGlobal;
      this.listOfDisplayData = this.listThemeTaxGlobal;
    }
    
  }
  sortTableThemeTaxAlvesCosta(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.alvesCostaTableTaxThemeData = this.alvesCostaListThemeTax;
    this.listOfDisplayData = this.alvesCostaListThemeTax;
    this.searchThemeTaxAlvesCosta('');
    if(this.sortValue == '' || this.sortValue == null){
      this.alvesCostaTableTaxThemeData = this.alvesCostaListThemeTax;
      this.listOfDisplayData = this.alvesCostaListThemeTax;
    }
  }
  sortTableThemeTaxImt(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.imtTableTaxThemeData = this.imtListThemeTax;
    this.listOfDisplayData = this.imtListThemeTax;
    this.searchThemeTaxImt('');
    if(this.sortValue == '' || this.sortValue == null){
      this.imtTableTaxThemeData = this.imtListThemeTax;
      this.listOfDisplayData = this.imtListThemeTax;
    }
  }
  get f() {
    return this.categoriesForm.controls;
  }

  reset(): void {
    this.endDtValue = '';
    this.typeValue = '';
    this.wrongsValue = '';
    this.resultValue = '';
    this.fontValueTest = '';

    this.typeValueAC = '';
    this.wrongsValueAC = '';

    this.typeValueImt = '';
    this.wrongsValueImt = '';

    this.idValue = '';
    this.fontValue = '';
    this.descriptionValue = '';
    this.wrongQuestionsValue = '';

    this.idValueAC = '';
    this.descriptionValueAC = '';
    this.wrongQuestionsValueAC = '';

    this.idValueImt = '';
    this.descriptionValueImt = '';
    this.wrongQuestionsValueImt = '';

    this.isAnswerValue = '';
    this.nameValue = '';
    this.userNameValue = '';
    this.companyNameValue = '';
    this.studentNumberValue = '';

    /* this.searchQuestions('');
    this.searchQuestionsAlvesCosta('');
    this.searchQuestionsImt('');
    this.searchTestsImt('');
    this.searchTests('');
    this.searchTestsAlvesCosta('');
    this.searchUsers('');
    this.searchThemeTaxGlobal('');
    this.searchThemeTaxAlvesCosta('');
    this.searchThemeTaxImt(''); */

    this.tableQuestionListData = this.listQuestions;
    this.alvesCostaTableQuestionListData = this.listQuestionsAlvesCosta;
    this.imtTableQuestionListData = this.listQuestionsImt;

    
    this.tableTestListData = this.listTest;
    this.alvesCostaTableTestListData = this.listTestAlvesCosta;
    this.imtTableTestListData = this.listTestImt;
    this.tableUserData = this.listUser;

    this.tableTaxThemeData = this.listThemeTaxGlobal;

    this.mapOfSort = {
      name: null,
      userName: null,
      studentNumber: null,
      endDt: null,
      font: null,
      tag: null,
      wrongQuestions: null,
      approved: null,
      oldQuestionId: null,
      description: null,
      isAnswer: null,
      rightQuestions: null
    };
    
  }
  select(event) {
    if (event.path[1].className.match('active')) {
      event.path[1].classList.remove('active');
    } else {
      event.path[1].classList.add('active');
    }
  }
  onSubmit() {
    this.localData.CategoryName = this.f.categories.value;
  }

  searchQuestions(type: string): void {
    const filterFunc = (item: any) => {
      const s = '' + item['oldQuestionId'].substring(this.charsLenToByPass()).toLowerCase();
      const x = item['font'].toLowerCase();
      const y = item['description'].toLowerCase();
      const z = '' + item['wrongQuestions'];
      return type == 'oldQuestionId'
        ? s.indexOf(this.idValue) !== -1
        : type == 'font'
          ? x.indexOf(this.fontValue.toLowerCase()) !== -1
          : type == 'description'
            ? y.indexOf(this.descriptionValue.toLowerCase()) !== -1
            : z.indexOf(this.wrongQuestionsValue) !== -1;
    };
    const data = this.tableQuestionListData.filter((item: any) =>
      filterFunc(item)
    );
    this.tableQuestionListData = data.sort((a, b) =>
      this.sortValue === 'ascend'
        ? // tslint:disable-next-line: no-non-null-assertion
        a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : // tslint:disable-next-line: no-non-null-assertion
        b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );
  }
  searchQuestionsAlvesCosta(type: string): void {
    const filterFunc = (item: any) => {
      const x = '' + item['oldQuestionId'].substring(this.charsLenToByPass()).toLowerCase();
      const y = item['description'].toLowerCase();
      const z = '' + item['wrongQuestions'];
      return type == 'oldQuestionId'
        ? x.indexOf(this.idValueAC.toString()) !== -1
        : type == 'description'
          ? y.indexOf(this.descriptionValueAC.toLowerCase()) !== -1
          : z.indexOf(this.wrongQuestionsValueAC) !== -1;
    };
    const data = this.alvesCostaTableQuestionListData.filter((item: any) =>
      filterFunc(item)
    );
    this.alvesCostaTableQuestionListData = data.sort((a, b) =>
      this.sortValue === 'ascend'
        ? a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );
  }
  searchQuestionsImt(type: string): void {
    const filterFunc = (item: any) => {
      const x = '' + item['oldQuestionId'].substring(this.charsLenToByPass()).toLowerCase();
      const y = item['description'].toLowerCase();
      const z = '' + item['wrongQuestions'];
      return type == 'oldQuestionId'
        ? x.indexOf(this.idValueImt.toString()) !== -1
        : type == 'description'
          ? y.indexOf(this.descriptionValueImt.toLowerCase()) !== -1
          : z.indexOf(this.wrongQuestionsValueImt) !== -1;
    };
    const data = this.imtTableQuestionListData.filter((item: any) =>
      filterFunc(item)
    );
    this.imtTableQuestionListData = data.sort((a, b) =>
      this.sortValue === 'ascend'
        ? a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );
  }
  searchTests(type: string): void {
    const filterFunc = (item: any) => {
      const y = item['font'].toLowerCase();
      const z = item['tag'].toLowerCase();
      const t = '' + item['wrongQuestions'];
      const s = '' + item['approved'];
      return type == 'font'
        ? y.indexOf(this.fontValueTest.toLowerCase()) !== -1
        : type == 'tag'
          ? z.indexOf(this.typeValue.toLowerCase()) !== -1
          : type == 'wrongQuestions'
            ? t.indexOf(this.wrongsValue) !== -1
            : s.indexOf(this.resultValue) !== -1;
    };
    const data = this.tableTestListData.filter((item: any) => filterFunc(item));
    this.tableTestListData = data.sort((a, b) =>
      this.sortValue === 'ascend'
        ? a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );
  }
  searchTestsAlvesCosta(type: string): void {
    const filterFunc = (item: any) => {
      const z = item['tag'].toLowerCase();
      const t = '' + item['wrongQuestions'];
      return type == 'tag'
        ? z.indexOf(this.typeValueAC.toLowerCase()) !== -1
        : t.indexOf(this.wrongsValueAC) !== -1;
    };
    const data = this.alvesCostaTableTestListData.filter((item: any) =>
      filterFunc(item)
    );
    this.alvesCostaTableTestListData = data.sort((a, b) =>
      this.sortValue === 'ascend'
        ? a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );
  }
  searchTestsImt(type: string): void {
    const filterFunc = (item: any) => {
      const z = item['tag'].toLowerCase();
      const t = '' + item['wrongQuestions'];
      return type === 'tag'
        ? z.indexOf(this.typeValueImt.toLowerCase()) !== -1
        : t.indexOf(this.wrongsValueImt) !== -1;
    };
    const data = this.imtTableTestListData.filter((item: any) =>
      filterFunc(item)
    );
    this.imtTableTestListData = data.sort((a, b) =>
      this.sortValue === 'ascend'
        ? a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );
  }
  searchUsers(type: string): void {
    const filterFunc = (item: any) => {
      const x = item['name'].toLowerCase();
      const y = item['userName'].toLowerCase();
      const w = '' + item['studentNumber'];
      let names = this.nameValue.toLowerCase().split(" ");
      let name = '';

      if(names.length > 1){
        for(let i = 0; i < names.length; i++){
          if(x.indexOf(names[i]) != -1 && x.indexOf(names[i+1]) != -1){
            name = '0';
            }
          }
      }
      else{ 
        if(x.indexOf(names[0]) != -1)
          name = '0';
      }

      return type == 'name' ? name != ''
        : type == 'userName'
          ? y.indexOf(this.userNameValue.toLowerCase()) !== -1
          : w.indexOf(this.studentNumberValue) !== -1;
    };
    const data = this.tableUserData.filter((item: any) => filterFunc(item));
    this.tableUserData = data.sort((a, b) =>
      this.sortValue === 'ascend'
        ? a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );
  }

  searchThemeTaxGlobal(type: string): void {
    const filterFunc = (item: any) => {
      const x = '' + item['name'];
      const y = '' + item['totalQuestionsTheme'];
      const w = '' + item['percentageRightTheme'];
      const z = '' + item['totalRightQuestions'];
      const c = '' + item['totalWrongQuestions'];
      return type == 'name'
        ? x.indexOf('') !== -1
        : type == 'totalQuestionsTheme'
          ? y.indexOf('') !== -1
          : type == 'percentageRightTheme'
            ? w.indexOf('') !== -1 
            : type == 'totalRightQuestions'
              ? z.indexOf('') !== -1 
              : type == 'totalWrongQuestions'
                ? c.indexOf('') !== -1 
                : w.indexOf('') !== -1;
                
    };
    const data = this.tableTaxThemeData.filter((item: any) => filterFunc(item));
    this.tableTaxThemeData = data.sort((a, b) =>
      this.sortValue === 'ascend'
        ? a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );
  }
  searchThemeTaxAlvesCosta(type: string): void {
    const filterFunc = (item: any) => {
      const x = '' + item['name'];
      const y = '' + item['totalQuestionsTheme'];
      const w = '' + item['percentageRightTheme'];
      const z = '' + item['totalRightQuestions'];
      const c = '' + item['totalWrongQuestions'];
      return type == 'name'
        ? x.indexOf('') !== -1
        : type == 'totalQuestionsTheme'
          ? y.indexOf('') !== -1
          : type == 'percentageRightTheme'
            ? w.indexOf('') !== -1 
            : type == 'totalRightQuestions'
              ? z.indexOf('') !== -1 
              : type == 'totalWrongQuestions'
                ? c.indexOf('') !== -1 
                : w.indexOf('') !== -1;
                
    };
    const data = this.alvesCostaTableTaxThemeData.filter((item: any) => filterFunc(item));
    this.alvesCostaTableTaxThemeData = data.sort((a, b) =>
      this.sortValue === 'ascend'
        ? a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );
  }
  searchThemeTaxImt(type: string): void {
    const filterFunc = (item: any) => {
      const x = '' + item['name'];
      const y = '' + item['totalQuestionsTheme'];
      const w = '' + item['percentageRightTheme'];
      const z = '' + item['totalRightQuestions'];
      const c = '' + item['totalWrongQuestions'];
      return type == 'name'
        ? x.indexOf('') !== -1
        : type == 'totalQuestionsTheme'
          ? y.indexOf('') !== -1
          : type == 'percentageRightTheme'
            ? w.indexOf('') !== -1 
            : type == 'totalRightQuestions'
              ? z.indexOf('') !== -1 
              : type == 'totalWrongQuestions'
                ? c.indexOf('') !== -1 
                : w.indexOf('') !== -1;
                
    };
    const data = this.imtTableTaxThemeData.filter((item: any) => filterFunc(item));
    this.imtTableTaxThemeData = data.sort((a, b) =>
      this.sortValue === 'ascend'
        ? a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );
  }


  ngOnInit() {
    this.categoriesForm = this.formBuilder.group({
      categories: ['', Validators.required]
    });
    // Lista de Categorias
    if (this.currentUser.user.profileId === 3) {
      this.categoryProductService
        .getCategoryProductListByUserId(this.currentUser.user.id)
        .subscribe(categories => {
          if (categories.length > 0) {
            this.categories = categories;
            this.selectedCategoryName = this.categories[0]['name'];
            this.categoryService.isCam(this.categories[0]['categoryId']).subscribe(camCheck => {
              this.isCam = camCheck;
              console.log(this.isCam);
            });
          }
        });
    }


    // super.ngOnInit();
  }
  // Events
  public chartClicked({
    event,
    active
  }: {
    event: MouseEvent;
    active: {}[];
  }): void { }
  public chartHovered({
    event,
    active
  }: {
    event: MouseEvent;
    active: {}[];
  }): void { }
  // tslint:disable-next-line: member-ordering

  openDialogUserDashboard(e, i) {
    const row: any = {};

    console.log(this.currentPage * this.pageSizeTableUserData + i);
    console.log(this.tableUserData);

    row.userId = this.tableUserData[this.currentPage * this.pageSizeTableUserData + i].userId;
    row.dashboard = true;
    row.name = this.tableUserData[this.currentPage * this.pageSizeTableUserData + i].name;
    const dialogRef = this.dialog.open(PageDashboardComponent, {
      id: 'dashboard',
      // disableClose: true,
      closeOnNavigation: true,
      width: '85vw',
      height: '90vh',
      maxWidth: '1120px',
      data: row
    });
  }

  openDialogTest(e, i) {
    const row: any = {};
    row.testId = this.tableTestListData[this.currentPage * this.pageSizeTableTestListData + i].testId;
    row.dashboard = true;
    row.name = this.localData.name;
    row.nif = this.localData.nif;
    
    const deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxHeight  = this.utils.isMobileDevice() ? '90vh' : '90vh';



    if (this.isCam == true) {
      const dialogRef = this.dialog.open(ShowcamTestComponent, {
        id: 'camWizard0',
        width: deviceWidth,
        maxWidth: deviceMaxWidth,
        height: 'auto',
        maxHeight: deviceMaxHeight,
        disableClose: true,
        closeOnNavigation: true,
        panelClass: 'custom-modalbox',
        data: row
      });
    }
    else {
      const dialogRef = this.dialog.open(ShowTestComponent, {
        id: 'wizard0',
        width: deviceWidth,
        maxWidth: deviceMaxWidth,
        height: 'auto',
        maxHeight: deviceMaxHeight,
        disableClose: true,
        closeOnNavigation: true,
        panelClass: 'custom-modalbox',
        data: row
      });
    }
  }
  openDialogTestAC(e, i) {
    const row: any = {};
    
    const deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxHeight  = this.utils.isMobileDevice() ? '90vh' : '90vh';
    
    row.testId = this.alvesCostaTableTestListData[this.currentPage * this.pageSizeAlvesCostaTableTestListData + i].testId;
    row.name = this.localData.name;
    row.nif = this.localData.nif;
    row.dashboard = true;

    if (this.isCam == true) {
      const dialogRef = this.dialog.open(ShowcamTestComponent, {
        id: 'camWizard1',
        width: deviceWidth,
        maxWidth: deviceMaxWidth,
        height: 'auto',
        maxHeight: deviceMaxHeight,
        disableClose: true,
        closeOnNavigation: true,
        panelClass: 'custom-modalbox',
        data: row
      });
    }
    else {
      const dialogRef = this.dialog.open(ShowTestComponent, {
        id: 'wizard1',
        width: deviceWidth,
        maxWidth: deviceMaxWidth,
        height: 'auto',
        maxHeight: deviceMaxHeight,
        disableClose: true,
        closeOnNavigation: true,
        panelClass: 'custom-modalbox',
        data: row
      });
    }
  }
  openDialogTestImt(e, i) {
    const row: any = {};
    row.testId = this.imtTableTestListData[(this.currentPage * this.pageSizeImtTableTestListData + i)].testId;
    row.name = this.localData.name;
    row.nif = this.localData.nif;
    row.dashboard = true;

    
    const deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxHeight  = this.utils.isMobileDevice() ? '90vh' : '90vh';

    if (this.isCam == true) {
      const dialogRef = this.dialog.open(ShowcamTestComponent, {
        id: 'camWizard2',
        width: deviceWidth,
        maxWidth: deviceMaxWidth,
        height: 'auto',
        maxHeight: deviceMaxHeight,
        disableClose: true,
        closeOnNavigation: true,
        panelClass: 'custom-modalbox',
        data: row
      });
    }
    else {
      const dialogRef = this.dialog.open(ShowTestComponent, {
        id: 'wizard2',
        width: deviceWidth,
        maxWidth: deviceMaxWidth,
        height: 'auto',
        maxHeight: deviceMaxHeight,
        disableClose: true,
        closeOnNavigation: true,
        panelClass: 'custom-modalbox',
        data: row
      });
    }
  }
  openDialogQuestion(e, i) {
    const row: any = {};
    row.testId = this.tableQuestionListData[this.currentPage * this.pageSizeTableQuestionListData + i].testId;
    row.questionId = this.tableQuestionListData[this.currentPage * this.pageSizeTableQuestionListData + i].questionId;
    row.name = this.localData.name;
    row.nif = this.localData.nif;
    row.dashboard = true;

    const deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxHeight  = this.utils.isMobileDevice() ? '90vh' : '90vh';

    if (this.isCam == true) {
      const dialogRef = this.dialog.open(ShowcamTestComponent, {
        id: 'camWizard3',
        width: deviceWidth,
        maxWidth: deviceMaxWidth,
        height: 'auto',
        maxHeight: deviceMaxHeight,
        disableClose: true,
        closeOnNavigation: true,
        panelClass: 'custom-modalbox',
        data: row
      });
    }
    else {
      const dialogRef = this.dialog.open(ShowTestComponent, {
        id: 'wizard3',
        width: deviceWidth,
        maxWidth: deviceMaxWidth,
        height: 'auto',
        maxHeight: deviceMaxHeight,
        disableClose: true,
        closeOnNavigation: true,
        panelClass: 'custom-modalbox',
        data: row
      });
    }
  }

  openDialogQuestionAC(e, i) {
    const row: any = {};
    
    const deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxHeight  = this.utils.isMobileDevice() ? '90vh' : '90vh';

    row.testId = this.alvesCostaTableQuestionListData[this.currentPage * this.pageSizeAlvesCostaTableQuestionListData + i].testId;
    row.questionId = this.alvesCostaTableQuestionListData[this.currentPage * this.pageSizeAlvesCostaTableQuestionListData + i].questionId;
    row.dashboard = true;
    row.name = this.localData.name;
    row.nif = this.localData.nif;



    if (this.isCam == true) {
      const dialogRef = this.dialog.open(ShowcamTestComponent, {
        id: 'camWizard4',
        width: deviceWidth,
        maxWidth: deviceMaxWidth,
        height: 'auto',
        maxHeight: deviceMaxHeight,
        disableClose: true,
        closeOnNavigation: true,
        panelClass: 'custom-modalbox',
        data: row
      });
    }
    else {
      const dialogRef = this.dialog.open(ShowTestComponent, {
        id: 'wizard4',
        width: deviceWidth,
        maxWidth: deviceMaxWidth,
        height: 'auto',
        maxHeight: deviceMaxHeight,
        disableClose: true,
        closeOnNavigation: true,
        panelClass: 'custom-modalbox',
        data: row
      });
    }
  }

  openDialogQuestionImt(e, i) {
    const row: any = {};
    
    const deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxHeight  = this.utils.isMobileDevice() ? '90vh' : '90vh';

    row.testId = this.imtTableQuestionListData[this.currentPage * this.pageSizeImtTableQuestionListData + i].testId;
    row.questionId = this.imtTableQuestionListData[this.currentPage * this.pageSizeImtTableQuestionListData + i].questionId;
    row.dashboard = true;
    row.name = this.localData.name;
    row.nif = this.localData.nif;

    if (this.isCam == true) {
      const dialogRef = this.dialog.open(ShowcamTestComponent, {
        id: 'camWizard5',
        width: deviceWidth,
        maxWidth: deviceMaxWidth,
        height: 'auto',
        maxHeight: deviceMaxHeight,
        disableClose: true,
        closeOnNavigation: true,
        panelClass: 'custom-modalbox',
        data: row
      });
    }
    else {
      const dialogRef = this.dialog.open(ShowTestComponent, {
        id: 'wizard5',
        width: deviceWidth,
        maxWidth: deviceMaxWidth,
        height: 'auto',
        maxHeight: deviceMaxHeight,
        disableClose: true,
        closeOnNavigation: true,
        panelClass: 'custom-modalbox',
        data: row
      });
    }
  }

  closeUserDashboard() {
    this.dialog.closeAll();
    // this.dialog.closeAll().subscribe(x => {
    //   this.dialog = null;
    // location.reload(true);
  }

  openedQuestions = false;
  openFilterQuestions() {
    const el = document.getElementById('filtroQuestions');
    if (this.openedQuestions == false) {
      el.classList.add('show');
      el.classList.remove('hide');
      this.openedQuestions = true;
    } else {
      el.classList.add('hide');
      el.classList.remove('show');
      this.openedQuestions = false;
    }
  }
  openedQuestionsAlvesCosta = false;
  openFilterQuestionsAlvesCosta() {
    const el = document.getElementById('filterQuestionsAlvesCosta');
    if (this.openedQuestionsAlvesCosta == false) {
      el.classList.add('show');
      el.classList.remove('hide');
      this.openedQuestionsAlvesCosta = true;
    } else {
      el.classList.add('hide');
      el.classList.remove('show');
      this.openedQuestionsAlvesCosta = false;
    }
  }
  openedQuestionsImt = false;
  openFilterQuestionsImt() {
    const el = document.getElementById('filterQuestionsImt');
    if (this.openedQuestionsImt == false) {
      el.classList.add('show');
      el.classList.remove('hide');
      this.openedQuestionsImt = true;
    } else {
      el.classList.add('hide');
      el.classList.remove('show');
      this.openedQuestionsImt = false;
    }
  }
  openedTests = false;
  openFilterTests() {
    const el = document.getElementById('filtroTests');
    if (this.openedTests == false) {
      el.classList.add('show');
      el.classList.remove('hide');
      this.openedTests = true;
    } else {
      el.classList.add('hide');
      el.classList.remove('show');
      this.openedTests = false;
    }
  }
  openedTestsAlvesCosta = false;
  openFilterTestsAlvesCosta() {
    const el = document.getElementById('filterTestsAlvesCosta');
    if (this.openedTestsAlvesCosta == false) {
      el.classList.add('show');
      el.classList.remove('hide');
      this.openedTestsAlvesCosta = true;
    } else {
      el.classList.add('hide');
      el.classList.remove('show');
      this.openedTestsAlvesCosta = false;
    }
  }
  openedTestsImt = false;
  openFilterTestsImt() {
    const el = document.getElementById('filterTestsImt');
    if (this.openedTestsImt == false) {
      el.classList.add('show');
      el.classList.remove('hide');
      this.openedTestsImt = true;
    } else {
      el.classList.add('hide');
      el.classList.remove('show');
      this.openedTestsImt = false;
    }
  }
  openedUsers = false;
  openFilterUsers() {
    const el = document.getElementById('filterUsers');
    if (this.openedUsers == false) {
      el.classList.add('show');
      el.classList.remove('hide');
      this.openedUsers = true;
    } else {
      el.classList.add('hide');
      el.classList.remove('show');
      this.openedUsers = false;
    }
  }

  filterQuestionTable() {
    this.tableQuestionListData = this.listQuestions;
    if (this.idValue != '' && this.idValue != null) {
      this.searchQuestions('oldQuestionId');
    }
    if (this.fontValue != '') {
      this.searchQuestions('font');
    }
    if (this.descriptionValue != '') {
      this.searchQuestions('description');
    }
    if (this.wrongQuestionsValue != '') {
      this.searchQuestions('wrongQuestions');
    }
  }
  filterQuestionTableAlvesCosta() {
    this.alvesCostaTableQuestionListData = this.listQuestionsAlvesCosta;
    if (this.idValueAC != '' && this.idValueAC != null) {
      this.searchQuestionsAlvesCosta('oldQuestionId');
    }
    if (this.descriptionValueAC != '') {
      this.searchQuestionsAlvesCosta('description');
    }
    if (this.wrongQuestionsValueAC != '') {
      this.searchQuestionsAlvesCosta('wrongQuestions');
    }
  }
  filterQuestionTableImt() {
    this.imtTableQuestionListData = this.listQuestionsImt;
    if (this.idValueImt != '' && this.idValueImt != null) {
      this.searchQuestionsImt('oldQuestionId');
    }
    if (this.descriptionValueImt != '') {
      this.searchQuestionsImt('description');
    }
    if (this.wrongQuestionsValueImt != '') {
      this.searchQuestionsImt('wrongQuestions');
    }
  }
  filterTestTable() {
    this.tableTestListData = this.listTest;
    if (this.fontValueTest != '') {
      this.searchTests('font');
    }
    if (this.typeValue != '' && this.typeValue != ' ') {
      this.searchTests('tag');
    }
    if (this.wrongsValue != '') {
      this.searchTests('wrongQuestions');
    }
  }
  filterTestTableAlvesCosta() {
    this.alvesCostaTableTestListData = this.listTestAlvesCosta;
    if (this.typeValueAC != '' && this.typeValueAC != '  ') {
      this.searchTestsAlvesCosta('tag');
    }
    if (this.wrongsValueAC != '') {
      this.searchTestsAlvesCosta('wrongQuestions');
    }
  }
  filterTestTableImt() {
    this.imtTableTestListData = this.listTestImt;
    // tslint:disable-next-line: triple-equals
    if (this.typeValueImt != '' && this.typeValueImt != '  ') {
      this.searchTestsImt('tag');
    }
    // tslint:disable-next-line: triple-equals
    if (this.wrongsValueImt != '') {
      this.searchTestsImt('wrongQuestions');
    }
  }
  filterUserTable() {
    this.tableUserData = this.listUser;
    // tslint:disable-next-line: triple-equals
    if (this.nameValue != '') {
      this.searchUsers('name');
    }
    // tslint:disable-next-line: triple-equals
    if (this.userNameValue != '') {
      this.searchUsers('userName');
    }
    // tslint:disable-next-line: triple-equals
    if (this.studentNumberValue != '' && this.studentNumberValue != null) {
      this.searchUsers('studentNumber');
    }
  }

  ngOnDestroy() {
    // super.ngOnDestroy();
    console.log("componentDestroyed");
    this.componentDestroyed.next(true);
    this.componentDestroyed.complete();
    console.log(this.componentDestroyed.isStopped);
  }

  openCity(evt, cityName, cityTab) {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("dashTab");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("active", "");
    }
      document.getElementById(cityName).style.display = "block";
      document.getElementById(cityName).classList.add("active");
      document.getElementById(cityTab).classList.add("active"); 
    }

  isMobile(): boolean {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  }

 charsLenToByPass(): number
  {
    if(this.categorySelected == 14 || this.categorySelected == 15 || this.categorySelected == 16 || this.categorySelected == 17 || this.categorySelected == 18 || this.categorySelected == 25 || this.categorySelected == 54)
      return 4;
    return 3;
  }

  isIMT(): boolean {
    /*console.log('isIMT()');
    console.log(this.currentUser.user);*/
    return this.currentUser.user.profileId === 8;
  };

  getChartOptions(): ChartOptions {
    if(this.isMobile())
      return this.chartOptionsMobile;
    else 
      return this.chartOptionsDefault;
  };
}
