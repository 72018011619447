<div *ngIf="!utils.isMobileDevice()" class="footer-wrap">
  <div class="row align-items-center">
    <div class="col-3"></div>
    <div class="col-6 info" style="text-align: center;">
      &#169; 2023 <a href="{{url}}" target="_blank">AlvesCosta. </a> Created by <a href="http://www.graycell.pt"
        target="_blank">graycell. </a><label>version:
        {{version}}</label>
    </div>
    <div class="col-3 info" style="text-align: center;"><a (click)="open()">Termos e
        Condições</a></div>
  </div>

  <div class="footer-skeleton">
    <div class="row align-items-center">
      <div class="col-12 col-md-6 d-md-block">
        <ul class="p-0 page-breadcrumbs">
          <li class="item bg animated-bg"></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div *ngIf="utils.isMobileDevice()" class="footer-wrap">
  <div class="row">
    <div class="col-9 info text-left">
      &#169; 2023 <a href="{{url}}" target="_blank">AlvesCosta. </a> Created by <a href="http://www.graycell.pt"
        target="_blank">graycell. </a><label>version: {{version}}</label>
    </div>
    <div class="col-3 info text-right">
      <a (click)="open()">Termos e Condições</a></div>
  </div>

  <div class="footer-skeleton">
    <div class="row align-items-center">
      <div class="col-12 col-md-6 d-md-block">
        <ul class="p-0 page-breadcrumbs">
          <li class="item bg animated-bg"></li>
        </ul>
      </div>
    </div>
  </div>
</div>
