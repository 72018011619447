import { Reset } from './../../../store/actions/page.actions';
import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';

import { AlertMailService } from '../../../services/alert-mail.service';
import { AuthService } from '../../../services/auth.service';
import { BasePageComponent } from '../../base-page';
import { Company } from '../../../models/Company';
import { CompanyService } from '../../../services/company.service';
import { DialogBoxTeacherEmailComponent } from '../DialogBoxes/dialog-box-teacher-email/dialog-box-teacher-email.component';
import { HttpService } from '../../../services/http/http.service';
import { IAppState } from '../../../interfaces/app-state';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../../models/user';
import { Utils } from 'src/utils/utils';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-student-requests',
  templateUrl: './student-requests.component.html',
  styleUrls: ['./student-requests.component.scss']
})
export class StudentRequestsComponent extends BasePageComponent implements OnInit, OnDestroy {
  currentUser: User;
  mails: any[] = [];
  listmails: any[] = [];
  closeDropdown: EventEmitter<boolean>;
  schools: Company[];
  inputValue: string = '';
  inputFilter: string = '';
  sortName: string | null = null;
  sortValue: string | null = null;
  @Input() layout: string;
  constructor(store: Store<IAppState>, public dialog: MatDialog, private authenticationService: AuthService, private router: Router,
    httpSv: HttpService, private toastr: ToastrService, private alertMailService: AlertMailService, private companyService: CompanyService,
    public utils: Utils) {
    super(store, httpSv);
    this.getData(`${environment.apiUrl}/AlertMail`, 'tabledata', 'setLoaded');
    this.closeDropdown = new EventEmitter<boolean>();
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.alertMailService.getRequestsByProfile(this.currentUser.user.companyId, this.currentUser.user.profileId).subscribe(c => {
      this.mails = c;
      this.listmails = this.mails;
    });
    this.companyService.getAll().subscribe(company => {
      this.schools = company;
    });
    this.pageData = {
      title: 'Mensagens',
      loaded: true
    };
  }

  openDialog(id, alertMailId, responded, response, question) {
    let row: any = {};
    row.userId = id;
    row.alertMailId = alertMailId;
    row.responded = responded;
    row.response = response;
    row.question = question;
    let width = this.utils.isMobileDevice() == true ? '95vw' : '50vw';
    let height = this.utils.isMobileDevice() == true ? '80vh' : 'auto';
    const dialogRef = this.dialog.open(DialogBoxTeacherEmailComponent, {
      disableClose: true,
      height: height,
      width: width,
      maxWidth: width,
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      
      if (result === undefined) { }
      else if (result.event == 'Send') {
        if(result.data.body)
          this.sendEmail(result.data);
      }
    });
  }

   filterSuportTableByUser() {
    this.mails = this.listmails;
    // tslint:disable-next-line: triple-equals
    if (this.inputValue != '') {
      this.searchMails('userName');
    }
  }

  filterSuportTable(a) {
    this.mails = this.listmails;
    // tslint:disable-next-line: triple-equals
    if (a != ''){
      this.inputFilter = a;
      this.searchMails('responded')
    }
  }

  searchMails(type: string): void {
    const filterFunc = (item: any) => {
      const x = '' + item['userName'].toLowerCase();
      const y = '' + item['responded'].toString();
      return type == 'userName' ? x.indexOf(this.inputValue.toLowerCase()) !== -1 : y.indexOf(this.inputFilter) !== -1;
    };
    const data = this.mails.filter((item: any) => filterFunc(item));
    this.mails = data.sort((a, b) =>
      this.sortValue === 'ascend'
        ? a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );
  }
  
  reset(): void{
    this.inputValue = '';
    this.inputFilter = '';
    
    
    this.searchMails('');
    this.mails = this.listmails;


  }

  ngOnInit() {
    super.ngOnInit();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  sendEmail(rowObj) {
    this.alertMailService.sendTeacherRequest(rowObj.userId, rowObj.alertMailId, rowObj.subject, rowObj.body).subscribe( c => {
        this.alertMailService.getRequestsByProfile(this.currentUser.user.companyId, this.currentUser.user.profileId).subscribe(c => {
          this.mails = c;
        });
        super.sendMessage();
        this.toastr.success('Resposta enviado com sucesso!');
    });
  }

  onCloseDropdown() {
    this.closeDropdown.emit(true);
  }
  
  show(type: string, param: string) {
    // console.log(type);
    // console.log(param);
    const cards = document.querySelectorAll('tc-card');
    cards.forEach(elem => {
      elem.classList.remove('hide');
    });
    if (type == 'show') {
      if (param == 'all') {
        cards.forEach(elem => {
          elem.classList.remove('hide');
        });
      } else {
        cards.forEach(elem => {
          if (elem.className.match(param)) {
            elem.classList.remove('hide');
          }
          else {
            elem.classList.add('hide');
          }
        });
      }
    } else if (type == 'bySchool') {
      cards.forEach(elem => {
        if (elem.className.match(param.replace(' ', ''))) {
          elem.classList.remove('hide');
        }
        else {
          elem.classList.add('hide');
        }
      });
    }
    this.onCloseDropdown();
  }
  
  filter(type: string, param: string) {
    const cards = document.querySelectorAll('tc-card');
    cards.forEach(elem => {
      elem.classList.remove('hide');
    });
    cards.forEach(elem => {
      if (elem.className.match(param.replace(' ', '')) && elem.className.match(type)) {
        elem.classList.remove('hide');
      }
      else {
        elem.classList.add('hide');
      }
    });
    this.onCloseDropdown();
  }

  isAdmin() {
    // console.log(this.currentUser.user.role);
    return (this.currentUser.user.role == 'Administrador' ? true : false);
  }
  opened = false;
  openFilter() {

    const el = document.getElementById('filtro');
    if (this.opened == false) {
      el.classList.add('show');
      el.classList.remove('hide');
      this.opened = true;
    }
    else {
      el.classList.add('hide');
      el.classList.remove('show');
      this.opened = false;
    }
  }
}


@Pipe({
  name: 'trim'
})
export class TrimPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    console.log(value + ':' + value.toString().trim());
    return value.toString().replace(' ', '');
  }

}
