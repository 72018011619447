var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute, Router } from '@angular/router';
import { OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../../base-page';
import { CamWizardComponent } from '../camWizard/camWizard.component';
import { CategoryService } from '../../../../services/category.service';
import { HttpService } from '../../../../services/http/http.service';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { Utils } from 'src/utils/utils';
import { WizardComponent } from '../wizard/wizard.component';
import { environment } from '../../../../../environments/environment';
var TestTypeComponent = /** @class */ (function (_super) {
    __extends(TestTypeComponent, _super);
    function TestTypeComponent(route, dialog, locations, categoryService, router, store, httpSv, utils) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.route = route;
        _this.dialog = dialog;
        _this.locations = locations;
        _this.categoryService = categoryService;
        _this.router = router;
        _this.utils = utils;
        _this.data = {};
        _this.category = _this.route.snapshot.params['id'];
        _this.font = _this.route.snapshot.params['font'];
        _this.getData(environment.apiUrl + "/Product/", 'tableData', 'setLoaded');
        _this.categoryService.isCam(_this.category).subscribe(function (camCheck) {
            _this.isCam = camCheck;
        });
        _this.pageData = {
            title: 'Realizar teste',
            loaded: true
        };
        return _this;
    }
    TestTypeComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    TestTypeComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    TestTypeComponent.prototype.select = function (e, id) {
        console.log(navigator.platform);
        var deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
        var deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
        var deviceMaxHeight = this.utils.isMobileDevice() ? '90vh' : '90vh';
        this.activeElement = id;
        this.data = { 'type': e.target.innerHTML, 'id': this.category, 'themes': [], 'font': this.font, 'origin': 'test' };
        if (e.target.innerHTML == 'Temático') {
            this.router.navigate(['/app/Tests/Themes/' + this.font + '/' + this.category]);
        }
        else {
            if (this.isCam == true) {
                var dialogRef = this.dialog.open(CamWizardComponent, {
                    id: 'camWizard',
                    width: deviceWidth,
                    maxWidth: deviceMaxWidth,
                    height: 'auto',
                    maxHeight: deviceMaxHeight,
                    disableClose: true,
                    closeOnNavigation: true,
                    data: this.data,
                    panelClass: 'custom-modalbox'
                });
            }
            else {
                var dialogRef = this.dialog.open(WizardComponent, {
                    id: 'wizard',
                    width: deviceWidth,
                    maxWidth: deviceMaxWidth,
                    height: 'auto',
                    maxHeight: deviceMaxHeight,
                    disableClose: true,
                    closeOnNavigation: true,
                    data: this.data,
                    panelClass: 'custom-modalbox'
                });
            }
        }
    };
    TestTypeComponent.prototype.back = function () {
        this.locations.back();
    };
    TestTypeComponent.prototype.isDeviceiPad = function () {
        return navigator.platform.match(/iPad/i);
    };
    return TestTypeComponent;
}(BasePageComponent));
export { TestTypeComponent };
