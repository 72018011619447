var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../../services/http/http.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';
import { BasePageComponent } from '../../../base-page';
import { UserService } from '../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/internal/operators/first';
var ChangePasswordComponent = /** @class */ (function (_super) {
    __extends(ChangePasswordComponent, _super);
    function ChangePasswordComponent(store, httpSv, formBuilder, toastr, authenticationService, userService) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.formBuilder = formBuilder;
        _this.toastr = toastr;
        _this.authenticationService = authenticationService;
        _this.userService = userService;
        _this.changes = false;
        _this.initPasswordForm();
        _this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        _this.pageData = {
            title: 'Mudar Senha',
            loaded: true
        };
        return _this;
    }
    ChangePasswordComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.setLoaded();
    };
    ChangePasswordComponent.prototype.initPasswordForm = function () {
        var _this = this;
        this.passwordForm = this.formBuilder.group({
            current: ['', Validators.required],
            new: ['', Validators.required],
            again: ['', Validators.required,],
        });
        // detect form changes
        this.passwordForm.valueChanges.subscribe(function () {
            _this.changes = true;
        });
    };
    ChangePasswordComponent.prototype.savePasswordData = function (form) {
        var _this = this;
        if (form.valid) {
            this.changes = false;
            this.userService.updatePassword(form.value['new'], this.currentUser.user.id).pipe(first()).subscribe(function (data) {
                _this.toastr.success("Password Alterada com sucesso");
                _this.passwordForm.reset();
            });
        }
    };
    ChangePasswordComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return ChangePasswordComponent;
}(BasePageComponent));
export { ChangePasswordComponent };
