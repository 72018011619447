import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
  
export class Utils {
    
    isMobileDevice(): boolean {
        if(window.innerWidth >= 992)
            return false;

        if(window.innerWidth <= 991)
            return true;

        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };


    transform(value: string): string {
        return value.replace(/\n/g, '<br/>');
    }


    time2Seconds(value: string): number {
        var arr = value.split(":"); 
        //console.log(arr);

        var seconds = Number(arr[0]) * 3600 + Number(arr[1]) * 60 + (+Number(arr[2])); 
        
        return seconds;
    } 
}