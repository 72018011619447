import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  constructor(private http: HttpClient) { }

  createTest(userId: number, name: string ) {
    return this.http.post<any>(`${environment.apiUrl}/Test/${userId}`, {userId });
  }
  getTestsByUserId(userId: number){
    return this.http.get<any>(`${environment.apiUrl}/Test/${userId}`);
  }
  getQuestionsByTestId(testId: number){
    return this.http.get<any>(`${environment.apiUrl}/Test/question/${testId}`);
  }
  getTotalQuestionsByFontId(id: number, categoryProductId: number, font: string) {
    return this.http.get<any[]>(`${environment.apiUrl}/Test/TotalQuestionsByFontId/${id}/${categoryProductId}/${font}`); 
  }
  getTotalQuestionsByUserId(id: number){
    return this.http.get<any[]>(`${environment.apiUrl}/Test/TotalQuestions/${id}`);
  } 
  getTypeByTestId(id: number){
    return this.http.get<any[]>(`${environment.apiUrl}/Test/category/${id}`);
  }

  getGlobalRatingByUserId(id: number, categoryProductId: number, font: string){
    return this.http.get<any[]>(`${environment.apiUrl}/Test/GlobalRatingTax/${id}/${categoryProductId}/${font}`);
  }

  getTaxThemeByUserId(id: number, categoryProductId: number, font: string){
    return this.http.get<any[]>(`${environment.apiUrl}/Test/TaxThemeByUserId/${id}/${categoryProductId}/${font}`);
  }
  getTestListByUserId(id: number, categoryProductId: number, font: string) {
    return this.http.get<any[]>(`${environment.apiUrl}/Test/TestListByUserId/${id}/${categoryProductId}/${font}`); 
  }

  getElearningTestsByUserId(userELearningId: number){
    return this.http.get<any>(`${environment.apiUrl}/eLearningTest/${userELearningId}`);
  }

  getSuccessTaxByUserId(id: number, categoryProductId: number, font: string) {
    return this.http.get<any[]>(`${environment.apiUrl}/Test/SuccessTaxByUserId/${id}/${categoryProductId}/${font}`); 
  }
}
