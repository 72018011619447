import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { AuthService } from '../../../../services/auth.service';
import { BasePageComponent } from '../../../base-page';
import { Company } from '../../../../models/company';
import { CompanyService } from '../../../../services/company.service';
import { CompanyTypeService } from '../../../../services/company-type.service';
import { ConfirmComponent } from '../../DialogBoxes/confirm/confirm.component';
import { DialogBoxCompaniesComponent } from '../../DialogBoxes/dialog-box-companies/dialog-box-companies.component';
import { HttpService } from '../../../../services/http/http.service';
import { IAppState } from '../../../../interfaces/app-state';
import { MatDialog } from '@angular/material';
import { TicketService } from '../../../../services/ticket.service';
import { ToastrService } from 'ngx-toastr';
import { Utils } from 'src/utils/utils';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent extends BasePageComponent implements OnInit, OnDestroy {
  bordered = true;
  position = 'bottom';
  pageSize = 10;
  listOfDisplayData: Company[] = [];
  listOfAllData: Company[] = [];
  list: Company[] = [];
  sortName: string | null = null;
  sortValue: string | null = null;
  mapOfSort: { [key: string]: string | null } = {
    name: null,
    email: null,
    comercial: null,
    companyTypeName: null,
    plataformName: null,
    active: null
  };
  nameValue = '';
  emailValue = '';
  typeValue = '';
  plataformValue = '';
  comercialValue = '';
  currentUser;
  public isAllDisplayDataChecked = false;
  public isIndeterminate = false;
  public numberOfChecked = 0;
  public mapOfCheckedId: { [key: string]: boolean } = {};
  public currentPage = 0;
  public prev_index: string = '0';

  constructor(private companyService: CompanyService, public dialog: MatDialog,
    private ticketService: TicketService, private companyTypeService: CompanyTypeService, private authenticationService: AuthService,
    store: Store<IAppState>,
    httpSv: HttpService,
    private toastr: ToastrService,
    public utils: Utils) {
    super(store, httpSv);
    this.getData(`${environment.apiUrl}/Company/`, 'tableData', 'setLoaded');
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    if (this.currentUser.user.profileId == 5) {
      this.companyService.getCompaniesOfComercial(this.currentUser.user.id).pipe(first()).subscribe(companies => {
        this.listOfAllData = companies;
        // this.listOfAllData.forEach(element => {
        //   this.companyTypeService.getById(element.companyTypeId).subscribe(companyType => {
        //     element.companyTypeName = companyType.name
        //   })
        // });
        this.list = this.listOfAllData;
      });
    } else {
      this.companyService.getAll().pipe(first()).subscribe(companies => {
        this.listOfAllData = companies;
        console.log(companies);
        // this.listOfAllData.forEach(element => {
        //   this.companyTypeService.getById(element.companyTypeId).subscribe(companyType => {
        //     element.companyTypeName = companyType.name
        //   })
        // });
        this.list = this.listOfAllData;
      });

    }
  }

  currentPageDataChange($event: Company[]): void {
    this.listOfDisplayData = $event;
  }
  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.search('');

    if(this.sortValue == '' || this.sortValue == null){
      this.sortName = 'name'
      this.sortValue = 'ascend'
      this.filter();
    }
  }
  reset(): void {
    this.nameValue = '';
    this.emailValue = '';
    this.typeValue = '';
    this.plataformValue = '';
    this.comercialValue = '';
    this.search('');
    this.listOfAllData = this.list;

    this.mapOfSort = {
      name: null,
      email: null,
      comercial: null,
      companyTypeName: null,
      plataformName: null,
      active: null
    };
  }

  /**
   * @param event
   * @param index
   */
  public select(event, index) {
    document.getElementById(this.prev_index).classList.remove('active');
    document.getElementById(index).classList.add('active');
    this.prev_index = index
  }

  public removeSelectedRows() {
    let row: any = {};
    row.text = 'Confirma a remoção da(s) empresa(s) selecionada(s)?'
    let dialogRef = this.dialog.open(ConfirmComponent, {
      id: 'confirm',
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) { }
      else if (result.event == 'sim') {
        this.listOfAllData.forEach(
          item => this.mapOfCheckedId[item['companyId']] == true ? this.removeById(item['companyId']) : ""
        );
      }
    });
  }

  public openDialog(action, e, i) {
    if (e == null || !e.target.className.match('ant-table-column-sorters') && !e.target.className.match('ant-spin-container')) {
      let row: any = {};
      row = e == null ? {} : this.listOfAllData[(this.currentPage * this.pageSize + i)];
      row.action = action;
      /** TODO:remove is deprecate...
      var path = e == null ? '' : e.path || (e.composedPath && e.composedPath());
      let row: any = {};
      row = e == null ? {} : this.listOfAllData[this.listOfAllData.findIndex(d => d['name'].trim() === path[1].children[0].innerText)];
      row.action = action;
      */

     const deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
     const deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '850px';
     const deviceMaxHeight  = this.utils.isMobileDevice() ? '90vh' : '90vh';

      const dialogRef = this.dialog.open(DialogBoxCompaniesComponent, {
        width: deviceWidth,
        maxWidth: deviceMaxWidth,
        height: deviceMaxHeight,
        maxHeight: deviceMaxHeight,
        data: row,
        disableClose: true,
        closeOnNavigation: true
      });

      dialogRef.afterClosed().subscribe(result => {
        this.dialog.closeAll();
        if (result === undefined) { }
        else if (result.event == 'AdicionarGeral') {
          this.addRowData(result.data);
        } else if (result.event == 'ModificarGeral') {
          this.updateRowData(result.data, result.submitType);
        } else if (result.event == 'Tickets') {
          this.updateTicketData(result.data);
        }
      });
    }
  }

  addRowData(rowObj) {
    this.companyService.createCompany('', rowObj.name, rowObj.email,
      rowObj.nif, rowObj.contacts, rowObj.companyTypeId, rowObj.address, rowObj.active,
      rowObj.plataform, rowObj.prefix, rowObj.alvara, rowObj.comercialId).subscribe((data) => {
        this.getData(`${environment.apiUrl}/Company/`, 'tableData', 'setLoaded');
        if (this.currentUser.user.profileId == 5) {
          this.companyService.getCompaniesOfComercial(this.currentUser.user.id).pipe(first()).subscribe(companies => {
            this.listOfAllData = companies;
            // this.listOfAllData.forEach(element => {
            //   this.companyTypeService.getById(element.companyTypeId).subscribe(companyType => {
            //     element.companyTypeName = companyType.name
            //   })
            // });
            this.list = this.listOfAllData;
            this.toastr.success('Empresa criada com sucesso!');
          });
        } else {
          this.companyService.getAll().pipe(first()).subscribe(company => {
            this.listOfAllData = company;
            // // this.listOfAllData.forEach(element => {
            // //   this.companyTypeService.getById(element.companyTypeId).subscribe(companyType => {
            // //     element.companyTypeName = companyType.name;
            // //   });
            // });
            this.list = this.listOfAllData;
          });
          this.toastr.success('Empresa criada com sucesso!');
        }
      },
        err => {
          this.toastr.error('Algo ocorreu mal!');
        });
  }

  updateRowData(rowObj, type) {
    console.log(rowObj);
    if (type == 'geral') {
      this.companyService.updateCompany(rowObj['companyId'], '', rowObj.name, rowObj.email,
        rowObj.nif, rowObj.contacts, rowObj.companyTypeId, rowObj.address, rowObj.active,
        rowObj.plataform, rowObj.prefix, rowObj.alvara, rowObj.url, rowObj.comercialId, rowObj.emailInstrutores).subscribe((data) => {
          this.getData(`${environment.apiUrl}/Company/`, 'tableData', 'setLoaded');
          if (this.currentUser.user.profileId == 5) {
            this.companyService.getCompaniesOfComercial(this.currentUser.user.id).pipe(first()).subscribe(companies => {
              this.listOfAllData = companies;
              // this.listOfAllData.forEach(element => {
              //   this.companyTypeService.getById(element.companyTypeId).subscribe(companyType => {
              //     element.companyTypeName = companyType.name
              //   })
              // });
              this.list = this.listOfAllData;
              this.toastr.success('Empresa modificada com sucesso!');
            });
          } else {
            this.companyService.getAll().pipe(first()).subscribe(company => {
              this.listOfAllData = company;
              // this.listOfAllData.forEach(element => {
              //   this.companyTypeService.getById(element.companyTypeId).subscribe(companyType => {
              //     element.companyTypeName = companyType.name;
              //   });
              // });
              this.list = this.listOfAllData;
            });
            this.toastr.success('Empresa modificada com sucesso!');
          }
        },
          err => {
            this.toastr.error('Algo ocorreu mal!');
          });
    } else {
      let productCategoriesId: number[] = [];
      let didacticMaterialsId: number[] = [];

      for (let i = 0; i < rowObj.products.length; i++) {
        if (rowObj.products[i].children != null) {
          for (let j = 0; j < rowObj.products[i].children.length; j++) {
            if (rowObj.products[i].children[j].selected == true) {
              if (rowObj.products[i].children[j].categoryProductId == undefined) {
                didacticMaterialsId.push(rowObj.products[i].children[j].didacticMaterialId);
              }
              else if (rowObj.products[i].children[j].didacticMaterialId == undefined) {
                productCategoriesId.push(rowObj.products[i].children[j].categoryProductId);
              }
            }
          }
        }
        else {
          if (rowObj.products[i].selected == true) {
            if (rowObj.products[i].categoryProductId == undefined) {
              didacticMaterialsId.push(rowObj.products[i].didacticMaterialId);
            }
            else {
              productCategoriesId.push(rowObj.products[i].categoryProductId);
            }
          }
        }
      }
      this.ticketService.ticketByCategoryId(rowObj.companyId, productCategoriesId, didacticMaterialsId, rowObj.durationId, rowObj.quantity, rowObj.createdBy).subscribe((data) => {
        this.toastr.success('Senhas atribuídas com sucesso!');
      },
        err => {
          this.toastr.error('Algo ocorreu mal!');
        }
      );
    }
  }

  updateTicketData(rowObj) {

  }
  ngOnInit() {
    //super.ngOnInit();
  }
  ngOnDestroy() {
    //  super.ngOnDestroy();
  }

  opened = false;
  openFilter() {
    const el = document.getElementById('filtro');
    if (this.opened == false) {
      el.classList.add('show');
      el.classList.remove('hide');
      this.opened = true;
    }
    else {
      el.classList.add('hide');
      el.classList.remove('show');
      this.opened = false;
    }
  }
  filter() {
    this.listOfAllData = this.list;
    if (this.nameValue != '') { this.search('name'); }
    if (this.emailValue != '') { this.search('email'); }
    if (this.typeValue != '') { this.search('companyType'); }
    if (this.plataformValue != '') { this.search('plataformName'); }
    if (this.comercialValue != '') { this.search('comercialUser'); }

  }
  search(type: string): void {
    const filterFunc = (item: Company) => {
      let x = '' + item['name'].toLowerCase();
      let y = item['email'].toLowerCase();
      let z = item['companyType'].toLowerCase();
      let w = item['plataformName'].toLowerCase();
      let k = item['comercialUser'].toLowerCase();
      let names = this.nameValue.toLowerCase().split(" ");
      let name = '';

      //Search names
      if(names.length > 1){
        for(let i = 0; i < names.length; i++){
          if(x.indexOf(names[i]) != -1 && x.indexOf(names[i+1]) != -1){
            name = '0';
            }
          }
      }
      else{ 
        if(x.indexOf(names[0]) != -1)
          name = '0';
      }

      return (
        type == 'name' ? name != '' 
          : type == 'email' ? y.indexOf(this.emailValue.toLowerCase()) !== -1 
            : type == 'companyType' ? z.indexOf(this.typeValue.toLowerCase()) !== -1 
              : type == 'plataformName' ? w.indexOf(this.plataformValue.toLowerCase()) !== -1 
                : k.indexOf(this.comercialValue.toLowerCase()) !== -1
      );
    };
    const data = this.listOfAllData.filter((item: Company) => filterFunc(item));
    this.listOfAllData = data.sort((a, b) =>

      this.sortValue === 'ascend'
        ? a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );
  }

  /**
   */
  public refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfAllData.every(
      item => this.mapOfCheckedId[item['companyId']]
    );
    this.isIndeterminate = this.listOfAllData.some(item => this.mapOfCheckedId[item['companyId']]) && !this.isAllDisplayDataChecked;
    this.numberOfChecked = this.listOfAllData.filter(item => this.mapOfCheckedId[item['companyId']]).length;
  }

  /**
   */
  public checkAll(value: boolean): void {
    this.listOfAllData.forEach(
      item => (this.mapOfCheckedId[item['companyId']] = value)
    );
    this.refreshStatus();
  }

  /**
   * @param companyId
   */
  public removeById(companyId) {
    this.companyService.deleteCompany(companyId).subscribe(
      (data) => { },
      (error) => { this.toastr.error('Algo ocorreu mal!'); },
      () => {
        // reload companies
        if (this.currentUser.user.profileId == 5) {
          this.companyService.getCompaniesOfComercial(this.currentUser.user.id).pipe(first()).subscribe(companies => {
            this.listOfAllData = companies;
            this.list = this.listOfAllData;
          });
        }
        else {
          this.companyService.getAll().pipe(first()).subscribe(companies => {
            this.listOfAllData = companies;
            this.list = this.listOfAllData;
          });
        }
        this.toastr.success('Empresa removida com sucesso!');
      }
    );
  }

  public custom(i) {
    this.currentPage = i - 1;
  }
}
