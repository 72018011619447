import { Component, OnInit, Optional, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-box-ebook',
  templateUrl: './dialog-box-ebook.component.html',
  styleUrls: ['./dialog-box-ebook.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class DialogBoxEbookComponent implements OnInit {

  localData: any;
  url: string;
  constructor(public dialogRef: MatDialogRef<DialogBoxEbookComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.localData = { ...data };
    console.log(this.localData);
    this.url = this.localData.url;
  }

  ngOnInit() {
  }

}
