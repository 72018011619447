import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(http) {
        this.http = http;
    }
    UserService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl + "/user/");
    };
    UserService.prototype.getByProfileId = function (profileId) {
        return this.http.get(environment.apiUrl + "/user/byprofile/" + profileId);
    };
    UserService.prototype.getById = function (id) {
        return this.http.get(environment.apiUrl + "/user/" + id);
    };
    UserService.prototype.getWithImgeById = function (id) {
        return this.http.get(environment.apiUrl + "/user/detail/" + id);
    };
    UserService.prototype.getComercials = function () {
        return this.http.get(environment.apiUrl + "/user/comercial");
    };
    UserService.prototype.createUser = function (SystemTs, Name, Email, Nif, Cc, ProfileId, CompanyId, Active, StudentNumber, learningLicense, contacts, Username, Password) {
        return this.http.post(environment.apiUrl + "/user/", {
            Name: Name, Email: Email, Nif: Nif, Cc: Cc, ProfileId: ProfileId,
            CompanyId: CompanyId, Active: Active, StudentNumber: StudentNumber, learningLicense: learningLicense, contacts: contacts, Username: Username, Password: Password
        });
    };
    UserService.prototype.updateUser = function (id, systemTs, name, email, nif, cc, profileId, companyId, active, contacts, studentNumber, learningLicense, username, password) {
        return this.http.put(environment.apiUrl + "/user/" + id, {
            systemTs: systemTs, name: name, email: email, nif: nif, cc: cc, profileId: profileId, contacts: contacts,
            companyId: companyId, active: active, studentNumber: studentNumber, learningLicense: learningLicense, username: username, password: password
        });
    };
    UserService.prototype.updateTerms = function (id) {
        return this.http.post(environment.apiUrl + "/user/terms/" + id, { id: id });
    };
    UserService.prototype.updateUserInfo = function (id, systemTs, name, email, nif, cc) {
        console.log(id);
        console.log(name);
        console.log(email);
        console.log(nif);
        console.log(cc);
        return this.http.put(environment.apiUrl + "/user/userInfo/" + id, {
            systemTs: systemTs, name: name, email: email, nif: nif, cc: cc
        });
    };
    UserService.prototype.deleteUser = function (id) {
        return this.http.delete(environment.apiUrl + "/user/" + id);
    };
    UserService.prototype.recoverPassword = function (email) {
        return this.http.put(environment.apiUrl + "/user/recover/" + email, email);
    };
    UserService.prototype.updatePassword = function (password, id) {
        return this.http.put(environment.apiUrl + "/user/update/" + id + "/" + password, password);
    };
    UserService.prototype.getAllStudentsByCompany = function (companyId, profileId) {
        return this.http.get(environment.apiUrl + "/user/GetAllStudentsByCompany/" + companyId + "/" + profileId);
    };
    UserService.prototype.checkEmail = function (email, id) {
        return this.http.get(environment.apiUrl + "/user/checkEmail/" + email + "/" + id);
    };
    UserService.prototype.checkUserName = function (username) {
        return this.http.get(environment.apiUrl + "/user/checkUserName/" + username);
    };
    UserService.prototype.GetLastDataById = function (userId) {
        return this.http.get(environment.apiUrl + "/user/GetLastDataById/" + userId);
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
