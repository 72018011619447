<tc-card [padding]="'20px 0'" [title]="'Clique no teste para obter mais detalhe'">
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <tc-card>
        <nz-table #rowSelectionTable [nzData]="listOfAllData" [nzBordered]="bordered" nzShowSizeChanger
          [(nzPageSize)]="pageSize"
          [nzPaginationPosition]="position" (nzCurrentPageDataChange)="currentPageDataChange($event)"
          (nzPageIndexChange)="custom($event)" style="overflow: auto;" class="text-center">
          <thead (nzSortChange)="sort($event)" nzSingleSort class="noClick">
            <thead (nzSortChange)="sort($event)" nzSingleSort>
              <tr class="noClick">
                <th nzShowSort nzSortKey="testDefinition" class="text-center">Tipo de Teste</th>
                <th nzShowSort nzSortKey="categoryName" class="text-center">Categoria</th>
                <th nzShowSort nzSortKey="aproved" class="text-center">Resultado</th>
                <th nzShowSort nzSortKey="endDt" class="text-center">Data</th>
              </tr>
            </thead>
          <tbody style="cursor: pointer;">
            <tr *ngFor="let data of rowSelectionTable.data; let i = index;" (click)="openDialog($event, i)">
              <td class="text-center">{{data.testDefinition}}</td>
              <td class="text-center">{{data.categoryName}}</td>
              <td class="text-center">
                <tc-badge *ngIf="data.aproved == true" style="width: 125px; padding-left:23px;" [view]="'success'">
                  Aprovado</tc-badge>
                <tc-badge *ngIf="data.aproved == false" style="width: 125px;" [view]="'danger'">Reprovado</tc-badge>
              </td>
              <td class="text-center">{{data.endDt | date: 'dd/MM/yyyy H:mm:ss'}}</td>
            </tr>
          </tbody>
        </nz-table>
      </tc-card>      
    </div>
  </div>  
</tc-card>