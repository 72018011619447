import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MenuItem } from '../../../models/menuItem';
import { Router } from '@angular/router';

import { MenuComponent } from '../menu';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {
  @Input() items: MenuItem[];
  @ViewChild('childMenu', {static: true}) public childMenu: any;
  constructor(
    public router: Router,
    private menu: MenuComponent
    ) { }

  ngOnInit() {
  }

  goAndCloseMenu(url: string) 
  {
    this.router.navigate(['/app/', url]); 
    this.menu.closeSidebar();
  }

}
