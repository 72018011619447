var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { UserService } from "src/app/services/user.service";
var DialogBoxEmailComponent = /** @class */ (function () {
    function DialogBoxEmailComponent(dialogRef, formBuilder, userService, 
    // @Optional() is used to prevent error if no data is passed
    data) {
        this.dialogRef = dialogRef;
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.data = data;
        this.requestType = [
            {
                id: "0",
                value: "Funcionamento da Plataforma",
            },
            {
                id: "1",
                value: "Dúvidas de Testes/Matéria",
            },
        ];
        this.form = this.formBuilder.group({
            myForm: new FormControl({ value: '', disabled: true }),
            myQuestion: new FormControl({ value: '', disabled: true })
        });
        this.localData = __assign({}, data);
    }
    DialogBoxEmailComponent.prototype.ngOnInit = function () {
        this.emailForm = this.formBuilder.group({
            requestType: ["", Validators.required],
            body: ["", Validators.required],
        });
    };
    Object.defineProperty(DialogBoxEmailComponent.prototype, "f", {
        get: function () {
            return this.emailForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    DialogBoxEmailComponent.prototype.closeDialog = function () {
        console.log("close");
        this.dialogRef.close({ event: "Cancel" });
    };
    DialogBoxEmailComponent.prototype.onSubmit = function () {
        if (this.emailForm.valid) {
            this.localData.subject = "Dúvida";
            this.localData.requestType = this.f.requestType.value;
            this.localData.body = this.f.body.value;
            this.dialogRef.close({ data: this.localData, event: "Send" });
        }
    };
    DialogBoxEmailComponent.prototype.isResponded = function () {
        if (this.localData.responded == true) {
            return true;
        }
        else {
            return false;
        }
    };
    DialogBoxEmailComponent.prototype.hasQuestion = function () {
        if (this.localData.question == undefined) {
            return false;
        }
        else {
            return true;
        }
    };
    return DialogBoxEmailComponent;
}());
export { DialogBoxEmailComponent };
