import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Product } from '../models/product';
import { ProductModel } from '../models/productModel';
import { didacticProductModel } from '../models/didacticProductModel';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Product[]>(`${environment.apiUrl}/Product/`);
  }
  getAllWithChildren() {
    return this.http.get<ProductModel[]>(`${environment.apiUrl}/Product/category/`);
  }
  getAllWithChildrenByCompanyId(id) {
    return this.http.get<ProductModel[]>(`${environment.apiUrl}/Product/category/company/${id}`);
  }
  getAllDidacticmWithChildrenByCompanyId(id){
    return this.http.get<didacticProductModel[]>(`${environment.apiUrl}/Product/didactic/company/${id}`);
  }
  getAllProductCategories() {
    return this.http.get<ProductModel[]>(`${environment.apiUrl}/Product/category/company/all/`);
  }

  getProductByCategoryId(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/Product/byCategoryId/${id}`);
  }
  createProduct(name: string, tag: string) {
    return this.http.post<any>(`${environment.apiUrl}/Product/`, {
      name, tag
    }
    );
  }
  updateProduct(id: number, name: string, tag: string) {
    return this.http.put<any>(`${environment.apiUrl}/product/${id}`, {
      name, tag
    }
    );
  }
  deleteProduct(id: number) {
    return this.http.delete<Product>(`${environment.apiUrl}/Product/${id}`);
  }

}
