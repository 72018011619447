<div style="display: none; visibility: hidden;">
    <img *ngIf="src" (load)="imageLoaded()" src="{{ src }}" alt="{{ alt }}" id="imgOriginal">
</div>

<img  *ngIf="loaded && size === 100" src="{{ src }}" alt="{{ alt }}" id="imgBig">
<img  *ngIf="loaded && size === 50" src="{{ src }}" alt="{{ alt }}" id="imgSmall">

<div *ngIf="(!loaded || !src) && initials" class="initials">{{ initials }}</div>

<span *ngIf="(!loaded || !src) && !initials" class="avatar-loader"><i class="icofont-ui-laoding rotate"></i></span>
