import { Component, EventEmitter, Input, OnInit, SimpleChanges } from '@angular/core';

import { AuthService } from '../../../services/auth.service';
import { CompanyService } from '../../../services/company.service';
import { GlobalConstants } from 'src/environments/settings';
import { HttpService } from '../../../services/http/http.service';
import { Router } from '@angular/router';
import { User } from '../../../models/user';
import { UserService } from 'src/app/services/user.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {
  notifications: any[];
  closeDropdown: EventEmitter<boolean>;
  imageUrl: string;
  icon = "icofont-envelope";
  iconBg = "#805aff";
  name = '';
  @Input() layout: string;
  @Input() actualize: boolean;
  
  currentUser: User;
  userService: any;

  constructor(
    private httpSv: HttpService,
    private router: Router,
    private authenticationService: AuthService,
    private companyService: CompanyService,
    private userServ: UserService
  ) {
    this.closeDropdown = new EventEmitter<boolean>();
    this.layout = 'vertical';

    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

    const va = this.currentUser.user.name.split(' ');
    
    if (va.length > 1) {
      this.name = va[0] + ' ' + va[va.length - 1];
    } else this.name = va[0];

    this.actualizeImg(this.currentUser.user.image);

    this.actualizeInfo;
  }

  ngOnInit() {
    this.userServ.getById(this.currentUser.user.id).subscribe(a => localStorage.setItem('currentUserDetails', JSON.stringify(a)));
    this.getData(`${environment.apiUrl}/AlertMail/getRequests/${this.currentUser.user.id}/${this.currentUser.user.profileId}`, 'notifications');
  }
  
  ngOnChanges(changes: SimpleChanges) {

    if(GlobalConstants.actualize)
    {
      this.actualizeInfo();
      GlobalConstants.actualize = false;
      this.actualize = false;
    }
    
  }

  private actualizeInfo() {
    this.notifications = [];
    
    this.userServ
        .getWithImgeById(this.currentUser.user.id)
        .subscribe(a => 
          {
            //console.log(a);
            
            if (a.image != null) {
              //console.log(a.image);
              this.currentUser.user.image = a.image;

              this.actualizeImg(this.currentUser.user.image);
            }

            a.image = '';
            localStorage.setItem("currentUserDetails", JSON.stringify(a));
          }
        );
       
    this.getData(`${environment.apiUrl}/AlertMail/getRequests/${this.currentUser.user.id}/${this.currentUser.user.profileId}`, 'notifications');
  }



  getData(url: string, dataName: string) {
    this.httpSv.getData(url).subscribe(
      data => {
        this[dataName] = data;
      },
      err => {
        //console.log(err);
      }
    );
  }

  onCloseDropdown() {
    this.closeDropdown.emit(true);
  }

  goTo(event: Event, link: string, layout: string = 'app') {
    event.preventDefault();

    this.onCloseDropdown();

    setTimeout(() => {
      if (link == 'requests') {
        if (this.currentUser.user.profileId == 3) {
          this.router.navigate([layout ? layout : this.layout, 'Requests']);
        }
        else {
          this.router.navigate([layout ? layout : this.layout, 'StudentRequests']);
        }
      }
      else { this.router.navigate([layout ? layout : this.layout, link]); }
    });
    this.closeDropdown.emit(true);
  }
  
  logout() {
    this.closeDropdown.emit(true);
    //console.log(this.currentUser);

    //console.log(this.currentUser.user.companyId);
    this.companyService.getById(this.currentUser.user.companyId).subscribe(company => {
      this.authenticationService.logout().subscribe();
      this.router.navigate(['/public/sign-in/' + company['plataformName'].replace(' ', '')]);
    });
  }

  actualizeImg(img:any)
  {
    if(img)
      this.imageUrl = "data:image/jpg;base64," + img;
    else
      this.imageUrl = "assets/avatar.jpg";
  }

}
