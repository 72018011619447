<tc-card>

    <h6>Clique na descrição do Ebook para o abrir.</h6>
    <nz-table #rowSelectionTable [nzData]="c" [nzBordered]="bordered" [nzPaginationPosition]="position" (nzCurrentPageDataChange)="currentPageDataChange($event)"
        style="overflow: auto;">
        <!-- (nzSortChange)="sort($event)"  -->
        <thead nzSingleSort>
            <tr class="noClick">
                <th>Descrição</th>
                <th *ngIf="currentUser.user.profileId == 1" nzWidth="25%"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of rowSelectionTable.data; let i=index;" (click)="openFile(i)">
                <td>
                    <tc-input [value]="data.description" id="{{'input' + i}}" [className]="isAdmin() ? '' : 'disabled'">
                    </tc-input>
                </td>
                <td *ngIf="currentUser.user.profileId == 1"
                    style="display: flex; justify-content: space-evenly; align-items: center;">
                    <button tc-button [beforeIcon]="'icofont-diskette'" (click)="guardar(i)"
                        [view]="'success'"></button>
                    &nbsp;
                    <button tc-button [beforeIcon]="'icofont-close-circled'" (click)="remover(i)"
                        [view]="'error'"></button>
                </td>
            </tr>
        </tbody>
    </nz-table>
</tc-card>