/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./footer.component";
import * as i4 from "../../../services/company.service";
import * as i5 from "../../../services/media.service";
import * as i6 from "../../../../utils/utils";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "footer-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "row align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "col-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "col-6 info"], ["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A9 2023 "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["AlvesCosta. "])), (_l()(), i1.ɵted(-1, null, [" Created by "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["href", "http://www.graycell.pt"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["graycell. "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["version: ", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "col-3 info"], ["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Termos e Condi\u00E7\u00F5es"])), (_l()(), i1.ɵeld(15, 0, null, null, 4, "div", [["class", "footer-skeleton"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [["class", "row align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "col-12 col-md-6 d-md-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "ul", [["class", "p-0 page-breadcrumbs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "li", [["class", "item bg animated-bg"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.url, ""); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.version; _ck(_v, 11, 0, currVal_1); }); }
function View_FooterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "footer-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "col-9 info text-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A9 2023 "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["AlvesCosta. "])), (_l()(), i1.ɵted(-1, null, [" Created by "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["href", "http://www.graycell.pt"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["graycell. "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["version: ", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "col-3 info text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Termos e Condi\u00E7\u00F5es"])), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "footer-skeleton"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "row align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "col-12 col-md-6 d-md-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "ul", [["class", "p-0 page-breadcrumbs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "li", [["class", "item bg animated-bg"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.url, ""); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.version; _ck(_v, 10, 0, currVal_1); }); }
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.utils.isMobileDevice(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.utils.isMobileDevice(); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "footer", [], [[2, "footer", null], [2, "loaded", null], [2, "boxed", null]], null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i3.FooterComponent, [i4.CompanyService, i5.MediaService, i6.Utils], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).loaded; var currVal_2 = i1.ɵnov(_v, 1).boxed; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var FooterComponentNgFactory = i1.ɵccf("footer", i3.FooterComponent, View_FooterComponent_Host_0, { loaded: "loaded", boxed: "boxed", pageData: "pageData" }, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
