var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute, Router } from '@angular/router';
import { OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { BasePageComponent } from '../../../../pages/base-page';
import { HttpService } from '../../../../services/http/http.service';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { TicketService } from '../../../../services/ticket.service';
import { environment } from '../../../../../environments/environment';
var TypeComponent = /** @class */ (function (_super) {
    __extends(TypeComponent, _super);
    function TypeComponent(router, authenticationService, location, ticket, store, route, httpSv) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.router = router;
        _this.authenticationService = authenticationService;
        _this.location = location;
        _this.ticket = ticket;
        _this.route = route;
        _this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        _this.getData(environment.apiUrl + "/Product/", 'tableData', 'setLoaded');
        _this.categoryId = _this.route.snapshot.params['id'];
        _this.pageData = {
            title: 'Realizar teste',
            loaded: true
        };
        return _this;
    }
    TypeComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    TypeComponent.prototype.select = function (e, i) {
        this.activeElement = i;
        this.router.navigate(['app/TestType/' + e.target.innerHTML.replace(' ', '') + '/' + this.categoryId]);
    };
    TypeComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    TypeComponent.prototype.back = function () {
        this.location.back();
    };
    return TypeComponent;
}(BasePageComponent));
export { TypeComponent };
