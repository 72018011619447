import { Component, OnInit, Optional, Inject, Input, ViewEncapsulation, HostListener, ChangeDetectorRef, ElementRef } from '@angular/core';
import { User } from '../../../models/user';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { WizardComponent } from '../Tests/wizard/wizard.component';
import { AuthService } from '../../../services/auth.service';
import { TestService } from '../../../services/test.service';
import { first } from 'rxjs/internal/operators/first';
import { FormControl } from '@angular/forms';
import { DialogBoxZoomComponent } from '../DialogBoxes/dialog-box-zoom/dialog-box-zoom.component';
import { BlockTemplateComponent } from '../block-template/block-template.component';
import { Subject } from 'rxjs/internal/Subject';
import { Utils } from 'src/utils/utils';

@Component({
    selector: 'app-showCamtest',
    templateUrl: './showCamtest.component.html',
    styleUrls: ['./showCamtest.component.css'],
    encapsulation: ViewEncapsulation.Emulated
})
export class ShowcamTestComponent implements OnInit {

    currentUser: User;
    localData: any;
    questions: any[] = [];
    @Input() selectedIndex: number | null;
    minutesLeft: number;
    isFinished = false;
    action: string;
    profile: any[];
    type: string = '';
    currentName: string = '';
    cc: string = '';
    selected = new FormControl(0);
    selectedThemes: number[] = [];
    blockTemplate = BlockTemplateComponent;
    numberOfQuestions: number;
    dashboard = true;
    public destroyed = new Subject<any>();
    constructor(public dialog: MatDialog, private testService: TestService,
        public dialogRef: MatDialogRef<WizardComponent>, private authenticationService: AuthService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        public utils: Utils,
        private cd: ChangeDetectorRef,
        private elRef:ElementRef) {
        this.localData = { ...data };
        
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

        let va = null;

        if(this.localData.name)
        {
            va = this.localData.name.split(' ');
        }
        else{
            va = this.currentUser.user.name.split(' ');
        }

        //console.log(this.localData.cc);
        //console.log(this.currentUser.user);

        if(this.localData.cc)
        {
            this.cc = this.localData.cc;
        }
        else{
            if(this.currentUser.user.cc)
                this.cc = this.currentUser.user.cc.toString();
            else
                this.cc = '';
        }

        if (this.localData.name != undefined && this.localData.name != null) {
            this.dashboard = false;
        }

        if (this.localData.dashboard == true || this.dashboard == true) {
            if (va.length > 1) {
                this.currentName = va[0] + ' ' + va[va.length - 1];
            } 
            else this.currentName = va[0];
        } 
        else
        {
            if (va.length > 1) {
                this.currentName = va[0] + ' ' + va[va.length - 1];
            } 
            else this.currentName = va[0];
        }

        this.testService.getTypeByTestId(this.localData.testId).subscribe(x => {
            this.type = x['type'];
        });


        this.testService.getQuestionsByTestId(this.localData.testId).pipe(first()).subscribe(questions => {
            this.questions = questions;
            this.numberOfQuestions = questions.length;
            const tabList = document.getElementsByClassName('mat-tab-list')[0];
            console.log(questions);

            
            if (this.localData.dashboard === true) {
                const index: number = this.questions.findIndex(d => d['questionId'] === this.localData.questionId);
                this.selected.setValue(index);
                this.selectedIndex = index;
            }
        });
    }

    ngOnInit() { }

    @HostListener('mouseover')
    refreshTabs() {
        let i = 0;

        this.questions.forEach(element => {

            let tabGroupIndex = this.elRef.nativeElement.querySelectorAll('.Dummy .mat-tab-body')[0].id.split('-')[3];
            
            const tab = this.elRef.nativeElement.querySelectorAll("#mat-tab-label-" + tabGroupIndex + "-" + i)[0];


            if (element.answerSelected == undefined || element.answerSelected == null) {
                element.answerSelected = -1;
                tab.classList.add('wrongTab');
            }
            else {
                if (element.answerSelected == element['answerCorrect']) 
                    tab.classList.add('correctTab');
                else 
                    tab.classList.add('wrongTab');
            }
            i++;
        });
    }

    close() {
        this.dialogRef.close({ event: 'Cancel' });
    }

    indexChange(event) {
        this.selected.setValue(event);
    }

    selectTab(id) {
        this.refreshTabs();
    }  

    SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

    swipe(selectedIndex: number, action = this.SWIPE_ACTION.RIGHT) {
        console.log("swipe");
        console.log("number",this.selected.value);
        console.log(action);
        console.log(this.questions.length - 1);
        
        // Out of range
        if (this.selected.value < 0 || this.selected.value > (this.questions.length - 1)) return;
        
        // Swipe left, next tab
        if (action === this.SWIPE_ACTION.LEFT) {
            const isLast = this.selected.value === (this.questions.length - 1);
            console.log("isLast", isLast);
            this.selected.setValue(isLast ? 0 : this.selected.value + 1);
            console.log("Swipe right - INDEX: " + this.selected.value);
        }

        // Swipe right, previous tab
        if (action === this.SWIPE_ACTION.RIGHT) {
            const isFirst = this.selected.value === 0;
            console.log("isFirst", isFirst);
            this.selected.setValue(isFirst ? 1 : this.selected.value - 1);
            console.log("Swipe left - INDEX: " + this.selected.value);
        }
    }
}
