import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DurationService = /** @class */ (function () {
    function DurationService(http) {
        this.http = http;
    }
    DurationService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl + "/Duration/");
    };
    DurationService.prototype.getDurationdByCompanyId = function (companyId) {
        console.log(companyId);
        return this.http.get(environment.apiUrl + "/Duration/company/" + companyId);
    };
    DurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DurationService_Factory() { return new DurationService(i0.ɵɵinject(i1.HttpClient)); }, token: DurationService, providedIn: "root" });
    return DurationService;
}());
export { DurationService };
