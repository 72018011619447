import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Duration } from '../models/Duration';

@Injectable({
  providedIn: 'root'
})
export class DurationService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Duration[]>(`${environment.apiUrl}/Duration/`);
}
getDurationdByCompanyId(companyId){
  console.log(companyId);
  return this.http.get<Duration[]>(`${environment.apiUrl}/Duration/company/${companyId}`);
}
}
