import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CompanyService = /** @class */ (function () {
    function CompanyService(http) {
        this.http = http;
    }
    CompanyService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl + "/company/");
    };
    CompanyService.prototype.getAllSorted = function () {
        return this.http.get(environment.apiUrl + "/company/");
    };
    CompanyService.prototype.getById = function (id) {
        return this.http.get(environment.apiUrl + "/company/" + id);
    };
    CompanyService.prototype.getByName = function (name) {
        name = name === undefined || '' ? 'AlvesCosta' : name;
        return this.http.get(environment.apiUrl + "/company/name/" + name);
    };
    CompanyService.prototype.createCompany = function (systemTs, Name, Email, NIF, Contacts, CompanyTypeId, address, active, PlataformName, Prefix, Alvara, ComercialUserId) {
        return this.http.post(environment.apiUrl + "/company/", {
            systemTs: systemTs, Name: Name, Email: Email, NIF: NIF, Contacts: Contacts, CompanyTypeId: CompanyTypeId,
            address: address, active: active, PlataformName: PlataformName, Prefix: Prefix, Alvara: Alvara, ComercialUserId: ComercialUserId
        });
    };
    CompanyService.prototype.updateCompany = function (id, systemTs, Name, Email, NIF, Contacts, CompanyTypeId, address, active, PlataformName, Prefix, Alvara, url, ComercialUserId, EmailInstrutores) {
        return this.http.put(environment.apiUrl + "/company/" + id, {
            systemTs: systemTs, Name: Name, Email: Email, NIF: NIF, Contacts: Contacts, CompanyTypeId: CompanyTypeId,
            address: address, active: active, PlataformName: PlataformName, Prefix: Prefix, Alvara: Alvara, url: url, ComercialUserId: ComercialUserId, EmailInstrutores: EmailInstrutores
        });
    };
    CompanyService.prototype.deleteCompany = function (id) {
        return this.http.delete(environment.apiUrl + "/company/" + id);
    };
    CompanyService.prototype.getCompaniesOfComercial = function (id) {
        return this.http.get(environment.apiUrl + "/company/comercial/" + id);
    };
    CompanyService.prototype.upload = function (id, mimeType, url, name, size, description) {
        console.log(url);
        return this.http.post(environment.apiUrl + "/company/upload/" + id, { mimeType: mimeType, url: url, name: name, size: size, description: description });
    };
    CompanyService.prototype.unlinkComercialUser = function (id, companyId) {
        console.log(id);
        console.log(companyId);
        return this.http.delete(environment.apiUrl + "/company/unlink/" + id + "/" + companyId);
    };
    CompanyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyService_Factory() { return new CompanyService(i0.ɵɵinject(i1.HttpClient)); }, token: CompanyService, providedIn: "root" });
    return CompanyService;
}());
export { CompanyService };
