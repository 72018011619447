import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Category } from '../models/Category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Category[]>(`${environment.apiUrl}/Category/`);
  }

  isCam(id: number) {
    return this.http.get<boolean>(`${environment.apiUrl}/Category/isCam/${id}`);
  }

  getCategoryByThemeId(id: number){
    return this.http.get<Category[]>(`${environment.apiUrl}/Category/byTheme/${id}`);
  }
  createCategory( name: string, ProductId: number) {

    return this.http.post<any>(`${environment.apiUrl}/Category/`, {
      name, ProductId
    }
    );
  }
  updateCategory(id: number, name: string, ProductId: number) {
    return this.http.put<any>(`${environment.apiUrl}/Category/${id}`, {  name, ProductId} );
  }
  deleteCategory(id: number) {
    return this.http.delete<Category>(`${environment.apiUrl}/Category/${id}`);
  }

}
