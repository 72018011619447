var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from "@angular/core";
import { FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { UserService } from "src/app/services/user.service";
var DialogBoxTeacherEmailComponent = /** @class */ (function () {
    function DialogBoxTeacherEmailComponent(dialogRef, formBuilder, userService, 
    // @Optional() is used to prevent error if no data is passed
    data) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.data = data;
        this.name = '';
        this.form = this.formBuilder.group({
            myForm: new FormControl({ value: '', disabled: true }),
            myQuestion: new FormControl({ value: '', disabled: true })
        });
        this.localData = __assign({}, data);
        //console.log(this.localData);
        if (this.localData.userId) {
            this.userService.getById(this.localData.userId).subscribe(function (x) {
                _this.name = x['name'];
            });
        }
    }
    DialogBoxTeacherEmailComponent.prototype.ngOnInit = function () {
        this.emailForm = this.formBuilder.group({
            //subject: ['', Validators.required],
            body: ["", Validators.required],
        });
    };
    Object.defineProperty(DialogBoxTeacherEmailComponent.prototype, "f", {
        get: function () {
            return this.emailForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    DialogBoxTeacherEmailComponent.prototype.onSubmit = function () {
        this.localData.subject = "Re: Dúvidas";
        this.localData.body = this.f.body.value;
        if (this.localData.body != '')
            this.dialogRef.close({ data: this.localData, event: "Send" });
    };
    DialogBoxTeacherEmailComponent.prototype.closeDialog = function () {
        console.log("close");
        this.dialogRef.close({ event: "Cancel" });
    };
    DialogBoxTeacherEmailComponent.prototype.isResponded = function () {
        if (this.localData.responded == true) {
            return true;
        }
        else {
            return false;
        }
    };
    return DialogBoxTeacherEmailComponent;
}());
export { DialogBoxTeacherEmailComponent };
