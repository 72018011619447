<script src="js/jquery.min.js"></script>
<script src="js/html2canvas.min.js"></script>
<script src="js/three.min.js"></script>
<script src="js/pdf.min.js"></script>
<script src="js/3dflipbook.min.js"></script>
<link href="https://fonts.googleapis.com/css?family=Roboto+Condensed:700" rel="stylesheet" type="text/css">
<link href="https://fonts.googleapis.com/css?family=Roboto:100" rel="stylesheet" type="text/css">
<link href="https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap" rel="stylesheet">

<div class="container d-block d-xl-none" style="width: 100%; background-color: #134472;color:#80BBD7; display: flex; justify-content: space-evenly; align-items: center;">
    <div class="row no-gutters align-items-center" style="height: 48px;" >
        <div class="col-10 text-left">
            {{localData.description}}
        </div>
        <div class="buttonsContainer col-2 text-right">
            <button class="closeButton" (click)="close()" rel="tooltip" matTooltip="Fechar"><i class="icofont-close-line"></i></button>
        </div>
    </div>
</div>


<div class="container d-none d-xl-block" style="width: 100%; height: 48px; background-color: #134472;color:#80BBD7; display: flex; justify-content: space-evenly; align-items: center;">
    <div class="row no-gutters align-items-center" style="height: 48px;" >
        <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10 text-truncate">
            <span class="terminate roboto500" id="type">Ensino à distância:&nbsp;<span style="color:white;">{{localData.number}} - {{localData.description}}</span></span>
        </div>
        <div class="buttonsContainer col-sm-2 col-md-2 col-lg-2 col-xl-2 text-right">      
            <button class="closeButton" (click)="close()" rel="tooltip" matTooltip="Fechar">
                <i class="icofont-close-line"></i>
            </button>
        </div>    
    </div>
</div>

<div class="container" style="width: 100%">
    <div class="x fullAreaLesson p-0 row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0" style="background-color: #134472; color: white;">
            
            <tc-progress *ngIf="!this.localData.dayClosed" [percent]="false" [color]="colorProgress" [progress]="percentage" [size]="'lg'" class="percentPanel"></tc-progress>
            <div class="error text-center our-border" style="background-color: white;" *ngIf="this.localData.dayClosed">
                O limite diário de permanência (4h), foi atingido.
            </div>

            <iframe [src]="url | safe" class="eBookExternal" frameborder="0" muted="muted" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </div>
    </div>            
</div>    
