<div style="display: inline-table;">
  <div style="display: table-cell; width: 99%;text-align: left;">
    <h4 small>{{action}} {{localData.name}}</h4>
  </div>
  <div style="display: table-cell; width: 1%; text-align: right; vertical-align: top;">
    <!-- <a mat-dialog-close style="float: right; cursor:pointer" class="tc-icon-wrap icofont-close-circled"></a> -->
  </div>
</div>
<div style="clear: both; width: 100%;">
  <mat-dialog-content [formGroup]="testDefinitionForm" (ngSubmit)="onSubmit()">
    <tc-form-group>
      <tc-form-label>Pertence a Categoria</tc-form-label>
      <mat-select formControlName="categories" placeholder="Pertence a Categoria" class="select-box">
        <mat-option *ngFor="let state of categories" [value]="state.name">
          {{ state.name }}
        </mat-option>
      </mat-select>
    </tc-form-group>
    <tc-form-group>
      <tc-form-label>Duração</tc-form-label>
      <tc-input [placeholder]="'Duração'" [type]="'text'" formControlName="duration" [(ngModel)]="localData.duration">
      </tc-input>
    </tc-form-group>
    <tc-form-group>
      <tc-form-label>Numero de Questões</tc-form-label>
      <tc-input [placeholder]="'Numero de Questões'" [type]="'text'" formControlName="numberOfQuestions" [(ngModel)]="localData.numberOfQuestions"></tc-input>
    </tc-form-group>
    <tc-form-group>
      <tc-form-label>Questões a Errar</tc-form-label>
      <tc-input [placeholder]="'Questões a Errar'" [type]="'text'" formControlName="wrongQuestions" [(ngModel)]="localData.wrongQuestions"></tc-input>
    </tc-form-group>
    <tc-form-group>
      <tc-form-label>Tipo de Teste</tc-form-label>
      <mat-select formControlName="testType" placeholder="Tipo de Teste">
        <mat-option *ngFor="let state of testType" [value]="state.name">
          {{ state.name }}
        </mat-option>
      </mat-select>
    </tc-form-group>
  </mat-dialog-content>
</div>
<mat-dialog-actions layout="row">
  <button tc-button [view]="'success'" [disabled]="testDefinitionForm.invalid" (click)="onSubmit()" style="margin: 2px;" [beforeIcon]="'icofont-diskette'">Submeter</button>
  <button tc-button [view]="'warning'" (click)="closeDialog()" style="margin: 2px;" [beforeIcon]="'icofont-close-circled'"></button>
</mat-dialog-actions>