import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DidaticMaterialService = /** @class */ (function () {
    function DidaticMaterialService(http) {
        this.http = http;
    }
    DidaticMaterialService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl + "/didaticMaterial/");
    };
    DidaticMaterialService.prototype.getDidacticMaterialByCompanyId = function (id) {
        return this.http.get(environment.apiUrl + "/didaticMaterial/companyDidacticMaterial/" + id);
    };
    DidaticMaterialService.prototype.getByCompanyId = function (id) {
        return this.http.get(environment.apiUrl + "/didaticMaterial/company/" + id);
    };
    DidaticMaterialService.prototype.getByUserId = function (id) {
        return this.http.get(environment.apiUrl + "/didaticMaterial/user/" + id);
    };
    DidaticMaterialService.prototype.getImageById = function (id) {
        return this.http.get(environment.apiUrl + "/didaticMaterial/image/" + id);
    };
    DidaticMaterialService.prototype.updateMaterial = function (id, text) {
        return this.http.put(environment.apiUrl + "/didaticMaterial/" + id + "/" + text, { text: text });
    };
    DidaticMaterialService.prototype.removeDidatic = function (id) {
        return this.http.delete(environment.apiUrl + "/didaticMaterial/" + id);
    };
    DidaticMaterialService.prototype.getEbooksByCompanyId = function (id) {
        return this.http.get(environment.apiUrl + "/didaticMaterial/ebooks/company/" + id);
    };
    DidaticMaterialService.prototype.getEbooksByUserId = function (id) {
        return this.http.get(environment.apiUrl + "/didaticMaterial/ebooks/user/" + id);
    };
    DidaticMaterialService.prototype.createEbook = function (companyId, ebookDesc, ebookUrl) {
        return this.http.post(environment.apiUrl + "/didaticMaterial/ebooks", {
            ebookDesc: ebookDesc, companyId: companyId, ebookUrl: ebookUrl
        });
    };
    DidaticMaterialService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DidaticMaterialService_Factory() { return new DidaticMaterialService(i0.ɵɵinject(i1.HttpClient)); }, token: DidaticMaterialService, providedIn: "root" });
    return DidaticMaterialService;
}());
export { DidaticMaterialService };
