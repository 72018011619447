var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from '../../../../services/auth.service';
import { BasePageComponent } from '../../../base-page';
import { CompanyService } from '../../../../services/company.service';
import { CompanyTypeService } from '../../../../services/company-type.service';
import { ConfirmComponent } from '../../DialogBoxes/confirm/confirm.component';
import { DialogBoxCompaniesComponent } from '../../DialogBoxes/dialog-box-companies/dialog-box-companies.component';
import { HttpService } from '../../../../services/http/http.service';
import { MatDialog } from '@angular/material';
import { TicketService } from '../../../../services/ticket.service';
import { ToastrService } from 'ngx-toastr';
import { Utils } from 'src/utils/utils';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';
var CompaniesComponent = /** @class */ (function (_super) {
    __extends(CompaniesComponent, _super);
    function CompaniesComponent(companyService, dialog, ticketService, companyTypeService, authenticationService, store, httpSv, toastr, utils) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.companyService = companyService;
        _this.dialog = dialog;
        _this.ticketService = ticketService;
        _this.companyTypeService = companyTypeService;
        _this.authenticationService = authenticationService;
        _this.toastr = toastr;
        _this.utils = utils;
        _this.bordered = true;
        _this.position = 'bottom';
        _this.pageSize = 10;
        _this.listOfDisplayData = [];
        _this.listOfAllData = [];
        _this.list = [];
        _this.sortName = null;
        _this.sortValue = null;
        _this.mapOfSort = {
            name: null,
            email: null,
            comercial: null,
            companyTypeName: null,
            plataformName: null,
            active: null
        };
        _this.nameValue = '';
        _this.emailValue = '';
        _this.typeValue = '';
        _this.plataformValue = '';
        _this.comercialValue = '';
        _this.isAllDisplayDataChecked = false;
        _this.isIndeterminate = false;
        _this.numberOfChecked = 0;
        _this.mapOfCheckedId = {};
        _this.currentPage = 0;
        _this.prev_index = '0';
        _this.opened = false;
        _this.getData(environment.apiUrl + "/Company/", 'tableData', 'setLoaded');
        _this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        if (_this.currentUser.user.profileId == 5) {
            _this.companyService.getCompaniesOfComercial(_this.currentUser.user.id).pipe(first()).subscribe(function (companies) {
                _this.listOfAllData = companies;
                // this.listOfAllData.forEach(element => {
                //   this.companyTypeService.getById(element.companyTypeId).subscribe(companyType => {
                //     element.companyTypeName = companyType.name
                //   })
                // });
                _this.list = _this.listOfAllData;
            });
        }
        else {
            _this.companyService.getAll().pipe(first()).subscribe(function (companies) {
                _this.listOfAllData = companies;
                console.log(companies);
                // this.listOfAllData.forEach(element => {
                //   this.companyTypeService.getById(element.companyTypeId).subscribe(companyType => {
                //     element.companyTypeName = companyType.name
                //   })
                // });
                _this.list = _this.listOfAllData;
            });
        }
        return _this;
    }
    CompaniesComponent.prototype.currentPageDataChange = function ($event) {
        this.listOfDisplayData = $event;
    };
    CompaniesComponent.prototype.sort = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.search('');
        if (this.sortValue == '' || this.sortValue == null) {
            this.sortName = 'name';
            this.sortValue = 'ascend';
            this.filter();
        }
    };
    CompaniesComponent.prototype.reset = function () {
        this.nameValue = '';
        this.emailValue = '';
        this.typeValue = '';
        this.plataformValue = '';
        this.comercialValue = '';
        this.search('');
        this.listOfAllData = this.list;
        this.mapOfSort = {
            name: null,
            email: null,
            comercial: null,
            companyTypeName: null,
            plataformName: null,
            active: null
        };
    };
    /**
     * @param event
     * @param index
     */
    CompaniesComponent.prototype.select = function (event, index) {
        document.getElementById(this.prev_index).classList.remove('active');
        document.getElementById(index).classList.add('active');
        this.prev_index = index;
    };
    CompaniesComponent.prototype.removeSelectedRows = function () {
        var _this = this;
        var row = {};
        row.text = 'Confirma a remoção da(s) empresa(s) selecionada(s)?';
        var dialogRef = this.dialog.open(ConfirmComponent, {
            id: 'confirm',
            data: row
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === undefined) { }
            else if (result.event == 'sim') {
                _this.listOfAllData.forEach(function (item) { return _this.mapOfCheckedId[item['companyId']] == true ? _this.removeById(item['companyId']) : ""; });
            }
        });
    };
    CompaniesComponent.prototype.openDialog = function (action, e, i) {
        var _this = this;
        if (e == null || !e.target.className.match('ant-table-column-sorters') && !e.target.className.match('ant-spin-container')) {
            var row = {};
            row = e == null ? {} : this.listOfAllData[(this.currentPage * this.pageSize + i)];
            row.action = action;
            /** TODO:remove is deprecate...
            var path = e == null ? '' : e.path || (e.composedPath && e.composedPath());
            let row: any = {};
            row = e == null ? {} : this.listOfAllData[this.listOfAllData.findIndex(d => d['name'].trim() === path[1].children[0].innerText)];
            row.action = action;
            */
            var deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
            var deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '850px';
            var deviceMaxHeight = this.utils.isMobileDevice() ? '90vh' : '90vh';
            var dialogRef = this.dialog.open(DialogBoxCompaniesComponent, {
                width: deviceWidth,
                maxWidth: deviceMaxWidth,
                height: deviceMaxHeight,
                maxHeight: deviceMaxHeight,
                data: row,
                disableClose: true,
                closeOnNavigation: true
            });
            dialogRef.afterClosed().subscribe(function (result) {
                _this.dialog.closeAll();
                if (result === undefined) { }
                else if (result.event == 'AdicionarGeral') {
                    _this.addRowData(result.data);
                }
                else if (result.event == 'ModificarGeral') {
                    _this.updateRowData(result.data, result.submitType);
                }
                else if (result.event == 'Tickets') {
                    _this.updateTicketData(result.data);
                }
            });
        }
    };
    CompaniesComponent.prototype.addRowData = function (rowObj) {
        var _this = this;
        this.companyService.createCompany('', rowObj.name, rowObj.email, rowObj.nif, rowObj.contacts, rowObj.companyTypeId, rowObj.address, rowObj.active, rowObj.plataform, rowObj.prefix, rowObj.alvara, rowObj.comercialId).subscribe(function (data) {
            _this.getData(environment.apiUrl + "/Company/", 'tableData', 'setLoaded');
            if (_this.currentUser.user.profileId == 5) {
                _this.companyService.getCompaniesOfComercial(_this.currentUser.user.id).pipe(first()).subscribe(function (companies) {
                    _this.listOfAllData = companies;
                    // this.listOfAllData.forEach(element => {
                    //   this.companyTypeService.getById(element.companyTypeId).subscribe(companyType => {
                    //     element.companyTypeName = companyType.name
                    //   })
                    // });
                    _this.list = _this.listOfAllData;
                    _this.toastr.success('Empresa criada com sucesso!');
                });
            }
            else {
                _this.companyService.getAll().pipe(first()).subscribe(function (company) {
                    _this.listOfAllData = company;
                    // // this.listOfAllData.forEach(element => {
                    // //   this.companyTypeService.getById(element.companyTypeId).subscribe(companyType => {
                    // //     element.companyTypeName = companyType.name;
                    // //   });
                    // });
                    _this.list = _this.listOfAllData;
                });
                _this.toastr.success('Empresa criada com sucesso!');
            }
        }, function (err) {
            _this.toastr.error('Algo ocorreu mal!');
        });
    };
    CompaniesComponent.prototype.updateRowData = function (rowObj, type) {
        var _this = this;
        console.log(rowObj);
        if (type == 'geral') {
            this.companyService.updateCompany(rowObj['companyId'], '', rowObj.name, rowObj.email, rowObj.nif, rowObj.contacts, rowObj.companyTypeId, rowObj.address, rowObj.active, rowObj.plataform, rowObj.prefix, rowObj.alvara, rowObj.url, rowObj.comercialId, rowObj.emailInstrutores).subscribe(function (data) {
                _this.getData(environment.apiUrl + "/Company/", 'tableData', 'setLoaded');
                if (_this.currentUser.user.profileId == 5) {
                    _this.companyService.getCompaniesOfComercial(_this.currentUser.user.id).pipe(first()).subscribe(function (companies) {
                        _this.listOfAllData = companies;
                        // this.listOfAllData.forEach(element => {
                        //   this.companyTypeService.getById(element.companyTypeId).subscribe(companyType => {
                        //     element.companyTypeName = companyType.name
                        //   })
                        // });
                        _this.list = _this.listOfAllData;
                        _this.toastr.success('Empresa modificada com sucesso!');
                    });
                }
                else {
                    _this.companyService.getAll().pipe(first()).subscribe(function (company) {
                        _this.listOfAllData = company;
                        // this.listOfAllData.forEach(element => {
                        //   this.companyTypeService.getById(element.companyTypeId).subscribe(companyType => {
                        //     element.companyTypeName = companyType.name;
                        //   });
                        // });
                        _this.list = _this.listOfAllData;
                    });
                    _this.toastr.success('Empresa modificada com sucesso!');
                }
            }, function (err) {
                _this.toastr.error('Algo ocorreu mal!');
            });
        }
        else {
            var productCategoriesId = [];
            var didacticMaterialsId = [];
            for (var i = 0; i < rowObj.products.length; i++) {
                if (rowObj.products[i].children != null) {
                    for (var j = 0; j < rowObj.products[i].children.length; j++) {
                        if (rowObj.products[i].children[j].selected == true) {
                            if (rowObj.products[i].children[j].categoryProductId == undefined) {
                                didacticMaterialsId.push(rowObj.products[i].children[j].didacticMaterialId);
                            }
                            else if (rowObj.products[i].children[j].didacticMaterialId == undefined) {
                                productCategoriesId.push(rowObj.products[i].children[j].categoryProductId);
                            }
                        }
                    }
                }
                else {
                    if (rowObj.products[i].selected == true) {
                        if (rowObj.products[i].categoryProductId == undefined) {
                            didacticMaterialsId.push(rowObj.products[i].didacticMaterialId);
                        }
                        else {
                            productCategoriesId.push(rowObj.products[i].categoryProductId);
                        }
                    }
                }
            }
            this.ticketService.ticketByCategoryId(rowObj.companyId, productCategoriesId, didacticMaterialsId, rowObj.durationId, rowObj.quantity, rowObj.createdBy).subscribe(function (data) {
                _this.toastr.success('Senhas atribuídas com sucesso!');
            }, function (err) {
                _this.toastr.error('Algo ocorreu mal!');
            });
        }
    };
    CompaniesComponent.prototype.updateTicketData = function (rowObj) {
    };
    CompaniesComponent.prototype.ngOnInit = function () {
        //super.ngOnInit();
    };
    CompaniesComponent.prototype.ngOnDestroy = function () {
        //  super.ngOnDestroy();
    };
    CompaniesComponent.prototype.openFilter = function () {
        var el = document.getElementById('filtro');
        if (this.opened == false) {
            el.classList.add('show');
            el.classList.remove('hide');
            this.opened = true;
        }
        else {
            el.classList.add('hide');
            el.classList.remove('show');
            this.opened = false;
        }
    };
    CompaniesComponent.prototype.filter = function () {
        this.listOfAllData = this.list;
        if (this.nameValue != '') {
            this.search('name');
        }
        if (this.emailValue != '') {
            this.search('email');
        }
        if (this.typeValue != '') {
            this.search('companyType');
        }
        if (this.plataformValue != '') {
            this.search('plataformName');
        }
        if (this.comercialValue != '') {
            this.search('comercialUser');
        }
    };
    CompaniesComponent.prototype.search = function (type) {
        var _this = this;
        var filterFunc = function (item) {
            var x = '' + item['name'].toLowerCase();
            var y = item['email'].toLowerCase();
            var z = item['companyType'].toLowerCase();
            var w = item['plataformName'].toLowerCase();
            var k = item['comercialUser'].toLowerCase();
            var names = _this.nameValue.toLowerCase().split(" ");
            var name = '';
            //Search names
            if (names.length > 1) {
                for (var i = 0; i < names.length; i++) {
                    if (x.indexOf(names[i]) != -1 && x.indexOf(names[i + 1]) != -1) {
                        name = '0';
                    }
                }
            }
            else {
                if (x.indexOf(names[0]) != -1)
                    name = '0';
            }
            return (type == 'name' ? name != ''
                : type == 'email' ? y.indexOf(_this.emailValue.toLowerCase()) !== -1
                    : type == 'companyType' ? z.indexOf(_this.typeValue.toLowerCase()) !== -1
                        : type == 'plataformName' ? w.indexOf(_this.plataformValue.toLowerCase()) !== -1
                            : k.indexOf(_this.comercialValue.toLowerCase()) !== -1);
        };
        var data = this.listOfAllData.filter(function (item) { return filterFunc(item); });
        this.listOfAllData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    /**
     */
    CompaniesComponent.prototype.refreshStatus = function () {
        var _this = this;
        this.isAllDisplayDataChecked = this.listOfAllData.every(function (item) { return _this.mapOfCheckedId[item['companyId']]; });
        this.isIndeterminate = this.listOfAllData.some(function (item) { return _this.mapOfCheckedId[item['companyId']]; }) && !this.isAllDisplayDataChecked;
        this.numberOfChecked = this.listOfAllData.filter(function (item) { return _this.mapOfCheckedId[item['companyId']]; }).length;
    };
    /**
     */
    CompaniesComponent.prototype.checkAll = function (value) {
        var _this = this;
        this.listOfAllData.forEach(function (item) { return (_this.mapOfCheckedId[item['companyId']] = value); });
        this.refreshStatus();
    };
    /**
     * @param companyId
     */
    CompaniesComponent.prototype.removeById = function (companyId) {
        var _this = this;
        this.companyService.deleteCompany(companyId).subscribe(function (data) { }, function (error) { _this.toastr.error('Algo ocorreu mal!'); }, function () {
            // reload companies
            if (_this.currentUser.user.profileId == 5) {
                _this.companyService.getCompaniesOfComercial(_this.currentUser.user.id).pipe(first()).subscribe(function (companies) {
                    _this.listOfAllData = companies;
                    _this.list = _this.listOfAllData;
                });
            }
            else {
                _this.companyService.getAll().pipe(first()).subscribe(function (companies) {
                    _this.listOfAllData = companies;
                    _this.list = _this.listOfAllData;
                });
            }
            _this.toastr.success('Empresa removida com sucesso!');
        });
    };
    CompaniesComponent.prototype.custom = function (i) {
        this.currentPage = i - 1;
    };
    return CompaniesComponent;
}(BasePageComponent));
export { CompaniesComponent };
