import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ROUTES, RoutingModule } from './routing/routing.module';

import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorInterceptor } from './helpers/error/error.interceptor';
import { FormsModule } from '@angular/forms';
import { HelpersComponent } from './helpers/helpers.component';
import { JwtInterceptor } from './helpers/jwt/jwt.interceptor';
import { LayoutModule } from './layout/layout.module';
import { MaterialModule } from '../material.module';
import { NgModule } from '@angular/core';
import { PagesModule } from './pages/pages.module';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { ToastrModule } from 'ngx-toastr';
import { TreetableModule } from 'ng-material-treetable';
import { UIModule } from './ui/ui.module';
import { appSettingsReducer } from './store/reducers/app-settings.reducer';
import { pageDataReducer } from './store/reducers/page-data.reducer';

@NgModule({
  declarations: [
    AppComponent,
    HelpersComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    LayoutModule,
    MaterialModule,
    TreetableModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, { useHash: false, onSameUrlNavigation: 'reload' }),
    ToastrModule.forRoot(),
    StoreModule.forRoot({
      pageData: pageDataReducer,
      appSettings: appSettingsReducer,

    }),
    RoutingModule,
    PagesModule,
    UIModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
