<img src="{{ img }}" *ngIf="img" alt="" class="card-img">

<div class="card-header" *ngIf="title">
  <h3 class="card-title">{{ title }}</h3>
</div>

<ng-content select="tc-card-header"></ng-content>

<div class="card-content" [ngStyle]="{ 'padding': padding }">
  <ng-content></ng-content>
</div>
