/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./block-template.component";
var styles_BlockTemplateComponent = ["[_nghost-%COMP%] {\n      text-align: center;\n      color: #1976D2;\n    }"];
var RenderType_BlockTemplateComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_BlockTemplateComponent, data: {} });
export { RenderType_BlockTemplateComponent as RenderType_BlockTemplateComponent };
export function View_BlockTemplateComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "block-ui-template"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "loader-overlay"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "app-loader main-loader"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_BlockTemplateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "block-temp", [], null, null, null, View_BlockTemplateComponent_0, RenderType_BlockTemplateComponent)), i0.ɵdid(1, 49152, null, 0, i1.BlockTemplateComponent, [], null, null)], null, null); }
var BlockTemplateComponentNgFactory = i0.ɵccf("block-temp", i1.BlockTemplateComponent, View_BlockTemplateComponent_Host_0, {}, {}, []);
export { BlockTemplateComponentNgFactory as BlockTemplateComponentNgFactory };
