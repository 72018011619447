import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var QuestionService = /** @class */ (function () {
    function QuestionService(http) {
        this.http = http;
    }
    QuestionService.prototype.getQuestionsByThemesId = function (id, font, count, themes) {
        // console.log(id);
        // console.log(themes);
        return this.http.post(environment.apiUrl + "/Question/byThemes/" + id + "/" + font + "/" + count, { themes: themes });
    };
    QuestionService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl + "/Question/");
    };
    QuestionService.prototype.createQuestion = function (description, help, questionOption0, questionOption1, questionOption2, questionOption3, isAnswer, active, font, themes) {
        return this.http.post(environment.apiUrl + "/Question/", {
            description: description, help: help, questionOption0: questionOption0, questionOption1: questionOption1, questionOption2: questionOption2, questionOption3: questionOption3, isAnswer: isAnswer, active: active, font: font, themes: themes
        });
    };
    QuestionService.prototype.updateQuestion = function (id, description, help, questionOption0, questionOption1, questionOption2, questionOption3, isAnswer, active, font, themes) {
        console.log(themes);
        return this.http.post(environment.apiUrl + "/Question/" + id, {
            description: description, help: help, questionOption0: questionOption0, questionOption1: questionOption1, questionOption2: questionOption2, questionOption3: questionOption3, isAnswer: isAnswer, active: active, font: font, themes: themes
        });
    };
    QuestionService.prototype.deleteQuestion = function (id) {
        return this.http.delete(environment.apiUrl + "/Question/" + id);
    };
    QuestionService.prototype.getQuestionsByCategoryId = function (id, font, count) {
        // console.log(font);
        return this.http.get(environment.apiUrl + "/Question/byCategory/" + id + "/" + font + "/" + count);
    };
    QuestionService.prototype.getQuestionListByUserId = function (id, categoryProductId, font, page) {
        return this.http.get(environment.apiUrl + "/Question/QuestionListByUserId/" + id + "/" + categoryProductId + "/" + font + "/" + page);
    };
    QuestionService.prototype.arrangeQuestionsByTheme = function (id, questions, startDt, endDt, testDefinitionId) {
        var x = [];
        questions.forEach(function (item) {
            x.push(item['questionId']);
            if (item.answerSelected == item['answerCorrect']) {
                x.push(1);
            }
            else {
                x.push(0);
            }
            //  x.push(item['answerID'+ (item.answerSelected + 1)]);
        });
        // console.log(x);
        return this.http.post(environment.apiUrl + "/Question/arrange/" + id + "/" + startDt + "/" + endDt + "/" + testDefinitionId, { x: x });
    };
    QuestionService.prototype.ratingQuestionsByTheme = function (userId, questions, categoryId, startDt, endDt, testDefinitionId, rightQuestions, wrongQuestions, font) {
        var x = [];
        questions.forEach(function (item) {
            var z = { 'questionId': item['questionId'], 'answerId': item['answerID' + (item.answerSelected + 1)] };
            x.push(z);
        });
        // console.log(x);
        return this.http.post(environment.apiUrl + "/Question/rating", { userId: userId, startDt: startDt, endDt: endDt, testDefinitionId: testDefinitionId, rightQuestions: rightQuestions, wrongQuestions: wrongQuestions, categoryId: categoryId, font: font, x: x });
    };
    QuestionService.prototype.getThemesByQuestionId = function (id) {
        return this.http.get(environment.apiUrl + "/Question/themesByQuestionId/" + id + "/");
    };
    QuestionService.prototype.getQuestionsByTheme = function (id) {
        return this.http.get(environment.apiUrl + "/Question/byTheme/" + id + "/");
    };
    QuestionService.prototype.exportQuestionsToTheme = function (id, categoryId, questions) {
        return this.http.put(environment.apiUrl + "/Question/export/" + id + "/" + categoryId + "/", { questions: questions });
    };
    QuestionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QuestionService_Factory() { return new QuestionService(i0.ɵɵinject(i1.HttpClient)); }, token: QuestionService, providedIn: "root" });
    return QuestionService;
}());
export { QuestionService };
