<div  *ngIf="!isResponded()">
  <h1 mat-dialog-title *ngIf="!hasQuestion()"><strong>Pedidos de Dúvidas </strong></h1>
  <h1 mat-dialog-title *ngIf="hasQuestion()"><strong>{{localData.subject}} </strong></h1>
</div>
<h1 mat-dialog-title *ngIf="isResponded() || !hasQuestion()"><strong>{{localData.subject}} </strong>
  <!-- <a style="float: right; cursor:pointer" (click)="closeDialog()"
    class="tc-icon-wrap icofont-close-circled"></a> -->
</h1>
<div mat-dialog-content style="overflow: hidden;">
  <form [formGroup]="emailForm">
    <!--Select Type VS Question-->
    <div *ngIf="!isResponded()">
      <mat-form-field *ngIf="!hasQuestion()">
        <mat-select  formControlName="requestType" placeholder="Tipo de Dúvida" class="select-box">
          <mat-option *ngFor="let state of requestType" [value]="state.value">
            {{ state.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="hasQuestion()" [formGroup]="form">
        <h6>Questão: </h6>
        <hr>
        <textarea formControlName="myQuestion" rows="5"  ngModel="{{localData.question}}" matInput style="overflow-wrap: break-word;"></textarea>
      </mat-form-field>
    </div>
    <mat-form-field *ngIf="isResponded()" [formGroup]="form">
      <h6>Questão: </h6>
      <hr>
      <textarea formControlName="myQuestion" rows="5"  ngModel="{{localData.question}}" matInput style="overflow-wrap: break-word;"></textarea>
    </mat-form-field>
    <!--Create Question VS Read Response-->
    <div  *ngIf="!isResponded()">
      <mat-form-field *ngIf="!hasQuestion()">
        <textarea formControlName="body" rows="10" placeholder="Insira a sua dúvida ..." matInput style="background-color: rgb(182, 182, 182); overflow-wrap: break-word;"></textarea>
      </mat-form-field>
      <mat-form-field *ngIf="hasQuestion()" [formGroup]="form">
      </mat-form-field>
    </div>
    <mat-form-field *ngIf="isResponded()" [formGroup]="form">
      <h6>Resposta: </h6>
      <hr>
      <textarea formControlName="myForm" rows="10" ngModel="{{localData.response}}" matInput style="background-color: rgb(182, 182, 182); overflow-wrap: break-word;"></textarea>
    </mat-form-field>
    <!--Buttons-->
    <div>
      <button *ngIf="!hasQuestion() " tc-button [beforeIcon]="'icofont-paper-plane'" [view]="'success'" (click)="onSubmit()">Enviar</button>
      &nbsp;
      <button *ngIf="!hasQuestion()" tc-button [beforeIcon]="'icofont-close-circled'" (click)="closeDialog()" [view]="'warning'">Cancelar</button>
      <button tc-button *ngIf="hasQuestion()" [beforeIcon]="'icofont-eye-blocked'" (click)="closeDialog()" [view]="'success'">Voltar</button>
    </div>
  </form>
</div>