<div style="display: inline-table;">
  <div style="display: table-cell; width: 99%;text-align: left;">
      <h4 small>{{action}} {{localData.name}}</h4>
  </div>
  <div style="display: table-cell; width: 1%; text-align: right; vertical-align: top;">
      <a mat-dialog-close style="float: right; cursor:pointer" class="tc-icon-wrap icofont-close-circled"></a>        
  </div>
</div>
<div style="clear: both; width: 100%;" >
  <div class="tab">
    <button class="tablinks" (click)="openCity($event, 'Geral')">Geral</button>
    <button class="tablinks" (click)="openCity($event, 'QuestTheme')">Questões do Tema</button>
</div>
<div id="Geral" class="tabcontent" style="display: block;">
  <form [formGroup]="themesForm">
    <tc-form-group>
      <tc-form-label>Nome</tc-form-label>
      <tc-input [placeholder]="'Nome'" [type]="'text'" formControlName="name" [(ngModel)]="localData.name"></tc-input>
    </tc-form-group>
    
    <tc-form-group>
      <tc-form-label>Categorias</tc-form-label>
      <div *ngFor="let state of categories; let i=index;">
      <tc-checkbox [value]="isSelected(state.categoryId)" (click)="selectCategory(i)">{{ state.name }}</tc-checkbox>
      </div>       
    </tc-form-group>
  </form>
</div>

<div id="QuestTheme" class="tabcontent">
  <div class="row" style="padding-left:2%;padding-bottom:2%;padding-top:2%;">
    <button tc-button class="btn-default" (click)="openFilter()" [view]="'default'">Filtro</button>
  </div>
    <div style="border:1px solid #ccd3d9; border-radius: 10px; background-color: aliceblue;" id="filtro" class="hide">
      <div class="row" style="padding-left: 2%;padding-bottom: 2%;padding-top: 1%;">
        <button tc-button class="btn-default" (click)="reset()" [view]="'default'">Reset</button>
      </div>
      <div class="row" style="padding-left: -2%;padding-bottom: 2%;">
        <div class="col-1" style="padding-top: 3px; padding-left: 6%;">Id:</div>
        <div class="col-2"><input type="number" [(ngModel)]="idValue" class="form-control"></div>
        <div class="col-2" style="padding-top: 3px;">Descrição:</div>
        <div class="col-2"><input type="text" [(ngModel)]="descValue" class="form-control"></div>
        <div class="col-1" style="padding-top: 3px;">Fonte:</div>
        <div class="col-2"><input type="text" [(ngModel)]="fontValue" class="form-control"></div>
        <div class="col-2"><button tc-button class="btn-success" [view]="'default'" (click)="filter()">Filtrar</button>
        </div>
      </div>
    </div>
    <nz-table #rowSelectionTable [nzData]="listOfAllData" [nzBordered]="bordered" nzShowSizeChanger [nzPaginationPosition]="position" (nzCurrentPageDataChange)="currentPageDataChange($event)">
      <thead (nzSortChange)="sort($event)" nzSingleSort class="noClick">
        <tr>
          <th nzShowSort nzSortKey="questionId">Id </th>
          <th nzShowSort nzSortKey="description">Descrição </th>
          <th nzShowSort nzSortKey="font">Font</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of rowSelectionTable.data" (click)="select($event)" (dblclick)="openDialog('Modificar', $event)">
          <td>{{ data.questionId }}</td>
          <td>{{ data.description }}</td>
          <td>{{ data.font }}</td>
          <td nzShowCheckbox [(nzChecked)]="data.active" [nzDisabled]="true"></td>
        </tr>
      </tbody>
    </nz-table>
</div>
<mat-dialog-actions layout="row">
  <button tc-button [view]="'success'" (click)="onSubmit()" style="margin: 2px;" [beforeIcon]="'icofont-diskette'">Submeter</button>
  <button tc-button [view]="'warning'" (click)="closeDialog()" style="margin: 2px;" [beforeIcon]="'icofont-close-circled'"></button>
</mat-dialog-actions>