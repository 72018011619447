import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
var TCAvatarComponent = /** @class */ (function () {
    function TCAvatarComponent() {
        this.landscape = false;
        this.portrait = false;
        this.size = 100;
        this.loaded = false;
        this.landscape = false;
        this.portrait = false;
    }
    Object.defineProperty(TCAvatarComponent.prototype, "height", {
        get: function () {
            return this.size + "px";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCAvatarComponent.prototype, "width", {
        get: function () {
            return this.size + "px";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCAvatarComponent.prototype, "minWidth", {
        get: function () {
            return this.size + "px";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCAvatarComponent.prototype, "minHeight", {
        get: function () {
            return this.size + "px";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCAvatarComponent.prototype, "fontSize", {
        get: function () {
            return this.size / 2.4 + "px";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCAvatarComponent.prototype, "backgroundColor", {
        get: function () {
            if (this.initials) {
                return this.bgColor || '#805aff';
            }
        },
        enumerable: true,
        configurable: true
    });
    TCAvatarComponent.prototype.ngOnInit = function () { };
    TCAvatarComponent.prototype.ngOnChanges = function (changes) {
        //console.log(changes);
        this.imageLoaded();
    };
    TCAvatarComponent.prototype.imageLoaded = function () {
        this.initials = '';
        this.loaded = true;
        var img = document.getElementById('imgOriginal');
        //console.log(this.size);
        this.portrait = false;
        this.landscape = false;
        var element = document.getElementById('imgBig');
        var elementSmall = document.getElementById('imgSmall');
        if (img) {
            if (img.height >= img.width) {
                this.portrait = true;
                if (element)
                    element.className = 'imgPort';
                if (elementSmall)
                    elementSmall.className = 'imgPortSmall';
                //console.log("portrait");
            }
            else {
                this.landscape = true;
                if (element)
                    element.className = 'imgLand';
                if (elementSmall)
                    elementSmall.className = 'imgLandSmall';
                //console.log("landscape");
            }
        }
    };
    return TCAvatarComponent;
}());
export { TCAvatarComponent };
