import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../../../services/auth.service';
import { BasePageComponent } from '../../../base-page';
import { CompanyService } from 'src/app/services/company.service';
import { HttpService } from '../../../../services/http/http.service';
import { IAppState } from '../../../../interfaces/app-state';
import { IOption } from '../../../../ui/interfaces/option';
import { MediaService } from '../../../../services/media.service';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../../../models/user';
import { UserService } from '../../../../services/user.service';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';

@Component({
  selector: 'page-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.scss']
})
export class PageEditAccountComponent extends BasePageComponent implements OnInit, OnDestroy {
  userInfo: any;
  userDetails : any;
  passwordInfo: any;
  userForm: FormGroup;
  passwordForm: FormGroup;
  gender: IOption[];
  status: IOption[];
  currentAvatar: string | ArrayBuffer;
  changes: boolean;
  apiKey: string = '';
  currentUser: User;

  tableData: [] = [];
  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthService,
    private userService: UserService,
    private mediaService: MediaService,
    private companyService: CompanyService,
    private toastr: ToastrService
  ) {
    super(store, httpSv);

    this.pageData = {
      title: 'Editar Perfil',
      loaded: true
    };
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

    this.actualizeImg (this.currentUser.user.image);
    
    this.changes = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.getData(`${environment.apiUrl}/Company/`, 'tableData', 'setLoaded');
    this.setLoaded();
    console.log("NGONINIT");
    this.userDetails = JSON.parse(localStorage.getItem('currentUserDetails'));
    this.inituserForm(this.userDetails);
  }

  ngOnDestroy() {

    super.ngOnDestroy();
  }

  openDocumentation()
  {
    window.open(environment.swagger, '_blank');
  }

  inituserForm(data: User) {
    this.userForm = this.formBuilder.group({
      img: [this.currentAvatar],
      name: [data['name'], Validators.required],
      email: [data['email'], Validators.required],
      nif: [data['nif']],
      cc: [data['cc']],
      apikey: [data['systemId']]
    })

    // detect form changes
    this.userForm.valueChanges.subscribe(() => {
      this.changes = true;
    });
  }

  // save form data
  saveData(form: FormGroup) {
    if (form.valid) {
      this.userInfo = form.value;
      this.changes = false;

      this.userService.updateUserInfo(this.currentUser.user.id, '', form.value['name'], form.value['email'], form.value['nif'], form.value['cc']).pipe(first()).subscribe(data => {
        this.toastr.success('Perfil Modificado com sucesso!');
        document.getElementsByClassName('currentName')[0].innerHTML = form.value['name'];
        localStorage.setItem('currentUserDetails', JSON.stringify(data));
        super.sendMessage();
      });
    }
  }

  
  // upload new file
  onFileChanged(inputValue: any) {
    const file: File = inputValue.target.files[0];
    if (file === null) {
      return;
    }

    const mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      alert('Imagem inválida.');
      return;
    }
    const reader = new FileReader();
    
    reader.readAsDataURL(file);
    // tslint:disable-next-line: variable-name
    reader.onload = (_event) => {
      this.currentAvatar = reader.result;
      let url = reader.result.toString().split(',')[1];
      this.mediaService.createImageToUser(this.currentUser.user.id, url, mimeType,
        file.name, file.size.toString()).pipe(first()).subscribe(user => {
          console.log('saved');
          super.sendMessage();
        });
    };
  }

  actualizeImg(img:any)
  {
    if(img && img != '')
      this.currentAvatar = "data:image/jpg;base64," + img;
    else
      this.currentAvatar = "assets/avatar.jpg";
  }

  isAdmin() {
    return (this.currentUser.user.role == 'Administrador' ? true : false);
  }
}
