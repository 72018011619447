var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit, OnDestroy } from '@angular/core';
import { QuestionService } from '../../../../services/question.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { DialogBoxTestResultComponent } from '../../DialogBoxes/dialog-box-test-result/dialog-box-test-result.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AuthService } from '../../../../services/auth.service';
import { BlockTemplateComponent } from '../../block-template/block-template.component';
import { BlockUI } from 'ng-block-ui';
import { first } from 'rxjs/internal/operators/first';
import { TestDefinitionService } from '../../../../services/test-definition.service';
import { ConfirmTerminateComponent } from '../../DialogBoxes/confirm-terminate/confirm-terminate.component';
import { QuestionHelpComponent } from "../../DialogBoxes/questionHelp/questionHelp.component";
import { Utils } from 'src/utils/utils';
var CamWizardComponent = /** @class */ (function () {
    function CamWizardComponent(route, dialog, dialogRef, testDefinitionService, router, questionService, authenticationService, 
    // private testService: TestService,
    data, utils) {
        var _this = this;
        this.route = route;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.testDefinitionService = testDefinitionService;
        this.router = router;
        this.questionService = questionService;
        this.authenticationService = authenticationService;
        this.data = data;
        this.utils = utils;
        this.selectedTab = 0;
        this.secondsLeft = 59;
        this.pieTimer = 0;
        this.pie = 2;
        this.isFinished = false;
        this.selected = new FormControl(0);
        this.selectedThemes = [];
        this.blockTemplate = BlockTemplateComponent;
        this.SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };
        this.blockUI.start('A preparar o seu Teste...');
        setTimeout(function () {
            _this.blockUI.stop();
        }, 2500);
        this.localData = __assign({}, data);
        //console.log(this.localData);
        this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        var va = this.currentUser.user.name.split(' ');
        if (va.length > 1) {
            this.currentName = va[0] + ' ' + va[va.length - 1];
        }
        else
            this.currentName = va[0];
        this.type = "CAM";
        if (this.localData.type == 'Exame') {
            this.testDefinitionService.getTestDefinitionByCategoryId(this.localData.id, this.localData.type).subscribe(function (definition) {
                _this.numberOfQuestions = definition.numberOfQuestions;
                _this.minutesLeft = definition.duration - 1;
                _this.definitionId = definition.testDefinitionId;
                var tabList = document.getElementsByClassName('mat-tab-list')[0];
                _this.type = definition.testTypeName;
                //console.log(definition.testTypeName);
                _this.questionService.getQuestionsByCategoryId(_this.localData.id, _this.localData.font, _this.numberOfQuestions).subscribe(function (questions) {
                    _this.questions = questions;
                });
            });
        }
        else {
            this.localData.themes.forEach(function (w) { _this.selectedThemes.push(Number(w)); });
            this.testDefinitionService.getTestDefinitionByCategoryId(this.localData.id, this.localData.type).subscribe(function (definition) {
                _this.numberOfQuestions = definition.numberOfQuestions;
                _this.minutesLeft = definition.duration;
                _this.definitionId = definition.testDefinitionId;
                var tabList = document.getElementsByClassName('mat-tab-list')[0];
                _this.type = definition.testTypeName;
                //console.log(definition.testTypeName);
                _this.questionService.getQuestionsByThemesId(_this.localData.id, _this.localData.font, _this.numberOfQuestions, _this.selectedThemes).subscribe(function (questions) {
                    _this.questions = questions;
                });
            });
        }
    }
    CamWizardComponent.prototype.getNumber = function (num) {
        return new Array(num);
    };
    CamWizardComponent.prototype.startTimer = function () {
        var _this = this;
        this.startDt = (Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toString();
        this.interval = setInterval(function () {
            if (_this.isFinished) { }
            else {
                if (_this.minutesLeft > 0 || _this.secondsLeft > 0) {
                    _this.secondsLeft--;
                    if (_this.secondsLeft < 0) {
                        _this.minutesLeft--;
                        _this.secondsLeft = 59;
                    }
                }
                if (_this.minutesLeft == 0 && _this.secondsLeft == 0 && _this.isFinished == false && _this.onPage == true) {
                    _this.terminateTimeExam();
                }
            }
        }, 1000);
    };
    CamWizardComponent.prototype.ngOnInit = function () {
        this.onPage = true;
        this.startTimer();
    };
    CamWizardComponent.prototype.ngOnDestroy = function () {
        this.onPage = false;
    };
    CamWizardComponent.prototype.a = function (e, i) {
        if (e.toElement.className.match('mat-tab-header-pagination-after')) {
            this.selected.setValue(i + 1);
        }
        else if (e.toElement.className.match('mat-tab-header-pagination-before')) {
            this.selected.setValue(i - 1);
        }
        else { }
    };
    CamWizardComponent.prototype.select = function (e, i, id) {
        var _this = this;
        id = id + 1;
        if (this.isFinished == false) {
            var tabGroupIndex = document.getElementsByClassName('mat-tab-body')[0].id.split('-')[3];
            var tab_1 = document.getElementById("mat-tab-label-" + tabGroupIndex + "-" + i);
            if (this.utils.isMobileDevice() == true) {
                var labels = document.querySelectorAll("li.input");
                var lis = document.querySelectorAll("li.answer");
                lis.forEach(function (element) {
                    element.classList.remove('active');
                    element.classList.remove('answered');
                    tab_1.classList.remove('label-answered');
                });
                if (lis[id - 1].className.match('active')) {
                    lis[id - 1].classList.remove('active');
                    lis[id - 1].classList.remove('answered');
                    tab_1.classList.remove('label-answered');
                }
                else {
                    lis[id - 1].classList.add('active');
                    lis[id - 1].classList.add('answered');
                    tab_1.classList.add('label-answered');
                }
                labels.forEach(function (element) {
                    // se estiver respondido e o id for diferente, retira
                    if (element.children[0].className.match('icofont-close') && Number(element.getAttribute('id')) != (id - 1)) {
                        element.children[0].classList.remove('icofont-close');
                    }
                    else {
                        //se o id for igual coloca
                        if (Number(element.getAttribute('id')) == (id - 1)) {
                            element.children[0].classList.add('icofont-close');
                            tab_1.classList.add('label-answered');
                        }
                    }
                });
                this.questions[i].answerSelected = id - 1;
            }
            else {
                var labels = document.querySelectorAll("li.withicon");
                labels.forEach(function (element) {
                    // se estiver respondido e o id for diferente, retira
                    if (element.children[0].className.match('icofont-close') && Number(element.getAttribute('id')) != (id - 1)) {
                        element.children[0].classList.remove('icofont-close');
                    }
                    else {
                        //se o id for igual coloca
                        if (Number(element.getAttribute('id')) == (id - 1)) {
                            element.children[0].classList.add('icofont-close');
                            tab_1.classList.add('label-answered');
                        }
                    }
                });
                this.questions[i].answerSelected = id - 1;
            }
        }
        else { }
        if ((i + 1) <= (this.questions.length - 1)) {
            setTimeout(function () {
                _this.selected.setValue(i + 1);
                _this.selectedIndex = i + 1;
            }, 500);
        }
    };
    CamWizardComponent.prototype.selectTab = function (isFinished, id) {
        var _this = this;
        if (isFinished == true) {
            if (this.utils.isMobileDevice()) {
                var tabGroupIndex = document.getElementsByClassName("mat-tab-body")[0].id.split("-")[3];
                //console.log(tabGroupIndex);
                var el = document.getElementById("mat-tab-content-" + tabGroupIndex + "-" + id);
                //console.log(tabGroupIndex);
                var answers = el.querySelectorAll("li.answer");
                //console.log(answers);
                //console.log(id);
                answers.forEach(function (element) {
                    if (Number(element.getAttribute("id")) ==
                        _this.questions[id]["answerCorrect"]) {
                        element.classList.remove("answered");
                        element.classList.add("correct");
                    }
                    if (element.className.match("answered") &&
                        Number(element.getAttribute("id")) !=
                            _this.questions[id]["answerCorrect"]) {
                        element.classList.remove("answered");
                        element.classList.add("wrong");
                    }
                });
                el.children[0].querySelectorAll("li.answer").forEach(function (elem) {
                    elem.parentElement.classList.add("disabled");
                });
            }
            else {
                var tabGroupIndex = document.getElementsByClassName("mat-tab-body")[0].id.split("-")[3];
                //console.log(tabGroupIndex);
                var el = document.getElementById("mat-tab-content-" + tabGroupIndex + "-" + id);
                //console.log(tabGroupIndex);
                var answers = el.querySelectorAll("li.withicon");
                answers.forEach(function (element) {
                    if (Number(element.getAttribute("id")) == _this.questions[id]["answerCorrect"]) {
                        element.children[0].classList.add('icofont-tick-mark');
                        element.children[0].classList.add('correctIcon');
                    }
                    if (element.children[0].className.match('icofont-close') && Number(element.getAttribute("id")) != _this.questions[id]["answerCorrect"]) {
                        element.children[0].classList.add('wrongIcon');
                    }
                });
                el.querySelectorAll('li.withicon').forEach(function (elem) {
                    elem.parentElement.classList.add('disabled');
                });
                el.querySelectorAll('li.option').forEach(function (elem) {
                    elem.parentElement.classList.add('disabled');
                });
                el.querySelectorAll("li.answer").forEach(function (elem) {
                    elem.parentElement.classList.add("disabled");
                });
            }
        }
    };
    CamWizardComponent.prototype.terminateTimeExam = function () {
        var _this = this;
        var width = this.utils.isMobileDevice() == true ? "100vw" : "30vw";
        var height = this.utils.isMobileDevice() == true ? "auto" : "auto";
        var row = {};
        row.text = 'Acabou o tempo para realizar o teste';
        var dialogRef = this.dialog.open(ConfirmTerminateComponent, {
            id: 'confirm',
            width: width,
            height: height,
            data: row,
            disableClose: true,
            closeOnNavigation: true
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === undefined) { }
            else if (result.event == 'sim') {
                _this.endExame();
            }
        });
    };
    CamWizardComponent.prototype.openHelp = function () {
        var width = this.utils.isMobileDevice() ? "90vw" : "30vw";
        var maxWidth = this.utils.isMobileDevice() ? "99vw" : "30vw";
        this.selectedTab = this.selected.value;
        var dialogRef = this.dialog.open(QuestionHelpComponent, {
            data: this.questions[this.selectedTab]["help"],
            width: width,
            height: 'auto',
            disableClose: false,
            closeOnNavigation: true,
            maxWidth: maxWidth,
            maxHeight: "80vh",
        });
    };
    CamWizardComponent.prototype.endExame = function () {
        var _this = this;
        this.isFinished = true;
        var i = 0;
        var wrong = 0;
        var count = this.numberOfQuestions;
        //console.log(count);
        this.questions.forEach(function (element) {
            var tabGroupIndex = document
                .getElementsByClassName("mat-tab-body")[0]
                .id.split("-")[3];
            //console.log(tabGroupIndex);
            var tab = document.getElementById("mat-tab-label-" + tabGroupIndex + "-" + i);
            //console.log(tab);
            tab.classList.remove("label-answered");
            if (element.answerSelected == undefined || element.answerSelected == null) {
                element.answerSelected = -1;
                count--;
                tab.classList.add("wrong");
                wrong++;
            }
            else {
                if (element.answerSelected == element["answerCorrect"]) {
                    tab.classList.add("correct");
                }
                else {
                    tab.classList.add("wrong");
                    wrong++;
                }
            }
            i++;
        });
        this.selected.setValue(0);
        this.selectedIndex = 0;
        var row = {};
        row.route = this.localData;
        row.wrongQuestions = wrong;
        row.questions = this.questions;
        row.startDt = this.startDt;
        row.endDt = (Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toString();
        row.count = count >= this.numberOfQuestions * 0.5 ? true : false;
        row.testDefinitionId = this.definitionId;
        row.categoryId = this.localData.id;
        row.userId = this.currentUser.user.id;
        row.font = this.localData.font;
        var widthTest = this.utils.isMobileDevice() == true ? "95vw" : "70vw";
        if (count == 0)
            this.refreshTabs();
        var dialogRefr = this.dialog.open(DialogBoxTestResultComponent, {
            id: "testResult",
            width: widthTest,
            maxWidth: widthTest,
            data: row,
            height: 'auto',
            maxHeight: '82vh',
        });
        dialogRefr
            .afterClosed()
            .pipe(first())
            .subscribe(function () {
            _this.dialogRef._containerInstance.dispose();
            var elem = document.getElementById("ter");
            elem.style.display = "none";
            _this.selectTab(_this.isFinished, 0);
        });
    };
    CamWizardComponent.prototype.refreshTabs = function () {
        var _this = this;
        var i = 0;
        this.questions.forEach(function (element) {
            _this.selectTab(_this.isFinished, i);
            i++;
        });
    };
    CamWizardComponent.prototype.terminate = function () {
        var _this = this;
        var width = this.utils.isMobileDevice() ? "90vw" : "30vw";
        var maxWidth = this.utils.isMobileDevice() ? "99vw" : "30vw";
        var height = this.utils.isMobileDevice() ? "auto" : "auto";
        var row = {};
        row.text = "Tem a certeza que quer terminar o teste?";
        var dialogRef = this.dialog.open(ConfirmTerminateComponent, {
            id: "confirm",
            width: width,
            maxWidth: maxWidth,
            height: height,
            data: row,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === undefined) {
            }
            else if (result.event == "sim") {
                _this.endExame();
            }
        });
    };
    CamWizardComponent.prototype.close = function () {
        var _this = this;
        var width = this.utils.isMobileDevice() ? "90vw" : "30vw";
        var maxWidth = this.utils.isMobileDevice() ? "99vw" : "30vw";
        var height = this.utils.isMobileDevice() ? "auto" : "auto";
        var row = {};
        row.text =
            this.isFinished == true
                ? "Tem a certeza que quer sair?"
                : "Tem a certeza que quer sair? Perderá os dados deste Teste!";
        var dialogRef = this.dialog.open(ConfirmTerminateComponent, {
            id: "confirm",
            width: width,
            maxWidth: maxWidth,
            height: height,
            data: row,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === undefined) {
            }
            else if (result.event == "sim") {
                _this.dialogRef.close({ event: "Cancel" });
            }
        });
    };
    CamWizardComponent.prototype.indexChange = function (event) {
        //console.log("indexChange");
        //console.log(event);
        this.selected.setValue(event);
        //this.selectTab(this.isFinished, this.selected.value);
    };
    // Action triggered when user swipes
    CamWizardComponent.prototype.swipe = function (selectedIndex, action) {
        //console.log("swipe");
        //console.log("number",this.selected.value);
        //console.log(action);
        //console.log(this.questions.length - 1);
        if (action === void 0) { action = this.SWIPE_ACTION.RIGHT; }
        // Out of range
        if (this.selected.value < 0 || this.selected.value > (this.questions.length - 1))
            return;
        // Swipe left, next tab
        if (action === this.SWIPE_ACTION.LEFT) {
            var isLast = this.selected.value === (this.questions.length - 1);
            //console.log("isLast", isLast);
            this.selected.setValue(isLast ? 0 : this.selected.value + 1);
            //console.log("Swipe right - INDEX: " + this.selected.value);
        }
        // Swipe right, previous tab
        if (action === this.SWIPE_ACTION.RIGHT) {
            var isFirst = this.selected.value === 0;
            //console.log("isFirst", isFirst);
            this.selected.setValue(isFirst ? 1 : this.selected.value - 1);
            //console.log("Swipe left - INDEX: " + this.selected.value);
        }
    };
    __decorate([
        BlockUI(),
        __metadata("design:type", Object)
    ], CamWizardComponent.prototype, "blockUI", void 0);
    return CamWizardComponent;
}());
export { CamWizardComponent };
