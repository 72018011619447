var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as xlsx from 'xlsx';
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { AlertMailService } from '../../../../services/alert-mail.service';
import { AuthService } from '../../../../services/auth.service';
import { BasePageComponent } from '../../../../pages/base-page';
import { CompanyService } from '../../../../services/company.service';
import { ConfirmComponent } from '../../DialogBoxes/confirm/confirm.component';
import { DialogBoxUsersComponent } from '../../DialogBoxes/dialog-box-users/dialog-box-users.component';
import { ELearningService } from 'src/app/services/elearning.service';
import { HttpService } from '../../../../services/http/http.service';
import { MatDialog } from '@angular/material';
import { ProfileService } from '../../../../services/profile.service';
import { Store } from '@ngrx/store';
import { TicketService } from '../../../../services/ticket.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../../services/user.service';
import { Utils } from 'src/utils/utils';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';
/**
 * @title Data table with sorting, pagination, and filtering.
 */
var UsersComponent = /** @class */ (function (_super) {
    __extends(UsersComponent, _super);
    function UsersComponent(userService, dialog, companyService, store, authenticationService, profileService, httpSv, alertMailService, ticketService, elearning, toastr, utils) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.userService = userService;
        _this.dialog = dialog;
        _this.companyService = companyService;
        _this.authenticationService = authenticationService;
        _this.profileService = profileService;
        _this.alertMailService = alertMailService;
        _this.ticketService = ticketService;
        _this.elearning = elearning;
        _this.toastr = toastr;
        _this.utils = utils;
        _this.bordered = true;
        _this.position = 'bottom';
        _this.searchValue = '';
        _this.pageSize = 10;
        _this.listOfDisplayData = [];
        _this.listOfAllData = [];
        _this.list = [];
        _this.sortName = null;
        _this.sortValue = null;
        _this.mapOfSort = {
            name: null,
            userName: null,
            email: null,
            companyName: null,
            profileName: null
        };
        _this.mapOfCheckedId = {};
        _this.isAllDisplayDataChecked = false;
        _this.isIndeterminate = false;
        _this.isOperating = false;
        _this.numberOfChecked = 0;
        _this.currentPage = 0;
        _this.nameValue = '';
        _this.userNameValue = '';
        _this.companyValue = '';
        _this.profileValue = '';
        _this.licenseValue = '';
        _this.emailValue = '';
        _this.prev_index = '0';
        _this.opened = false;
        _this.getData(environment.apiUrl + "/User/", 'tableData', 'setLoaded');
        _this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        if (_this.currentUser.user.profileId == 6) {
            _this.companyService.getById(_this.currentUser.user.companyId).subscribe(function (a) { _this.companyName = a.name; });
            _this.userService.getAllStudentsByCompany(_this.currentUser.user.companyId, _this.currentUser.user.profileId).subscribe(function (user) {
                _this.listOfAllData = user;
                _this.list = user;
            });
        }
        else {
            if (_this.currentUser.user.profileId == 8) //IMT
             {
                _this.userService.getByProfileId(3).subscribe(function (user) {
                    _this.listOfAllData = user;
                    _this.list = user;
                });
            }
            else {
                _this.userService.getAll().pipe(first()).subscribe(function (user) {
                    _this.listOfAllData = user;
                    _this.list = user;
                });
            }
            _this.companyService.getAll().subscribe(function (company) {
                _this.schools = company;
            });
        }
        _this.closeDropdown = new EventEmitter();
        _this.profileService.getAll().subscribe(function (profiles) {
            _this.profiles = profiles;
        });
        return _this;
    }
    UsersComponent.prototype.currentPageDataChange = function ($event) {
        this.listOfDisplayData = $event;
    };
    UsersComponent.prototype.sort = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.search('');
        if (this.sortValue == '' || this.sortValue == null) {
            this.sortName = 'name';
            this.sortValue = 'ascend';
            this.filter();
        }
    };
    UsersComponent.prototype.reset = function () {
        this.nameValue = '';
        this.userNameValue = '';
        this.companyValue = '';
        this.profileValue = '';
        this.licenseValue = '';
        this.emailValue = '';
        this.search('');
        this.listOfAllData = this.list;
        this.sortName = null;
        this.sortValue = null;
        this.mapOfSort = {
            name: null,
            userName: null,
            email: null,
            companyName: null,
            profileName: null
        };
    };
    /**
     * @param event
     * @param index
     */
    UsersComponent.prototype.select = function (event, index) {
        document.getElementById(this.prev_index).classList.remove('active');
        document.getElementById(index).classList.add('active');
        this.prev_index = index;
    };
    UsersComponent.prototype.checkName = function (name, str) {
        var pattern = str.split("").map(function (x) {
            return "(?=.*" + x + ")";
        }).join("");
        var regex = new RegExp("" + pattern, "g");
        return name.match(regex);
    };
    UsersComponent.prototype.search = function (type) {
        var _this = this;
        var filterFunc = function (item) {
            var x = '' + item['name'].toLowerCase();
            var y = item['userName'].toLowerCase();
            var z = item['companyName'].toLowerCase();
            var w = '' + item['profileName'].toLowerCase();
            var e = item['email'].toLowerCase();
            var names = _this.nameValue.toLowerCase().split(" ");
            var name = '';
            var h = '';
            if (item['learningLicense'])
                h = item['learningLicense'].toLowerCase();
            //Search names
            if (names.length > 1) {
                for (var i = 0; i < names.length; i++) {
                    if (x.indexOf(names[i]) != -1 && x.indexOf(names[i + 1]) != -1) {
                        name = '0';
                    }
                }
            }
            else {
                if (x.indexOf(names[0]) != -1)
                    name = '0';
            }
            return (type == 'name' ? name != '' :
                type == 'userName' ? y.indexOf(_this.userNameValue.toLowerCase()) !== -1 :
                    type == 'companyName' ? z.indexOf(_this.companyValue.toLowerCase()) !== -1 :
                        type == 'profileName' ? w.indexOf(_this.profileValue.toLowerCase()) !== -1 :
                            type == 'email' ? e.indexOf(_this.emailValue.toLowerCase()) !== -1 :
                                h.indexOf(_this.licenseValue.toLowerCase()) !== -1);
        };
        var data = this.listOfAllData.filter(function (item) { return filterFunc(item); });
        this.listOfAllData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    UsersComponent.prototype.removeSelectedRows = function () {
        var _this = this;
        var row = {};
        var windowHeight = window.innerHeight * 0.23;
        row.text = 'Confirma a remoção do(s) utilizadore(s) selecionado(s)?';
        var dialogRef = this.dialog.open(ConfirmComponent, {
            id: 'confirm',
            data: row
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === undefined) { }
            else if (result.event == 'sim') {
                _this.listOfAllData.forEach(function (item) { return _this.mapOfCheckedId[item['userId']] == true ? _this.removeById(item['userId']) : ""; });
                /**
                 * TODO:remove is deprecate...
                const e = document.querySelectorAll('tr');
                e.forEach(el => {
                  if (el.className.match('active')) {
                    const index: number = this.listOfAllData.findIndex(d => d['name'] === el.children[1].lastChild.nodeValue.trim());
                    this.userService.deleteUser(this.listOfAllData[index]['userId']).subscribe((data) => {
                      this.userService.getAll().pipe(first()).subscribe(user => {
                        this.listOfAllData = user;
                      });
                      this.toastr.success('Utilizador(es) removido(s) com sucesso!');
                    },
                      err => {
                        this.toastr.error('Algo ocorreu mal!');
                      });
                  }
                });
                */
            }
        });
    };
    UsersComponent.prototype.openDialog = function (action, e, i) {
        //console.log('hello');
        //console.log(this.pageSize);
        var _this = this;
        if (e == null || !e.target.className.match('ant-table-column-sorters') && !e.target.className.match('ant-spin-container')) {
            var row_1 = {};
            row_1 = e == null ? {} : this.listOfAllData[(this.currentPage * this.pageSize + i)];
            row_1.action = action;
            if (row_1.action == 'Adicionar') {
                row_1.companyName = action == 'Adicionar' ? this.companyName : this.listOfAllData[(this.currentPage * this.pageSize + i)]['companyName'];
                if (action == 'Adicionar') {
                    row_1.userId = this.currentUser.user.id;
                }
                var deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
                var deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '850px';
                var deviceMaxHeight = this.utils.isMobileDevice() ? '97vh' : '97vh';
                var dialogRef = this.dialog.open(DialogBoxUsersComponent, {
                    width: deviceWidth,
                    maxWidth: deviceMaxWidth,
                    height: 'auto',
                    maxHeight: deviceMaxHeight,
                    data: row_1,
                    disableClose: true,
                    closeOnNavigation: true
                });
                dialogRef.afterClosed().subscribe(function (result) {
                    if (result === undefined) { }
                    else if (result.event == 'AdicionarGeral') {
                        _this.addRowData(result.data);
                    }
                    else if (result.event == 'ModificarGeral') {
                        _this.updateRowData(result.data);
                    }
                    // dialogRef.componentInstance = null;
                    // this.dialog.closeAll();
                });
            }
            else {
                this.userService
                    .GetLastDataById(row_1.userId)
                    .subscribe(function (a) {
                    console.log(a);
                    row_1.lastAccessEmail = a.sendedInfo;
                    row_1.created = a.created;
                    row_1.lastInteraction = a.lastInteraction;
                    row_1.companyName = action == 'Adicionar' ? _this.companyName : _this.listOfAllData[(_this.currentPage * _this.pageSize + i)]['companyName'];
                    if (action == 'Adicionar') {
                        row_1.userId = _this.currentUser.user.id;
                    }
                    var deviceWidth = _this.utils.isMobileDevice() ? '99vw' : '1000px';
                    var deviceMaxWidth = _this.utils.isMobileDevice() ? '99vw' : '90vw';
                    var deviceMaxHeight = _this.utils.isMobileDevice() ? '97vh' : '97vh';
                    console.log(row_1);
                    var dialogRef = _this.dialog.open(DialogBoxUsersComponent, {
                        width: deviceWidth,
                        maxWidth: deviceMaxWidth,
                        height: 'auto',
                        maxHeight: deviceMaxHeight,
                        data: row_1,
                        disableClose: true,
                        closeOnNavigation: true
                    });
                    dialogRef.afterClosed().subscribe(function (result) {
                        if (result === undefined) { }
                        else if (result.event == 'AdicionarGeral') {
                            _this.addRowData(result.data);
                        }
                        else if (result.event == 'ModificarGeral') {
                            _this.updateRowData(result.data);
                        }
                        // dialogRef.componentInstance = null;
                        // this.dialog.closeAll();
                    });
                });
            }
        }
    };
    UsersComponent.prototype.addRowData = function (rowObj) {
        var _this = this;
        this.userService.createUser('', rowObj.name, rowObj.email, rowObj.nif, rowObj.cc, rowObj.profileId, rowObj.companyId, rowObj.active, rowObj.studentNumber, rowObj.learningLicense, rowObj.contacts, '', '123456').subscribe(function (data) {
            if (_this.currentUser.user.profileId == 6) {
                _this.userService.getAllStudentsByCompany(_this.currentUser.user.companyId, _this.currentUser.user.profileId).subscribe(function (user) {
                    _this.listOfAllData = user;
                    _this.list = user;
                    _this.listOfDisplayData = user;
                    _this.filter();
                });
            }
            else {
                _this.userService.getAll().pipe(first()).subscribe(function (user) {
                    _this.listOfAllData = user;
                    _this.list = user;
                    _this.listOfDisplayData = user;
                    _this.filter();
                });
            }
            _this.toastr.success('Utilizador criado com sucesso!');
        }, function (err) {
            _this.toastr.error('Algo ocorreu mal!');
        });
    };
    UsersComponent.prototype.updateRowData = function (rowObj) {
        var _this = this;
        this.userService.updateUser(rowObj.userId, '', rowObj.name, rowObj.email, rowObj.nif, rowObj.cc, rowObj.profileId, rowObj.companyId, rowObj.active, rowObj.contacts, rowObj.studentNumber, rowObj.learningLicense, rowObj.userName, '').subscribe(function (data) {
            if (_this.currentUser.user.profileId == 6) {
                _this.userService.getAllStudentsByCompany(_this.currentUser.user.companyId, _this.currentUser.user.profileId).subscribe(function (user) {
                    _this.listOfAllData = user;
                    _this.list = user;
                    _this.filter();
                });
            }
            else {
                _this.userService.getAll().pipe(first()).subscribe(function (user) {
                    _this.listOfAllData = user;
                    _this.list = user;
                    _this.filter();
                });
            }
            _this.toastr.success('Utilizador modificado com sucesso!');
        }, function (err) {
            _this.toastr.error('Algo ocorreu mal!');
        });
    };
    // updateTicketData(rowObj) {
    //   this.ticketService.ticketByCategoryIdToUser(rowObj.companyId,
    //     rowObj.products, rowObj.durationId, rowObj.userId).subscribe((data) => {
    //       this.toastr.success('Tickets atribuidos com sucesso!');
    //     },
    //       err => {
    //         this.toastr.error('Algo ocorreu mal!');
    //       }
    //     );
    // }
    UsersComponent.prototype.haveSelected = function () {
        var _this = this;
        var sendId;
        sendId = [];
        this.listOfAllData.forEach(function (item) {
            if (_this.mapOfCheckedId[item['userId']] == true)
                sendId.push(item['userId']);
        });
        return (sendId.length > 0);
    };
    UsersComponent.prototype.export = function () {
        var _this = this;
        var sendId;
        sendId = [];
        this.listOfAllData.forEach(function (item) {
            if (_this.mapOfCheckedId[item['userId']] == true)
                sendId.push(item['userId']);
        });
        if (sendId.length == 0)
            return;
        this.elearning.getReport(sendId).subscribe(function (resume) {
            var res;
            var tableExport = [];
            res = resume.filter(function (x) { return sendId.find(function (i) { return i == x.userId; }); });
            res.forEach(function (element) {
                var z = {
                    'Licença de Aprendizagem': element['learningLicense'],
                    'Nome': element['name'],
                    'NIF': element['nif'],
                    'Cartão de Cidadão': element['cc'],
                    'Número': element['companyNumber'],
                    'Escola': element['company'],
                    'Categoria': element['category'],
                    'Concluído': element['finished'] === true ? 'Sim' : 'Não',
                    'Percentagem': element['concludedPercentage'] + '%',
                    'Tempo Total Plataforma': element['totalTimeInPlatform'],
                    'Temas Sub/Temas Concluídos': element['totalLessonConcluded'],
                    'Testes realizados': element['totalExamesConcluded']
                };
                tableExport.push(z);
            });
            var ws = xlsx.utils.json_to_sheet(tableExport);
            var wb = xlsx.utils.book_new();
            var wscols = [
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 25 },
                { wch: 30 }
            ];
            ws['!cols'] = wscols;
            xlsx.utils.book_append_sheet(wb, ws, 'Utilizadores');
            xlsx.writeFile(wb, 'utilizadores.xlsx');
        });
    };
    UsersComponent.prototype.refreshStatus = function () {
        var _this = this;
        this.isAllDisplayDataChecked = this.listOfAllData.every(function (item) { return _this.mapOfCheckedId[item['userId']]; });
        this.isIndeterminate = this.listOfAllData.some(function (item) { return _this.mapOfCheckedId[item['userId']]; }) && !this.isAllDisplayDataChecked;
        this.numberOfChecked = this.listOfAllData.filter(function (item) { return _this.mapOfCheckedId[item['userId']]; }).length;
    };
    UsersComponent.prototype.checkAll = function (value) {
        var _this = this;
        this.listOfAllData.forEach(function (item) { return (_this.mapOfCheckedId[item['userId']] = value); });
        this.refreshStatus();
    };
    /**
     * @param userId
     */
    UsersComponent.prototype.removeById = function (userId) {
        var _this = this;
        this.userService.deleteUser(userId).subscribe(function (data) { }, function (error) { _this.toastr.error('O utilizador tem informação associada!'); }, function () {
            if (_this.currentUser.user.profileId == 6) {
                _this.userService.getAllStudentsByCompany(_this.currentUser.user.companyId, _this.currentUser.user.profileId).subscribe(function (user) {
                    _this.listOfAllData = user;
                    _this.list = user;
                });
            }
            else {
                _this.userService.getAll().pipe(first()).subscribe(function (user) {
                    _this.listOfAllData = user;
                    _this.list = user;
                });
            }
            _this.toastr.success('Utilizador(es) removido(s) com sucesso!');
        });
    };
    UsersComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    UsersComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    UsersComponent.prototype.isAdmin = function () {
        return (this.currentUser.user.role == 'Administrador' ? true : false);
    };
    UsersComponent.prototype.onCloseDropdown = function () {
        this.closeDropdown.emit(true);
    };
    UsersComponent.prototype.openFilter = function () {
        var el = document.getElementById('filtro');
        if (this.opened == false) {
            el.classList.add('show');
            el.classList.remove('hide');
            this.opened = true;
        }
        else {
            el.classList.add('hide');
            el.classList.remove('show');
            this.opened = false;
        }
    };
    UsersComponent.prototype.filter = function () {
        //console.log('s');
        this.listOfAllData = this.list;
        if (this.nameValue != '') {
            this.search('name');
        }
        if (this.userNameValue != '') {
            this.search('userName');
        }
        if (this.companyValue != '') {
            this.search('companyName');
        }
        if (this.profileValue != '  ' && this.profileValue != '') {
            this.search('profileName');
        }
        if (this.emailValue != '') {
            this.search('email');
        }
        if (this.licenseValue != '') {
            this.search('license');
        }
    };
    UsersComponent.prototype.custom = function (i) {
        this.currentPage = i - 1;
    };
    UsersComponent.prototype.sendEmail = function (i) {
        var _this = this;
        this.alertMailService.sendAccountInfo(this.listOfAllData[(this.currentPage * this.pageSize + i)]['userId']).subscribe(function (a) {
            _this.toastr.success("Dados enviados com sucesso");
        });
    };
    UsersComponent.prototype.sendT = function () {
        var _this = this;
        this.listOfAllData.forEach(function (item) { return _this.mapOfCheckedId[item['userId']] == true ? _this.alertMailService.sendAccountInfo(item["userId"]).subscribe(function (a) { _this.toastr.success("Dados enviados com sucesso"); }) : ""; });
    };
    return UsersComponent;
}(BasePageComponent));
export { UsersComponent };
