import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Question } from '../models/question';
import { Theme } from '@fullcalendar/core';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  constructor(private http: HttpClient) { }

  getQuestionsByThemesId(id: number, font: string, count: number, themes: number[]) {
    // console.log(id);
    // console.log(themes);
    return this.http.post<Question[]>(`${environment.apiUrl}/Question/byThemes/${id}/${font}/${count}`, { themes });
  }
  getAll() {
    return this.http.get<Question[]>(`${environment.apiUrl}/Question/`);
  }
  createQuestion(description: string, help: string, questionOption0: string, questionOption1: string, questionOption2: string, questionOption3: string,
    isAnswer: number, active: boolean, font: string, themes: number[]) {
    return this.http.post<any>(`${environment.apiUrl}/Question/`, {
      description, help, questionOption0, questionOption1, questionOption2, questionOption3, isAnswer, active, font, themes
    }
    );
  }
  updateQuestion(id: number, description: string, help: string, questionOption0: string, questionOption1: string, questionOption2: string, questionOption3: string,
    isAnswer: number, active: boolean, font: string, themes: number[]) {
    console.log(themes)
    return this.http.post<any>(`${environment.apiUrl}/Question/${id}`, {
      description, help, questionOption0, questionOption1, questionOption2, questionOption3, isAnswer, active, font, themes
    }
    );
  }
  deleteQuestion(id: number) {
    return this.http.delete<Question>(`${environment.apiUrl}/Question/${id}`);
  }

  getQuestionsByCategoryId(id: number, font: string, count: number) {
    // console.log(font);
    return this.http.get<Question[]>(`${environment.apiUrl}/Question/byCategory/${id}/${font}/${count}`);
  }


  getQuestionListByUserId(id: number, categoryProductId: number, font: string, page: number) {
    return this.http.get<any[]>(`${environment.apiUrl}/Question/QuestionListByUserId/${id}/${categoryProductId}/${font}/${page}`);
  }

  arrangeQuestionsByTheme(id: number, questions: Question[], startDt: string, endDt: string, testDefinitionId: number) {
    let x: number[] = [];
    questions.forEach(item => {
      x.push(item['questionId']);
      if (item.answerSelected == item['answerCorrect']) {
        x.push(1);
      }
      else { x.push(0); }
      //  x.push(item['answerID'+ (item.answerSelected + 1)]);
    });
    // console.log(x);
    return this.http.post<any[]>(`${environment.apiUrl}/Question/arrange/${id}/${startDt}/${endDt}/${testDefinitionId}`, { x });
  }
  ratingQuestionsByTheme(userId: number, questions: Question[], categoryId: number, startDt: string, endDt: string, testDefinitionId: number, rightQuestions: number, wrongQuestions: number, font: string) {
    let x: any[] = [];
    questions.forEach(item => {
      var z = { 'questionId': item['questionId'], 'answerId': item['answerID' + (item.answerSelected + 1)] };
      x.push(z);
    });
    // console.log(x);
    return this.http.post<any[]>(`${environment.apiUrl}/Question/rating`, { userId, startDt, endDt, testDefinitionId, rightQuestions, wrongQuestions, categoryId, font, x });
  }

  getThemesByQuestionId(id: number) {
    return this.http.get<any[]>(`${environment.apiUrl}/Question/themesByQuestionId/${id}/`);
  }


  getQuestionsByTheme(id: number) {
    return this.http.get<any[]>(`${environment.apiUrl}/Question/byTheme/${id}/`);

  }

  exportQuestionsToTheme(id: number, categoryId: number, questions: number[]) {
    return this.http.put<any>(`${environment.apiUrl}/Question/export/${id}/${categoryId}/`, { questions });
  }
}