import { Component, OnInit, Optional, Inject, Input, ViewEncapsulation, HostListener, Pipe, ChangeDetectorRef,  ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { User } from '../../../models/user';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { WizardComponent } from '../Tests/wizard/wizard.component';
import { AuthService } from '../../../services/auth.service';
import { TestService } from '../../../services/test.service';
import { first } from 'rxjs/internal/operators/first';
import { FormControl } from '@angular/forms';
import { DialogBoxZoomComponent } from '../DialogBoxes/dialog-box-zoom/dialog-box-zoom.component';
import { BlockTemplateComponent } from '../block-template/block-template.component';
import { Subject } from 'rxjs/internal/Subject';
import { DomSanitizer } from '@angular/platform-browser';
import { QuestionHelpComponent } from '../DialogBoxes/questionHelp/questionHelp.component';
import { Utils } from 'src/utils/utils';
import { interval } from 'rxjs';
import { ELearningService } from 'src/app/services/elearning.service';

@Component({
  selector: 'app-lesson',
  templateUrl: './lesson.html',
  styleUrls: ['./lesson.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default
})

export class Lesson implements OnInit {
  currentUser: User;
  localData: any;
  startSeconds: any;
  spendSeconds: any;
  actualizeGeneral: any;
  actualizeThread: any;
  actualizeInfoThread: any;
  totalSeconds: any;
  percentage: number;
  UserELearningSessionId: number;
  UserELearningSessionIdBefore: number = -1;
  url: string;
  endSec: any;
  colorProgress = '#ffffff';
  dummy : boolean = false;
  userActivity;
  userInactive: Subject<any> = new Subject();
  isActivity: boolean = true;
  stopTime: any = 0;
  realTime: any;
  spendSave: any = 0;
  spendSaveBefore: any = -1;
  aux: any = 0;
  resume: boolean = false;
  pause: boolean = false;
  firstComplete: boolean = false;
  executing: boolean= false;


  public destroyed = new Subject<any>();
  
  constructor(public dialog: MatDialog, private testService: TestService, private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<WizardComponent>, private authenticationService: AuthService,
    private elearningService: ELearningService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public utils: Utils,
    private cd: ChangeDetectorRef,
    private elRef:ElementRef) 
  {
    this.localData = { ...data };

    console.log(this.localData);

    this.checkActivity();

    this.url = this.localData.lessonMaterial[0].urlMat;

    //console.log(this.localData);

    //console.log(this.url);
   
    this.UserELearningSessionId = 0;

    this.spendSeconds = utils.time2Seconds(this.localData.timeSpend);
    this.totalSeconds = utils.time2Seconds(this.localData.time);
    this.firstComplete = this.spendSeconds >= this.totalSeconds ? false : true;

    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
    });

    this.startSeconds = new Date().getTime() / 1000;
    this.endSec = new Date().getTime() / 1000;
    this.realTime = this.startSeconds;

    this.actualizeInfo();
    
    setTimeout(() => {
      this.actualizeThread = interval(100).subscribe(x => {
        this.actualize();
      });
  
      this.actualizeInfoThread =  interval(5000).subscribe(x => {
        this.actualizeInfo();
      });       
    }, 3000);
  }

  close() {
    this.actualize();
    this.actualizeInfo();
    this.executing = true;

    if(this.actualizeInfoThread)
      this.actualizeInfoThread.unsubscribe();
      
    if(this.actualizeThread)
      this.actualizeThread.unsubscribe();
      
    if(this.userInactive)
      this.userInactive.unsubscribe();
    
    clearTimeout(this.userActivity);

    this.dialogRef.close();
  }

  actualize() {
    if(!this.localData.dayClosed)
    {
      this.endSec = new Date().getTime() / 1000;
      this.realTime = this.isActivity ? this.realTime : this.endSec;

      var spend = this.endSec - this.realTime;
      //var spend = this.endSec - this.startSeconds;

      this.spendSave = spend != 0 ? this.spendSave + spend - this.aux : this.spendSave;
      //Ver se é um resume
      if(this.pause && spend != 0){
        this.resume = true
        this.pause = false
      }
      //Testa se está pausado
      this.pause = this.spendSave == this.spendSaveBefore ? true : false;
      //Se for resume temos que adicionar o tempo passado anteriormente
      if(this.resume){
        this.spendSave = this.spendSaveBefore + spend;
        this.resume = false;
      }
      this.aux = spend == 0 ? this.aux : spend;
      this.spendSaveBefore = this.spendSave;
      
      var runningSeconds = this.spendSeconds + this.spendSave;
      if(runningSeconds > this.totalSeconds) 
      {
        if(this.firstComplete){
        }
        if(!this.localData.isClosed)
          this.localData.isClosed = true;

        this.percentage = 100;
      }
      else this.percentage = Math.floor(runningSeconds / this.totalSeconds * 100.0);

      if(this.percentage == 100)
      {
        if(!this.dummy)
        {
          this.dummy = true;
          this.actualizeInfo();
        }

        this.colorProgress = '#A6CE3A';
      }
    }
  }
  
  actualizeInfo() {
    console.log("Fucntion In");
    console.log(this.executing);
    if(!this.executing){
      this.executing = this.percentage == undefined ? false : this.isActivity == false ? false : true;
      if(!this.localData.dayClosed){
        if(this.percentage < 100 || (this.percentage == 100 && this.firstComplete)){
          this.firstComplete = this.percentage == 100 ? false : this.firstComplete;
          if(this.isActivity){
            let secondsStart = this.stopTime == 0 ? this.startSeconds : this.stopTime;
            this.endSec = new Date().getTime() / 1000;
            console.log("UserELearningSessionIdBefore -> ", this.UserELearningSessionIdBefore);
            console.log("UserELearningSessionId -> ", this.UserELearningSessionId);
            if(this.UserELearningSessionId == 0)
            {
              console.log("### Create ###");
              console.log("SecondsStart -> ", new Date(secondsStart * 1000).toISOString().slice(11, 19));
              console.log("EndSec -> ", new Date(this.endSec * 1000).toISOString().slice(11, 19));
              this.elearningService.createSession(this.localData.userElearningLessonId, secondsStart, this.endSec).subscribe(x => {
                //console.log(x);
                this.UserELearningSessionId = x.userElearningLessonSessionId;
                this.localData.dayClosed = x.dayClosed;
                console.log(this.UserELearningSessionId);
                this.executing = false;
              });
            }
            else if (this.UserELearningSessionIdBefore <= this.UserELearningSessionId)
            {
              this.UserELearningSessionIdBefore = this.UserELearningSessionId;
              console.log("### Atualize ###");
              console.log("EndSec -> ", new Date(this.endSec * 1000).toISOString().slice(11, 19));
              this.elearningService.updateSession(this.UserELearningSessionId, this.endSec).subscribe(x => {
                this.localData.dayClosed = x.dayClosed;
                this.executing = false;
                //console.log(x);
              });
            }
          }
        }
      }
    }
  }
  
  checkActivity() {
    console.log('checkActivity');
    this.setTimeout();
    this.userInactive.subscribe(() => {
      this.actualizeInfo();
      this.isActivity = false;
      this.UserELearningSessionId = 0;
      console.log("Reset UserELearningSessionId");
      
    });
  }
  
  setTimeout() {
    try{
      this.userActivity = setTimeout(() => this.userInactive.next(undefined), 60000);
    }
    catch{

    }
  }
  
  @HostListener('window:mousemove')
  @HostListener('window:mousedown')
  @HostListener('window:keydown')
  @HostListener('window:mousescroll')
  refreshUserState(e) {
    this.stopTime = this.isActivity ? this.stopTime : new Date().getTime() / 1000;
    this.isActivity = true;
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  
  ngOnInit() {
    window.addEventListener('message', (evt) => this.refreshUserState(evt));
  }

}

