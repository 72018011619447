import * as i0 from "@angular/core";
var Utils = /** @class */ (function () {
    function Utils() {
    }
    Utils.prototype.isMobileDevice = function () {
        if (window.innerWidth >= 992)
            return false;
        if (window.innerWidth <= 991)
            return true;
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };
    ;
    Utils.prototype.transform = function (value) {
        return value.replace(/\n/g, '<br/>');
    };
    Utils.prototype.time2Seconds = function (value) {
        var arr = value.split(":");
        //console.log(arr);
        var seconds = Number(arr[0]) * 3600 + Number(arr[1]) * 60 + (+Number(arr[2]));
        return seconds;
    };
    Utils.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Utils_Factory() { return new Utils(); }, token: Utils, providedIn: "root" });
    return Utils;
}());
export { Utils };
