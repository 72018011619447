import { Component, OnInit, Optional, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-terminate',
  templateUrl: './confirm-terminate.component.html',
  styleUrls: ['./confirm-terminate.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class ConfirmTerminateComponent implements OnInit {
  localData: any;
  text: string;
  constructor(public dialogRef: MatDialogRef<ConfirmTerminateComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.localData = { ...data };
    this.text = this.localData.text;
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
  confirm() {
    this.dialogRef.close({ event: 'sim' });
  }

}
