import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AlertMailService = /** @class */ (function () {
    function AlertMailService(http) {
        this.http = http;
    }
    AlertMailService.prototype.sendRequest = function (id, subject, requestType, body) {
        return this.http.post(environment.apiUrl + "/AlertMail/sendRequest/" + id, { subject: subject, requestType: requestType, body: body });
    };
    AlertMailService.prototype.getRequests = function (id, profileId) {
        return this.http.get(environment.apiUrl + "/AlertMail/getRequests/" + id + "/" + profileId);
    };
    AlertMailService.prototype.getRequestsByProfile = function (id, profileId) {
        return this.http.get(environment.apiUrl + "/AlertMail/getRequestsByProfile/" + id + "/" + profileId);
    };
    AlertMailService.prototype.sendTeacherRequest = function (id, alertMailId, Subject, Body) {
        return this.http.post(environment.apiUrl + "/AlertMail/sendTeacherRequest/" + id, { alertMailId: alertMailId, Subject: Subject, Body: Body });
    };
    AlertMailService.prototype.getRequestsByUser = function (id) {
        return this.http.get(environment.apiUrl + "/AlertMail/getRequestsByUser/" + id);
    };
    AlertMailService.prototype.getRespondedRequestsByUser = function (id) {
        return this.http.get(environment.apiUrl + "/AlertMail/getRespondedRequestsByUser/" + id);
    };
    AlertMailService.prototype.sendAccountInfo = function (id) {
        return this.http.post(environment.apiUrl + "/AlertMail/sendAccountInfo/" + id, {});
    };
    AlertMailService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertMailService_Factory() { return new AlertMailService(i0.ɵɵinject(i1.HttpClient)); }, token: AlertMailService, providedIn: "root" });
    return AlertMailService;
}());
export { AlertMailService };
