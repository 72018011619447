/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./free.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./free.component";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../../../../../utils/utils";
var styles_FreeComponent = [i0.styles];
var RenderType_FreeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FreeComponent, data: {} });
export { RenderType_FreeComponent as RenderType_FreeComponent };
export function View_FreeComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_FreeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-free", [], null, null, null, View_FreeComponent_0, RenderType_FreeComponent)), i1.ɵdid(1, 49152, null, 0, i2.FreeComponent, [i3.MatDialog, i4.Utils], null, null)], null, null); }
var FreeComponentNgFactory = i1.ɵccf("app-free", i2.FreeComponent, View_FreeComponent_Host_0, {}, {}, []);
export { FreeComponentNgFactory as FreeComponentNgFactory };
