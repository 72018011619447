import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CategoryProduct } from '../models/CategoryProduct';
import { Category } from '../models/Category';

@Injectable({
  providedIn: 'root'
})
export class CategoryProductService {


  constructor(private http: HttpClient) { }
  getAll() {
    return this.http.get<CategoryProduct[]>(`${environment.apiUrl}/CategoryProduct/`);
  }
  getCategoriesByProductName(name: string) {
    return this.http.get<Category[]>(`${environment.apiUrl}/CategoryProduct/name/${name}`);
  }
  getCategoryProductListByUserId(id: number) {
    return this.http.get<any[]>(`${environment.apiUrl}/CategoryProduct/abc/${id}`);
  }
}
