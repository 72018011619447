<div class="row" *ngIf="categories.length > 1 && activeCategory">
  <div class="col col-12 col-md-12 mb-12 mb-md-12">
    <tc-card class="mb-2">
      <div class="row">
        <div class="container">
          <p>Escolha a categoria do Ensino à Distância</p>
  
          <mat-select placeholder="Categoria" class="select-box" required [(value)]="selectedValue">
            <mat-option *ngFor="let item of categories; let i = index;" [value]="item.categoryId" (click)="select($event,i, item)">
              {{ item.categoryName }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </tc-card>
  </div> 
</div>

<p *ngIf="showMessage && !activeCategory">Não existem senhas ativas de ensino à distância, consulte a sua escola para obter senhas.</p>

<app-elearning-student *ngIf="this.userId != 0 && activeCategory" [comeFromDashboard]="false" [userId]="this.userId" [categoryId]="activeCategory.categoryId" [categoryName]="activeCategory.categoryName"></app-elearning-student>

