<div class="row" style="padding-left: 1%;">
    <tc-card style="width: 99%;">
        <mat-label>Selecione o Intervalo de Tempo</mat-label> <br>
        <div class="row">
            <div class="col-9">
                <mat-form-field>
                    <mat-label>Data Inicial</mat-label>
                    <input matInput [matDatepicker]="dp" [formControl]="startDt">
                    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp></mat-datepicker>
                </mat-form-field>
                <mat-form-field style="padding-left: 25px;padding-right: 25px;">
                    <mat-label>Data Final</mat-label>
                    <input matInput [matDatepicker]="dp1" [formControl]="endDt">
                    <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                    <mat-datepicker #dp1></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-3">
                <button tc-button class="btn-success" (click)="date()" [beforeIcon]="'icofont-search-1'"
                    [view]="'success'">Filtrar Data</button>
            </div>
        </div>


    </tc-card>
</div>
<!-- <div class="row" style="padding-left:2%;padding-bottom:2%">
    <div class="col-5 text-left mt-2">
        <button tc-button class="btn-default" (click)="openFilter()" [beforeIcon]="'icofont-search-document'"
            [view]="'default'">Filtro</button>
    </div>
    <div class="col-3  text-right mt-2">
    </div>
    <div class="col-3  text-right mt-2">
        <button tc-button class="btn-secondary" (click)="export()" [beforeIcon]="'icofont-file-excel'"
            [view]="'secondary'">Exportar para Excel</button>
    </div>
    <div class="col-1"></div>
</div>
<div style="border:1px solid #ccd3d9; border-radius: 3px; background-color: aliceblue;" id="filtro" class="hide">
    <div class="row" style="padding-left: 2%;padding-bottom: 2%;padding-top: 1%;"><button tc-button class="btn-default"
            [beforeIcon]="'icofont-ui-reply'" (click)="reset()" [view]="'default'">Reset</button></div>
    <div class="row" style="padding-left: -2%;padding-bottom: 2%;">
        <div class="col-1" style="padding-top: 3px;">Escola:</div>
        <div class="col-2"><input type="text" [(ngModel)]="schoolValue" class="form-control"></div>
        <div class="col-1" style="padding-top: 3px;padding-left:1px">Utilizador:</div>
        <div class="col-2"><input type="text" [(ngModel)]="createdByValue" class="form-control"></div>
        <div class="col-1" style="padding-top: 3px;">Categoria:</div>
        <div class="col-2"><input type="text" [(ngModel)]="categoryValue" class="form-control"></div>
        <div class="col-1" style="padding-top: 3px;">Duração:</div>
        <div class="col-2" style="margin-left: -20px;"><input type="text" [(ngModel)]="durationValue"
                class="form-control">
        </div>
    </div>
    <div class="row" style="padding-bottom: 2%;">
        <div class="col-10"></div>
        <div class="col-2"><button tc-button class="btn-success" [view]="'default'" [beforeIcon]="'icofont-search-1'"
                (click)="filter()">Filtrar</button>
        </div>
    </div>
</div> -->
<tc-card>
    <div class="row" style="padding-left:2%;padding-bottom:2%">
        <div class="col-5 text-left mt-2">

        </div>
        <div class="col-3  text-right mt-2">
        </div>
        <div class="col-3  text-right mt-2">
            <button tc-button class="btn-default" (click)="export()" [beforeIcon]="'icofont-file-excel'"
                [view]="'default'">Exportar para Excel</button>
        </div>
        <div class="col-1"></div>
    </div>
    <nz-table #rowSelectionTable [nzData]="listOfAllData" [nzBordered]="bordered" nzShowSizeChanger
        (nzPageIndexChange)="custom($event)" [nzPaginationPosition]="position"
        (nzCurrentPageDataChange)="currentPageDataChange($event)" style="overflow: auto;">
        <thead (nzSortChange)="sort($event)" nzSingleSort>
            <tr class="noClick">
                <th>Escola</th>
                <th>Senhas Atribuidas</th>
                <th>Data de Atribuição</th>
                <th>Utilizador</th>
                <th>Categoria</th>
                <th>Duração</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of rowSelectionTable.data; let i=index;">
                <td>{{data.companyName}}</td>
                <td>{{data.quantity}}</td>
                <td>{{data.createdDate | date: 'dd/MM/yyyy H:mm:ss'}}</td>
                <td>{{data.createdBy}}</td>
                <td>{{data.categoryName}}</td>
                <td>{{data.durationName}}</td>
            </tr>
        </tbody>
    </nz-table>
</tc-card>