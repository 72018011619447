import { Injectable } from '@angular/core';
import { Company } from '../models/Company';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ProductModel } from '../models/productModel';

@Injectable({
    providedIn: 'root'
})
export class DidaticMaterialService {

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/didaticMaterial/`);
    }

    getDidacticMaterialByCompanyId(id: number){
        return this.http.get<ProductModel[]>(`${environment.apiUrl}/didaticMaterial/companyDidacticMaterial/${id}`);
    }
    getByCompanyId(id: number) {
        return this.http.get<any[]>(`${environment.apiUrl}/didaticMaterial/company/${id}`);
    }
    getByUserId(id: number) {
        return this.http.get<any[]>(`${environment.apiUrl}/didaticMaterial/user/${id}`);
    }
    getImageById(id: number) {
        return this.http.get<any>(`${environment.apiUrl}/didaticMaterial/image/${id}`);
    }
    updateMaterial(id: number, text: string) {
        return this.http.put<any>(`${environment.apiUrl}/didaticMaterial/${id}/${text}`, { text });
    }
    removeDidatic(id: number) {
        return this.http.delete<any>(`${environment.apiUrl}/didaticMaterial/${id}`);
    }
    getEbooksByCompanyId(id: number) {
        return this.http.get<any>(`${environment.apiUrl}/didaticMaterial/ebooks/company/${id}`);
    }
    getEbooksByUserId(id: number) {
        return this.http.get<any>(`${environment.apiUrl}/didaticMaterial/ebooks/user/${id}`);
    }
    createEbook(companyId: string, ebookDesc: string, ebookUrl: string) {
        return this.http.post<any>(`${environment.apiUrl}/didaticMaterial/ebooks`, {
            ebookDesc, companyId, ebookUrl
        }
        );
    }
}
