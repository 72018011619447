<div style="width: 100%;text-align: left; height: 30px; max-height: 30px;overflow: hidden;">
    <h4 small *ngIf="currentUser.user.profileId != 8">{{action}} {{localData.name}}</h4>
    <h4 small *ngIf="currentUser.user.profileId == 8"><b>{{localData.name}}</b></h4>
    <hr>
</div>

<mat-tab-group mat-align-tabs="start" class="tabContainer">
    <mat-tab label="Geral">
        <form [formGroup]="userGeralForm">

            <tc-form-group *ngIf="currentUser.user.profileId != 7 && currentUser.user.profileId != 8">
                <div class="row" style="padding-left: 10px;">
                    <tc-form-group class="col-lg-6 col-xl-6 col-sm-12 col-md-6 text-left">
                <tc-form-label>Activo</tc-form-label>
                <tc-switcher formControlName="status" [value]="localData.active">Activo</tc-switcher>
            </tc-form-group>
                    <tc-form-label class="col-lg-6 col-xl-6 col-sm-12 col-md-6 text-right" *ngIf="localData.action != 'Adicionar'">
                        <div *ngIf="currentUser.user.profileId == 1" style="font-weight: normal; padding-right: 5px;">
                            Criado a: {{localData.created | date: 'dd/MM/yyyy HH:mm:ss'}}
                        </div>
                        
                        <div style="font-weight: normal; padding-right: 5px;">
                            Dados de acesso enviados a: {{
                                localData.lastAccessEmail ? (localData.lastAccessEmail | date: 'dd/MM/yyyy HH:mm:ss') : 'NA'
                            }}
                        </div>

                        <div *ngIf="currentUser.user.profileId == 1" style="font-weight: normal; padding-right: 5px;">
                            Última interacção: {{
                                localData.lastInteraction ? (localData.lastInteraction | date: 'dd/MM/yyyy HH:mm:ss') : 'NA'
                            }}
                        </div>
                    </tc-form-label>                 
                </div>
            </tc-form-group>

            <tc-form-group *ngIf="currentUser.user.profileId != 6 && currentUser.user.profileId != 8 && currentUser.user.profileId != 7">
                <tc-form-label>Perfil</tc-form-label>
                <mat-select formControlName="profile" placeholder="Perfil" class="select-box">
                    <mat-option *ngFor="let state of profile" [value]="state.name" (click)="changeProfile()">
                        {{ state.name }}
                    </mat-option>
                </mat-select>
            </tc-form-group>

            <tc-form-group
                *ngIf="localData.profileName != 'Comercial' && companies != null && currentUser.user.profileId != 6 && currentUser.user.profileId != 8 && currentUser.user.profileId != 7">
                <tc-form-label>Escola</tc-form-label>
                <mat-select formControlName="company" placeholder="Escola" class="select-box">
                    <mat-option *ngFor="let state of companies" [value]="state.name" (click)="changeCompany()">
                        {{ state.name }}
                    </mat-option>
                </mat-select>
            </tc-form-group>

            <tc-form-group>
                <tc-form-label>Nome</tc-form-label>
                <tc-input [readonly]="this.isReadOnly()" [placeholder]="'Nome'" [type]="'text'" formControlName="name" [(ngModel)]="localData.name">
                </tc-input>
            </tc-form-group>
            <tc-form-group>
                <tc-form-label>Contactos</tc-form-label>
                <tc-input [readonly]="this.isReadOnly()" [placeholder]="'Contactos'" [type]="'text'" formControlName="contacts"
                    [(ngModel)]="localData.contacts">
                </tc-input>
            </tc-form-group>

            <tc-form-group *ngIf="localData.profileName == 'Aluno' || selectedValue == 'Aluno' || currentUser.user.profileId == 6 || currentUser.user.profileId == 8">
                <tc-form-label>Licença de Aprendizagem</tc-form-label>
                <tc-input [readonly]="this.isReadOnly()" [placeholder]="'Licença de Aprendizagem'" [type]="text" formControlName="learningLicense" [(ngModel)]="localData.learningLicense">
                </tc-input>
            </tc-form-group>


            <tc-form-group
                *ngIf="localData.profileName == 'Aluno' || selectedValue == 'Aluno' || currentUser.user.profileId == 6 || currentUser.user.profileId == 8">
                <tc-form-label>Nif</tc-form-label>
                <tc-input [readonly]="this.isReadOnly()" [placeholder]="'Nif'" [type]="'text'" formControlName="nif" [(ngModel)]="localData.nif">
                </tc-input>
            </tc-form-group>
            <tc-form-group
                *ngIf="localData.profileName == 'Aluno' || selectedValue == 'Aluno' || currentUser.user.profileId == 6 || currentUser.user.profileId == 8">
                <tc-form-label>Cc</tc-form-label>
                <tc-input [readonly]="this.isReadOnly()" [placeholder]="'Cc'" [type]="'text'" formControlName="cc" [(ngModel)]="localData.cc">
                </tc-input>
            </tc-form-group>
            <tc-form-group>
                <tc-form-label>Email</tc-form-label>
                <tc-input [readonly]="this.isReadOnly()" [placeholder]="'Email'" [type]="'email'" formControlName="email" (keyup)="onChanges()"
                    [(ngModel)]="localData.email">
                </tc-input>
                <tc-form-label *ngIf="emailExists == true" style="color:red">Email já em uso!!!</tc-form-label>
            </tc-form-group>

            <tc-form-group *ngIf="localData.profileName == 'Aluno' || selectedValue == 'Aluno' || currentUser.user.profileId == 6 || currentUser.user.profileId == 8">
                <tc-form-label>Número de Estudante</tc-form-label>
                <tc-input [readonly]="this.isReadOnly()" [placeholder]="'Número de Estudante'" [type]="text" formControlName="studentNumber" [(ngModel)]="localData.studentNumber" (keyup)="onChangesNumber()">
                </tc-input>
                <tc-form-label> Utilizador : {{localData.prefix}}{{localData.studentNumber}}</tc-form-label>
                <tc-form-label *ngIf="UserNameExists == true" style="color:red">Número de aluno já em uso!!!
                </tc-form-label>
            </tc-form-group>

            <tc-form-group *ngIf="currentUser.user.profileId == 8">
                <tc-form-label>Email instrutores</tc-form-label>
                <tc-input [readonly]="this.isReadOnly()" [placeholder]="'Email instrutores'" [type]="'email'" formControlName="emailInstrutores"
                    [(ngModel)]="localData.emailInstrutores != null ? localData.emailInstrutores : localData.emailEscola">
                </tc-input>
            </tc-form-group>

            <tc-form-group *ngIf="localData.profileName == 'Comercial'">
                <tc-form-label>Escolas a que Pertence</tc-form-label>
                <mat-select formControlName="companyN" placeholder="Escolas" [multiple]="true" [(ngModel)]="selectedCompanies" class="select-box">
                    <mat-option *ngFor="let state of companies" [value]="state.name" (click)="clickedOption()">
                        {{ state.name }}
                    </mat-option>
                </mat-select>
            </tc-form-group>

            <nz-table *ngIf="localData.profileName == 'Comercial'" #rowSelectionTableS [nzData]="schools"
                [nzShowPagination]="false" style="width: 98%;padding-left: 2%;" style="overflow: auto;">
                <thead>
                    <tr>
                        <th>Este Comercial tem as Seguintes escolas:</th>
                        <th> Remover</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of schools; let i=index;">
                        <td>{{data.name}}</td>
                        <td> <button tc-button [view]="'error'" (click)="deleteCompanyLinkToComercial(i)"
                                style="margin: 2px;" [beforeIcon]="'icofont-close-circled'"></button></td>
                    </tr>
                </tbody>
            </nz-table>
        </form>
    </mat-tab>

    <mat-tab label="Imagem">
        <img class="img-profile img-circle img-responsive center-block avatar" [src]="imgURL" alt="">
        <input #file type="file" id="file" accept='image/*' (change)="preview(file.files)" *ngIf="currentUser.user.profileId != 8" title="Seleccionar imagem" />
    </mat-tab>

    <mat-tab label="Senhas" *ngIf="this.localData.isReportPage != true || isAdmin()">
        <div style="width: 100%; padding: 2%;">
            <div class="row" style="padding: 5px; background-color: cadetblue; color: white;">
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6 text-left">
                    Descrição
                </div>
        
                <div class="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-center">
                    Duração
                </div>
                
                <div class="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-center">
                    Data
                </div>
        
                <div *ngIf="currentUser.user.profileId != 6" class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                    Estado
                </div>
        
                <div *ngIf="currentUser.user.profileId == 6" class="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 text-right">
                    Expiração
                </div>       
            </div>
            <div class="row striped" *ngFor="let item of tickets; let i=index;" style="padding: 5px;">
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6 text-left">
                    {{item.fullName}}
                </div>

                <div class="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-center">
                    <span class="badge badge-info" style="margin-left: 5px; background-color: lightskyblue;" rel="tooltip" matTooltip="Duração de Senhas atribuídas">{{item.durationName}}</span>
                </div>
                
                <div class="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-center">
                    {{item.ticketDate | date: 'dd/MM/yyyy'}}
                </div>

                <div *ngIf="currentUser.user.profileId != 6" class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                    <tc-switcher [value]="item.active" (click)="changeStatus(i)"></tc-switcher>
                </div>

                <div *ngIf="item.daysLeft > 0" class="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 text-right">
                    <span style="color:orangered ">{{item.daysLeft}} para expirar</span>
                </div>

                <div *ngIf="item.daysLeft < 1 && item.activateDate != null" class="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 text-right">
                    <span style="color:orangered ">Senha Expirada</span>
                </div>

                <div *ngIf="item.daysLeft <= -1 && item.activateDate == null" class="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 text-right">
                    <span style="color:orangered ">Senha ainda por Ativar</span>
                </div>
            </div>
        </div>

        <div style="width: 100%; clear: both; padding-top: 2%;">
            <nz-table #rowSelectionTableS [nzData]="products" [nzShowPagination]="false" style="overflow: auto;">
                <thead>
                    <tr>
                        <th>Produto</th>
                        <th>Categoria</th>
                        <th>Duração</th>
                        <th>Restantes</th>
                        <th>Atribuir Senha</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of products; let i=index;">
                        <td>{{data.name}}</td>
                        <td>{{data.categoryName}}</td>
                        <td>{{data.duration}}</td>
                        <td>{{data.ticketsLeft}}/<strong>{{data.totalTickets}}</strong></td>
                        <td>
                            <button tc-button [view]="'success'" (click)="submitTickets(i)" [beforeIcon]="'icofont-diskette'"></button>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </mat-tab>

    <mat-tab label="Material" *ngIf="this.localData.isReportPage != true || isAdmin()">
        <div style="width: 100%; padding: 2%;">
            <div class="row" style="padding: 5px; background-color: cadetblue; color: white;">
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6 text-left">
                    Descrição
                </div>
        
                <div class="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-center">
                    Duração
                </div>
                
                <div class="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-center">
                    Data
                </div>
        
                <div *ngIf="currentUser.user.profileId != 6" class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                    Estado
                </div>
        
                <div *ngIf="currentUser.user.profileId == 6" class="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 text-right">
                    Expiração
                </div>       
            </div>

            <div class="row striped" *ngFor="let item of didacticTickets; let i=index;" style="padding: 5px;">
                <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6 text-left">
                    {{item.materialDescription}}
                </div>
        
                <div class="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-center">
                    <span class="badge badge-info" style="margin-left: 5px; background-color: lightskyblue;" rel="tooltip" matTooltip="Duração de Senhas atribuídas">{{item.durationName}}</span>
                </div>
                
                <div class="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-center">
                    {{item.ticketDate | date: 'dd/MM/yyyy'}}
                </div>
        
                <div *ngIf="currentUser.user.profileId != 6" class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center">
                    <tc-switcher style="margin-left: 5px; float: right;" [value]="item.active" (click)="changeDidaticStatus(i)"></tc-switcher>
                </div>
        
                <div *ngIf="item.daysLeft > 0" class="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 text-right">
                    <span style="float:right;color:orangered ">{{item.daysLeft}} para expirar</span>
                </div>
        
                <div *ngIf="item.daysLeft < 1 && item.activateDate != null" class="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 text-right">
                    <span style="float:right;color:orangered ">Senha Expirada</span>
                </div>
        
                <div *ngIf="item.daysLeft <= -1 && item.activateDate == null" class="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 text-right">
                    <span style="float:right;color:orangered ">Senha ainda por Ativar</span>
                </div>
            </div>
        </div>

        <nz-table #rowSelectionTableS [nzData]="products" [nzShowPagination]="false" style="width: 100%;" style="overflow: auto;">
            <thead>
                <tr>
                    <th>Material</th>
                    <th>Descrição</th>
                    <th>Duração</th>
                    <th>Restantes</th>
                    <th>Atribuir Senha</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of didacticProducts; let i=index;">
                    <td>{{data.name}}</td>
                    <td>{{data.categoryName}}</td>
                    <td>{{data.duration}}</td>
                    <td>{{data.ticketsLeft}}/<strong>{{data.totalTickets}}</strong></td>
                    <td><button tc-button [view]="'success'" (click)="submitTickets(i, 'didacticTicket')" [beforeIcon]="'icofont-diskette'"></button>
                    </td>
                </tr>

            </tbody>
        </nz-table>
    </mat-tab>

    <mat-tab label="Dashboard" *ngIf="localData.profileName == 'Aluno' || currentUser.user.profileId == 8">
        <page-dashboard data-custom="show" data-user="localData.userId"></page-dashboard>
    </mat-tab>
</mat-tab-group>


<mat-dialog-actions layout="row" style="height: 60px;">
    <button *ngIf="localData.action != 'Adicionar' && currentUser.user.profileId != 8" tc-button [view]="'default'" (click)="sendEmail()"
        style="margin: 2px;" [beforeIcon]="'icofont-ui-message'"><span class="hide-xs">Dados de acesso</span></button>
    <button tc-button [view]="'success'" *ngIf="currentUser.user.profileId != 8" (click)="onSubmitGeral()" style="margin: 2px;"
        [beforeIcon]="'icofont-diskette'"><span class="hidden-sm hidden-xs">Gravar</span></button>
    <button tc-button [view]="'warning'" (click)="closeDialog()" style="margin: 2px;"
        [beforeIcon]="'icofont-close-circled'"></button>
</mat-dialog-actions>