var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AuthService } from '../../../services/auth.service';
import { TestService } from '../../../services/test.service';
import { Subject } from 'rxjs/internal/Subject';
import { DomSanitizer } from '@angular/platform-browser';
import { Utils } from 'src/utils/utils';
import { interval } from 'rxjs';
import { ELearningService } from 'src/app/services/elearning.service';
var Lesson = /** @class */ (function () {
    function Lesson(dialog, testService, sanitizer, dialogRef, authenticationService, elearningService, data, utils, cd, elRef) {
        var _this = this;
        this.dialog = dialog;
        this.testService = testService;
        this.sanitizer = sanitizer;
        this.dialogRef = dialogRef;
        this.authenticationService = authenticationService;
        this.elearningService = elearningService;
        this.data = data;
        this.utils = utils;
        this.cd = cd;
        this.elRef = elRef;
        this.UserELearningSessionIdBefore = -1;
        this.colorProgress = '#ffffff';
        this.dummy = false;
        this.userInactive = new Subject();
        this.isActivity = true;
        this.stopTime = 0;
        this.spendSave = 0;
        this.spendSaveBefore = -1;
        this.aux = 0;
        this.resume = false;
        this.pause = false;
        this.firstComplete = false;
        this.executing = false;
        this.destroyed = new Subject();
        this.localData = __assign({}, data);
        console.log(this.localData);
        this.checkActivity();
        this.url = this.localData.lessonMaterial[0].urlMat;
        //console.log(this.localData);
        //console.log(this.url);
        this.UserELearningSessionId = 0;
        this.spendSeconds = utils.time2Seconds(this.localData.timeSpend);
        this.totalSeconds = utils.time2Seconds(this.localData.time);
        this.firstComplete = this.spendSeconds >= this.totalSeconds ? false : true;
        this.authenticationService.currentUser.subscribe(function (x) {
            _this.currentUser = x;
        });
        this.startSeconds = new Date().getTime() / 1000;
        this.endSec = new Date().getTime() / 1000;
        this.realTime = this.startSeconds;
        this.actualizeInfo();
        setTimeout(function () {
            _this.actualizeThread = interval(100).subscribe(function (x) {
                _this.actualize();
            });
            _this.actualizeInfoThread = interval(5000).subscribe(function (x) {
                _this.actualizeInfo();
            });
        }, 3000);
    }
    Lesson.prototype.close = function () {
        this.actualize();
        this.actualizeInfo();
        this.executing = true;
        if (this.actualizeInfoThread)
            this.actualizeInfoThread.unsubscribe();
        if (this.actualizeThread)
            this.actualizeThread.unsubscribe();
        if (this.userInactive)
            this.userInactive.unsubscribe();
        clearTimeout(this.userActivity);
        this.dialogRef.close();
    };
    Lesson.prototype.actualize = function () {
        if (!this.localData.dayClosed) {
            this.endSec = new Date().getTime() / 1000;
            this.realTime = this.isActivity ? this.realTime : this.endSec;
            var spend = this.endSec - this.realTime;
            //var spend = this.endSec - this.startSeconds;
            this.spendSave = spend != 0 ? this.spendSave + spend - this.aux : this.spendSave;
            //Ver se é um resume
            if (this.pause && spend != 0) {
                this.resume = true;
                this.pause = false;
            }
            //Testa se está pausado
            this.pause = this.spendSave == this.spendSaveBefore ? true : false;
            //Se for resume temos que adicionar o tempo passado anteriormente
            if (this.resume) {
                this.spendSave = this.spendSaveBefore + spend;
                this.resume = false;
            }
            this.aux = spend == 0 ? this.aux : spend;
            this.spendSaveBefore = this.spendSave;
            var runningSeconds = this.spendSeconds + this.spendSave;
            if (runningSeconds > this.totalSeconds) {
                if (this.firstComplete) {
                }
                if (!this.localData.isClosed)
                    this.localData.isClosed = true;
                this.percentage = 100;
            }
            else
                this.percentage = Math.floor(runningSeconds / this.totalSeconds * 100.0);
            if (this.percentage == 100) {
                if (!this.dummy) {
                    this.dummy = true;
                    this.actualizeInfo();
                }
                this.colorProgress = '#A6CE3A';
            }
        }
    };
    Lesson.prototype.actualizeInfo = function () {
        var _this = this;
        console.log("Fucntion In");
        console.log(this.executing);
        if (!this.executing) {
            this.executing = this.percentage == undefined ? false : this.isActivity == false ? false : true;
            if (!this.localData.dayClosed) {
                if (this.percentage < 100 || (this.percentage == 100 && this.firstComplete)) {
                    this.firstComplete = this.percentage == 100 ? false : this.firstComplete;
                    if (this.isActivity) {
                        var secondsStart = this.stopTime == 0 ? this.startSeconds : this.stopTime;
                        this.endSec = new Date().getTime() / 1000;
                        console.log("UserELearningSessionIdBefore -> ", this.UserELearningSessionIdBefore);
                        console.log("UserELearningSessionId -> ", this.UserELearningSessionId);
                        if (this.UserELearningSessionId == 0) {
                            console.log("### Create ###");
                            console.log("SecondsStart -> ", new Date(secondsStart * 1000).toISOString().slice(11, 19));
                            console.log("EndSec -> ", new Date(this.endSec * 1000).toISOString().slice(11, 19));
                            this.elearningService.createSession(this.localData.userElearningLessonId, secondsStart, this.endSec).subscribe(function (x) {
                                //console.log(x);
                                _this.UserELearningSessionId = x.userElearningLessonSessionId;
                                _this.localData.dayClosed = x.dayClosed;
                                console.log(_this.UserELearningSessionId);
                                _this.executing = false;
                            });
                        }
                        else if (this.UserELearningSessionIdBefore <= this.UserELearningSessionId) {
                            this.UserELearningSessionIdBefore = this.UserELearningSessionId;
                            console.log("### Atualize ###");
                            console.log("EndSec -> ", new Date(this.endSec * 1000).toISOString().slice(11, 19));
                            this.elearningService.updateSession(this.UserELearningSessionId, this.endSec).subscribe(function (x) {
                                _this.localData.dayClosed = x.dayClosed;
                                _this.executing = false;
                                //console.log(x);
                            });
                        }
                    }
                }
            }
        }
    };
    Lesson.prototype.checkActivity = function () {
        var _this = this;
        console.log('checkActivity');
        this.setTimeout();
        this.userInactive.subscribe(function () {
            _this.actualizeInfo();
            _this.isActivity = false;
            _this.UserELearningSessionId = 0;
            console.log("Reset UserELearningSessionId");
        });
    };
    Lesson.prototype.setTimeout = function () {
        var _this = this;
        try {
            this.userActivity = setTimeout(function () { return _this.userInactive.next(undefined); }, 60000);
        }
        catch (_a) {
        }
    };
    Lesson.prototype.refreshUserState = function (e) {
        this.stopTime = this.isActivity ? this.stopTime : new Date().getTime() / 1000;
        this.isActivity = true;
        clearTimeout(this.userActivity);
        this.setTimeout();
    };
    Lesson.prototype.ngOnInit = function () {
        var _this = this;
        window.addEventListener('message', function (evt) { return _this.refreshUserState(evt); });
    };
    return Lesson;
}());
export { Lesson };
