<h1 mat-dialog-title><strong>Resposta à dúvida do User : {{name}}</strong>
</h1>
<div mat-dialog-content style="overflow: hidden;">
    <form [formGroup]="emailForm">
        <!--mat-form-field>
            <input formControlName="subject" placeholder="Assunto" matInput>
        </mat-form-field-->
        <mat-form-field [formGroup]="form">
            <h6>Questão: </h6>
            <hr>
            <textarea formControlName="myQuestion" rows="5"  ngModel="{{localData.question}}" matInput style="overflow-wrap: break-word;"></textarea>
        </mat-form-field>
        <mat-form-field [formGroup]="form" *ngIf="isResponded()">
            <h6>Resposta: </h6>
            <hr>
            <textarea formControlName="myForm" rows="10" ngModel="{{localData.response}}" matInput style="background-color: rgb(182, 182, 182); overflow-wrap: break-word; width: 100%;"></textarea>
        </mat-form-field>
        <mat-form-field *ngIf="!isResponded()">
            <textarea  formControlName="body" rows="10" placeholder="Insira a sua resposta ..." matInput style="background-color: rgb(182, 182, 182);"></textarea>
        </mat-form-field>
        <div>
            <button tc-button *ngIf="!isResponded()" [beforeIcon]="'icofont-paper-plane'" [view]="'success'" (click)="onSubmit()">Enviar</button>
            <button tc-button *ngIf="!isResponded()" [beforeIcon]="'icofont-close-circled'" (click)="closeDialog()" [view]="'warning'">Cancelar</button>
            <button tc-button *ngIf="isResponded()" [beforeIcon]="'icofont-eye-blocked'" (click)="closeDialog()" [view]="'success'">Voltar</button>
        </div>
    </form>
</div>