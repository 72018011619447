var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { first } from 'rxjs/internal/operators/first';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../../../services/product.service';
import { BasePageComponent } from '../../../base-page';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../../services/http/http.service';
import { environment } from '../../../../../environments/environment';
import { DialogBoxProductsComponent } from '../../DialogBoxes/dialog-box-products/dialog-box-products.component';
import { ConfirmComponent } from '../../DialogBoxes/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
var ProductsComponent = /** @class */ (function (_super) {
    __extends(ProductsComponent, _super);
    function ProductsComponent(productService, dialog, store, httpSv, toastr) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.productService = productService;
        _this.dialog = dialog;
        _this.toastr = toastr;
        _this.bordered = true;
        _this.position = 'bottom';
        _this.searchValue = '';
        _this.listOfDisplayData = [];
        _this.listOfAllData = [];
        _this.list = [];
        _this.sortName = null;
        _this.sortValue = null;
        _this.getData(environment.apiUrl + "/Product/", 'tableData', 'setLoaded');
        _this.productService.getAll().pipe(first()).subscribe(function (product) {
            _this.listOfAllData = product;
            _this.list = product;
        });
        return _this;
        /*
            this.pageData = {
              title: 'Produtos',
              loaded: true
            };*/
    }
    ProductsComponent.prototype.currentPageDataChange = function ($event) {
        this.listOfDisplayData = $event;
    };
    ProductsComponent.prototype.sort = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.listOfAllData = this.list;
        this.listOfDisplayData = this.list;
        this.search();
    };
    ProductsComponent.prototype.reset = function () {
        this.searchValue = '';
        this.listOfAllData = this.list;
        this.search();
    };
    ProductsComponent.prototype.select = function (event) {
        var path = event.path || (event.composedPath && event.composedPath());
        if (path[1].className.match('active')) {
            path[1].classList.remove('active');
        }
        else {
            path[1].classList.add('active');
        }
    };
    ProductsComponent.prototype.search = function () {
        var _this = this;
        var filterFunc = function (item) {
            return (item['name'].toLowerCase().indexOf(_this.searchValue.toLowerCase()) !== -1);
        };
        var data = this.list.filter(function (item) { return filterFunc(item); });
        this.listOfAllData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    ProductsComponent.prototype.removeSelectedRows = function () {
        var _this = this;
        var row = {};
        row.text = 'Confirma a remoção do(s) produto(s) selecionado(s)?';
        var dialogRef = this.dialog.open(ConfirmComponent, {
            id: 'confirm',
            // width: '30vw',
            // height: '23vh',
            data: row
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === undefined) { }
            else if (result.event == 'sim') {
                var e = document.querySelectorAll('tr');
                e.forEach(function (el) {
                    if (el.className.match('active')) {
                        var index = _this.listOfAllData.findIndex(function (d) { return d['name'] === el.children[0].lastChild.nodeValue.trim(); });
                        _this.productService.deleteProduct(_this.listOfAllData[index]['productId']).subscribe(function (data) {
                            _this.productService.getAll().pipe(first()).subscribe(function (product) {
                                _this.listOfAllData = product;
                            });
                            _this.toastr.success('Produto(s) removido(s) com sucesso!');
                        }, function (err) {
                            _this.toastr.error('Algo ocorreu mal!');
                        });
                    }
                });
            }
        });
    };
    ProductsComponent.prototype.openDialog = function (action, e) {
        var _this = this;
        if (e == null || !e.target.className.match('ant-table-column-sorters') && !e.target.className.match('ant-spin-container')) {
            var path = e == null ? '' : e.path || (e.composedPath && e.composedPath());
            var row = {};
            row.action = action;
            row.tag = e == null ? '' : this.listOfAllData[this.listOfAllData.findIndex(function (d) { return d['name'] === path[1].children[0].innerText; })].tag;
            row.productId = e == null ? -1 : this.listOfAllData[this.listOfAllData.findIndex(function (d) { return d['name'] === path[1].children[0].innerText; })]['productId'];
            row.name = e == null ? '' : path[1].children[0].innerText;
            var dialogRef = this.dialog.open(DialogBoxProductsComponent, {
                width: '99%',
                maxWidth: '850px',
                height: 'auto',
                data: row,
                disableClose: true,
                closeOnNavigation: true
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result === undefined) { }
                else if (result.event == 'Adicionar') {
                    _this.addRowData(result.data);
                }
                else if (result.event == 'Modificar') {
                    _this.updateRowData(result.data);
                }
            });
        }
    };
    ProductsComponent.prototype.addRowData = function (rowObj) {
        var _this = this;
        this.productService.createProduct(rowObj.name, rowObj.tag).subscribe(function (data) {
            _this.productService.getAll().pipe(first()).subscribe(function (product) {
                _this.listOfAllData = product;
            });
            _this.toastr.success('Produto Adicionado com sucesso!');
        }, function (err) {
            _this.toastr.error('Algo ocorreu mal!');
        });
    };
    ProductsComponent.prototype.updateRowData = function (rowObj) {
        var _this = this;
        this.productService.updateProduct(rowObj.productId, rowObj.name, rowObj.tag).subscribe(function (data) {
            _this.productService.getAll().pipe(first()).subscribe(function (product) {
                _this.listOfAllData = product;
            });
            _this.toastr.success('Produto Modificado com sucesso!');
        }, function (err) {
            _this.toastr.error('Algo ocorreu mal!');
        });
    };
    ProductsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    ProductsComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return ProductsComponent;
}(BasePageComponent));
export { ProductsComponent };
