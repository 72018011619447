<div class="col col-12 col-md-12 mb-12 mb-md-12">
        <tc-card class="mb-0 custom">
                <div class="row">
                        <div class="container">
                                <p>Por favor escolha a origem das questões</p>
                                <ul class="list-group" style="padding: 0%;">
                                        <li class="form-control" [ngClass]="{'active': activeElement == 1}" (click)="select($event,1)">Alves Costa</li>
                                        <li class="form-control" [ngClass]="{'active': activeElement == 2}" (click)="select($event,2)">IMT</li>
                                </ul>
                                <button tc-button [view]="'warning'" [beforeIcon]="'icofont-reply-all'" (click)="back()">Voltar</button>
                        </div>
                </div>
        </tc-card>

</div>