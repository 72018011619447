/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/menu";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/bidi";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../menu-item/menu-item.component.ngfactory";
import * as i10 from "../menu-item/menu-item.component";
import * as i11 from "@angular/router";
import * as i12 from "./menu.component";
import * as i13 from "@angular/common";
import * as i14 from "../../../services/http/http.service";
import * as i15 from "@ngrx/store";
import * as i16 from "../../../services/auth.service";
import * as i17 from "../../../services/menu.service";
var styles_MenuComponent = [i0.styles];
var RenderType_MenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuComponent, data: { "animation": [{ type: 7, name: "subMenu", definitions: [{ type: 0, name: "active", styles: { type: 6, styles: { height: "*", visibility: "visible" }, offset: null }, options: undefined }, { type: 0, name: "inactive", styles: { type: 6, styles: { height: 0, visibility: "hidden" }, offset: null }, options: undefined }, { type: 1, expr: "inactive => active", animation: { type: 4, styles: null, timings: "300ms ease-in-out" }, options: null }, { type: 1, expr: "active => inactive", animation: { type: 4, styles: null, timings: "200ms ease-in-out" }, options: null }], options: {} }] } });
export { RenderType_MenuComponent as RenderType_MenuComponent };
function View_MenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [["style", "padding: 21px; width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 3, "button", [["aria-haspopup", "true"], ["class", "dropdown-toggle menuBtn text-left mat-menu-trigger"], ["mat-button", ""]], [[1, "aria-expanded", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 1196032, null, 0, i3.MatMenuTrigger, [i4.Overlay, i1.ElementRef, i1.ViewContainerRef, i3.MAT_MENU_SCROLL_STRATEGY, [2, i3.MatMenu], [8, null], [2, i5.Directionality], i6.FocusMonitor], { menu: [0, "menu"] }, null), i1.ɵdid(3, 180224, null, 0, i7.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(4, 0, [" ", "\u00A0\u00A0 "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-menu-item", [], null, null, null, i9.View_MenuItemComponent_0, i9.RenderType_MenuItemComponent)), i1.ɵdid(6, 114688, [["menu", 4]], 0, i10.MenuItemComponent, [i11.Router, i12.MenuComponent], { items: [0, "items"] }, null)], function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 6).childMenu; _ck(_v, 2, 0, currVal_3); var currVal_5 = _v.parent.context.$implicit.children; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).menuOpen || null); var currVal_1 = (i1.ɵnov(_v, 3).disabled || null); var currVal_2 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _v.parent.context.$implicit.title; _ck(_v, 4, 0, currVal_4); }); }
function View_MenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["style", "padding: 21px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "dropdown-toggle menuBtn text-left"], ["color", "primary"], ["mat-button", ""], ["style", "color: white"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goRoute(_v.parent.context.$implicit.url) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i7.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(3, 0, [" ", "\u00A0\u00A0 "]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.title; _ck(_v, 3, 0, currVal_3); }); }
function View_MenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_2)), i1.ɵdid(2, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_3)), i1.ɵdid(4, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.children && (_v.context.$implicit.children.length > 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_v.context.$implicit.children || (_v.context.$implicit.children.length === 0)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_MenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "nav", [["class", "main-menu-wrap"], ["style", "overflow: hidden;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "ul", [["class", "menu-ul"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "collapse navbar-collapse"], ["id", "navbarsDefault"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [["class", "navbar-nav mr-auto container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_1)), i1.ɵdid(5, 278528, null, 0, i13.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuItems; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_MenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu", [], [[2, "main-menu", null], [2, "app", null], [2, "vertical", null]], null, null, View_MenuComponent_0, RenderType_MenuComponent)), i1.ɵdid(1, 114688, null, 0, i12.MenuComponent, [i14.HttpService, i15.Store, i11.Router, i1.ElementRef, i16.AuthService, i17.MenuService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).app; var currVal_2 = i1.ɵnov(_v, 1).vertical; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var MenuComponentNgFactory = i1.ɵccf("app-menu", i12.MenuComponent, View_MenuComponent_Host_0, { orientation: "orientation" }, {}, []);
export { MenuComponentNgFactory as MenuComponentNgFactory };
