import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../../../services/auth.service';
import { CompanyService } from '../../../../services/company.service';
import { DialogBoxActivateTicketComponent } from '../../../custom/DialogBoxes/dialog-box-activateTicket/dialog-box-activateTicket.component';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { PublicLayoutComponent } from '../../../../layout/public';
import { TicketService } from '../../../../services/ticket.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../../services/user.service';
import { Utils } from 'src/utils/utils';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';

@Component({
  selector: 'page-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})

export class PageSignInComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: string;
  companyName: string;
  version: string;
  UserTickets: any[];
  HasTickets: any[];
  url: string = '';
  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private userTicketService: TicketService,
    private _formBuilder: FormBuilder, private authenticationService: AuthService, private companyService: CompanyService, public dialog: MatDialog,
    private _location: Location, private userService: UserService, _public: PublicLayoutComponent,
    public utils: Utils) {
    localStorage.removeItem('currentUser');
    this.version = environment.version;
    this.companyService.getByName('AlvesCosta').subscribe(c => {
      this.url = c.url;
    });
    if (this.authenticationService.currentUserValue) {
      if (this.authenticationService.currentUserValue.user.profileId == 3) {
        this.router.navigate(['/app/dashboard']);
      }
      else {
        this.router.navigate(['/app/homePage']);
      }
    }
    _public.initialiseCompany(this.route.snapshot.params['name']);

    this.companyService.getByName(this.route.snapshot.params['name']).pipe(first()).subscribe(company => {
      this.companyName = company.PlataformName;
    });
  }

  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      Utilizador: new FormControl('Utilizador', [Validators.required, Validators.minLength(1)]),
      password: new FormControl('password', [Validators.required, Validators.minLength(5)])
    });

  }

  Login() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.toastr.error('Utilizador ou Password Incorrecta!');
      return;
    }
    this.loading = true;

    this.authenticationService.isLogged(String(this.login().replace('/', '%2F'))).subscribe(c => {
      if (c == false) {
        this.authenticationService.login(String(this.login().replace('/', '%2F')), String(this.password()))
          .pipe(first())
          .subscribe(
            data => {
              const deviceWidth = this.utils.isMobileDevice() == true ? '95vw' : '40vw';
              const devideMaxWidth = this.utils.isMobileDevice() == true ? '98vw' : '90vw';

              if(data.user.active == false) {
                this.toastr.warning("A sua conta encontra-se inactiva, contacte a sua escola.");
                this.authenticationService.logout().subscribe();
              }
              else {
                if (data.user.profileId == 3 || data.user.profileId == 7  ) {
                  this.router.navigate(['/app/dashboard']);
                  
                  this.userTicketService.didacticTicketsByUserId(data.user.id).subscribe(dt => 
                    {
                      this.UserTickets = dt['userTicket']; 
                      this.HasTickets = dt['hasTickets']; 

                      this.userTicketService.ticketsByUserId(data.user.id).subscribe( a => {
                        if (a['userTicket'].length === 0 && a['hasTickets'].length === 0 && this.UserTickets.length === 0 && this.HasTickets.length === 0) {
                          const row: any = {};
                          row.hasTicket = false;

                          const dialogRef = this.dialog.open(
                            DialogBoxActivateTicketComponent,
                            {
                              width: 'auto',
                              minWidth: '40vw',
                              height: 'auto',
                              maxHeight: '50vh',
                              disableClose: true,
                              data: row
                            }
                          );

                        dialogRef.afterClosed().subscribe(result => {
                          if (result === undefined) {
                          } else if (result.event === 'activate') {
                            this.userService.updateTerms(data.user.id).subscribe();
                          }
                        });
                        }
                        else if (a['userTicket'] != 0 || this.UserTickets.length != 0) {
                          const row: any = {};
                          row.hasTicket = true;
                          a['userTicket'].forEach( item => {
                            this.UserTickets.push(item);
                          });
                          row.tickets = this.UserTickets;
                          const dialogRef = this.dialog.open(
                            DialogBoxActivateTicketComponent,
                            {
                              width: 'auto',
                              minWidth: '40vw',
                              height: 'auto',
                              maxHeight: '50vh',
                              disableClose: true,
                              data: row
                            }
                          );
                          dialogRef.afterClosed().subscribe(result => {
                            if (result === undefined) {
                            } else if (result.event === 'activate') {
                              this.userTicketService
                                .activateTicket(result.data, data.user.id)
                                .subscribe(res => {
                                  this.toastr.success('Senhas Ativas !!!');
                                  this.userService.updateTerms(data.user.id).subscribe();
    
                                });
                            } else {
                              if (a['hasTickets'].length != 0) {
                                a['hasTickets'].forEach(element => {
                                  if (element.daysLeft < 1) {
                                    // this.toastr.error('A senha ' + element.categoryName + ' expirou');
                                  } else {
                                    this.toastr.warning('A senha ' + element.fullName + ' expira em ' + (element.daysLeft) + ' dias');
                                  }
                                });
                              }
                              if(this.HasTickets.length != 0){
                                this.HasTickets.forEach(element => {
                                  if(element.daysLeft < 1){
                                     // this.toastr.error('A senha ' + element.categoryName + ' expirou');
                                  }
                                  else{
                                    this.toastr.warning('A senha ' + element.materialDescription + ' expira em ' + (element.daysLeft) + ' dias');
                                  }
                                })
                              }
                            }
                          });
                        }
                        else {
                          if (a['hasTickets'].length != 0) {
                            a['hasTickets'].forEach(element => {
                              if (element.daysLeft < 1) {
                              } else {
                                this.toastr.warning('A senha ' + element.fullName + ' expira em ' + (element.daysLeft) + ' dias');
                              }
                            });
                            if(this.HasTickets.length != 0){
                              this.HasTickets.forEach(element => {
                                if(element.daysLeft < 1){
                                }
                                else{
                                  this.toastr.warning('A senha ' + element.materialDescription + ' expira em ' + (element.daysLeft) + ' dias');
                                }
                              })
                            }
                          }
                        }
                      });
                    }
                  )
                }
                else {
                  if (data.user.profileId == 8) 
                    this.router.navigate(['/app/Users']);
                  else
                    this.router.navigate(['/app/homePage']);
                }
              }
            },
            error => {
              this.error = error;
              this.loading = false;
              this.toastr.error('Utilizador ou Password Incorrecta!');
            });
      } 
      else {
        this.toastr.error('Já existe um login ativo para esta conta!!!');
        this.router.navigate(['public/sign-in/' + this.companyName.replace(' ', '')]);
      }
      error => {
        this.loading = false;
        this.toastr.error('Utilizador ou Password Incorrecta!');
      }
    });
  }
  
  togglePassword() {
    const pass = document.getElementsByClassName('password');
    pass[0].attributes.item(2).value = pass[0].attributes.item(2).value == 'password' ? 'text' : 'password';
    const innerPass = document.getElementsByClassName('input-infix');
    innerPass[1].children[0].attributes.item(2).value = innerPass[1].children[0].attributes.item(2).value == 'password' ? 'text' : 'password';


    /*
    const pass = document.getElementsByClassName('password');
   
    if(pass[0].attributes.getNamedItem('ng-reflect-type').value == 'password')
      pass[0].attributes.getNamedItem('ng-reflect-type').value =  'text';
    else
      pass[0].attributes.getNamedItem('ng-reflect-type').value =  'password';

    const innerPass = document.getElementsByClassName('input-infix');

    if(innerPass[1].children[0].attributes.getNamedItem('type').value == 'password')
      innerPass[1].children[0].attributes.getNamedItem('type').value =  'text';
    else
      innerPass[1].children[0].attributes.getNamedItem('type').value =  'password';
    */
  }

  login() {
    return this.loginForm.get('Utilizador').value;
  }

  password() {
    return this.loginForm.get('password').value;
  }
}
