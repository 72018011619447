<nav class="main-menu-wrap" style="overflow: hidden;">
  <ul class="menu-ul">
    <div  class="collapse navbar-collapse" id="navbarsDefault">
      <ul class="navbar-nav mr-auto container">
        <span *ngFor="let item of menuItems">
          <!-- Handle branch node buttons here -->
          <span *ngIf="item.children && item.children.length > 0" style="padding: 21px; width: 100%;">
            <button mat-button [matMenuTriggerFor]="menu.childMenu" class="dropdown-toggle menuBtn text-left">
              {{item.title}}&nbsp;&nbsp;
            </button>
            <app-menu-item #menu [items]="item.children"></app-menu-item>
          </span>
          <!-- Leaf node buttons here -->
          <span *ngIf="!item.children || item.children.length === 0" style="padding: 21px;">
            <button mat-button color="primary" style="color: white" class="dropdown-toggle menuBtn text-left" (click)="goRoute(item.url)">
              {{item.title}}&nbsp;&nbsp;
            </button>
          </span>
        </span>        
      </ul>
    </div>
  </ul>
</nav>