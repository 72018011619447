<link href="https://fonts.googleapis.com/css?family=Roboto+Condensed:700" rel="stylesheet" type="text/css">
<link href="https://fonts.googleapis.com/css?family=Roboto:100" rel="stylesheet" type="text/css">
<link href="https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap" rel="stylesheet">

<div class="container d-block d-xl-none" style="width: 100%; background-color: #134472;color:#80BBD7; display: flex; justify-content: space-evenly; align-items: center;">
    <div class="row no-gutters align-items-center" style="height: 48px;" >
        <div class="col-8 text-left">
            <span class="terminate roboto400" style="color:red" id="termin" (click)="terminate()" *ngIf="!isFinished">
                <strong id="ter">&nbsp;Terminar&nbsp;teste</strong>
            </span>
        </div>
        <div class="buttonsContainer col-4 text-right">
            <button class="closeButton" (click)="close()" rel="tooltip" *ngIf="false"
                matTooltip="Terminará o teste sem guardar os resultados">
                <i class="icofont-close-line"></i>
            </button>
        </div>
    </div>
</div>


<div class="container d-none d-xl-block" style="width: 100%; height: 48px; background-color: #134472;color:#80BBD7; display: flex; justify-content: space-evenly; align-items: center;">
    <div class="row no-gutters align-items-center" style="height: 48px;" >
        <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <span class="terminate roboto500" id="type">Categoria B/B1</span>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 text-truncate">
            <span class="terminate roboto400" id="name">&nbsp;</span>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
            <span class="terminate roboto400" style="color:red" id="termin" (click)="terminate()" *ngIf="!isFinished">
                <strong id="ter">&nbsp;Terminar&nbsp;teste</strong>
            </span>
        </div>
        <div class="buttonsContainer col-sm-2 col-md-2 col-lg-2 col-xl-2 text-right">   
            <button class="closeButton" (click)="close()" rel="tooltip" *ngIf="false"
                matTooltip="Terminará o teste sem guardar os resultados">
                <i class="icofont-close-line"></i>
            </button>
        </div>    
    </div>
</div>

<div id="wizardContainer" class="wizardContainer">
    <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="indexChange($event)"
        (selectedTabChange)="selectTab(this.isFinished, selected.value)" class="x mat-tab-group" headerPosition="below"
        (click)="selectTab(this.isFinished, selected.value)">
        <mat-tab *ngFor="let question of questions; let index = index" [label]="index + 1">
            <div class="container d-block d-lg-none d-xl-none" (swipeleft)="swipe(index, $event.type)"
                (swiperight)="swipe(index, $event.type)" style="width: 100%; height: 100%; display: inline-block;">
                <div class="row no-gutters" style="height: 30px;">
                    <div class="col roboto300">
                        <p><strong style="color: white;">{{index +1}}</strong><span
                                style="color:#80BBD7">/30</span></p>
                    </div>
                    <div class="col roboto300 text-right">
                        <div><span style="color:white;">{{minutesLeft}}&nbsp;Min</span></div>
                    </div>
                </div>
                <div class="row no-gutters"
                    style="margin: auto; position: relative; height: calc(100%-30px); overflow: auto;">
                    <div class="col-12 text-center">
                        <img id="imagem" class="imgQuestion" src="{{question.Image}}"
                            (click)="zoom()" alt="" />
                        <img src="../../../../../assets/lupa.png" class="lupa" />
                    </div>
                    <div class="col-12" style="padding-top: 2vw;">
                        <div style="list-style-type: none; width: 100%;">
                            <li class="question" style="background-color: #80BBD7;">{{question.Question}}</li>
                            <li *ngIf="question.QuestionOption1 != null" class="answer" id="0"
                                (click)="select($event,index, 0)">
                                {{question.QuestionOption1}}</li>
                            <li *ngIf="question.QuestionOption2 != null" class="answer" id="1"
                                (click)="select($event,index, 1)">
                                {{question.QuestionOption2}}</li>
                            <li *ngIf="question.QuestionOption3 != null" class="answer" id="2"
                                (click)="select($event,index, 2)">
                                {{question.QuestionOption3}}</li>
                            <li *ngIf="question.answer3 == null" class="optionNull"><label></label></li>
                            <li *ngIf="question.answer4 == null" class="optionNull"><label></label></li>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container d-none d-lg-block d-xl-block no-gutters" style="width: 100%; display: inline-block;">
                <div class="row no-gutters no-gutters">
                    <div class="col-sm-4 col-md-5 col-lg-5 col-xl-4">
                        <img id="imagem" class="imgQuestion" src="{{question.Image}}" (click)="zoom()" alt="" style="width: 100%; max-height: 450px;" />
                        <img src="../../../../../assets/lupa.png" class="lupa" />
                    </div>

                    <div class="col-sm-8 col-md-7 col-lg-7 col-xl-8 no-gutters">
                        <div class="container no-gutters" style="height: 100%;">
                            <div class="row no-gutters align-items-start" style="min-height: 140px; height: 140px;">
                                <div class="col roboto300">
                                    <p class="index customColor"><strong class="customNumber"
                                            style="font-size: 48px">{{index +1}}</strong><span class="roboto300"
                                            style="color:#80BBD7">/30</span></p>
                                </div>
                                <div class="col roboto300 text-right">
                                    <div id="customCorner"><span class="customSpan" style="font-size: 1.35vw; color:white;">{{minutesLeft}}&nbsp;Min</span></div>
                                </div>
                            </div>
                            <div class="row no-gutters align-items-end" style="height:calc(100% - 140px)">
                                <div class="col">
                                    <div class="question">{{question.Question}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="basic-grid" style="clear: both; padding-top: 1rem;" *ngIf="question.QuestionOption1 != null">
                    <div style="list-style-type: none;">
                        <li *ngIf="question.QuestionOption1 != null" style="text-align: center;" class="option" id="0"
                            (click)="select($event,index, 0)">A</li>
                    </div>

                    <div style="list-style-type: none;">
                        <li class="answer" id="0" (click)="select($event,index, 0)">{{question.QuestionOption1}}</li>
                    </div>

                    <div style="list-style-type: none;">
                        <li style="text-align: center;" class="withicon option" id="0"
                            (click)="select($event,index, 0)">
                            <label class="labelX">
                            </label>
                        </li>
                    </div>
                </div>

                <div class="basic-grid" style="clear: both; padding-top: 0rem;" *ngIf="question.QuestionOption2 != null">
                    <div style="list-style-type: none;">
                        <li style="text-align: center;" class="option" id="1" (click)="select($event,index, 1)">B</li>
                    </div>

                    <div style="list-style-type: none;">
                        <li class="answer" id="1" (click)="select($event,index, 1)">
                            {{question.QuestionOption2}}</li>
                    </div>

                    <div style="list-style-type: none;">
                        <li style="text-align: center;" class="withicon option" id="1"
                            (click)="select($event,index, 1)">
                            <label class="labelX">
                            </label>
                        </li>
                    </div>
                </div>


                <div class="basic-grid" style="clear: both; padding-top: 0rem;" *ngIf="question.QuestionOption3 != null">
                    <div style="list-style-type: none;">
                        <li style="text-align: center;" class="option" id="2" (click)="select($event,index, 2)">C</li>
                    </div>

                    <div style="list-style-type: none;">
                        <li class="answer" id="2" (click)="select($event,index, 2)">
                            {{question.QuestionOption3}}</li>
                    </div>

                    <div style="list-style-type: none;">
                        <li style="text-align: center;" class="withicon option" id="2"
                            (click)="select($event,index, 2)">
                            <label class="labelX">
                            </label>
                        </li>
                    </div>
                </div>                
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
