<svg viewBox="0, 0, 100, 100">
   <defs>
     <linearGradient id="gradient">
       <stop offset=".25" stop-color="#6ec8ff" />
       <stop offset=".65" stop-color="#b3589c" />
       <stop offset="1" stop-color="#9d5ce5" />
     </linearGradient>
   </defs>

  <circle
    class="progress-circle"
    [ngStyle]="getProgressCircleStyles()"
    [style.strokeDashoffset]="strokeDashoffset"
    stroke="url(#gradient)"
    r="47"
    cx="50"
    cy="50"
  />

  <circle
    class="secondary-circle"
    [ngStyle]="getSecondaryCircleStyles()"
    r="40"
    cx="50"
    cy="50"
  />
</svg>

<div class="text-box">
  <p class="text">{{(value) + '%'}}</p>
</div>
