var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { PipeTransform } from '@angular/core';
import { AlertMailService } from '../../../services/alert-mail.service';
import { AuthService } from '../../../services/auth.service';
import { BasePageComponent } from '../../base-page';
import { CompanyService } from '../../../services/company.service';
import { DialogBoxTeacherEmailComponent } from '../DialogBoxes/dialog-box-teacher-email/dialog-box-teacher-email.component';
import { HttpService } from '../../../services/http/http.service';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Utils } from 'src/utils/utils';
import { environment } from '../../../../environments/environment';
var StudentRequestsComponent = /** @class */ (function (_super) {
    __extends(StudentRequestsComponent, _super);
    function StudentRequestsComponent(store, dialog, authenticationService, router, httpSv, toastr, alertMailService, companyService, utils) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.dialog = dialog;
        _this.authenticationService = authenticationService;
        _this.router = router;
        _this.toastr = toastr;
        _this.alertMailService = alertMailService;
        _this.companyService = companyService;
        _this.utils = utils;
        _this.mails = [];
        _this.listmails = [];
        _this.inputValue = '';
        _this.inputFilter = '';
        _this.sortName = null;
        _this.sortValue = null;
        _this.opened = false;
        _this.getData(environment.apiUrl + "/AlertMail", 'tabledata', 'setLoaded');
        _this.closeDropdown = new EventEmitter();
        _this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        _this.alertMailService.getRequestsByProfile(_this.currentUser.user.companyId, _this.currentUser.user.profileId).subscribe(function (c) {
            _this.mails = c;
            _this.listmails = _this.mails;
        });
        _this.companyService.getAll().subscribe(function (company) {
            _this.schools = company;
        });
        _this.pageData = {
            title: 'Mensagens',
            loaded: true
        };
        return _this;
    }
    StudentRequestsComponent.prototype.openDialog = function (id, alertMailId, responded, response, question) {
        var _this = this;
        var row = {};
        row.userId = id;
        row.alertMailId = alertMailId;
        row.responded = responded;
        row.response = response;
        row.question = question;
        var width = this.utils.isMobileDevice() == true ? '95vw' : '50vw';
        var height = this.utils.isMobileDevice() == true ? '80vh' : 'auto';
        var dialogRef = this.dialog.open(DialogBoxTeacherEmailComponent, {
            disableClose: true,
            height: height,
            width: width,
            maxWidth: width,
            data: row
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log(result);
            if (result === undefined) { }
            else if (result.event == 'Send') {
                if (result.data.body)
                    _this.sendEmail(result.data);
            }
        });
    };
    StudentRequestsComponent.prototype.filterSuportTableByUser = function () {
        this.mails = this.listmails;
        // tslint:disable-next-line: triple-equals
        if (this.inputValue != '') {
            this.searchMails('userName');
        }
    };
    StudentRequestsComponent.prototype.filterSuportTable = function (a) {
        this.mails = this.listmails;
        // tslint:disable-next-line: triple-equals
        if (a != '') {
            this.inputFilter = a;
            this.searchMails('responded');
        }
    };
    StudentRequestsComponent.prototype.searchMails = function (type) {
        var _this = this;
        var filterFunc = function (item) {
            var x = '' + item['userName'].toLowerCase();
            var y = '' + item['responded'].toString();
            return type == 'userName' ? x.indexOf(_this.inputValue.toLowerCase()) !== -1 : y.indexOf(_this.inputFilter) !== -1;
        };
        var data = this.mails.filter(function (item) { return filterFunc(item); });
        this.mails = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    StudentRequestsComponent.prototype.reset = function () {
        this.inputValue = '';
        this.inputFilter = '';
        this.searchMails('');
        this.mails = this.listmails;
    };
    StudentRequestsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    StudentRequestsComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    StudentRequestsComponent.prototype.sendEmail = function (rowObj) {
        var _this = this;
        this.alertMailService.sendTeacherRequest(rowObj.userId, rowObj.alertMailId, rowObj.subject, rowObj.body).subscribe(function (c) {
            _this.alertMailService.getRequestsByProfile(_this.currentUser.user.companyId, _this.currentUser.user.profileId).subscribe(function (c) {
                _this.mails = c;
            });
            _super.prototype.sendMessage.call(_this);
            _this.toastr.success('Resposta enviado com sucesso!');
        });
    };
    StudentRequestsComponent.prototype.onCloseDropdown = function () {
        this.closeDropdown.emit(true);
    };
    StudentRequestsComponent.prototype.show = function (type, param) {
        // console.log(type);
        // console.log(param);
        var cards = document.querySelectorAll('tc-card');
        cards.forEach(function (elem) {
            elem.classList.remove('hide');
        });
        if (type == 'show') {
            if (param == 'all') {
                cards.forEach(function (elem) {
                    elem.classList.remove('hide');
                });
            }
            else {
                cards.forEach(function (elem) {
                    if (elem.className.match(param)) {
                        elem.classList.remove('hide');
                    }
                    else {
                        elem.classList.add('hide');
                    }
                });
            }
        }
        else if (type == 'bySchool') {
            cards.forEach(function (elem) {
                if (elem.className.match(param.replace(' ', ''))) {
                    elem.classList.remove('hide');
                }
                else {
                    elem.classList.add('hide');
                }
            });
        }
        this.onCloseDropdown();
    };
    StudentRequestsComponent.prototype.filter = function (type, param) {
        var cards = document.querySelectorAll('tc-card');
        cards.forEach(function (elem) {
            elem.classList.remove('hide');
        });
        cards.forEach(function (elem) {
            if (elem.className.match(param.replace(' ', '')) && elem.className.match(type)) {
                elem.classList.remove('hide');
            }
            else {
                elem.classList.add('hide');
            }
        });
        this.onCloseDropdown();
    };
    StudentRequestsComponent.prototype.isAdmin = function () {
        // console.log(this.currentUser.user.role);
        return (this.currentUser.user.role == 'Administrador' ? true : false);
    };
    StudentRequestsComponent.prototype.openFilter = function () {
        var el = document.getElementById('filtro');
        if (this.opened == false) {
            el.classList.add('show');
            el.classList.remove('hide');
            this.opened = true;
        }
        else {
            el.classList.add('hide');
            el.classList.remove('show');
            this.opened = false;
        }
    };
    return StudentRequestsComponent;
}(BasePageComponent));
export { StudentRequestsComponent };
var TrimPipe = /** @class */ (function () {
    function TrimPipe() {
    }
    TrimPipe.prototype.transform = function (value, args) {
        console.log(value + ':' + value.toString().trim());
        return value.toString().replace(' ', '');
    };
    return TrimPipe;
}());
export { TrimPipe };
