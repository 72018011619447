<link href="https://fonts.googleapis.com/css?family=Roboto+Condensed:700" rel="stylesheet" type="text/css">
<link href="https://fonts.googleapis.com/css?family=Roboto:100" rel="stylesheet" type="text/css">
<link href="https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap" rel="stylesheet">

<div class="Dummy">

    <div class="container d-block d-xl-none" style="width: 100%; background-color: #134472;color:#80BBD7; display: flex; justify-content: space-evenly; align-items: center;">
        <div class="row no-gutters align-items-center" style="height: 48px;" >
            <div class="col-8 text-left">
                <span class="terminate roboto400" style="color:red" id="termin" (click)="terminate()" *ngIf="false">
                    <strong id="ter">&nbsp;Terminar&nbsp;teste</strong>
                </span>
            </div>
            <div class="buttonsContainer col-4 text-right">
                <button class="closeButton" (click)="close()" rel="tooltip" matTooltip="Fechar">
                    <i class="icofont-close-line"></i>
                </button>
            </div>
        </div>
    </div>


    <div class="container d-none d-xl-block" style="width: 100%; height: 48px; background-color: #134472;color:#80BBD7; display: flex; justify-content: space-evenly; align-items: center;">
        <div class="row no-gutters align-items-center" style="height: 48px;" >
            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <span class="terminate roboto500" id="type">TESTES&nbsp;<span style="color:white">{{type}}&nbsp;</span></span>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 text-truncate">
                &nbsp;
            </div>
            <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <span class="terminate roboto400" style="color:red" id="termin" (click)="terminate()" *ngIf="false">
                    <strong id="ter">&nbsp;Terminar&nbsp;teste</strong>
                </span>
            </div>
            <div class="buttonsContainer col-sm-2 col-md-2 col-lg-2 col-xl-2 text-right">   
                <button class="closeButton" (click)="close()" rel="tooltip"
                    matTooltip="Fechar">
                    <i class="icofont-close-line"></i>
                </button>
            </div>    
        </div>
    </div>




    <div id="wizardContainer" class="wizardContainer">
        <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="indexChange($event)" (selectedTabChange)="selectTab(selected.value)" class="x mat-tab-group" headerPosition="below" (click)="selectTab(selected.value)">
            <mat-tab *ngFor="let question of questions; let index = index" [label]="index + 1">
                <div class="d-block d-lg-none d-xl-none" (swipeleft)="swipe(index, $event.type)"
                (swiperight)="swipe(index, $event.type)" style="width: 100%; height: 100%; display: inline-block;">
                    <div class="row no-gutters">
                        <div class="header basic-grid-header-small infoPanel">
                            <div style="border-top: 40px solid #134472; border-right: 40px solid transparent; width: 100%; display: flex; justify-content: center; align-items: center;">
                                <li *ngIf="selected.value + 1 > 0" class="indexSmall">
                                    <label><strong class="questionIndex">{{selected.value +1}}</strong><span class="roboto300" style="color:#80BBD7">/{{numberOfQuestions}}</span></label>
                                </li>
        
                                <li *ngIf="selected.value + 1 < 1" class="index">
                                    <label><strong class="questionIndex">1</strong><span class="roboto300" style="color:#80BBD7">/{{numberOfQuestions}}</span></label>
                                </li>
                            </div>
                            <div *ngIf="false" style="display: flex; justify-content: center; align-items: center;">
                                <li class="camTimer"><label>{{minutesLeft}}:{{secondsLeft}}</label></li>
                            </div>
                        </div>
                    </div>
                    <div class="row no-gutters" style="padding-top: 1%;">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 no-gutters">
                            <div class="no-gutters" style="height: 100%;">
                                <div class="row no-gutters align-items-start">
                                    <div class="col roboto400 camTheme">
                                        {{question.themeName}}
                                    </div>
                                    <div class="col roboto400 text-right id">
                                        ID.{{question.oldQuestionId | replace:'[a-zA-z]':''}} {{type}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-gutters container"
                        style="margin: auto; position: relative; height: calc(100%-30px); overflow: auto;">
                        <div class="col-12" style="padding-top: 2vw;">
                            <div style="list-style-type: none; width: 100%;">
                                <li class="question" style="background-color: #80BBD7;">{{question.question}}</li>

                                <li><div *ngIf="question.answerSelected == -1" style="color:red;">Questão não respondida</div></li>
                                
                                <li *ngIf="question.answer1 != null" class="answer" id="0" [ngClass]="{'correct' : question.answerCorrect == 0, 'wrong'  : question.answerSelected == 0 && question.answerCorrect != 0}">
                                    {{question.answer1}}
                                </li>
                                <li *ngIf="question.answer2 != null" class="answer" id="1" [ngClass]="{'correct' : question.answerCorrect == 1, 'wrong'  : question.answerSelected == 1 && question.answerCorrect != 1}">
                                    {{question.answer2}}
                                </li>
                                <li *ngIf="question.answer3 != null" class="answer" id="2" [ngClass]="{'correct' : question.answerCorrect == 2, 'wrong'  : question.answerSelected == 2 && question.answerCorrect != 2}">
                                    {{question.answer3}}
                                </li>
                                <li *ngIf="question.answer4 != null" class="answer" id="3" [ngClass]="{'correct' : question.answerCorrect == 3, 'wrong'  : question.answerSelected == 3 && question.answerCorrect != 3}">
                                    {{question.answer4}}
                                </li>
                                <li *ngIf="question.answer3 == null" class="optionNull"><label></label></li>
                                <li *ngIf="question.answer4 == null" class="optionNull"><label></label></li>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                <div class="d-none d-lg-block d-xl-block no-gutters">
                    <div class="header basic-grid-header infoPanel">
                        <div style="border-top: 100px solid #134472; border-right: 50px solid transparent; width: 100%; display: flex; justify-content: center; align-items: center;">
                            <li *ngIf="selected.value + 1 > 0" class="index">
                            <label>Questão <strong class="questionIndexBig">{{selected.value +1}}</strong><span class="roboto300" style="color:#80BBD7">/{{numberOfQuestions}}</span></label>
                            </li>

                            <li *ngIf="selected.value + 1 < 1" class="index">
                            <label>Questão <strong class="questionIndexBig">1</strong><span class="roboto300" style="color:#80BBD7">/{{numberOfQuestions}}</span></label>
                            </li>
                        </div>
                        <div style="border-top: 100px solid #367dbe; border-right: 50px solid #80BBD7; width: 100%; display: flex; justify-content: flex-end; align-items: center;">
                            <div class="row nameLabel">
                                <label>Nome: </label><br>
                                <label>CC: </label>
                            </div>
                        </div>
                        <div style="border-top: 100px solid #80BBD7; border-right: 50px solid transparent; width: 100%; display: flex; justify-content: left; align-items: center;">
                            <div class="row customName">
                                <label>{{currentName}}</label><br>
                                <label>{{cc}}</label>
                            </div>
                        </div>
                        <div *ngIf="false" style="display: flex; justify-content: center; align-items: center;">
                            <li class="camTimer"><label>{{minutesLeft}}:{{secondsLeft}}</label></li>
                        </div>
                    </div>

                </div>
                

                <div class="container">
                    <div class="d-none d-lg-block d-xl-block no-gutters" style="width: 100%; display: inline-block;">
                        <div class="row no-gutters" style="padding-top: 1%;">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 no-gutters">
                                <div class="no-gutters" style="height: 100%;">
                                    <div class="row no-gutters align-items-start">
                                        <div class="col roboto400 camTheme">
                                            {{question.themeName}}
                                        </div>
                                        <div class="col roboto400 text-right id">
                                            ID.{{question.oldQuestionId | replace:'[a-zA-z]':''}} {{type}}
                                        </div>
                                    </div>
                                    <div class="row no-gutters align-items-end">
                                        <div class="col">
                                            <div class="question">{{question.question}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="question.answerSelected == -1" style="color:red; float: right;">
                            <br/>Questão não respondida
                        </div>

                        <div class="basic-grid" style="clear: both; padding-top: 1rem;" *ngIf="question.answer1 != null">
                            <div style="list-style-type: none;">
                                <li *ngIf="question.answer1 != null" style="text-align: center;" class="option" id="0">A</li>
                            </div>
        
                            <div style="list-style-type: none;">
                                <li class="answer" id="0">{{question.answer1}}</li>
                            </div>
        
                            <div style="list-style-type: none;">
                                <li style="text-align: center;" class="withicon option" id="0">
                                    <label class="labelX" [ngClass]="{'icofont-tick-mark correctIcon' : question.answerCorrect == 0 , 'icofont-close wrongIcon'  : question.answerSelected == 0 && question.answerCorrect != 0}"></label>
                                </li>
                            </div>
                        </div>
        
                        <div class="basic-grid" style="clear: both; padding-top: 0rem;" *ngIf="question.answer2 != null">
                            <div style="list-style-type: none;">
                                <li style="text-align: center;" class="option" id="1">B</li>
                            </div>
        
                            <div style="list-style-type: none;">
                                <li class="answer" id="1">{{question.answer2}}</li>
                            </div>
        
                            <div style="list-style-type: none;">
                                <li style="text-align: center;" class="withicon option" id="1">
                                    <label class="labelX" [ngClass]="{'icofont-tick-mark correctIcon' : question.answerCorrect == 1 , 'icofont-close wrongIcon'  : question.answerSelected == 1 && question.answerCorrect != 1}"></label>
                                </li>
                            </div>
                        </div>
        
        
                        <div class="basic-grid" style="clear: both; padding-top: 0rem;" *ngIf="question.answer3 != null">
                            <div style="list-style-type: none;">
                                <li style="text-align: center;" class="option" id="2">C</li>
                            </div>
        
                            <div style="list-style-type: none;">
                                <li class="answer" id="2">
                                    {{question.answer3}}</li>
                            </div>
        
                            <div style="list-style-type: none;">
                                <li style="text-align: center;" class="withicon option" id="2">
                                    <label class="labelX" [ngClass]="{'icofont-tick-mark correctIcon' : question.answerCorrect == 2 , 'icofont-close wrongIcon'  : question.answerSelected == 2 && question.answerCorrect != 2}"></label>
                                </li>
                            </div>
                        </div>
        
                        <div class="basic-grid" style="clear: both; padding-top: 0rem;" *ngIf="question.answer4 != null">
                            <div style="list-style-type: none;">
                                <li style="text-align: center;" class="option" id="3">D</li>
                            </div>
        
                            <div style="list-style-type: none;">
                                <li class="answer" id="3">
                                    {{question.answer4}}
                                </li>
                            </div>
        
                            <div style="list-style-type: none;">
                                <li style="text-align: center;" class="withicon option" id="3">
                                    <label class="labelX" [ngClass]="{'icofont-tick-mark correctIcon' : question.answerCorrect == 3 , 'icofont-close wrongIcon'  : question.answerSelected == 3 && question.answerCorrect != 3}"></label>
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>