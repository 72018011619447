var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, ComponentFactoryResolver } from '@angular/core';
import { MatDialogRef, MatTreeNestedDataSource, MatDialog } from '@angular/material';
import { UserModel } from '../../../../models/userModel';
import { CompanyService } from '../../../../services/company.service';
import { ProfileService } from '../../../../services/profile.service';
import { TicketService } from '../../../../services/ticket.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { ProductService } from '../../../../services/product.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { DurationService } from '../../../../services/duration.service';
import { first } from 'rxjs/internal/operators/first';
import { MediaService } from '../../../../services/media.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../../services/auth.service';
import { TestService } from '../../../../services/test.service';
import { AlertMailService } from '../../../../services/alert-mail.service';
import { ConfirmComponent } from '../confirm/confirm.component';
import { UserService } from '../../../../services/user.service';
import { Utils } from 'src/utils/utils';
var DialogBoxUsersComponent = /** @class */ (function () {
    function DialogBoxUsersComponent(dialogRef, resolver, profileService, companyService, ticketService, productService, dialog, userService, durationService, formBuilder, authenticationService, mediaService, toastr, alertMailService, data, testService, utils) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.resolver = resolver;
        this.profileService = profileService;
        this.companyService = companyService;
        this.ticketService = ticketService;
        this.productService = productService;
        this.dialog = dialog;
        this.userService = userService;
        this.durationService = durationService;
        this.formBuilder = formBuilder;
        this.authenticationService = authenticationService;
        this.mediaService = mediaService;
        this.toastr = toastr;
        this.alertMailService = alertMailService;
        this.data = data;
        this.testService = testService;
        this.utils = utils;
        this.showEmptyDashboardValueAC = false;
        this.showEmptyDashboardValueImt = false;
        this.showEmptyDashboardValue = false;
        this.loading = false;
        this.submitted = false;
        this.imagePath = '';
        this.selectedValue = '';
        this.imgURL = "assets/img/img_avatar.png";
        this.treeControl = new NestedTreeControl(function (node) { return node.children; });
        this.dataSource = new MatTreeNestedDataSource();
        this.selectedAll = true;
        this.selection = new SelectionModel(true, []);
        this.emailExists = false;
        this.categoryProductList = [];
        this.UserNameExists = false;
        this.hasChild = function (_, node) { return !!node.children && node.children.length > 0; };
        this.localData = __assign({}, data);
        console.log(this.localData);
        this.action = this.localData.action;
        this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        if (this.action != 'Adicionar') {
            this.mediaService.getImageByUserId(this.localData.userId).subscribe(function (x) {
                if (x.image != null && x.image != '') {
                    _this.imgURL = 'data:image/jpg;base64,' + x.image;
                }
            });
            if (this.localData.isReportPage == false || this.localData.isReportPage == undefined) {
                this.companyService.getByName(this.localData.plataformName).subscribe(function (companies) {
                    _this.productService.getAllWithChildrenByCompanyId(companies['companyId']).subscribe(function (products) {
                        _this.dataSource.data = products;
                        _this.products = products;
                    });
                    _this.durationService.getDurationdByCompanyId(companies['companyId']).subscribe(function (durations) {
                        _this.durations = durations;
                    });
                    _this.productService.getAllDidacticmWithChildrenByCompanyId(companies['companyId']).subscribe(function (dproducts) {
                        // this.dataSource.data = products;
                        _this.didacticProducts = dproducts;
                    });
                });
                this.ticketService.ticketsByUserId(this.localData.userId).subscribe(function (tickets) {
                    _this.tickets = tickets['hasTickets'];
                });
                this.ticketService.didacticTicketsByUserId(this.localData.userId).subscribe(function (dtickets) {
                    _this.didacticTickets = dtickets['hasTickets'];
                });
            }
            if (this.localData.profileName == 'Comercial') {
                this.companyService.getCompaniesOfComercial(this.localData.userId).subscribe(function (company) {
                    _this.schools = company;
                });
            }
            this.companyService.getAllSorted().subscribe(function (companies) {
                _this.companies = companies;
                var compan = _this.companies.filter(function (item) {
                    return item.name === _this.localData.companyName;
                })[0];
                _this.localData.prefix = compan['prefix'];
            });
        }
        if (this.localData.profileName != 'Aluno' || this.currentUser.user.profileId == 1) {
            this.profileService.getAll().subscribe(function (profiles) {
                _this.profile = profiles;
                console.log(_this.profile);
            });
            this.companyService.getAllSorted().subscribe(function (companies) {
                _this.companies = companies;
                var compan = _this.companies.filter(function (item) {
                    return item.name === _this.localData.companyName;
                })[0];
                _this.localData.prefix = compan['prefix'];
            });
        }
    }
    DialogBoxUsersComponent.prototype.isReadOnly = function () {
        //action = modificar e perfil é escola e o 
        return (this.currentUser.user.profileId == 6 || this.currentUser.user.profileId == 7 || this.currentUser.user.profileId == 8) && this.action != 'Adicionar';
    };
    Object.defineProperty(DialogBoxUsersComponent.prototype, "fgeral", {
        // convenience getter for easy access to form fields
        get: function () { return this.userGeralForm.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogBoxUsersComponent.prototype, "fticket", {
        get: function () { return this.userTicketsForm.controls; },
        enumerable: true,
        configurable: true
    });
    DialogBoxUsersComponent.prototype.onChanges = function () {
        var _this = this;
        this.fgeral.email.valueChanges.subscribe(function (x) {
            _this.userService.checkEmail(x, _this.localData.userId).subscribe(function (a) {
                _this.emailExists = a == true ? true : false;
            });
        });
    };
    DialogBoxUsersComponent.prototype.onChangesNumber = function () {
        var _this = this;
        this.fgeral.studentNumber.valueChanges.subscribe(function (x) {
            _this.userService.checkUserName(_this.localData.prefix + '' + x).subscribe(function (a) {
                _this.UserNameExists = a == true ? true : false;
            });
        });
    };
    DialogBoxUsersComponent.prototype.changeCompany = function () {
        var _this = this;
        var compan = this.companies.filter(function (item) {
            return item.name === _this.fgeral.company.value;
        })[0];
        this.localData.prefix = compan['prefix'];
    };
    DialogBoxUsersComponent.prototype.changeProfile = function () {
        this.selectedValue = this.fgeral.profile.value;
    };
    DialogBoxUsersComponent.prototype.onKeyUp = function () {
        this.dialogRef.close();
    };
    DialogBoxUsersComponent.prototype.closeDialog = function () {
        this.dialogRef.close({ event: 'Cancel' });
    };
    DialogBoxUsersComponent.prototype.onSubmitGeral = function () {
        var _this = this;
        this.localData.companyId = [];
        var profiles;
        var compan;
        if (this.currentUser.user.profileId != 6) {
            profiles = this.profile.filter(function (item) {
                return item.name === _this.fgeral.profile.value;
            })[0];
            compan = this.companies.filter(function (item) {
                return item.name === _this.fgeral.company.value;
            })[0];
            this.localData.profileId = profiles['profileId'];
            if (this.localData.profileName != 'Comercial') {
                this.localData.companyId.push(compan['companyId']);
            }
            else {
                this.selectedCompanies.forEach(function (element) {
                    _this.localData.companyId.push(_this.companies.filter(function (item) { return item.name === element; })[0]['companyId']);
                });
            }
        }
        else {
            this.localData.companyId.push(this.currentUser.user.companyId);
            this.localData.profileId = 3;
        }
        this.localData.active = this.fgeral.status.untouched == true ? this.localData.active : this.fgeral.status.value;
        this.localData.contacts = this.fgeral.contacts.value;
        this.localData.name = this.fgeral.name.value;
        this.localData.nif = this.fgeral.nif.value;
        this.localData.cc = this.fgeral.cc.value;
        this.localData.email = this.fgeral.email.value;
        this.localData.learningLicense = this.fgeral.learningLicense.value;
        if (this.fgeral.studentNumber)
            this.localData.studentNumber = this.fgeral.studentNumber.value.replace('%2F', '/') == ('' || null) ? '' : this.fgeral.studentNumber.value.replace('%2F', '/');
        if (this.currentUser.user.profileId == 6) {
            if (this.fgeral.name.valid && this.fgeral.email.valid && this.fgeral.studentNumber.valid) {
                this.dialogRef.close({ event: this.action + 'Geral', data: this.localData });
            }
            else {
                this.toastr.error("formulario com erros");
            }
        }
        else {
            if (this.fgeral.name.valid && this.fgeral.email.valid && this.fgeral.company.valid
                && this.fgeral.companyN.valid && this.fgeral.learningLicense.valid && this.fgeral.profile.valid && this.fgeral.status.valid) {
                this.dialogRef.close({ event: this.action + 'Geral', data: this.localData });
                console.log(this.action);
            }
            else {
                this.toastr.error("formulario com erros");
                console.log("dsfdsf2");
            }
        }
    };
    DialogBoxUsersComponent.prototype.submitTickets = function (i, ticketType) {
        var _this = this;
        var row = {};
        var deviceWidth = this.utils.isMobileDevice() == true ? '80vw' : '25vw';
        var devideHeight = this.utils.isMobileDevice() == true ? 'auto' : '25vh';
        row.text = 'Confirma a atribuição da/s senha/s?';
        var dialogRef = this.dialog.open(ConfirmComponent, {
            id: 'confirm',
            width: deviceWidth,
            height: devideHeight,
            data: row
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === undefined) { }
            else if (result.event == 'sim') {
                _this.companyService.getAll().subscribe(function (companies) {
                    var compan = companies.filter(function (item) {
                        return item.name === _this.localData.companyName;
                    })[0];
                    if (ticketType == 'didacticTicket') {
                        _this.ticketService.ticketByDidacticMaterialIdToUser(compan['companyId'], _this.didacticProducts[i]['didacticMaterialId'], _this.didacticProducts[i]['durationId'], _this.localData.userId).subscribe(function (data) {
                            _this.toastr.success('Senhas atribuídas com sucesso!');
                        }, function (err) {
                            _this.toastr.error('Algo ocorreu mal!');
                        });
                    }
                    else {
                        console.log(_this.products[i]['categoryId']);
                        console.log(_this.products[i]);
                        console.log(_this.products);
                        _this.ticketService.ticketByCategoryIdToUser(compan['companyId'], _this.products[i]['productId'], _this.products[i]['categoryId'], _this.products[i]['durationId'], _this.localData.userId).subscribe(function (data) {
                            _this.toastr.success('Senhas atribuídas com sucesso!');
                        }, function (err) {
                            _this.toastr.error('Algo ocorreu mal!');
                        });
                    }
                });
                _this.dialogRef.close({});
            }
        });
    };
    DialogBoxUsersComponent.prototype.ngOnInit = function () {
        this.userGeralForm = this.formBuilder.group({
            name: new FormControl('', Validators.required),
            nif: new FormControl(''),
            contacts: new FormControl(''),
            email: new FormControl('', [Validators.required, Validators.email]),
            cc: new FormControl(''),
            company: new FormControl('', Validators.required),
            companyN: new FormControl(''),
            studentNumber: new FormControl('', Validators.required),
            learningLicense: new FormControl(''),
            profile: new FormControl('', Validators.required),
            status: new FormControl(''),
            emailInstrutores: new FormControl(''),
        });
        this.userTicketsForm = this.formBuilder.group({
            quantity: new FormControl('', Validators.required),
            ticketType: new FormControl('', Validators.required),
            product: new FormControl('', Validators.required)
        });
        if (this.localData.profileId == 3) {
            this.localData.profileName = "Aluno";
        }
        this.fgeral.company.setValue(this.localData.companyName);
        this.fgeral.profile.setValue(this.localData.profileName);
    };
    DialogBoxUsersComponent.prototype.selectAll = function () {
        for (var i = 0; i < this.products.length; i++) {
            if (this.products[i].children != null) {
                this.products[i].selected = this.selectedAll;
                for (var j = 0; j < this.products[i].children.length; j++) {
                    this.products[i].children[j].selected = this.selectedAll;
                }
            }
            else {
                this.products[i].selected = this.products[i].selected == this.selectedAll;
            }
        }
        this.selectedAll = this.selectedAll == true ? false : true;
    };
    DialogBoxUsersComponent.prototype.masterToggle = function (node) {
        if (node.children != null) {
            for (var i = 0; i < node.children.length; i++) {
                node.children[i].selected = node.children[i].selected == true ? false : true;
            }
        }
        else {
            node.selected = node.selected == true ? false : true;
        }
    };
    DialogBoxUsersComponent.prototype.preview = function (files) {
        var _this = this;
        if (files.length === 0) {
            return;
        }
        var mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.message = 'Only images are supported.';
            return;
        }
        var reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(files[0]);
        // tslint:disable-next-line: variable-name
        reader.onload = function (_event) {
            _this.imgURL = reader.result;
            // console.log(reader.result.toString().split(',')[1]);
            var url = reader.result.toString().split(',')[1];
            // let url = this.imgURL.replace(/data:image\/png;base64,/g, '') || this.imgURL.replace(/data:image\/jpeg;base64,/g, '') || this.imgURL.replace(/data:image\/jpg;base64,/g, '');
            _this.mediaService.createImageToUser(_this.localData.userId, url, mimeType, files[0].name, files[0].size).pipe(first()).subscribe(function (user) {
                _this.toastr.success('Imagem do Utilizador Modificada com sucesso!');
            });
        };
    };
    DialogBoxUsersComponent.prototype.clickedOption = function () {
    };
    DialogBoxUsersComponent.prototype.showEmptyDashboard = function () {
        return this.showEmptyDashboardValue;
    };
    DialogBoxUsersComponent.prototype.showEmptyDashboardImt = function () {
        return this.showEmptyDashboardValueImt;
    };
    DialogBoxUsersComponent.prototype.showEmptyDashboardAC = function () {
        return this.showEmptyDashboardValueAC;
    };
    DialogBoxUsersComponent.prototype.sendEmail = function () {
        var _this = this;
        this.alertMailService.sendAccountInfo(this.localData.userId).subscribe(function (a) { _this.toastr.success("Dados enviados com sucesso"); });
    };
    DialogBoxUsersComponent.prototype.changeStatus = function (i) {
        var _this = this;
        this.tickets[i].active = this.tickets[i].active == true ? false : true;
        this.ticketService.userTicketStatus(this.tickets[i].userTicketId, this.tickets[i].active).subscribe(function (c) {
            _this.toastr.success('senha ativada/inativada com sucesso');
        });
    };
    DialogBoxUsersComponent.prototype.changeDidaticStatus = function (i) {
        var _this = this;
        this.didacticTickets[i].active = this.didacticTickets[i].active == true ? false : true;
        this.ticketService.userTicketStatus(this.didacticTickets[i].userTicketId, this.didacticTickets[i].active).subscribe(function (c) {
            _this.toastr.success('senha ativada/inativada com sucesso');
        });
    };
    DialogBoxUsersComponent.prototype.deleteCompanyLinkToComercial = function (i) {
        var _this = this;
        this.companyService.unlinkComercialUser(this.localData.userId, this.schools[i]['companyId']).subscribe(function (c) {
            _this.toastr.success('Empresa removida do Comercial com sucesso');
            _this.dialogRef.close({});
        });
    };
    DialogBoxUsersComponent.prototype.openCity = function (evt, cityName, cityTab) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("active", "");
        }
        document.getElementById(cityName).style.display = "block";
        document.getElementById(cityName).classList.add("active");
        document.getElementById(cityTab).classList.add("active");
    };
    DialogBoxUsersComponent.prototype.isAdmin = function () {
        return (this.currentUser.user.role == 'Administrador' ? true : false);
    };
    return DialogBoxUsersComponent;
}());
export { DialogBoxUsersComponent };
