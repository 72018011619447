var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { QuestionService } from '../../../../services/question.service';
import { BasePageComponent } from '../../../base-page';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../../services/http/http.service';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../../../services/auth.service';
import { TestDefinitionService } from '../../../../services/test-definition.service';
import { Subject } from 'rxjs/internal/Subject';
import { filter } from 'rxjs/internal/operators/filter';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Utils } from 'src/utils/utils';
var DialogBoxTestResultFreeComponent = /** @class */ (function (_super) {
    __extends(DialogBoxTestResultFreeComponent, _super);
    function DialogBoxTestResultFreeComponent(questionService, store, router, _location, authenticationService, httpSv, testDefinitionService, dialogRef, data, utils) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.questionService = questionService;
        _this.router = router;
        _this._location = _location;
        _this.authenticationService = authenticationService;
        _this.testDefinitionService = testDefinitionService;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.utils = utils;
        _this.tableData = [];
        _this.bordered = true;
        _this.destroyed = new Subject();
        _this.localData = __assign({}, data);
        console.log(_this.localData);
        _this.tableData = _this.localData.themes;
        _this.approved = _this.localData.totalWrong > 3 ? "Reprovado" : "Aprovado";
        _this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }), takeUntil(_this.destroyed))
            .subscribe();
        return _this;
    }
    DialogBoxTestResultFreeComponent.prototype.ngOnInit = function () { };
    DialogBoxTestResultFreeComponent.prototype.ngOnDestroy = function () {
        this.destroyed.next();
        this.destroyed.complete();
    };
    DialogBoxTestResultFreeComponent.prototype.rever = function () {
        this.dialogRef.close();
        this.dialogRef._containerInstance = null;
    };
    DialogBoxTestResultFreeComponent.prototype.out = function () {
        console.log(this.router.url);
        //this.router.navigate([this.router.url])
        window.location.reload();
    };
    DialogBoxTestResultFreeComponent.prototype.currentPageDataChange = function ($event) {
        this.tableData = $event;
    };
    return DialogBoxTestResultFreeComponent;
}(BasePageComponent));
export { DialogBoxTestResultFreeComponent };
