import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AuthService } from '../../../../services/auth.service';
import { BasePageComponent } from '../../../base-page';
import { CategoryService } from '../../../../services/category.service';
import { ELearningService } from 'src/app/services/elearning.service';
import { ELearningStudentComponent } from './elearning-student.component';
import { HttpService } from '../../../../services/http/http.service';
import { IAppState } from '../../../../interfaces/app-state';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { TestService } from '../../../../services/test.service';
import { TicketService } from 'src/app/services/ticket.service';
import { Utils } from 'src/utils/utils';

@Component({
  selector: 'app-elearning-student-container-component',
  templateUrl: './elearning-student-container-component.html',
  styleUrls: ['./elearning-student-container-component.scss']
})

export class ELearningStudentContainerComponent extends BasePageComponent implements OnInit, OnDestroy {
  currentUser: any;
  categories: any[] = [];
  activeCategory: any;
  showMessage: boolean = false;
  selectedValue: number = 0;
  userId: number = 0;

  constructor(store: Store<IAppState>, public dialog: MatDialog, private testService: TestService, private categoryService: CategoryService, private authenticationService: AuthService,
    private ticket: TicketService,
    private elearning: ELearningService,
    httpSv: HttpService, private location: Location,
    public utils: Utils,
    private modal: TCModalService) {
    super(store, httpSv);

    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      this.userId = this.currentUser.user.id;

    } );

    this.pageData = {
      title: 'Ensino à Distância',
      loaded: true
    };   
  }

  ngOnInit() {
    super.ngOnInit();

    this.ticket.ticketsByUserId(this.currentUser.user.id).subscribe(ticket => {

      this.categories = [];
      ticket['hasTickets'].forEach(element => {
        
          if (element['type'] =='eLearning' && element['daysLeft'] > 0 ) {
              if(!this.categories.find(x => x.categoryId === element.categoryId))
                  this.categories.push(element);
          }
      });

      if(this.categories.length > 0)
      {
        this.categories.sort((a, b) => (a.categoryName > b.categoryName) ? 1 : -1)

        console.log(this.categories);
  
        this.activeCategory = this.categories[0];
        this.selectedValue = this.activeCategory.categoryId;
  
        this.showMessage = true; 
      }
      else 
      {
        this.showMessage = true; 
      }
      //console.log(this.categories);
    });
  }

  select(e, i, o) {
    //console.log(this.categories);
    //console.log(o);
    //console.log(this.categories[i]);
    this.activeCategory = this.categories[i];
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}


