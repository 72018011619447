import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatCardModule, MatCheckboxModule, MatIconModule, MatInputModule, MatMenuModule, MatProgressSpinnerModule, MatToolbarModule } from '@angular/material';

import { ActionsComponent } from './components/actions';
import { AppLayoutComponent } from './app';
import { BaseLayoutComponent } from './base-layout';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer';
import { LoginFormComponent } from './components/login-form';
import { LogoComponent } from './components/logo';
import { MenuComponent } from './components/menu';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { NavbarComponent } from './components/navbar';
import { NavbarSkeletonComponent } from './components/navbar-skeleton';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PublicLayoutComponent } from './public';
import { RegisterFormComponent } from './components/register-form';
import { RouterModule } from '@angular/router';
import { SettingsComponent } from './components/settings';
import { TreetableModule } from 'ng-material-treetable';
import { UIModule } from '../ui/ui.module';

// import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
    MatMenuModule,
    MatButtonModule, MatCheckboxModule, MatToolbarModule, MatInputModule, MatProgressSpinnerModule, MatCardModule, MatIconModule,
    ReactiveFormsModule,
    // MalihuScrollbarModule.forRoot(),
    UIModule,
    TreetableModule,
  ],
  declarations: [
    BaseLayoutComponent,
    AppLayoutComponent,
    MenuItemComponent,
    NavbarComponent,
    MenuComponent,
    FooterComponent,
    LogoComponent,
    ActionsComponent,
    PublicLayoutComponent,
    LoginFormComponent,
    RegisterFormComponent,
    NavbarSkeletonComponent,
    SettingsComponent,
  ],
  exports: [
    LoginFormComponent,
    RegisterFormComponent,
    SettingsComponent
  ]
})
export class LayoutModule { }
