import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(private http: HttpClient) {

  }
  // getCategoryIdByUserId(id: number){
  //   return this.http.get<any[]>(`${environment.apiUrl}/UserTicket/category/${id}`);
  // }

  ticketByCategoryId(CompanyId: number, ProductCategoriesId: number[], DidacticMaterialsId: number[], DurationId: number, Quantity: number, CreatedBy: string) {
    return this.http.post<any>(`${environment.apiUrl}/Ticket/`, { CompanyId, ProductCategoriesId, DidacticMaterialsId, DurationId, Quantity, CreatedBy });
  }

  ticketsByCompanyId(id: number) {
    return this.http.get<any[]>(`${environment.apiUrl}/Ticket/company/${id}`);
  }
  didacticMaterialTicketsByCompanyId(id: number) {
    return this.http.get<any[]>(`${environment.apiUrl}/Ticket/company/didacticticket/${id}`);
  }
  ticketsByUserId(id: number) {
    return this.http.get<any[]>(`${environment.apiUrl}/UserTicket/user/${id}`);
  }
  didacticTicketsByUserId(id: number) {
    return this.http.get<any[]>(`${environment.apiUrl}/UserTicket/didactic/user/${id}`);
  }
  ticketByCategoryIdToUser(CompanyId: number, ProductId: number, CategoryId: number, DurationId: number, UserId: number) {
    var CategoryIdList: number[] = [];
    CategoryIdList.push(CategoryId);

    var ProductIdList: number[] = [];
    ProductIdList.push(ProductId);
    return this.http.post<any>(`${environment.apiUrl}/UserTicket/`, { CompanyId, CategoryIdList, ProductIdList, DurationId, UserId });
  }
  ticketByDidacticMaterialIdToUser(CompanyId: number, didacticMaterialId: number, DurationId: number, UserId: number) {
    var DidacticMaterialIdList: number[] = [];
    DidacticMaterialIdList.push(didacticMaterialId);
    return this.http.post<any>(`${environment.apiUrl}/UserTicket/didactitouser/`, { CompanyId, DidacticMaterialIdList, DurationId, UserId });
  }
  activateTicket(x: any[], userId: number) {
    let ids: number[] = [];
    x.forEach(item => {
      ids.push(item['userTicketId']);
    });
    return this.http.put<any>(`${environment.apiUrl}/UserTicket/activate/${userId}`, { userId, ids });
  }
  ticketStatus(id: number, active: boolean) {
    return this.http.put<any>(`${environment.apiUrl}/Ticket/ticketStatus/${id}/${active}`, { active });
  }
  removeticket(id: number) {
    return this.http.put<any>(`${environment.apiUrl}/Ticket/removeTickets/${id}`, { id });
  }
  userTicketStatus(id: number, active: boolean) {
    return this.http.put<any>(`${environment.apiUrl}/UserTicket/userTicketStatus/${id}/${active}`, { active });
  }
}
