/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog-box-ebook.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../safe.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./dialog-box-ebook.component";
import * as i5 from "@angular/material/dialog";
var styles_DialogBoxEbookComponent = [i0.styles];
var RenderType_DialogBoxEbookComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogBoxEbookComponent, data: {} });
export { RenderType_DialogBoxEbookComponent as RenderType_DialogBoxEbookComponent };
export function View_DialogBoxEbookComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafePipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 1, "iframe", [["allowfullscreen", ""], ["frameborder", "0"], ["height", "100%"], ["mozallowfullscreen", ""], ["muted", "muted"], ["webkitallowfullscreen", ""], ["width", "100%"]], [[8, "src", 5]], null, null, null, null)), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v, 0), _co.url)); _ck(_v, 1, 0, currVal_0); }); }
export function View_DialogBoxEbookComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-box-ebook", [], null, null, null, View_DialogBoxEbookComponent_0, RenderType_DialogBoxEbookComponent)), i1.ɵdid(1, 114688, null, 0, i4.DialogBoxEbookComponent, [i5.MatDialogRef, [2, i5.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogBoxEbookComponentNgFactory = i1.ɵccf("app-dialog-box-ebook", i4.DialogBoxEbookComponent, View_DialogBoxEbookComponent_Host_0, {}, {}, []);
export { DialogBoxEbookComponentNgFactory as DialogBoxEbookComponentNgFactory };
