import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'page-404',
  templateUrl: './page-404.component.html',
  styleUrls: ['../error-base-page/error-base-page.component.scss'],
})
export class Page404Component implements OnInit {
  title: string;
  subTitle: string;
  errorMessage: string;

  constructor() { }

  ngOnInit() {
    this.title = '404 Página não encontrada';
    this.subTitle = 'A página que tentou aceder não existe!';
    this.errorMessage = 'Verifique se o endereço foi indroduzido corretamente';
  }
}
