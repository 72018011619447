<link href="https://fonts.googleapis.com/css?family=Roboto+Condensed:700" rel="stylesheet" type="text/css">
<link href="https://fonts.googleapis.com/css?family=Roboto:100" rel="stylesheet" type="text/css">
<link href="https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap" rel="stylesheet">


<div class="Dummy">

    <div class="container d-block d-xl-none" style="width: 100%; background-color: #134472;color:#80BBD7; display: flex; justify-content: space-evenly; align-items: center;">
        <div class="row no-gutters align-items-center" style="height: 48px;" >
            <div class="col-8 text-left">
                <span class="terminate roboto400" style="color:red" id="termin" (click)="terminate()" *ngIf="false">
                    <strong id="ter">&nbsp;Terminar&nbsp;teste</strong>
                </span>
            </div>
            <div class="buttonsContainer col-4 text-right">
                <button class="helpButton" rel="tooltip" (click)="openHelp()">
                    <i class="icofont-question"></i>
                </button>

                <button class="closeButton" (click)="close()" rel="tooltip"
                    matTooltip="Terminará o teste">
                    <i class="icofont-close-line"></i>
                </button>

            </div>
        </div>
    </div>


    <div class="container d-none d-xl-block" style="width: 100%; height: 48px; background-color: #134472;color:#80BBD7; display: flex; justify-content: space-evenly; align-items: center;">
        <div class="row no-gutters align-items-center" style="height: 48px;" >
            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4" *ngIf="this.localData.categoryId != 55">
                <span class="terminate roboto500" id="type">TESTES&nbsp;DE&nbsp;CÓDIGO&nbsp;<span
                        style="color:white">{{localData.font}}</span></span>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4" *ngIf="this.localData.categoryId == 55">
                <span class="terminate roboto500" id="type">Táxis&nbsp;Curso&nbsp;de&nbsp;Formação&nbsp;Inicial&nbsp;<span
                        style="color:white">{{localData.font}}</span></span>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 text-truncate">
                <span class="terminate roboto400" id="name">{{currentName}}&nbsp;<span *ngIf="nif">-</span>&nbsp;{{nif}}</span>
            </div>
            <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <span class="terminate roboto400" style="color:red" id="termin" (click)="terminate()" *ngIf="false">
                    <strong id="ter">&nbsp;Terminar&nbsp;teste</strong>
                </span>
            </div>
            <div class="buttonsContainer col-sm-2 col-md-2 col-lg-2 col-xl-2 text-right">
                <button class="helpButton" rel="tooltip" (click)="openHelp()">
                    <i class="icofont-question"></i>
                </button>
        
                <button class="closeButton" (click)="close()" rel="tooltip"
                    matTooltip="Terminará o teste">
                    <i class="icofont-close-line"></i>
                </button>
            </div>    
        </div>
    </div>

    <div id="wizardContainer" class="wizardContainer">
        <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="indexChange($event)" class="x mat-tab-group"
            (selectedTabChange)="selectTab(selected.value)" (click)="selectTab(selected.value)" headerPosition="below">
            <mat-tab *ngFor="let question of questions; let index = index" [label]="index + 1" class="wrongTab">

                <!-- Mobile -->
                <div class="container d-block d-lg-none d-xl-none" (swipeleft)="swipe(index, $event.type)" (swiperight)="swipe(index, $event.type)" style="width: 100%; height: 100%; display: inline-block;">
                    <div class="row no-gutters" style="height: 30px;">
                        <div class="col roboto300">
                            <p><strong style="color: white;">{{index +1}}</strong><span style="color:#80BBD7">/{{numberOfQuestions}}</span></p>
                        </div>
                        <div class="col">
                            <p class="roboto300"><strong style="color:#262A32">ID.{{question.oldQuestionId | replace:'[a-zA-z]':''}}</strong></p>
                        </div>
                        <div class="col roboto300 text-right" *ngIf="false">
                            <div><span style="color:white;">{{minutesLeft}}&nbsp;Min</span></div>
                        </div>
                    </div>
                    <div class="row no-gutters" style="margin: auto; position: relative; height: calc(100%-30px); overflow: auto;">
                        <div class="col-12 text-center" *ngIf="question.image">
                            <img id="imagem" class="imgQuestion" src="data:image/jpg;base64,{{question.image}}" (click)="zoom()" alt="" />
                            <img src="../../../../../assets/lupa.png" class="lupa" />
                        </div>
                        <div class="col-12" style="padding-top: 2vw;">
                            <div style="list-style-type: none; width: 100%;">
                                <li class="question" style="background-color: #80BBD7;">{{question.question}}</li>
                                
                                <li><div *ngIf="question.answerSelected == -1" style="color:red;">Questão não respondida</div></li>

                                <li *ngIf="question.answer1 != null" class="answer" id="0" [ngClass]="{'correct' : question.answerCorrect == 0, 'wrong'  : question.answerSelected == 0 && question.answerCorrect != 0}">
                                    {{question.answer1}}
                                </li>
                                <li *ngIf="question.answer2 != null" class="answer" id="1" [ngClass]="{'correct' : question.answerCorrect == 1, 'wrong'  : question.answerSelected == 1 && question.answerCorrect != 1}">
                                    {{question.answer2}}
                                </li>
                                <li *ngIf="question.answer3 != null" class="answer" id="2" [ngClass]="{'correct' : question.answerCorrect == 2, 'wrong'  : question.answerSelected == 2 && question.answerCorrect != 2}">
                                    {{question.answer3}}
                                </li>
                                <li *ngIf="question.answer4 != null" class="answer" id="3" [ngClass]="{'correct' : question.answerCorrect == 3, 'wrong'  : question.answerSelected == 3 && question.answerCorrect != 3}">
                                    {{question.answer4}}
                                </li>
                                <li *ngIf="question.answer3 == null" class="optionNull"><label></label></li>
                                <li *ngIf="question.answer4 == null" class="optionNull"><label></label></li>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Sem imagem em desktop -->
                <div class="container d-none d-lg-block d-xl-block no-gutters" style="width: 100%; display: inline-block;" *ngIf="!question.image">
                    <div class="row no-gutters">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 no-gutters">
                            <div class="container no-gutters" style="height: 100%; width: 100%;padding: 0px !important;">
                                <div class="row no-gutters align-items-start" style="min-height: 140px; height: 140px;">
                                    <div class="col roboto300">
                                        <p class="index customColor">
                                            <strong class="customNumber" style="font-size: 48px">{{index +1}}</strong><span class="roboto300" style="color:#80BBD7">/{{numberOfQuestions}}</span>
                                        </p>
                                        <p class="roboto300"><strong style="color:#262A32">ID.{{question.oldQuestionId | replace:'[a-zA-z]':''}}</strong></p>
                                    </div>
                                    <div class="col roboto300 text-right" *ngIf="false">
                                        <div id="customCorner"><span class="customSpan" style="font-size: 1.35vw; color:white;">{{minutesLeft}}&nbsp;Min</span></div>
                                    </div>
                                </div>
                                <div class="row no-gutters align-items-end" style="height:calc(100% - 140px)">
                                    <div class="col">
                                        <div class="question">{{question.question}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div *ngIf="question.answerSelected == -1" style="color:red; float: right;">
                        <br/>Questão não respondida
                    </div>
                    

                    <div class="basic-grid" style="clear: both; padding-top: 1rem;" *ngIf="question.answer1 != null">
                        <div style="list-style-type: none;">
                            <li *ngIf="question.answer1 != null" style="text-align: center;" class="option" id="0">A</li>
                        </div>

                        <div style="list-style-type: none;">
                            <li class="answer" id="0">{{question.answer1}}</li>
                        </div>

                        <div style="list-style-type: none;">
                            <li style="text-align: center;" class="withicon option" id="0">
                                <label class="labelX" [ngClass]="{'icofont-tick-mark correctIcon' : question.answerCorrect == 0 , 'icofont-close wrongIcon'  : question.answerSelected == 0 && question.answerCorrect != 0}"></label>
                            </li>
                        </div>
                    </div>

                    <div class="basic-grid" style="clear: both; padding-top: 0rem;" *ngIf="question.answer2 != null">
                        <div style="list-style-type: none;">
                            <li style="text-align: center;" class="option" id="1">B</li>
                        </div>

                        <div style="list-style-type: none;">
                            <li class="answer" id="1">{{question.answer2}}</li>
                        </div>

                        <div style="list-style-type: none;">
                            <li style="text-align: center;" class="withicon option" id="1">
                                <label class="labelX" [ngClass]="{'icofont-tick-mark correctIcon' : question.answerCorrect == 1 , 'icofont-close wrongIcon'  : question.answerSelected == 1 && question.answerCorrect != 1}"></label>
                            </li>
                        </div>
                    </div>


                    <div class="basic-grid" style="clear: both; padding-top: 0rem;" *ngIf="question.answer3 != null">
                        <div style="list-style-type: none;">
                            <li style="text-align: center;" class="option" id="2">C</li>
                        </div>

                        <div style="list-style-type: none;">
                            <li class="answer" id="2">
                                {{question.answer3}}</li>
                        </div>

                        <div style="list-style-type: none;">
                            <li style="text-align: center;" class="withicon option" id="2">
                                <label class="labelX" [ngClass]="{'icofont-tick-mark correctIcon' : question.answerCorrect == 2 , 'icofont-close wrongIcon'  : question.answerSelected == 2 && question.answerCorrect != 2}"></label>
                            </li>
                        </div>
                    </div>

                    <div class="basic-grid" style="clear: both; padding-top: 0rem;" *ngIf="question.answer4 != null">
                        <div style="list-style-type: none;">
                            <li style="text-align: center;" class="option" id="3">D</li>
                        </div>

                        <div style="list-style-type: none;">
                            <li class="answer" id="3">
                                {{question.answer4}}
                            </li>
                        </div>

                        <div style="list-style-type: none;">
                            <li style="text-align: center;" class="withicon option" id="3">
                                <label class="labelX" [ngClass]="{'icofont-tick-mark correctIcon' : question.answerCorrect == 3 , 'icofont-close wrongIcon'  : question.answerSelected == 3 && question.answerCorrect != 3}"></label>
                            </li>
                        </div>
                    </div>
                </div>

                <!-- Com imagem em desktop -->
                <div class="container d-none d-lg-block d-xl-block no-gutters" style="width: 100%; display: inline-block;" *ngIf="question.image">
                    <div class="row no-gutters">
                        <div class="col-sm-4 col-md-5 col-lg-5 col-xl-4">
                            <img id="imagem" class="imgQuestion" src="data:image/jpg;base64,{{question.image}}" (click)="zoom()" alt="" style="width: 100%; max-height: 450px;" />
                            <img src="../../../../../assets/lupa.png" class="lupa" />
                        </div>

                        <div class="col-sm-8 col-md-7 col-lg-7 col-xl-8 no-gutters">
                            <div class="container no-gutters" style="height: 100%; width: 100%;">
                                <div class="row no-gutters align-items-start" style="min-height: 140px; height: 140px;">
                                    <div class="col roboto300">
                                        <p class="index customColor">
                                            <strong class="customNumber" style="font-size: 48px">{{index +1}}</strong><span class="roboto300" style="color:#80BBD7">/{{numberOfQuestions}}</span>
                                        </p>
                                        <p class="roboto300"><strong style="color:#262A32">ID.{{question.oldQuestionId | replace:'[a-zA-z]':''}}</strong></p>
                                    </div>
                                    <div class="col roboto300 text-right" *ngIf="false">
                                        <div id="customCorner"><span class="customSpan" style="font-size: 1.35vw; color:white;">{{minutesLeft}}&nbsp;Min</span></div>
                                    </div>
                                </div>
                                <div class="row no-gutters align-items-end" style="height:calc(100% - 140px)">
                                    <div class="col">
                                        <div class="question">{{question.question}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="question.answerSelected == -1" style="color:red; float: right;">
                        <br/>Questão não respondida
                    </div>
                    

                    <div class="basic-grid" style="clear: both; padding-top: 1rem;" *ngIf="question.answer1 != null">
                        <div style="list-style-type: none;">
                            <li *ngIf="question.answer1 != null" style="text-align: center;" class="option" id="0">A</li>
                        </div>

                        <div style="list-style-type: none;">
                            <li class="answer" id="0">{{question.answer1}}</li>
                        </div>

                        <div style="list-style-type: none;">
                            <li style="text-align: center;" class="withicon option" id="0">
                                <label class="labelX" [ngClass]="{'icofont-tick-mark correctIcon' : question.answerCorrect == 0 , 'icofont-close wrongIcon'  : question.answerSelected == 0 && question.answerCorrect != 0}"></label>
                            </li>
                        </div>
                    </div>

                    <div class="basic-grid" style="clear: both; padding-top: 0rem;" *ngIf="question.answer2 != null">
                        <div style="list-style-type: none;">
                            <li style="text-align: center;" class="option" id="1">B</li>
                        </div>

                        <div style="list-style-type: none;">
                            <li class="answer" id="1">{{question.answer2}}</li>
                        </div>

                        <div style="list-style-type: none;">
                            <li style="text-align: center;" class="withicon option" id="1">
                                <label class="labelX" [ngClass]="{'icofont-tick-mark correctIcon' : question.answerCorrect == 1 , 'icofont-close wrongIcon'  : question.answerSelected == 1 && question.answerCorrect != 1}"></label>
                            </li>
                        </div>
                    </div>


                    <div class="basic-grid" style="clear: both; padding-top: 0rem;" *ngIf="question.answer3 != null">
                        <div style="list-style-type: none;">
                            <li style="text-align: center;" class="option" id="2">C</li>
                        </div>

                        <div style="list-style-type: none;">
                            <li class="answer" id="2">
                                {{question.answer3}}</li>
                        </div>

                        <div style="list-style-type: none;">
                            <li style="text-align: center;" class="withicon option" id="2">
                                <label class="labelX" [ngClass]="{'icofont-tick-mark correctIcon' : question.answerCorrect == 2 , 'icofont-close wrongIcon'  : question.answerSelected == 2 && question.answerCorrect != 2}"></label>
                            </li>
                        </div>
                    </div>

                    <div class="basic-grid" style="clear: both; padding-top: 0rem;" *ngIf="question.answer4 != null">
                        <div style="list-style-type: none;">
                            <li style="text-align: center;" class="option" id="3">D</li>
                        </div>

                        <div style="list-style-type: none;">
                            <li class="answer" id="3">
                                {{question.answer4}}
                            </li>
                        </div>

                        <div style="list-style-type: none;">
                            <li style="text-align: center;" class="withicon option" id="3">
                                <label class="labelX" [ngClass]="{'icofont-tick-mark correctIcon' : question.answerCorrect == 3 , 'icofont-close wrongIcon'  : question.answerSelected == 3 && question.answerCorrect != 3}"></label>
                            </li>
                        </div>
                    </div>
                </div>

            </mat-tab>
        </mat-tab-group>
    </div>
</div>