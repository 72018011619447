import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';

import { ActionsComponent } from 'src/app/layout/components/actions';
import { AlertMailService } from '../../../services/alert-mail.service';
import { AuthService } from '../../../services/auth.service';
import { BasePageComponent } from '../../base-page';
import { CompanyService } from '../../../services/company.service';
import { DialogBoxEmailComponent } from '../DialogBoxes/dialog-box-email/dialog-box-email.component';
import { HttpService } from '../../../services/http/http.service';
import { IAppState } from '../../../interfaces/app-state';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../../models/user';
import { Utils } from 'src/utils/utils';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent extends BasePageComponent implements OnInit, OnDestroy {
  currentUser: User;
  mails: any[] = [];
  responded: any[] = [];
  inputValue: string = '';
  closeDropdown: EventEmitter<boolean>;
  contacts: string = '';
  selectedTab: any = 'Enviadas';
  constructor(store: Store<IAppState>, public dialog: MatDialog, private authenticationService: AuthService, private companyService: CompanyService,
    httpSv: HttpService, private toastr: ToastrService, private alertMailService: AlertMailService,
    public utils: Utils
    ) {
    super(store, httpSv);
    this.closeDropdown = new EventEmitter<boolean>();
    this.getData(`${environment.apiUrl}/Category/`, 'tabledata', 'setLoaded');
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.alertMailService.getRequestsByUser(this.currentUser.user.id).subscribe(c => {
      this.mails = c;
    });
    this.alertMailService.getRespondedRequestsByUser(this.currentUser.user.id).subscribe(c => {
      this.responded = c;
    });
    this.pageData = {
      title: 'Mensagens',
      loaded: true
    };
    
    this.companyService.getById(this.currentUser.user.companyId).subscribe(
      c => {
        this.contacts = c.contacts;
      }
    );   
  }

  public openDialog(subject, responded, response, question) {
    let row: any = {};
    row.subject = subject;
    row.responded = responded;
    row.response = response;
    row.question = question;

    let width  = this.utils.isMobileDevice() == true ? '95vw' : '60vw';
    let height = this.utils.isMobileDevice() == true ? 'auto' : 'auto';

    const dialogRef = this.dialog.open(DialogBoxEmailComponent, {
      disableClose: true,
      height: height,
      maxWidth: width,
      width: width,
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result && result.data) {
        const _data = result.data;
        const _body = result.data.body;
        const _reqType = result.data.requestType;
        const _subject = result.data.subject;
        const _is_valid = result && _data && _reqType && _body && _subject;

        if (_is_valid) {
          this.sendEmail(result.data);        
        }
      }
    });
  }

  inputSubmit() {
    if (this.inputValue != "") {
      const cards = document.querySelectorAll('tc-card');
      cards.forEach(elem => {
        elem.classList.remove('hide');
      });
      cards.forEach(elem => {
        if (elem.className.match(this.inputValue)) {
          elem.classList.remove('hide');
        }
        else {
          elem.classList.add('hide');
        }
      });
    }
    this.inputValue = "";
  }

  show(type: string, param: string) {
    // console.log(type);
    // console.log(param);
    const cards = document.querySelectorAll('tc-card');
    cards.forEach(elem => {
      elem.classList.remove('hide');
    });
    if (type == 'show') {
      if (param == 'all') {
        cards.forEach(elem => {
          elem.classList.remove('hide');
        });
      } else {
        cards.forEach(elem => {
          if (elem.className.match(param)) {
            elem.classList.remove('hide');
          }
          else {
            elem.classList.add('hide');
          }
        });
      }
    } else if (type == 'bySchool') {
      cards.forEach(elem => {
        if (elem.className.match(param.replace(' ', ''))) {
          elem.classList.remove('hide');
        }
        else {
          elem.classList.add('hide');
        }
      });
    }
    this.onCloseDropdown();
  }
  onCloseDropdown() {
    this.closeDropdown.emit(true);
  }
  isAdmin() {
    // console.log(this.currentUser.user.role);
    return (this.currentUser.user.role == 'Administrador' ? true : false);
  }
  ngOnInit() {
    super.ngOnInit();
  }
  ngOnDestroy() {~
    super.ngOnDestroy();
  }

  sendEmail(rowObj) {
    this.alertMailService.sendRequest(this.currentUser.user.id, rowObj.subject, rowObj.requestType, rowObj.body).subscribe(c => {
      this.alertMailService.getRequestsByUser(this.currentUser.user.id).subscribe(c => {
        this.mails = c;
      }); 
      this.toastr.success('Pedido enviado com sucesso! Tentaremos responder o mais breve possivel, Obrigado!');
    });
  }

  opened = false;
  openFilter() {

    const el = document.getElementById('filtro');
    if (this.opened == false) {
      el.classList.add('show');
      el.classList.remove('hide');
      this.opened = true;
    }
    else {
      el.classList.add('hide');
      el.classList.remove('show');
      this.opened = false;
    }
  }

  openCity(evt, cityName) {
    this.selectedTab = cityName;

    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("active", "");
    }
    document.getElementById(cityName).style.display = "block";
    document.getElementById(cityName).classList.add("active");
  }
}
