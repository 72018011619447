import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../../../services/auth.service';
import { MediaService } from '../../../../services/media.service';

@Component({
    selector: 'app-dialog-box-activateTicket',
    templateUrl: './dialog-box-activateTicket.component.html',
    styleUrls: ['./dialog-box-activateTicket.component.css']
})
export class DialogBoxActivateTicketComponent implements OnInit {

    localData: any;
    isSelected = true;
    tickets: any[] = [];
    currentUser;
    rgpd: any;
    constructor(public dialogRef: MatDialogRef<DialogBoxActivateTicketComponent>, private authenticationService: AuthService, private mediaService: MediaService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.localData = { ...data };
        this.authenticationService.currentUser.subscribe(
            x => (this.currentUser = x)
        );
        if (this.localData.tickets != undefined && this.localData.tickets != null) {
            this.localData.tickets.forEach(element => {
                if (element['activateDate'] == null) {
                    this.tickets.push(element);
                }
            });
        }
        this.mediaService.getRGPD().subscribe(
            a => {
                console.log(a.url);
                let fileBlob: any = this.b64toBlob(a.url, 'application/pdf');
                this.rgpd = URL.createObjectURL(fileBlob);
                // window.open(encodeURI(fileBlob + '#toolbar=0&navpanes=0'));
            }
        );
    }
    ngOnInit() { }
    activate() {
        if (this.isSelected == true) {
            this.dialogRef.close({ event: 'activate', data: this.tickets, agreed: this.isSelected });
        }
    }
    out() {
        this.dialogRef.close({});
    }
    changeValue() {
        this.isSelected = this.isSelected == true ? false : true;
    }
    changeTicket(i) {
        if (this.tickets.findIndex(c => c['userTicketId'] === this.localData.tickets[i]['userTicketId']) != -1) {
            this.tickets.splice(this.tickets.findIndex(c => c['userTicketId'] === this.localData.tickets[i]['userTicketId']), 1);
        } else {
            this.tickets.push(this.localData.tickets[i]);
        }
    }

    b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    open() {
        window.open(encodeURI(this.rgpd + '#toolbar=0&navpanes=0'));
    }
}