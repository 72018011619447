import { Component, OnInit, Optional, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { QuestionService } from '../../../../services/question.service';
import { environment } from '../../../../../environments/environment';
import { BasePageComponent } from '../../../base-page';
import { IAppState } from '../../../../interfaces/app-state';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../../services/http/http.service';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../../../services/auth.service';
import { TestDefinitionService } from '../../../../services/test-definition.service';
import { BlockTemplateComponent } from '../../block-template/block-template.component';
import { BlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs/internal/Subject';
import { filter } from 'rxjs/internal/operators/filter';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Utils } from 'src/utils/utils';

@Component({
  selector: "app-dialog-box-test-result-free",
  templateUrl: "./dialog-box-test-result-free.component.html",
  styleUrls: ["./dialog-box-test-result-free.component.css"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DialogBoxTestResultFreeComponent
  extends BasePageComponent
  implements OnInit {
  questions: any[];
  tableData: any[] = [];
  position: "bottom";
  bordered = true;
  localData: any;
  currentUser;
  currentName;
  repprovedQuestions;
  approvedQuestions;

  approved: string;

  public destroyed = new Subject<any>();
  constructor(
    private questionService: QuestionService,
    store: Store<IAppState>,
    private router: Router,
    private _location: Location,
    private authenticationService: AuthService,
    httpSv: HttpService,
    private testDefinitionService: TestDefinitionService,
    public dialogRef: MatDialogRef<DialogBoxTestResultFreeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public utils: Utils
  ) {
    super(store, httpSv);
    this.localData = { ...data };

    console.log(this.localData);
    this.tableData = this.localData.themes;
    this.approved = this.localData.totalWrong > 3 ? "Reprovado" : "Aprovado";
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  ngOnInit() {}
  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
  rever() {
    this.dialogRef.close();
    this.dialogRef._containerInstance = null;
  }
  out() {
    console.log (this.router.url);
    //this.router.navigate([this.router.url])
    window.location.reload();
  }
  
  currentPageDataChange($event: any[]): void {
    this.tableData = $event;
  }
}
