import { Component, HostBinding, Input, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { first } from 'rxjs/operators';
import { HttpService } from '../../../services/http/http.service';
import * as SettingsActions from '../../../store/actions/app-settings.actions';
import { IAppState } from '../../../interfaces/app-state';

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { IAppSettings } from '../../../interfaces/settings';
import { AuthService } from '../../../services/auth.service';
import { MenuService } from '../../../services/menu.service';
import { MenuItem } from '../../../models/menuItem';
import { Subscription } from 'rxjs/internal/Subscription';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('subMenu', [
      state(
        'active',
        style({
          height: '*',
          visibility: 'visible',
        })
      ),
      state(
        'inactive',
        style({
          height: 0,
          visibility: 'hidden',
        })
      ),
      transition('inactive => active', animate('300ms ease-in-out')),
      transition('active => inactive', animate('200ms ease-in-out')),
    ]),
  ],
})
export class MenuComponent implements OnInit {
  @HostBinding('class.main-menu') true;
  @HostBinding('class.app') get app() {
    return this.orientation === 'app';
  }
  @HostBinding('class.vertical') get vertical() {
    return this.orientation === 'vertical';
  }

  @Input() orientation: string;

  routerSubscription: Subscription;
  settings: IAppSettings;
  menuItems: MenuItem[];
  contrastColor: string;
  accentColor: string;
  navColor: string;
  caret: string;
  currentUser: any;
  constructor(
    private httpSv: HttpService,
    private store: Store<IAppState>,
    private router: Router,
    private elementRef: ElementRef,
    private authenticationService: AuthService,
    private menuService: MenuService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.menuService.getAll(this.currentUser.user.profileId).pipe(first()).subscribe(menu => {
      this.menuItems = menu;
    });
  }

  ngOnInit() {
  }

  closeSidebar() {
    this.store.dispatch(new SettingsActions.SidebarState(false));
  }

  goRoute(
    url: string
  ) {
    this.router.navigate(['/app/', url]);
    this.closeSidebar();
  }

}
