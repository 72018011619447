import { Routes } from '@angular/router';
import { AppLayoutComponent } from '../layout/app';
import { AuthGuard } from '../helpers/auth.guard/auth.guard';
import { CategoriesComponent } from '../pages/custom/Plataform/categories/categories.component';
import { ChangePasswordComponent } from '../pages/apps/user-pages/change-password/change-password.component';
import { CompaniesComponent } from '../pages/custom/Access/companies/companies.component';
import { ELearningStudentContainerComponent } from '../pages/custom/Plataform/elearning-student/elearning-student-container-component';
import { EbookComponent } from '../pages/custom/Material/Ebook/ebook.component';
import { FreeComponent } from '../pages/custom/Tests/BeforefixedTest/free.component';
import { MaterialComponent } from '../pages/custom/Material/material/material.component';
import { Page404Component } from '../pages/errors/page-404';
import { PageAnalyticsComponent } from '../pages/dashboards/analytics/analytics.component';
import { PageDashboardComponent } from '../pages/dashboards/dashboard';
import { PageEditAccountComponent } from '../pages/apps/user-pages/edit-account';
import { PageResetPasswordComponent } from '../pages/apps/authentication/reset-password';
import { PageSignInComponent } from '../pages/apps/authentication/sign-in';
import { PageVerifyAccountComponent } from '../pages/apps/authentication/verify-account';
import { ProductsComponent } from '../pages/custom/Plataform/products/products.component';
import { PublicLayoutComponent } from '../layout/public';
import { QuestionsComponent } from '../pages/custom/Plataform/questions/questions.component';
import { RequestsComponent } from '../pages/custom/requests/requests.component';
import { SchoolSalesSheetsComponent } from '../pages/custom/Relatorios/RelatoriosEscola/VendasEscolas/vendasEscolas.component';
import { SchoolSheetsComponent } from '../pages/custom/Relatorios/RelatoriosEscola/relatorioEscola.component';
import { StudentRequestsComponent } from '../pages/custom/student-requests/student-requests.component';
import { StudentSheetsComponent } from '../pages/custom/Relatorios/RelatoriosEscola/RelatorioAluno/relatorioAluno.component';
import { TestComponent } from '../pages/custom/Plataform/test/test.component';
import { TestHistoryComponent } from '../pages/custom/test-history/test-history.component';
import { TestTypeComponent } from '../pages/custom/Tests/test-type/test-type.component';
import { ThemeListComponent } from '../pages/custom/Tests/theme-list/theme-list.component';
import { ThemesComponent } from '../pages/custom/Plataform/themes/themes.component';
import { TypeComponent } from '../pages/custom/Tests/type/type.component';
import { UsersComponent } from '../pages/custom/Access/users/users.component';
import { categoriesTestComponent } from '../pages/custom/Tests/categories/categoriesTest.component';
var ɵ0 = { roles: [1] }, ɵ1 = { roles: [1] }, ɵ2 = { roles: [1, 4, 6, 8] }, ɵ3 = { roles: [1, 4, 5, 6] }, ɵ4 = { roles: [1] }, ɵ5 = { roles: [1] }, ɵ6 = { roles: [1] }, ɵ7 = { roles: [3, 7] }, ɵ8 = { roles: [3, 7] }, ɵ9 = { roles: [3] }, ɵ10 = { roles: [1, 4, 5, 6, 7] }, ɵ11 = { roles: [3, 7] }, ɵ12 = { roles: [3, 7] }, ɵ13 = { roles: [3] }, ɵ14 = { roles: [3] }, ɵ15 = { roles: [1, 4, 5, 6, 7] }, ɵ16 = { roles: [1, 3, 4, 5, 6, 7], id: 10 }, ɵ17 = { roles: [1, 3, 4, 5, 6, 7] }, ɵ18 = { roles: [1, 4, 5, 6, 8] };
var CHILD_ROUTES = [
    { path: 'categories', canActivate: [AuthGuard], data: ɵ0, component: CategoriesComponent },
    { path: 'Products', canActivate: [AuthGuard], data: ɵ1, component: ProductsComponent },
    { path: 'Users', canActivate: [AuthGuard], data: ɵ2, component: UsersComponent },
    { path: 'Companies', canActivate: [AuthGuard], data: ɵ3, component: CompaniesComponent },
    { path: 'Themes', canActivate: [AuthGuard], data: ɵ4, component: ThemesComponent },
    { path: 'Questions', canActivate: [AuthGuard], data: ɵ5, component: QuestionsComponent },
    { path: 'Tests', canActivate: [AuthGuard], data: ɵ6, component: TestComponent },
    { path: 'Taking', canActivate: [AuthGuard], data: ɵ7, component: categoriesTestComponent },
    { path: 'Taking/:id', canActivate: [AuthGuard], data: ɵ8, component: TypeComponent },
    { path: 'Requests', canActivate: [AuthGuard], data: ɵ9, component: RequestsComponent },
    { path: 'StudentRequests', canActivate: [AuthGuard], data: ɵ10, component: StudentRequestsComponent },
    { path: 'Tests/Themes/:font/:id', canActivate: [AuthGuard], data: ɵ11, component: ThemeListComponent },
    { path: 'TestType/:font/:id', canActivate: [AuthGuard], data: ɵ12, component: TestTypeComponent },
    { path: 'TestsHistory', canActivate: [AuthGuard], data: ɵ13, component: TestHistoryComponent },
    { path: 'ELearningStudent', canActivate: [AuthGuard], data: ɵ14, component: ELearningStudentContainerComponent },
    { path: 'Material', canActivate: [AuthGuard], data: ɵ15, component: MaterialComponent },
    { path: 'MaterialAC', canActivate: [AuthGuard], data: ɵ16, component: MaterialComponent },
    { path: 'Ebook', canActivate: [AuthGuard], data: ɵ17, component: EbookComponent },
    { path: 'dashboard', canActivate: [AuthGuard], component: PageDashboardComponent },
    { path: 'ConsumosEscolas', component: SchoolSheetsComponent },
    { path: 'VendasEscolas', component: SchoolSalesSheetsComponent },
    { path: 'Alunos', component: StudentSheetsComponent },
    { path: 'homePage', canActivate: [AuthGuard], data: ɵ18, component: PageAnalyticsComponent },
    { path: 'edit-account', component: PageEditAccountComponent },
    { path: 'change-password', component: ChangePasswordComponent },
];
var ERROR_ROUTES = [
    { path: '404', component: Page404Component },
    { path: '**', component: Page404Component }
];
export var ROUTES = [
    {
        path: '',
        redirectTo: 'public/sign-in/alvescosta',
        pathMatch: 'full',
        runGuardsAndResolvers: 'always',
    },
    {
        path: ':name',
        redirectTo: 'public/sign-in/:name',
        pathMatch: 'full',
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'public/sign-in',
        redirectTo: 'public/sign-in/alvescosta',
        pathMatch: 'full',
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'app',
        component: AppLayoutComponent,
        canActivate: [AuthGuard],
        children: CHILD_ROUTES
    },
    {
        path: 'public',
        component: PublicLayoutComponent,
        children: [
            { path: 'sign-in/:name', component: PageSignInComponent },
            { path: 'forgot-password', component: PageResetPasswordComponent },
            { path: 'verify-account', component: PageVerifyAccountComponent },
            { path: 'testesOnline', component: FreeComponent },
            { path: '**', component: Page404Component }
        ]
    },
];
var RoutingModule = /** @class */ (function () {
    function RoutingModule() {
    }
    return RoutingModule;
}());
export { RoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18 };
