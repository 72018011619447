import { Component, OnInit, Optional, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-dialog-box-email",
  templateUrl: "./dialog-box-email.component.html",
  styleUrls: ["./dialog-box-email.component.scss"],
})
export class DialogBoxEmailComponent implements OnInit {
  localData: any;
  emailForm: FormGroup;
  requestType = [
    {
      id: "0",
      value: "Funcionamento da Plataforma",
    },
    {
      id: "1",
      value: "Dúvidas de Testes/Matéria",
    },
  ];
  constructor(
    public dialogRef: MatDialogRef<DialogBoxEmailComponent>,
    private formBuilder: FormBuilder,
    private userService: UserService,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.localData = { ...data };
  }

  ngOnInit() {
    this.emailForm = this.formBuilder.group({
      requestType: ["", Validators.required],
      body: ["", Validators.required],
    });
  }
  get f() {
    return this.emailForm.controls;
  }

  closeDialog() {
    console.log("close");
    this.dialogRef.close({ event: "Cancel" });
  }

  onSubmit() {
    if (this.emailForm.valid) {
      this.localData.subject = "Dúvida";
      this.localData.requestType = this.f.requestType.value;
      this.localData.body = this.f.body.value;
      this.dialogRef.close({ data: this.localData, event: "Send" });
    }
  }
  
  isResponded(){
    if(this.localData.responded == true){
      return true;
    } else{
      return false;
    }
  }

  hasQuestion(){
    if(this.localData.question == undefined){
      return false;
    } else{
      return true;
    }
  }

   form = this.formBuilder.group({
     myForm: new FormControl({ value: '', disabled: true }),
     myQuestion: new FormControl({ value: '', disabled: true })
  });
}
