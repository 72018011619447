<div class="error-card" style="margin-left: -11%;">
  <div class="icon-block">
    <i class="icofont-bulb-alt">

    </i>
    <h5 class="title">{{ title }}</h5>
  </div>

  <div class="card-content">
    <div class="text-block mb-5">


      <h6 class="sub-title">{{ subTitle }}</h6>

      <p class="error-message">{{ errorMessage }}</p>
    </div>

    <a [routerLink]="'/'" [view]="'gradient'" tc-button [beforeIcon]="'icofont-ui-home'" [block]="true">
      Voltar
    </a>
  </div>
</div>