var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Category } from '../../../../models/Category';
import { MatDialogRef, MatDialog } from '@angular/material';
import { CategoryService } from '../../../../services/category.service';
import { QuestionService } from '../../../../services/question.service';
import { first } from 'rxjs/internal/operators/first';
import { DialogBoxQuestionsComponent } from '../dialog-box-questions/dialog-box-questions.component';
import { ToastrService } from 'ngx-toastr';
var DialogBoxThemesComponent = /** @class */ (function () {
    function DialogBoxThemesComponent(dialogRef, categoryService, formBuilder, questionService, dialog, toastr, data) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.categoryService = categoryService;
        this.formBuilder = formBuilder;
        this.questionService = questionService;
        this.dialog = dialog;
        this.toastr = toastr;
        this.data = data;
        this.bordered = true;
        this.position = 'bottom';
        this.listOfDisplayData = [];
        this.listOfAllData = [];
        this.list = [];
        this.sortName = null;
        this.sortValue = null;
        this.categories = [];
        this.idValue = '';
        this.descValue = '';
        this.fontValue = '';
        this.selectedCategoriesData = [];
        this.categoriesSelected = [];
        this.opened = false;
        this.localData = __assign({}, data);
        this.action = this.localData.action;
        this.categoryService.getAll().subscribe(function (categories) {
            _this.categories = categories.sort(function (prev, curr) { return curr.CategoryName > prev.CategoryName ? -1 : 1; });
            console.log(_this.categories);
        });
        if (this.action != 'Adicionar') {
            this.questionService.getQuestionsByTheme(this.localData.id).subscribe(function (c) {
                _this.listOfAllData = c;
                _this.list = c;
            });
        }
        this.categoryService.getAll().subscribe(function (a) { _this.categories = a; });
        this.categoryService.getCategoryByThemeId(this.localData.id).subscribe(function (categoriesTheme) {
            _this.selectedCategoriesData = categoriesTheme;
            _this.categories.map(function (a) {
                var found = 0;
                _this.selectedCategoriesData["name"].forEach(function (c) {
                    if (c["name"] == a["name"]) {
                        found = 1;
                    }
                });
                if (found == 1) {
                    var cat = { 'id': a["categoryId"], 'value': a["name"], 'selected': true };
                    _this.categoriesSelected.push(cat);
                }
                else {
                    var cat = { 'id': a["categoryId"], 'value': a["name"], 'selected': false };
                    _this.categoriesSelected.push(cat);
                }
            });
        });
    }
    DialogBoxThemesComponent.prototype.selectCategory = function (i) {
        this.categoriesSelected[i]["selected"] = this.categoriesSelected[i]["selected"] == true ? false : true;
    };
    DialogBoxThemesComponent.prototype.isSelected = function (categoryId) {
        var e = this.selectedCategoriesData['name'] == undefined ? -1 : this.selectedCategoriesData['name'].findIndex(function (c) { return c['categoryId'] === categoryId; });
        return e == -1 ? false : true;
    };
    Object.defineProperty(DialogBoxThemesComponent.prototype, "f", {
        get: function () { return this.themesForm.controls; },
        enumerable: true,
        configurable: true
    });
    DialogBoxThemesComponent.prototype.closeDialog = function () {
        this.dialogRef.close({ event: 'Cancel' });
    };
    DialogBoxThemesComponent.prototype.ngOnInit = function () {
        this.themesForm = this.formBuilder.group({
            name: ['', Validators.required],
            categoriesF: ['', Validators.required]
        });
    };
    DialogBoxThemesComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.action != 'Adicionar') {
            this.localData.categories = [];
            this.categoriesSelected.forEach(function (c) {
                if (c["selected"] == true) {
                    var x = Number(c["id"]);
                    _this.localData.categories.push(x);
                }
            });
            this.localData.name = this.f.name.value;
            this.dialogRef.close({ event: this.action, data: this.localData });
        }
        else {
            this.localData.categories = [];
            this.categoriesSelected.forEach(function (c) {
                if (c["selected"] == true) {
                    var x = Number(c["id"]);
                    _this.localData.categories.push(x);
                    console.log(x);
                }
            });
            this.localData.name = this.f.name.value;
            this.dialogRef.close({ event: this.action, data: this.localData });
        }
    };
    DialogBoxThemesComponent.prototype.currentPageDataChange = function ($event) {
        this.listOfDisplayData = $event;
    };
    DialogBoxThemesComponent.prototype.sort = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.listOfAllData = this.list;
        this.listOfDisplayData = this.list;
        this.search('');
    };
    DialogBoxThemesComponent.prototype.reset = function () {
        this.idValue = '';
        this.descValue = '';
        this.fontValue = '';
        this.listOfAllData = this.list;
        this.search('');
    };
    DialogBoxThemesComponent.prototype.select = function (event) {
        if (event.path[1].className.match('active')) {
            event.path[1].classList.remove('active');
        }
        else {
            event.path[1].classList.add('active');
        }
    };
    DialogBoxThemesComponent.prototype.search = function (type) {
        var _this = this;
        var filterFunc = function (item) {
            var x = '' + item['questionId'];
            var y = item['description'];
            var z = item['font'];
            return (type == 'id' ? x.indexOf(_this.idValue) !== -1 : type == 'desc' ? y.indexOf(_this.descValue) !== -1 : z.indexOf(_this.fontValue) !== -1);
        };
        var data = this.listOfAllData.filter(function (item) { return filterFunc(item); });
        this.listOfAllData = data.sort(function (a, b) {
            return _this.sortValue === 'ascend'
                ? a[_this.sortName] > b[_this.sortName]
                    ? 1
                    : -1
                : b[_this.sortName] > a[_this.sortName]
                    ? 1
                    : -1;
        });
    };
    DialogBoxThemesComponent.prototype.openDialog = function (action, e) {
        var _this = this;
        if (e == null || !e.target.className.match('ant-table-column-sorters') && !e.target.className.match('ant-spin-container')) {
            var row = {};
            row = e == null ? {} : this.listOfAllData[this.listOfAllData.findIndex(function (d) { return d['description'].trim() === e.path[1].children[1].innerText.trim(); })];
            row.action = action;
            var dialogRef = this.dialog.open(DialogBoxQuestionsComponent, {
                width: '99%',
                maxWidth: '850px',
                height: 'auto',
                data: row
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result === undefined) { }
                else if (result.event == 'Adicionar') {
                    _this.addRowData(result.data);
                }
                else if (result.event == 'Modificar') {
                    _this.updateRowData(result.data);
                }
            });
        }
    };
    DialogBoxThemesComponent.prototype.addRowData = function (rowObj) {
        var _this = this;
        this.questionService.createQuestion(rowObj.description, rowObj.help, rowObj.questionOption0, rowObj.questionOption1, rowObj.questionOption2, rowObj.questionOption3, rowObj.isAnswer, rowObj.active, rowObj.font, rowObj.themeId).subscribe(function (data) {
            _this.questionService.getAll().pipe(first()).subscribe(function (questions) {
                _this.listOfAllData = questions;
                _this.list = questions;
            });
            _this.toastr.success('Questão criada com sucesso!');
        }, function (err) {
            _this.toastr.error('Algo ocorreu mal!');
        });
    };
    DialogBoxThemesComponent.prototype.updateRowData = function (rowObj) {
        var _this = this;
        this.questionService.updateQuestion(rowObj.questionId, rowObj.description, rowObj.help, rowObj.questionOption0, rowObj.questionOption1, rowObj.questionOption2, rowObj.questionOption3, rowObj.isAnswer, rowObj.active, rowObj.font, rowObj.themeId).subscribe(function (data) {
            _this.questionService.getAll().pipe(first()).subscribe(function (questions) {
                _this.listOfAllData = questions;
                _this.list = questions;
            });
            _this.toastr.success('Questão modificada com sucesso!');
        }, function (err) {
            _this.toastr.error('Algo ocorreu mal!');
        });
    };
    DialogBoxThemesComponent.prototype.openFilter = function () {
        var el = document.getElementById('filtro');
        if (this.opened == false) {
            el.classList.add('show');
            el.classList.remove('hide');
            this.opened = true;
        }
        else {
            el.classList.add('hide');
            el.classList.remove('show');
            this.opened = false;
        }
    };
    DialogBoxThemesComponent.prototype.filter = function () {
        this.listOfAllData = this.list;
        if (this.idValue != '') {
            this.search('id');
        }
        if (this.descValue != '') {
            this.search('desc');
        }
        if (this.fontValue != '') {
            this.search('font');
        }
    };
    DialogBoxThemesComponent.prototype.openCity = function (evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("active", "");
        }
        document.getElementById(cityName).style.display = "block";
        document.getElementById(cityName).classList.add("active");
    };
    return DialogBoxThemesComponent;
}());
export { DialogBoxThemesComponent };
