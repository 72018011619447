import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';
import { BasePageComponent } from '../../../base-page';
import { UserService } from '../../../../services/user.service';
import { User } from '../../../../models/user';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/internal/operators/first';

@Component({
  selector: 'page-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends BasePageComponent implements OnInit, OnDestroy {
  passwordForm: FormGroup;
  changes: boolean;
  currentUser: User;
  constructor(store: Store<IAppState>,
    httpSv: HttpService,
    private formBuilder: FormBuilder, private toastr: ToastrService,
    private authenticationService: AuthService, private userService: UserService
  ) {
    super(store, httpSv);
    this.changes = false;
    this.initPasswordForm();
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.pageData = {
      title: 'Mudar Senha',
      loaded: true
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.setLoaded();

  }

  initPasswordForm() {
    this.passwordForm = this.formBuilder.group({
      current: ['', Validators.required],
      new: ['', Validators.required],
      again: ['', Validators.required,],

    });
    // detect form changes
    this.passwordForm.valueChanges.subscribe(() => {
      this.changes = true;
    });
  }
  savePasswordData(form: FormGroup) {
    if (form.valid) {
      this.changes = false;

      this.userService.updatePassword(form.value['new'], this.currentUser.user.id).pipe(first()).subscribe(data => {
        this.toastr.success("Password Alterada com sucesso");
        this.passwordForm.reset();
      });
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
