/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./analytics.component";
import * as i2 from "@ngrx/store";
import * as i3 from "../../../services/http/http.service";
var styles_PageAnalyticsComponent = [];
var RenderType_PageAnalyticsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageAnalyticsComponent, data: {} });
export { RenderType_PageAnalyticsComponent as RenderType_PageAnalyticsComponent };
export function View_PageAnalyticsComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_PageAnalyticsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "page-analytics", [], null, null, null, View_PageAnalyticsComponent_0, RenderType_PageAnalyticsComponent)), i0.ɵdid(1, 245760, null, 0, i1.PageAnalyticsComponent, [i2.Store, i3.HttpService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageAnalyticsComponentNgFactory = i0.ɵccf("page-analytics", i1.PageAnalyticsComponent, View_PageAnalyticsComponent_Host_0, {}, { messageEvent: "messageEvent" }, []);
export { PageAnalyticsComponentNgFactory as PageAnalyticsComponentNgFactory };
