import { AuthService } from './services/auth.service';
import { CompanyService } from './services/company.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from './services/session.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  title = 'AlvesCosta';
  currentUser;
  constructor(private companyService: CompanyService, private authenticationService: AuthService, private sessionService: SessionService,
    private router: Router) {
    authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }
}