<div class="app-container" [ngClass]="{ 'boxed': appSettings.boxed }" *ngIf="appSettings">
  <!-- App navbar -->
  <navbar [boxed]="appSettings.boxed" [tcBgColor]="appSettings.topbarBg" [tcColor]="appSettings.topbarColor"
    [minHeight]="'6rem'" class="top-bar">

    <button class="navbar-toggle d-lg-none" (click)="toggleSidebar(true)">
      <span></span>
      <span></span>
      <span></span>
    </button>

    <img [src]="imageUrl || ''" class="d-none d-lg-block p-0" (click)="btnLogoClick();" style="max-height: 60px;">


    <actions [layout]="'app'" [actualize]="actualize()"></actions>

    <navbar-skeleton [type]="'app'" [loaded]="loaded"></navbar-skeleton>
  </navbar>
  <!-- end app navbar -->

  <!-- app navbar 2 -->
  <navbar [orientation]="'app-vertical'" [boxed]="appSettings.boxed" [ngStyle]="getNavbarStyles()"
    [opened]="appSettings.sidebarOpened" [minHeight]="'55px'" [ngClass]="{ 'loading': !loaded }">

    <button class="no-style navbar-close icofont-close-line d-lg-none" [tcColor]="appSettings.topbarColor"
      (click)="toggleSidebar(false)"></button>

    <div class="custom0 d-lg-none">
      <img [src]="imageUrl || ''" class="d-lg-none" (click)="btnLogoClick();" style="max-height: 55px; padding: 5px;">
    </div>

    <app-menu [orientation]="'app'"></app-menu>

  </navbar>

  <main class="main-content" [ngClass]="{ 'loaded': pageData?.loaded }">
    <div class="page-loader" *ngIf="!pageData?.loaded"><i class="icofont-spinner-alt-4 rotate"></i></div>

    <div class="main-content-wrap" [ngClass]="{ 'full-filled': pageData?.fullFilled }">
      <header class="page-header" *ngIf="pageData && !pageData?.fullFilled && pageData.title.length">
        <div class="left" style="width:100%">
          <ul class="page-breadcrumbs" *ngIf="pageData && pageData.breadcrumbs">
            <li class="item" *ngFor="let item of pageData.breadcrumbs; let last = last">
              <a class="link" [ngClass]="{ 'last': last }" routerLink="{{ item.route }}" *ngIf="item.route">
                {{ item.title }}
              </a>

              <span class="link last" *ngIf="!item.route">{{ item.title }}</span>

              <span class="separator" *ngIf="!last">|</span>
            </li>
          </ul>

          <div style="width:100%">
            <div style="float: left">
              <h1 class="page-title">{{ pageData.title }}</h1>
            </div>
            <div style="float: right" class="mb-1" *ngIf="pageData.title == 'Ensino à Distância'">
              <button tc-button type="button" (click)="openHelp()"  [beforeIcon]="'icofont-question-circle'">Ajuda</button>
            </div>
          </div>
          
        </div>
      </header>

      <router-outlet></router-outlet>
    </div>
  </main>

  <footer [boxed]="appSettings.boxed" [pageData]="pageData" [loaded]="loaded"></footer>
</div>