import { Component, OnInit, Optional, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-box-zoom',
  templateUrl: './dialog-box-zoom.component.html',
  styleUrls: ['./dialog-box-zoom.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class DialogBoxZoomComponent implements OnInit {

  localData: any ;
  imagePath : string;
  constructor( public dialogRef: MatDialogRef<DialogBoxZoomComponent>, 
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.localData = {...data};
    this.imagePath = this.localData.src;
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
    this.dialogRef._containerInstance = null;
  }
}
