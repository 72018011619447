var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { BasePageComponent } from '../../base-page';
import { CategoryService } from '../../../services/category.service';
import { HttpService } from '../../../services/http/http.service';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { ShowTestComponent } from '../show-test/show-test.component';
import { ShowcamTestComponent } from '../showCamTest/showCamTest.component';
import { Store } from '@ngrx/store';
import { TestService } from '../../../services/test.service';
import { Utils } from 'src/utils/utils';
import { environment } from '../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';
var TestHistoryComponent = /** @class */ (function (_super) {
    __extends(TestHistoryComponent, _super);
    function TestHistoryComponent(store, dialog, testService, categoryService, authenticationService, httpSv, location, utils) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.dialog = dialog;
        _this.testService = testService;
        _this.categoryService = categoryService;
        _this.authenticationService = authenticationService;
        _this.location = location;
        _this.utils = utils;
        _this.bordered = true;
        _this.position = 'both';
        _this.currentPage = 0;
        _this.pageSize = 10;
        _this.searchValue = '';
        _this.listOfDisplayData = [];
        _this.listOfAllData = [];
        _this.sortName = null;
        _this.sortValue = null;
        _this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        _this.getData(environment.apiUrl + "/category", 'tabledata', 'setLoaded');
        _this.testService.getTestsByUserId(_this.currentUser.user.id).pipe(first()).subscribe(function (data) {
            _this.listOfAllData = data;
        });
        _this.pageData = {
            title: 'Histórico de testes',
            loaded: true
        };
        return _this;
    }
    TestHistoryComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    TestHistoryComponent.prototype.openDialog = function (e, i) {
        var _this = this;
        this.testService.getTypeByTestId(this.listOfAllData[this.currentPage * this.pageSize + i].testId).subscribe(function (x) {
            _this.isCam = x['type'] == 'CAM' || x['type'] == 'ADR' || x['type'] == 'Taxis';
            console.log(x['type']);
            console.log(_this.isCam);
            var row = {};
            var deviceWidth = _this.utils.isMobileDevice() ? '99vw' : '90vw';
            var deviceMaxWidth = _this.utils.isMobileDevice() ? '99vw' : '90vw';
            var deviceMaxHeight = _this.utils.isMobileDevice() ? '90vh' : '90vh';
            row.testId = _this.listOfAllData[_this.currentPage * _this.pageSize + i].testId;
            row.dashboard = false;
            row.origin = 'history';
            row.categoryId = x['categoryId'];
            if (_this.isCam == true) {
                var dialogRef = _this.dialog.open(ShowcamTestComponent, {
                    id: 'camWizard',
                    width: deviceWidth,
                    maxWidth: deviceMaxWidth,
                    height: 'auto',
                    maxHeight: deviceMaxHeight,
                    disableClose: true,
                    closeOnNavigation: true,
                    panelClass: 'custom-modalbox',
                    data: row
                });
            }
            else {
                console.log(row);
                var dialogRef = _this.dialog.open(ShowTestComponent, {
                    id: 'wizard',
                    width: deviceWidth,
                    maxWidth: deviceMaxWidth,
                    height: 'auto',
                    maxHeight: deviceMaxHeight,
                    disableClose: true,
                    closeOnNavigation: true,
                    panelClass: 'custom-modalbox',
                    data: row
                });
            }
        });
    };
    TestHistoryComponent.prototype.custom = function (i) {
        this.currentPage = i - 1;
        console.log(this.currentPage);
    };
    TestHistoryComponent.prototype.currentPageDataChange = function ($event) {
        // console.log($event);
        this.listOfDisplayData = $event;
    };
    TestHistoryComponent.prototype.sort = function (sort) {
        this.sortName = sort.key;
        this.sortValue = sort.value;
        this.search();
    };
    TestHistoryComponent.prototype.reset = function () {
        this.searchValue = '';
        this.search();
    };
    TestHistoryComponent.prototype.select = function (event) {
        if (event.path[1].className.match('active')) {
            event.path[1].classList.remove('active');
        }
        else {
            event.path[1].classList.add('active');
        }
    };
    TestHistoryComponent.prototype.search = function () {
        var _this = this;
        //  const data = this.listOfDisplayData.filter((item) => filterFunc(item));
        /** sort data **/
        if (this.sortName && this.sortValue) {
            this.listOfDisplayData = this.listOfDisplayData.sort(function (a, b) {
                return _this.sortValue === 'ascend'
                    ? a[_this.sortName] > b[_this.sortName]
                        ? 1
                        : -1
                    : b[_this.sortName] > a[_this.sortName]
                        ? 1
                        : -1;
            });
        }
        else {
            this.listOfDisplayData = this.listOfDisplayData;
        }
    };
    return TestHistoryComponent;
}(BasePageComponent));
export { TestHistoryComponent };
