var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AuthService } from '../../../services/auth.service';
import { TestService } from '../../../services/test.service';
import { first } from 'rxjs/internal/operators/first';
import { FormControl } from '@angular/forms';
import { DialogBoxZoomComponent } from '../DialogBoxes/dialog-box-zoom/dialog-box-zoom.component';
import { BlockTemplateComponent } from '../block-template/block-template.component';
import { Subject } from 'rxjs/internal/Subject';
import { DomSanitizer } from '@angular/platform-browser';
import { QuestionHelpComponent } from '../DialogBoxes/questionHelp/questionHelp.component';
import { Utils } from 'src/utils/utils';
var ShowTestComponent = /** @class */ (function () {
    function ShowTestComponent(dialog, testService, sanitizer, dialogRef, authenticationService, data, utils, cd, elRef) {
        var _this = this;
        this.dialog = dialog;
        this.testService = testService;
        this.sanitizer = sanitizer;
        this.dialogRef = dialogRef;
        this.authenticationService = authenticationService;
        this.data = data;
        this.utils = utils;
        this.cd = cd;
        this.elRef = elRef;
        this.questions = [];
        this.selectedTab = 0;
        this.selected = new FormControl(0);
        this.selectedThemes = [];
        this.blockTemplate = BlockTemplateComponent;
        this.dashboard = true;
        this.nif = '';
        this.destroyed = new Subject();
        this.SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };
        this.localData = __assign({}, data);
        console.log(this.localData);
        this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        var va = null;
        if (this.localData.name) {
            va = this.localData.name.split(' ');
        }
        else {
            va = this.currentUser.user.name.split(' ');
        }
        if (this.localData.nif) {
            this.nif = this.localData.nif;
        }
        else {
            if (this.currentUser.user.nif)
                this.nif = this.currentUser.user.nif.toString();
            else
                this.nif = '';
        }
        if (this.localData.name != undefined && this.localData.name != null) {
            this.dashboard = false;
        }
        if (this.localData.dashboard == true || this.dashboard == true) {
            if (va.length > 1) {
                this.currentName = va[0] + ' ' + va[va.length - 1];
            }
            else
                this.currentName = va[0];
        }
        else {
            if (va.length > 1) {
                this.currentName = va[0] + ' ' + va[va.length - 1];
            }
            else
                this.currentName = va[0];
        }
        this.testService.getQuestionsByTestId(this.localData.testId).pipe(first()).subscribe(function (questions) {
            _this.questions = questions;
            _this.numberOfQuestions = questions.length;
            var tabList = document.getElementsByClassName('mat-tab-list')[0];
            if (_this.localData.dashboard === true) {
                var index = _this.questions.findIndex(function (d) { return d['questionId'] === _this.localData.questionId; });
                _this.selected.setValue(index);
                _this.selectedIndex = index;
            }
        });
    }
    ShowTestComponent.prototype.ngOnInit = function () {
    };
    //@HostListener('mouseover')
    ShowTestComponent.prototype.refreshTabs = function () {
        var _this = this;
        var i = 0;
        this.questions.forEach(function (element) {
            var tabGroupIndex = _this.elRef.nativeElement.querySelectorAll('.Dummy .mat-tab-body')[0].id.split('-')[3];
            //console.log("mat-tab-label-" + tabGroupIndex + "-" + i);
            var tab = _this.elRef.nativeElement.querySelectorAll("#mat-tab-label-" + tabGroupIndex + "-" + i)[0];
            //console.log(tab);
            if (element.answerSelected == undefined || element.answerSelected == null) {
                element.answerSelected = -1;
                tab.classList.add('wrongTab');
            }
            else {
                if (element.answerSelected == element['answerCorrect'])
                    tab.classList.add('correctTab');
                else
                    tab.classList.add('wrongTab');
            }
            i++;
        });
    };
    ShowTestComponent.prototype.indexChange = function (event) {
        //console.log("indexChange");
        //console.log(event);
        this.selected.setValue(event);
    };
    ShowTestComponent.prototype.selectTab = function (id) {
        //console.log('selected');
        this.refreshTabs();
    };
    ShowTestComponent.prototype.zoom = function () {
        var el = document.getElementById("imagem");
        var row = {};
        row.src = el.getAttribute("src");
        var dialogRef = this.dialog.open(DialogBoxZoomComponent, {
            id: "zoom",
            data: row,
            width: 'auto',
            height: 'auto',
            maxHeight: "97vh",
            maxWidth: "97vw"
        });
    };
    ShowTestComponent.prototype.close = function () {
        this.dialogRef.close({ event: 'Cancel' });
    };
    ShowTestComponent.prototype.sanitizeUrl = function (img) {
        var i = 'data:image/jpg;base64,' + img;
        return this.sanitizer.bypassSecurityTrustResourceUrl(i);
    };
    ShowTestComponent.prototype.swipe = function (selectedIndex, action) {
        if (action === void 0) { action = this.SWIPE_ACTION.RIGHT; }
        console.log("swipe");
        console.log("number", this.selected.value);
        console.log(action);
        console.log(this.questions.length - 1);
        // Out of range
        if (this.selected.value < 0 || this.selected.value > (this.questions.length - 1))
            return;
        // Swipe left, next tab
        if (action === this.SWIPE_ACTION.LEFT) {
            var isLast = this.selected.value === (this.questions.length - 1);
            console.log("isLast", isLast);
            this.selected.setValue(isLast ? 0 : this.selected.value + 1);
            console.log("Swipe right - INDEX: " + this.selected.value);
        }
        // Swipe right, previous tab
        if (action === this.SWIPE_ACTION.RIGHT) {
            var isFirst = this.selected.value === 0;
            console.log("isFirst", isFirst);
            this.selected.setValue(isFirst ? 1 : this.selected.value - 1);
            console.log("Swipe left - INDEX: " + this.selected.value);
        }
    };
    ShowTestComponent.prototype.openHelp = function () {
        var width = this.utils.isMobileDevice() ? "90vw" : "30vw";
        var maxWidth = this.utils.isMobileDevice() ? "99vw" : "30vw";
        var dialogRef = this.dialog.open(QuestionHelpComponent, {
            data: this.questions[this.selected.value]["help"],
            width: width,
            height: 'auto',
            disableClose: false,
            closeOnNavigation: true,
            maxWidth: maxWidth,
            maxHeight: "80vh",
        });
    };
    return ShowTestComponent;
}());
export { ShowTestComponent };
