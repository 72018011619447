import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TestDefinition } from '../models/TestDefinition';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TestDefinitionService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<TestDefinition[]>(`${environment.apiUrl}/testDefinition/`);
  }
  createTestDefinition(CategoryName: string, TestTypeName: string, duration: number, numberOfQuestions: number, wrongQuestions: number) {
    return this.http.post<any>(`${environment.apiUrl}/testDefinition/`, {
      CategoryName, TestTypeName, duration, numberOfQuestions, wrongQuestions
    });
  }
  updateTestDefinition(testDefinitionId: number, CategoryName: string, TestTypeName: string, duration: number, numberOfQuestions: number, wrongQuestions: number) {
    return this.http.put<any>(`${environment.apiUrl}/testDefinition/${testDefinitionId}`, {
      CategoryName, TestTypeName, duration, numberOfQuestions, wrongQuestions
    }
    );
  }

  deleteTestDefinition(id: number) {
    return this.http.delete<TestDefinition>(`${environment.apiUrl}/testDefinition/${id}`);
  }

  getTestDefinitionByCategoryId(id: number, name: string) {
    return this.http.get<any>(`${environment.apiUrl}/testDefinition/byCategoryId/${id}/${name}`);
  }
  getTestDefinitionById(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/testDefinition/${id}`);
  }
}
