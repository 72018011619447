import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ELearningService = /** @class */ (function () {
    function ELearningService(http) {
        this.http = http;
    }
    ELearningService.prototype.getReport = function (list) {
        return this.http.post(environment.apiUrl + "/elearning/report", list);
    };
    ELearningService.prototype.getELearningResume = function (id, categoryId) {
        return this.http.get(environment.apiUrl + "/elearning/resume/" + id + "/" + categoryId);
    };
    ELearningService.prototype.createTestSession = function (userElearningLessonId, testId) {
        return this.http.post(environment.apiUrl + "/elearning/elearningTestLessonSession/", { userElearningLessonId: userElearningLessonId, testId: testId });
    };
    ELearningService.prototype.createSession = function (userElearningLessonId, StartDt, EndDt) {
        return this.http.post(environment.apiUrl + "/elearning/elearningLessonSession/", { userElearningLessonId: userElearningLessonId, StartDt: StartDt, EndDt: EndDt });
    };
    ELearningService.prototype.updateSession = function (elearningLessonSessionId, EndDt) {
        return this.http.put(environment.apiUrl + "/elearning/elearningLessonSession/" + elearningLessonSessionId, EndDt);
    };
    ELearningService.prototype.toggleNodeLesson = function (userELearningLessonId) {
        return this.http.put(environment.apiUrl + "/elearning/treeNodeToggle/" + userELearningLessonId, null);
    };
    ELearningService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ELearningService_Factory() { return new ELearningService(i0.ɵɵinject(i1.HttpClient)); }, token: ELearningService, providedIn: "root" });
    return ELearningService;
}());
export { ELearningService };
