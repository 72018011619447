import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { BasePageComponent } from '../../../../pages/base-page';
import { CamWizardComponent } from '../camWizard/camWizard.component';
import { CategoryService } from '../../../../services/category.service';
import { CategoryThemeService } from '../../../../services/category-theme.service';
import { HttpService } from '../../../../services/http/http.service';
import { IAppState } from '../../../../interfaces/app-state';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { TestDefinitionService } from 'src/app/services/test-definition.service';
import { Theme } from '../../../../models/Theme';
import { Utils } from 'src/utils/utils';
import { WizardComponent } from '../wizard/wizard.component';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';
import { zh_CN } from 'ng-zorro-antd';

@Component({
  selector: 'app-theme-list',
  templateUrl: './theme-list.component.html',
  styleUrls: ['./theme-list.component.css']
})
export class ThemeListComponent extends BasePageComponent implements OnInit, OnDestroy {
  bodyTag: HTMLBodyElement = document.getElementsByTagName('body')[0];
  htmlTag: HTMLElement = document.getElementsByTagName('html')[0];
  categoryId: number;
  activeElement: number;
  themes: any[];
  theme: number;
  isCam: boolean;
  data = {};
  font: string;
  count: number = 0;
  numberOfQuestions: number = 30;

  @Input() selectedThemes: number[] = [];
  constructor(private route: ActivatedRoute, public dialog: MatDialog, private categoryService: CategoryService,
    private themeProductService: CategoryThemeService, private location: Location,
    private testDefinitionService: TestDefinitionService,
    store: Store<IAppState>, httpSv: HttpService,
    public utils: Utils) {
    super(store, httpSv);
    this.categoryId = this.route.snapshot.params['id'];
    this.font = this.route.snapshot.params['font'];


    this.testDefinitionService
    .getTestDefinitionByCategoryId(this.categoryId, 'Tematico')
    .subscribe((definition) => {
      //console.log(definition);
      this.numberOfQuestions = definition.numberOfQuestions;
    });

    this.themeProductService.getNewThemesProductById(this.categoryId, this.font).pipe(first()).subscribe(themeProduct => {
      console.log(themeProduct);
      this.themes = themeProduct;
    });
    this.getData(`${environment.apiUrl}/Product/`, 'tableData', 'setLoaded');

    this.categoryService.isCam(this.categoryId).subscribe(camCheck => {
      this.isCam = camCheck;
    });

    this.pageData = {
      title: 'Realizar teste',
      loaded: true
    };
  }

  select(e, id) {
    const el = document.getElementById(id);
    if (el.className.match('active')) {
      el.classList.remove('active');
      this.count--;
    }
    else {
      el.classList.add('active');
      this.count++;
    }
  }

  beginTest() {
    const el = document.querySelectorAll('li.form-control.active');
    const deviceWidth = this.isMobileDevice() == true ? '99vw' : '90vw';
    const deviceMaxWidth = this.isMobileDevice() == true ? '99vw' : '90vw';
    const deviceMaxHeight  = this.utils.isMobileDevice() ? '90vh' : '90vh';

    this.selectedThemes = [];

    el.forEach(elem => {
      this.selectedThemes.push(Number(elem.getAttribute('id')));
    });

    if (this.selectedThemes.length != 0 && this.canStart()) {
      this.data = { 'type': 'Tematico', 'id': this.categoryId, 'themes': this.selectedThemes, 'font': this.font, 'origin' : 'test' };
      if (this.isCam == true) {
        const dialogRef = this.dialog.open(CamWizardComponent, {
          id: 'camWizard',
          width: deviceWidth,
          maxWidth: deviceMaxWidth,
          height: 'auto',
          maxHeight: deviceMaxHeight,
          disableClose: true,
          closeOnNavigation: true,
          data: this.data,
          panelClass: 'custom-modalbox'
        });
      } else {
        const dialogRef = this.dialog.open(WizardComponent, {
          id: 'wizard',
          width: deviceWidth,
          maxWidth: deviceMaxWidth,
          height: 'auto',
          maxHeight: deviceMaxHeight,
          disableClose: true,
          closeOnNavigation: true,
          data: this.data,
          panelClass: 'custom-modalbox'
        });
      }
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  back() {
    this.location.back();
  }

  isMobileDevice(): boolean {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  }

  canStart(): boolean {
    var sum = 0;

    const el = document.querySelectorAll('li.form-control.active');

    console.log(this.themes);

    el.forEach(elem => {

      const id = Number(elem.getAttribute('id'));
      const theme = this.themes.find(x=> x.themeId == id)
    
      console.log(theme);

      sum = sum + theme.count;
    });

    console.log(sum);


    return sum > this.numberOfQuestions;


  }

}
