<div style="text-align: center;" *ngIf="!localData.hasTicket">
    <h5>Não tem senhas disponíveis, por favor contacte a sua escola.</h5>
        <div style="display: flex; justify-content: space-evenly; align-items: center;">
            <tc-checkbox *ngIf="currentUser.user.agreedTerms == false" [value]="isSelected" (click)="changeValue()" [style.color]="isSelected == true ? 'black':'red'">
                Li e aceito os <a (click)="open()" [style.color]="isSelected == true ? 'blue':'red'">Termos e Condições</a>
            </tc-checkbox>
            <button tc-button style="margin-left: 30%;" (click)="activate()" [beforeIcon]="'icofont-diskette'" [view]="'error'">Entrar</button>
        </div>
</div>
<div class="modal-content" *ngIf="localData.hasTicket">
    <h5 style="text-align: center;">Bem-vindo, para começar a usufruir da plataforma active as suas senhas.</h5>
    <div style="display: flex; justify-content: space-evenly; align-items: center;">
        <div style="text-align: left;">
            <li *ngFor="let item of localData.tickets; let i=index;">
                <label *ngIf="item.categoryName != null || item.categoryName != ''">{{item.fullName}}</label>
                <label *ngIf="item.materialDescription != null || item.materialDescription != ''">{{item.materialDescription}}</label>
                <tc-checkbox *ngIf="item.activateDate == null" [value]="true" (click)="changeTicket(i)"></tc-checkbox>
                <label *ngIf="item.activateDate != null"><strong>(Senha Ativa)</strong> </label>
            </li>
        </div>
    </div>
    <div style="padding-top: 5%;">
        <div style="display: flex; justify-content: space-evenly; align-items: center;">
            <tc-checkbox *ngIf="currentUser.user.agreedTerms == false" [value]="isSelected" [style.color]="isSelected == true ? 'black':'red'">Li e aceito os <a (click)="open()" [style.color]="isSelected == true ? 'blue':'red'">Termos e Condições</a>
            </tc-checkbox>
            <button tc-button (click)="activate()" class="button" [disabled]="isSelected == false" [beforeIcon]="'icofont-diskette'" [view]="'success'">Ativar</button>
            <button [beforeIcon]="'icofont-close-circled'" tc-button (click)="out()" [view]="'error'">Sair</button>
        </div>
    </div>

</div>