var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { TestTypeService } from '../../../../services/test-type.service';
import { CategoryService } from '../../../../services/category.service';
var DialogBoxTestComponent = /** @class */ (function () {
    function DialogBoxTestComponent(dialogRef, formBuilder, testTypeService, categoryService, 
    // @Optional() is used to prevent error if no data is passed
    data) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.formBuilder = formBuilder;
        this.testTypeService = testTypeService;
        this.categoryService = categoryService;
        this.data = data;
        this.loading = false;
        this.submitted = false;
        this.localData = __assign({}, data);
        this.action = this.localData.action;
        this.testTypeService.getAll().subscribe(function (test) {
            _this.testType = test;
        });
        this.categoryService.getAll().subscribe(function (categories) {
            _this.categories = categories;
        });
    }
    Object.defineProperty(DialogBoxTestComponent.prototype, "f", {
        get: function () { return this.testDefinitionForm.controls; },
        enumerable: true,
        configurable: true
    });
    DialogBoxTestComponent.prototype.closeDialog = function () {
        this.dialogRef.close({ event: 'Cancel' });
    };
    DialogBoxTestComponent.prototype.ngOnInit = function () {
        this.testDefinitionForm = this.formBuilder.group({
            categories: ['', Validators.required],
            duration: ['', Validators.required],
            numberOfQuestions: ['', Validators.required],
            wrongQuestions: ['', Validators.required],
            testType: ['', Validators.required]
        });
        this.testDefinitionForm.get('categories').setValue(this.localData.categoryName);
        this.testDefinitionForm.get('testType').setValue(this.localData.testTypeName);
    };
    DialogBoxTestComponent.prototype.onSubmit = function () {
        this.localData.CategoryName = this.f.categories.value;
        this.localData.TestTypeName = this.f.testType.value;
        this.localData.numberOfQuestions = this.f.numberOfQuestions.value;
        this.localData.duration = this.f.duration.value;
        this.localData.wrongQuestions = this.f.wrongQuestions.value;
        this.dialogRef.close({ event: this.action, data: this.localData });
    };
    return DialogBoxTestComponent;
}());
export { DialogBoxTestComponent };
