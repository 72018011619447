import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TestTypeService = /** @class */ (function () {
    function TestTypeService(http) {
        this.http = http;
    }
    TestTypeService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl + "/testType/");
    };
    TestTypeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TestTypeService_Factory() { return new TestTypeService(i0.ɵɵinject(i1.HttpClient)); }, token: TestTypeService, providedIn: "root" });
    return TestTypeService;
}());
export { TestTypeService };
