import { Component } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Utils } from "src/utils/utils";
import { FixedTestComponent } from "../FixedTest/fixedTest.component";


@Component({
    selector: 'app-free',
    templateUrl: './free.component.html',
    styleUrls: ['./free.component.css'],
  })
export class FreeComponent {

    constructor( public dialog: MatDialog, public utils: Utils){
      this.start();
    }


    start(){
      const deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
      const deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
      const deviceMaxHeight  = this.utils.isMobileDevice() ? '90vh' : '90vh';


      console.log("abrir teste fixo");

      const dialogRef = this.dialog.open(FixedTestComponent, {
        id: 'fixedWizard',
        width: deviceWidth,
        maxWidth: deviceMaxWidth,
        height: 'auto',
        maxHeight: deviceMaxHeight,
        disableClose: true,
        closeOnNavigation: true,
        panelClass: 'custom-modalbox'
      });
    }
  }