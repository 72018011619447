import {
  Component,
  OnInit,
  Input,
  Optional,
  Inject,
  OnDestroy,
  HostListener,
} from "@angular/core";
import { QuestionService } from "../../../../services/question.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Question } from "../../../../models/question";
import { FormControl } from "@angular/forms";
import { DialogBoxTestResultComponent } from "../../DialogBoxes/dialog-box-test-result/dialog-box-test-result.component";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { User } from "../../../../models/user";
import { AuthService } from "../../../../services/auth.service";
import { BlockTemplateComponent } from "../../block-template/block-template.component";
import { BlockUI } from "ng-block-ui";
import { first } from "rxjs/internal/operators/first";
import { TestDefinitionService } from "../../../../services/test-definition.service";
import { DialogBoxZoomComponent } from "../../DialogBoxes/dialog-box-zoom/dialog-box-zoom.component";
import { ConfirmTerminateComponent } from "../../DialogBoxes/confirm-terminate/confirm-terminate.component";
import { QuestionHelpComponent } from "../../DialogBoxes/questionHelp/questionHelp.component";
import { Utils } from "src/utils/utils";
import { formatDate } from "@fullcalendar/core";

@Component({
  selector: "app-wizard",
  templateUrl: "./wizard.component.html",
  styleUrls: ["./wizard.component.css"],
})
export class WizardComponent implements OnInit, OnDestroy {
  @Input() selectedIndex: number | null;
  currentUser: User;
  minutesLeft: number;
  isFinished = false;
  action: string;

  swipeCoord: any;
  swipeTime: any;

  selectedTab: number = 0;
  localData: any;
  profile: any[];
  interval;
  category;
  selected = new FormControl(0);
  selectedThemes: number[] = [];
  questions: Question[];
  numberOfQuestions: number;
  @BlockUI() blockUI;
  blockTemplate = BlockTemplateComponent;
  startDt: string;
  definitionId: number;
  currentName: string;
  onPage: boolean;

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<WizardComponent>,
    private testDefinitionService: TestDefinitionService,
    private router: Router,
    private questionService: QuestionService,
    private authenticationService: AuthService,
    // private testService: TestService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public utils: Utils
  ) {
    this.blockUI.start("A preparar o seu Teste...");
    setTimeout(() => {
      this.blockUI.stop();
    }, 2500);
    this.localData = { ...data };

    console.log(this.localData);


    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    const va = this.currentUser.user.name.split(" ");
    if (va.length > 1) {
      this.currentName = va[0] + " " + va[va.length - 1];
    } else this.currentName = va[0];
    if (this.localData.type == "Exame") {
      this.testDefinitionService
        .getTestDefinitionByCategoryId(this.localData.id, this.localData.type)
        .subscribe((definition) => {
          this.numberOfQuestions = definition.numberOfQuestions;
          this.minutesLeft = definition.duration;
          this.definitionId = definition.testDefinitionId;
          const tabList = document.getElementsByClassName("mat-tab-list")[0];
          if (
            definition.numberOfQuestions > 15 &&
            definition.numberOfQuestions <= 21
          ) {
            tabList.classList.add("c1");
          } else if (definition.numberOfQuestions > 21) {
            tabList.classList.add("c");
          }
          this.questionService
            .getQuestionsByCategoryId(
              this.localData.id,
              this.localData.font,
              this.numberOfQuestions
            )
            .subscribe((questions) => {
              this.questions = questions;
            });
        });
    } else {
      this.localData.themes.forEach((w) => {
        this.selectedThemes.push(Number(w));
      });
      this.testDefinitionService
        .getTestDefinitionByCategoryId(this.localData.id, this.localData.type)
        .subscribe((definition) => {
          this.numberOfQuestions = definition.numberOfQuestions;
          this.minutesLeft = definition.duration;
          this.definitionId = definition.testDefinitionId;
          const tabList = document.getElementsByClassName("mat-tab-list")[0];
          if (
            definition.numberOfQuestions > 15 &&
            definition.numberOfQuestions <= 21
          ) {
            tabList.classList.add("c1");
          } else if (definition.numberOfQuestions > 21) {
            tabList.classList.add("c");
          }
          this.questionService
            .getQuestionsByThemesId(
              this.localData.id,
              this.localData.font,
              this.numberOfQuestions,
              this.selectedThemes
            )
            .subscribe((questions) => {
              this.questions = questions;
            });
        });
    }
  }

  getNumber(num) {
    return new Array(num);
  }

  startTimer() {
    this.startDt = (Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toString();
    

    this.interval = setInterval(() => {
      // this.pieTimer++;
      if (this.isFinished) {
      } else {
        // if (this.pieTimer % 4 == 0) {
        //   const pie = document.querySelector('._' + this.pie);
        //   pie.children[0].classList.add('redPie');
        //   // this.pie++;
        // }
        if (this.minutesLeft > 0) {
          this.minutesLeft--;
        }
        if (
          this.minutesLeft == 0 &&
          this.isFinished == false &&
          this.onPage == true
        ) {
          this.terminateTimeExam();
        }
      }
    }, 60000);
  }

  ngOnInit() {
    this.onPage = true;
    this.startTimer();
    const tabList = document.getElementsByClassName("mat-tab-list")[0];
    if (this.numberOfQuestions > 15) {
      tabList.classList.add("c");
    }
  }
  @HostListener("window:beforeunload")
  ngOnDestroy() {
    this.onPage = false;
  }
  zoom() {
    const el = document.getElementById("imagem");
    let row: any = {};
    row.src = el.getAttribute("src");
    let dialogRef = this.dialog.open(DialogBoxZoomComponent, {
      id: "zoom",
      data: row,
      width: 'auto',
      height: 'auto',
      maxHeight: "97vh",
      maxWidth: "97vw"
    });
  }

  a(e, i: number) {
    if (e.toElement.className.match("mat-tab-header-pagination-after")) {
      this.selected.setValue(i + 1);
    } else if (
      e.toElement.className.match("mat-tab-header-pagination-before")
    ) {
      this.selected.setValue(i - 1);
    } else {
    }
  }

  select(e, i, id) {
    id = id + 1;
    if (this.isFinished == false) {

      let tabGroupIndex = document.getElementsByClassName("mat-tab-body")[0].id.split("-")[3];

      const tab = document.getElementById("mat-tab-label-" + tabGroupIndex + "-" + i);

      if (this.utils.isMobileDevice() == true) {
        const labels = document.querySelectorAll("li.input");
        const lis = document.querySelectorAll("li.answer");

        lis.forEach((element) => {
          element.classList.remove("active");
          element.classList.remove("answered");
          tab.classList.remove("label-answered");
        });
        if (lis[id - 1].className.match("active")) {
          lis[id - 1].classList.remove("active");
          lis[id - 1].classList.remove("answered");
          tab.classList.remove("label-answered");
        } else {
          lis[id - 1].classList.add("active");
          lis[id - 1].classList.add("answered");
          tab.classList.add("label-answered");
        }
        labels.forEach((element) => {
          // se estiver respondido e o id for diferente, retira
          if (
            element.children[0].className.match("icofont-close") &&
            Number(element.getAttribute("id")) != id - 1
          ) {
            element.children[0].classList.remove("icofont-close");
            tab.classList.remove("label-answered");
          } else {
            //se o id for igual coloca
            if (Number(element.getAttribute("id")) == id - 1) {
              element.children[0].classList.add("icofont-close");
              tab.classList.add("label-answered");
            }
          }
        });
        this.questions[i].answerSelected = id - 1;
      } else {
        const labels = document.querySelectorAll("li.withicon");

        //console.log(labels);

        labels.forEach((element) => {
          // se estiver respondido e o id for diferente, retira
          if (element.children[0].className.match("icofont-close") && Number(element.getAttribute("id")) != id - 1) {
            element.children[0].classList.remove("icofont-close");
          } else {
            //se o id for igual coloca
            if (Number(element.getAttribute("id")) == id - 1) {
              element.children[0].classList.add("icofont-close");
              tab.classList.add("label-answered");
            }
          }
        });
        this.questions[i].answerSelected = id - 1;
      }
    } else {
    }

    if (i + 1 <= this.questions.length - 1) {
      setTimeout(() => {
        this.selected.setValue(i + 1);
        this.selectedIndex = i + 1;
      }, 500);
    }
  }

  selectTab(isFinished, id) {
    if (isFinished == true) {
      if (this.utils.isMobileDevice()) {
        let tabGroupIndex = document
          .getElementsByClassName("mat-tab-body")[0]
          .id.split("-")[3];

        //console.log(tabGroupIndex);

        const el = document.getElementById("mat-tab-content-" + tabGroupIndex + "-" + id);

        console.log(el);

        let answers = el.querySelectorAll("li.answer");

        console.log(answers);
        console.log(id);

        answers.forEach((element) => {
          if (Number(element.getAttribute("id")) == this.questions[id]["answerCorrect"]) {
            element.classList.remove("answered");
            element.classList.add("correct");
          }

          if (element.className.match("answered") && Number(element.getAttribute("id")) != this.questions[id]["answerCorrect"]) {
            element.classList.remove("answered");
            element.classList.add("wrong");
          }
        });

        el.children[0].querySelectorAll("li.answer").forEach((elem) => {
          elem.parentElement.classList.add("disabled");
        });
      }
      else
      {
        //console.log(id);

        let tabGroupIndex = document.getElementsByClassName("mat-tab-body")[0].id.split("-")[3];

        //console.log(tabGroupIndex);

        const el = document.getElementById("mat-tab-content-" + tabGroupIndex + "-" + id);

        //console.log(el);

        let answers = el.querySelectorAll("li.withicon");

        //console.log(answers);
        
        //console.log(this.questions[id]["answerCorrect"]);

        answers.forEach((element) => {

          if (Number(element.getAttribute("id")) == this.questions[id]["answerCorrect"]) {
            element.children[0].classList.add('icofont-tick-mark');
            element.children[0].classList.add('correctIcon');
          }

          if (element.children[0].className.match('icofont-close') && Number(element.getAttribute("id")) != this.questions[id]["answerCorrect"]) {
            element.children[0].classList.add('wrongIcon');
          }
        });

        el.querySelectorAll('li.withicon').forEach(elem => {
          elem.parentElement.classList.add('disabled');
        });

        el.querySelectorAll('li.option').forEach(elem => {
          elem.parentElement.classList.add('disabled');
        });

        el.querySelectorAll("li.answer").forEach((elem) => {
          elem.parentElement.classList.add("disabled");
        });
      }
    }
  }

  openHelp() {
    let width = this.utils.isMobileDevice() ? "90vw" : "30vw";
    let maxWidth = this.utils.isMobileDevice() ? "99vw" : "30vw";

    this.selectedTab = this.selected.value;
    let dialogRef = this.dialog.open(QuestionHelpComponent, {
      data: this.questions[this.selectedTab]["help"],
      width: width,
      height: 'auto',
      disableClose: false,
      closeOnNavigation: true,
      maxWidth: maxWidth,
      maxHeight: "80vh",
    });
  }

  terminateTimeExam() {
    let width = this.utils.isMobileDevice() == true ? "100vw" : "30vw";
    let height = this.utils.isMobileDevice() == true ? "auto" : "auto";

    let row: any = {};
    row.text = "Acabou o tempo para realizar o teste";
    let dialogRef = this.dialog.open(ConfirmTerminateComponent, {
      id: "confirm",
      width: width,
      data: row,
      disableClose: true,
      closeOnNavigation: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) {
      } else if (result.event == "sim") {
        this.endExame();
      }
    });
  }

  endExame() {
    this.isFinished = true;
    let i = 0;
    let wrong = 0;
    let count = this.numberOfQuestions;

    this.questions.forEach((element) => {
      let tabGroupIndex = document
        .getElementsByClassName("mat-tab-body")[0]
        .id.split("-")[3];

      const tab = document.getElementById(
        "mat-tab-label-" + tabGroupIndex + "-" + i
      );

      //console.log(tab);
      //console.log(element);

      tab.classList.remove("label-answered");

      if (element.answerSelected == undefined || element.answerSelected == null) {
        element.answerSelected = -1;
        count--;
        tab.classList.add("wrong");
        wrong++;
      } else {
        if (element.answerSelected == element["answerCorrect"]) {
          tab.classList.add("correct");
        } else {
          tab.classList.add("wrong");
          wrong++;
        }
      }
        i++;
    });

    this.selected.setValue(0);
    this.selectedIndex = 0;
    let row: any = {};
    row.route = this.localData;
    row.wrongQuestions = wrong;
    row.questions = this.questions;
    row.startDt = this.startDt;
    row.endDt = (Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toString();
    row.count = count >= this.numberOfQuestions * 0.6 ? true : false;
    row.testDefinitionId = this.definitionId;
    row.categoryId = this.localData.id;
    row.userId = this.currentUser.user.id;
    row.font = this.localData.font;
    row.origin = this.localData.origin;
    row.userElearningLessonId = this.localData.userElearningLessonId;
    let widthTest = this.utils.isMobileDevice() == true ? "95vw" : "70vw";

    if (count == 0) this.refreshTabs();

    let dialogRefr = this.dialog.open(DialogBoxTestResultComponent, {
      id: "testResult",
      width: widthTest,
      maxWidth: widthTest,
      data: row,
      height: 'auto',
      maxHeight: '82vh',
    });

    dialogRefr
      .afterClosed()
      .pipe(first())
      .subscribe(() => {
        this.dialogRef._containerInstance.dispose();
        const elem = document.getElementById("ter");
        elem.style.display = "none";

        this.selectTab(this.isFinished, 0);
      });
  }

  refreshTabs() {
    let i = 0;

    this.questions.forEach((element) => {
      this.selectTab(this.isFinished, i);
      i++;
    });
  }

  terminate() {
    let width = this.utils.isMobileDevice() ? "90vw" : "30vw";
    let maxWidth = this.utils.isMobileDevice() ? "99vw" : "30vw";
    let height = this.utils.isMobileDevice() ? "auto" : "auto";
    let row: any = {};
    row.text = "Tem a certeza que quer terminar o teste?";
    let dialogRef = this.dialog.open(ConfirmTerminateComponent, {
      id: "confirm",
      width: width,
      maxWidth: maxWidth,
      height: height,
      data: row,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) {
      } else if (result.event == "sim") {
        this.endExame();
      }
    });
  }

  close() {
    let width = this.utils.isMobileDevice() ? "90vw" : "30vw";
    let maxWidth = this.utils.isMobileDevice() ? "99vw" : "30vw";
    let height = this.utils.isMobileDevice() ? "auto" : "auto";
    let row: any = {};
    row.text =
      this.isFinished == true
        ? "Tem a certeza que quer sair?"
        : "Tem a certeza que quer sair? Perderá os dados deste Teste!";

    let dialogRef = this.dialog.open(ConfirmTerminateComponent, {
      id: "confirm",
      width: width,
      maxWidth: maxWidth,
      height: height,
      data: row,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) {
      } else if (result.event == "sim") {
        this.dialogRef.close({ event: "Cancel" });
      }
    });
  }

  indexChange(event) {
    //console.log("indexChange");
    //console.log(event);
    this.selected.setValue(event);
    //this.selectTab(this.isFinished, this.selected.value);
  }

  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  // Action triggered when user swipes
  swipe(selectedIndex: number, action = this.SWIPE_ACTION.RIGHT) {
    //console.log("swipe");
    //console.log("number",this.selected.value);
    //console.log(action);
    //console.log(this.questions.length - 1);

    // Out of range
    if (this.selected.value < 0 || this.selected.value > (this.questions.length - 1)) return;

    // Swipe left, next tab
    if (action === this.SWIPE_ACTION.LEFT) {
      const isLast = this.selected.value === (this.questions.length - 1);
      //console.log("isLast", isLast);
      this.selected.setValue(isLast ? 0 : this.selected.value + 1);
      //console.log("Swipe right - INDEX: " + this.selected.value);
    }

    // Swipe right, previous tab
    if (action === this.SWIPE_ACTION.RIGHT) {
      const isFirst = this.selected.value === 0;
      //console.log("isFirst", isFirst);
      this.selected.setValue(isFirst ? 1 : this.selected.value - 1);
      //console.log("Swipe left - INDEX: " + this.selected.value);
    }
  }
}

