import * as xlsx from 'xlsx';

import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';

import { AlertMailService } from '../../../../services/alert-mail.service';
import { AuthService } from '../../../../services/auth.service';
import { BasePageComponent } from '../../../../pages/base-page';
import { Company } from '../../../../models/Company';
import { CompanyService } from '../../../../services/company.service';
import { ConfirmComponent } from '../../DialogBoxes/confirm/confirm.component';
import { DialogBoxUsersComponent } from '../../DialogBoxes/dialog-box-users/dialog-box-users.component';
import { ELearningService } from 'src/app/services/elearning.service';
import { HttpService } from '../../../../services/http/http.service';
import { IAppState } from '../../../../interfaces/app-state';
import { MatDialog } from '@angular/material';
import { ProfileService } from '../../../../services/profile.service';
import { Store } from '@ngrx/store';
import { TicketService } from '../../../../services/ticket.service';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../../../models/user';
import { UserModel } from '../../../../models/userModel';
import { UserService } from '../../../../services/user.service';
import { Utils } from 'src/utils/utils';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';

/**
 * @title Data table with sorting, pagination, and filtering.
 */
@Component({
  selector: 'app-users',
  styleUrls: ['./users.component.css'],
  templateUrl: './users.component.html',
})
export class UsersComponent extends BasePageComponent implements OnInit, OnDestroy {
  bordered = true;
  position = 'bottom';
  searchValue = '';
  pageSize = 10;
  listOfDisplayData: UserModel[] = [];
  listOfAllData: UserModel[] = [];
  list: UserModel[] = [];
  sortName: string | null = null;
  sortValue: string | null = null;
  mapOfSort: { [key: string]: string | null } = {
    name: null,
    userName: null,
    email: null,
    companyName: null,
    profileName: null
  };
  mapOfCheckedId: { [key: string]: boolean } = {};
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  isOperating = false;
  numberOfChecked = 0;
  schools: Company[];
  profiles: any[];
  currentUser: User;
  currentPage = 0;
  nameValue = '';
  userNameValue = '';
  companyValue = '';
  profileValue = '';
  licenseValue = '';
  emailValue = '';
  companyName: string;
  closeDropdown: EventEmitter<boolean>;
  public prev_index: string = '0';

  constructor(private userService: UserService, public dialog: MatDialog, private companyService: CompanyService,
    store: Store<IAppState>, private authenticationService: AuthService, private profileService: ProfileService,
    httpSv: HttpService, private alertMailService: AlertMailService,
    private ticketService: TicketService, 
    private elearning: ELearningService,
    private toastr: ToastrService,
    public utils: Utils) {
    super(store, httpSv);
    
    this.getData(`${environment.apiUrl}/User/`, 'tableData', 'setLoaded');

    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

    if (this.currentUser.user.profileId == 6) {
      this.companyService.getById(this.currentUser.user.companyId).subscribe(a => { this.companyName = a.name; })
      this.userService.getAllStudentsByCompany(this.currentUser.user.companyId, this.currentUser.user.profileId).subscribe(user => {
        this.listOfAllData = user;
        this.list = user;

      });
    }
    else {

      if(this.currentUser.user.profileId == 8) //IMT
      {
        this.userService.getByProfileId(3).subscribe(user =>
          {
            this.listOfAllData = user;
            this.list = user;
          }
        );
      }
      else
      {
        this.userService.getAll().pipe(first()).subscribe(user => {
          this.listOfAllData = user;
          this.list = user;
          }
        ); 
      }
      
      this.companyService.getAll().subscribe(company => {
        this.schools = company;
      });
    }

    this.closeDropdown = new EventEmitter<boolean>();

    this.profileService.getAll().subscribe(profiles => {
      this.profiles = profiles;
    });
  }

  currentPageDataChange($event: UserModel[]): void {
    this.listOfDisplayData = $event;
  }
  sort(sort: { key: string; value: string }): void {
this.sortName = sort.key;
    this.sortValue = sort.value;
    this.search('');
    if(this.sortValue == '' || this.sortValue == null){
      this.sortName = 'name'
      this.sortValue = 'ascend'
      this.filter();
    }
  }
  reset(): void {
    this.nameValue = '';
    this.userNameValue = '';
    this.companyValue = '';
    this.profileValue = '';
    this.licenseValue = '';
    this.emailValue = '';
this.search('');
    this.listOfAllData = this.list;

    this.sortName = null;
    this.sortValue = null;
    this.mapOfSort = {
      name: null,
      userName: null,
      email: null,
      companyName: null,
      profileName: null
    };
  }

  /**
   * @param event
   * @param index
   */
  public select(event, index) {
    document.getElementById(this.prev_index).classList.remove('active');
    document.getElementById(index).classList.add('active');
    this.prev_index = index
  }

  public checkName (name, str) : boolean
  {
    var pattern = str.split("").map((x)=>{
        return `(?=.*${x})`
    }).join("");
    var regex = new RegExp(`${pattern}`, "g")
    return name.match(regex);
  }


  search(type: string): void {
    const filterFunc = (item: UserModel) => {
      let x = '' + item['name'].toLowerCase();
      let y = item['userName'].toLowerCase();
      let z = item['companyName'].toLowerCase();
      let w = ''+ item['profileName'].toLowerCase();
      let e = item['email'].toLowerCase();
      let names = this.nameValue.toLowerCase().split(" ");
      let name = '';
      let h = '';
      
      if(item['learningLicense'])
        h = item['learningLicense'].toLowerCase();

        //Search names
      if(names.length > 1){
        for(let i = 0; i < names.length; i++){
          if(x.indexOf(names[i]) != -1 && x.indexOf(names[i+1]) != -1){
            name = '0';
            }
          }
      }
      else{ 
        if(x.indexOf(names[0]) != -1)
          name = '0';
      }
            
      return (
        type == 'name' ? name != '' :
        type == 'userName' ? y.indexOf(this.userNameValue.toLowerCase()) !== -1 : 
        type == 'companyName' ? z.indexOf(this.companyValue.toLowerCase()) !== -1 : 
        type == 'profileName' ? w.indexOf(this.profileValue.toLowerCase()) !== -1 : 
        type == 'email' ? e.indexOf(this.emailValue.toLowerCase()) !== -1 :
        h.indexOf(this.licenseValue.toLowerCase()) !== -1
      );
    };
    const data = this.listOfAllData.filter((item: UserModel) => filterFunc(item));
    this.listOfAllData = data.sort((a, b) =>

      this.sortValue === 'ascend'
        ? a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );
  }

  public removeSelectedRows() {
    let row: any = {};
    let windowHeight = window.innerHeight * 0.23;
    row.text = 'Confirma a remoção do(s) utilizadore(s) selecionado(s)?'
    let dialogRef = this.dialog.open(ConfirmComponent, {
      id: 'confirm',
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) { }
      else if (result.event == 'sim') {
        this.listOfAllData.forEach(
          item => this.mapOfCheckedId[item['userId']] == true ? this.removeById(item['userId']) : ""
        );
        /**
         * TODO:remove is deprecate...
        const e = document.querySelectorAll('tr');
        e.forEach(el => {
          if (el.className.match('active')) {
            const index: number = this.listOfAllData.findIndex(d => d['name'] === el.children[1].lastChild.nodeValue.trim());
            this.userService.deleteUser(this.listOfAllData[index]['userId']).subscribe((data) => {
              this.userService.getAll().pipe(first()).subscribe(user => {
                this.listOfAllData = user;
              });
              this.toastr.success('Utilizador(es) removido(s) com sucesso!');
            },
              err => {
                this.toastr.error('Algo ocorreu mal!');
              });
          }
        });
        */
      }
    });
  }

  openDialog(action, e, i) {
    //console.log('hello');
    //console.log(this.pageSize);


    if (e == null || !e.target.className.match('ant-table-column-sorters') && !e.target.className.match('ant-spin-container')) {
      let row: any = {};
      row = e == null ? {} : this.listOfAllData[(this.currentPage * this.pageSize + i)];
      row.action = action;

      if(row.action == 'Adicionar'){
        row.companyName = action == 'Adicionar' ? this.companyName : this.listOfAllData[(this.currentPage * this.pageSize + i)]['companyName'];
            
            if (action == 'Adicionar') {
              row.userId = this.currentUser.user.id;
            }
            
            const deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
            const deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '850px';
            const deviceMaxHeight  = this.utils.isMobileDevice() ? '97vh' : '97vh';

            const dialogRef = this.dialog.open(DialogBoxUsersComponent, {
              width: deviceWidth,
              maxWidth: deviceMaxWidth,
              height: 'auto',
              maxHeight: deviceMaxHeight,
              data: row,
              disableClose: true,
              closeOnNavigation: true
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result === undefined) { }
              else if (result.event == 'AdicionarGeral') {
                this.addRowData(result.data);
              } else if (result.event == 'ModificarGeral') {
                this.updateRowData(result.data);
              }
              // dialogRef.componentInstance = null;
              // this.dialog.closeAll();
            });
      }
      else{
        this.userService
        .GetLastDataById(row.userId)
        .subscribe(a => {
            console.log(a);
            row.lastAccessEmail = a.sendedInfo;
            row.created = a.created;
            row.lastInteraction = a.lastInteraction;
            
      row.companyName = action == 'Adicionar' ? this.companyName : this.listOfAllData[(this.currentPage * this.pageSize + i)]['companyName'];

      if (action == 'Adicionar') {
        row.userId = this.currentUser.user.id;
      }
      
      const deviceWidth = this.utils.isMobileDevice() ? '99vw' : '1000px';
      const deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
      const deviceMaxHeight  = this.utils.isMobileDevice() ? '97vh' : '97vh';

      console.log(row);
      const dialogRef = this.dialog.open(DialogBoxUsersComponent, {
        width: deviceWidth,
        maxWidth: deviceMaxWidth,
        height: 'auto',
        maxHeight: deviceMaxHeight,
        data: row,
        disableClose: true,
        closeOnNavigation: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === undefined) { }
        else if (result.event == 'AdicionarGeral') {
          this.addRowData(result.data);
        } else if (result.event == 'ModificarGeral') {
          this.updateRowData(result.data);
        }
        // dialogRef.componentInstance = null;
        // this.dialog.closeAll();
      });
          });
        }


    }
  }

  addRowData(rowObj) {
    this.userService.createUser('', rowObj.name, rowObj.email,
      rowObj.nif, rowObj.cc, rowObj.profileId, rowObj.companyId, rowObj.active,
      rowObj.studentNumber, rowObj.learningLicense, rowObj.contacts, '', '123456').subscribe((data) => {
        if (this.currentUser.user.profileId == 6) {
          this.userService.getAllStudentsByCompany(this.currentUser.user.companyId, this.currentUser.user.profileId).subscribe(user => {
            this.listOfAllData = user;
            this.list = user;
            this.listOfDisplayData = user;
            this.filter()
          });
        } else {
          this.userService.getAll().pipe(first()).subscribe(user => {
            this.listOfAllData = user;
            this.list = user;
            this.listOfDisplayData = user;
            this.filter()
          });
        }
        this.toastr.success('Utilizador criado com sucesso!');
      },
        err => {
          this.toastr.error('Algo ocorreu mal!');
        });
  }
  
  updateRowData(rowObj) {
    this.userService.updateUser(rowObj.userId, '', rowObj.name, rowObj.email,
      rowObj.nif, rowObj.cc, rowObj.profileId, rowObj.companyId, rowObj.active, rowObj.contacts,
      rowObj.studentNumber, rowObj.learningLicense, rowObj.userName, '').subscribe((data) => {
        if (this.currentUser.user.profileId == 6) {
          this.userService.getAllStudentsByCompany(this.currentUser.user.companyId, this.currentUser.user.profileId).subscribe(user => {
            this.listOfAllData = user;
            this.list = user;
            this.filter()
          });
        } else {
          this.userService.getAll().pipe(first()).subscribe(user => {
            this.listOfAllData = user;
            this.list = user;
            this.filter()
          });
        }
        this.toastr.success('Utilizador modificado com sucesso!');
      },
        err => {
          this.toastr.error('Algo ocorreu mal!');
        });
  }

  // updateTicketData(rowObj) {
  //   this.ticketService.ticketByCategoryIdToUser(rowObj.companyId,
  //     rowObj.products, rowObj.durationId, rowObj.userId).subscribe((data) => {
  //       this.toastr.success('Tickets atribuidos com sucesso!');
  //     },
  //       err => {
  //         this.toastr.error('Algo ocorreu mal!');
  //       }
  //     );
  // }

 haveSelected() : boolean
 {
  var sendId : any[];
  sendId = [];

  this.listOfAllData.forEach(item => {   
      if (this.mapOfCheckedId[item['userId']] == true)
        sendId.push(item['userId']);
  });

  return (sendId.length > 0)
 }

 export() {
  var sendId : any[];
  sendId = [];

  this.listOfAllData.forEach(item => {   
      if (this.mapOfCheckedId[item['userId']] == true)
        sendId.push(item['userId']);
  });

  if(sendId.length == 0)
    return;

  this.elearning.getReport(sendId).subscribe(resume => {
    var res: any[];

    var tableExport: any[] = [];
    
    res = resume.filter(x => { return sendId.find(i => i == x.userId);});

    res.forEach(element => {
        var z = {
            'Licença de Aprendizagem': element['learningLicense'],
            'Nome': element['name'],
            'NIF': element['nif'],
            'Cartão de Cidadão': element['cc'],
            'Número': element['companyNumber'],
            'Escola': element['company'],
            'Categoria': element['category'],
            'Concluído': element['finished'] === true? 'Sim' : 'Não',
            'Percentagem': element['concludedPercentage'] + '%',
            'Tempo Total Plataforma': element['totalTimeInPlatform'],
            'Temas Sub/Temas Concluídos': element['totalLessonConcluded'],
            'Testes realizados': element['totalExamesConcluded']
        };
        tableExport.push(z);
    });
    
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(tableExport);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();

        var wscols = [
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 30 }
        ];
        ws['!cols'] = wscols;
        xlsx.utils.book_append_sheet(wb, ws, 'Utilizadores');
        xlsx.writeFile(wb, 'utilizadores.xlsx');
  });
   
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfAllData.every(item => this.mapOfCheckedId[item['userId']]);
    this.isIndeterminate = this.listOfAllData.some(item => this.mapOfCheckedId[item['userId']]) && !this.isAllDisplayDataChecked;
    this.numberOfChecked = this.listOfAllData.filter(item => this.mapOfCheckedId[item['userId']]).length;
  }

  checkAll(value: boolean): void {
    this.listOfAllData.forEach(item => (this.mapOfCheckedId[item['userId']] = value));
    this.refreshStatus();
  }

  /**
   * @param userId
   */
  public removeById(userId) {
    this.userService.deleteUser(userId).subscribe(
      (data) => { },
      (error) => { this.toastr.error('O utilizador tem informação associada!'); },
      () => {

        if (this.currentUser.user.profileId == 6) {
          this.userService.getAllStudentsByCompany(this.currentUser.user.companyId, this.currentUser.user.profileId).subscribe(user => {
            this.listOfAllData = user;
            this.list = user;
          });
        } else {
          this.userService.getAll().pipe(first()).subscribe(user => {
            this.listOfAllData = user;
            this.list = user;
          });
        }

        this.toastr.success('Utilizador(es) removido(s) com sucesso!');
      }
    );
  }

  ngOnInit() {
    super.ngOnInit();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  isAdmin() {
    return (this.currentUser.user.role == 'Administrador' ? true : false);
  }
  onCloseDropdown() {
    this.closeDropdown.emit(true);
  }
  opened = false;
  openFilter() {
    const el = document.getElementById('filtro');
    if (this.opened == false) {
      el.classList.add('show');
      el.classList.remove('hide');
      this.opened = true;
    }
    else {
      el.classList.add('hide');
      el.classList.remove('show');
      this.opened = false;
    }
  }
  filter() {

    //console.log('s');
    this.listOfAllData = this.list;
    if (this.nameValue != '') { this.search('name'); }
    if (this.userNameValue != '') { this.search('userName'); }
    if (this.companyValue != '') { this.search('companyName'); }
    if (this.profileValue != '  ' && this.profileValue != '') { this.search('profileName'); }
    if (this.emailValue != '') { this.search('email'); }
    if (this.licenseValue != '') { this.search('license'); }
  }

  custom(i) {
    this.currentPage = i - 1;
  }

  sendEmail(i) {
    this.alertMailService.sendAccountInfo(this.listOfAllData[(this.currentPage * this.pageSize + i)]['userId']).subscribe(a => {
      this.toastr.success("Dados enviados com sucesso");
    });
  }

  sendT() {
    this.listOfAllData.forEach(item => this.mapOfCheckedId[item['userId']] == true ? this.alertMailService.sendAccountInfo(item["userId"]).subscribe(a => { this.toastr.success("Dados enviados com sucesso"); }) : "");
  }
}