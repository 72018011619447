<div class="modalbox" (click)="closeDialog()">
   <div class="modal_content">
         <h4 style="margin-top: 0.428571rem; margin-bottom: 0.428571rem">AJUDA</h4>
         <hr style="border-bottom: 1px solid #CCCCCC;">
      <mat-dialog-content>
         <div *ngIf="text == null || text == ''">
            <p>Esta pergunta não tem ajuda</p>
         </div>
         <div *ngIf="text != null || text != ''">
            <p [innerHTML]="utils.transform(text)"></p>
         </div>
      </mat-dialog-content>
   </div>
</div>