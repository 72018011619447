<block-ui [template]="blockTemplate">
    <div class="container d-none d-xl-block" style="width: 100%; height: 48px; background-color: #134472;color:#80BBD7; display: flex; justify-content: space-evenly; align-items: center;">
        <div class="row no-gutters align-items-center" style="height: 48px;" >
            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <span class="terminate roboto500" id="type">RESULTADO</span>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 text-truncate">
                <span class="terminate roboto400" id="name">{{currentName}}&nbsp;-&nbsp;{{currentUser.user.nif}}</span>
            </div>
            <div class="buttonsContainer col-sm-2 col-md-2 col-lg-2 col-xl-2 text-right">
                <button class="closeButton" (click)="rever()" style="background-color: white; color: #134472; border-radius:50%;  margin:2px">
                    <i class="tc-icon-wrap icofont-close-line"></i>
                </button>
            </div>    
        </div>
    </div>

    <div *ngIf="utils.isMobileDevice()" class="row no-gutters">
        <label class="result" [style.background-color]="approved == 'Aprovado' ? '#00C800' : '#FF0000'">{{approved}}</label>
    </div>
    <div *ngIf="!utils.isMobileDevice()" class="text-center">
        <label class="result" [style.background-color]="approved == 'Aprovado' ? '#00C800' : '#FF0000'">{{approved}}</label>
    </div>

    <nz-table #rowSelectionTable [nzData]="tableData" [nzBordered]="bordered"
        [nzShowPagination]="false" [nzPageSize]="250" [nzScroll]="{ y: '50vh' }">
        <thead>
            <tr class="noClick">
                <th [nzWidth]="'52%'" [nzAlign]="'left'" title="Tema">Tema</th>
                <th [nzWidth]="'16%'" [nzAlign]="'center'" title="Nº de Questões">#</th>
                <th [nzWidth]="'16%'" [nzAlign]="'center'" title="Acertadas"><i class="icofont-tick-mark"></i></th>
                <th [nzWidth]="'16%'" [nzAlign]="'center'" title="Erradas"><i class="icofont-error"></i></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="Msg()">
                <td colspan="4" class="text-center" style="background-color:#FF0000; color:#ffffff">
                    {{ msg }}   
                </td>
            </tr>
            <tr *ngFor="let data of rowSelectionTable.data">
                <td [nzBreakWord]="true" [nzAlign]="'left'" title="Tema">{{ data.themeName }}</td>

                
                <td [nzAlign]="'center'" title="Nº de Questões" *ngIf="data.leaf">{{ data.countQuestions }}</td>
                <td [nzAlign]="'center'" title="Acertadas" *ngIf="data.leaf">
                    <tc-badge [view]="'success'" [size]="utils.isMobileDevice() ? 'xs' :'sm'">{{ data.countRight }}</tc-badge>
                </td>
                <td [nzAlign]="'center'" title="Erradas" *ngIf="data.leaf">
                    <tc-badge [view]="'error'" [size]="utils.isMobileDevice() ? 'xs' : 'sm'">{{ data.countWrong }}</tc-badge>
                </td>
                <td [nzAlign]="'center'" *ngIf="!data.leaf" colspan="3">
                    
                </td>
            </tr>
        </tbody>
    </nz-table>
    <div style="text-align:center; padding: 2%;">
        <button tc-button class="btn-default" [view]="'success'" (click)="rever()" [beforeIcon]="'icofont-reply-all'">Rever</button>
        <div class="divider"></div>
        <button *ngIf="this.localData.origin == 'test'" tc-button class="btn-error" [view]="'warning'" (click)="out()" [beforeIcon]="'icofont-close-circled'">Sair</button>
    </div>
</block-ui>