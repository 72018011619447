import { Component, Input } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";

import { ConfirmTerminateComponent } from "../../DialogBoxes/confirm-terminate/confirm-terminate.component";
import { DialogBoxTestResultFreeComponent } from "../../DialogBoxes/dialog-box-test-result-free/dialog-box-test-result-free.component";
import { DialogBoxZoomComponent } from "../../DialogBoxes/dialog-box-zoom/dialog-box-zoom.component";
import { DomSanitizer } from "@angular/platform-browser";
import { FormControl } from "@angular/forms";
import { Location } from "@angular/common";
import { MediaService } from "../../../../services/media.service";
import { Router } from "@angular/router";
import { Utils } from "src/utils/utils";
import { first } from "rxjs/internal/operators/first";

@Component({
  selector: "app-fixedTest",
  templateUrl: "./fixedTest.component.html",
  styleUrls: ["./fixedTest.component.css"],
})
export class FixedTestComponent {
  themes: any[] = [
    {
      ThemeName:
        "B101 - Circulação, segurança e veículos em missão urgente de socorro",
      Questions: 2,
      Correct: 0,
      Wrong: 0,
    },
    {
      ThemeName: "B102 - Sinais de perigo",
      Questions: 2,
      Correct: 0,
      Wrong: 0,
    },
    {
      ThemeName: "B103 - Sinais de proibição",
      Questions: 2,
      Correct: 0,
      Wrong: 0,
    },
    {
      ThemeName:
        "B104 - Sinais de prescrição específica, sinais de cedência de passagem",
      Questions: 2,
      Correct: 0,
      Wrong: 0,
    },
    {
      ThemeName: "B105 - Sinais de indicação",
      Questions: 1,
      Correct: 0,
      Wrong: 0,
    },
    {
      ThemeName:
        "B106 - Sinalização luminosa, marcas no pavimento e outra sinalização",
      Questions: 1,
      Correct: 0,
      Wrong: 0,
    },
    {
      ThemeName: "B107 - Ultrapassagem",
      Questions: 2,
      Correct: 0,
      Wrong: 0,
    },
    {
      ThemeName: "B108 - Paragem, estacionamento e cruzamento de veículos",
      Questions: 2,
      Correct: 0,
      Wrong: 0,
    },
    {
      ThemeName: "B109 - Outras manobras",
      Questions: 1,
      Correct: 0,
      Wrong: 0,
    },
    {
      ThemeName: "B110 - Velocidade",
      Questions: 2,
      Correct: 0,
      Wrong: 0,
    },
    {
      ThemeName: "B111 - Cedência de passagem",
      Questions: 2,
      Correct: 0,
      Wrong: 0,
    },
    {
      ThemeName: "B109 - Outras manobras",
      Questions: 1,
      Correct: 0,
      Wrong: 0,
    },
    {
      ThemeName: "B2 - O Condutor e o seu Estado Físico e Psicológico",
      Questions: 1,
      Correct: 0,
      Wrong: 0,
    },
    {
      ThemeName: "B3 - O Condutor e o Veículo",
      Questions: 6,
      Correct: 0,
      Wrong: 0,
    },
    {
      ThemeName: "B4 - O Condutor e os Outros Utentes da Via",
      Questions: 1,
      Correct: 0,
      Wrong: 0,
    },
    {
      ThemeName: "B5 - O Condutor, a Via e Outros Fatores Externos",
      Questions: 1,
      Correct: 0,
      Wrong: 0,
    },
    {
      ThemeName: "B6 - Diversos",
      Questions: 2,
      Correct: 0,
      Wrong: 0,
    },
    {
      ThemeName: "TOTAL",
      Questions: 30,
      Correct: 0,
      Wrong: 0,
    },
  ];
  questions: any[] = [
    {
      QuestionId: 5655,
      Question: "A minha intenção é seguir em frente. Devo:",
      Image: "",
      QuestionOption1: "Mudar de via de trânsito se pretender ultrapassar. ",
      QuestionOption2: "Mudar de via de trânsito. ",
      QuestionOption3: "Ultrapassar. ",
      IsAnswer: 1,
      ThemeName:
        "B101 - Circulação, segurança e veículos em missão urgente de socorro",
      Answered: 0,
    },
    {
      QuestionId: 5953,
      Question:
        "Um dos factores que mais influencia a distância de segurança é:",
      Image: "",
      QuestionOption1: "A pressa do condutor.",
      QuestionOption2: "A velocidade do veículo.",
      QuestionOption3: "As marcas rodoviárias que existam na via. ",
      IsAnswer: 2,
      ThemeName:
        "B101 - Circulação, segurança e veículos em missão urgente de socorro",
      Answered: 0,
    },
    {
      QuestionId: 6133,
      Question: "Neste local, devo:",
      Image: "",
      QuestionOption1:
        "Reduzir a velocidade, porque me aproximo de uma curva à esquerda.",
      QuestionOption2:
        "Transitar com precaução e aproximar-me do eixo da faixa de rodagem.",
      QuestionOption3: "Transitar com precaução.",
      IsAnswer: 3,
      ThemeName: "B102 - Sinais de perigo",
      Answered: 0,
    },
    {
      QuestionId: 6134,
      Question: "Neste local, devo:",
      Image: "",
      QuestionOption1: "Transitar com muita precaução.",
      QuestionOption2: "Transitar com precaução e utilizar os sinais sonoros.",
      QuestionOption3:
        "Utilizar os sinais sonoros, assinalando assim a minha presença.",
      IsAnswer: 1,
      ThemeName: "B102 - Sinais de perigo",
      Answered: 0,
    },
    {
      QuestionId: 6349,
      Question: "A indicação dada pelo sinal de mensagem variável:",
      Image: "",
      QuestionOption1: "Não tem qualquer valor.",
      QuestionOption2: "Tem carácter facultativo.",
      QuestionOption3: "Tem carácter obrigatório.",
      IsAnswer: 3,
      ThemeName: "B103 - Sinais de proibição",
      Answered: 0,
    },
    {
      QuestionId: 6350,
      Question: "A partir deste sinal fico a saber que:",
      Image: "",
      QuestionOption1: "Devo usar os sinais sonoros à noite.",
      QuestionOption2: "Nunca poderei usar os sinais sonoros.",
      QuestionOption3: "Termina a proibição de usar os sinais sonoros.",
      IsAnswer: 3,
      ThemeName: "B103 - Sinais de proibição",
      Answered: 0,
    },
    {
      QuestionId: 6744,
      Question:
        "Neste local, os condutores que circulam em sentido contrário, encontram no início da ponte um sinal de:",
      Image: "",
      QuestionOption1: "Cedência de passagem.",
      QuestionOption2: "Informação.",
      QuestionOption3: "Proibição.",
      IsAnswer: 1,
      ThemeName:
        "B104 - Sinais de prescrição específica, sinais de cedência de passagem",
      Answered: 0,
    },
    {
      QuestionId: 6790,
      Question:
        "Perante a sinalização existente devo saber que na via de trânsito onde circulo:",
      Image: "",
      QuestionOption1: "Devo circular a uma velocidade média de 70 km/h. ",
      QuestionOption2: "É obrigatório circular à velocidade mínima de 70 km/h.",
      QuestionOption3: "Não devo circular a mais de 70 Km/h.",
      IsAnswer: 2,
      ThemeName:
        "B104 - Sinais de prescrição específica, sinais de cedência de passagem",
      Answered: 0,
    },
    {
      QuestionId: 6971,
      Question: "O sinal turístico-cultural indica-me:",
      Image: "",
      QuestionOption1: "Património natural.",
      QuestionOption2: "Ponto de entrada ou início de uma rota turística.",
      QuestionOption3: "Zona de pesca desportiva.",
      IsAnswer: 1,
      ThemeName: "B105 - Sinais de indicação",
      Answered: 0,
    },
    {
      QuestionId: 7269,
      Question: "O agente regulador de trânsito está:",
      Image: "",
      QuestionOption1:
        "A indicar a todos os condutores na intersecção o dever de parar.",
      QuestionOption2:
        "A indicar ao trânsito à sua retaguarda o dever de parar.",
      QuestionOption3: "A indicar que todos os condutores devem avançar.",
      IsAnswer: 2,
      ThemeName:
        "B106 - Sinalização luminosa, marcas no pavimento e outra sinalização",
      Answered: 0,
    },
    {
      QuestionId: 7592,
      Question: "Posso ultrapassar?",
      Image: "",
      QuestionOption1: "Não, não posso.",
      QuestionOption2:
        "Sim, desde que o veículo da frente não pretenda mudar de direcção.",
      QuestionOption3: "Sim, desde que sinalize a manobra. ",
      IsAnswer: 1,
      ThemeName: "B107 - Ultrapassagem",
      Answered: 0,
    },
    {
      QuestionId: 7593,
      Question: "Posso ultrapassar?",
      Image: "",
      QuestionOption1:
        "Sim, porque existem duas vias de trânsito no mesmo sentido. ",
      QuestionOption2: "Sim, porque o local tem dois sentidos de trânsito. ",
      QuestionOption3:
        "Sim, porque o local tem pouca intensidade de trânsito. ",
      IsAnswer: 1,
      ThemeName: "B107 - Ultrapassagem",
      Answered: 0,
    },
    {
      QuestionId: 7680,
      Question:
        "A que distância mínima posso estacionar para trás deste sinal?",
      Image: "",
      QuestionOption1: "25 metros",
      QuestionOption2: "6 metros.",
      QuestionOption3: "8 metros.",
      IsAnswer: 1,
      ThemeName: "B108 - Paragem, estacionamento e cruzamento de veículos",
      Answered: 0,
    },
    {
      QuestionId: 7681,
      Question: "A que distância posso parar para trás do sinal?",
      Image: "",
      QuestionOption1: "10 metros.",
      QuestionOption2: "15 metros.",
      QuestionOption3: "25 metros.",
      IsAnswer: 3,
      ThemeName: "B108 - Paragem, estacionamento e cruzamento de veículos",
      Answered: 0,
    },
    {
      QuestionId: 8095,
      Question: "Na auto-estrada em que circulo é proibido:",
      Image: "",
      QuestionOption1: "Circular a menos de 60 Km/h",
      QuestionOption2: "Inverter o sentido de marcha",
      QuestionOption3: "Transitar com os máximos ligados.",
      IsAnswer: 2,
      ThemeName: "B109 - Outras manobras",
      Answered: 0,
    },
    {
      QuestionId: 8462,
      Question: "Como devo proceder na presença deste sinal vertical?",
      Image: "",
      QuestionOption1: "Circular a velocidade especialmente moderada.",
      QuestionOption2:
        "Circular o mais junto possível ao eixo da faixa de rodagem.",
      QuestionOption3: "Fazer sinais de luzes para assinalar a minha presença.",
      IsAnswer: 1,
      ThemeName: "B110 - Velocidade",
      Answered: 0,
    },
    {
      QuestionId: 8463,
      Question:
        "Conduzindo um automóvel ligeiro de mercadorias com reboque, qual a velocidade máxima a que posso circular na via em que vou entrar?",
      Image: "",
      QuestionOption1: "100 km/h.",
      QuestionOption2: "120 km/h.",
      QuestionOption3: "80 km/h.",
      IsAnswer: 3,
      ThemeName: "B110 - Velocidade",
      Answered: 0,
    },
    {
      QuestionId: 8891,
      Question: "Nesta intersecção devo:",
      Image: "",
      QuestionOption1: "Avançar lentamente.",
      QuestionOption2:
        "Ser bastante prudente e aguardar a passagem dos outros veículos.",
      QuestionOption3: "Utilizar os sinais luminosos.",
      IsAnswer: 2,
      ThemeName: "B111 - Cedência de passagem",
      Answered: 0,
    },
    {
      QuestionId: 8892,
      Question: "Nesta intersecção devo:",
      Image: "",
      QuestionOption1:
        "Ceder a passagem aos veículos que se vão apresentar à minha direita.",
      QuestionOption2: "Parar obrigatoriamente, haja trânsito ou não.",
      QuestionOption3:
        "Tentar avançar sempre em primeiro lugar, de maneira a não embaraçar o trânsito que circula atrás do meu veículo. ",
      IsAnswer: 1,
      ThemeName: "B111 - Cedência de passagem",
      Answered: 0,
    },
    {
      QuestionId: 9448,
      Question: "Que indicação me é dada pelo sinal vertical?",
      Image: "",
      QuestionOption1: "Obrigação de parar nas passagens estreitas.",
      QuestionOption2: "Obrigação de seguir em frente.",
      QuestionOption3: "Via com trânsito num só sentido.",
      IsAnswer: 2,
      ThemeName: "B2 - O Condutor e o seu Estado Físico e Psicológico",
      Answered: 0,
    },
    {
      QuestionId: 9764,
      Question: "É considerado automóvel ligeiro, o veículo que tiver:",
      Image: "",
      QuestionOption1: "7 lugares e peso de 3500 Kg.",
      QuestionOption2: "3 lugares e peso de 4000 Kg.",
      QuestionOption3: "10 lugares e peso de 2500 Kg.",
      IsAnswer: 1,
      ThemeName: "B3 - O Condutor e o Veículo",
      Answered: 0,
    },
    {
      QuestionId: 9704,
      Question:
        "Se o passageiro transportado no banco da frente não utilizar o cinto de segurança, a responsabilidade é, por regra:",
      Image: "",
      QuestionOption1: "Do condutor do veículo.",
      QuestionOption2: "Do proprietário do veículo.",
      QuestionOption3: "Do próprio passageiro.",
      IsAnswer: 2,
      ThemeName: "B3 - O Condutor e o Veículo",
      Answered: 0,
    },
    {
      QuestionId: 9705,
      Question:
        "Se tiver necessidade de substituir uma roda, devo em primeiro lugar:",
      Image: "",
      QuestionOption1: "Colocar o «macaco». ",
      QuestionOption2: "Desapertar as porcas totalmente.",
      QuestionOption3: "Imobilizar o veículo num local seguro.",
      IsAnswer: 3,
      ThemeName: "B3 - O Condutor e o Veículo",
      Answered: 0,
    },
    {
      QuestionId: 9588,
      Question: "A almofada de ar frontal (airbag) é um elemento de segurança?",
      Image: "",
      QuestionOption1: "Não.",
      QuestionOption2: "Sim, segurança activa.",
      QuestionOption3: "Sim, segurança passiva.",
      IsAnswer: 3,
      ThemeName: "B3 - O Condutor e o Veículo",
      Answered: 0,
    },
    {
      QuestionId: 9589,
      Question:
        "A forma como se utiliza o veículo, influencia a sua manutenção?",
      Image: "",
      QuestionOption1:
        "Não, a forma como eu conduzo nada tem a ver com a manutenção do veículo.",
      QuestionOption2: "Sim, mas apenas em grandes viagens.",
      QuestionOption3: "Sim.",
      IsAnswer: 3,
      ThemeName: "B3 - O Condutor e o Veículo",
      Answered: 0,
    },
    {
      QuestionId: 9591,
      Question: "A parte mecânica do veículo tem influência na condução?",
      Image: "",
      QuestionOption1:
        "Não, porque a forma como se conduz um veículo, nada tem a ver com seu funcionamento.",
      QuestionOption2:
        "Sim, mas apenas se eu pretender atingir «grandes velocidades».",
      QuestionOption3:
        "Sim, porque um funcionamento incorrecto do veículo pode influenciar de forma negativa a minha condução.",
      IsAnswer: 3,
      ThemeName: "B3 - O Condutor e o Veículo",
      Answered: 0,
    },
    {
      QuestionId: 9860,
      Question: "A entrada e saída de passageiros deve fazer-se:",
      Image: "",
      QuestionOption1: "Pelo lado direito ou esquerdo indistintamente.",
      QuestionOption2: "Pelo lado permitido para a paragem.",
      QuestionOption3: "Sempre pelo lado direito.",
      IsAnswer: 2,
      ThemeName: "B4 - O Condutor e os Outros Utentes da Via",
      Answered: 0,
    },
    {
      QuestionId: 10379,
      Question:
        "Nesta situação, a minha condução pode ser especialmente condicionada pela:",
      Image: "",
      QuestionOption1: "Aderência.",
      QuestionOption2: "Ausência de sinalização vertical.",
      QuestionOption3: "Visibilidade.",
      IsAnswer: 1,
      ThemeName: "B5 - O Condutor, a Via e Outros Fatores Externos",
      Answered: 0,
    },
    {
      QuestionId: 10612,
      Question:
        "O titular de carta de condução de automóveis pesados de mercadorias está habilitado a conduzir:",
      Image: "",
      QuestionOption1: "Automóveis ligeiros e pesados de mercadorias.",
      QuestionOption2: "Motociclos e automóveis pesados de mercadorias.",
      QuestionOption3: "Só automóveis pesados.",
      IsAnswer: 1,
      ThemeName: "B6 - Diversos",
      Answered: 0,
    },
    {
      QuestionId: 10613,
      Question:
        "O titular de carta de condução de automóveis pesados de mercadorias está, para além destes, habilitado a conduzir:",
      Image: "",
      QuestionOption1: "Automóveis e ciclomotores.",
      QuestionOption2: "Automóveis ligeiros.",
      QuestionOption3: "Automóveis pesados de passageiros.",
      IsAnswer: 2,
      ThemeName: "B6 - Diversos",
      Answered: 0,
    },
  ];

  minutesLeft: number = 30;
  isFinished = false;
  startDt: string;
  interval;
  onPage: boolean;
  @Input() selectedIndex: number | null;
  selected = new FormControl(0);
  constructor(
    public dialogRef: MatDialogRef<FixedTestComponent>,
    private _location: Location,
    private router: Router,
    private mediaService: MediaService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public utils: Utils
  ) {
    this.startTimer();
    this.questions.forEach((element) => {
      this.mediaService
        .getImageByQuestionId(element["QuestionId"])
        .subscribe((a) => {
          element["Image"] = "data:image/jpeg;base64," + a["image"];
        });
    });
  }

  startTimer() {
    this.startDt = (Date.now() - new Date().getTimezoneOffset() * 60 * 1000).toString();
    this.interval = setInterval(() => {
      if (this.isFinished) {
      } else {
        if (this.minutesLeft > 0) {
          this.minutesLeft--;
        }
        if (
          this.minutesLeft == 0 &&
          this.isFinished == false &&
          this.onPage == true
        ) {
          this.terminateTimeExam();
        }
      }
    }, 60000);
  }
  zoom() {
    const el = document.getElementById("imagem");
    let row: any = {};
    row.src = el.getAttribute("src");
    let dialogRef = this.dialog.open(DialogBoxZoomComponent, {
      id: "zoom",
      data: row,
      width: "auto",
      height: "auto",
      maxHeight: "97vh",
      maxWidth: "97vw",
    });
  }

  a(e, i: number) {
    if (e.toElement.className.match("mat-tab-header-pagination-after")) {
      this.selected.setValue(i + 1);
    } else if (
      e.toElement.className.match("mat-tab-header-pagination-before")
    ) {
      this.selected.setValue(i - 1);
    } else {
    }
  }

  select(e, i, id) {
    id = id + 1;

    if (this.isFinished == false) {
      let tabGroupIndex = document.getElementsByClassName("mat-tab-body")[0].id.split("-")[3];

      const tab = document.getElementById("mat-tab-label-" + tabGroupIndex + "-" + i);

      if (this.utils.isMobileDevice() == true) {
        const labels = document.querySelectorAll("li.input");
        const lis = document.querySelectorAll("li.answer");

        lis.forEach((element) => {
          element.classList.remove("active");
          element.classList.remove("answered");
          tab.classList.remove("label-answered");
        });
        if (lis[id - 1].className.match("active")) {
          lis[id - 1].classList.remove("active");
          lis[id - 1].classList.remove("answered");
          tab.classList.remove("label-answered");
        } else {
          lis[id - 1].classList.add("active");
          lis[id - 1].classList.add("answered");
          tab.classList.add("label-answered");
        }
        labels.forEach((element) => {
          // se estiver respondido e o id for diferente, retira
          if (
            element.children[0].className.match("icofont-close") &&
            Number(element.getAttribute("id")) != id - 1
          ) {
            element.children[0].classList.remove("icofont-close");
            tab.classList.remove("label-answered");
          } else {
            //se o id for igual coloca
            if (Number(element.getAttribute("id")) == id - 1) {
              element.children[0].classList.add("icofont-close");
              tab.classList.add("label-answered");
            }
          }
        });
        this.questions[i]["Answered"] = id;
      } else {
        const labels = document.querySelectorAll("li.withicon");

        console.log(labels);

        labels.forEach((element) => {
          // se estiver respondido e o id for diferente, retira
          if (element.children[0].className.match("icofont-close") && Number(element.getAttribute("id")) != id - 1) {
            element.children[0].classList.remove("icofont-close");
          } else {
            //se o id for igual coloca
            if (Number(element.getAttribute("id")) == id - 1) {
              element.children[0].classList.add("icofont-close");
              tab.classList.add("label-answered");
            }
          }
        });
        this.questions[i]["Answered"] = id;
      }
    } else {
    }

    if (i + 1 <= this.questions.length - 1) {
      setTimeout(() => {
        this.selected.setValue(i + 1);
        this.selectedIndex = i + 1;
      }, 500);
    }
  }

  selectTab(isFinished, id) {

    if (isFinished == true) {
      if (this.utils.isMobileDevice()) {
        let tabGroupIndex = document
          .getElementsByClassName("mat-tab-body")[0]
          .id.split("-")[3];

        console.log(tabGroupIndex);

        const el = document.getElementById(
          "mat-tab-content-" + tabGroupIndex + "-" + id
        );

        console.log(tabGroupIndex);

        let answers = el.querySelectorAll("li.answer");

        console.log(answers);
        console.log(id);

        answers.forEach((element) => {
          if (
            Number(element.getAttribute("id")) == (Number(this.questions[id]["IsAnswer"]) - 1)) {
            element.classList.remove("answered");
            element.classList.add("correct");
          }

          if (
            element.className.match("answered") &&
            Number(element.getAttribute("id")) != (Number(this.questions[id]["IsAnswer"]) - 1)) {
            element.classList.remove("answered");
            element.classList.add("wrong");
          }
        });

        el.children[0].querySelectorAll("li.answer").forEach((elem) => {
          elem.parentElement.classList.add("disabled");
        });
      } else {
        let tabGroupIndex = document.getElementsByClassName("mat-tab-body")[0].id.split("-")[3];

        const el = document.getElementById("mat-tab-content-" + tabGroupIndex + "-" + id);

        let answers = el.querySelectorAll("li.withicon");

        answers.forEach((element) => {
          if (Number(element.getAttribute("id")) == (Number(this.questions[id]["IsAnswer"]) - 1)) {
            element.children[0].classList.add("icofont-tick-mark");
            element.children[0].classList.add("correctIcon");
          }

          if (element.children[0].className.match("icofont-close") && Number(element.getAttribute("id")) != (Number(this.questions[id]["IsAnswer"]) - 1)) {
            element.children[0].classList.add("wrongIcon");
          }
        });

        el.querySelectorAll("li.withicon").forEach((elem) => {
          elem.parentElement.classList.add("disabled");
        });

        el.querySelectorAll("li.option").forEach((elem) => {
          elem.parentElement.classList.add("disabled");
        });

        el.querySelectorAll("li.answer").forEach((elem) => {
          elem.parentElement.classList.add("disabled");
        });
      }
    }
  }

  terminate() {
    let width = this.utils.isMobileDevice() ? "90vw" : "30vw";
    let maxWidth = this.utils.isMobileDevice() ? "99vw" : "30vw";
    let height = this.utils.isMobileDevice() ? "auto" : "auto";
    let row: any = {};
    row.text = "Tem a certeza que quer terminar o teste?";
    let dialogRef = this.dialog.open(ConfirmTerminateComponent, {
      id: "confirm",
      width: width,
      maxWidth: maxWidth,
      height: height,
      data: row,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) {
      } else if (result.event == "sim") {
        this.endExame();
      }
    });
  }

  endExame() {
    this.isFinished = true;
    let i = 0;
    let totalWrong = 0;
    let totalCorrect = 0;

    console.log(this.questions);

    this.questions.forEach((element) => {
      let tabGroupIndex = document.getElementsByClassName("mat-tab-body")[0].id.split("-")[3];
      const tab = document.getElementById("mat-tab-label-" + tabGroupIndex + "-" + i);
      tab.classList.remove("label-answered");

      console.log(element);

      if (element["Answered"] == 0) {
        console.log("NA");
        const index: number = this.themes.findIndex((d) => d["ThemeName"] === element["ThemeName"]);
        this.themes[index]["Wrong"] = this.themes[index]["Wrong"] + 1;
        tab.classList.add("wrong");
        totalWrong++;
      } else {

        if (element["Answered"] == element["IsAnswer"]) {
          console.log("Correct");
          tab.classList.add("correct");
          const index: number = this.themes.findIndex(
            (d) => d["ThemeName"] === element["ThemeName"]
          );
          this.themes[index]["Correct"] = this.themes[index]["Correct"] + 1;
          totalCorrect++;
        } else {
          console.log("Wrong");
          tab.classList.add("wrong");
          const index: number = this.themes.findIndex(
            (d) => d["ThemeName"] === element["ThemeName"]
          );
          this.themes[index]["Wrong"] = this.themes[index]["Wrong"] + 1;
          totalWrong++;
        }
        i++;
      }
    });
    this.themes[this.themes.length - 1]["Correct"] = totalCorrect;
    this.themes[this.themes.length - 1]["Wrong"] = totalWrong;
    console.log(this.themes);
    this.selected.setValue(0);
    this.selectedIndex = 0;
    let row: any = {};
    row.themes = this.themes;
    row.totalWrong = totalWrong;
    let widthTest = this.utils.isMobileDevice() == true ? "95vw" : "70vw";



    let dialogRefr = this.dialog.open(DialogBoxTestResultFreeComponent, {
      id: "testResult",
      width: widthTest,
      maxWidth: widthTest,
      data: row,
      height: 'auto',
      maxHeight: '82vh',
    });

    dialogRefr
      .afterClosed()
      .pipe(first())
      .subscribe(() => {
        this.dialogRef._containerInstance.dispose();
        this.selectTab(this.isFinished, 0);
      });
  }

  refreshTabs() {
    let i = 0;

    this.questions.forEach((element) => {
      this.selectTab(this.isFinished, i);
      i++;
    });
  }

  terminateTimeExam() {
    let width = this.utils.isMobileDevice() == true ? "100vw" : "30vw";
    let height = this.utils.isMobileDevice() == true ? "auto" : "auto";

    let row: any = {};
    row.text = "Acabou o tempo para realizar o teste";
    let dialogRef = this.dialog.open(ConfirmTerminateComponent, {
      id: "confirm",
      width: width,
      data: row,
      disableClose: true,
      closeOnNavigation: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) {
      } else if (result.event == "sim") {
        this.endExame();
      }
    });
  }

  close() {
    let width = this.utils.isMobileDevice() ? "90vw" : "30vw";
    let maxWidth = this.utils.isMobileDevice() ? "99vw" : "30vw";
    let height = this.utils.isMobileDevice() ? "auto" : "auto";
    let row: any = {};
    row.text =
      this.isFinished == true
        ? "Tem a certeza que quer sair?"
        : "Tem a certeza que quer sair? Perderá os dados deste Teste!";

    let dialogRef = this.dialog.open(ConfirmTerminateComponent, {
      id: "confirm",
      width: width,
      maxWidth: maxWidth,
      height: height,
      data: row,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) {
      } else if (result.event == "sim") {
        this.dialogRef.close({ event: "Cancel" });
      }
    });
  }

  indexChange(event) {
    console.log("indexChange");
    console.log(event);
    this.selected.setValue(event);
    //this.selectTab(this.isFinished, this.selected.value);
  }

  SWIPE_ACTION = { LEFT: "swipeleft", RIGHT: "swiperight" };

  // Action triggered when user swipes
  swipe(selectedIndex: number, action = this.SWIPE_ACTION.RIGHT) {
    console.log("swipe");
    console.log("number", this.selected.value);
    console.log(action);
    console.log(this.questions.length - 1);

    // Out of range
    if (
      this.selected.value < 0 ||
      this.selected.value > this.questions.length - 1
    )
      return;

    // Swipe left, next tab
    if (action === this.SWIPE_ACTION.LEFT) {
      const isLast = this.selected.value === this.questions.length - 1;
      console.log("isLast", isLast);
      this.selected.setValue(isLast ? 0 : this.selected.value + 1);
      console.log("Swipe right - INDEX: " + this.selected.value);
    }

    // Swipe right, previous tab
    if (action === this.SWIPE_ACTION.RIGHT) {
      const isFirst = this.selected.value === 0;
      console.log("isFirst", isFirst);
      this.selected.setValue(isFirst ? 1 : this.selected.value - 1);
      console.log("Swipe left - INDEX: " + this.selected.value);
    }
  }
}
