<tc-card>
    <h6>Clique no nome para abrir o material.</h6>
    <nz-table #rowSelectionTable [nzData]="c" [nzBordered]="bordered" [nzPaginationPosition]="position" (nzCurrentPageDataChange)="currentPageDataChange($event)"
        style="overflow: auto;">
        <thead nzSingleSort>
            <tr class="noClick">
                <th>Nome</th>
                <th>Descrição</th>
                <th>Tipo</th>
                <th *ngIf="currentUser.user.profileId == 1" nzWidth="25%"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of rowSelectionTable.data; let i=index;" (click)="openFile(i)">
                <td>{{ data.name | split: ['.',0] }}</td>
                <td>
                    <div *ngIf="currentUser.user.profileId == 1">
                        <tc-input [value]="data.description" id="{{'inputValue' + i}}"></tc-input>
                    </div>
                    <div *ngIf="currentUser.user.profileId != 1">
                        <tc-input [value]="data.description" id="{{'inputValue' + i}}" class="disabled"></tc-input>
                    </div>
                </td>
                <td>{{ data.mimeType | split: ['/',1]}}</td>
                <td *ngIf="currentUser.user.profileId == 1"
                    style="display: flex; justify-content: space-evenly; align-items: center;">
                    <button tc-button [beforeIcon]="'icofont-diskette'" (click)="guardar(i)"
                        [view]="'success'"></button>
                    &nbsp;
                    <button tc-button [beforeIcon]="'icofont-close-circled'" (click)="remover(i)"
                        [view]="'error'"></button>
                </td>
            </tr>
        </tbody>
    </nz-table>
</tc-card>