import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UserService } from '../../../../services/user.service';
import { UserModel } from '../../../../models/userModel';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { BasePageComponent } from '../../../../pages/base-page';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { environment } from '../../../../../environments/environment';
import { Company } from '../../../../models/Company';
import { User } from '../../../../models/user';
import { AuthService } from '../../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { DidaticMaterialService } from '../../../../services/didaticMaterial.service';
import { DialogBoxEbookComponent } from '../../DialogBoxes/dialog-box-ebook/dialog-box-ebook.component';
import { ConfirmComponent } from '../../DialogBoxes/confirm/confirm.component';
/**
 * @title Data table with sorting, pagination, and filtering.
 */
@Component({
    selector: 'app-ebook',
    styleUrls: ['./ebook.component.scss'],
    templateUrl: './ebook.component.html',
})
export class EbookComponent extends BasePageComponent implements OnInit, OnDestroy {
    bordered = true;
    position = 'bottom';
    currentUser: User;
    currentPage = 0;
    c: any = [];
    id;
    @Input() title: string;
    constructor(private userService: UserService, public dialog: MatDialog,
        store: Store<IAppState>, private authenticationService: AuthService, private route: ActivatedRoute,
        httpSv: HttpService,
        private didaticMaterial: DidaticMaterialService, private toastr: ToastrService) {
        super(store, httpSv);
        this.getData(`${environment.apiUrl}/User/`, 'tableData', 'setLoaded');
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        this.id = this.route.snapshot.data['id'];

        if (this.id == null || this.id == undefined) {
            this.id = this.title == (undefined || null) ? this.currentUser.user.companyId : this.title;
        }

        if (this.currentUser.user.profileId != 3 && this.currentUser.user.profileId != 7) {
            this.didaticMaterial.getEbooksByCompanyId(this.id).subscribe(c => {
                this.c = c;
            });
        }
        else {
            this.didaticMaterial.getEbooksByUserId(this.currentUser.user.id).subscribe(c => {
                this.c = c;
            });
        }
    }

    ngOnInit() {
        //  super.ngOnInit();
    }
    ngOnDestroy() {
        // super.ngOnDestroy();
    }
    currentPageDataChange($event: any[]): void {
        this.c = $event;
    }
    openFile(i) {
        let row: any = {};
        row.url = this.c[i]['url'];
        console.log(row.url);
        const dialogRef = this.dialog.open(DialogBoxEbookComponent, {
            width: '95vw',
            height: '95vh',
            data: row
        });
    }
    guardar(i) {
        const el = document.getElementById('input' + i);
        let text = el.children[0].children[0].children[0].attributes[3].value;

        this.c[i]['description'] = text;

        this.didaticMaterial.updateMaterial(this.c[i]['didacticMaterialId'], text).subscribe(c => {
            // console.log(c);
            this.toastr.success('Material modificado com sucesso')
        },
            error => {
                this.toastr.error('Algo correu mal')
            });

    }
    remover(i) {
        let row: any = {};
        row.text = 'Confirma a remoção do ebook?'
        let dialogRef = this.dialog.open(ConfirmComponent, {
            id: 'confirm',
            data: row
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result === undefined) { }
            else if (result.event == 'sim') {
                this.didaticMaterial.removeDidatic(this.c[i]['didacticMaterialId']).subscribe(c => {
                    this.toastr.success('Material removido com sucesso');
                },
                    error => {
                        this.toastr.error('Algo correu mal')
                    });
            }
        });

    }
    isAdmin() {
        return this.currentUser.user.profileId == 1 ? true : false;
    }
}
