var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../../../base-page';
import { Store } from '@ngrx/store';
import { AuthService } from '../../../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '../../../../../services/http/http.service';
import { SheetSalesService } from '../../../../../services/sheetsSales.service';
import { environment } from '../../../../../../environments/environment';
import * as xlsx from 'xlsx';
import { FormControl } from '@angular/forms';
// import * as _moment from 'moment';
import { DialogBoxUsersComponent } from '../../../DialogBoxes/dialog-box-users/dialog-box-users.component';
import { UserService } from '../../../../../services/user.service';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
// tslint:disable-next-line:no-duplicate-imports
// import { default as _rollupMoment } from 'moment';
// const moment = _moment;
export var MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
var StudentSheetsComponent = /** @class */ (function (_super) {
    __extends(StudentSheetsComponent, _super);
    function StudentSheetsComponent(store, userService, dialog, authenticationService, route, getStudentsReportGeneralservice, httpSv, toastr, sheetsService) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.userService = userService;
        _this.dialog = dialog;
        _this.authenticationService = authenticationService;
        _this.route = route;
        _this.getStudentsReportGeneralservice = getStudentsReportGeneralservice;
        _this.toastr = toastr;
        _this.sheetsService = sheetsService;
        _this.bordered = true;
        _this.position = 'bottom';
        _this.startDt = new FormControl();
        _this.endDt = new FormControl();
        _this.currentPage = 0;
        _this.listOfAllData = [];
        _this.currentListData = [];
        _this.found = false;
        _this.getData(environment.apiUrl + "/User/", 'tableData', 'setLoaded');
        _this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        _this.pageData = {
            title: 'Relatório de Alunos',
            loaded: true
        };
        _this.getReports(_this.currentUser.user.companyId, "start", "end");
        return _this;
    }
    StudentSheetsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    StudentSheetsComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    StudentSheetsComponent.prototype.currentPageDataChange = function ($event) {
        this.studentSheets = $event;
    };
    StudentSheetsComponent.prototype.isAdmin = function () {
        return this.currentUser.user.profileId == 1 ? false : true;
    };
    StudentSheetsComponent.prototype.export = function () {
        var tableExport = [];
        this.displayData.forEach(function (element) {
            var z = {
                'Nome': element['name'],
                'Nº de Aluno': element['studentNumber'],
                'Email': element['eMail'],
                'Escola': element['school'],
                'Testes Realizados': element['allFontNumberOfTests'],
                'Taxa Sucesso Global': element['allFontSuccess'],
            };
            tableExport.push(z);
            element['children'].forEach(function (item) {
                var x = {
                    'Categoria': item['categoryName'],
                    'Data de Atribuição': item['startDt'],
                    'Data de Ativação': item['activeDt'],
                    'Testes Realizados AC': item['alvesCostaNumberOfTests'],
                    'Taxa Sucesso AC': item['alvesCostaSuccess'],
                    'Testes Realizados IMT': item['imtNumberOfTests'],
                    'Taxa Sucesso IMT': item['imtSuccess']
                };
                tableExport.push(x);
            });
        });
        var ws = xlsx.utils.json_to_sheet(tableExport);
        var wb = xlsx.utils.book_new();
        var wscols = [
            { wch: 30 },
            { wch: 10 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 15 },
            { wch: 15 },
            { wch: 20 },
            { wch: 15 },
            { wch: 15 },
            { wch: 20 },
            { wch: 15 },
            { wch: 15 },
            { wch: 20 }
        ];
        ws['!cols'] = wscols;
        xlsx.utils.book_append_sheet(wb, ws, 'relatorio_Alunos');
        xlsx.writeFile(wb, 'relatorio_Alunos.xlsx');
    };
    StudentSheetsComponent.prototype.date = function () {
        var _this = this;
        var start = this.startDt.touched == false ? "start" :
            "" + this.startDt.value['_i']['year'] + "-" + (this.startDt.value['_i']['month'] + 1 < 10 ? "0" + (this.startDt.value['_i']['month'] + 1) : this.startDt.value['_i']['month'] + 1)
                + "-" + (this.startDt.value['_i']['date'] < 10 ? "0" + this.startDt.value['_i']['date'] : this.startDt.value['_i']['date']);
        var end = this.endDt.touched == false ? "end" :
            "" + this.endDt.value['_i']['year'] + "-" + (this.endDt.value['_i']['month'] + 1 < 10 ? "0" + (this.endDt.value['_i']['month'] + 1) : this.endDt.value['_i']['month'] + 1) + "-"
                + (this.endDt.value['_i']['date'] < 10 ? "0" + this.endDt.value['_i']['date'] : this.endDt.value['_i']['date']);
        /* this.getReports(this.currentUser.user.companyId, start, end); */
        this.currentListData = [];
        this.getStudentsReportGeneralservice
            .getStudentsReportGeneral(this.currentUser.user.companyId, start, end)
            .subscribe(function (sheetTest) {
            sheetTest.forEach(function (element) {
                _this.found = false;
                for (var index = 0; index < _this.listOfAllData.length; index++) {
                    if (_this.listOfAllData[index]['userId'] == element['userId']) {
                        _this.found = true;
                        var z = {
                            'userId': element['userId'], 'expand': false, 'key': _this.listOfAllData.length,
                            'studentNumber': element['studentNumber'], 'name': element['name'], 'eMail': element['eMail'], 'school': element['school'],
                            'allFontNumberOfTests': element['allFontNumberOfTests'],
                            'allFontSuccess': element['allFontSuccess'],
                            'children': []
                        };
                        _this.currentListData.push(z);
                        var y = {
                            'categoryName': element['categoryName'], 'startDt': element['startDt'], 'activeDt': element['activeDt'],
                            'alvesCostaNumberOfTests': element['alvesCostaNumberOfTests'],
                            'alvesCostaSuccess': element['alvesCostaSuccess'], 'imtNumberOfTests': element['imtNumberOfTests'],
                            'imtSuccess': element['imtSuccess'], 'key': _this.listOfAllData.length - 1
                        };
                        z['children'].push(y);
                    }
                }
            });
            _this.displayData = _this.currentListData;
        });
    };
    StudentSheetsComponent.prototype.getReports = function (id, st, end) {
        var _this = this;
        this.getStudentsReportGeneralservice
            .getStudentsReportGeneral(id, st, end)
            .subscribe(function (sheetTest) {
            sheetTest.forEach(function (element) {
                _this.found = false;
                for (var index = 0; index < _this.listOfAllData.length; index++) {
                    if (_this.listOfAllData[index]['userId'] == element['userId']) {
                        _this.found = true;
                        var x = {
                            'categoryName': element['categoryName'], 'startDt': element['startDt'], 'activeDt': element['activeDt'],
                            'alvesCostaNumberOfTests': element['alvesCostaNumberOfTests'],
                            'alvesCostaSuccess': element['alvesCostaSuccess'], 'imtNumberOfTests': element['imtNumberOfTests'],
                            'imtSuccess': element['imtSuccess'], 'key': _this.listOfAllData.length - 1
                        };
                        _this.listOfAllData[index]['children'].push(x);
                        break;
                    }
                }
                // se nao existir, cria o userId na primeira table e preenche a segunda table tambem
                if (_this.found == false) {
                    var z = {
                        'userId': element['userId'], 'expand': false, 'key': _this.listOfAllData.length,
                        'studentNumber': element['studentNumber'], 'name': element['name'], 'eMail': element['eMail'], 'school': element['school'],
                        'allFontNumberOfTests': element['allFontNumberOfTests'],
                        'allFontSuccess': element['allFontSuccess'],
                        'children': []
                    };
                    _this.listOfAllData.push(z);
                    var y = {
                        'categoryName': element['categoryName'], 'startDt': element['startDt'], 'activeDt': element['activeDt'],
                        'alvesCostaNumberOfTests': element['alvesCostaNumberOfTests'],
                        'alvesCostaSuccess': element['alvesCostaSuccess'], 'imtNumberOfTests': element['imtNumberOfTests'],
                        'imtSuccess': element['imtSuccess'], 'key': _this.listOfAllData.length - 1
                    };
                    z['children'].push(y);
                }
            });
            _this.displayData = _this.listOfAllData;
        });
    };
    StudentSheetsComponent.prototype.openDialog = function (action, event) {
        var _this = this;
        var c = event.srcElement.closest('tr');
        var i = this.displayData.findIndex(function (d) { return d['studentNumber'].toString() === c.children[1].innerText; });
        this.userService.getById(this.displayData[i]['userId']).subscribe(function (a) {
            var row = {};
            row = a;
            row.action = action;
            row.companyName = _this.displayData[i]['school'];
            row.isReportPage = true;
            var windowHeight = window.innerHeight * 0.30;
            var dialogRef = _this.dialog.open(DialogBoxUsersComponent, {
                width: '85vw',
                maxWidth: '1120px',
                height: '90vh',
                maxHeight: windowHeight.toString(),
                data: row
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result === undefined) { }
                else if (result.event == 'ModificarGeral') {
                    _this.updateRowData(result.data);
                }
            });
        });
    };
    StudentSheetsComponent.prototype.updateRowData = function (rowObj) {
        var _this = this;
        this.userService.updateUser(rowObj.userId, '', rowObj.name, rowObj.email, rowObj.nif, rowObj.cc, rowObj.profileId, rowObj.companyId, rowObj.active, rowObj.contacts, rowObj.studentNumber, rowObj.learningLicense, rowObj.userName, '').subscribe(function (data) {
            _this.getReports(_this.currentUser.user.companyId, "start", "end");
            _this.toastr.success('Utilizador modificado com sucesso!');
        }, function (err) {
            _this.toastr.error('Algo ocorreu mal!');
        });
    };
    return StudentSheetsComponent;
}(BasePageComponent));
export { StudentSheetsComponent };
