import { Component, OnInit, Optional, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { QuestionService } from '../../../../services/question.service';
import { environment } from '../../../../../environments/environment';
import { BasePageComponent } from '../../../base-page';
import { IAppState } from '../../../../interfaces/app-state';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../../services/http/http.service';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../../../services/auth.service';
import { TestDefinitionService } from '../../../../services/test-definition.service';
import { BlockTemplateComponent } from '../../block-template/block-template.component';
import { BlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs/internal/Subject';
import { filter } from 'rxjs/internal/operators/filter';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Utils } from 'src/utils/utils';
import { ELearningService } from 'src/app/services/elearning.service';

@Component({
  selector: 'app-dialog-box-test-result',
  templateUrl: './dialog-box-test-result.component.html',
  styleUrls: ['./dialog-box-test-result.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class DialogBoxTestResultComponent extends BasePageComponent implements OnInit {
  testId : number;
  questions: any[];
  tableData: any[] = [];
  position: 'bottom';
  bordered = true;
  msg: String = ''
  localData: any;
  currentUser;
  currentName;
  repprovedQuestions;
  approvedQuestions;
  @BlockUI() blockUI;
  approved: string;
  blockTemplate = BlockTemplateComponent;
  public destroyed = new Subject<any>();
  constructor(private questionService: QuestionService, store: Store<IAppState>, private router: Router, private _location: Location, private authenticationService: AuthService,
    httpSv: HttpService, private testDefinitionService: TestDefinitionService, public dialogRef: MatDialogRef<DialogBoxTestResultComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public utils: Utils,
    private elearningService: ELearningService
    ) {
    super(store, httpSv);
    this.localData = { ...data };

    console.log(this.localData);

    setTimeout(() => {
      this.blockUI.stop();
    }, 2000);
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    const va = this.currentUser.user.name.split(' ');
    if(va.length > 1){
      this.currentName = va[0] + ' ' + va[va.length - 1];
    }
    else{
      this.currentName = this.currentUser.user.name;
    }
    this.getData(`${environment.apiUrl}/Category/`, 'tableData', 'setLoaded');
    this.testDefinitionService.getTestDefinitionById(this.localData.testDefinitionId).subscribe(a => {
      this.approvedQuestions = a['questionsWrong'];
      this.repprovedQuestions = a['questionsWrong'] + 1;
      this.approved = this.localData.wrongQuestions > a['questionsWrong'] ? 'Reprovado' : 'Aprovado';
    });
    this.questionService.arrangeQuestionsByTheme(this.localData.userId, this.localData.questions,
      this.localData.startDt, this.localData.endDt, this.localData.testDefinitionId).subscribe(questions => {

        if (this.localData.count == true) {


          this.questionService.ratingQuestionsByTheme(this.localData.userId, this.localData.questions, this.localData.categoryId,
            this.localData.startDt, this.localData.endDt, this.localData.testDefinitionId, questions[questions.length - 1].countRight, questions[questions.length - 1].countWrong, this.localData.font).subscribe(c => {
              this.testId = c['testId'];

              
              console.log(this.testId);
              console.log(this.localData.userElearningLessonId);
              console.log(this.localData.userId);
              console.log(this.localData.startDt);
              console.log(this.localData.endDt);

              //this.testId = 24481; //batota

              this.elearningService.createTestSession(this.localData.userElearningLessonId, this.testId).subscribe(x => {
                console.log(x);
              });     
              
            });
        }
        else {
          this.msg = 'Este teste não será contabilizado, uma vez que existem várias questões sem resposta';
        }
        this.tableData = questions;
      });
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe();
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
  rever() {
    this.dialogRef.close();
    this.dialogRef._containerInstance = null;
  }
  out() {
    this._location.back();
  }
  currentPageDataChange($event: any[]): void {
    this.tableData = $event;
  }
  
  Msg(): String {
    return this.msg;
  };

}
