import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { BasePageComponent } from '../../../base-page';
import { CamWizardComponent } from '../camWizard/camWizard.component';
import { CategoryService } from '../../../../services/category.service';
import { HttpService } from '../../../../services/http/http.service';
import { IAppState } from '../../../../interfaces/app-state';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { Utils } from 'src/utils/utils';
import { WizardComponent } from '../wizard/wizard.component';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-test-type',
  templateUrl: './test-type.component.html',
  styleUrls: ['./test-type.component.css']
})
export class TestTypeComponent extends BasePageComponent implements OnInit, OnDestroy {
  activeElement: any;
  category: number;
  isCam: boolean;
  font: string;
  data = {};
  constructor(private route: ActivatedRoute, public dialog: MatDialog, private locations: Location, private categoryService: CategoryService,
    private router: Router,
    store: Store<IAppState>,
    httpSv: HttpService,
    public utils: Utils) {
    super(store, httpSv);
    this.category = this.route.snapshot.params['id'];
    this.font = this.route.snapshot.params['font'];
    this.getData(`${environment.apiUrl}/Product/`, 'tableData', 'setLoaded');

    this.categoryService.isCam(this.category).subscribe(camCheck => {
      this.isCam = camCheck;
    });

    this.pageData = {
      title: 'Realizar teste',
      loaded: true
    };
  }

  ngOnInit() {
    super.ngOnInit();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  select(e, id) {
    console.log(navigator.platform);

    const deviceWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxWidth = this.utils.isMobileDevice() ? '99vw' : '90vw';
    const deviceMaxHeight  = this.utils.isMobileDevice() ? '90vh' : '90vh';
    
    this.activeElement = id;
    this.data = { 'type': e.target.innerHTML, 'id': this.category, 'themes': [], 'font': this.font, 'origin' : 'test'};
    


    if (e.target.innerHTML == 'Temático') {
      this.router.navigate(['/app/Tests/Themes/' + this.font + '/' + this.category]);
    }
    else {

      if (this.isCam == true) {
        const dialogRef = this.dialog.open(CamWizardComponent, {
          id: 'camWizard',
          width: deviceWidth,
          maxWidth: deviceMaxWidth,
          height: 'auto',
          maxHeight: deviceMaxHeight,
          disableClose: true,
          closeOnNavigation: true,
          data: this.data,
          panelClass: 'custom-modalbox'
        });
      } else {
        const dialogRef = this.dialog.open(WizardComponent, {
          id: 'wizard',
          width: deviceWidth,
          maxWidth: deviceMaxWidth,
          height: 'auto',
          maxHeight: deviceMaxHeight,
          disableClose: true,
          closeOnNavigation: true,
          data: this.data,
          panelClass: 'custom-modalbox'
          
        });
      }
    }
  }
  back() {
    this.locations.back();
  }

  isDeviceiPad() {
    return navigator.platform.match(/iPad/i);
  }
}