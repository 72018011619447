import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'navbar-skeleton',
  templateUrl: './navbar-skeleton.component.html',
  styleUrls: ['./navbar-skeleton.component.scss']
})
export class NavbarSkeletonComponent implements OnInit {
  @Input() type: string;
  @Input() withLogo: boolean;

  @HostBinding('class.navbar-skeleton') true;
  @HostBinding('class.anvisible') @Input() loaded: boolean;
  @HostBinding('class.app') get app() {
    return this.type === 'app';
  }
  @HostBinding('class.vertical') get vertical() {
    return this.type === 'vertical';
  }

  constructor() {
    this.withLogo = true;
  }

  ngOnInit() {

  }
}
