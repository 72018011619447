<div class="row" style="padding-left:2%;padding-right:2%; padding-bottom:2%">
  <div class="col-5 col-sm-8 col-md-6 col-lg-8 col-xl-8 text-left mt-2">
    <button tc-button class="btn-default" (click)="openFilter()" [beforeIcon]="'icofont-search-document'"
      [view]="'default'">Filtro</button>
  </div>

  <div class="col-7 col-sm-4 col-md-6 col-lg-4 col-xl-4 text-right mt-2">
    <button tc-button class="btn-success" style="margin-right: 2%;" [view]="'success'" [beforeIcon]="'icofont-ui-add'"
      (click)="openDialog('Adicionar',null, null)">Adicionar</button>
  </div>


  <div *ngIf="false" class="col-4 col-sm-2 col-md-3 col-lg-2 col-xl-2 text-right mt-2">
    <button *ngIf="currentUser.user.profileId != 5" tc-button class="btn-error" [view]="'error'"
      [beforeIcon]="'icofont-ui-delete'" (click)="removeSelectedRows()">Remover</button>
  </div>
</div>

<div style="margin-bottom: 1%; border:1px solid #ccd3d9; border-radius: 10px; background-color: aliceblue; padding: 1%;" id="filtro" class="hide">
  <div class="row mt-1 pl-1">
    <div class="col-12 text-left pl-4">
      <button tc-button class="btn-default" [beforeIcon]="'icofont-ui-reply'" (click)="reset()" [view]="'default'">Reset</button>
    </div>
  </div>

  <div *ngIf="!utils.isMobileDevice()" class="row mt-2">
    <div class="col-1"></div>
    <div class="col-1 text-right filter-label">Nome:</div>
    <div class="col-2"><input type="text" [(ngModel)]="nameValue" class="form-control"></div>
    <div class="col-1 text-right filter-label">Email:</div>
    <div class="col-2"><input type="text" [(ngModel)]="emailValue" class="form-control"></div>
    <div class="col-1 text-right filter-label">Tipo:</div>
    <div class="col-2"><input type="text" [(ngModel)]="typeValue" class="form-control"></div>
    <div class="col-1"></div>
  </div>

  <div *ngIf="!utils.isMobileDevice()" class="row mt-2">
    <div class="col-1"></div>
    <div class="col-1 text-right filter-label text-nowrap">Plataforma:</div>
    <div class="col-2"><input type="text" [(ngModel)]="plataformValue" class="form-control"></div>
    <div class="col-1 text-right filter-label">Comercial:</div>
    <div class="col-2"><input type="text" [(ngModel)]="comercialValue" class="form-control"></div>
    <div class="col-1"></div>
  </div>

  <!-- Mobile -->
  <div *ngIf="utils.isMobileDevice()">
    <div class="row mt-1">
      <div class="col-3 text-right filter-label">Nome:</div>
      <div class="col-8"><input type="text" [(ngModel)]="nameValue" class="form-control"></div>
    </div>

    <div class="row mt-1">
      <div class="col-3 text-right filter-label">Email:</div>
      <div class="col-8"><input type="text" [(ngModel)]="emailValue" class="form-control"></div>
    </div>

    <div class="row mt-1">
      <div class="col-3 text-right filter-label">Tipo:</div>
      <div class="col-8"><input type="text" [(ngModel)]="typeValue" class="form-control"></div>
    </div>

    <div class="row mt-1">
      <div class="col-3 text-right filter-label">Plataforma:</div>
      <div class="col-8"><input type="text" [(ngModel)]="plataformValue" class="form-control"></div>
    </div>

    <div class="row mt-1">
      <div class="col-3 text-right filter-label">Comercial:</div>
      <div class="col-8"><input type="text" [(ngModel)]="comercialValue" class="form-control"></div>
    </div>
  </div>

  <div class="row mt-1 pr-1">
    <div class="col-12 text-right pr-4">
      <button tc-button class="btn-success" [view]="'default'" [beforeIcon]="'icofont-search-1'" (click)="filter()">Filtrar</button>
    </div>
  </div>
</div>

<tc-card [padding]="'20px 5px'" [title]="'Empresas'">
  <nz-table #rowSelectionTable [nzData]="listOfAllData" [nzBordered]="bordered" nzShowSizeChanger
    [(nzPageSize)]="pageSize" class="text-center"
    (nzPageIndexChange)="custom($event)" [nzPaginationPosition]="position" (nzCurrentPageDataChange)="currentPageDataChange($event)" style="overflow: auto;">
    <thead (nzSortChange)="sort($event)" nzSingleSort>
      <tr class="noClick">
        <th nzShowCheckbox
          [(nzChecked)]="isAllDisplayDataChecked"
          [nzIndeterminate]="isIndeterminate"
          (nzCheckedChange)="checkAll($event)">
          <a class="tc-icon-wrap icofont-trash"
            (click)="removeSelectedRows()"
            [style.display]="numberOfChecked === 0 ? 'none' : '' "
            style="margin-left: 5px; font-size: 14px;">
          </a>
        </th>
        <th nzShowSort [(nzSort)]="mapOfSort.name" nzSortKey="name">Nome</th>
        <th nzShowSort [(nzSort)]="mapOfSort.email" nzSortKey="email">Email</th>
        <th nzShowSort [(nzSort)]="mapOfSort.comercial" nzSortKey="comercialUser">Comercial</th>
        <th nzShowSort [(nzSort)]="mapOfSort.companyTypeName" nzSortKey="companyType">Tipo de Empresa</th>
        <th nzShowSort [(nzSort)]="mapOfSort.plataformName" nzSortKey="plataformName">Plataforma</th>
        <th nzSortKey="active">Estado </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of rowSelectionTable?.data; let i = index;" (click)="select($event,i)" [attr.id]="i">
        <td nzShowCheckbox [(nzChecked)]="mapOfCheckedId[data.companyId]" (nzCheckedChange)="refreshStatus()"></td>
        <td (click)="openDialog('Modificar', $event,i)">{{ data.name }}</td>
        <td (click)="openDialog('Modificar', $event,i)">{{ data.email }}</td>
        <td (click)="openDialog('Modificar', $event,i)">{{ data.comercialUser }}</td>
        <td (click)="openDialog('Modificar', $event,i)">{{ data.companyType }}</td>
        <td (click)="openDialog('Modificar', $event,i)">{{ data.plataformName }}</td>
        <td nzShowCheckbox [(nzChecked)]="data.active" [nzDisabled]="true"></td>
      </tr>
    </tbody>
  </nz-table>
</tc-card>