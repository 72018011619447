import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ThemeService = /** @class */ (function () {
    function ThemeService(http) {
        this.http = http;
    }
    ThemeService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl + "/Theme/");
    };
    ThemeService.prototype.createTheme = function (categories, name) {
        return this.http.post(environment.apiUrl + "/Theme/", {
            categories: categories, name: name
        });
    };
    ThemeService.prototype.updateTheme = function (id, name, categories) {
        return this.http.put(environment.apiUrl + "/Theme/" + id, {
            name: name, categories: categories
        });
    };
    ThemeService.prototype.deleteTheme = function (id) {
        return this.http.delete(environment.apiUrl + "/Theme/" + id);
    };
    ThemeService.prototype.getThemesAndCategories = function () {
        return this.http.get(environment.apiUrl + "/Theme/themesAndCategories/");
    };
    ThemeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ThemeService_Factory() { return new ThemeService(i0.ɵɵinject(i1.HttpClient)); }, token: ThemeService, providedIn: "root" });
    return ThemeService;
}());
export { ThemeService };
