import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogBoxThemesComponent } from '../dialog-box-themes/dialog-box-themes.component';
import { Theme } from '@fullcalendar/core';
import { Category } from '../../../../models/Category';
import { TestType } from '../../../../models/TestType';
import { TestTypeService } from '../../../../services/test-type.service';
import { CategoryService } from '../../../../services/category.service';

@Component({
  selector: 'app-dialog-box-test',
  templateUrl: './dialog-box-test.component.html',
  styleUrls: ['./dialog-box-test.component.scss']
})
export class DialogBoxTestComponent implements OnInit {

  testDefinitionForm: FormGroup;
  loading = false;
  submitted = false;
  themes: Theme;
  testType: TestType[];
  categories: Category[];
  action: string;
  localData: any;
  constructor(public dialogRef: MatDialogRef<DialogBoxThemesComponent>,
    private formBuilder: FormBuilder, private testTypeService: TestTypeService, private categoryService: CategoryService,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.localData = { ...data };
    this.action = this.localData.action;
    this.testTypeService.getAll().subscribe(test => {
      this.testType = test;
    });
    this.categoryService.getAll().subscribe(categories => {
      this.categories = categories;
    });
  }

  get f() { return this.testDefinitionForm.controls; }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
  ngOnInit() {
    this.testDefinitionForm = this.formBuilder.group({
      categories: ['', Validators.required],
      duration: ['', Validators.required],
      numberOfQuestions: ['', Validators.required],
      wrongQuestions:['', Validators.required],
      testType: ['', Validators.required]
    });
    this.testDefinitionForm.get('categories').setValue(this.localData.categoryName);
    this.testDefinitionForm.get('testType').setValue(this.localData.testTypeName);
  }

  onSubmit() {
    this.localData.CategoryName = this.f.categories.value;
    this.localData.TestTypeName = this.f.testType.value;
    this.localData.numberOfQuestions = this.f.numberOfQuestions.value;
    this.localData.duration = this.f.duration.value;
    this.localData.wrongQuestions = this.f.wrongQuestions.value;
    this.dialogRef.close({ event: this.action, data: this.localData });
  }

}
