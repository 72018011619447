import { Component, OnDestroy, OnInit } from '@angular/core';

import { BasePageComponent } from '../../../../pages/base-page';
import { ConfirmComponent } from '../../DialogBoxes/confirm/confirm.component';
import { DialogBoxThemesComponent } from '../../DialogBoxes/dialog-box-themes/dialog-box-themes.component';
import { HttpService } from '../../../../services/http/http.service';
import { IAppState } from '../../../../interfaces/app-state';
import { MatDialog } from '@angular/material';
import { MediaService } from '../../../../services/media.service';
import { Store } from '@ngrx/store';
import { Theme } from '../../../../models/Theme';
import { ThemeService } from '../../../../services/theme.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import { first } from 'rxjs/internal/operators/first';

@Component({
  selector: 'app-themes',
  templateUrl: './themes.component.html',
  styleUrls: ['./themes.component.css']
})
export class ThemesComponent extends BasePageComponent implements OnInit, OnDestroy {
  bordered = true;
  pageSize = 10;
  position = 'bottom';
  searchValue = '';
  currentPage = 0;
  listOfDisplayData: Theme[] = [];
  listOfAllData: Theme[] = [];
  list: Theme[] = [];
  sortName: string | null = null;
  sortValue: string | null = null;
  constructor(private themeService: ThemeService, store: Store<IAppState>, private mediaService: MediaService,
    httpSv: HttpService, public dialog: MatDialog, private toastr: ToastrService) {
    super(store, httpSv);
    this.getData(`${environment.apiUrl}/Theme/`, 'tableData', 'setLoaded');

    this.themeService.getAll().pipe(first()).subscribe(themes => {
      this.listOfAllData = themes;
      this.list = themes;
    });
    /*this.pageData = {
      title: 'Temas',
      loaded: true
    };*/
  }

  currentPageDataChange($event: Theme[]): void {
    this.listOfDisplayData = $event;
  }
  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.listOfAllData = this.list;
    this.listOfDisplayData = this.list;
    this.search();
  }
  reset(): void {
    this.searchValue = '';
    this.listOfAllData = this.list;
    this.search();
  }
  select(event) {
    var path = event.path || (event.composedPath && event.composedPath());

    if (path[1].className.match('active')) {
      path[1].classList.remove('active');
    }
    else {
      path[1].classList.add('active');
    }
  }
  search(): void {
    const filterFunc = (item: Theme) => {
      return (
        item['name'].toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
      );
    };
    const data = this.list.filter((item: Theme) => filterFunc(item));
    this.listOfAllData = data.sort((a, b) =>

      this.sortValue === 'ascend'
        ? a[this.sortName!] > b[this.sortName!]
          ? 1
          : -1
        : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
    );
  }

  removeSelectedRows() {
    let row: any = {};
    row.text = 'Confirma a remoção do(s) tema(s) selecionado(s)?'
    let dialogRef = this.dialog.open(ConfirmComponent, {
      id: 'confirm',
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) { }
      else if (result.event == 'sim') {
        const e = document.querySelectorAll('tr');
        e.forEach(el => {
          if (el.className.match('active')) {
            const index: number = this.listOfAllData.findIndex(d => d['name'] === el.children[0].lastChild.nodeValue.trim());
            this.themeService.deleteTheme(this.listOfAllData[index]['id']).subscribe((data) => {
              this.themeService.getAll().pipe(first()).subscribe(themes => {
                this.listOfAllData = themes;
              });
              this.toastr.success('Tema(s) removido(s) com sucesso!');
            },
              err => {
                this.toastr.error('Algo ocorreu mal!');
              });
          }
        });
      }
    });
  }
  openDialog(action, e, i) {
    if (e == null || !e.target.className.match('ant-table-column-sorters') && !e.target.className.match('ant-spin-container')) {

      var path = e == null ? '' : e.path || (e.composedPath && e.composedPath());

      let row: any = {};
      row.action = action;
      row.id = e == null ? -1 : this.listOfAllData[(this.currentPage * this.pageSize + i)]['id'];
      row.name = e == null ? '' : path[1].children[0].innerText;
      const dialogRef = this.dialog.open(DialogBoxThemesComponent, {
        width: '99%',
        maxWidth: '850px',
        height: 'auto',
        maxHeight: '97vh',
        data: row,
        disableClose: true,
        closeOnNavigation: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === undefined) { }
        else if (result.event == 'Adicionar') {
          this.addRowData(result.data);
        } else if (result.event == 'Modificar') {
          this.updateRowData(result.data);
        }
      });
    }
  }

  addRowData(rowObj) {
    this.themeService.createTheme(rowObj.categories, rowObj.name).subscribe((data) => {
      this.themeService.getAll().pipe(first()).subscribe(themes => {
        this.listOfAllData = themes;
        this.list = themes;
      });
      this.toastr.success('Tema adicionado com sucesso!');
    },
      err => {
        this.toastr.error('Algo ocorreu mal!');
      });
  }

  updateRowData(rowObj) {
    this.themeService.updateTheme(rowObj.id, rowObj.name, rowObj.categories).subscribe((data) => {
      this.themeService.getAll().pipe(first()).subscribe(themes => {
        this.listOfAllData = themes;
        this.list = themes;
      });
      this.toastr.success('Tema modificado com sucesso!')
    },
      err => {
        this.toastr.error('Algo correu mal!')
      });
  }

  ngOnInit() {
    super.ngOnInit();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  custom(i) {
    this.currentPage = i - 1;
  }
}