import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
	selector: 'tc-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss']
})
export class TCSpinnerComponent implements OnInit, OnChanges {
	@Input() show: boolean;

	constructor() {
	}

	ngOnChanges(changes: SimpleChanges) {
		console.log('Changes');
		console.log(changes);
	}

	ngOnInit() {}
}
