<div class="col col-12 col-md-12 mb-12 mb-md-12">
    <tc-card class="mb-0 custom">
        <div class="row">
            <div class="container">
                <p>Escolha os temas das perguntas e clique no botão de <i>Iniciar Teste</i></p>

             

                <ul class="list-group" style="padding-left: 0%;">
                    <div *ngFor="let theme of themes; let i = index" >
                        
                        <li *ngIf="theme.isLeaf == false && theme.themeId != 75 && theme.themeId != 86 && theme.themeId != 117 && theme.themeId != 123" id="{{theme.themeId}}" class="form-control" (click)="select($event, theme.themeId)">{{ theme.name }}</li>
                        
                        <li *ngIf="theme.isLeaf == false && (theme.themeId == 75 || theme.themeId == 86 || theme.themeId == 117 || theme.themeId == 123)" id="{{theme.themeId}}" class="form-control">{{ theme.name }}</li>

                        <div *ngIf="theme.isLeaf == true">
                            <ul>
                                <li class="form-control"  id="{{theme.themeId}}" (click)="select($event, theme.themeId)">{{ theme.name }}</li>
                            </ul>
                        </div>
                    </div>
                </ul>

                <p style="color:red" *ngIf="count > 0 && !canStart()">
                    Os temas escolhidos não contém questões suficientes para a realização de um teste temático. Escolha um ou mais temas.
                </p>
                <p style="color:red" *ngIf="!(count > 0 && !canStart())">
                    <br>
                </p>

                <div style="display: flex; justify-content: space-evenly; align-items: center;">
                    <button tc-button [view]="'warning'" [beforeIcon]="'icofont-reply-all'" (click)="back()">Voltar</button>
                    <button tc-button [beforeIcon]="'tc-icon-wrap icofont-check'" [style.background-color]="count > 0 && canStart() ? '#35cc35' : 'Gray'" (click)="beginTest()">Iniciar Teste</button>
                </div>
                
            </div>
        </div>
    </tc-card>
</div>