var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../../../../services/user.service';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { BasePageComponent } from '../../../../pages/base-page';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../../services/http/http.service';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { DidaticMaterialService } from '../../../../services/didaticMaterial.service';
import { DialogBoxEbookComponent } from '../../DialogBoxes/dialog-box-ebook/dialog-box-ebook.component';
import { ConfirmComponent } from '../../DialogBoxes/confirm/confirm.component';
/**
 * @title Data table with sorting, pagination, and filtering.
 */
var EbookComponent = /** @class */ (function (_super) {
    __extends(EbookComponent, _super);
    function EbookComponent(userService, dialog, store, authenticationService, route, httpSv, didaticMaterial, toastr) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.userService = userService;
        _this.dialog = dialog;
        _this.authenticationService = authenticationService;
        _this.route = route;
        _this.didaticMaterial = didaticMaterial;
        _this.toastr = toastr;
        _this.bordered = true;
        _this.position = 'bottom';
        _this.currentPage = 0;
        _this.c = [];
        _this.getData(environment.apiUrl + "/User/", 'tableData', 'setLoaded');
        _this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        _this.id = _this.route.snapshot.data['id'];
        if (_this.id == null || _this.id == undefined) {
            _this.id = _this.title == (undefined || null) ? _this.currentUser.user.companyId : _this.title;
        }
        if (_this.currentUser.user.profileId != 3 && _this.currentUser.user.profileId != 7) {
            _this.didaticMaterial.getEbooksByCompanyId(_this.id).subscribe(function (c) {
                _this.c = c;
            });
        }
        else {
            _this.didaticMaterial.getEbooksByUserId(_this.currentUser.user.id).subscribe(function (c) {
                _this.c = c;
            });
        }
        return _this;
    }
    EbookComponent.prototype.ngOnInit = function () {
        //  super.ngOnInit();
    };
    EbookComponent.prototype.ngOnDestroy = function () {
        // super.ngOnDestroy();
    };
    EbookComponent.prototype.currentPageDataChange = function ($event) {
        this.c = $event;
    };
    EbookComponent.prototype.openFile = function (i) {
        var row = {};
        row.url = this.c[i]['url'];
        console.log(row.url);
        var dialogRef = this.dialog.open(DialogBoxEbookComponent, {
            width: '95vw',
            height: '95vh',
            data: row
        });
    };
    EbookComponent.prototype.guardar = function (i) {
        var _this = this;
        var el = document.getElementById('input' + i);
        var text = el.children[0].children[0].children[0].attributes[3].value;
        this.c[i]['description'] = text;
        this.didaticMaterial.updateMaterial(this.c[i]['didacticMaterialId'], text).subscribe(function (c) {
            // console.log(c);
            _this.toastr.success('Material modificado com sucesso');
        }, function (error) {
            _this.toastr.error('Algo correu mal');
        });
    };
    EbookComponent.prototype.remover = function (i) {
        var _this = this;
        var row = {};
        row.text = 'Confirma a remoção do ebook?';
        var dialogRef = this.dialog.open(ConfirmComponent, {
            id: 'confirm',
            data: row
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === undefined) { }
            else if (result.event == 'sim') {
                _this.didaticMaterial.removeDidatic(_this.c[i]['didacticMaterialId']).subscribe(function (c) {
                    _this.toastr.success('Material removido com sucesso');
                }, function (error) {
                    _this.toastr.error('Algo correu mal');
                });
            }
        });
    };
    EbookComponent.prototype.isAdmin = function () {
        return this.currentUser.user.profileId == 1 ? true : false;
    };
    return EbookComponent;
}(BasePageComponent));
export { EbookComponent };
